/* eslint-disable no-debugger */
import { PhoneFilled } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { Avatar, List, Skeleton, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import { sortBy } from "lodash";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import orderBy from "lodash/orderBy";
import React, { useContext, useEffect, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { UserContext } from "../../amplify/authenticator-provider";
import { APPOINTMENT_LIST } from "../../graphql/query";
import useLang from "../../hooks/useLang";
import CalendarIcon from "../../icons/js/CalendarIcon";
import PersonIcon from "../../icons/js/PersonIcon";
import TimerIcon from "../../icons/js/TimerIcon";
import { colorConfig } from "../../library/colorConfig";
import { openDialog } from "../../library/helpers";
import { TabContext } from "../../library/tabs";
import { convertUtcToLocal } from "../../library/utils";
import { reducerSetPagination } from "../../redux/slices/appointmentSlice";

const AvtarWrap = styled(Avatar)`
  height: ${props => (props.height ? props.height : "90px")};
  width: ${props => (props.width ? props.width : "80px")};
  padding: 4px;
  padding-top: 2px !important;
  cursor: ${props => (props?.isFromActiviytCenter ? "pointer" : "")};
  border-radius: 4px !important;
  background: ${props =>
    props.status === "error"
      ? colorConfig.cancelAppointement
      : props.status === "success"
      ? colorConfig.successAppointement
      : props.status === "default"
      ? colorConfig.secondaryDark
      : props.status === "noshow"
      ? colorConfig.noShowAppointment
      : props.status === "show"
      ? colorConfig.primaryDark
      : colorConfig.schedualStatusAppointment};
  & p {
    margin-bottom: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 18px;
  }
`;

const AppointmentList = ({
  searchKey = "",
  leadId,
  canView = true,
  leadOpportunities,
  selectedLead = {},
  onClick = () => {},
  count,
  isFromActiviytCenter = false,
  handleLeadDetailProfile,
  appointmentsData = [],
  isFromAppointment = false,
  // filters,
  passUserIds = true,
}) => {
  const { filters } = useSelector(state => state.appointments);
  const { userIds, appointmentStatus, range, userMode, page, pageSize } = filters;
  const dispatch = useDispatch();
  const { user, refetchAppointments, setrefetchAppointments } = useContext(UserContext);
  const { setActiveTab } = useContext(TabContext);
  const [lang] = useLang();
  const [appointments, setAppointments] = useState([]);
  const [openAppointmentPopup, setAppointmentPopupVisible] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [defaultDate, setDefaultDate] = useState(null);
  const [totalCount, setCount] = useState(0);
  const [variables, setVariables] = useState({
    userIds,
    search: searchKey,
    page: 1,
    pageSize: 10,
    orderBy: "start_datetime",
    orderDirection: "desc",
    dateFilter: null,
    companyId: user?.company?.id || null,
    leadId: +leadId || null,
    leadOpportunities: [],
  });
  useEffect(() => {
    const temp = { ...filters };
    // delete temp.startDate;
    // delete temp.endDate;
    temp.page = 1;
    temp.pageSize = 10;
    temp.orderBy = variables.orderBy || "id";
    temp.orderDirection = variables.orderDirection || "asc";
    temp.dateFilter = null;
    temp.leadOpportunities = [];
    temp.leadId = +leadId;
    // temp.createdByUserIds = createdByUserIds === "All" ? null : createdByUserIds;
    setVariables(
      { ...temp } || {
        page: 1,
        search: searchKey,
        pageSize: 10,
        orderBy: "start_datetime",
        orderDirection: "desc",
        dateFilter: null,
        companyId: user?.company?.id || null,
        leadId: +leadId || null,
        leadOpportunities: [],
      }
    );
  }, [leadId, user, filters]);

  const handleAppointmentModalClose = (status, isPopClosed = true) => {
    setAppointmentPopupVisible(false);
    setAppointment(null);
    setDefaultDate(null);
    if (isPopClosed) {
      // eslint-disable-next-line no-use-before-define
      // getAppointments({
      //   ...variables,
      // });
      setVariables({
        ...variables,
        companyId: user?.company?.id || null,
      });
    }
  };

  const [getAppointments, { loading, refetch: refetchAppointmentsQuery }] = useLazyQuery(
    APPOINTMENT_LIST,
    {
      fetchPolicy: "network-only",
      onCompleted: resp => {
        const appointments = (resp?.getUserAppointment?.data || []).map(el => ({
          ...el,
          type:
            el.appointmentStatus === "CANCELLED"
              ? "error"
              : el.appointmentStatus === "SHOWED"
              ? "show"
              : el.appointmentStatus === "NO_SHOWED"
              ? "noshow"
              : el.isConfirmed
              ? "success"
              : dayjs(el?.startDatetime).format("MM/DD/YYYYTHH:mm") <
                dayjs().utc().format("MM/DD/YYYYTHH:mm")
              ? "default"
              : "processing",
          content: el.summary,
        }));
        const totalCount = resp?.getUserAppointment?.count || 0;
        setAppointments(appointments);
        setCount(totalCount);
      },
    }
  );
  // useEffect(() => {
  //   if (refetchAppointments) {
  //     console.log("Called Refetch");
  //     getAppointments({
  //       variables: {
  //         userIds: userMode === 1 ? userIds : null,
  //         startDate: range.startDate,
  //         endDate: range.endDate,
  //         companyId: user?.company?.id,
  //         createdByUserIds: userMode === 0 ? userIds : null,
  //         appointmentStatus: appointmentStatus,
  //         orderBy: "id",
  //         orderDirection: "asc",
  //         leadOpportunities: [],
  //         page: page,
  //         pageSize: pageSize,
  //       },
  //     });
  //     setrefetchAppointments(false);
  //   }
  // }, [refetchAppointments]);
  useEffect(() => {
    if (userIds && userIds.length > 0) {
      const temp = {
        ...variables,
        // userIds: +filterSetting === 1 ? null : userIds.map(item => item),
        // createdByUserIds: +filterSetting === 2 ? null : userIds.map(item => item),
      };
      // getAppointments({ variables: { ...temp, search: searchKey } });
    }
  }, [variables, userIds]);

  useEffect(() => {
    if (isFromAppointment) {
      setAppointments(appointmentsData);
      setCount(count);
    }
  }, [appointmentsData]);
  const fetchAppointments = () => {
    getAppointments({
      variables: {
        ...(passUserIds && { startDate: range.startDate, endDate: range.endDate }),
        companyId: user?.company?.id,
        ...(passUserIds && {
          userIds: userMode === 1 ? userIds : null,
          createdByUserIds: userMode === 0 ? userIds : null,
        }),
        appointmentStatus: appointmentStatus,
        orderBy: "id",
        orderDirection: "asc",
        leadOpportunities: [],
        page: page,
        pageSize: pageSize,
        search: searchKey,
        leadId,
      },
    });
  };
  useEffect(() => {
    if (!isEmpty(leadId)) fetchAppointments();
  }, [filters, searchKey, leadId, refetchAppointments]);
  // useEffect(() => {
  //   console.log("Refetch", refetch);
  //   if (refetch) {
  //     getAppointments({ variables: { ...variables, leadId: leadId } });
  //   }
  // }, [refetch]);

  const handleAppointmentClick = item => {
    if (isFromActiviytCenter) {
      setAppointment(item);
      openDialog("appointmentForm", {
        dialogHeader: lang.editAppointment,
        lead: selectedLead,
        visibleModal: openAppointmentPopup,
        appointment: item,
        defaultDate: defaultDate,
        refetchAppointmentList: refetchAppointmentsQuery,
        setAppointmentModelVisible: handleAppointmentModalClose,
        leadOpportunities: leadOpportunities,
        redirect: (e, updateDate) => {
          if (isEmpty(selectedLead) && e) {
            setActiveTab("activity-center");
            onClick({
              data: item?.lead || selectedLead,
              tab: "activity-center",
            });
          } else {
            onClick(item?.lead || selectedLead, true, updateDate);
          }
        },
        activeAppointments: (appointments || []).filter(el =>
          ["default", "processing"].includes(el.type)
        ),
        handleLeadDetailProfile: handleLeadDetailProfile,
      });
      setAppointmentPopupVisible(true);
    }
  };

  const vehicles = vehicles => {
    let list = [];
    if (vehicles.length > 0) {
      vehicles = orderBy(vehicles, ["isCurrent"], ["asc"]);
      list = vehicles.map(el => {
        let voi = "";
        if (!isUndefined(el.year) && el.year !== undefined && el.year !== null && el.year !== 0)
          voi = `${voi + el.year} `;
        if (!isUndefined(el.make) && el.make !== null && el.make !== undefined && el.make !== 0)
          voi = `${voi + el.make} `;
        if (!isUndefined(el.model) && el.model !== null && el.model !== undefined && el.model !== 0)
          voi = `${voi + el.model} `;

        return voi === "" || voi === 0 ? (
          <span>{lang.noVehicles}</span>
        ) : (
          <Tooltip
            placement="topLeft"
            title={el?.isCurrent ? lang.previousOrCurrentVehicle : lang.vehicleOfInterest}
          >
            <Tag className="w-[99%] truncate" color={el?.isCurrent ? "magenta" : "blue"}>
              {voi}
            </Tag>
          </Tooltip>
        );
      });
    }
    return list;
  };
  const discussedVoi = (lead, voiId) => {
    if (lead?.leadVehicleInventory?.length > 0) {
      const vehicleInventoryObj = lead.leadVehicleInventory.find(item => +item.id === +voiId);
      if (vehicleInventoryObj) {
        let voi = "";
        if (
          !isUndefined(vehicleInventoryObj.make) &&
          vehicleInventoryObj.make !== null &&
          vehicleInventoryObj.make !== undefined &&
          vehicleInventoryObj.make !== 0
        )
          voi = `${voi} ${vehicleInventoryObj.make} `;
        if (
          !isUndefined(vehicleInventoryObj.model) &&
          vehicleInventoryObj.model !== null &&
          vehicleInventoryObj.model !== undefined &&
          vehicleInventoryObj.model !== 0
        )
          voi = `${voi} ${vehicleInventoryObj.model} `;
        if (
          !isUndefined(vehicleInventoryObj.year) &&
          +vehicleInventoryObj.year !== 0 &&
          vehicleInventoryObj.year !== null &&
          vehicleInventoryObj.year !== undefined &&
          +vehicleInventoryObj.year !== 0
        )
          voi = `${voi} ${vehicleInventoryObj.year} `;
        return (
          <Tooltip title={lang.vehicleOfInterest}>
            <Tag color="blue">{voi || "No vehicles"}</Tag>
          </Tooltip>
        );
      }
    } else if (lead?.vehicleOfInterest?.length > 0) {
      const vehicleOfInterestObj = lead.vehicleOfInterest.find(item => +item.id === +voiId);
      if (vehicleOfInterestObj) {
        let voi = "";
        if (
          !isUndefined(vehicleOfInterestObj.year) &&
          +vehicleOfInterestObj.year !== 0 &&
          vehicleOfInterestObj.year !== null &&
          vehicleOfInterestObj.year !== undefined &&
          +vehicleOfInterestObj.year !== 0
        )
          voi = `${voi} ${vehicleOfInterestObj.year} `;
        if (
          !isUndefined(vehicleOfInterestObj.make) &&
          vehicleOfInterestObj.make !== null &&
          vehicleOfInterestObj.make !== undefined &&
          vehicleOfInterestObj.make !== 0
        )
          voi = `${voi} ${vehicleOfInterestObj.make} `;
        if (
          !isUndefined(vehicleOfInterestObj.model) &&
          vehicleOfInterestObj.model !== null &&
          vehicleOfInterestObj.model !== undefined &&
          vehicleOfInterestObj.model !== 0
        )
          voi = `${voi} ${vehicleOfInterestObj.model} `;
        return (
          <Tooltip title={lang.previousOrCurrentVehicle}>
            <Tag color="magenta">{voi || "No vehicles"}</Tag>
          </Tooltip>
        );
      }
    }
  };
  const status = status => {
    switch (status) {
      case "error":
        return (
          <div className="text-[14px] font-[400] flex flex-row items-center">
            <CalendarIcon className="mr-2" />
            <span style={{ color: colorConfig.cancelAppointement }}>Cancelled</span>
          </div>
        );
      case "success":
        return (
          <div className="text-[14px] font-[400] flex flex-row items-center">
            <CalendarIcon className="mr-2" />
            <span style={{ color: colorConfig.successAppointement }}>Scheduled</span>
          </div>
        );
      case "default":
        return (
          <div className="text-[14px] font-[400] flex flex-row items-center">
            <CalendarIcon className="mr-2" />
            <span style={{ color: colorConfig.secondaryDark }}>Default</span>
          </div>
        );
      case "noshow":
        return (
          <div className="text-[14px] font-[400] flex flex-row items-center">
            <CalendarIcon className="mr-2" />
            <span style={{ color: colorConfig.noShowAppointment }}>No Showed</span>
          </div>
        );
      case "show":
        return (
          <div className="text-[14px] font-[400] flex flex-row items-center">
            <CalendarIcon className="mr-2" />
            <span style={{ color: colorConfig.primaryDark }}>Showed</span>
          </div>
        );
      default:
        return (
          <div className="text-[14px] font-[400] flex flex-row items-center">
            <PersonIcon className="mr-2" />
            <span style={{ color: colorConfig.schedualStatusAppointment }}>
              Scheduled Appointment
            </span>
          </div>
        );
    }
  };
  return (
    <Skeleton loading={loading} active avatar>
      <List
        itemLayout="horizontal"
        dataSource={sortBy(appointments, "startDatetime")}
        pagination={
          totalCount < 5
            ? null
            : {
                size: "small",
                current: page,
                pageSize: pageSize,
                total: totalCount,
                showTotal: total =>
                  `Total: ${total} ${
                    total === 1 ? lang.appointment.toLowerCase() : lang.appointments.toLowerCase()
                  }`,
                pageSizeOptions: ["5", "25", "50"],
                showSizeChanger: true,
                onChange: (page, pageSize) => {
                  dispatch(reducerSetPagination({ page: page, pageSize: pageSize }));
                },
                onShowSizeChange: (page, pageSize) => {
                  dispatch(reducerSetPagination({ page: page, pageSize: pageSize }));
                },
              }
        }
        renderItem={item => (
          <List.Item className=" px-0 mb-1 rounded-[4px] p-3 border-solid border-[1px] border-[#cbd5e1]">
            <div className="flex flex-col px-3 w-full">
              <div className="flex flex-row items-center w-full">
                <AvtarWrap
                  shape="square"
                  height="54px"
                  width="54px"
                  status={item.type}
                  isFromActiviytCenter={isFromActiviytCenter}
                  onClick={() => handleAppointmentClick(item)}
                >
                  <div className="flex flex-col">
                    <span className="text-sm font-bold">
                      {convertUtcToLocal(item.startDatetime, "MMM")}
                    </span>
                    <span className="text-[30px]">
                      {convertUtcToLocal(item.startDatetime, "DD")}
                    </span>
                  </div>
                </AvtarWrap>
                <div className="flex flex-col ml-5">
                  <span className="text-[14px] font-[400] flex flex-row items-center">
                    <PersonIcon className="mr-2" /> {item?.user?.fullName}
                  </span>
                  <span className="text-[14px] font-[400] flex flex-row items-center">
                    <TimerIcon className="mr-2" />
                    {convertUtcToLocal(item.startDatetime, "hh:mm A")}
                  </span>
                  <span className="text-[14px] font-[400] flex flex-row items-center">
                    {status(item.type)}
                  </span>
                </div>
              </div>
              <div className="w-full mt-4 mb-3 h-[1px] bg-[#cbd5e1]" />
              {canView ? (
                <div className="flex flex-col justify-start items-start gap-1">
                  <div className="flex flex-row flex-wrap">
                    {item?.leadOpportunityId && item?.leadOpportunity?.name && (
                      <Tooltip placement="topLeft" title={lang.opportunity}>
                        <Tag color="green">{item?.leadOpportunity?.name}</Tag>
                      </Tooltip>
                    )}
                    {discussedVoi(item.lead, item.discussedVoiId)}
                  </div>
                  <p className="break-words">{item.description}</p>
                  <p style={{ marginBottom: 0 }}>{item?.lead?.fullName || ""} </p>
                  <p className="flex items-center flex-row">
                    <PhoneFilled className="rotate-90 mr-1" />
                    {formatPhoneNumber(
                      item?.lead?.phoneNumbers ? item?.lead?.phoneNumbers[0]?.phone : "N/A"
                    )}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {item?.lead?.emails ? item?.lead?.emails[0]?.email : "N/A"}
                  </p>
                  {/* <p>{vehicles(item?.lead?.leadVehicleInventory || [])}</p>
                    <p>{vehicles(item?.lead?.vehicleOfInterest || [])}</p> */}
                </div>
              ) : null}
            </div>
          </List.Item>
        )}
      />
    </Skeleton>
  );
};
export default AppointmentList;
