import React from "react";
import { Button, Drawer } from "antd";
import styled from "styled-components";
import Appointments from "../../appointments";
import useLang from "../../../../hooks/useLang";

const DrawerBody = styled.div`
  & .ant-list-item {
    &:last-child {
      border-bottom: 1px solid #f1ecec;
    }
  }
`;

const DrawerFooter = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  border-top: 1px solid rgb(232, 232, 232);
  z-index: 999;
`;

const AppointmentModal = props => {
  const {
    lead = null,
    openAppointmentDrawer = false,
    setOpenDrawer,
    onClick,
    leadOpportunities,
    handleLeadDetailProfile,
    eventId,
    assignedUserId = null,
    refetchLeads,
  } = props;

  const [lang] = useLang();

  return (
    <Drawer
      title={<b>{`${lang.addAppointmentFor} ${lead?.fullName || ""}`}</b>}
      placement="right"
      closable={false}
      width="95%"
      onClose={() => setOpenDrawer(false)}
      open={openAppointmentDrawer}
    >
      <DrawerBody>
        <Appointments
          lead={lead}
          onBackClick={() => setOpenDrawer(false)}
          onClick={onClick}
          leadOpportunities={leadOpportunities}
          handleLeadDetailProfile={handleLeadDetailProfile}
          eventId={eventId}
          assignedUserId={assignedUserId}
          refetchLeads={refetchLeads}
        />
      </DrawerBody>
      <DrawerFooter>
        <Button
          style={{ marginRight: 20 }}
          onClick={() => setOpenDrawer(false)}
          className="z-[999]"
        >
          Close
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default AppointmentModal;
