/* eslint-disable react/destructuring-assignment */
import * as React from "react";

const CalendarIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill={props.color || "#64748B"}
      d="M13.555 2.445h-2.222v-.56a.551.551 0 0 0-.551-.551h-.009a.551.551 0 0 0-.551.551v.56H5.777v-.56a.551.551 0 0 0-.55-.551h-.01a.551.551 0 0 0-.55.551v.56H2.443c-.614 0-1.111.498-1.111 1.111v10c0 .614.497 1.111 1.111 1.111h11.111c.614 0 1.111-.497 1.111-1.11v-10c0-.614-.497-1.112-1.11-1.112Zm0 10.56a.551.551 0 0 1-.55.551H2.994a.551.551 0 0 1-.55-.55V6.89h11.11v6.115Zm0-7.227H2.445v-1.67c0-.305.246-.552.55-.552h1.671v.56c0 .305.247.551.551.551h.01a.551.551 0 0 0 .55-.55v-.56h4.445v.56c0 .304.247.55.551.55h.009a.551.551 0 0 0 .551-.55v-.56h1.671c.305 0 .551.246.551.55v1.671Z"
    />
    <path
      fill={props.color || "#64748B"}
      d="M4.107 9.111h1.12a.551.551 0 0 0 .55-.551v-.009A.551.551 0 0 0 5.228 8h-1.12a.551.551 0 0 0-.551.551v.009c0 .304.246.551.55.551Zm3.333 0h1.12a.551.551 0 0 0 .551-.551v-.009A.551.551 0 0 0 8.561 8H7.44a.551.551 0 0 0-.552.551v.009c0 .304.247.551.551.551Zm3.333 0h1.12a.551.551 0 0 0 .552-.551v-.009A.551.551 0 0 0 11.893 8h-1.12a.551.551 0 0 0-.55.551v.009c0 .304.246.551.55.551Zm-6.666 2.222h1.12a.551.551 0 0 0 .55-.55v-.01a.551.551 0 0 0-.55-.55h-1.12a.551.551 0 0 0-.551.55v.01c0 .304.246.55.55.55Zm3.333 0h1.12a.551.551 0 0 0 .551-.55v-.01a.551.551 0 0 0-.55-.55H7.44a.551.551 0 0 0-.552.55v.01c0 .304.247.55.551.55Zm3.333 0h1.12a.551.551 0 0 0 .552-.55v-.01a.551.551 0 0 0-.552-.55h-1.12a.551.551 0 0 0-.55.55v.01c0 .304.246.55.55.55Z"
    />
  </svg>
);
export default CalendarIcon;
