/* eslint-disable default-param-last */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-else-return */
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Avatar, List, message, Spin, Tag, Tooltip } from "antd";
import { isEmpty, isUndefined } from "lodash";
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { UserContext } from "../../../../amplify/authenticator-provider";
import { GET_POTENTIAL_LEADS_BY_ID } from "../../../../graphql/query";
import CustomLabel from "../../../commonComponents/label";
import { handleOwnerCRM, LeadStatus } from "../engagementHelpers";
import { handleOpportunityDilaog } from "../opporotunityLayer/opportuityWarning";
import { colorConfig } from "../../../../library/colorConfig";
import { closeDialog } from "../../../../library/helpers";
import useLang from "../../../../hooks/useLang";
import { crmLinkGeneration } from "../opporotunityLayer/crmLinks";

const PotentialLeadComp = props => {
  const {
    selectedLeadPotentialIds,
    handleLeadClick = () => {},
    crmIntegrationData,
    refetchLeads,
    isFromLeadCenter,
    crmIntegrationType = "",
  } = props;

  const [lang] = useLang();

  const { user } = useContext(UserContext);
  const [userLocationsId, setUserLocationsId] = useState([]);
  const [duplicateLeads, setDuplicateLeds] = useState([]);
  const [opportunityWarningDialog, setOpportunityWarningDialog] = useState(false);
  const [opportunityInfo, setOpportunityInfo] = useState({});
  const [isOpporDialogFlagChanged, setIsOpprDialogFlagChanged] = useState(false);

  useEffect(() => {
    if (user && user?.locations?.length > 0) {
      const locationIDs = user?.locations.map(location => location?.location?.id);
      setUserLocationsId(locationIDs);
    }
  }, [user]);

  const handleOpporDialogFlagChanged = () => {
    setIsOpprDialogFlagChanged(true);
    localStorage.setItem("isOpporDialogFlagChangedLocal", "true");
  };

  const { loading: leadLoader } = useQuery(GET_POTENTIAL_LEADS_BY_ID, {
    variables: {
      leadIds:
        selectedLeadPotentialIds && selectedLeadPotentialIds?.length > 0
          ? [...selectedLeadPotentialIds]
          : [],
    },
    onCompleted: res => {
      setDuplicateLeds(res?.getLeadsById);
    },
  });

  const displayPhoneNumbers = phones => {
    const phoneNumbers = [];
    for (let i = 0; i < phones.length; i++) {
      const phoneObj = !isUndefined(phones[i]) ? phones[i] : {};
      const phoneType = phoneObj?.lookupType || "";
      const phone = phoneObj?.phone || "";
      if (!isEmpty(phone) && phone != null) {
        if (["Invalid", "None"].includes(phoneType)) {
          phoneNumbers.push(phone);
        } else if (isValidPhoneNumber(phone)) {
          phoneNumbers.push(formatPhoneNumber(phone));
        }
      }
    }
    const phoneStr = phoneNumbers.join(" | ");
    return phoneStr;
  };

  const onLeadStatus = (user, status) => {
    if (status.includes("ACTIVE") || status === "Open") {
      return (
        <Tag color="#2db7f5">
          {user?.company?.crmIntegrationType === "SF"
            ? lang.open
            : status.includes("ACTIVE")
            ? lang.active
            : status}
        </Tag>
      );
    } else if (status === "LOST") {
      return <Tag color="#cd201f">{status}</Tag>;
    } else if (status.includes("SOLD") || status === "Converted") {
      return (
        <Tag color="#87d068">
          {user?.company?.crmIntegrationType === "SF"
            ? lang.converted
            : status.includes("SOLD")
            ? lang.sold
            : status}
        </Tag>
      );
    } else if (status.includes("Contacted")) {
      return <Tag color="#87d068">{status}</Tag>;
    } else {
      return <Tag color="#ff4500">{status}</Tag>;
    }
  };

  const onVehicles = lead => {
    let list = [];
    if ((lead?.leadVehicleInventory || []).length > 0) {
      list = (lead?.leadVehicleInventory || []).map(el => {
        let voi = "";
        if (!isUndefined(el.make) && el.make !== null && el.make !== undefined && el.make !== 0)
          voi = `${voi}${el.make} `;
        if (!isUndefined(el.model) && el.model !== null && el.model !== undefined && el.model !== 0)
          voi = `${voi}${el.model} `;
        if (
          !isUndefined(el.year) &&
          el.year !== 0 &&
          el.year !== null &&
          el.year !== undefined &&
          el.year !== 0
        )
          voi = `${voi}${el.year} `;
        {
          voi === "" || voi === 0 ? (
            <strong>{lang.noVehicles}</strong>
          ) : (
            <Tooltip
              placement="topLeft"
              title={el?.isCurrent ? lang.previousOrCurrentVehicle : lang.vehicleOfInterest}
            >
              <Tag
                className="mb-[5px]"
                color={el?.isCurrent ? colorConfig.primaryDark : colorConfig.secondaryDark}
              >
                {voi}
              </Tag>
            </Tooltip>
          );
        }
      });
    }
    if ((lead?.vehicleOfInterest || []).length > 0) {
      lead?.vehicleOfInterest.map(el => {
        let voi = "";
        if (
          !isUndefined(el.year) &&
          el.year !== 0 &&
          el.year !== null &&
          el.year !== undefined &&
          el.year !== 0
        )
          voi = `${voi}${el.year} `;
        if (!isUndefined(el.make) && el.make !== null && el.make !== undefined && el.make !== 0)
          voi = `${voi}${el.make} `;
        if (!isUndefined(el.model) && el.model !== null && el.model !== undefined && el.model !== 0)
          voi = `${voi}${el.model} `;
        {
          voi !== "" && voi !== 0
            ? list.push(
                <Tooltip
                  placement="topLeft"
                  title={el?.isCurrent ? lang.previousOrCurrentVehicle : lang.vehicleOfInterest}
                >
                  <Tag
                    style={{ marginBottom: 5 }}
                    color={el?.isCurrent ? colorConfig.primaryDark : colorConfig.secondaryDark}
                  >
                    {voi}
                  </Tag>
                </Tooltip>
              )
            : list.push(<strong>{lang.noVehicles}</strong>);
        }
      });
    } else {
      return <strong>{lang.noVehicles}</strong>;
    }
    return list;
  };

  const onLeadOwners = (owners, crmIntegration = "") => {
    if (crmIntegration === "DS" || crmIntegration === "SF") {
      return handleOwnerCRM(owners);
    } else {
      let list = [];
      if (owners.length > 0) {
        list = owners.map((el, i) => {
          const fullName = `${el?.user?.firstName} ${el?.user?.lastName} (${el?.user?.companyRole?.name})`;
          return (
            <Tooltip key="SF" placement="topLeft" title={i === 0 ? "Primary Owner" : ""}>
              <Tag style={{ marginBottom: 5 }} color={i === 0 ? "purple" : "geekblue"}>
                {fullName || "NA"}
              </Tag>
            </Tooltip>
          );
        });
      }
      return list;
    }
  };

  const locationPermission = lead => {
    if (userLocationsId.includes(lead?.location?.id)) {
      handleLeadClick(lead?.id, lead?.location?.id, lead?.fullName, lead?.phoneNumbers[0]?.phone);
      closeDialog("potentialDuplicateLeads");
    } else {
      message.error(`${lang.leadPermission} ${lead?.location?.title}`);
    }
  };

  const handleOpportunity = item => {
    if (
      item?.leadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.sfLeadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId
    ) {
      const oppLinkType =
        userLocationsId?.length > 0 && userLocationsId.includes(item?.location?.id) ? (
          <a
            href={`https://usautosales.lightning.force.com/lightning/r/Opportunity/${item?.leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId}/view`}
            target="_blank"
            rel="noreferrer"
          >
            {lang.gotoOpportunity}
          </a>
        ) : (
          <div
            onClick={() => message.error(`${lang.dontHaveOppr} ${item?.location?.title}`)}
            className="secondaryColorTheme cursor-pointer"
            style={{
              transition: "color 0.3s",
            }}
          >
            {lang.gotoOpportunity}
          </div>
        );
      return oppLinkType;
    } else if (
      item?.leadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.vsLeadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.vsLeadOpportunities[0]?.vsContactId
    ) {
      const vinLink = crmLinkGeneration(
        "VIN",
        Boolean(item?.leadOpportunities[0]?.vsLeadOpportunities[0]?.vsContactId),
        item?.leadOpportunities[0]?.vsLeadOpportunities[0]?.vsContactId,
        "",
        "VinSolution",
        user?.company?.id
      );

      const oppLinkType =
        userLocationsId?.length > 0 && userLocationsId.includes(item?.location?.id) ? (
          <a href={vinLink} target="_blank" rel="noreferrer">
            {lang.gotoOpportunity}
          </a>
        ) : (
          <div
            onClick={() => message.error(`${lang.dontHaveOppr} ${item?.location?.title}`)}
            className="secondaryColorTheme cursor-pointer"
            style={{
              transition: "color 0.3s",
            }}
          >
            {lang.gotoOpportunity}
          </div>
        );
      return oppLinkType;
    } else {
      const linkType =
        userLocationsId?.length > 0 && userLocationsId.includes(item?.location?.id) ? (
          <a
            href={`https://usautosales.lightning.force.com/lightning/r/Lead/${item?.crmLeadObj?.sfExtractedLead?.sfLeadId}/view`}
            target="_blank"
            rel="noreferrer"
          >
            {lang.gotoLead}
          </a>
        ) : (
          <div
            onClick={() => message.error(`${lang.leadPermission} ${item?.location?.title}`)}
            className="secondaryColorTheme cursor-pointer"
            style={{
              transition: "color 0.3s",
            }}
          >
            {lang.gotoLead}
          </div>
        );
      return linkType;
    }
  };

  const handleOpportunityWarningDialog = (
    item,
    crmLink,
    opprId,
    opprName,
    isOpp = false,
    crmLeadObj
  ) => {
    setOpportunityInfo({
      ...item.leadOpportunities[0],
      opprLink: crmLink,
      opprId: opprId,
      opprName: opprName,
      isOpp: isOpp,
      crmLeadObj: crmLeadObj,
    });
    setOpportunityWarningDialog(true);
  };

  const handleOpportunityDialog = item => {
    if (
      item?.leadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.sfLeadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId
    ) {
      const opprName = item?.leadOpportunities[0]?.name;
      const oppLinkType =
        userLocationsId?.length > 0 && userLocationsId.includes(item?.location?.id) ? (
          <div
            onClick={() =>
              handleOpportunityWarningDialog(
                item,
                "oppLink",
                item?.leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId,
                opprName,
                true,
                item?.crmLeadObj
              )
            }
            className="secondaryColorTheme cursor-pointer"
            style={{
              transition: "color 0.3s",
            }}
          >
            {lang.gotoOpportunity}
          </div>
        ) : (
          <div
            onClick={() => message.error(`${lang.dontHaveOppr} ${item?.location?.title}`)}
            className="secondaryColorTheme cursor-pointer"
            style={{
              transition: "color 0.3s",
            }}
          >
            {lang.gotoOpportunity}
          </div>
        );
      return oppLinkType;
    } else if (
      item?.leadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.vsLeadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.vsLeadOpportunities[0]?.vsContactId
    ) {
      const opprName = item?.leadOpportunities[0]?.name;
      const oppLinkType =
        userLocationsId?.length > 0 && userLocationsId.includes(item?.location?.id) ? (
          <div
            onClick={() =>
              handleOpportunityWarningDialog(
                item,
                "oppLink",
                item?.leadOpportunities[0]?.vsLeadOpportunities[0]?.vsContactId,
                opprName,
                true,
                item?.crmLeadObj
              )
            }
            className="secondaryColorTheme cursor-pointer"
            style={{
              transition: "color 0.3s",
            }}
          >
            {lang.gotoOpportunity}
          </div>
        ) : (
          <div
            onClick={() => message.error(`${lang.dontHaveOppr} ${item?.location?.title}`)}
            className="secondaryColorTheme cursor-pointer"
            style={{
              transition: "color 0.3s",
            }}
          >
            {lang.gotoOpportunity}
          </div>
        );
      return oppLinkType;
    } else {
      const opprName = `Lead: ${item?.fullName}`;
      const linkType =
        userLocationsId?.length > 0 && userLocationsId.includes(item?.location?.id) ? (
          <div
            onClick={() =>
              handleOpportunityWarningDialog(
                item,
                "oppLink",
                item?.crmLeadObj?.sfExtractedLead?.sfLeadId,
                opprName,
                false,
                item?.crmLeadObj
              )
            }
            className="secondaryColorTheme cursor-pointer"
            style={{
              transition: "color 0.3s",
            }}
          >
            {lang.gotoLead}
          </div>
        ) : (
          <div
            onClick={() => message.error(`${lang.leadPermission} ${item?.location?.title}`)}
            className="secondaryColorTheme cursor-pointer"
            style={{
              transition: "color 0.3s",
            }}
          >
            {lang.gotoLead}
          </div>
        );
      return linkType;
    }
  };

  const handleOpportunityName = item => {
    if (
      item?.leadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.sfLeadOpportunities.length > 0 &&
      item?.leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId
    ) {
      return item?.leadOpportunities[0]?.name;
    } else if (
      item?.leadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.vsLeadOpportunities.length > 0 &&
      item?.leadOpportunities[0]?.vsLeadOpportunities[0]?.vsLeadId
    ) {
      return item?.leadOpportunities[0]?.name(
        `#${item?.leadOpportunities[0]?.vsLeadOpportunities[0]?.vsLeadId}`
      );
    } else {
      return `${lang.leadLabel}: ${item?.fullName}`;
    }
  };

  const getOpportunityBoolean = item => {
    // eslint-disable-next-line no-unneeded-ternary
    return item?.leadOpportunities?.length > 0 &&
      item?.leadOpportunities[0]?.sfLeadOpportunities.length > 0 &&
      item?.leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId
      ? true
      : false;
  };

  return (
    <div>
      <div className="h-[500px] overflow-y-auto overflow-x-hidden">
        {duplicateLeads.length > 0 ? (
          <div className="w-full">
            <List
              itemLayout="vertical"
              dataSource={duplicateLeads}
              loading={leadLoader}
              pagination={false}
              renderItem={item => {
                const email = item?.emails ? item?.emails[0]?.email || "NA" : "NA";
                return (
                  <List.Item className="p-1">
                    <List.Item.Meta
                      avatar={
                        <Avatar size={40}>
                          {item?.fullName ? item?.fullName.substring(0, 1).toUpperCase() : "X"}
                        </Avatar>
                      }
                      title={
                        <p className="m-0 flex flex-wrap justify-between labelColor">
                          <CustomLabel
                            onClick={() => {
                              locationPermission(item);
                            }}
                            label={item?.firstName}
                            labelClass="secondaryColorTheme cursor-pointer"
                          />
                          <div className="flex justify-end">
                            <Tooltip
                              placement="topLeft"
                              title={
                                item?.crmLeadObj?.crmStatus
                                  ? item?.crmLeadObj?.crmStatus
                                  : item?.leadStatusType
                                  ? item?.leadStatusType?.status.replace(/_/g, " ")
                                  : ""
                              }
                            >
                              {item?.crmLeadObj?.crmStatus
                                ? onLeadStatus(user, item?.crmLeadObj?.crmStatus)
                                : item?.leadStatusType
                                ? onLeadStatus(user, item?.leadStatusType?.type)
                                : ""}
                            </Tooltip>
                            {item?.leadOpportunities?.length > 0 && (
                              <Tooltip title={lang.opportunityName}>
                                <Tag color="#768775">{handleOpportunityName(item)}</Tag>
                              </Tooltip>
                            )}
                          </div>
                        </p>
                      }
                      description={
                        <div className="flex justify-between items-end">
                          <div>
                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.contacts}:{" "}
                              <strong>{displayPhoneNumbers(item?.phoneNumbers || [])}</strong>
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.emailLabel}: <strong>{email}</strong>
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.source}: <strong>{item?.leadSource?.name || "NA"}</strong>
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.status}:{" "}
                              <strong>
                                {item?.status ? LeadStatus(user, item?.status) : "NA"}
                              </strong>
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.location}:{" "}
                              <strong>
                                {`${item?.location?.title || "NA"} (${
                                  item?.location?.locationType ||
                                  `${item?.location?.title ? lang.store : ""}`
                                })`}
                              </strong>
                            </div>

                            {(crmIntegrationType === "SF" || crmIntegrationType === "DS") && (
                              <div className="text-left">{lang.owners}</div>
                            )}

                            <div className="m-0 w-full mb-[6px] items-start">
                              {crmIntegrationType === "" && <p>{lang.owners}:</p>}{" "}
                              <div className="text-left">
                                {onLeadOwners(item?.leadUsers || [], crmIntegrationType)}
                              </div>
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.vehicles}:{" "}
                              <span className="text-left">
                                {onVehicles(item?.vehicleOfInterest || [])}
                              </span>
                            </div>
                          </div>
                          <div
                            className={`mr-[10px] ${
                              getOpportunityBoolean(item) ? "w-[100px]" : "w-[83px]"
                            }`}
                          >
                            {localStorage.getItem("isOpporDialogFlagChangedLocal")
                              ? handleOpportunity(item)
                              : !handleOpportunityDilaog(user?.locations, user)
                              ? handleOpportunity(item)
                              : handleOpportunityDialog(item)}
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                );
              }}
            />
          </div>
        ) : (
          <Spin
            spinning={leadLoader}
            className="fixed top-[50%] left-[50%] z-[999]"
            style={{
              transform: "translateX(-50%) translateY(-50%)",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PotentialLeadComp;
