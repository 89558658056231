import * as React from "react";

const PencilIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <path
      // eslint-disable-next-line react/destructuring-assignment
      fill={props.color || "#F59E0B"}
      d="m14.708 4.585-2.794-2.792a1 1 0 0 0-1.414 0L2.793 9.5a.991.991 0 0 0-.293.707V13a1 1 0 0 0 1 1H14a.5.5 0 1 0 0-1H7.708l7-7a1.002 1.002 0 0 0 0-1.415Zm-9.25 7.165 5.646-5.646 1.043 1.042L6.5 12.793 5.458 11.75Zm4.937-6.354L4.75 11.043 3.707 10l5.647-5.646 1.041 1.042ZM3.5 11.206 5.293 13H3.5v-1.793Z"
    />
  </svg>
);
export default PencilIcon;
