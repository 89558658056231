import React, { memo, useEffect, useState } from "react";
import { Pie } from "@ant-design/plots";
import { Empty, Skeleton } from "antd";

const PieChart = props => {
  const { graphData } = props;

  const [api, setApi] = useState(true);

  const Wait = () =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(false);
      }, 2000);
    });

  const handleAPI = async () => {
    try {
      const api = await Wait();
      setApi(api);
    } catch (err) {
      setApi(true);
    }
  };

  useEffect(() => {
    handleAPI();
  }, []);

  const config = {
    appendPadding: 10,
    data: graphData,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    height: 320,
    label: {
      type: "outer",
      content: "{name} {percentage}",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };

  return api ? (
    <div className="h-[320px]">
      <Skeleton loading={api} active />
      <Skeleton className="mt-3" loading={api} active />
    </div>
  ) : graphData?.length > 0 ? (
    <Pie {...config} />
  ) : (
    <Empty className="h-[320px] flex flex-col justify-center" />
  );
};

export default memo(PieChart); // converted into pure component to prevent unwanted rendering
