import { createSlice } from "@reduxjs/toolkit";
import en from "../../../en.json";

const initialState = {
  statement: en,
  language: "en",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {},
});

export const getStatements = state => state.language.statement;

export default languageSlice.reducer;
