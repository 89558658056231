import * as React from "react";

const AttachmentIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M16.38 9.558a.626.626 0 0 1 0 .884l-6.41 6.406a4.375 4.375 0 1 1-6.188-6.188l7.755-7.869a3.126 3.126 0 0 1 4.422 4.418l-7.756 7.87a1.878 1.878 0 1 1-2.656-2.657l6.508-6.611a.625.625 0 1 1 .89.877l-6.508 6.618a.625.625 0 1 0 .88.887l7.756-7.865a1.876 1.876 0 0 0-2.651-2.656l-7.753 7.865a3.125 3.125 0 1 0 4.416 4.424l6.411-6.406a.625.625 0 0 1 .884.003Z"
    />
  </svg>
);
export default AttachmentIcon;
