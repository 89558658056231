import * as React from "react";

const AppointmentIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M16.25 2.5h-1.875v-.625a.625.625 0 1 0-1.25 0V2.5h-6.25v-.625a.625.625 0 0 0-1.25 0V2.5H3.75A1.25 1.25 0 0 0 2.5 3.75v12.5a1.25 1.25 0 0 0 1.25 1.25h12.5a1.25 1.25 0 0 0 1.25-1.25V3.75a1.25 1.25 0 0 0-1.25-1.25ZM5.625 3.75v.625a.625.625 0 0 0 1.25 0V3.75h6.25v.625a.625.625 0 1 0 1.25 0V3.75h1.875v2.5H3.75v-2.5h1.875Zm10.625 12.5H3.75V7.5h12.5v8.75Zm-3.75-4.375a.624.624 0 0 1-.625.625h-1.25v1.25a.624.624 0 1 1-1.25 0V12.5h-1.25a.625.625 0 1 1 0-1.25h1.25V10a.625.625 0 0 1 1.25 0v1.25h1.25a.624.624 0 0 1 .625.625Z"
    />
  </svg>
);
export default AppointmentIcon;
