import * as React from "react";

const SummaryIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#334155"
      d="M13.125 10a.624.624 0 0 1-.625.625h-5a.625.625 0 1 1 0-1.25h5a.625.625 0 0 1 .625.625Zm-.625 1.875h-5a.625.625 0 1 0 0 1.25h5a.624.624 0 1 0 0-1.25Zm4.375-8.125v11.875a2.5 2.5 0 0 1-2.5 2.5h-8.75a2.5 2.5 0 0 1-2.5-2.5V3.75a1.25 1.25 0 0 1 1.25-1.25h1.25v-.625a.625.625 0 0 1 1.25 0V2.5h2.5v-.625a.625.625 0 0 1 1.25 0V2.5h2.5v-.625a.625.625 0 1 1 1.25 0V2.5h1.25a1.25 1.25 0 0 1 1.25 1.25Zm-1.25 0h-1.25v.625a.625.625 0 1 1-1.25 0V3.75h-2.5v.625a.625.625 0 1 1-1.25 0V3.75h-2.5v.625a.625.625 0 0 1-1.25 0V3.75h-1.25v11.875a1.25 1.25 0 0 0 1.25 1.25h8.75a1.25 1.25 0 0 0 1.25-1.25V3.75Z"
    />
  </svg>
);
export default SummaryIcon;
