import * as React from "react";

const FilterIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M17.016.87a1.235 1.235 0 0 0-1.141-.745H2.125a1.25 1.25 0 0 0-.923 2.09l.007.008L6.5 7.873v6.002a1.25 1.25 0 0 0 1.943 1.04l2.5-1.666a1.25 1.25 0 0 0 .557-1.041V7.873l5.292-5.65.006-.007A1.234 1.234 0 0 0 17.016.87Zm-6.427 6.152c-.217.23-.338.534-.339.85v4.336l-2.5 1.667V7.873c0-.318-.12-.624-.338-.855L2.125 1.375h13.75l-5.286 5.647Z"
    />
  </svg>
);
export default FilterIcon;
