/* eslint-disable no-restricted-syntax */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-fragments */
import { SearchOutlined } from "@ant-design/icons";

import { Button, Input, Select, Switch, Tabs } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ordinalNumbers } from "../../../library/constants";
import TemplateDrawer from "../schedual/templateDrawer";
import { Form } from "./Form";

export const Panel = ({
  item,
  index,
  campaignData,
  setCampaignData,
  sources,
  activeKeys,
  setactiveKeys,
  campaignByLead,
}) => {
  const [activeSourceTab, setactiveSourceTab] = useState("all");
  const [searchSource, setsearchSource] = useState("");
  const [sourceList, setsourceList] = useState([]);
  const handleChange = (value, key, index) => {
    setCampaignData(prev => {
      const temp = structuredClone(prev.messages);
      temp[index] = {
        ...temp[index],
        isChanged: true,
      };
      temp[index][key] = value;
      return { ...prev, messages: temp };
    });
  };
  const [templateDrawer, settemplateDrawer] = useState({ visible: false });
  const result = () => {
    const arr = _.flatMap(_.cloneDeep(campaignData.source), keys => {
      const resultArray = [];
      let currentNode = _.cloneDeep(_.find(sources, { key: keys[0] }));

      for (let i = 1; i < keys.length; i++) {
        const currentKey = keys[i];
        const childNode = _.find(currentNode.childs, { key: currentKey });

        if (!childNode) {
          // If no child nodes exist, include the parent node
          resultArray.push({ key: currentNode.key, name: currentNode.name });
          break; // Break as there are no child nodes
        }

        currentNode = childNode;

        if (i === keys.length - 1) {
          // Only push the last child into the result array
          resultArray.push({ key: currentKey, name: childNode.name });
        }
      }

      // Add the parent node (e.g., KBB) if it's not included in the result array
      if (!resultArray.some(item => item.key === currentNode.key)) {
        resultArray.push({ key: currentNode.key, name: currentNode.name });
      }

      return resultArray;
    });
    return arr;
  };
  const resultForLeads = (data, ids) => {
    const resultArray = [];

    const findItem = items => {
      _.forEach(items, item => {
        if (_.includes(ids, item.key)) {
          resultArray.push({ name: item.name, id: item.key });
        }

        if (_.isArray(item.childs) && !_.isEmpty(item.childs)) {
          findItem(item.childs);
        }
      });
    };

    findItem(data);

    return resultArray;
  };
  useEffect(() => {
    setsourceList(campaignByLead ? resultForLeads(sources, campaignData.source) : result());
  }, [sources, searchSource, campaignData.source]);
  return (
    <div className="mt-2">
      <div className="flex flex-col gap-3">
        <div className="flex flex-row gap-2 items-center ">
          <span>Title</span>
          <Input
            className="w-1/3 ml-[3px]"
            value={item.title}
            onChange={e => handleChange(e.target.value, "title", index)}
            placeholder="Enter the title of the message"
          />
        </div>
        <div className="flex flex-row gap-2 items-center">
          <span>Send</span>
          <Input
            type="number"
            value={item.numericValue}
            min={0}
            onChange={e => handleChange(+e.target.value, "numericValue", index)}
            className="w-1/6"
          />{" "}
          <Select
            className="w-1/5"
            options={[
              {
                label: "Minutes",
                value: "MINUTES",
              },
              {
                label: "Hours",
                value: "HOURS",
              },
              {
                label: "Days",
                value: "DAYS",
              },
              {
                label: "Weeks",
                value: "WEEKS",
              },
              {
                label: "Months",
                value: "MONTHS",
              },
            ]}
            value={item.temporalValue}
            onChange={value => handleChange(value, "temporalValue", index)}
          />{" "}
          {index === 0 ? "After the lead comes in" : `After ${ordinalNumbers[index - 1]} message`}
        </div>
        <div className="flex flex-row gap-2 items-center w-1/4">
          <span>Repeat</span>
          <Switch
            checked={item.type === "REPEAT"}
            onChange={checked => handleChange(checked ? "REPEAT" : "ONCE", "type", index)}
          />
        </div>
        <div className="p-4 bg-white rounded-[4px]">
          <div className="flex flex-row items-center justify-between">
            <Input
              className="w-1/3"
              value={searchSource}
              onChange={e => setsearchSource(e.target.value)}
              prefix={<SearchOutlined />}
              placeholder="Search source here"
            />
            <Button onClick={() => settemplateDrawer({ ...templateDrawer, visible: true })}>
              Select Template
            </Button>
          </div>
          <Tabs
            activeKey={activeSourceTab}
            onChange={activeKey => {
              setactiveSourceTab(activeKey);
            }}
          >
            {searchSource === "" && (
              <Tabs.TabPane tab="All" key="all">
                <Form
                  internalIndex="all"
                  sourceId="all"
                  campaignData={campaignData}
                  index={index}
                  setCampaignData={setCampaignData}
                  key="all"
                  activeKeys={activeKeys}
                  setactiveKeys={setactiveKeys}
                />
              </Tabs.TabPane>
            )}
            {sourceList.map((item, internalIndex) => (
              <Tabs.TabPane key={internalIndex} tab={item.name}>
                <Form
                  internalIndex={internalIndex}
                  campaignData={campaignData}
                  index={index}
                  setCampaignData={setCampaignData}
                  key={internalIndex}
                  sourceId={item.key}
                  activeKeys={activeKeys}
                  setactiveKeys={setactiveKeys}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </div>
      {templateDrawer.visible && (
        <TemplateDrawer
          openTemplateDrawer={templateDrawer}
          setOpenTemplateDrawer={settemplateDrawer}
          onApply={textMessage => {
            if (activeSourceTab === "all") {
              setCampaignData(prev => {
                const temp = structuredClone(prev.messages);
                temp[index] = {
                  ...temp[index],
                  isChanged: true,
                };
                temp[index].campaignTemplates[0] = {
                  templateText: textMessage,
                  sourceId: "all",
                };
                return { ...prev, messages: temp };
              });
            } else {
              const obj = sourceList[+activeSourceTab];
              if (obj) {
                setCampaignData(prev => {
                  const temp = structuredClone(prev.messages);
                  temp[index] = {
                    ...temp[index],
                    isChanged: true,
                  };
                  temp[index].campaignTemplates[+activeSourceTab + 1] = {
                    templateText: textMessage,
                    sourceId: obj.key,
                  };
                  return { ...prev, messages: temp };
                });
              }
            }
          }}
        />
      )}
    </div>
  );
};
