import React from "react";

const SettingHeader = props => {
  const { headerTitle, top, children } = props;
  return (
    <div
      className={`flex border-b-4 px-4 headerBackground w-full justify-between items-centers py-2 sticky ${
        top || "top-0"
      } top-0 z-50`}
    >
      <p className="text-lg font-semibold">{headerTitle}</p>
      <div className="flex items-center gap-2">{children}</div>
    </div>
  );
};

export default SettingHeader;
