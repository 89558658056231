import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { message, Switch, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { uniq, uniqBy } from "lodash";
import { CREATE_TEAM, UPDATE_TEAM } from "../../../graphql/mutation";
import { GET_LOCATIONS, GET_ALL_USERS_TEAMS } from "../../../graphql/query";
import FilterDropdown from "../../../library/filterDropdown";
import FilterTextBox from "../../../library/filterTextbox";
import { closeDialog } from "../../../library/helpers";
import TextInput from "../../../library/textInput";
import { getLoaderStatus, setLoaderStatus } from "../../../redux/slices/loaderSlice";
import CustomButton from "../../commonComponents/button";
import CustomTable from "../../commonComponents/table";
import CustomTabs from "../../commonComponents/tabs";
import useLang from "../../../hooks/useLang";

const validateTeamInputs = team => {
  return team.name !== "" && team.leaderId !== "" && team.members && team.members.length;
};

const ModalTeamForm = props => {
  const {
    users,
    company,
    team: teamData,
    refreshTeams,
    handleLocatinFilter,
    handleCompanyRoleFilter,
    companyRolesFilter,
    handleFilterSelection,
    tabItem = "All",
  } = props;

  const [lang] = useLang();
  const [usersData, setUsersData] = useState(users);

  const dispatch = useDispatch();

  const confirmLoader = useSelector(state => getLoaderStatus(state));

  const searchInputRef = useRef(null);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [activePane, setActivePane] = useState("1");

  const [variables, setVariables] = useState({
    companyId: parseInt(company.id),
    isLocationFilter: false,
    allUser: true,
  });

  const [locationList, setLocationList] = useState([]);
  const [team, setTeam] = useState({ companyId: company.id, members: [] });
  const [teamMembers, setTeamMembers] = useState([]);

  const [userVariables, setUserVariables] = useState({
    companyId: parseInt(company.id),
    isLocationFilter: false,
    allUser: true,
    search: "",
  });

  useEffect(() => {
    if (teamData) {
      const selectedUserResult = usersData?.filter(o1 =>
        teamData?.members?.some(o2 => +o1.id === +o2.memberId)
      );
      setTeam({ ...teamData });
      setTeamMembers(selectedUserResult);
    }
    //  else {
    //   setTeam({ companyId: company.id, members: [] });
    //   console.log("callled====>", team);
    // }
  }, [teamData, usersData]);

  // eslint-disable-next-line no-unused-vars
  const { loading: teamLoader, refetch: refetchUsers } = useQuery(GET_ALL_USERS_TEAMS, {
    fetchPolicy: "network-only",
    variables: {
      ...userVariables,
    },
    onCompleted: res => {
      const selectedUserResult = res?.getUsers?.data?.filter(o1 =>
        teamData?.members?.some(o2 => +o1.id === +o2.memberId)
      );
      const unSelectedUserResult = res?.getUsers?.data?.filter(
        o1 => !teamData?.members?.some(o2 => +o1.id === +o2.memberId)
      );
      setUsersData(selectedUserResult.concat(unSelectedUserResult));
    },
  });

  // eslint-disable-next-line no-unused-vars
  const { data: resp } = useQuery(GET_LOCATIONS, {
    variables: { companyId: company?.id },
    onCompleted: res => {
      if (res?.getLocations?.length > 0) {
        const locationFilter = res.getLocations.map(location => {
          return {
            value: location.id,
            text: location.title,
          };
        });
        setLocationList([...locationFilter]);
      }
    },
  });

  const [createTeam] = useMutation(CREATE_TEAM, {
    onCompleted: () => refreshTeams(),
  });

  const [updateTeam] = useMutation(UPDATE_TEAM, {
    onCompleted: () => refreshTeams(),
  });

  const resetTeam = () => {
    setTeam({ companyId: company.id, members: [] });
  };

  const handleNameSearch = (searckKey, keyname) => {
    switch (keyname) {
      case "fullName":
        if (searckKey.length > 0) {
          setVariables({
            ...variables,
            search: searckKey,
          });
        } else {
          setVariables({ ...variables, search: "" });
        }
        break;
      default:
        break;
    }
  };

  const handleModelCloseDialog = () => {
    setVariables({
      companyId: parseInt(company.id),
      isLocationFilter: false,
      allUser: true,
      locationIds: [],
      companyRoles: [],
      search: "",
    });
    setIsCheckAll(prev => prev + 1);
    setActivePane("1");
    dispatch(setLoaderStatus({ open: false }));
    closeDialog("teamDialog");
  };

  const updateTeamField = (field, value) => {
    setTeam(prevState => {
      const newState = { ...prevState };
      newState[field] = value;
      // Improve this line
      if (field === "leaderId") {
        newState.leader = { id: value };
        const index = newState?.members?.findIndex(member => member.memberId === value);
        if (index === -1) {
          newState.members.push({ memberId: value });
        }
      }
      return newState;
    });
  };

  const submitTeam = async () => {
    // dispatch(setLoaderStatus({ open: true }));
    const toSaveTeam = { ...team, id: +team?.id };
    // Improve this line
    // toSaveTeam.members.forEach(m => {
    //   // eslint-disable-next-line no-underscore-dangle
    //   delete m?.__typename;
    //   delete m?.member;
    // });
    const { members } = toSaveTeam;
    const uniqMembers = uniq(
      members.map(item => +item.memberId),
      "memberId"
    ).map(item => {
      return { memberId: item };
    });
    if (validateTeamInputs(toSaveTeam)) {
      if (toSaveTeam.id) {
        await updateTeam({ variables: { ...toSaveTeam, members: uniqMembers } });
        handleModelCloseDialog();
        resetTeam();
      } else {
        await createTeam({ variables: { ...toSaveTeam, members: uniqMembers } });
        handleModelCloseDialog();
        resetTeam();
      }

      message.success(toSaveTeam.id ? lang.teamUpdated : lang.teamCreated);
    } else {
      message.error(lang.invalidFields);
    }
  };

  const handleActiveToggle = (checked, e, id) => {
    let currentMembers = team.members;
    if (checked) {
      // add to list
      currentMembers?.push({ memberId: id });
    } else {
      // remove from list
      currentMembers = currentMembers?.filter(u => +u.memberId !== +id);
    }

    setTeam(prevState => {
      const newState = { ...prevState };
      newState.members = currentMembers;
      return newState;
    });
  };

  const COLUMNS = [
    {
      title: lang.name,
      dataIndex: "fullName",
      key: "fullName",
      // defaultSortOrder: "ascend",
      // sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      filterDropdown: props => (
        <FilterTextBox
          {...props}
          isCheckAll={isCheckAll}
          searchKeyword={variables?.search || ""}
          searchInputRef={searchInputRef}
          confirm={e => handleNameSearch(e, "fullName")}
        />
      ),
    },
    {
      title: lang.copmanyRole,
      render: (value, record) => {
        return record?.companyRole?.name ? (
          <span>{record?.companyRole?.name || "--"}</span>
        ) : (
          <span>--</span>
        );
      },
      filters: companyRolesFilter,
      filterDropdown: props => (
        <FilterDropdown
          {...props}
          confirm={e => handleCompanyRoleFilter(e)}
          dataIndex="companyRole"
          isCheckAll={isCheckAll}
        />
      ),
    },
    {
      title: lang.locations,
      render: (value, record) => {
        return record?.location?.title ? (
          <span>{record?.location?.title || "--"}</span>
        ) : (
          <span>--</span>
        );
      },
      filters: locationList,
      filterDropdown: props => (
        <FilterDropdown
          {...props}
          isCheckAll={isCheckAll}
          confirm={e => handleLocatinFilter(e)}
          dataIndex="Location"
        />
      ),
    },
    {
      title: lang.selected,
      dataIndex: "isDisabled",
      key: "isDisabled",
      align: "center",
      render: (value, record) =>
        team.id ? (
          <Switch
            size="small"
            // disabled={record?.id === team.leaderId}
            disabled={record?.id === team.leaderId || tabItem !== "All"}
            checked={team.members.filter(u => +u?.memberId === +record?.id).length > 0}
            onChange={(checked, e) => handleActiveToggle(checked, e, parseInt(record?.id))}
            id={record?.id}
          />
        ) : (
          <Switch
            size="small"
            checked={team?.members?.filter(u => +u?.memberId === +record?.id).length > 0}
            onChange={(checked, e) => handleActiveToggle(checked, e, parseInt(record?.id))}
            id={record?.id}
            disabled={tabItem !== "All"}
          />
        ),
      filters: [
        { value: "selected", text: "Selected" },
        { value: "not_selected", text: "Not Selected" },
      ],
      filterDropdown: props => (
        <FilterDropdown
          {...props}
          isCheckAll={isCheckAll}
          confirm={e => handleFilterSelection(e)}
          dataIndex="Selected Users"
        />
      ),
    },
    {
      title: lang.teamLeader,
      render: (record, index) => {
        return (
          <div key={index}>
            <Switch
              size="small"
              checked={team.leader && team.leader.id === record?.id}
              onChange={() => updateTeamField("leaderId", record?.id)}
              disabled={tabItem !== "All"}
            />
          </div>
        );
      },
    },
  ];

  const TAB_ITEMS = [
    {
      label: lang.teamInfo,
      key: "1",
      children: (
        <>
          <div className="mb-2">{lang.teamName}</div>
          <TextInput
            placeholder={lang.enterValue}
            value={team.name}
            onChange={e => updateTeamField("name", e.target.value)}
          />
          <CustomButton
            className="my-4"
            btnText="Next"
            type="primary"
            handleClick={() => setActivePane("2")}
          />
        </>
      ),
    },
    {
      label: lang.members,
      key: "2",
      children: (
        <>
          <div className="mb-2 w-[50%]">
            <Input
              placeholder="Search..."
              onChange={e => {
                refetchUsers({
                  ...userVariables,
                  search: e.target.value,
                });
              }}
              allowClear
            />
          </div>
          <div className="overflow-y-auto h-[454px] relative" id="antdTable">
            <CustomTable
              // data={users}
              data={usersData}
              loading={{ spinning: false, tip: "" }}
              columns={COLUMNS}
              rowKey="id"
              pagination={false}
              getPopupContainer={() => document.getElementById("antdTable")}
            />
          </div>
          <div className="flex w-full items-center mt-4 justify-center">
            <CustomButton
              btnText={team.id ? lang.saveChanges : lang.createTeam}
              type="primary"
              loading={confirmLoader}
              handleClick={() => submitTeam()}
            />
          </div>
        </>
      ),
    },
  ];

  const TAB_ITEMS_FOR_MEMBERS = [
    {
      label: lang.members,
      key: "1",
      children: (
        <>
          <div className="mb-2 w-[50%]">
            <Input
              placeholder="Search..."
              onChange={e => {
                refetchUsers({
                  ...userVariables,
                  search: e.target.value,
                });
              }}
              allowClear
            />
          </div>
          <div className="overflow-y-auto h-[454px] relative" id="antdTable">
            <CustomTable
              data={teamMembers}
              loading={{ spinning: false, tip: "" }}
              columns={COLUMNS}
              rowKey="id"
              pagination={false}
              getPopupContainer={() => document.getElementById("antdTable")}
            />
          </div>
          {/* <div className="flex w-full items-center mt-4 justify-center">
            <CustomeButton
              btnText={team.id ? lang.saveChanges : lang.createTeam}
              type="primary"
              loading={confirmLoader}
              handleClick={() => submitTeam()}
            />
          </div> */}
        </>
      ),
    },
  ];

  return (
    <CustomTabs
      // tabItems={TAB_ITEMS}
      tabItems={tabItem === "All" ? TAB_ITEMS : TAB_ITEMS_FOR_MEMBERS}
      handleChange={key => setActivePane(key)}
      activeKey={activePane}
      defaultActiveKey="1"
    />
  );
};

export default ModalTeamForm;
