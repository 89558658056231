/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { message } from "antd";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { CREATE_LEAD_PHONE, DELETE_LEAD_PHONE, UPDATE_LEAD_PHONE } from "../../../graphql/mutation";
import { DEFAULT_COUNTRY } from "../../../config";
import DialogFooter from "../../commonComponents/dialogFooter";
import CustomLabel from "../../commonComponents/label";
import useLang from "../../../hooks/useLang";

const EditPhoneNumber = props => {
  const { phoneModal, getLeads, handleCancelBtn, onClose } = props;

  const [lang] = useLang();

  const [phoneNumbers, setPhonenumbers] = useState([]);
  const [phone, setphoneState] = useState("");
  const [confirmDeleteNumber, setconfirmDeleteNumber] = useState({
    state: false,
    data: {},
  });

  const [updateLeadPhone, { loading: updatePhoneLoading }] = useMutation(UPDATE_LEAD_PHONE);
  const [createLeadPhone, { loading: phoneLoading }] = useMutation(CREATE_LEAD_PHONE);
  const [deleteLeadPhone] = useMutation(DELETE_LEAD_PHONE);

  useEffect(() => {
    if (phoneModal?.data?.phoneNumbers) {
      setPhonenumbers([...phoneModal?.data?.phoneNumbers]);
    }
  }, [phoneModal?.data?.phoneNumbers]);

  const setphone = (key, e, index) => {
    if (index !== -1) {
      const temp = [...phoneNumbers];
      temp[index][key] = e;
      setPhonenumbers([...temp]);
    }
  };

  const save = async () => {
    if (phoneNumbers.length > 0) {
      if (phoneNumbers[0]?.phone) {
        if (phoneNumbers[0]?.phone && isPossiblePhoneNumber(phoneNumbers[0]?.phone)) {
          try {
            const res = await updateLeadPhone({
              variables: {
                id: parseInt(phoneNumbers[0]?.id),
                phone: phoneNumbers[0]?.phone,
                isSyncCheck: false,
                isPushCrm: false,
              },
            });
            if (res?.data?.updateLeadPhone?.statusCode === 200) {
              // refetchLeads();
              getLeads();
              message.success(res?.data?.updateLeadPhone?.message);
              onClose();
            } else {
              message.error(res?.data?.updateLeadPhone?.message);
            }
          } catch (err) {
            console.log("Error in updateLeadPhone", err);
            message.error(lang.somethingWentWrong);
          }
        } else {
          message.error(lang.enterValidPhone);
        }
      } else {
        setconfirmDeleteNumber({
          state: true,
          data: {
            id: parseInt(phoneNumbers[0]?.id),
            phone: "",
            isSyncCheck: false,
            isPushCrm: false,
          },
        });
      }
    } else {
      if (isPossiblePhoneNumber(phone)) {
        try {
          const res = await createLeadPhone({
            variables: {
              leadId: parseInt(phoneModal?.data?.id),
              phone: phone,
              isSyncCheck: false,
              isPushCrm: false,
            },
          });

          if (res?.data?.createLeadPhone?.statusCode !== 200) {
            message.error(res?.data?.createLeadPhone?.message);
          } else {
            message.success(res?.data?.createLeadPhone?.message);
            onClose();
            getLeads();
          }
        } catch (err) {
          console.log("Error in updateLeadPhone", err);
          message.error(lang.somethingWentWrong);
        }
      } else {
        message.error(lang.enterValidPhone);
      }
    }
  };

  const deleteNumber = () => {
    deleteLeadPhone({
      variables: {
        id: parseInt(confirmDeleteNumber?.data?.id),
        isPushCrm: confirmDeleteNumber?.data?.isPushCrm,
        isSyncCheck: confirmDeleteNumber?.data?.isSyncCheck,
      },
    }).then(res => {
      if (res?.data?.deleteLeadPhone?.statusCode !== 200) {
        message.error(res?.data?.deleteLeadPhone?.message);
      } else {
        message.success(res?.data?.deleteLeadPhone?.message);
        setconfirmDeleteNumber({ state: false, data: {} });
        getLeads();
      }
    });
  };

  return (
    <div>
      <CustomLabel label={phoneNumbers?.length > 1 ? lang.phoneNumbers : lang.phoneNumberLabel} />

      {phoneNumbers?.length > 0 ? (
        <PhoneInput
          defaultCountry={DEFAULT_COUNTRY}
          value={phoneNumbers[0]?.phone}
          placeholder={lang.phoneNumber}
          className="phoneInputCss"
          onChange={e => setphone("phone", e, 0)}
        />
      ) : (
        <PhoneInput
          defaultCountry={DEFAULT_COUNTRY}
          value={phone}
          placeholder={lang.phoneNumber}
          className="phoneInputCss"
          onChange={e => setphoneState(e)}
        />
      )}
      <DialogFooter
        confirmBtnText={lang.ok}
        loading={updatePhoneLoading || phoneLoading}
        handleCancel={handleCancelBtn}
        handleConfirm={save}
      />
    </div>
  );
};

export default EditPhoneNumber;
