import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Alert, Input, Select } from "antd";
import PhoneInput from "react-phone-number-input";

import TextInput from "../../../library/textInput";
import DialogFooter from "../../commonComponents/dialogFooter";
import { getLoaderStatus } from "../../../redux/slices/loaderSlice";
import { DEFAULT_COUNTRY, ServiceTypes } from "../../../config";
import useLang from "../../../hooks/useLang";

const PhoneBotForm = ({ editablePhone = null, saveData, locations, handleCancelBtn }) => {
  const [lang] = useLang();

  const confirmLoading = useSelector(state => getLoaderStatus(state));

  const [error, setError] = useState("");

  const [description, setDescription] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [type, setType] = useState("");
  const [phone, setPhone] = useState("");
  const [locationId, setLocation] = useState();
  const [accountSid, setAccountSid] = useState("");
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    if (editablePhone) {
      setServiceName(editablePhone?.serviceName || "");
      setDescription(editablePhone?.description || "");
      setPhone(editablePhone?.user?.phone || "");
      setType(editablePhone?.type);
      setLocation(String(editablePhone?.locationId || ""));
      setAccountSid(String(editablePhone?.twilioAccountSid || ""));
      setAuthToken(String(editablePhone?.twilioAuthToken || ""));
    }
  }, [editablePhone]);

  useEffect(() => {
    if ((locations || []).length > 0 && !editablePhone) {
      setLocation(locations[0].id);
    }
  }, [locations, editablePhone]);

  const handleUpdateClick = () => {
    if (isEmpty(serviceName)) {
      setError(lang.enterServiceName);
      return;
    }
    if (isEmpty(phone)) {
      setError(lang.enterPhoneNumber);
      return;
    }
    if (isEmpty(type)) {
      setError(lang.selectType);
      return;
    }
    // if (isEmpty(location)) {
    //   setError(lang.plzSelectLocation);
    //   return;
    // }
    if (isEmpty(accountSid)) {
      setError(lang.enterAccountSid);
      return;
    }
    if (isEmpty(authToken)) {
      setError(lang.enterAuthToken);
      return;
    }
    saveData({
      id: parseInt(editablePhone?.id) || null,
      serviceName,
      phone,
      type,
      description,
      locationId,
      twilioAccountSid: accountSid,
      twilioAuthToken: authToken,
    });
  };

  return (
    <div className="relative">
      {error && (
        <div className="w-full my-2">
          <Alert message={error} type="error" banner />
        </div>
      )}
      <div className="flex gap-3 items-end w-full">
        <div className="basis-1/2">
          <TextInput
            placeholder={lang.serviceName}
            name="serviceName"
            label={lang.serviceName}
            require
            className="w-full"
            labelClass="my-1 font-medium"
            value={serviceName}
            onChange={e => {
              setServiceName(e.target.value);
            }}
            maxLength={50}
          />
        </div>
        <div className="basis-1/2">
          <p className="font-medium">
            {lang.phoneNumber}
            <span className="text-red-500">*</span>
          </p>
          <PhoneInput
            international
            withCountryCallingCode
            countryCallingCodeEditable={false}
            defaultCountry={DEFAULT_COUNTRY}
            value={phone}
            placeholder={lang.phoneNumber}
            className="phoneInputCss"
            onChange={e => setPhone(e)}
          />
        </div>
      </div>
      <div className="w-full my-2">
        <TextInput
          placeholder={lang.accountSid}
          name="accountSid"
          label={lang.accountSid}
          labelClass="my-1 font-medium"
          require
          value={accountSid}
          onChange={e => {
            setAccountSid(e.target.value);
          }}
        />
      </div>
      <div className="w-full my-2">
        <TextInput
          placeholder={lang.authToken}
          name="authToken"
          label={lang.authToken}
          labelClass="my-1 font-medium"
          require
          value={authToken}
          onChange={e => {
            setAuthToken(e.target.value);
          }}
        />
      </div>

      <div className="w-full my-2">
        <p className="my-1 font-medium">
          {lang.type}
          <span className="text-red-500">*</span>
        </p>
        <Select
          className="w-full"
          onChange={setType}
          value={type}
          placeholder={lang.selectTypeLabel}
          showArrow
        >
          {ServiceTypes.map(e => (
            <Select.Option key={e.value} value={e.value}>
              {e.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="w-full my-2">
        <p className="my-1 font-medium">
          {lang.location}
          <span className="text-red-500">*</span>
        </p>
        <Select
          className="w-full"
          onChange={setLocation}
          value={locationId}
          placeholder={lang.selectLocation}
          showArrow
        >
          {(locations || []).map(item => (
            <Select.Option key={item?.id} value={item?.id}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="w-full my-2">
        <p className="my-1 font-medium">{lang.description}</p>
        <Input.TextArea
          placeholder={lang.description}
          rows={4}
          value={description}
          onChange={e => setDescription(e.target.value)}
          maxLength={300}
        />
      </div>
      <DialogFooter
        handleConfirm={handleUpdateClick}
        loading={confirmLoading}
        handleCancel={handleCancelBtn}
        confirmBtnText={editablePhone ? lang.update : lang.save}
      />
    </div>
  );
};

export default PhoneBotForm;
