import * as React from "react";

const CarIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#334155"
      stroke="#334155"
      strokeWidth={0.078}
      d="m17.87 8.766.01.023h.87a.586.586 0 0 1 0 1.172h-.665v6.289a1.21 1.21 0 0 1-1.21 1.21H15a1.21 1.21 0 0 1-1.21-1.21v-1.29H6.21v1.29A1.21 1.21 0 0 1 5 17.46H3.125a1.21 1.21 0 0 1-1.21-1.21V9.96H1.25a.586.586 0 0 1 0-1.17h.869l.01-.024L4.3 3.883a1.21 1.21 0 0 1 1.107-.719h9.188a1.21 1.21 0 0 1 1.106.719l2.17 4.883ZM5.407 4.336h-.025l-.01.023-1.945 4.375-.024.055H16.598l-.024-.055-1.945-4.375-.01-.023H5.406ZM5 16.289h.04V14.96H3.084v1.33H5Zm9.96-.039v.04h1.955v-1.33H14.96v1.29Zm1.915-2.46h.04V9.96H3.084v3.83h13.79Zm-12.46-1.915A.586.586 0 0 1 5 11.289h1.25a.586.586 0 1 1 0 1.172H5a.586.586 0 0 1-.586-.586Zm8.75 0a.586.586 0 0 1 .585-.586H15a.586.586 0 1 1 0 1.172h-1.25a.586.586 0 0 1-.586-.586Z"
    />
  </svg>
);
export default CarIcon;
