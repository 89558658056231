import React, { useState, forwardRef, useImperativeHandle, useEffect, useContext } from "react";
import { Switch, TimePicker, Divider, message, Collapse, Spin } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import range from "lodash/range";
import dayjs from "dayjs";
import { GET_COMPANY_WORK_HOURS, GET_LOCATIONS } from "../../graphql/query";
import { UPDATE_COMPANY_WORK_HOURS } from "../../graphql/mutation";
import { AccessPermissionAction } from "../../library/constants";
import { checkAccessPermission } from "../../library/helpers";
import useLang from "../../hooks/useLang";
import { getStatements } from "../../redux/slices/languageSlice";
import { Holidays } from "../userSettings/companyProfile/companyProfile";
import CommonHoliday from "../commonComponents/commonHoliday";
import { UserContext } from "../../amplify/authenticator-provider";

const days = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

// eslint-disable-next-line react/display-name
const WorkHours = forwardRef((props, ref) => {
  const { newCoSetup, setActiveTab, showTitle, setmisc, misc, uiStyle = "Drawer" } = props;
  const { user } = useContext(UserContext);
  const [lang] = useLang();

  const [workDays, setWorkDays] = useState({});
  const handleWorkStatus = () => {
    let dayStatus = {};
    days.forEach(e => {
      dayStatus = {
        ...dayStatus,
        [e]: {
          status: e !== "SUNDAY" && e !== "SATURDAY",
          startTime: dayjs("09:00", "HH:mm"),
          endTime: dayjs("09:00", "HH:mm").add(8, "hours"),
        },
      };
    });
    setWorkDays(dayStatus);
    return dayStatus;
  };

  const { data: resp } = useQuery(GET_LOCATIONS, {
    variables: { companyId: parseInt(props?.company?.id || 0) },
  });

  const currentLocation = (resp?.getLocations || []).find(
    el => el.id === parseInt(sessionStorage.getItem("currentLocationId") || 0)
  );

  const setLocationWorkHours = data => {
    if (data.length > 0) {
      let dayStatus = {};
      data.forEach(e => {
        dayStatus = {
          ...dayStatus,
          [e.weekDay]: {
            status: e.isWorkingDay,
            startTime: dayjs(e?.startTime || "09:00", "HH:mm"),
            endTime: dayjs(e?.endTime || "05:00", "HH:mm"),
          },
        };
      });
      setWorkDays({ ...dayStatus });
    } else {
      setWorkDays(handleWorkStatus());
    }
  };

  const { refetch: refreshWorkHours, loading: workingHoursLoader } = useQuery(
    GET_COMPANY_WORK_HOURS,
    {
      variables: {
        companyId: parseInt(props?.company?.id || 0),
        locationId: parseInt(props?.locationId || sessionStorage.getItem("currentLocationId") || 0),
      },
      onCompleted: res => {
        const data = res?.getCompanyWorkingHours || [];
        setLocationWorkHours(data);
      },
    }
  );

  useEffect(() => {
    if (props?.company) {
      refreshWorkHours();
    }

    if (props.locationId) {
      refreshWorkHours().then(resp => {
        const data = resp?.getCompanyWorkingHours || [];
        data.length > 0 && setLocationWorkHours(data);
      });
    }
  }, [newCoSetup, setActiveTab, showTitle, props?.company, props.locationId]);

  const [updateWorkingHours] = useMutation(UPDATE_COMPANY_WORK_HOURS);

  const handleStatusChange = (e, day) => {
    setWorkDays(prevState => {
      const newState = { ...prevState };
      newState[day].status = e;
      return newState;
    });
  };

  const handleTimeChange = (time, day, key) => {
    if (time) {
      setWorkDays(prevState => {
        const newState = { ...prevState };
        newState[day][key] = time;
        return newState;
      });
    }
  };

  const submitCompany = () => {
    const saveObj = [];
    let error = true;

    // eslint-disable-next-line no-restricted-syntax
    for (const dayName of days) {
      if (error) {
        const startTime = dayjs(workDays[dayName].startTime, "h:mm a");
        const endTime = dayjs(workDays[dayName].endTime, "h:mm a");
        if (
          workDays[dayName].status &&
          workDays[dayName].startTime.format("HH:mm") !==
            workDays[dayName].endTime.format("HH:mm") &&
          (!startTime.isAfter(endTime) || !endTime.isBefore(startTime))
        ) {
          saveObj.push({
            weekDay: dayName,
            isWorkingDay: workDays[dayName]?.status,
            startTime: workDays[dayName]?.startTime.format("HH:mm"),
            endTime: workDays[dayName]?.endTime.format("HH:mm"),
          });
        } else {
          saveObj.push({
            weekDay: dayName,
            isWorkingDay: workDays[dayName]?.status,
            startTime: workDays[dayName]?.startTime.format("HH:mm"),
            endTime: workDays[dayName]?.endTime.format("HH:mm"),
          });
          workDays[dayName].status && message.error(lang.startEndTimeErr, 4.0);
          if (workDays[dayName].status) {
            error = false;
            return;
          }
          workDays[dayName].status && props?.setEditCompany(true);
        }
      }
    }

    if (error && saveObj.length > 0) {
      let variables = {
        companyId: props?.company?.id,
        inputWorkingHours: saveObj,
      };
      if (!props?.newCoSetup) {
        variables = {
          ...variables,
          locationId: parseInt(sessionStorage.getItem("currentLocationId") || 0),
        };
      }
      updateWorkingHours({
        variables,
      }).then(resp => {
        if (resp?.data?.updateWorkingHours?.statusCode === 200) {
          try {
            refreshWorkHours();
          } catch (err) {
            //
          }

          if (!showTitle) {
            message.success(resp?.data?.updateWorkingHours?.message);
          }

          if (setActiveTab && newCoSetup) {
            setActiveTab("Sources");
          }
        } else {
          message.error(resp?.data?.updateWorkingHours?.message);
        }
      });
    }

    if (!error) {
      return false;
    }
    return true;
  };

  const canEditWorkingHours = checkAccessPermission(AccessPermissionAction.WORKING_HOUR_UPDATE);

  const disableRangeTime = (dayName, timeString) => {
    if (timeString === "endTime") {
      return {
        disabledHours: () => {
          const openingHour = workDays[dayName][timeString];
          return openingHour ? range(0, openingHour.hour()) : [];
        },
      };
    }
    if (timeString === "startTime") {
      return {
        disabledHours: () => {
          const openingHour = workDays[dayName][timeString];
          return openingHour ? range(0, openingHour.hour()) : [];
        },
        disabledMinutes: () => {
          const openingHour = workDays[dayName][timeString];
          const closingHour = workDays[dayName].endTime;
          return openingHour
            ? closingHour <= openingHour
              ? range(0, openingHour.minute() + 15)
              : []
            : [];
        },
      };
    }
  };

  useImperativeHandle(ref, () => ({
    saveWorkHours() {
      return submitCompany();
    },
  }));

  useEffect(() => {
    handleWorkStatus();
  }, []);

  return (
    <Spin spinning={workingHoursLoader} tip="Loading Working Hours...">
      <div className="px-4">
        {props?.showTitle && (
          <div>
            <p className="m-0 font-normal text-lg">
              {`${lang.workingHoursFor} ${currentLocation?.title || lang.defaultLocation}`}
            </p>
          </div>
        )}

        <div className="flex items-center w-full py-5">
          <p className="flex-1">{lang.day}</p>
          <p className="flex-1">{lang.openingHours}</p>
          <p className="flex-1">{lang.closeingHours}</p>
        </div>
        <Divider className="m-0 mb-1" />
        {days.map((dayName, index) => {
          return (
            <div className="flex items-center gap-2" key={index}>
              <div className="flex items-center flex-1">
                <Switch
                  size="small"
                  checked={workDays[dayName]?.status}
                  onChange={e => handleStatusChange(e, dayName)}
                  disabled={!props?.isEdit || !canEditWorkingHours}
                />
                <span className="pl-1">{dayName}</span>
              </div>
              <TimePicker
                className="flex-1 w-full my-1"
                use12Hours
                minuteStep={15}
                format="hh:mm a"
                value={dayjs(workDays[dayName]?.startTime)}
                onChange={time => handleTimeChange(time, dayName, "startTime")}
                onSelect={time => handleTimeChange(time, dayName, "startTime")}
                disabled={!workDays[dayName]?.status || !props?.isEdit || !canEditWorkingHours}
              />
              <TimePicker
                className="flex-1 w-full my-1"
                use12Hours
                minuteStep={15}
                format="hh:mm a"
                value={dayjs(workDays[dayName]?.endTime)}
                onChange={time => handleTimeChange(time, dayName, "endTime")}
                onSelect={time => handleTimeChange(time, dayName, "endTime")}
                disabled={!workDays[dayName]?.status || !props?.isEdit || !canEditWorkingHours}
                disabledTime={() => disableRangeTime(dayName, "startTime")}
              />
            </div>
          );
        })}
        {uiStyle === "Collapse" ? (
          <Collapse defaultActiveKey={1} expandIconPosition="right" className="mt-4 mb-12">
            <Collapse.Panel key={1} header="Holiday List">
              <Holidays edit={false} user={user} />
            </Collapse.Panel>
          </Collapse>
        ) : (
          <div className="w-max">
            <CommonHoliday misc={misc} setmisc={setmisc} />
          </div>
        )}
      </div>
    </Spin>
  );
});
export default WorkHours;
