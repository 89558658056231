import React from "react";
import { Tabs } from "antd";

const CustomTabs = props => {
  const {
    tabItems,
    className,
    tabSize,
    activeKey,
    handleChange,
    animated,
    defaultActiveKey,
    type,
    destroyInactiveTabPane,
    tabBarExtraContent,
  } = props;

  return (
    <Tabs
      size={tabSize}
      className={className}
      defaultActiveKey={defaultActiveKey}
      items={tabItems}
      activeKey={activeKey}
      onChange={handleChange}
      tabPosition="top"
      type={type}
      animated={animated}
      destroyInactiveTabPane={destroyInactiveTabPane}
      tabBarExtraContent={tabBarExtraContent}
    />
  );
};

CustomTabs.defaultProps = {
  animated: true,
  tabSize: "small",
  defaultActiveKey: "1",
  handleChange: () => {},
  type: "line",
  destroyInactiveTabPane: false,
};

export default CustomTabs;
