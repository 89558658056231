/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-autofocus */
import { CaretDownOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input, Popover } from "antd";
import { isEmpty, sortBy, uniq } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { SNIPPET_OPTIONS } from "../../library/constants";
import { formattextFn, getHighlightedText } from "../../library/utils";

export function RichTextEditorJSX({
  formData,
  setformData,
  changingKey = "",
  className = "min-h-[300px] p-2 hover:shadow-inner",
  width = "100%",
}) {
  const snippetRef = useRef(null);
  const [snippetOpen, setsnippetOpen] = useState(false);
  const [cursorPosition, setcursorPosition] = useState(null);
  const Snippets = ({ text, inputRef, setText }) => {
    const [search, setsearch] = useState("");
    const handleKeywords = value => {
      const output = [text.slice(0, cursorPosition), `${value} `, text.slice(cursorPosition)].join(
        ""
      );
      setText(output);
      setcursorPosition(cursorPosition + value.trim().length);
      if (inputRef) inputRef?.current?.focus();
    };
    return (
      <div className="flex flex-col p-2 gap-2 w-[300px]">
        <div id="header" className="flex flex-row items-center justify-between">
          <span className="text-lg font-[600] text-grey-700">Select snippet token</span>
          <CloseCircleOutlined
            className="cursor-pointer"
            onClick={() => snippetRef.current.click()}
          />
        </div>
        <div className="flex flex-row items-center">
          <Input
            value={search}
            onChange={e => setsearch(e.target.value)}
            placeholder="Search snippets"
          />
        </div>
        <div className="flex flex-col items-start gap-1 max-h-[150px] overflow-y-auto justify-start">
          {uniq(SNIPPET_OPTIONS)
            .filter(item => (isEmpty(search) ? item : item.includes(search)))
            .sort()
            .map((item, index) => (
              <span
                key={index}
                onClick={() => handleKeywords(item)}
                className="cursor-pointer hover:text-[#16A34A]"
              >
                {getHighlightedText(item, search)}
              </span>
            ))}
        </div>
      </div>
    );
  };
  const textRef = useRef(null);

  useEffect(() => {
    formattextFn(formData[changingKey]?.replace(/\n/g, "<br/>"));
  }, [formData]);
  const handleChange = event => {
    setformData({ ...formData, [changingKey]: event.target.value });
    setcursorPosition(event.target.selectionStart);
  };
  useEffect(() => {
    const ctl = document.getElementById("otto-prompt");
    if (ctl) ctl.setSelectionRange(cursorPosition, cursorPosition);
  }, [cursorPosition]);
  const handleInteraction = () => {
    const caretPosition = textRef.current.selectionStart;
    setcursorPosition(caretPosition);
  };

  const [mode, setmode] = useState("view");
  return (
    <div
      className="flex flex-col border-solid border-[1.5px] mt-3 border-grey-200"
      style={{ width: width }}
    >
      {mode === "view" ? (
        <div
          // onDoubleClick={() => setmode("edit")}
          onClick={() => {
            setmode("edit");
            textRef?.current?.focus();
          }}
          dangerouslySetInnerHTML={{
            __html: isEmpty(formData[changingKey])
              ? `<i>Add Template here</i>`
              : formattextFn(formData[changingKey]),
          }}
          className={`${className}  `}
        />
      ) : (
        <div className="relative w-full">
          <textarea
            onBlur={() => {
              console.log("Blur event triggered");
              setmode("view");
            }}
            value={formData && formData[changingKey]}
            id="otto-prompt"
            autoFocus
            ref={textRef}
            onChange={handleChange}
            onClick={handleInteraction}
            onKeyDown={handleInteraction}
            className={`${className}`}
            cols="40"
          />
          {/* <div className="absolute flex flex-row items-center gap-2 bottom-2 left-2">
                      <Button
                        onClick={() => {
                          formattextFn(formData.promptText);
                          setmode("view");
                        }}
                      >
                        <CheckIconJSX />
                      </Button>
                      <Button
                        onClick={() => {
                          setmode("view");
                          // setText(formattedText); // set it to initial value of text
                        }}
                      >
                        <CloseCircleOutlined />
                      </Button>
                    </div> */}
        </div>
      )}

      <div className="py-2.5 pl-3 bg-brand-50">
        <span className="cursor-pointer flex flex-row items-center gap-2 text-sm font-[600] text-brand-500">
          <Popover
            placement="topLeft"
            showArrow={false}
            className="otto-snippets-popover"
            trigger="click"
            content={
              <Snippets
                text={formData[changingKey]}
                inputRef={textRef}
                setText={value => setformData({ ...formData, [changingKey]: value })}
              />
            }
          >
            <span
              onClick={e => {
                e.stopPropagation();
              }}
              className="flex flex-row items-center gap-1"
              ref={snippetRef}
            >
              Personalize <CaretDownOutlined className="mt-0.5" />
            </span>
          </Popover>
        </span>
      </div>
    </div>
  );
}
