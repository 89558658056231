import dayjs from "dayjs";

const APP_ENVIRONMENT = process.env.GATSBY_APP_ENVIRONMENT || "dev";
// TODO: Externalize this configuration
const API_GATEWAY_ID =
  APP_ENVIRONMENT === "prod"
    ? "fcfcg7gyl1"
    : APP_ENVIRONMENT === "stage"
    ? "g9yl70gkjh"
    : "kowfh23cfd";
const API_GATWAY_URL = `https://${API_GATEWAY_ID}.execute-api.us-east-1.amazonaws.com/v1/getPresignedUrl`;

const Base64ToImage = (b64Data, contentType) => {
  const binary = atob(b64Data?.split(",")[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: contentType });
};

const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

export const s3uploader = async (
  files,
  filePath,
  handleLoader,
  locationId,
  user,
  lead,
  suffixSplitter,
  index = 0,
  isThumbnail = false
) => {
  let fileObj = files;

  if (isThumbnail) {
    const base64img = Base64ToImage(files[0], "image/png");
    const fileName = `1-thumbnail.png`;
    const file = blobToFile(base64img, fileName);
    fileObj = file;
  }

  try {
    handleLoader(true);
    const filename = fileObj.name;
    const fileExtension = filename.split(".").pop();
    const fileName = `${dayjs().utc().format("YYYYMMDD")}_${dayjs()
      .utc()
      .format("HHmmss")}_${locationId}_${user.id || 0}_${lead.id || 0}_${index}`;
    const fileFolder =
      fileObj?.type?.split("/")[0] === "image"
        ? "images"
        : fileObj.type.split("/")[0] === "video"
        ? "videos"
        : "documents";

    const apiUrl = `${API_GATWAY_URL}?filePath=${filePath}&fileName=${fileName}&fileExtension=${fileExtension}&fileFolder=${fileFolder}&contentType=${fileObj.type}`;

    const response = await fetch(apiUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
    const data = await response.json();

    // PUT request: upload file to S3
    await fetch(data.uploadURL, {
      method: "PUT",
      headers: {
        "Content-Type": fileObj.type,
      },
      body: fileObj,
    });

    handleLoader(false);

    return {
      url: data?.downloadUrl?.split("?")[0],
      filename: `${fileObj.name.split(".")[0]}.${fileExtension}`,
    };
  } catch (err) {
    console.log("Error in s3uploader", err.message);
    handleLoader(false);
  }
};
