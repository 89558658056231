import React from "react";
import useLang from "../../../hooks/useLang";

const PermissionInfo = props => {
  const { hasAutoAnalytics, hasAdExport, hasClm } = props;

  const [lang] = useLang();

  let PERMISSION_INFO = [
    {
      label: lang.companyAdmin,
      subLabel: lang.canEditCompanyInfo,
    },
    {
      label: lang.users,
      subLabel: lang.canCreateUserAndRoles,
    },
    {
      label: lang.teams,
      subLabel: lang.canManageTeams,
    },
    {
      label: lang.prospects,
      subLabel: lang.canActProspects,
    },
  ];

  if (hasAutoAnalytics) {
    PERMISSION_INFO = [
      ...PERMISSION_INFO,
      {
        label: lang.autoAnalytics,
        subLabel: lang.canViewAutoAnalytics,
      },
    ];
  }

  if (hasAdExport) {
    PERMISSION_INFO = [
      ...PERMISSION_INFO,
      {
        label: lang.adExports,
        subLabel: lang.canViewPersonalized,
      },
    ];
  }

  if (hasClm) {
    PERMISSION_INFO = [
      ...PERMISSION_INFO,
      {
        label: lang.lifeEvents,
        subLabel: lang.canViewLifeEvents,
      },
      {
        label: lang.engagements,
        subLabel: lang.canViewEngagements,
      },
    ];
  }

  return (
    <div>
      <h3>{lang.permissions}</h3>
      {PERMISSION_INFO.map((permission, index) => (
        <li key={index}>
          <span className="font-medium">{permission.label} -</span> {permission.subLabel}
        </li>
      ))}
    </div>
  );
};

export default PermissionInfo;
