import { useQuery } from "@apollo/client";
import { Form, Image, Input, Select } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { GET_VEHICLE_MAKES, GET_VEHICLE_MODELS } from "../../graphql/query";
import { currencies } from "../../library/constants";
import { formatPrice, vehicleFilter } from "../../library/utils";

export function YearMakeModelJSX({
  InterestDropdown,
  index,
  item,
  years,
  DeleteIcon,
  leadData,
  setleadData,
}) {
  const [search, setsearch] = useState({
    make: "",
    model: "",
  });
  const [vehicleMakeModels, setvehicleMakeModels] = useState({
    makes: [],
    models: [],
  });
  const { loading: makeLoader } = useQuery(GET_VEHICLE_MAKES, {
    variables: {
      search: search.make,
      page: 1,
      pageSize: 20,
    },
    onCompleted: res => {
      setvehicleMakeModels({ ...res, makes: res.getVehicleMakes.data });
    },
  });
  const { loading: modelLoader } = useQuery(GET_VEHICLE_MODELS, {
    variables: {
      makeName: leadData.vehiclesOfInterest[index].make,
      year: leadData.vehiclesOfInterest[index].year,
      search: search.model,
    },
    onCompleted: res => {
      setvehicleMakeModels({ ...res, models: res.getVehicleModels.data });
    },
  });
  return (
    <div className="w-full gap-1 flex flex-row justify-between">
      {InterestDropdown}
      <Form.Item
        name={`vehiclesOfInterest[${index}].year`}
        rules={[{ required: true, message: "Please select Year" }]}
        className="w-[15%]"
        label="Year"
      >
        <Select
          className="vehicle-selector"
          name={`vehiclesOfInterest[${index}].year`}
          value={item.year}
          onChange={value =>
            // setvehicles(prev => {
            //   const temp = [...prev];
            //   temp[index].year = value;
            //   return temp;
            // })
            setleadData(prev => {
              const temp = { ...prev };
              temp.vehiclesOfInterest[index].year = value;
              return temp;
            })
          }
        >
          {years.map((item, index) => (
            <Select.Option key={index} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={`vehiclesOfInterest[${index}].make`}
        rules={[{ required: true, message: "Please select vehicle make" }]}
        className="w-[30%]"
        label="Make"
      >
        <Select
          name={`vehiclesOfInterest[${index}].make`}
          showSearch
          loading={makeLoader}
          className="vehicle-selector"
          onSearch={value => setsearch({ ...search, make: value })}
          onChange={value => {
            setleadData(prev => {
              const temp = { ...prev };
              temp.vehiclesOfInterest[index].make = value;
              return temp;
            });
          }}
          value={item.make}
          disabled={!item.year}
        >
          {(vehicleMakeModels?.makes || []).map((item, index) => (
            <Select.Option key={index} value={item.MakeName}>
              {item.MakeName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={`vehiclesOfInterest[${index}].model`}
        rules={[{ required: true, message: "Please select vehicle model" }]}
        className="w-[50%]"
        label="Model"
      >
        <Select
          name={`vehiclesOfInterest[${index}].model`}
          loading={modelLoader}
          showSearch
          className="vehicle-selector"
          disabled={!item.make}
          onSearch={value => setsearch({ ...search, model: value })}
          onChange={value => {
            setleadData(prev => {
              const temp = { ...prev };
              temp.vehiclesOfInterest[index].model = value;
              return temp;
            });
          }}
          value={item.model}
        >
          {(vehicleMakeModels?.models || []).map((item, index) => (
            <Select.Option key={index} value={item.ModelName}>
              {item.ModelName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {DeleteIcon}
    </div>
  );
}

export function VINVehicleForm({
  InterestDropdown,
  index,
  item,
  DeleteIcon,
  leadData,
  setleadData,
}) {
  return (
    <div className="w-full gap-2 flex flex-row justify-between">
      {InterestDropdown}
      <Form.Item
        className="w-full"
        rules={[{ required: true, message: "Please enter vin number" }]}
        name={`vehiclesOfInterest[${index}].vin`}
        label="vin"
      >
        <Input
          className="vehicle-selector"
          name={`vehiclesOfInterest[${index}].vin`}
          onChange={e =>
            // setvehicles(prev => {
            //   const temp = [...prev];
            //   temp[index].vin = e.target.value;
            //   return temp;
            // })
            setleadData(prev => {
              const temp = { ...prev };
              temp.vehiclesOfInterest[index].vin = e.target.value;
              return temp;
            })
          }
          placeholder="Enter vin number"
        />
      </Form.Item>
      {DeleteIcon}
    </div>
  );
}
export function InventoryVehicleForm({
  InterestDropdown,
  index,
  vehicleInventoryLoader,
  vehicleInventoryList,
  DeleteIcon,
  setleadData,
}) {
  const [selectedVehicle, setselectedVehicle] = useState({});
  return (
    <div className="w-full gap-2 flex flex-row justify-between">
      {InterestDropdown}
      <Form.Item
        rules={[{ required: true, message: "Please select vehicle from inventory" }]}
        name={`vehiclesOfInterest[${index}].inventoryId`}
        className="w-full"
        label="Vehicle"
      >
        <Select
          showSearch
          onChange={value =>
            setleadData(prev => {
              const temp = { ...prev };
              temp.vehiclesOfInterest[index].inventoryId = value;
              return temp;
            })
          }
          className="vehicle-selector"
          name={`vehiclesOfInterest[${index}].inventoryId`}
          placeholder="Select vehicle from inventory"
          loading={vehicleInventoryLoader}
          filterOption={(input, option) => vehicleFilter(input, option)}
          onSelect={value =>
            setselectedVehicle((vehicleInventoryList || []).find(item => +item.id === +value))
          }
          style={{ height: "auto" }}
        >
          {vehicleInventoryList.map((item, index) => (
            <Select.Option
              value={item.id}
              key={index}
              label={`${item.year || ""} ${item.make || ""} ${item.model || ""}`}
              vehicle={item}
            >
              <div className="flex flex-row gap-2 items-center">
                <img
                  src={
                    (item?.vehicleInventoryMedias || [])[0]?.mediaUrl ||
                    "https://funnel-webapp-uploads.s3.amazonaws.com/prod/images/generic-car-not-available-image.png"
                  }
                  height="auto"
                  alt={`${item.year || ""} ${item.make || ""} ${item.model || ""}`}
                  width="40px"
                  className="rounded-full"
                />
                <div className="flex flex-col gap-0.5">
                  <span className="text-sm font-[600]">
                    {item.year || ""} {item.make || ""} {item.model || ""}
                  </span>
                  <span className="text-sm text-grey-700">
                    <span className="font-semibold">MSRP:</span>{" "}
                    {item?.msrp || item?.sellingPrice
                      ? `${
                          currencies?.find(currency => currency.code === item.currencyUnit)?.symbol
                        } ${formatPrice(item?.msrp || item?.sellingPrice) || "N/A"}`
                      : "Price not available"}
                  </span>
                  <span className="text-xs font[400] text-[#64748B]">{item.vin}</span>
                  <span className="text-sm text-grey-700">
                    <span className="font-semibold">Stock No</span>{" "}
                    <span>{item?.stockNumber || "N/A"}</span>
                  </span>
                  <span className="text-sm text-grey-700">
                    <span className="font-semibold">Model No</span>{" "}
                    <span>{item?.modelNumber || "N/A"}</span>
                  </span>
                  <span className="text-sm text-grey-700">
                    <span className="font-semibold">Color:</span>{" "}
                    <span>
                      {item?.exteriorColor || "N/A"}
                      <strong>(E)</strong>
                      {", "}
                    </span>
                    <span>
                      {item?.interiorColor || "N/A"}
                      <strong>(I)</strong>
                    </span>
                  </span>
                </div>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {!isEmpty(selectedVehicle) && (
        <div className="vehicle-images flex-row-centered items-center">
          <Image.PreviewGroup
            items={(selectedVehicle?.vehicleInventoryMedias || []).map(item => item.mediaUrl)}
          >
            <Image
              className="w-10 h-10 cursor-pointer "
              src={
                selectedVehicle?.vehicleInventoryMedias[0]?.mediaUrl ||
                "https://funnel-webapp-uploads.s3.amazonaws.com/prod/images/generic-car-not-available-image.png"
              }
            />
          </Image.PreviewGroup>
        </div>
      )}
      {DeleteIcon}
    </div>
  );
}
