import { Alert } from "antd";
import React from "react";

function CustomAlert(props) {
  const {
    message,
    type,
    className,
    description,
    banner,
    showIcon = false,
    icon = false,
    closable = false,
    onClose,
  } = props;
  return (
    <Alert
      className={className}
      description={description}
      message={message || "Something went wrong. Please contact support."}
      type={type}
      banner={banner}
      icon={icon}
      showIcon={showIcon}
      onClose={onClose}
      closable={closable}
    />
  );
}

export default CustomAlert;
