import * as React from "react";

const ConversationIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M18.109 14.635a6.249 6.249 0 0 0-4.861-8.964 6.25 6.25 0 1 0-11.356 5.214l-.599 2.095a1.094 1.094 0 0 0 1.352 1.352l2.095-.598c.632.31 1.312.511 2.012.596a6.25 6.25 0 0 0 8.508 3.154l2.096.598a1.094 1.094 0 0 0 1.351-1.352l-.598-2.095ZM4.807 12.44a.644.644 0 0 0-.172.023l-2.063.59.59-2.063a.625.625 0 0 0-.053-.469 4.996 4.996 0 1 1 1.997 1.998.62.62 0 0 0-.299-.08Zm12.031 2.3.59 2.063-2.063-.59a.625.625 0 0 0-.469.053 5.005 5.005 0 0 1-6.744-1.925A6.246 6.246 0 0 0 13.65 7.01a5 5 0 0 1 3.243 7.26.625.625 0 0 0-.054.47Z"
    />
  </svg>
);
export default ConversationIcon;
