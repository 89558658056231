import * as React from "react";

const MessageIcon2 = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      fill="#14B8A6"
      d="M15.188 3.375H2.813A1.125 1.125 0 0 0 1.688 4.5v11.25a1.114 1.114 0 0 0 .65 1.02 1.117 1.117 0 0 0 1.205-.168L5.8 14.625h9.387a1.125 1.125 0 0 0 1.124-1.125v-9a1.125 1.125 0 0 0-1.125-1.125Zm0 10.125H5.8c-.265 0-.522.093-.724.264l-.009.007-2.255 1.979V4.5h12.375v9Zm-9-5.625a.563.563 0 0 1 .562-.563h4.5a.563.563 0 1 1 0 1.125h-4.5a.563.563 0 0 1-.562-.562Zm0 2.25a.563.563 0 0 1 .562-.563h4.5a.563.563 0 1 1 0 1.126h-4.5a.563.563 0 0 1-.562-.563Z"
    />
  </svg>
);
export default MessageIcon2;
