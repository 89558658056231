/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    campaignFilters: [],
    userFilters: [],
    quickFilters: [],
    opportunityFilter: "",
    addMyConversation: true,
  },
  leadSort: "lastMessage",
  leads: [],
  leadScheduledMessages: [],
  selectedLeadId: null,
  selectedLead: {},
  leadCountChange: {},
  search: "",
  leadCenterFilters: {
    search: "",
  },
};
function moveElementToFirst(arr, n) {
  if (n >= 0 && n < arr.length) {
    const elementToMove = arr.splice(n, 1)[0];
    arr.unshift(elementToMove);
  } else {
    console.error("Invalid index provided.");
  }
}

const leadSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    setLeadsRedux(state, action) {
      state.leads = action.payload;
    },
    setCampaignFilters(state, action) {
      state.filters.campaignFilters = action.payload;
    },
    setSelectedLeadRedux(state, action) {
      state.selectedLead = action.payload;
    },
    pinLeadToTop(state, action) {
      state.selectedLead = action.payload;
      const findIndex = state.leads.findIndex(item => item.id === action.payload.id);
      if (findIndex !== -1) {
        // lead was found in the lead list itself
        moveElementToFirst(state.leads, findIndex);
      } else {
        // lead was not found, insert the new lead to first position
        state.leads.unshift(action.payload);
      }
    },

    setSearchRedux(state, action) {
      state.search = action.payload;
    },
    setSelectedLeadIdRedux(state, action) {
      state.selectedLeadId = action.payload;
    },
    setUserFilters(state, action) {
      state.filters.userFilters = action.payload;
    },
    addMessage(state, action) {
      const index = state.leads.findIndex(item => +item.id === +action.payload.id);
      if (index !== -1) {
        state.leads[index] = {
          ...state.leads[index],
          messages: [action.payload.messages],
        };
      }
    },
    setTeamFilters(state, action) {
      state.filters.teamFilters = action.payload;
    },
    setLeadSortOrder(state, action) {
      state.leadSort = action.payload;
    },
    addQuickFilter(state, action) {
      state.filters.quickFilters.push(action.payload);
    },
    removeQuickFilter(state, action) {
      state.filters.quickFilters = state.filters.quickFilters.filter(
        item => item !== action.payload
      );
    },
    replaceQuickFilters(state, action) {
      if (!action.payload.includes("MY_CONVERSATION")) {
        state.filters.addMyConversation = false;
      }
      state.filters.quickFilters = action.payload;
    },
    setOpportunityFilters(state, action) {
      state.filters.opportunityFilter = action.payload;
    },
    resetLeadFilters(state, action) {
      state.filters = {
        campaignFilters: [],
        userFilters: [],
        quickFilters: [],
        opportunityFilter: "",
        addMyConversation: false,
      };
    },
    setScheduledMessages(state, action) {
      state.leadScheduledMessages = action.payload;
    },
    updateScheduledMessageRedux(state, action) {
      const { index, message } = action.payload;
      console.log("LEad Slice", index, message);
      state.leadScheduledMessages[index] = { ...message };
    },
    addScheduledMessage(state, action) {
      const newMessage = action.payload;
      state.leadScheduledMessages.push(newMessage);
    },
    removeScheduledMessage: (state, action) => {
      const indexToRemove = action.payload;
      state.leadScheduledMessages.splice(indexToRemove, 1);
    },
    setLeadCountChange(state, action) {
      state.leadCountChange = action.payload;
    },
    deleteNoteRedux(state, action) {
      console.log(action);
      state.selectedLead = {
        ...state.selectedLead,
        leadNotes: state.selectedLead.leadNotes.filter(item => +item.id !== +action.payload),
      };
    },
    updateLeadNoteRedux(state, action) {
      const temp = state.selectedLead.leadNotes;
      const index = temp.findIndex(item => +item.id === +action.payload.id);
      console.log(index);
      if (index !== -1) {
        temp[index] = action.payload;
      }
      state.selectedLead = {
        ...state.selectedLead,
        leadNotes: temp,
      };
    },
    setLeadCenterFilters(state, action) {
      state.leadCenterFilters = {
        search: action.payload.search,
      };
    },
    resetLeadCenterFilters(state, action) {
      state.leadCenterFilters = {
        search: "",
      };
    },
  },
});

export const {
  setCampaignFilters,
  setUserFilters,
  setLeadsRedux,
  addMessage,
  setLeadSortOrder,
  addQuickFilter,
  removeQuickFilter,
  replaceQuickFilters,
  setOpportunityFilters,
  resetLeadFilters,
  setScheduledMessages,
  updateScheduledMessageRedux,
  addScheduledMessage,
  removeScheduledMessage,
  setLeadCountChange,
  setSelectedLeadRedux,
  setSelectedLeadIdRedux,
  pinLeadToTop,
  setSearchRedux,
  deleteNoteRedux,
  updateLeadNoteRedux,
  setLeadCenterFilters,
  resetLeadCenterFilters,
} = leadSlice.actions;

export const getUpdatedCountInfo = state => state.lead.leadCountChange;

export default leadSlice.reducer;
