/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { DownloadOutlined } from "@ant-design/icons";
import { Col, Image, Row, Skeleton } from "antd";
import React, { useState } from "react";
import useLang from "../../../../hooks/useLang";
import { formatBytes } from "../../../../library/utils";
import AntdIconV4 from "../../../../utils/antdIcon";
import { mediaDownloader } from "../../../../utils/mediaDownloader";
import CustomButton from "../../../commonComponents/button";
import { ViewMediaPopup } from "./ViewMediaPopup";
import "./image.css";
import MediaViewer from "./mediaViewer";
import MultipleMediaViewer from "./multipleMediaViewer";

const MultipleMediaView = props => {
  const { direction, messageDetails, media } = props;

  const [lang] = useLang();

  const [multipleFileModal, setmultipleFileModal] = useState(false);
  const [isModalOpen, setisModalOpen] = useState({
    visible: false,
    selectedMedia: null,
  });
  const [pdfModal, setpdfModal] = useState({
    visible: false,
    pdfUrl: "",
    mediaType: "",
  });
  const handleMediaUI = mediaInfo => {
    const mediaUI = (mediaFile, open = true) => {
      if (mediaFile.mediaType.includes("image") || mediaFile.mediaType.includes("gif")) {
        return (
          <div className="relative">
            <Image
              src={mediaFile.compressedMediaUrl || mediaFile.mediaUrl}
              className="w-[150px] h-[150px] cursor-pointer aspect-video"
              alt=""
              loading="lazy"
              placeholder={<Skeleton.Image className="w-[150px] h-[150px]" active />}
            />
            <AntdIconV4
              onClick={() => {
                mediaDownloader(mediaFile.mediaUrl, mediaFile?.mediaType?.split("/")[1]);
              }}
              type="DownloadOutlined"
              className="bg-[#000] text-white right-1 top-1 text-[31px] absolute rounded-[5px]"
            />
          </div>
        );
      } else if (mediaFile.mediaType.includes("video")) {
        return (
          <video
            src={mediaFile.compressedMediaUrl || mediaFile.mediaUrl}
            poster={mediaFile.compressedThumbnail || mediaFile.thumbnailUrl}
            className="w-[150px] h-[150px] cursor-pointer aspect-video"
            onClick={() =>
              setisModalOpen({
                ...isModalOpen,
                visible: true,
                selectedMedia: mediaFile,
              })
            }
            controls
          >
            <source src={mediaFile.compressedMediaUrl || mediaFile.mediaUrl} type="video/mp4" />
          </video>
        );
      } else {
        return direction !== "TO_LEAD" ? (
          <div className="flex items-center justify-between  text-white rounded-[15px] rounded-bl-none relative mr-0 ml-0">
            <div className="flex items-center">
              <CustomButton
                handleClick={() =>
                  mediaDownloader(mediaFile.mediaUrl, mediaFile.mediaType.split("/")[1])
                }
                tooltipText={lang.downloadDocument}
                shape="circle"
                icon={<DownloadOutlined className="text-black" />}
                className="bg-[#e6f7ff]"
              />
              <div className="ml-[18px] cursor-pointer">
                {mediaFile?.mediaUrl?.substring(mediaFile?.mediaUrl?.lastIndexOf("/") + 1)}
              </div>
            </div>
            <span className="text-[10px] text-[#fafafa] mt-auto ml-[10px]">
              {formatBytes(mediaFile?.size)}mb
            </span>
          </div>
        ) : (
          <>
            {" "}
            <div className="flex items-center justify-between text-white smaiChatSent rounded-[15px] rounded-br-[0px] relative">
              <div className="flex items-center">
                <CustomButton
                  shape="circle"
                  wantIcon
                  icon={<DownloadOutlined className="text-black" />}
                  className="bg-[#e6f7ff]"
                  handleClick={() =>
                    mediaDownloader(media[0].mediaUrl, media[0].mediaType.split("/")[1])
                  }
                  tooltipText={lang.downloadDocument}
                />
                <div className="ml-[18px] cursor-pointer">
                  {media[0].mediaUrl.substring(media[0].mediaUrl.lastIndexOf("/") + 1)}
                </div>
              </div>
              <span className="text-[10px] text-[#fafafa] mt-auto ml-[10px]">
                {formatBytes(media[0]?.size)}
                {lang.mb}
              </span>
            </div>
          </>
        );
      }
    };
    if (mediaInfo?.mediaType?.includes("image") || mediaInfo?.mediaType?.includes("video")) {
      return (
        <div className="relative py-2">
          <Image.PreviewGroup>
            {media.length === 2 ? (
              <Row>
                <Col span={12}>{mediaUI(media[0])}</Col>
                <Col span={12}>{mediaUI(media[1])}</Col>
              </Row>
            ) : media.length > 2 ? (
              <>
                <Row>
                  <Col span={12}>{mediaUI(media[0])}</Col>
                  <Col span={12}>{mediaUI(media[1])}</Col>
                </Row>
                <Row>
                  <Col span={media.length > 2 ? 12 : 24}>{mediaUI(media[2])}</Col>
                  {media.length > 3 && (
                    <Col span={12}>
                      <div
                        className="relative text-center text-white cursor-pointer"
                        // onClick={() => setmultipleFileModal(true)}
                      >
                        <div className="wrap">{mediaUI(media[3], false)}</div>
                        {media.length > 4 && (
                          <div
                            className="absolute top-[50%] left-[50%]"
                            style={{
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            <p className="text-[20px]">+{media.length - 3}</p>
                          </div>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
              </>
            ) : (
              ""
            )}
            {/* <AntdIconV4
              type="EyeOutlined"
              onClick={() => setmultipleFileModal(true)}
              className="bg-[#0000009e] text-white right-[18px] top-[15px] text-[31px] absolute rounded-[5px]"
            /> */}
          </Image.PreviewGroup>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-start gap-1">
          {media.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between text-white rounded-[15px] relative"
            >
              <div className="flex items-center">
                <CustomButton
                  shape="circle"
                  wantIcon
                  icon={<DownloadOutlined className="text-black" />}
                  className="bg-[#e6f7ff]"
                  handleClick={() => mediaDownloader(item.mediaUrl, item.mediaType.split("/")[1])}
                  tooltipText={lang.downloadDocument}
                />
                <span
                  onClick={() =>
                    setpdfModal({ visible: true, pdfUrl: item.mediaUrl, mediaType: item.mediaType })
                  }
                  className="ml-[18px] cursor-pointer"
                >
                  {item?.mediaUrl?.substring(item?.mediaUrl?.lastIndexOf("/") + 1)}
                </span>
              </div>
              <span className="text-[10px] text-[#fafafa] mt-auto ml-[10px]">
                {formatBytes(item?.size)}
              </span>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div
      className={`flex items-end ${direction === "TO_LEAD" ? "justify-end" : "justify-start"}  p-0`}
    >
      <div className="flex flex-col">
        <Image.PreviewGroup>
          {media && media.length > 0 && handleMediaUI(media[0])}
        </Image.PreviewGroup>
      </div>
      {multipleFileModal && (
        <MultipleMediaViewer
          isModalOpen={multipleFileModal}
          handleCancel={() => setmultipleFileModal(false)}
          media={media[0]}
          allMedia={media}
          messageDetails={messageDetails}
        />
      )}
      {isModalOpen.visible && (
        <MediaViewer
          isModalOpen={isModalOpen.visible}
          handleCancel={() => setisModalOpen({ ...isModalOpen, visible: false })}
          media={isModalOpen.selectedMedia}
          messageDetails={messageDetails}
        />
      )}
      {pdfModal.visible && <ViewMediaPopup pdfModal={pdfModal} setpdfModal={setpdfModal} />}
    </div>
  );
};

export default MultipleMediaView;
