/* eslint-disable react/destructuring-assignment */
import * as React from "react";

const SendIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill={props.color || "#64748B"}
      d="M17.49 8.906 4.365 1.415a1.25 1.25 0 0 0-1.792 1.5l2.422 7.069v.015l-2.422 7.084a1.25 1.25 0 0 0 1.796 1.503l13.118-7.504a1.25 1.25 0 0 0 .004-2.179l-.001.003ZM3.75 17.5v-.007l2.355-6.868h4.52a.625.625 0 0 0 0-1.25H6.111L3.755 2.509 3.75 2.5l13.125 7.487L3.75 17.5Z"
    />
  </svg>
);
export default SendIcon;
