/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-syntax */
import { Menu } from "antd";
import dayjs from "dayjs";
import _, { isNaN } from "lodash";
import isEmpty from "lodash/isEmpty";
import CryptoJS from "crypto-js";
import isUndefined from "lodash/isUndefined";
import React from "react";
import styled from "styled-components";

const numWords = [
  "",
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelvth",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
];

export const createMenu = items => (
  <Menu>
    {items.map(([text, value]) => (
      <Menu.Item key={value}>{text}</Menu.Item>
    ))}
  </Menu>
);

export const parseTimestamp = timestamp => {
  const date = timestamp;
  // const date = dayjs('17/09/2020 15:20:00', 'DD/MM/YYYY HH:mm:ss');
  const now = dayjs();
  const months = now.diff(date, "months");
  if (months > 0) {
    // return months === 1 ? `${months} month ago` : `${months} months ago`;
    return `${dayjs(timestamp).format("MM/DD/YYYY")}`;
  }
  const days = now.diff(date, "days");
  if (days > 0) {
    return days === 1 ? `${days} day ago` : `${days} days ago`;
  }
  const hours = now.diff(date, "hours");
  if (hours > 0) {
    return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
  }
  const minutes = now.diff(date, "minutes");
  if (minutes > 0) {
    return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
  }
  return `${now.diff(date, "seconds")} seconds ago`;
};

export const storeStorageData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getStorageData = key => {
  return JSON.parse(localStorage.getItem(key));
};

export const convertUTCDate = custdate => {
  const mydate = dayjs(custdate);
  if (mydate.isValid()) {
    return mydate.utc().format();
  }
  return custdate;
};
/**
 *
 * @param {dayjs} custdate Date to be parsed, dayjs Object
 * @param {String} format Date Format, Defaults to "MM/DD/YYYY"
 * @param {String} timezone Required Timezone conversion, Defaults to Local
 * @returns {String} Formated Local Date
 */
export const convertUtcToLocal = (custdate, format = "MM/DD/YYYY", timezone = null) => {
  const mydate = dayjs.utc(custdate);
  if (mydate.isValid()) {
    return timezone ? mydate.tz(timezone).format(format) : mydate.local().format(format);
  }
  return custdate;
};

export const numToWord = number => {
  return numWords[number];
};

export const parseLocation = item => {
  let address = " ";
  if (item?.addresses && item?.addresses[0] != null) {
    if (
      !isUndefined(item?.addresses[0]?.addressLine1) &&
      item?.addresses[0]?.addressLine1 != null &&
      !isEmpty(item?.addresses[0]?.addressLine1)
    ) {
      address = item?.addresses[0]?.addressLine1;
    }
    if (
      !isUndefined(item?.addresses[0]?.city) &&
      item?.addresses[0]?.city != null &&
      !isEmpty(item?.addresses[0]?.city)
    ) {
      address = `${address}, ${item?.addresses[0]?.city}`;
    }
    if (
      !isUndefined(item?.addresses[0]?.state) &&
      item?.addresses[0]?.state != null &&
      !isEmpty(item?.addresses[0]?.state)
    ) {
      address = `${address}, ${item?.addresses[0]?.state}`;
    }
    if (
      !isUndefined(item?.addresses[0]?.country) &&
      item?.addresses[0]?.country != null &&
      !isEmpty(item?.addresses[0]?.country)
    ) {
      address = `${address}, ${item?.addresses[0]?.country}`;
    }
    if (
      !isUndefined(item?.addresses[0]?.postalCode) &&
      item?.addresses[0]?.postalCode != null &&
      !isEmpty(item?.addresses[0]?.postalCode)
    ) {
      address = `${address}, ${item?.addresses[0]?.postalCode}`;
    }
  } else {
    address = "N/A";
  }
  address = address.indexOf(",") === 1 ? address.replace(/,/, "") : address;

  return address;
};

export const Spacer = styled.div`
  > * {
    margin-right: ${props => (props.size ? `${props.size}` : "1rem")};
    &:last-child {
      margin-right: 0;
    }
  }
`;

/**
 *
 * @param {String} TEXT
 * @returns  ```Text``` with first letter capital
 */
export const firstUpperCaseWordParser = TEXT => {
  if (TEXT == null) {
    return null;
  }
  try {
    return `${TEXT[0].toUpperCase()}${TEXT.slice(1).toLowerCase()}`.replaceAll("_", " ");
  } catch (err) {
    console.log(err.message);
  }
};

export function formatBytes(bytes) {
  if (bytes === 0) {
    return "0 B";
  }

  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const base = 1024;
  const exponent = Math.floor(Math.log(Math.abs(bytes)) / Math.log(base));

  const value = parseFloat((bytes / base ** exponent).toFixed(2));
  const unit = units[exponent];

  return bytes ? `${value} ${unit}` : "";
}

export const getDifferedState = async setState => {
  let differedState;
  await new Promise(resolve =>
    // eslint-disable-next-line no-promise-executor-return
    setState(data => {
      differedState = data;
      resolve();
      return data;
    })
  );
  return differedState;
};
export const getGenericFilterId = (genericList, mode) => {
  try {
    let obj = {};
    console.log(genericList);
    switch (mode) {
      case 0:
        obj = genericList.find(item => item.code === "LEAD");
        return obj?.id;
      case 1:
        obj = genericList.find(item => item.code === "LEAD_OPPORTUNITY");
        return obj?.id;
      default:
        break;
    }
  } catch (err) {
    console.log(err);
  }
};

export const applicationUseCaseMapping = {
  ACTIVITY_CENTER: "ACTIVITY_CENTER",
  CAMPAIGN: "CAMPAIGN",
  REVIEW_SALES_RESPONSE: "REVIEW_SALES_RESPONSE",
  REVIEW_SERVICE_RESPONSE: "REVIEW_SERVICE_RESPONSE",
  CUSTOMER_REVIEW_SUGGESTION: "CUSTOMER_REVIEW_SUGGESTION",
  ENGAGEMENT_MESSAGE_SUGGESTION: "ENGAGEMENT_MESSAGE_SUGGESTION",
  ENGAGEMENT_CONVERSATION_SUMMARY: "ENGAGEMENT_CONVERSATION_SUMMARY",
  ENGAGEMENT_CUSTOMER_SUMMARY: "ENGAGEMENT_CUSTOMER_SUMMARY",
  PROSPECT_MESSAGE_SUGGESSTION: "PROSPECT_MESSAGE_SUGGESSTION",
};

export const getApplicationUseCaseId = (useCases, useCase) => {
  const obj = useCases.find(item => item.code === useCase);
  return obj;
};
export function getParentIds(data, targetKey, parentIds = []) {
  for (const item of data) {
    if (item.key === targetKey) {
      return [...parentIds, targetKey];
    }

    if (item.childs && item.childs.length > 0) {
      const result = getParentIds(item.childs, targetKey, [...parentIds, item.key]);
      if (result.length > 0) {
        return result;
      }
    }
  }

  return [];
}

export function formatPrice(number) {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return "Invalid Number";
  }

  // Convert the number to a string and split it into integer and decimal parts
  const parts = number.toFixed(2).toString().split(".");

  // Add commas as thousands separators to the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Join the integer and decimal parts with a period
  return parts.join(".");
}
/**
 *
 * @param {String} text
 * @param {String} highlight
 * @returns
 */
export const getHighlightedText = (text, highlight) => {
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {parts?.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <strong
            key={i}
            style={{
              color: "magenta",
            }}
          >
            {part}
          </strong>
        ) : (
          part
        )
      )}
    </span>
  );
};
export const parseChildSources = (sources, values) => {
  const findPath = (arr, key) => {
    const result = [];

    const recursiveSearch = (node, currentPath) => {
      const newPath = currentPath.concat(node.key);

      if (node.key === key) {
        result.push(newPath);
      }

      if (_.isArray(node.childs)) {
        for (const child of node.childs) {
          recursiveSearch(child, newPath);
        }
      }
    };

    for (const node of arr) {
      recursiveSearch(node, []);
    }

    return result;
  };

  const result = _.flatten(values.map(key => findPath(sources, key)));
  return result;
};

export const formattextFn = text => {
  const regex = /{([^}]+)}/g;
  const formatted = (text?.replace(/</g, "&lt;").replace(/>/g, "&gt;") || "").replace(
    regex,
    match =>
      `<span style="background:#F0FDF4; color:#16A34A;" class="bg-[#F0FDF4] text-[#16A34A] font-[600]">${match}</span>`
  );
  return formatted?.replace(/\n/g, "<br/>");
};

export const prioritizePhones = (data, crmIntegration) => {
  const sortedData = _.sortBy(data, item => {
    switch (item.phoneType) {
      case "MOBILE":
        return 1; // Highest priority
      case "WORK":
        return 2; // Medium priority
      case crmIntegration === "DS" ? "OTHER" : "Home":
        return 3; // Lowest priority
      default:
        return 4; // Default priority if type is not recognized
    }
  });

  const selectedPhone = _.find(sortedData, item => !_.isEmpty(item.phone));
  return selectedPhone ? selectedPhone.phone : null;
};

export const encryptData = text => {
  try {
    const encrypted = CryptoJS.AES.encrypt(`${text}`, "secret").toString();
    console.log(encrypted);
    return encrypted;
  } catch (err) {
    console.log(err);
  }
};
export const decryptData = encryptedText => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedText, "secret");
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  } catch (err) {
    console.log(err);
  }
};

export const vehicleFilter = (input, option) => {
  try {
    const lowercasedInput = (input || "").toLowerCase();
    const vehicle = option?.vehicle || {};
    const fieldsToCheck = [
      option?.label,
      vehicle.vin,
      vehicle.exteriorColor,
      vehicle.interiorColor,
      vehicle.stockNumber,
      vehicle.msrp?.toString(),
      vehicle.modelNumber?.toString(),
      vehicle.make,
      vehicle.model,
      vehicle.year,
    ];

    return fieldsToCheck.some(field => (field || "").toLowerCase().includes(lowercasedInput));
  } catch (e) {
    console.error("ERROR:::", e);
    return false;
  }
};
