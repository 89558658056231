import * as React from "react";

const ImageIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#334155"
      stroke="#334155"
      strokeWidth={0.078}
      d="M16.914 4.375v-.04H3.085V11.765l.068-.067L6.33 8.52a1.21 1.21 0 0 1 1.712 0l3.437 3.438.028.027.027-.027 1.563-1.563-.028-.028.028.028a1.21 1.21 0 0 1 1.713 0l2.036 2.036.067.067V4.375ZM3.097 13.41l-.011.011V15.665h10.446l-.067-.068-6.25-6.25-.027-.027-.028.027-4.063 4.063Zm13.778 2.254h.04v-1.51l-.012-.011-2.92-2.92-.027-.028-.028.027-1.563 1.563-.027.027.027.028 2.813 2.813.011.011h1.686Zm-13.75-12.5h13.75a1.21 1.21 0 0 1 1.21 1.211v11.25a1.21 1.21 0 0 1-1.21 1.21H3.125a1.21 1.21 0 0 1-1.21-1.21V4.375a1.21 1.21 0 0 1 1.21-1.21Zm8.164 4.649a.899.899 0 1 1 1.797 0 .899.899 0 0 1-1.797 0Z"
    />
  </svg>
);
export default ImageIcon;
