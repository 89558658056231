import { Button, Form, Input } from "antd";
import React from "react";
import PhoneInput from "react-phone-number-input";
import "./leadCenter.css";
import useCrmIntegration from "../../hooks/useCrmIntegration";
import { toTitleCase } from "../../utils";

export default function LeadCustomerInformationForm({ leadData, setleadData, mode, form, loader }) {
  const crmIntegration = useCrmIntegration();

  const handleChangePhone = (e, key) => {
    setleadData(prev => {
      const temp = structuredClone(prev);
      const index = temp.phones.findIndex(item => toTitleCase(item.phoneType) === key);
      if (index !== -1)
        temp.phones[index] = {
          ...temp.phones[index],
          phone: e,
          phoneType: key,
        };
      else {
        temp.phones.push({
          phone: e,
          phoneType: key,
        });
      }
      return temp;
    });
  };
  const getPhoneValue = key => {
    // console.log("LeadCustomerInformationForm:getPhoneValue:key", key, "leadData", leadData);
    return leadData.phones.find(item => toTitleCase(item.phoneType) === key)?.phone;
  };
  return (
    <div className="flex flex-wrap ">
      <span className="bg-[#F8FAFC] p-2 w-full text-sm font-[600] text-grey-700 ">
        Customer Information
      </span>
      <div className="flex justify-between w-full flex-wrap">
        <Form.Item
          className="w-[48%]"
          name="firstName"
          rules={[{ required: true, message: "First name is required" }]}
          label="First Name"
        >
          <Input
            name="firstName"
            value={leadData.firstName}
            onChange={e => setleadData({ ...leadData, firstName: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          className="w-[48%]"
          name="lastName"
          rules={[{ required: true, message: "Last name is required" }]}
          label="Last Name"
        >
          <Input
            name="lastName"
            value={leadData.lastName}
            onChange={e => setleadData({ ...leadData, lastName: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          className="w-[100%]"
          name="email"
          rules={[
            // { required: true, message: "Email address is required" },
            { type: "email", message: "Email is is invalid" },
          ]}
          label="Email Address"
        >
          <Input
            name="email"
            value={leadData.email}
            onChange={e => setleadData({ ...leadData, email: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          className="w-[48%]"
          name="Mobile"
          rules={[{ required: true, message: "Primary Phone number is required" }]}
          label="Phone number (Mobile)"
        >
          <PhoneInput
            value={getPhoneValue("Mobile")}
            onChange={e => handleChangePhone(e, "Mobile")}
            className="lead-form-phone"
            name="Mobile"
          />
        </Form.Item>
        <Form.Item
          className="w-[48%]"
          name="Work"
          // rules={[{ required: true, message: "Work Phone number is required" }]}
          label="Phone number (Work)"
        >
          <PhoneInput
            value={getPhoneValue("Work")}
            onChange={e => handleChangePhone(e, "Work")}
            className="lead-form-phone"
            name="Work"
          />
        </Form.Item>
        <Form.Item
          className="w-[48%]"
          name={crmIntegration === "DS" ? "Other" : "Home"}
          // rules={[{ required: true, message: "Home Phone number is required" }]}
          label={crmIntegration === "DS" ? "Phone number (Other)" : "Phone number (Home)"}
        >
          <PhoneInput
            value={getPhoneValue(crmIntegration === "DS" ? "Other" : "Home")}
            onChange={e => handleChangePhone(e, crmIntegration === "DS" ? "Other" : "Home")}
            className="lead-form-phone"
            name={crmIntegration === "DS" ? "Other" : "Home"}
          />
        </Form.Item>
        <Form.Item
          className="w-full"
          name="addressLine1"
          // rules={[{ required: true, message: "Primary Address is required" }]}
          label="Address Line 1"
        >
          <Input
            name="addressLine1"
            value={leadData.addressLine1}
            onChange={e => setleadData({ ...leadData, addressLine1: e.target.value })}
          />
        </Form.Item>
        <Form.Item className="w-full" name="addressLine2" label="Address Line 2">
          <Input
            name="addressLine2"
            value={leadData.addressLine2}
            onChange={e => setleadData({ ...leadData, addressLine2: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          className="w-full"
          name="city"
          // rules={[{ required: true, message: "City is required" }]}
          label="City"
        >
          <Input
            name="city"
            value={leadData.city}
            onChange={e => setleadData({ ...leadData, city: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          className="w-[48%]"
          name="state"
          // rules={[{ required: true, message: "State is required" }]}
          label="State"
        >
          <Input
            name="state"
            value={leadData.state}
            onChange={e => setleadData({ ...leadData, state: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          className="w-[48%]"
          name="postalCode"
          // rules={[{ required: true, message: "Postal Code is required" }]}
          label="Postal Code"
        >
          <Input
            type="number"
            name="postalCode"
            value={leadData.postalCode}
            onChange={e => setleadData({ ...leadData, postalCode: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          className="w-full"
          name="country"
          // rules={[{ required: true, message: "Country is required" }]}
          label="Country"
        >
          <Input
            name="country"
            value={leadData.country}
            onChange={e => setleadData({ ...leadData, country: e.target.value })}
          />
        </Form.Item>
      </div>
      {mode === "EDIT" && (
        <div className="bg-[#F8FAFC] mt-2 w-full flex justify-end gap-2 p-4 ">
          <Button loading={loader} type="primary" onClick={() => form.submit()}>
            Save Customer Info
          </Button>
        </div>
      )}
    </div>
  );
}
