import { getCurrentDateTimeUTC } from "./dateTimes";

const APP_ENVIRONMENT = process.env.GATSBY_APP_ENVIRONMENT || "prod";
// TODO: Externalize this configuration
const API_GATEWAY_ID =
  APP_ENVIRONMENT === "prod"
    ? "fcfcg7gyl1"
    : APP_ENVIRONMENT === "stage"
    ? "g9yl70gkjh"
    : "kowfh23cfd";
const API_GATWAY_URL = `https://${API_GATEWAY_ID}.execute-api.us-east-1.amazonaws.com/v1/getPresignedUrl`;

export const s3LogoUploader = async (
  files,
  filePath,
  fileNamePrefix,
  handleLoader,
  isThumbnail = false
) => {
  try {
    if (filePath == null) {
      filePath = APP_ENVIRONMENT;
    }
    const fileObj = files;
    const fileExtension = fileObj.type.split("/")[1];
    let fileName = fileObj.name.split(".")[0];
    if (fileNamePrefix != null) {
      fileName = `${fileNamePrefix}_${fileName}`;
    }
    const currentDateTimeUTC = getCurrentDateTimeUTC();
    fileName = `${currentDateTimeUTC}_${fileName}`;
    const fileFolder =
      fileObj.type.split("/")[0] === "image"
        ? "images"
        : fileObj.type.split("/")[0] === "video"
        ? "videos"
        : "documents";
    const apiUrl = `${API_GATWAY_URL}?filePath=${filePath}&fileName=${fileName}&fileExtension=${fileExtension}&fileFolder=${fileFolder}&contentType=${fileObj.type}`;
    const response = await fetch(apiUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
    const data = await response.json();
    // PUT request: upload file to S3
    const result = await fetch(data.uploadURL, {
      method: "PUT",
      headers: {
        "Content-Type": fileObj.type,
      },
      body: fileObj,
    });
    return data?.downloadUrl?.split("?")[0];
  } catch (err) {
    console.error(err);
  }
};
