/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { createMigrate, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import { getPersistConfig } from "redux-deep-persist";
import { leadMigrations, migrations, reviewMigrations } from "./migrations";
import apiCacheReducer from "./slices/apiCacheSlice";
import drawerReducer from "./slices/drawerSlice";
import mobileViewReducer from "./slices/isMobile";
import languageReducer from "./slices/languageSlice";
import leadSlice from "./slices/leadSlice";
import loaderReducer from "./slices/loaderSlice";
import locationReducer from "./slices/locationSlice";
import reviewSlice from "./slices/reviewSlice";
import userSlice from "./slices/userSlice";
import aiSlice from "./slices/aiSlice";
import appointmentSlice from "./slices/appointmentSlice";
import opportunitySlice from "./slices/opportunitySlice";
import promptSlice from "./slices/promptSlice";
import campaignSlice from "./slices/campaignSlice";

const persistConfig = {
  key: "root",
  storage,
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [
    encryptTransform({
      secretKey: "my-super-secret-key",
      onError(error) {
        console.log("REDUX STORE : ", error);
      },
    }),
  ],
};
const leadPersistConfig = {
  key: "lead-persist-key",
  storage,
  whitelist: ["filters", "leadSort"],
  version: 1,
  migrate: createMigrate(leadMigrations, { debug: false }),
  transforms: [
    encryptTransform({
      secretKey: "leads-key",
      onError(error) {
        console.log("REDUX STORE : ", error);
      },
    }),
  ],
};
const reviewPersistConfig = {
  key: "review-persist-key",
  storage,
  whitelist: ["filters"],
  version: 0,
  migrate: createMigrate(reviewMigrations, { debug: false }),
  transforms: [
    encryptTransform({
      secretKey: "review-key",
      onError(error) {
        console.log("REDUX STORE : ", error);
      },
    }),
  ],
};
const appointmentPersistConfig = getPersistConfig({
  key: "appointment-persist-key",
  storage,
  blacklist: ["filters.page", "filters.pageSize", "filters.range"],
  rootReducer: appointmentSlice,
  transforms: [
    encryptTransform({
      secretKey: "appointment-key",
      onError(error) {
        console.log("REDUX STORE : ", error);
      },
    }),
  ],
});
const campaignPersistConfig = getPersistConfig({
  key: "campaign-perist-key",
  storage,
  rootReducer: campaignSlice,
});
const reducers = combineReducers({
  apiCache: apiCacheReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);
const leadPersistReducer = persistReducer(leadPersistConfig, leadSlice);
const reviewPersistReducer = persistReducer(reviewPersistConfig, reviewSlice);
const appointmentPersistReducer = persistReducer(appointmentPersistConfig, appointmentSlice);
const campaignPersistReducer = persistReducer(campaignPersistConfig, campaignSlice);
export const store = configureStore({
  reducer: {
    location: locationReducer,
    language: languageReducer,
    apiCache: persistedReducer,
    drawer: drawerReducer,
    loader: loaderReducer,
    mobile: mobileViewReducer,
    lead: leadPersistReducer,
    user: userSlice,
    prompt: promptSlice,
    reviews: reviewPersistReducer,
    appointments: appointmentPersistReducer,
    opportunity: opportunitySlice,
    aiData: aiSlice,
    campaign: campaignPersistReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
