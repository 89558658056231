import { useMutation } from "@apollo/client";
import { Button, Drawer, Form, Input, Select, message } from "antd";
import { capitalize, isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UserContext } from "../../../amplify/authenticator-provider";
import { CREATE_NOTE, UPDATE_NOTE } from "../../../graphql/mutation";
import useCrmIntegration from "../../../hooks/useCrmIntegration";
import { convertUtcToLocal } from "../../../library/utils";
import { updateLeadNoteRedux } from "../../../redux/slices/leadSlice";

export function NotesDrawer({ leadNotesData, setleadNotesData, setSelectedLead }) {
  const initialNotes = {
    noteType: "OPPORTUNITY",
  };
  const { selectedLead, mode, leadNote } = leadNotesData;
  console.log("🚀 ~ NotesDrawer ~ leadNote:", leadNote);
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const [form] = Form.useForm();
  const crmIntegration = useCrmIntegration();

  const [createNote, { loading: createNoteLoader }] = useMutation(CREATE_NOTE);
  const [updateNote, { loading: updateNoteLoader }] = useMutation(UPDATE_NOTE);
  const [notesData, setnotesData] = useState({ ...initialNotes, ...leadNote });
  const close = () => {
    setleadNotesData({ open: false, leadNote: null });
    setnotesData(initialNotes);
    form.resetFields();
  };
  const setOppDropdown = () => {
    const OppId = opportunity => {
      if (crmIntegration === "EL") {
        const elObject =
          opportunity?.elLeadOpportunities?.length > 0 && opportunity?.elLeadOpportunities[0];
        return `${elObject.elOpportunityId || ""}`;
      }
      if (crmIntegration === "DS") {
        const dsObject =
          opportunity?.dsOpportunityEvents?.length > 0 && opportunity?.dsOpportunityEvents[0];
        return `${dsObject.dsEventId || ""}`;
      }
      if (crmIntegration === "SF") {
        const sfLeadOpportunity =
          opportunity?.sfLeadOpportunities?.length > 0 && opportunity?.sfLeadOpportunities[0];
        return `${sfLeadOpportunity.sfOpportunityId || ""}`;
      }
      if (crmIntegration === "DT") {
        const dtLeadOpportunity =
          opportunity?.dtLeadOpportunities?.length > 0 && opportunity?.dtLeadOpportunities[0];
        return `${dtLeadOpportunity.dtOpportunityId || ""}`;
      }
    };
    const oppoDropdown =
      selectedLead?.leadOpportunities?.length > 0 &&
      selectedLead?.leadOpportunities.map(opp => {
        return {
          id: opp.id,
          name: opp.name,
          opprId: OppId(opp),
        };
      });
    return oppoDropdown || [];
  };

  useEffect(() => {
    if (mode === "ADD" && (selectedLead?.leadOpportunities || []).length > 0) {
      form.setFieldsValue({
        ...leadNote,
        leadOpportunityId: selectedLead?.leadOpportunities[0]?.id || null,
      });
    }
  }, [selectedLead?.leadOpportunities, mode]);

  useEffect(() => {
    if (mode === "EDIT") {
      form.setFieldsValue({
        ...leadNote,
        leadOpportunityId: leadNote?.leadOpportunity?.id || null,
      });
      if (!isEmpty(leadNote.leadOpportunity)) {
        setnotesData({ ...notesData, noteType: "OPPORTUNITY" });
      }
    }
  }, [leadNote, mode]);
  const handleSave = async values => {
    const payload = {
      ...values,
      leadId: selectedLead.id,
      userId: user.id,
    };
    if (mode === "ADD") {
      const res = await createNote({
        variables: payload,
      });
      if (res.data.createNote.statusCode === 200) {
        message.success(res.data.createNote.message);
        setSelectedLead({
          ...selectedLead,
          leadNotes: [...(selectedLead.leadNotes || []), res.data.createNote.leadNote],
        });
        close();
      } else {
        message.error(res.data.createNote.message);
      }
    } else {
      const res = await updateNote({
        variables: {
          id: leadNote.id,
          ...payload,
        },
      });
      if (res.data.updateNote.statusCode === 200) {
        message.success(res.data.updateNote.message);
        const index = selectedLead.leadNotes.findIndex(item => +item.id === +leadNote.id);
        if (index !== -1) {
          dispatch(updateLeadNoteRedux(res.data.updateNote.leadNote));
        }
        close();
      } else {
        message.error(res.data.updateNote.message);
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue(notesData);
  }, [notesData]);

  return (
    <Drawer
      open={leadNotesData.open}
      destroyOnClose
      width={600}
      title={`${capitalize(mode)} Note for ${
        leadNote?.leadOpportunity?.name || leadNotesData?.selectedLead?.fullName || ""
      }`}
      onClose={() => close()}
      footer={
        mode !== "VIEW" ? (
          <div className="flex-row-centered gap-2 items-center">
            <Button
              loading={createNoteLoader || updateNoteLoader}
              type="primary"
              onClick={() => form.submit()}
            >
              Save
            </Button>
            <Button onClick={() => close()}>Cancel</Button>
          </div>
        ) : null
      }
    >
      {mode !== "VIEW" ? (
        <Form
          onFinish={values => handleSave({ ...values, ...notesData })}
          layout="vertical"
          form={form}
        >
          <div className="flex-row-centered gap-2 items-center justify-between">
            <Form.Item name="noteType" label="Note Type" className="w-full">
              <Select
                name="noteType"
                options={[
                  { label: "Lead Note", value: "LEAD" },
                  { label: "Opportunity Note", value: "OPPORTUNITY" },
                ]}
                placeholder="Note Type"
                onSelect={value => setnotesData({ ...notesData, noteType: value })}
              />
            </Form.Item>
            {notesData.noteType === "OPPORTUNITY" && (
              <Form.Item
                rules={[{ required: true, message: "Please select Opportunity" }]}
                name="leadOpportunityId"
                className="w-full"
                label="Select Opportunity"
              >
                <Select
                  options={setOppDropdown().map(item => ({
                    label: `${item.name} #(${item.opprId || item.id})`,
                    value: item.id,
                  }))}
                  placeholder="Select Opportunity"
                  name="leadOpportunityId"
                />
              </Form.Item>
            )}
          </div>
          <Form.Item
            rules={[{ required: true, message: "Lead Note is required" }]}
            name="note"
            label="Note"
          >
            <Input.TextArea className="h-[57vh]" name="note" />
          </Form.Item>
        </Form>
      ) : (
        <div className="flex-col-centered items-start gap-2">
          <div className="flex-col-centered items-start gap-1">
            <span>Note</span>
            <span className="text-xs ">
              By: {leadNote?.updatedBy?.fullName || leadNote?.createdBy?.fullName} -{" "}
              {convertUtcToLocal(leadNote.updateTs || leadNote.createTs, "MM/DD/YYYY hh:mm:ss A")}
            </span>
          </div>
          <div className="w-full border-gray-200 border-solid border-[2px] p-2 h-[70vh] overflow-y-auto">
            <div dangerouslySetInnerHTML={{ __html: leadNote.note }} />
          </div>
        </div>
      )}
    </Drawer>
  );
}
