import { Input } from "antd";
import React from "react";

export default function SelectSources(props) {
  const { sources } = props;
  return (
    <div className="flex flex-col items-start">
      <Input />
    </div>
  );
}
