import { message } from "antd";
import dayjs from "dayjs";

export const mediaDownloader = async imageSrc => {
  try {
    const fileName = imageSrc.split("/").pop();

    // Extract the file extension from the file name
    const extension = fileName.split(".").pop();
    const res = await fetch(imageSrc, { method: "GET", mode: "cors" });
    const blobImage = await res.blob();
    const url = window.URL.createObjectURL(blobImage);
    const a = document.createElement("a");
    a.href = url;
    a.download = `smai-${dayjs()}.${extension}`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  } catch (err) {
    message.error(`Something went wrong, Error: - ${err.message}`);
  }
};
