/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import { Button, Modal, Switch, Tag } from "antd";
import styled from "styled-components";
import { SVGIcon } from "../../library/basicComponents";
import TextInput from "../../library/textInput";

import CloseIconBlue from "../../../static/icons/CloseIconBlue.svg";
import AddIcon from "../../../static/icons/AddIcon.svg";
import CheckIconBlue from "../../../static/icons/CheckIconBlue.svg";
import CustomTabs from "../commonComponents/tabs";
import AntdIconV4 from "../../utils/antdIcon";
import { colorConfig } from "../../library/colorConfig";
import useLang from "../../hooks/useLang";

export const TestTag = styled.div`
  display: inline-block;
  background-color: ${props => (props.checked ? "#E6F0FF" : "#FFFFFF")};
  ${props => (props.checked ? "border: 1px solid #E6F0FF;" : "border: 1px solid #EEEEF1;")};
  color: ${props => (props.checked ? colorConfig.secondaryDark : "#5E5E5E")};
  border-radius: 20px;
  font-family: Helvetica;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 0.7em 1em 0.7em 1em;
  margin: 0.5em;
  min-width: 50px;
  text-align: center;
`;

const SourceFiltersComponent = props => {
  const {
    userCurrentFilters,
    locationTag,
    saveFilterText,
    hasUnknownLocationFilter,
    saveUnknownLocationFilter,
    removeFilterText,
    keywordTag,
    hasMatchAllOptionForKeywordFilter,
    saveFilterAllOrAnyOption,
    multiselectsSubgroup,
    checkFilter,
    additionalFilterListSet,
    checkAdditionFilter,
    setManageFiltersVisible,
    manageFiltersVisible,
    refreshProspects,
  } = props;

  const [lang] = useLang();

  const TAB_ITEMS = [
    {
      label: lang.locations,
      key: "1",
      children: (
        <>
          {userCurrentFilters &&
            userCurrentFilters
              .filter(filter => filter.type === "Text" && filter.typeName.indexOf("Location") === 0)
              .map(filter => (
                <Tag key={filter.id}>
                  {filter.value}{" "}
                  <SVGIcon
                    component={CloseIconBlue}
                    alt="Remove"
                    onClick={() => removeFilterText(filter.id)}
                  />
                </Tag>
              ))}
          <br />
          <br />
          <div className="flex items-center justify-center w-full gap-2">
            <TextInput
              reference={locationTag}
              placeholder={lang.addLocationFilter}
              name="locationTag"
              onKeyUp={e => e.keyCode === 13 && saveFilterText("Location")}
              small
            />
            <Button onClick={() => saveFilterText("Location")}>
              <SVGIcon component={AddIcon} alt="Add" />
            </Button>
          </div>
          <br />
          <div className="flex justify-center mb-[1em]">
            <Switch
              className="my-0 mx-[0.5rem]"
              disabled={
                userCurrentFilters.filter(
                  f => f.type === "Text" && f.typeName.indexOf("Location") === 0
                ).length === 0
                  ? true
                  : false
              }
              checked={hasUnknownLocationFilter()}
              onChange={saveUnknownLocationFilter}
            />{" "}
            {lang.IncludePostsWithUnknownLocations}
          </div>
        </>
      ),
    },
    {
      label: lang.keywords,
      key: "2",
      children: (
        <>
          {userCurrentFilters &&
            userCurrentFilters
              .filter(filter => filter.type === "Text" && filter.typeName.indexOf("Keyword") === 0)
              .map(filter => (
                <Tag key={filter.id}>
                  {filter.value}{" "}
                  <SVGIcon
                    component={CloseIconBlue}
                    alt="Remove"
                    onClick={() => removeFilterText(filter.id)}
                  />
                </Tag>
              ))}
          <br />
          <br />
          <div className="flex items-center justify-center w-full gap-2">
            <TextInput
              className="flex-1"
              reference={keywordTag}
              placeholder={lang.addKeywordFilter}
              name="keywordTag"
              onKeyUp={e => e.keyCode === 13 && saveFilterText("Keyword")}
              small
            />
            <Button onClick={() => saveFilterText("Keyword")}>
              <SVGIcon component={AddIcon} alt="Add" />
            </Button>
          </div>
          <br />
          <div className="flex justify-center mb-[1em]">
            <Switch
              className="my-0 mx-[0.5rem]"
              disabled={
                userCurrentFilters.filter(
                  f => f.type === "Text" && f.typeName.indexOf("Keyword") === 0
                ).length === 0
                  ? true
                  : false
              }
              checked={hasMatchAllOptionForKeywordFilter()}
              onChange={saveFilterAllOrAnyOption}
              id="KeywordMatchAllSwitch"
            />{" "}
            {lang.matchAllKeywords}
          </div>
        </>
      ),
    },
    {
      label: lang.sources,
      key: "3",
      children: (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {multiselectsSubgroup.Sources &&
            multiselectsSubgroup.Sources.map((filter, index) => (
              <TestTag key={index} checked={filter.checked}>
                {filter.value}{" "}
                {filter.checked ? (
                  <SVGIcon
                    component={CheckIconBlue}
                    alt="Remove"
                    onClick={() => checkFilter(filter, false)}
                  />
                ) : (
                  <SVGIcon
                    component={AddIcon}
                    alt="Add"
                    onClick={() => checkFilter(filter, true)}
                  />
                )}
              </TestTag>
            ))}
        </>
      ),
    },
    {
      label: lang.entites,
      key: "4",
      children: (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {additionalFilterListSet &&
            additionalFilterListSet.map((filter, index) => (
              <TestTag key={index} checked={filter.checked}>
                {filter.value}{" "}
                {filter.checked ? (
                  <SVGIcon
                    component={CheckIconBlue}
                    alt="Remove"
                    onClick={() => checkAdditionFilter(filter, false)}
                  />
                ) : (
                  <SVGIcon
                    component={AddIcon}
                    alt="Add"
                    onClick={() => checkAdditionFilter(filter, true)}
                  />
                )}
              </TestTag>
            ))}
        </>
      ),
    },
  ];

  return (
    <Modal
      open={manageFiltersVisible}
      onOk={() => setManageFiltersVisible(false)}
      onCancel={() => {
        setManageFiltersVisible(false);
        refreshProspects();
      }}
      bodyStyle={{ padding: 0 }}
      footer={
        <div className="text-left flex flex-wrap">
          <AntdIconV4
            type="InfoCircleOutlined"
            style={{
              marginTop: "3px",
              marginRight: "6px",
              color: colorConfig.primaryBrandBlue,
            }}
          />
          <p className="m-0">{lang.closeWindowToSaveFilters}</p>
        </div>
      }
    >
      <div>
        <CustomTabs tabItems={TAB_ITEMS} defaultActiveKey="2" size="small" animated={false} />
      </div>
    </Modal>
  );
};

export default SourceFiltersComponent;
