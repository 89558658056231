import * as React from "react";

const ActivateIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#334155"
      stroke="#334155"
      strokeWidth={0.052}
      d="M14.948 9.22a1.106 1.106 0 0 1 0 1.89l-8.225 5.03a1.116 1.116 0 0 1-1.697-.944V5.134a1.116 1.116 0 0 1 1.697-.945l8.225 5.032Zm-8.604 5.56v.047l.04-.024 7.545-4.616.036-.022-.036-.022-7.545-4.616-.04-.024V14.78Z"
    />
  </svg>
);
export default ActivateIcon;
