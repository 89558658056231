/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  filters: {
    userIds: [],
    appointmentStatus: [
      "SCHEDULED",
      "NO_SHOWED",
      "SHOWED",
      "CANCELLED",
      "CONFIRMED",
      "RESCHEDULED",
      "VIRTUAL",
    ],
    userMode: 0,
    range: {
      startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
      endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
    },
    page: 1, // do not persist this
    pageSize: 10, // do not persist this
    calendarMode: "dayGridMonth",
  },
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    reducerSetAppointmentUserIds(state, action) {
      state.filters.userIds = action.payload;
    },
    reducerSetAppointmentStatus(state, action) {
      state.filters.appointmentStatus = action.payload;
    },
    reducerSetAppointmentRange(state, action) {
      state.filters.range = action.payload;
    },
    reducerSetAppointmentUserMode(state, action) {
      state.filters.userMode = action.payload;
    },
    reducerSetPagination(state, action) {
      state.filters.page = action.payload.page;
      state.filters.pageSize = action.payload.pageSize;
    },
    reducerSetAppointmentCalendarMode(state, action) {
      state.filters.calendarMode = action.payload;
    },
  },
});

export const {
  reducerSetAppointmentUserIds,
  reducerSetAppointmentRange,
  reducerSetAppointmentStatus,
  reducerSetAppointmentUserMode,
  reducerSetPagination,
  reducerSetAppointmentCalendarMode,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
