/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-cond-assign */
import { useMutation } from "@apollo/client";
import { Button, Cascader, Checkbox, Form, Select, message } from "antd";
import _, { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserContext } from "../../amplify/authenticator-provider";
import { CREATE_PROMPT, UPDATE_PROMPT } from "../../graphql/mutation";
import { closeDialog } from "../../library/helpers";
import { parseChildSources } from "../../library/utils";
import UserSelector from "../commonComponents/userSelector";
import { RichTextEditorJSX } from "./RichTextEditor";
import "./ottosettings.css";

export const OttoSettingsForm = ({
  teamList,
  roleList,
  refetchPromptList,
  setpromptList,
  settingsCompany,
  sourceList,
  mode: promptMode = "ADD",
  prompt = {},
}) => {
  const { user } = useContext(UserContext);
  const { applicationUseCases, applicationLevels } = useSelector(state => state.aiData);
  const [form] = Form.useForm();
  const [formData, setformData] = useState({
    applicationLevelId: 1,
    promptText: "",
    leadSourceOriginalIds: [],
    formattext: "",
    userId: user.id,
  });
  console.log(prompt);

  useEffect(() => {
    if (promptMode === "EDIT") {
      setformData({
        applicationLevelId: prompt.applicationLevelId,
        userId: prompt.userId,
        teamId: prompt.teamId,
        companyRoleId: prompt.companyRoleId,
        applicationUseCaseId: +prompt.applicationUseCaseId,
        levelCode: prompt.applicationLevel.code,
        leadSourceOriginalIds: parseChildSources(
          sourceList,
          prompt.promptSources.map(item => item.leadSourceOriginalId)
        ),
        promptText: prompt.promptText,
        // formattext: formattextFn(prompt.promptText, true),
      });
      // formattextFn(prompt.promptText);
      console.log(prompt);
      form.setFieldsValue({
        applicationLevelId: prompt.applicationLevelId,
        userId: prompt.userId,
        teamId: prompt.teamId,
        companyRoleId: prompt.companyRoleId,
        applicationUseCaseId: +prompt.applicationUseCaseId,
      });
    }
  }, [promptMode, prompt]);
  const [createPrompt, { loading: createPromptLoader }] = useMutation(CREATE_PROMPT, {
    onError: err => {
      message.error("Something went wrong");
    },
  });
  const [updatePrompt, { loading: updatePromptLoader }] = useMutation(UPDATE_PROMPT, {
    onError: err => {
      message.error("Something went wrong");
    },
  });
  const handleSave = async value => {
    const payload = {
      ...value,
      ...formData,
      companyId: settingsCompany.id,
      ...(formData.levelCode !== "LOCATION"
        ? {
            locationId: sessionStorage.getItem("currentLocationId"),
          }
        : {}),
    };
    if (isEmpty(payload.promptText)) {
      return message.error("Prompt text is required");
    }
    if (promptMode === "ADD") {
      const res = await createPrompt({
        variables: {
          ...payload,
          leadSourceOriginalIds: _.map(payload.leadSourceOriginalIds, _.last),
        },
      });
      if (res.data.createPrompt.statusCode === 200) {
        message.success(res.data.createPrompt.message);
        closeDialog("ottoSettingsForm");
        const promptRes = await refetchPromptList();
        setpromptList(promptRes.data.getPrompts);
      } else {
        message.error(res.data.createPrompt.message);
      }
    } else {
      // return console.log(payload, prompt);
      const res = await updatePrompt({
        variables: {
          id: prompt.id,
          ...payload,
          leadSourceOriginalIds: _.map(payload.leadSourceOriginalIds, _.last),
        },
      });
      if (res.data.updatePrompt.statusCode === 200) {
        message.success(res.data.updatePrompt.message);
        closeDialog("ottoSettingsForm");
        const promptRes = await refetchPromptList();
        setpromptList(promptRes.data.getPrompts);
      }
    }
  };

  const RenderCustomSelect = ({ code }) => {
    switch (code?.toLowerCase()) {
      case "company":
        return null;
      case "user":
        return (
          <UserSelector
            item={{ name: <span className="text-xs font-[600] text-[#64748B]">User</span> }}
            // className="w-[32%]"
            containerClassName="w-[32%]"
            name="userId"
            showAll={false}
            errorMessage="User is required"
            // rules={[{ required: true, message: "User is required" }]}
            allowClear
            placeholder="Select users"
            disabled={promptMode === "EDIT"}
            multiple={false}
            validation
            value={formData.userId}
            handleUsersSelection={value => setformData({ ...formData, userId: value })}
          />
        );
      case "role":
        return (
          <Form.Item
            label={<span className="text-xs font-[600] text-[#64748B]">Role</span>}
            name="companyRoleId"
            className="w-[32%]"
            rules={[{ required: true, message: "Role is required" }]}
          >
            <Select
              placeholder="Select Role"
              name="companyRoleId"
              disabled={promptMode === "EDIT"}
              className="w-full"
              onSelect={value => setformData({ ...formData, companyRoleId: +value })}
            >
              {(roleList || []).map(item => (
                <Select.Option key={item.id} value={+item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      case "team":
        return (
          <Form.Item
            label={<span className="text-xs font-[600] text-[#64748B]">Team</span>}
            name="teamId"
            className="w-[32%]"
            rules={[{ required: true, message: "Team is required" }]}
          >
            <Select
              placeholder="Select Team"
              name="teamId"
              disabled={promptMode === "EDIT"}
              className="w-full"
              onSelect={value => setformData({ ...formData, teamId: +value })}
            >
              {(teamList || []).map(item => (
                <Select.Option key={item.id} value={+item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      case "location":
        return (
          <Form.Item
            label={<span className="text-xs font-[600] text-[#64748B]">Location</span>}
            className="w-[32%]"
            name="locationId"
            rules={[{ required: true, message: "Location is required" }]}
          >
            <Select
              placeholder="Select location"
              name="locationId"
              disabled={promptMode === "EDIT"}
              className="w-full"
              onSelect={value => setformData({ ...formData, locationId: value })}
            >
              {(user.locations || []).map(item => (
                <Select.Option key={item.id} value={item.location.id}>
                  {item.location.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      default:
        break;
    }
  };
  return (
    <Form form={form} onFinish={handleSave} layout="vertical">
      {applicationLevels.map((item, index) => (
        <Button
          type="text"
          key={index}
          disabled={promptMode === "EDIT"}
          className={`${
            formData.applicationLevelId === +item.id ? "bg-brand-100 text-brand-500" : ""
          }`}
          onClick={() =>
            setformData({ ...formData, applicationLevelId: +item.id, levelCode: item.code })
          }
        >
          {item.title}
        </Button>
      ))}
      <div className="border-solid border-0 border-t-[1px] mx-[-24px] border-grey-200 px-4 py-2">
        <div className="flex flex-row w-full gap-2">
          <Form.Item
            label={<span className="text-xs font-[600] text-[#64748B]">Prompt Usecase</span>}
            className="w-[32%]"
            name="applicationUseCaseId"
            rules={[{ required: true, message: "Use case is required" }]}
          >
            <Select
              name="applicationUseCaseId"
              disabled={promptMode === "EDIT"}
              placeholder="Select usecase"
              onSelect={value => setformData({ ...formData, applicationUseCaseId: +value })}
              className="w-full"
            >
              {applicationUseCases.map((item, index) => (
                <Select.Option key={index} value={+item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <RenderCustomSelect code={formData.levelCode} />
        </div>
        <div className="flex flex-row gap-2">
          <Cascader
            multiple
            fieldNames={{ label: "name", value: "key", children: "childs" }}
            expandTrigger="hover"
            options={sourceList}
            maxTagCount="responsive"
            className="w-[300px]"
            name="source"
            value={formData.leadSourceOriginalIds}
            onChange={e => {
              setformData({ ...formData, leadSourceOriginalIds: e });
            }}
            placeholder="Select Sources"
          />
        </div>
        <RichTextEditorJSX
          formData={formData}
          className="min-h-[250px] p-2 hover:shadow-inner w-full"
          setformData={setformData}
          changingKey="promptText"
        />
        <div className="flex flex-row items-center gap-2 ">
          <Checkbox>Include Opportunity</Checkbox>
          <Checkbox>Include Campaigns</Checkbox>
          <Checkbox>Include Appointments</Checkbox>
        </div>
        <div className="flex flex-row items-center mt-1 gap-2 justify-end">
          <Button
            loading={createPromptLoader || updatePromptLoader}
            type="primary"
            htmlType="submit"
          >
            {promptMode === "EDIT" ? "Edit Prompt" : "Add Prompt"}
          </Button>
          <Button onClick={() => closeDialog("ottoSettingsForm")}>Cancel</Button>
        </div>
      </div>
    </Form>
  );
};
