/* eslint-disable no-unreachable */
import { useLazyQuery, useQuery } from "@apollo/client";
import { Input, Spin, message } from "antd";
import React, { useState } from "react";
import { GET_INITIAL_REVIEW_MESSAGE, GET_REVIEW_LINK } from "../../../graphql/query";
import { closeDialog } from "../../../library/helpers";
import DialogFooter from "../../commonComponents/dialogFooter";

export default function ReviewInviteForm(props) {
  const { lead, sendSendReview, oncancel } = props;
  const { TextArea } = Input;
  const [messageContent, setmessage] = useState();
  const { loading } = useQuery(GET_INITIAL_REVIEW_MESSAGE, {
    variables: {
      leadId: +lead.id,
    },
    onCompleted: res => {
      if (res.getInitialReviewMessage.statusCode === 200) {
        setmessage(res.getInitialReviewMessage.content || "Please give us a review via this link");
      } else {
        setmessage("Please give us a review via this link");
      }
    },
  });
  const [getReviewLink, { loading: reviewLinkLoader }] = useLazyQuery(GET_REVIEW_LINK);
  const handleSaveLead = async () => {
    // add url parser query for review invite link
    // setReviewLink(res?.getInitialReviewMessage?.reviewLink || "");
    const res = await getReviewLink({
      variables: {
        leadId: +lead.id,
        templateText: messageContent,
      },
    });
    if (res.data.getReviewLink.statusCode === 200) {
      sendSendReview({
        body: res.data.getReviewLink.content,
        reviewLink: res.data.getReviewLink.reviewLink,
      });
      oncancel();
    } else {
      message.error(res.data.getReviewLink.message);
    }
  };
  try {
    return (
      <Spin tip="Loading..." spinning={loading} className="w-full">
        <div>
          <div className="flex">
            <b>Full Name: </b> &nbsp;
            <p>{lead.fullName}</p>
          </div>
          <div className="flex">
            <b>Phone Number: </b> &nbsp;
            <p>{lead.phoneNumbers[0].phone}</p>
          </div>
          <b className="mt-2">Message:</b>
          <TextArea
            rows={4}
            value={messageContent}
            placeholder="Enter the review invite message here"
            onChange={e => setmessage(e.target.value)}
          />
          <DialogFooter
            handleConfirm={e => handleSaveLead(e)}
            handleCancel={() => closeDialog("reviewDialog")}
            // loading={loading}
            confirmBtnText="Send"
          />
        </div>
      </Spin>
    );
  } catch (err) {
    console.error(err.message);
    return null;
  }
}
