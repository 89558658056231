import * as React from "react";

const ReviewsIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M18.688 7.6a1.25 1.25 0 0 0-1.08-.859l-4.64-.4-1.818-4.325a1.246 1.246 0 0 0-2.3 0L7.037 6.341l-4.646.403a1.25 1.25 0 0 0-.711 2.192l3.524 3.08-1.056 4.573a1.25 1.25 0 0 0 1.862 1.355l3.985-2.422 3.993 2.422a1.25 1.25 0 0 0 1.862-1.355l-1.056-4.578 3.524-3.075a1.25 1.25 0 0 0 .37-1.335Zm-1.19.391-3.523 3.075a1.25 1.25 0 0 0-.397 1.228l1.059 4.581-3.99-2.422a1.242 1.242 0 0 0-1.292 0L5.37 16.875l1.052-4.578a1.25 1.25 0 0 0-.397-1.228L2.5 7.996V7.99l4.644-.402a1.25 1.25 0 0 0 1.043-.761L10 2.506l1.813 4.32a1.25 1.25 0 0 0 1.042.761l4.645.402v.006l-.002-.004Z"
    />
  </svg>
);
export default ReviewsIcon;
