import * as React from "react";

const AnalyticsIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M10.733 1.88a1.25 1.25 0 0 0-1.358 1.245v3.177a1.24 1.24 0 0 0 1.035 1.232A2.5 2.5 0 1 1 7.5 10.13c-.032-.642.1-1.172.39-1.583a1.239 1.239 0 0 0-.132-1.599L5.601 4.741a1.25 1.25 0 0 0-1.846.086 8.094 8.094 0 0 0 .542 10.958A8.065 8.065 0 0 0 10 18.125h.116a8.148 8.148 0 0 0 8.009-8.01c.058-4.242-3.188-7.859-7.392-8.235Zm-6.02 3.74 2.156 2.203v.004c-.32.46-.52.992-.585 1.548H3.153a6.807 6.807 0 0 1 1.56-3.755Zm-1.56 5.005h3.15a3.75 3.75 0 0 0 3.072 3.073v3.149a6.875 6.875 0 0 1-6.221-6.222Zm11.702 4.23a6.832 6.832 0 0 1-4.23 1.99v-3.146a3.75 3.75 0 0 0 0-7.397V3.125c3.556.319 6.302 3.381 6.25 6.973a6.832 6.832 0 0 1-2.02 4.757Z"
    />
  </svg>
);
export default AnalyticsIcon;
