import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, DatePicker, Input, Tooltip, message } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { UserContext } from "../../../amplify/authenticator-provider";
import {
  MODIFIED_COMPANY_SCHEDULE,
  MODIFIED_LOCATION_SCHEDULE,
  UPDATE_COMPANY,
} from "../../../graphql/mutation";
import {
  GET_COMPANY_SCHEDULE,
  GET_LOCATION_SCHEDULE,
  GET_MY_COMPANY,
} from "../../../graphql/query";
import { colorConfig } from "../../../library/colorConfig";
import { AccessPermissionAction } from "../../../library/constants";
import { checkAccessPermission, closeDialog, openDialog } from "../../../library/helpers";
import { getMobileView } from "../../../redux/slices/isMobile";
import AntdIconV4 from "../../../utils/antdIcon";
import { s3LogoUploader } from "../../../utils/awslogoUploader";
import { bytesToMegaBytes } from "../../../utils/getFileSize";
import SettingHeader from "../../adminDashboard/adminOptions/settingHeader";
import CustomButton from "../../commonComponents/button";
import CustomDivider from "../../commonComponents/divider";
import CustomDrawer from "../../commonComponents/drawer";
import CustomeTable from "../../commonComponents/table";
import WorkHours from "../../settings/workHours";
import CompanyProfileForm from "./companyProfileForm";
import CompanyProfileInfo from "./companyProfileInfo";
import useLang from "../../../hooks/useLang";
import { getLocationId } from "../../../redux/slices/locationSlice";

const CompanyProfile = () => {
  const [lang] = useLang();

  const isMobileView = useSelector(state => getMobileView(state));
  const [misc, setmisc] = useState({});

  const { user } = useContext(UserContext);
  const workHoursRef = useRef(null);
  const [company, setCompany] = useState(null);
  const [editCompany, setEditCompany] = useState(null);
  const [checkbox, setCheckbox] = useState(company?.isOpenCrmWarningPopup || false);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(
    user && user.role ? user.role.isCompanyAdmin : false
  );
  const [awsPicture, setAwsPictuer] = useState(); // this state is for aws uploader
  const [companyPicture, setCompanyPicture] = useState(company?.profilePic); // this state is for display

  useEffect(() => {
    if (user && user.role) {
      setIsCompanyAdmin(user.role.isCompanyAdmin);
    }
  }, [user]);

  const { data: companyDetail, refetch: refreshCompany } = useQuery(GET_MY_COMPANY, {
    onCompleted: res => {
      setCompany(res?.me?.company || {});
      setCheckbox(res?.me?.company?.isOpenCrmWarningPopup);
    },
  });

  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    onCompleted: () => {
      if (refreshCompany) {
        try {
          refreshCompany();
        } catch (err) {
          console.log("Error in updateCompany", err);
        }
      }
    },
  });

  const validateInputs = company => {
    return company?.name?.trim() !== "";
  };

  const handleFileUpload = () => {
    return new Promise((resolve, reject) => {
      // aws s3 bucket upload
      if (bytesToMegaBytes(awsPicture.size) > 5) {
        reject(lang.fileSizeErr);
        return;
      }
      message.info(`${lang.uploadingMedia}...`);
      s3LogoUploader(awsPicture, process.env.GATSBY_S3_BUCKET, () => {})
        .then(mediaUrl => {
          resolve(mediaUrl);
        })
        .catch(() => {
          reject(lang.somethingWentWrong);
        });
    });
  };

  const submitCompany = async () => {
    if (validateInputs(company)) {
      // eslint-disable-next-line prettier/prettier
      const res = (await workHoursRef.current) && workHoursRef.current.saveWorkHours();

      let profilePic;

      if (companyPicture) {
        try {
          profilePic = await handleFileUpload();
          message.success(lang.uploadedSuccessfully);
        } catch (err) {
          message.error(err);
        }
      }

      if (res) {
        updateCompany({
          variables: {
            ...company,
            phone: company.phone ? company.phone : "",
            isOpenCrmWarningPopup: checkbox,
            profilePic: profilePic || company?.profilePic,
          },
        });
        message.success(lang.companySuccessMsg);
        setEditCompany(null);
        setAwsPictuer(null);
        setCompanyPicture(profilePic);
      }
    } else {
      message.error(lang.invalidFields);
    }
  };

  const handleCheckbox = async e => {
    setCheckbox(e.target.checked);
    updateCompany({
      variables: {
        ...company,
        phone: company.phone ? company.phone : "",
        isOpenCrmWarningPopup: e.target.checked,
      },
    });
    message.success(lang.companyPreferenceUpdated);
  };

  const canViewWorkingHours = checkAccessPermission(AccessPermissionAction.WORKING_HOUR_VIEW);

  return (
    <div>
      <SettingHeader headerTitle={lang.companyProfile}>
        <div className="flex items-center gap-2">
          {editCompany ? (
            <>
              <CustomButton
                icon={<AntdIconV4 type="CheckOutlined" />}
                btnText={lang.saveChanges}
                type="primary"
                handleClick={submitCompany}
                disabled={!isCompanyAdmin}
              />
              <CustomButton
                handleClick={() => {
                  setEditCompany(false);
                  setCompany(companyDetail?.me?.company);
                }}
                btnText={lang.cancel}
              />
            </>
          ) : (
            <CustomButton
              icon={<AntdIconV4 type="EditOutlined" />}
              type="primary"
              handleClick={() => setEditCompany(true)}
              disabled={!isCompanyAdmin}
              btnText={lang.editCompanyInfo}
            />
          )}
        </div>
      </SettingHeader>
      <div className="block md:flex justify-between">
        {company ? (
          editCompany ? (
            <div
              className="basis-1/2 px-[15px]"
              style={{
                borderRight: isMobileView ? "none" : `2px solid ${colorConfig.disabledGray}`,
              }}
            >
              <CompanyProfileForm
                setAwsPictuer={setAwsPictuer}
                company={company}
                setCompany={setCompany}
                companyPicture={companyPicture}
                setCompanyPicture={setCompanyPicture}
                misc={misc}
                setmisc={setmisc}
              />
            </div>
          ) : (
            <CompanyProfileInfo
              company={company}
              checkbox={checkbox}
              handleCheckbox={handleCheckbox}
              isEdit={editCompany}
              misc={misc}
              setmisc={setmisc}
            />
          )
        ) : null}

        <CustomDivider className="mt-5 md:hidden" />

        {canViewWorkingHours && (
          <div className="basis-1/2 mt-3">
            <WorkHours
              showTitle
              isCompanyAdmin={isCompanyAdmin}
              ref={workHoursRef}
              isEdit={editCompany}
              company={user?.company || null}
              setmisc={setmisc}
              misc={misc}
            />
          </div>
        )}
        <CustomDrawer
          data={misc}
          setdata={setmisc}
          content={<Holidays edit={editCompany} user={user} />}
        />
      </div>
    </div>
  );
};

export default CompanyProfile;

export const Holidays = ({
  edit = true,
  CompanyHolidays = [],
  user,
  misc,
  isLocationBased = false,
}) => {
  const [holidays, setholidays] = useState([]);
  const [modifyCompanySchedule] = useMutation(MODIFIED_COMPANY_SCHEDULE);
  const [modifyLocationSchedule] = useMutation(MODIFIED_LOCATION_SCHEDULE);
  const [leaves, setLeaves] = useState([]);
  const [date, setDate] = useState();
  const locationIdslice = useSelector(state => getLocationId(state));
  // const { authUser } = useContext(UserContext);

  const [getCompanySchedule, { data: companySchedule }] = useLazyQuery(GET_COMPANY_SCHEDULE, {
    fetchPolicy: "network-only",
    variables: {
      companyId: user?.company?.id,
    },
    onCompleted: resp => {
      console.log("resp=====>", resp);
      const holidayList = [];
      resp?.getCompanySchedule?.map(item => {
        const obj = { date: item?.fromDatetime, details: item?.name, id: item?.id };
        holidayList.push(obj);
      });
      setholidays(holidayList);
    },
  });

  console.log("mics====>", misc);

  const [getLocationSchedule, { data: locationSchedule }] = useLazyQuery(GET_LOCATION_SCHEDULE, {
    fetchPolicy: "network-only",
    variables: {
      locationId: +misc?.item?.id,
    },
    onCompleted: resp => {
      console.log("resp=====>", resp);
      const holidayList = [];
      resp?.getLocationSchedule?.map(item => {
        const obj = { date: item?.fromDatetime, details: item?.name, id: item?.id };
        holidayList.push(obj);
      });
      setholidays(holidayList);
    },
  });

  useEffect(() => {
    !isLocationBased && getCompanySchedule();
  }, []);

  useEffect(() => {
    isLocationBased && getLocationSchedule();
  }, [locationIdslice]);
  // useEffect(() => {
  //   if (edit) {
  //     setholidays(prev => {
  //       const temp = [...prev];
  //       temp.push({
  //         date: dayjs().toISOString(),
  //         details: "",
  //       });
  //       return temp;
  //     });
  //   } else if (holidays.length === 0) {
  //     setholidays(prev => {
  //       const temp = [...prev];
  //       temp.push({ date: dayjs("2023-01-26").toISOString(), details: "Indian Republic Day" });
  //       temp.push({ date: dayjs("2023-08-15").toISOString(), details: "Indian Independence day" });
  //       return temp;
  //     });
  //   }
  // }, []);
  const columns = [
    {
      title: "SR#",
      render: (text, record, index) => <span key={index}>{index + 1}</span>,
    },
    {
      title: "Holiday",
      render: (text, record, index) => <span key={index}>{record?.details}</span>,
    },
    {
      title: "Date",
      render: (text, record, index) => (
        <span key={index}>{dayjs(record?.date).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "Day",
      render: (text, record, index) => (
        <span key={index}>{dayjs(record?.date).format("dddd")}</span>
      ),
    },
    {
      title: "Actions",
      render: (text, record, index) => (
        <div key={index} className="flex justify-around">
          {console.log("recorddd====>", record)}
          <Tooltip title="Edit Holiday">
            <EditOutlined
              onClick={() => {
                openDialog("holidayForm", {
                  dialogHeader: "Update holiday",
                  leaves,
                  setLeaves,
                  leave: record,
                  index,
                  edit: true,
                  modifyCompanySchedule: data => {
                    !isLocationBased
                      ? modifyCompanySchedule({
                          variables: {
                            companyId: user?.company?.id,
                            schedule: {
                              id: data?.id,
                              name: data?.reason,
                              fromDatetime: data?.fromDate,
                              toDatetime: data?.toDate,
                              isCompany: true,
                            },
                          },
                          onCompleted: () => {
                            getCompanySchedule();
                          },
                        })
                      : modifyLocationSchedule({
                          variables: {
                            locationId: sessionStorage.getItem("currentLocationId"),
                            locationSchedule: {
                              id: data?.id,
                              name: data?.reason,
                              fromDatetime: data?.fromDate,
                              toDatetime: data?.toDate,
                              isLocation: true,
                            },
                          },
                          onCompleted: () => {
                            getLocationSchedule();
                          },
                        });
                  },
                });
              }}
              className="text-orange-500 cursor-pointer"
            />
          </Tooltip>
          <Tooltip title="Delete Holiday">
            <DeleteOutlined
              onClick={() =>
                openDialog("confirmDialog", {
                  label: "Delete Holiday",
                  content: "On confirming, the Holiday will be deleted permanently",
                  confirmBtnText: "Delete",
                  handleConfirm: () => {
                    console.log("record====>", record);
                    // handleDelete(index);
                    !isLocationBased
                      ? modifyCompanySchedule({
                          variables: {
                            companyId: user?.company?.id,
                            schedule: {
                              id: record?.id,
                              isDelete: true,
                              isCompany: true,
                            },
                          },
                          onCompleted: () => {
                            getCompanySchedule();
                          },
                        })
                      : modifyLocationSchedule({
                          variables: {
                            locationId: sessionStorage.getItem("currentLocationId"),
                            locationSchedule: {
                              id: record?.id,
                              isDelete: true,
                              isLocation: true,
                            },
                          },
                          onCompleted: () => {
                            getLocationSchedule();
                          },
                        });
                    closeDialog("confirmDialog");
                  },
                })
              }
              className="text-red-500 cursor-pointer"
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  return edit ? (
    <div>
      {" "}
      {holidays.map((item, i) => (
        <div className="flex justify-between mt-2" key={i}>
          <div className="flex flex-col w-1/4">
            <DatePicker
              value={dayjs(item.date)}
              onSelect={value => {
                setholidays(prev => {
                  const temp = [...prev];
                  temp[i].date = value.toISOString();
                  return temp;
                });
              }}
              placeholder="Select Holiday Date"
            />
          </div>
          <div className="w-1/2 flex flex-col">
            <Input
              value={item.details}
              onChange={e =>
                setholidays(prev => {
                  const temp = [...prev];
                  temp[i].details = e.target.value;
                  return temp;
                })
              }
              placeholder="Holiday Details"
            />
          </div>
          <Button
            onClick={() =>
              setholidays(prev => {
                const temp = [...prev];
                temp.splice(i, 1);
                return temp;
              })
            }
            className="bg-red-500 text-white"
          >
            -
          </Button>
        </div>
      ))}
      <div className="w-full text-right">
        <Button
          className="mt-2"
          onClick={() =>
            setholidays(prev => {
              const temp = [...prev];
              temp.push({ date: dayjs().toISOString() });
              return temp;
            })
          }
          type="primary"
        >
          + Add Holiday Details
        </Button>
      </div>
    </div>
  ) : (
    <div>
      <div className="w-full text-right">
        <Button
          className="mb-2"
          onClick={() => {
            // setholidays(prev => {
            //   const temp = [...prev];
            //   temp.push({ date: dayjs().toISOString() });
            //   return temp;
            // })
            // modifyCompanySchedule({
            //   variables: {
            //     companyId: 1,
            //     companySchedules: [
            //       {
            //         name: "Test",
            //         fromDatetime: dayjs(new Date()),
            //         toDatetime: dayjs(new Date()),
            //         isCompany: true,
            //       },
            //     ],
            //   },
            //   onCompleted: () => {
            //     getCompanySchedule();
            //   },
            // });
            openDialog("holidayForm", {
              leave: {
                fromDate: dayjs(date).toISOString(),
                toDate: dayjs(date).toISOString(),
              },
              dialogHeader: "Add Holiday",
              edit: false,
              user,
              setLeaves,
              modifyCompanySchedule: data => {
                !isLocationBased
                  ? modifyCompanySchedule({
                      variables: {
                        companyId: user?.company?.id,
                        schedule: {
                          name: data?.reason,
                          fromDatetime: data?.fromDate,
                          toDatetime: data?.toDate,
                          isCompany: true,
                        },
                      },
                      onCompleted: () => {
                        getCompanySchedule();
                      },
                    })
                  : modifyLocationSchedule({
                      variables: {
                        locationId: sessionStorage.getItem("currentLocationId"),
                        locationSchedule: {
                          name: data?.reason,
                          fromDatetime: data?.fromDate,
                          toDatetime: data?.toDate,
                          // isCompany: true,
                          isLocation: true,
                        },
                      },
                      onCompleted: () => {
                        getLocationSchedule();
                      },
                    });
              },
            });
          }}
          type="primary"
        >
          + Add Holiday
        </Button>
      </div>
      <CustomeTable columns={columns} data={holidays} />
    </div>
  );
};
