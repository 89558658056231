import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locationId: null,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocationIdSlice: (state, action) => {
      state.locationId = action.payload.locationId;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLocationIdSlice } = locationSlice.actions;

export const getLocationId = state => state.location.locationId;

export default locationSlice.reducer;
