import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Divider, Table, message } from "antd";
import dayjs from "dayjs";
import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_SCHEDULED_MESSAGE } from "../../../graphql/mutation";
import useLang from "../../../hooks/useLang";
import { closeDialog, openDialog } from "../../../library/helpers";
import { removeScheduledMessage } from "../../../redux/slices/leadSlice";
import { UserContext } from "../../../amplify/authenticator-provider";
import { timezones } from "../../../library/constants";

const localizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(localizedFormat);

const ViewScheduledMessagesPopup = props => {
  const { selectedLead, getMessages, messageText } = props;
  const [lang] = useLang();
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const { leadScheduledMessages: scheduledMessages } = useSelector(state => state?.lead);
  const getLocation = () => {
    return (user?.locations || [])?.find(
      item => item?.location?.id === sessionStorage?.getItem("currentLocationId")
    );
  };
  const locationObj = useMemo(getLocation, []);
  const timeZone = sessionStorage.getItem("currentLocationId").includes(",")
    ? selectedLead?.location?.timezone
    : locationObj?.location?.timezone;
  const shortCodeTimeZone = timezones.find(item => item.value === timeZone);
  const [deleteScheduledMessage, { loading: deleteLoading }] =
    useMutation(DELETE_SCHEDULED_MESSAGE);
  const handleDeleteMessage = async (index, record) => {
    try {
      const variables = {
        id: record?.id,
        timezone: dayjs.tz.guess(),
      };
      const resp = await deleteScheduledMessage({
        variables,
      });
      if (resp?.data?.deleteScheduledMessage?.statusCode === 200) {
        message.success("Scheduled message deleted successfully");
        if (index > -1) {
          dispatch(removeScheduledMessage(index));
        }
        getMessages();
      } else {
        console.error(resp?.data?.deleteScheduledMessage?.message);
        message.error("Failed to delete scheduled message. Please try again or contact support.");
      }
      closeDialog("confirmDialog");
    } catch (err) {
      console.error(
        "Failed to delete scheduled message. Please try again or contact support. err =",
        err
      );
      message.error("Failed to delete scheduled message. Please try again or contact support.");
    }
  };
  const columns = [
    {
      title: "Scheduled Message",
      render: (text, record, index) => <span key={index}>{record?.message}</span>,
    },
    {
      title: "Scheduled Date & Timezone",
      render: (text, record, index) => (
        <span key={index}>
          {dayjs(record?.taskSendDatetime).utc(true).tz(timeZone).format("LLLL")}{" "}
          {shortCodeTimeZone?.shortName || ""}
        </span>
      ),
    },
    {
      title: "Scheduled By",
      render: (text, record, index) => (
        <span key={index}>
          {record?.user?.firstName} {record?.user?.lastName}
        </span>
      ),
    },
    {
      title: "Status",
      render: (text, record, index) => (
        <span key={index}>{record?.isTaskExecuted ? "Sent" : "Pending"}</span>
      ),
    },
    {
      title: "Actions",
      render: (text, record, index) => (
        <span className="flex justify-evenly gap-1" key={index}>
          <EditOutlined
            className={`${
              record?.isTaskExecuted
                ? "text-[#d3d3d3] cursor-not-allowed"
                : "text-yellow-500 cursor-pointer"
            }`}
            onClick={() => {
              record?.isTaskExecuted
                ? () => message.error("This message is already sent, cannot edit it now")
                : openDialog("scheduleMessage", {
                    message: record?.message,
                    messageData: record,
                    dialogHeader: "Edit scheduled message",
                    scheduledMessages,
                    // setScheduledMessages,
                    getMessages,
                    selectedLead,
                  });
            }}
          />
          <DeleteOutlined
            onClick={() => {
              record?.isTaskExecuted
                ? () => message.error("This message is already sent, cannot delete it now")
                : openDialog("confirmDialog", {
                    handleConfirm: () => handleDeleteMessage(index, record),
                    label: lang.deleteSchMsgConfPopupTitle,
                    loading: deleteLoading,
                    content: lang.deleteSchMsgConfPopupBody,
                    confirmBtnText: lang.ok,
                  });
            }}
            className={`${
              record?.isTaskExecuted
                ? "text-[#d3d3d3] cursor-not-allowed"
                : "text-red-500 cursor-pointer"
            }`}
          />
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="text-right flex justify-end flex-row mb-2">
        <Button
          type="primary"
          className="rounded-[2px]"
          onClick={() => {
            openDialog("scheduleMessage", {
              message: "",
              messageData: { message: messageText },
              dialogHeader: "Schedule Message",
              scheduledMessages,
              // setScheduledMessages,
              getMessages,
              selectedLead,
              mode: "ADD",
            });
          }}
        >
          Schedule a new message
        </Button>
      </div>
      <Table rowKey={(record, index) => index} dataSource={scheduledMessages} columns={columns} />
    </div>
  );
};

export default ViewScheduledMessagesPopup;
