/* eslint-disable no-unreachable */
import { useMutation, useQuery } from "@apollo/client";
import { Form, Select, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../amplify/authenticator-provider";
import { UPDATE_LEAD_OPPORTUNITY } from "../../graphql/mutation";
import { GET_FILTER_USERS, GET_OPPORTUNITY_USER_TYPE } from "../../graphql/query";
import { checkAccessPermission, closeDialog } from "../../library/helpers";
import DialogFooter from "../commonComponents/dialogFooter";
import UserSelector from "../commonComponents/userSelector";
import { AccessPermissionAction } from "../../library/constants";

export default function LeadOpportunityUsersForm(props) {
  const { opportunity, refetch, refetchLeads } = props;
  const [form] = Form.useForm();
  const [userTypes, setuserTypes] = useState([]);
  const { leadOpportunityUsers } = opportunity;
  const { user } = useContext(UserContext);
  const [userFormData, setuserFormData] = useState([]);
  const [userList, setuserList] = useState([]);
  const { loading: userLoader } = useQuery(GET_FILTER_USERS, {
    variables: {
      companyId: user.company.id,
      locationIds: sessionStorage.getItem("currentLocationId").split(","),
    },
    onCompleted: res => {
      setuserList(res.getUsers.data);
    },
  });
  const canAddEditOpportunity = checkAccessPermission(
    AccessPermissionAction.LEAD_OPPORTUNITY_MANAGEMENT_ADD_EDIT
  );
  useQuery(GET_OPPORTUNITY_USER_TYPE, {
    variables: {
      locationIds: sessionStorage.getItem("currentLocationId").split(","),
      opportunityTypeId: 1,
    },
    onCompleted: res => {
      setuserTypes(res.getOpportunityUserTypes);
    },
  });
  useEffect(() => {
    const ownerObj = {};
    for (let index = 0; index < opportunity?.leadOpportunityUsers?.length; index++) {
      const element = opportunity.leadOpportunityUsers[index];
      console.log(element);
      ownerObj[`leadOpportunityUsers-${element.id}`] = +element.userId;
    }
    form.setFieldsValue({ ...ownerObj });
    setuserFormData(opportunity?.leadOpportunityUsers || []);
  }, [opportunity]);
  const [updateLeadOpportunity, { loading: updateOpportunityLoader }] =
    useMutation(UPDATE_LEAD_OPPORTUNITY);
  const handleSave = async () => {
    const res = await updateLeadOpportunity({
      variables: {
        leadOpportunityId: opportunity.id,
        leadOpportunityUsers: userFormData.map(item => ({
          id: item.id,
          userTypeId: item.userTypeId,
          userId: item.userId,
        })),
      },
    });
    if (res.data.updateLeadOpportunity.statusCode === 200) {
      message.success(res.data.updateLeadOpportunity.message);
      refetch();
      refetchLeads();
      closeDialog("editLeadOpportunityUsers");
    } else {
      message.error(res.data.updateLeadOpportunity.message);
    }
  };
  return (
    <>
      <Form onFinish={handleSave} layout="vertical" form={form}>
        {leadOpportunityUsers.length > 0
          ? leadOpportunityUsers.map((item, index) => (
              <UserSelector
                key={index}
                validation
                item={{ name: item.userType.name, item: item }}
                name={`leadOpportunityUsers-${item.id}`}
                placeholder={`Select ${item.userType.name}`}
                handleUsersSelection={value =>
                  setuserFormData(prev => {
                    const temp = structuredClone(prev);
                    temp[index] = {
                      ...temp[index],
                      userTypeId: +item.userTypeId,
                      userId: value,
                      userType: item.name,
                    };
                    return temp;
                  })
                }
                allowClear
                multiple={false}
                showAll={false}
                disabled={!canAddEditOpportunity}
              />
            ))
          : userTypes.map((item, index) => (
              <Form.Item
                rules={[{ required: true, message: `${item.name} is required` }]}
                name={`leadOpportunityUsers-${item.id}`}
                key={index}
                label={item.name}
              >
                <Select
                  allowClear
                  onChange={value => {
                    setuserFormData(prev => {
                      const temp = structuredClone(prev);
                      temp[index] = {
                        ...temp[index],
                        userTypeId: +item.id,
                        userId: value,
                        userType: item.name,
                      };
                      return temp;
                    });
                  }}
                  placeholder={`Select ${item.name} opportunity user`}
                  name={`leadOpportunityUsers-${item.id}`}
                >
                  {(userList || []).map((item, i) => (
                    <Select.Option key={i} value={+item.id}>
                      {item.fullName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ))}
      </Form>
      <DialogFooter
        loading={updateOpportunityLoader}
        handleConfirm={() => form.submit()}
        handleCancel={() => closeDialog("editLeadOpportunityUsers")}
      />
    </>
  );
}
