export const toUpperCase = str => {
  if (str === undefined) {
    return "";
  }
  const arr = str.split(" ");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

export const parsePhoneNumber = str => {
  const phoneNumberRegex =
    /^\s*(?:\w*?:)?\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/gi;
  return str.match(phoneNumberRegex);
};
