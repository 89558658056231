import React, { useState } from "react";
import { Upload, Popover, message } from "antd";
import CustomButton from "../components/commonComponents/button";
import AntdIconV4 from "./antdIcon";
import DialogFooter from "../components/commonComponents/dialogFooter";
import { GRAPHQL_URL } from "../config";
import useLang from "../hooks/useLang";

const LEAD_DETAILS = ["First name", "Last name", "Middle name", "Email", "Phone", "DOB", "Address"];
const ADDRESS_DETAILS = ["City", "State", "Country", "Make", "Model", "Year", "Original Source"];

const CsvImport = props => {
  const { onClose, handlePopupClose } = props;

  const [lang] = useLang();

  const [file, setFile] = useState(null);

  let api_url = GRAPHQL_URL;
  [api_url] = api_url.split("/graph");

  const uploadProps = {
    accept: ".csv",
    beforeUpload: file => {
      setFile(file);
      return false;
    },
    onRemove: () => {
      setFile(null);
    },
  };

  const handleUpload = () => {
    const hide = message.loading("Upload In Progress...", 0);
    const formData = new FormData();
    formData.append("file", file);
    fetch(`${api_url}/life-event/import`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then(res => res.json())
      .then(results => {
        hide();
        if (results && results.ok) {
          message.success(lang.uploadedSuccessfully);
        } else {
          message.error(lang.unableUploadDocument);
        }
        setFile(null);
      })
      .catch(err => {
        hide();
        console.log(err);
        message.error(lang.errorDuringUpload);
      });
  };

  return (
    <>
      <div className="cursor-pointer mb-[26px]">
        <p>{lang.csvImportsInfo}</p>
        <Popover
          title={lang.csvImportsRequirments}
          content={
            <>
              <p>{lang.csvImportsHeader}</p>
              <div className="flex justify-around flex-row w-full">
                <ul>
                  {LEAD_DETAILS.map(label => (
                    <li key={label}>{label}</li>
                  ))}
                </ul>
                <ul>
                  {ADDRESS_DETAILS.map(label => (
                    <li key={label}>{label}</li>
                  ))}
                </ul>
              </div>
            </>
          }
        >
          <AntdIconV4 type="InfoCircleOutlined" /> {lang.formatRequirements}
        </Popover>
      </div>
      <Upload {...uploadProps} multiple="false" maxCount={1}>
        <CustomButton
          icon={<AntdIconV4 type="CloudUploadOutlined" />}
          btnText={lang.selectCsvToImport}
        />
      </Upload>
      <DialogFooter
        handleConfirm={handleUpload}
        confirmBtnText={lang.uploadCsv}
        handleCancel={handlePopupClose || onClose}
        disabled={!file}
      />
    </>
  );
};

export default CsvImport;
