/* eslint-disable react/destructuring-assignment */
import * as React from "react";

const TimerIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    className="text-[1em]"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill={props.color || "#64748B"}
      d="M17.125 9.677A8.135 8.135 0 1 1 8.323.875a.627.627 0 0 1 .104 1.25 6.884 6.884 0 1 0 7.448 7.448.627.627 0 1 1 1.25.104Zm-8.75-5.052V9A.625.625 0 0 0 9 9.625h4.375a.625.625 0 1 0 0-1.25h-3.75v-3.75a.625.625 0 1 0-1.25 0ZM11.5 2.75a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Zm2.813 1.875a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Zm1.874 2.812a.937.937 0 1 0 0-1.874.937.937 0 0 0 0 1.874Z"
    />
  </svg>
);
export default TimerIcon;
