import React from "react";
import { closeModal, openModal } from "../../../library/rxjsSubjects";
import Dialog from "./dialog";

class DialogHoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {},
    };

    openModal.subscribe((...params) => this.openDialog(...params));
    closeModal.subscribe((...params) => this.closeDialog(...params));
  }

  openDialog(dialog) {
    if (typeof dialog[1] !== "undefined" && dialog[1].closeAll === true) {
      setTimeout(() => {
        this.setState(previousState => ({
          ...previousState,
          dialog: {
            [dialog[0]]: {
              open: true,
              type: dialog[0],
              dialogData: dialog[1] || {},
            },
          },
        }));
      }, 20);
      return;
    }

    this.setState(previousState => ({
      ...previousState,
      dialogData: dialog[1] || {},
      open: true,
      type: dialog[0] || "",
    }));

    this.setState(
      previousState => ({
        ...previousState,
        dialog: {
          ...previousState.dialog,
          [dialog[0]]: {
            open: false,
            type: dialog[0],
            dialogData: dialog[1] || {},
          },
        },
      }),
      () => {
        setTimeout(() => {
          this.setState(previousState => ({
            ...previousState,
            dialog: {
              ...previousState.dialog,
              [dialog[0]]: {
                ...previousState.dialog[dialog[0]],
                open: true,
              },
            },
          }));
        }, 20);
      }
    );
  }

  closeDialog(type) {
    const stateObject = { ...this.state };

    let dialogContent = { ...stateObject.dialog };
    if (dialogContent[type]) {
      dialogContent[type].open = false;
    }
    this.setState(
      previousState => ({
        ...previousState,
        dialog: dialogContent,
      }),
      () => {
        if (type) {
          delete dialogContent[type];
        } else {
          dialogContent = {};
        }

        setTimeout(() => {
          this.setState(previousState => ({
            ...previousState,
            dialog: dialogContent,
          }));
        }, 30);
      }
    );
  }

  render() {
    const { dialog } = this.state;
    return (
      <div>
        {Object.values(dialog).length > 0 &&
          Object.values(dialog).map((dialog, index) => {
            return (
              <Dialog
                open={dialog.open}
                type={dialog.type}
                handleClose={() => {
                  this.closeDialog(null, dialog.type);
                  dialog?.dialogData?.handleClose?.(null);
                }}
                openDialogHandler={this.openDialog}
                dialogData={dialog.dialogData}
                key={index}
              />
            );
          })}
      </div>
    );
  }
}

export default DialogHoc;
