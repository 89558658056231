import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    setDrawerStatus: (state, action) => {
      state.open = action.payload.open;
    },
  },
});

export const { setDrawerStatus } = drawerSlice.actions;

export const getDrawerStatus = state => state.drawer.open;

export default drawerSlice.reducer;
