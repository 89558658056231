import React from "react";
import { Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import BarChart from "./barChart";
import AntdIconV4 from "../../../utils/antdIcon";
import PieChart from "./pieChart";
import { closeDialog, openDialog } from "../../../library/helpers";
import { getStatements } from "../../../redux/slices/languageSlice";
import { LAST_MONTH, LAST_WEEK, THIS_MONTH, THIS_WEEK } from "./dashboardHelpers";
import useLang from "../../../hooks/useLang";

const GraphAnalysis = props => {
  const {
    label,
    graphKey,
    handleGraphChanges,
    graphChanger,
    data,
    filterType,
    setFilterType,
    API,
    loader = false,
    isDialog = false,
  } = props;

  const [lang] = useLang();

  const handleFilter = filterType => {
    setFilterType(filterType);
    API({
      filterType: filterType,
    });
  };

  const handleGraphDialog = () => {
    openDialog("dashboardGraph", {
      dialogHeader: label,
      label,
      graphKey,
      graphChanger,
      handleGraphChanges,
      width: "700",
      isDialog: true,
      filterType,
      setFilterType,
      API,
      data,
      handleFilter,
      handleCancelBtn: () => closeDialog("dashboardGraph"),
    });
  };

  return (
    <div className="flex-1 px-2 sectionBackground shadow w-full">
      <div id="antdDropdown" className="flex p-2 w-full justify-between">
        <p className="text-[18px] font-semibold labelColor">{label}</p>
        {!isDialog && (
          <div className="flex gap-2 items-center">
            {data.length > 0 && (
              <Tooltip title={lang.barChart}>
                <div>
                  <AntdIconV4
                    onClick={() => handleGraphChanges(graphKey)}
                    className={`text-2xl cursor-pointer ${
                      !graphChanger[graphKey] ? "labelColor" : ""
                    } hover:-translate-y-1 transition-all ease-in-out delay-75`}
                    type="BarChartOutlined"
                  />
                </div>
              </Tooltip>
            )}
            {data.length > 0 && (
              <Tooltip title={lang.pieChart}>
                <div>
                  <AntdIconV4
                    onClick={() => handleGraphChanges(graphKey)}
                    className={`text-2xl cursor-pointer ${
                      graphChanger[graphKey] ? "labelColor" : ""
                    } hover:-translate-y-1 transition-all ease-in-out delay-75`}
                    type="PieChartOutlined"
                  />
                </div>
              </Tooltip>
            )}
            {!isDialog && data.length > 0 && (
              <Tooltip title={lang.expand}>
                <div>
                  <AntdIconV4
                    onClick={() => handleGraphDialog()}
                    className="text-2xl cursor-pointer hover:-translate-y-1 transition-all ease-in-out delay-75"
                    type="FullscreenOutlined"
                  />
                </div>
              </Tooltip>
            )}
            <Select
              value={filterType}
              className="w-[120px]"
              getPopupContainer={() => document.getElementById("antdDropdown")}
              options={[
                {
                  value: THIS_MONTH,
                  label: lang.thisMonth,
                },
                {
                  value: LAST_MONTH,
                  label: lang.lastMonth,
                },
                {
                  value: THIS_WEEK,
                  label: lang.thisWeek,
                },
                {
                  value: LAST_WEEK,
                  label: lang.laseWeek,
                },
              ]}
              onChange={handleFilter}
            />
          </div>
        )}
      </div>
      {!graphChanger[graphKey] ? (
        <BarChart graphData={data} loader={loader} />
      ) : (
        <PieChart graphData={data} loader={loader} />
      )}
    </div>
  );
};

export default GraphAnalysis;
