import { LinkOutlined, SearchOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Image, Input, List, Skeleton, Tooltip, message } from "antd";
import { isEmpty, isUndefined } from "lodash";
import React, { useState } from "react";
import { GET_LOCATION_VEHICLE_INVENTORY } from "../../../graphql/query";
import { closeDialog } from "../../../library/helpers";
import DialogFooter from "../../commonComponents/dialogFooter";
import { CREATE_VDP_LINK_INVENTORY } from "../../../graphql/mutation";
import { currencies } from "../../../library/constants";
import { formatPrice, getHighlightedText } from "../../../library/utils";
import useLang from "../../../hooks/useLang";

export default function VDPSelector(props) {
  const [lang] = useLang();
  const { text, setmessageText } = props;
  const [selectedVehicle, setselectedVehicle] = useState([]);
  const [search, setsearch] = useState("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const { data: vdpData, loading: vdpLoader } = useQuery(GET_LOCATION_VEHICLE_INVENTORY, {
    fetchPolicy: "cache-and-network",
    variables: {
      locationId: sessionStorage.getItem("currentLocationId"),
      search,
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
  });
  const handleAddKeyword = keyword => {
    const ctl = document.getElementById("text");
    const startPos = ctl.selectionStart;

    text.current.value = [
      text.current.value.slice(0, startPos),
      `${keyword} `,
      text.current.value.slice(startPos),
    ].join("");
    if (text.current) {
      text.current.focus();
      text.current.style.height = "inherit";
      text.current.style.height = `${text.current.scrollHeight}px`;
    }
    setmessageText(text.current.value);
  };
  const [createVdpLinks, { loading: vdpLinkLoader }] = useMutation(CREATE_VDP_LINK_INVENTORY);
  const parseLink = async () => {
    const res = await createVdpLinks({
      variables: {
        locationId: sessionStorage.getItem("currentLocationId"),
        vehicleIds: selectedVehicle.map(item => +item),
      },
    });
    if (res.data.createVdpLinksVehicleInventory.statusCode === 200) {
      message.success(res?.data?.createVdpLinksVehicleInventory?.message);
      const fullString = res?.data?.createVdpLinksVehicleInventory?.vdpLinks.join("\n");
      return fullString;
    }
    message.error(res?.data?.createVdpLinksVehicleInventory?.message);
  };
  const getVinLink = item => {
    if (item?.location?.inventoryUrlFormat) {
      const url = item?.location?.inventoryUrlFormat || "";
      const isNewOrUsed = item?.isNew ? "new" : "used";
      return url
        .replace("{vinNumber}", item?.vin)
        .replace("{vehicleUsedOrNew}", isNewOrUsed)
        .replace("{vehicleMake}", item?.make);
    }
    return "";
  };
  const count = vdpData?.getVehicleInventory?.count || 0;
  return (
    <div>
      <Input
        className="w-full"
        placeholder="Search vehicle by name, vin number or stock number"
        prefix={<SearchOutlined />}
        value={search}
        onChange={e => setsearch(e.target.value)}
      />
      <List
        loading={vdpLoader}
        className="mt-2 max-h-[300px] overflow-y-scroll vdp-scrollbar-hide"
        dataSource={vdpData?.getVehicleInventory?.data || []}
        renderItem={(item, index) => {
          return (
            <div
              key={index}
              onClick={() =>
                setselectedVehicle(prevSelected => {
                  // Check if the maximum selection limit (3) has been reached
                  if (prevSelected.length >= 3) {
                    // If the limit is reached and the item is already selected, remove it
                    if (prevSelected.includes(item.id)) {
                      return prevSelected.filter(id => id !== item.id);
                    }
                    // If the limit is reached and the item is not selected, return the current selection
                    return prevSelected;
                  }
                  // If the maximum selection limit has not been reached, toggle the item as before
                  if (prevSelected.includes(item.id)) {
                    return prevSelected.filter(id => id !== item.id);
                  }
                  return [...prevSelected, item.id];
                })
              }
              className={`flex flex-row p-2 gap-3 mr-4 mb-4 cursor-pointer ${
                selectedVehicle.includes(item.id)
                  ? "border-solid border-[2px] border-brand-300 bg-brand-100"
                  : ""
              }`}
            >
              <Image.PreviewGroup
                items={(item?.vehicleInventoryMedias || []).map(media => media.mediaUrl)}
              >
                <Image
                  onClick={e => e.stopPropagation()}
                  placeholder={<Skeleton active />}
                  fallback="https://funnel-webapp-uploads.s3.amazonaws.com/prod/images/generic-car-not-available-image.png"
                  className="h-auto w-[100px] rounded-[15px]"
                  src={(item?.vehicleInventoryMedias || [])[0]?.mediaUrl || ""}
                  alt="Vehicle"
                />
              </Image.PreviewGroup>
              <div className="flex flex-col gap-3 ml-4 w-full">
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex flex-row items-center justify-between w-full">
                    <span
                      // href={getVinLink(item)}
                      className="text-sm font-bold"
                      // onClick={e => e.stopPropagation()}
                    >
                      {getHighlightedText(
                        `${item?.year === 0 || isUndefined(item?.year) ? "" : item?.year} ${
                          isEmpty(item?.make) ? "" : item?.make
                        } ${isEmpty(item?.model) ? "" : item?.model} ${
                          isEmpty(item?.trim) ? "" : item?.trim
                        }`,
                        search
                      )}{" "}
                    </span>
                    <Tooltip title="Open inventory">
                      <LinkOutlined
                        onClick={e => {
                          e.stopPropagation();
                          window.open(getVinLink(item));
                        }}
                        className="text-blue-500"
                      />
                    </Tooltip>
                  </div>
                  <span className="text-sm text-grey-700">
                    <span className="font-semibold">MSRP</span>{" "}
                    {item?.msrp || item?.sellingPrice
                      ? `${
                          currencies?.find(currency => currency.code === item.currencyUnit)?.symbol
                        } ${formatPrice(item?.msrp || item?.sellingPrice) || "N/A"}`
                      : "Price not available"}
                  </span>
                </div>
                <div className="flex flex-col font-[400] gap-1">
                  <span className="text-sm text-grey-700">
                    <span>{getHighlightedText(item?.vin || "VIN N/A", search)}</span>
                  </span>
                  <span className="text-sm text-grey-700">
                    <span className="font-semibold">Stock No</span>{" "}
                    <span>{getHighlightedText(item?.stockNumber || "N/A", search)}</span>
                  </span>
                  <span className="text-sm text-grey-700">
                    <span className="font-semibold">Model No</span>{" "}
                    <span>{getHighlightedText(item?.modelNumber || "N/A", search)}</span>
                  </span>
                  <span className="text-sm text-grey-700">
                    <span className="font-semibold">Color</span>{" "}
                    <span>
                      {getHighlightedText(item?.exteriorColor || "N/A", search)}
                      <strong>(E)</strong>
                      {", "}
                    </span>
                    <span>
                      {getHighlightedText(item?.interiorColor || "N/A", search)}
                      <strong>(I)</strong>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          );
        }}
        pagination={
          count < 5
            ? null
            : {
                current: pagination.page,
                pageSize: pagination.pageSize,
                total: count || 0,
                showTotal: total => `${lang.total}: ${total} ${lang.vehicles}`,
                pageSizeOptions: ["25", "50", "100", "150", "200", "500"],
                showSizeChanger: true,
                onChange: (page, pageSize) => {
                  setPagination({ page, pageSize });
                },
                onShowSizeChange: (current, size) => {
                  setPagination({ page: current, pageSize: size });
                },
              }
        }
        itemLayout="horizontal"
      />
      <DialogFooter
        loading={vdpLinkLoader}
        showConfirmButton={selectedVehicle.length > 0}
        confirmBtnText={selectedVehicle.length > 1 ? "Add Vehicles" : "Add Vehicle"}
        handleCancel={() => closeDialog("vdpSelect")}
        handleConfirm={async () => {
          handleAddKeyword(await parseLink());
          closeDialog("vdpSelect");
        }}
        align="left"
      />
      <style jsx>{`
        /* For Webkit-based browsers (Chrome, Safari and Opera) */
        .vdp-scrollbar-hide::-webkit-scrollbar {
          display: none;
        }

        /* For IE, Edge and Firefox */
        .vdp-scrollbar-hide {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
      `}</style>
    </div>
  );
}
