/* eslint-disable react/destructuring-assignment */
import * as React from "react";

const BellIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#3B82F6"}
      {...props}
      d="M16.25 21a.75.75 0 0 1-.75.75h-6a.75.75 0 1 1 0-1.5h6a.75.75 0 0 1 .75.75Zm5.567-15.345a10.44 10.44 0 0 0-3.667-4.039.749.749 0 1 0-.8 1.268 8.825 8.825 0 0 1 3.136 3.46.75.75 0 0 0 1.331-.69ZM3.847 6.75a.75.75 0 0 0 .666-.405 8.825 8.825 0 0 1 3.137-3.46.75.75 0 1 0-.8-1.269 10.44 10.44 0 0 0-3.668 4.039.75.75 0 0 0 .665 1.095ZM20 10.5a7.5 7.5 0 1 0-15 0c0 2.463-.448 4.536-1.294 5.994A1.5 1.5 0 0 0 5 18.75h15a1.5 1.5 0 0 0 1.292-2.256C20.448 15.035 20 12.963 20 10.5Z"
    />
  </svg>
);

export default BellIcon;
