/* eslint-disable react/destructuring-assignment */
import * as React from "react";

const EnvalopeIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#A855F7"}
      d="M21 4.5H3a.75.75 0 0 0-.75.75V18a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V5.25A.75.75 0 0 0 21 4.5ZM20.25 18H3.75V6.955l7.743 7.098a.75.75 0 0 0 1.014 0l7.743-7.098V18Z"
    />
  </svg>
);
export default EnvalopeIcon;
