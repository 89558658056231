/* eslint-disable no-case-declarations */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unreachable */
/* eslint-disable no-use-before-define */
import Icon, {
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
  FilterOutlined,
  MoreOutlined,
  SearchOutlined,
  SettingOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Dropdown,
  Empty,
  Input,
  Menu,
  Modal,
  Popover,
  Radio,
  Spin,
  message,
} from "antd";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import { navigate } from "gatsby";
import _, { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import AnalyticsIcon from "../../../static/icons/AnalyticsIcon.svg";
import NotificationIcon from "../../../static/icons/BellIcon.svg";
import ConversationsIcon from "../../../static/icons/ConversationsIcon.svg";
import LifeEventsIcn from "../../../static/icons/LifeEventsIcn.svg";
import ProspectsIcon from "../../../static/icons/ProspectsIcon.svg";
import { BASE_URL_FOR_STATIC_ICONS_AND_IMAGES, background } from "../../config";
import { DELETE_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from "../../graphql/mutation";
import { GET_LEAD_NAMES } from "../../graphql/query";
import useCrmIntegration from "../../hooks/useCrmIntegration";
import CalendarIcon from "../../icons/js/CalendarIcon";
import DoubleTickIcon from "../../icons/js/DoubleTickIcon";
import MessageIcon2 from "../../icons/js/MessageIcon2";
import ReadIcon from "../../icons/js/ReadIcon";
import { colorConfig } from "../../library/colorConfig";
import { NOTIFICATION_TYPES } from "../../library/constants";
import { openDialog } from "../../library/helpers";
import { getHighlightedText, parseTimestamp, prioritizePhones } from "../../library/utils";
import { resetApiCacheStore } from "../../redux/slices/apiCacheSlice";
import { setNoficationFor, setNotificationSortAndFilters } from "../../redux/slices/userSlice";
import AntdIconV4 from "../../utils/antdIcon";
import CustomDivider from "../commonComponents/divider";
import { renderLinks } from "../engagements/activityCenter/engagementHelpers";
import "./notifications.css";
import { setLeadsRedux, setSelectedLeadRedux } from "../../redux/slices/leadSlice";

export const LoginContainter = styled.div`
  width: 100%;
  height: 100vh;
  background: url("${BASE_URL_FOR_STATIC_ICONS_AND_IMAGES + background}") no-repeat center center
    fixed;
  background-size: cover;
  padding: 5em;
  font-family: Helvetica;
  @media (max-width: 678px) {
    padding: 0;
  }
`;

export const PhoneDiv = styled.div`
  input {
    border: 1px solid #d9d9d9;
    width: 373px;
    padding: 5px;
    border-radius: 4px;
  }
`;

export const ProfileButton = styled(Avatar)`
  background-color: #e6f0ff;
  color: ${colorConfig.secondaryDark};
`;

export const DropdownMenu = styled.a`
  margin-top: 0.5em;
  flex: 1;
  max-width: 50px;
`;

export const TitleStyle = styled.div`
    height: 3em;
    line-height: 3em;
    padding-left: 1.5em;
    justify-content: center;
    font-weight: bold;
    text-flex: 20;
    @media (min-width: 668px) {
    padding-left: 18px;
    font-size: 20px;
    line-height: 3.5em;
    display: flex !important;
    }
    @media (max-width: 800px) {
    padding-left: 18px;
    padding-right: 10px;
    font-size: 15px;
    line-height: 3.5em;
    display: flex !important;
    }import { data } from 'data';
`;

export const notifTypeMap = {
  RESPONSE: NotificationIcon,
  LIFE_EVENTS: LifeEventsIcn,
  PROSPECTS: ProspectsIcon,
  ANALYTICS: AnalyticsIcon,
  CONVERSATIONS: ConversationsIcon,
  OTHER: NotificationIcon,
};

export const titleMap = {
  "/analytics": "Analytics",
  "/prospect": "Prospects",
  "/life-events": "Life Events",
  "/engagements": "Engagements",
  "/conversation": "Conversations",
  "/admin-dashboard": "Admin Dashboard",
  "/reviews": "Reviews",
  "/settings": "Settings",
  "/personalized-ads": "Personalized Ad Export",
};

export const fsIdentify = process.env.GATSBY_FS_ORG_ID
  ? user => {
      window?.FS?.identify(`${user.id}`, {
        displayName: `${user.fullName}`,
        email: `${user.email}`,
        company: `${user.company.name}`,
      });
    }
  : user => {
      // console.log("Fullstory dev env placeholder: " + user.fullName)
    };

export const posthogIdentify = process.env.GATSBY_POSTHOG_API_KEY
  ? user => {
      window?.posthog?.identify(`${user.id}`, {
        displayName: `${user.fullName}`,
        email: `${user.email}`,
        company: `${user.company.name}`,
      });
    }
  : user => {
      // console.log("Posthog dev env placeholder: " + user.fullName)
    };

export const convertToInitials = string => {
  if (typeof string !== "string") {
    return null;
  }
  return string
    .split(" ")
    .slice(0, 2)
    .map(word => word.slice(0, 1).toUpperCase())
    .join("");
};
function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

export const handleProfileMenuClick = (
  e,
  setShowSupport,
  deleteFcmDevice = () => {},
  persistor = null,
  dispatch = null
) => {
  console.log("SMAI:handleProfileMenuClick: e", e);
  function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }
  const signOut = () => {
    Auth.signOut().then(async () => {
      const regData = {
        registrationId: sessionStorage.getItem("fcmToken"),
      };
      if (regData?.registrationId) {
        await deleteFcmDevice({
          variables: {
            ...regData,
          },
        });
      }
      if (persistor) {
        console.log("SMAI:log out:purging persisted data");
        persistor.pause();
        persistor.flush().then(() => {
          return persistor.purge();
        });
      }
      if (dispatch) {
        console.log("SMAI:handleProfileMenuClick:log out:resetting cache");
        dispatch(resetApiCacheStore({}));
      }
      sessionStorage.clear();
      localStorage.clear();
      deleteAllCookies();
      if (process.env.GATSBY_POSTHOG_API_KEY) {
        window?.posthog?.reset();
      }
      sessionStorage.setItem("isLogin", false);
    });
  };
  switch (e?.key) {
    case "logout":
      localStorage.setItem("showPhone", true);
      localStorage.setItem("callSocket", true);
      if (window.location.href.includes("browser-ext")) {
        navigate(window.location.href);
      } else {
        navigate("/");
      }
      signOut();
      break;
    case "refresh":
      if (persistor) {
        persistor?.pause();
        persistor?.flush()?.then(() => {
          return persistor?.purge();
        });
      }
      if (dispatch) {
        console.log("SMAI:handleProfileMenuClick:log out:resetting cache");
        dispatch(resetApiCacheStore({}));
      }
      break;
    case "settings":
      navigate("/settings");
      break;
    case "support":
      openDialog("supportModal", {
        dialogHeader: "Need to report an issue? Have a question?",
      });
      break;
    default:
      break;
  }
};

export const handleNotifMenuClick = async (
  notif,
  setShowNotifModal,
  setSelectedNotif,
  markNotificationAsRead,
  locationIdslice,
  handleDispatch,
  setSelectedLeadPhoneToRedux,
  crmIntegration,
  dispatch
) => {
  if (notif?.clickableLink) {
    setShowNotifModal(false);
    const splitWithQuastionMark = notif?.clickableLink.split("?");
    const splitWithSlash = splitWithQuastionMark[0].split("/");
    const phoneNumber = splitWithSlash.pop().split("?")[0];
    const selectedPhoneNumber = !isEmpty(notif.lead.phoneNumbers)
      ? prioritizePhones(notif.lead.phoneNumbers, crmIntegration)
      : phoneNumber;

    if (selectedPhoneNumber) {
      setSelectedLeadPhoneToRedux((selectedPhoneNumber || "")?.replace("+", ""));
      notif?.locationId?.toString() &&
        notif?.locationId?.toString() !== sessionStorage.getItem("currentLocationId") &&
        handleDispatch &&
        handleDispatch(notif?.locationId?.toString());
      notif?.locationId?.toString() &&
        notif?.locationId?.toString() !== sessionStorage.getItem("currentLocationId") &&
        sessionStorage.setItem("currentLocationId", notif?.locationId?.toString());
      markNotificationAsRead([notif]);
      // dispatch(setNotificationLeadId(notif.leadId));

      if (window.location.pathname.includes("browser-ext")) {
        // window.history.pushState(
        //   "",
        //   "",
        //   `/browser-ext/${notif?.clickableLink.split("engagements/activity-center/").pop()}`
        // );
        navigate(
          `/browser-ext/${selectedPhoneNumber.replace("+", "")}?loc=${
            notif.lead.locationId
          }&leadId=${notif.leadId}`
        );
      } else {
        // window.history.pushState("", "", `/engagements/activity-center/${clickableLink}`);
        // window.location = `/engagements/activity-center/${clickableLink}`;

        navigate(
          `/engagements/activity-center/${selectedPhoneNumber.replace("+", "")}?loc=${
            notif.lead.locationId
          }&leadId=${notif.leadId}`
        );
      }
    }
  } else {
    setShowNotifModal(true);
    setSelectedNotif(notif);
  }
};

export const profileMenu = (
  setShowSupport,
  deleteFcmDevice,
  handleActiveLeadFilterOnLogout,
  setLocationToNullOnLogout,
  isPlatformAdminUser = false,
  persistor = null,
  dispatch = null
) => (
  <Menu
    onClick={e => {
      if (e?.key === "logout") {
        setLocationToNullOnLogout();
        handleActiveLeadFilterOnLogout();
        sessionStorage.clear();
        dispatch(setLeadsRedux([]));
        dispatch(setSelectedLeadRedux({}));
      }
      handleProfileMenuClick(e, setShowSupport, deleteFcmDevice, persistor, dispatch);
    }}
    style={{ width: "140px", marginRight: "12px", marginTop: "-2px" }}
  >
    <Menu.Item key="refresh">
      <div className="flex items-center">
        <AntdIconV4 type="ReloadOutlined" className="pr-1" /> Refresh Data
      </div>
    </Menu.Item>
    {!isPlatformAdminUser && (
      <Menu.Item key="settings">
        <div className="flex items-center">
          <AntdIconV4 type="SettingFilled" className="pr-1" /> Settings
        </div>
      </Menu.Item>
    )}
    {!isPlatformAdminUser && (
      <Menu.Item key="support">
        <div className="flex items-center">
          <AntdIconV4 type="QuestionCircleOutlined" className="pr-1" /> Support
        </div>
      </Menu.Item>
    )}
    {!isPlatformAdminUser && <Menu.Divider />}
    <Menu.Item key="logout">
      <div className="flex items-center">
        <AntdIconV4 type="LogoutOutlined" className="pr-1" />
        Log out
      </div>
    </Menu.Item>
  </Menu>
);

export const NotificationModal = ({ visible, setVisible, notification, markAsRead }) => {
  return (
    <Modal
      title="Notification"
      open={visible}
      onOk={() => {
        markAsRead(notification);
        setVisible(false);
      }}
      onCancel={() => setVisible(false)}
    >
      {notification && (
        <div className="flex items-center gap-2">
          <div className="bg-[#fbfafa] h-[40px] w-[40px] rounded-[45px] text-center align-middle">
            <Badge count={notification.read ? 0 : 1} dot>
              <Icon
                className="text-[18px] mt-[7px] align-middle leading-none text-[#E6F0FF]"
                component={notifTypeMap[notification.notificationType]}
              />
            </Badge>
          </div>
          <div className="pl-[10px]">
            <div className="text-[#c4c1c2] text-[12px]">{notification.notificationType}</div>
            <div className="text-[#c4c1c2] text-[12px]">{notification.text}</div>
            <div className="text-[#c4c1c2] text-[12px]">
              {parseTimestamp(dayjs(new Date(notification.date), "MM/DD/YYYY HH:mm:ss"))}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

const Notification = ({
  n,
  index,
  setShowNotifModal,
  setSelectedNotif,
  markNotificationAsRead,
  deleteNotificationHandler,
  locationIdslice,
  handleDispatch,
  setSelectedLeadPhoneToRedux,
  checkedNotifications,
  onClose,
}) => {
  const firstNotification = n.notifications[0];
  const [expand, setexpand] = useState(false);
  const [showSelector, setshowSelector] = useState(false);
  const IconRender = ({ notification }) => {
    const Title = ({ children }) => <span className="text-sm text-[#0f172a]">{children}</span>;
    switch (notification.notificationType) {
      case NOTIFICATION_TYPES.NEW_MESSAGE_FROM_LEAD:
        return (
          // <div className={`${commonClassName} bg-[#CCFBF1]`}>
          <>
            <MessageIcon2 /> <Title>New Message</Title>
          </>
          // </div>
        );
      case NOTIFICATION_TYPES.APPOINTMENT_SCHEDULED:
        return (
          // <div className={`${commonClassName} bg-brand-100`}>
          <>
            <CalendarIcon color="#3B82F6" /> <Title>Appointment Scheduled</Title>
          </>
          // </div>
        );
      case NOTIFICATION_TYPES.APPOINTMENT_CANCELLED:
        return (
          // <div className={`${commonClassName} bg-[#FEE2E2]`}>
          <>
            <CalendarIcon color="#EF4444" /> <Title>Appointment Cancelled</Title>
          </>
          // </div>
        );
      case NOTIFICATION_TYPES.APPOINTMENT_RESCHEDULED:
        return (
          // <div className={`${commonClassName} bg-[#F3E8FF]`}>
          <>
            <CalendarIcon color="#A855F7" /> <Title>Appointment Rescheduled</Title>
          </>
          // </div>
        );
      case NOTIFICATION_TYPES.REVIEW_REQUEST:
        return (
          // <div className={`${commonClassName} bg-[#FEF3C7]`}>
          <>
            <StarOutlined color="#F59E0B" /> <Title>Review Request</Title>
          </>
          // </div>
        );
      default:
        return <MessageIcon2 />;
    }
  };
  // markNotificationAsRead(n.notifications[0])
  const actionMenu = isRead => {
    return [
      {
        key: 1,
        label: (
          <span className="flex flex-row items-center gap-1">
            <EyeOutlined />
            Mark as Read
          </span>
        ),
        // disabled: isRead,
      },
      // {
      //   key: 2,
      //   label: (
      //     <span className="flex flex-row items-center gap-1">
      //       <DeleteOutlined />
      //       Delete
      //     </span>
      //   ),
      // },
    ];
  };
  const handleActionMenu = (e, isRead) => {
    switch (+e.key) {
      case 1:
        markNotificationAsRead(n.notifications, isRead ? 1 : 0, true);
        break;
      case 2:
        // delete notification
        deleteNotificationHandler(n.notifications);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (checkedNotifications.includes(n.notifications[0].id)) {
      setshowSelector(true);
    }
    if (checkedNotifications.length === 0) {
      setshowSelector(false);
    }
  }, [checkedNotifications]);
  const crmIntegration = useCrmIntegration();
  const dispatch = useDispatch();
  return (
    <div
      className={`cursor-pointer p-2 border-solid w-full border-[1px] ${
        expand ? "bg-brand-50" : "bg-[#F8FAFC]"
      } rounded ${expand ? "border-brand-500" : "border-[#CBD5E1]"}`}
      // onMouseEnter={() => setshowSelector(true)}
      // onMouseLeave={() => {
      //   if (checkedNotifications.includes(n.notifications[0].id)) {
      //     return;
      //   }
      //   setshowSelector(false);
      // }}
      key={index}
    >
      <div className="flex flex-col items-start gap-1.5">
        <div className="flex flex-row items-center justify-between w-full">
          <div
            onClick={() => {
              handleNotifMenuClick(
                firstNotification,
                setShowNotifModal,
                setSelectedNotif,
                markNotificationAsRead,
                locationIdslice,
                handleDispatch,
                setSelectedLeadPhoneToRedux,
                crmIntegration,
                dispatch
              );
              onClose();
            }}
            className="flex flex-row items-center gap-1"
          >
            <span className="text-sm font-[600] text-grey-700" style={{ wordBreak: "break-all" }}>
              {(n?.notifications &&
                n?.notifications.length > 0 &&
                n?.notifications[0]?.lead?.fullName) ||
                ""}{" "}
            </span>
            {n?.notifications && n?.notifications.length > 0 && !n.notifications[0].read && (
              <div className="mr-1">
                <ReadIcon />
              </div>
            )}
          </div>
          <Dropdown
            menu={{
              items: actionMenu(
                n?.notifications && n?.notifications.length > 0 && n.notifications[0].read
              ),
              onClick: e =>
                handleActionMenu(
                  e,
                  n?.notifications && n?.notifications.length > 0 && n.notifications[0].read
                ),
            }}
          >
            <Button onClick={e => e.stopPropagation()} type="text" className="text-right">
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
        <NotificationItem
          IconRender={IconRender}
          item={n.notifications[0]}
          firstNotification={firstNotification}
          setShowNotifModal={setShowNotifModal}
          setSelectedNotif={setSelectedNotif}
          markNotificationAsRead={markNotificationAsRead}
          locationIdslice={locationIdslice}
          handleDispatch={handleDispatch}
          setSelectedLeadPhoneToRedux={setSelectedLeadPhoneToRedux}
          onClose={onClose}
        />
        {expand && (
          <div>
            {n.notifications.slice(1, n.notifications.length).map((item, index) => (
              <div key={index}>
                <div className="w-full h-[1px] bg-grey-200 my-3" />
                <NotificationItem
                  IconRender={IconRender}
                  item={item}
                  firstNotification={firstNotification}
                  setShowNotifModal={setShowNotifModal}
                  setSelectedNotif={setSelectedNotif}
                  markNotificationAsRead={markNotificationAsRead}
                  locationIdslice={locationIdslice}
                  handleDispatch={handleDispatch}
                  setSelectedLeadPhoneToRedux={setSelectedLeadPhoneToRedux}
                  onClose={onClose}
                />
              </div>
            ))}
          </div>
        )}
        {n.notifications.length > 1 && (
          <span
            className="text-brand-500 text-sm"
            onClick={e => {
              e.stopPropagation();
              setexpand(prev => !prev);
            }}
          >
            {expand ? "Show less" : `${n.notifications.length - 1} more notifications`}
          </span>
        )}
      </div>
    </div>
  );
};

export const NotificationsDropdown = ({
  notifications: list,
  setShowNotifModal,
  setSelectedNotif,
  getMoreNotifications,
  hasMore,
  setSelectedLeadPhoneToRedux,
  locationIdslice,
  handleDispatch,
  refetchNotifications,
  notificationLoader,
  onClose,
  refetchNotificationsCount,
  setNotifications,
}) => {
  const filteredNotifications = list.filter(item => item?.notifications?.length > 0);
  const dispatch = useDispatch();
  const settingsRef = useRef();
  const filterRef = useRef();
  const [checkedNotifications, setcheckedNotifications] = useState([]);
  const {
    type: notificationFilter,
    isReadOnly: showReadNotifications,
    sortBy,
    notificationsFor,
    leadIds,
  } = useSelector(state => state.user.notificationFilters);
  const [updateNotification, { loading }] = useMutation(UPDATE_NOTIFICATIONS, {
    onCompleted: res => {
      if (res.updateNotification.statusCode === 200) {
        // message.success(res.updateNotification.message);
        refetchNotificationsCount();
        refetchNotifications().then(res => {
          setNotifications(res?.data?.getNotifications || []);
        });
      } else {
        message.error(res.updateNotification.message);
      }
    },
  });
  const [deleteNotifications, { loading: deleteLoader }] = useMutation(DELETE_NOTIFICATIONS, {
    onCompleted: res => {
      if (res.deleteNotification.statusCode === 200) {
        // message.success(res.deleteNotification.message);
        refetchNotifications();
      } else {
        message.error(res.deleteNotification.message);
      }
    },
  });
  const notificationIds = list?.getNotifications?.flatMap(entry =>
    entry.notifications.map(notification => notification?.id)
  );
  const markNotificationAsRead = async notifications => {
    updateNotification({
      variables: {
        notificationInputs: notifications.map(item => ({
          id: item.id,
          read: true,
        })),
        leadIds: [notifications[0].leadId],
      },
    });
  };
  const deleteNotificationHandler = async notifications => {
    deleteNotifications({
      variables: {
        notificationIds: notifications.map(item => +item.id),
      },
    });
  };
  const Title = ({ children }) => (
    <span className="text-[#374151] text-sm font-[600]">{children}</span>
  );
  const SortAndFilterContent = () => {
    const [sortAndFilterData, setsortAndFilterData] = useState({
      sortBy: "date",
      filters: [],
    });
    useEffect(() => {
      setsortAndFilterData({ ...sortAndFilterData, filters: notificationFilter, sortBy: sortBy });
    }, [notificationFilter, sortBy]);
    const close = () => filterRef.current.click();
    return (
      <div className="w-[300px] flex flex-col gap-1 items-start">
        {/* <div className="flex flex-col gap-1">
          <Title>Sort By</Title>
          <Radio.Group
            value={sortAndFilterData.sortBy}
            onChange={e => setsortAndFilterData({ ...sortAndFilterData, sortBy: e.target.value })}
            className="flex flex-row items-center justify-evenly"
          >
            <Radio value="date">Date</Radio>
            <Radio value="unread">Unread first</Radio>
            <Radio value="read">Read first</Radio>
          </Radio.Group>
        </div> */}
        <div className="flex flex-col gap-1">
          <Title>Notification Type</Title>
          <Checkbox.Group
            value={sortAndFilterData.filters}
            onChange={checked => setsortAndFilterData({ ...sortAndFilterData, filters: checked })}
          >
            <div className="flex flex-col gap-0.5 items-start">
              {notificationFilters.map((filter, index) => (
                <Checkbox value={filter.value} key={index}>
                  {filter.label}
                </Checkbox>
              ))}
            </div>
          </Checkbox.Group>
        </div>
        <div className="flex flex-row items-center gap-1 w-full">
          <Button
            onClick={() => {
              dispatch(setNotificationSortAndFilters(sortAndFilterData));
              close();
            }}
            className="w-1/2"
            type="primary"
          >
            Apply
          </Button>
          <Button onClick={close} className="w-1/2">
            Cancel
          </Button>
        </div>
      </div>
    );
  };
  const actionMenu = [
    {
      key: 1,
      label: (
        <span className="flex flex-row items-center gap-1">
          <EyeOutlined />
          Mark all as Read
        </span>
      ),
      disabled: filteredNotifications.length === 0,
    },
    // {
    //   key: 2,
    //   label: (
    //     <span className="flex flex-row items-center gap-1">
    //       <DeleteOutlined />
    //       Delete all
    //     </span>
    //   ),
    //   disabled: filteredNotifications.length === 0,
    // },
  ];
  const handleActionMenu = e => {
    switch (+e.key) {
      case 1:
        const result = _.flatMap(list, "notifications").map(notification => ({
          id: notification.id,
          read: true,
        }));
        updateNotification({
          variables: {
            notificationInputs: result,
            leadIds: list.map(item => item.leadId),
          },
        });
        break;
      case 2:
        // delete notification
        deleteNotificationHandler(_.flatMap(list, "notifications"));
        break;
      default:
        break;
    }
  };

  const SettingsContent = () => {
    const [settingsPayload, setSettingsPayload] = useState({
      notificationsFor: "all",
      selectedLeads: [],
    });
    const [search, setsearch] = useState("");
    const [leadList, setleadList] = useState([]);
    const { loading: leadsLoader } = useQuery(GET_LEAD_NAMES, {
      variables: {
        search,
        page: 1,
        pageSize: 20,
      },
      onCompleted: res => {
        setleadList(res.getLeads.data);
      },
    });
    useEffect(() => {
      setSettingsPayload({ notificationsFor, selectedLeads: leadIds });
    }, [notificationsFor, leadIds]);
    const close = () => settingsRef.current.click();
    return (
      <div className="w-[300px] h-[400px] flex flex-col gap-3 items-start">
        <Title>Notification For</Title>
        <Radio.Group
          className="w-full custom-radio"
          value={settingsPayload.notificationsFor}
          onChange={e =>
            setSettingsPayload({ ...settingsPayload, notificationsFor: e.target.value })
          }
        >
          <Radio.Button value="all" className="w-1/2 text-center rounded-l-sm ">
            All
          </Radio.Button>
          <Radio.Button value="customer" className="w-1/2 text-center rounded-r-sm ">
            Customer
          </Radio.Button>
        </Radio.Group>
        {settingsPayload.notificationsFor === "all" ? (
          <p className="text-[#6B7280] mt-2 text-sm">
            On select of notification for all, you will see notifications for the all customers.{" "}
            <br /> <br /> If you want to see notification for particular customer then select
            customer.
          </p>
        ) : (
          <div className="flex flex-col gap-1 w-full">
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search customer"
              className="w-full rounded-sm"
              value={search}
              onChange={e => setsearch(e.target.value)}
            />
            <Spin spinning={leadsLoader} tip="Loading...">
              <Checkbox.Group
                value={settingsPayload.selectedLeads}
                onChange={checked =>
                  setSettingsPayload({ ...settingsPayload, selectedLeads: checked })
                }
                className="w-full"
              >
                <div className="flex gap-3 w-full flex-col max-h-[230px] overflow-y-auto">
                  {leadList.map((item, index) => (
                    <Checkbox value={+item.id} key={index}>
                      <span className="text-sm text-grey-700">
                        {getHighlightedText(item.fullName, search)}
                      </span>
                    </Checkbox>
                  ))}
                </div>
              </Checkbox.Group>
            </Spin>
          </div>
        )}
        <div className="flex mt-auto flex-row items-center gap-1 w-full">
          <Button
            onClick={() => {
              dispatch(setNoficationFor(settingsPayload));
              close();
            }}
            className="w-1/2"
            type="primary"
          >
            Apply
          </Button>
          <Button onClick={close} className="w-1/2">
            Cancel
          </Button>
        </div>
      </div>
    );
  };
  return (
    <div className="bg-white h-full flex flex-col gap-1.5 w-full">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <Popover
            trigger="click"
            placement="bottomLeft"
            showArrow={false}
            content={<SortAndFilterContent />}
          >
            <Badge count={notificationFilter.length}>
              <Button ref={filterRef} className="flex flex-row items-center gap-1">
                <FilterOutlined />
                Sort & Filter
              </Button>
            </Badge>
          </Popover>
          <Popover
            trigger="click"
            placement="bottom"
            showArrow={false}
            content={<SettingsContent />}
          >
            <Badge count={notificationsFor === "all" ? 0 : leadIds.length}>
              <Button ref={settingsRef} className="flex flex-row items-center gap-1">
                <SettingOutlined />
                Settings
              </Button>
            </Badge>
          </Popover>
        </div>
        <Dropdown menu={{ items: actionMenu, onClick: handleActionMenu }}>
          <Button
            type="link"
            disabled={filteredNotifications.length === 0}
            className="flex flex-row items-center gap-1 text-brand-500 cursor-pointer"
          >
            Actions <DownOutlined className="hover:rotate-180 transition-all duration-150 " />
          </Button>
        </Dropdown>
      </div>
      {checkedNotifications.length === 0 ? (
        <span className="flex items-center font-bold text-sm text-[#6b7280]">All Notification</span>
      ) : (
        <div className="flex flex-row mt-1 items-center w-full justify-between ">
          <span
            className="text-sm font-bold text-[#374151] cursor-pointer"
            onClick={() => {
              setcheckedNotifications(_.flatMap(list, "notifications").map(item => item.id));
            }}
          >
            Select All
          </span>
          <div className="flex flex-row items-center gap-3">
            <span className="cursor-pointer text-red-500 flex flex-row items-center gap-0.5 font-bold text-sm">
              <DeleteOutlined />
              Delete
            </span>
            <span
              onClick={async () => {
                await updateNotification({
                  variables: {
                    notificationInputs: checkedNotifications.map(item => ({
                      id: item,
                      read: true,
                    })),
                    leadIds: filteredNotifications.map(item => item.leadId),
                  },
                });
                setcheckedNotifications([]);
              }}
              className="cursor-pointer text-blue-500 flex flex-row items-center gap-0.5 font-bold text-sm"
            >
              <DoubleTickIcon />
              Mark as read
            </span>
            <span
              onClick={() => setcheckedNotifications([])}
              className="cursor-pointer text-sm font-bold text-[#6b7280]"
            >
              Cancel
            </span>
          </div>
        </div>
      )}
      {/* <div className="flex items-center justify-between">
        <Checkbox
          className="flex ml-8"
          indeterminate={
            checkedNotifications.length === 0
              ? false
              : checkedNotifications.length < notificationIds?.length
          }
          checked={allChecked}
          onClick={e => {
            e.stopPropagation();

            allChecked
              ? setcheckedNotifications([])
              : setcheckedNotifications([...notificationIds]);
          }}
        >
          Select all
        </Checkbox>
      </div> */}
      <CustomDivider />
      <div className="overflow-y-auto mx-[-24px]">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={() => getMoreNotifications()}
          hasMore={hasMore}
          useWindow={false}
        >
          <Spin spinning={notificationLoader} tip="Loading...">
            {/* <Checkbox.Group
              value={checkedNotifications}
              onChange={checked => setcheckedNotifications(checked)}
            > */}
            <div className="px-4 flex flex-col items-start gap-3">
              {filteredNotifications.length > 0 ? (
                filteredNotifications.map((n, index) => (
                  <Notification
                    key={index}
                    checkedNotifications={checkedNotifications}
                    handleDispatch={handleDispatch}
                    index={index}
                    locationIdslice={locationIdslice}
                    markNotificationAsRead={markNotificationAsRead}
                    deleteNotificationHandler={deleteNotificationHandler}
                    n={n}
                    setSelectedLeadPhoneToRedux={setSelectedLeadPhoneToRedux}
                    setSelectedNotif={setSelectedNotif}
                    setShowNotifModal={setShowNotifModal}
                    setcheckedNotifications={setcheckedNotifications}
                    onClose={onClose}
                  />
                ))
              ) : (
                <Empty className="w-full" description="No Notifications" />
              )}
            </div>
            {/* </Checkbox.Group> */}
          </Spin>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export const notificationFilters = [
  {
    label: "Unread notifications only",
    value: "unread",
  },
  {
    label: "New message from lead",
    value: "NEW_MESSAGE_FROM_LEAD",
    color: "green",
  },
  {
    label: "Appointment action by lead",
    value: "APPOINTMENT_ACTION_BY_LEAD",
    color: "blue",
  },
  {
    label: "Review request",
    value: "REVIEW_REQUEST",
    color: "cyan",
  },
  {
    value: "RESPONSE_FROM_PROSPECT",
    label: "Response from prospect",
  },
  {
    value: "PROSPECTS",
    label: "Prospects",
  },
  {
    value: "LIFE_EVENTS",
    label: "Life Events",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];
function NotificationItem({
  IconRender,
  item,
  firstNotification,
  setShowNotifModal,
  setSelectedNotif,
  markNotificationAsRead,
  locationIdslice,
  handleDispatch,
  setSelectedLeadPhoneToRedux,
  onClose,
}) {
  const crmIntegration = useCrmIntegration();
  const getRelativeTime = () => {
    const currentDate = dayjs.utc().tz(dayjs.tz.guess());

    const targetDate = dayjs.utc(item.date).tz(dayjs.tz.guess());
    // Calculate the difference in days
    const daysDiff = targetDate.diff(currentDate, "day");

    // Get the relative time for the first 3 days
    let relativeTime = targetDate.fromNow();

    // After the first 3 days, switch to displaying the date
    if (daysDiff < -3) {
      relativeTime = targetDate.format("lll");
    }
    return relativeTime;
  };
  const dispatch = useDispatch();
  return (
    <div
      className="flex flex-col gap-2 w-full"
      onClick={() => {
        handleNotifMenuClick(
          firstNotification,
          setShowNotifModal,
          setSelectedNotif,
          markNotificationAsRead,
          locationIdslice,
          handleDispatch,
          setSelectedLeadPhoneToRedux,
          crmIntegration,
          dispatch
        );
        onClose();
      }}
    >
      <div className="flex flex-row items-center w-full justify-between ">
        <div className="flex flex-row items-center gap-2.5">
          <IconRender notification={item} />
        </div>
        <span className="text-xs text-grey-500">{getRelativeTime(item)}</span>
      </div>
      <div className="flex flex-row">
        <div title="Open in activity center" className="flex flex-col items-start">
          <span
            className="text-sm text-grey-900"
            style={{
              wordBreak: "break-word",
            }}
          >
            {renderLinks(item.text, "black")}
          </span>
        </div>
      </div>
    </div>
  );
}
