/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-no-useless-fragment */
import Icon, {
  ArrowRightOutlined,
  CaretDownOutlined,
  CheckOutlined,
  CheckSquareFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  FrownTwoTone,
  GoogleOutlined,
  PhoneFilled,
  SmileFilled,
  SmileOutlined,
  SmileTwoTone,
  TranslationOutlined,
} from "@ant-design/icons";
import { Avatar, Tooltip, Typography, message, Divider, Button } from "antd";
import { isEmpty, sortBy } from "lodash";
import React, { Fragment, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import EngagedAI from "../../../../static/EngagedAi_trans_bg.svg";
import { UserContext } from "../../../amplify/authenticator-provider";
import useCrmIntegration from "../../../hooks/useCrmIntegration";
import useLang from "../../../hooks/useLang";
import TimerIcon from "../../../icons/js/TimerIcon";
import { MESSAGE_CONTENT_TYPES } from "../../../library/constants";
import { convertUtcToLocal } from "../../../library/utils";
import { getMobileView } from "../../../redux/slices/isMobile";
import AntdIconV4 from "../../../utils/antdIcon";
import { convertToInitials } from "../../layoutComponent/layoutHelpers";
import { renderLinks } from "./engagementHelpers";
import BellIcon from "./icons/BellIcon";
import CancelledIcon from "./icons/CancelledIcon";
import CheckBoxIcon from "./icons/CheckBoxIcon";
import EnvalopeIcon from "./icons/EnvalopeIcon";
import Calendar12Icon from "./icons/calendar";
import HandShake from "./icons/handShake";
import MediaSharing from "./mediaSharing/mediaSharing";
import MultipleMediaView from "./mediaSharing/multipleMediaView";
import "./messages.css";
import { TRANSLATE_MESSAGE } from "../../../graphql/mutation";
import { GET_DETECTED_LANGUAGE, GET_SUPPORTED_LANGUAGES } from "../../../graphql/query";
import { SVGIcon } from "../../../library/basicComponents";
import TranslateIcon from "../../../../static/icons/TranslateIcon.svg";
import GreenEnvalopeIcon from "./icons/greenEnvalope";
import SadIcon from "./icons/SadIcon";
import DealerraterIcon from "../../../../static/icons/DealerraterIcon.svg";
import FacebooksIcon from "../../../../static/icons/FacebooksIcon.svg";
import GoogleIcon from "../../../../static/icons/GoogleIcon.svg";
import YelpIcon from "../../../../static/icons/YelpIcon.svg";
import PencilIcon from "../../../icons/js/PencilIcon";

export const AppointmentBox = styled.div`
  text-align: center;
  margin: 2rem auto;
  width: 75%;
  @media (max-width: 488px) {
    width: 80%;
  }
  & .ant-alert-info,
  .ant-alert-success {
    border: 0;
  }
  & .anticon-calendar,
  .anticon-android {
    font-size: 20px;
    color: #007dae;
  }
  background: red;
`;
export const LineDiv = styled.div`
  &::before {
    background: ${props => props.colorprop} !important;
  }
`;
function parsePhone(selectedLead, crmIntegration, user, c) {
  const toPhoneNumber =
    (c?.messageLog && c?.messageLog.length > 0 && c?.messageLog[0].toPhone) || "";
  return toPhoneNumber;
}

const Messages = props => {
  const {
    key,
    SetContentBy,
    c,
    userIdMap,
    selectedLead,
    handleRetry,
    messageIndex,
    // isTranslationForCustomer,
    // isTranslationForEmployee,
    // targetLangForCustomer,
    // targetLangForEmployee,
    canViewTranslation,
    isIncludeOriginalMessage,
    isAutoTranslate,
  } = props;
  const crmIntegration = useCrmIntegration();
  const { user } = useContext(UserContext);
  const [isOpenOttoMessage, setisOpenOttoMessage] = useState(false);
  const toPhone = parsePhone(selectedLead, crmIntegration, user, c);
  const msgType = (c?.messageType || "").toLowerCase();
  const msgContentType = (c?.contentType || "").toLowerCase();
  const [showMore, setShowMore] = useState(false);
  const [showMoreForReview, setShowMoreForReview] = useState(false);
  const [showMoreForCustomeAlert, setShowMoreForCustomeAlert] = useState(false);
  const [lang] = useLang();
  const isMobileView = useSelector(state => getMobileView(state));
  const [translatedContent, setTranlatedContent] = useState("");
  const [translateMessage, { loading: translateMessageLoader }] = useMutation(TRANSLATE_MESSAGE);
  const [detectedLanguage, setDetectedLanguage] = useState(null);
  const [targetLanguage, setTargetLanguage] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [visible, setVisible] = useState(false);
  const [showTranslatedText, setShowTranslatedText] = useState(true);
  const [visibleTranslateIcon, setVisibleTranslateIcon] = useState(false);

  const { refetch: refetchSupportedLanguages } = useQuery(GET_SUPPORTED_LANGUAGES, {
    onCompleted: res => {
      setLanguages(res?.getSupportedLanguages);
    },
  });

  const [getDetectedLanguage, { loading: getDetectedLanguageLoader }] = useLazyQuery(
    GET_DETECTED_LANGUAGE,
    {
      variables: {
        content: c?.translatedContent || c?.content,
      },
      onCompleted: res => {
        const possibleDetectedLanguage = languages?.find(item =>
          item?.languageCode.includes(res?.getLanguageCode?.code)
        );
        setDetectedLanguage(possibleDetectedLanguage?.languageCode);
      },
    }
  );

  const returnTranslatedContent = () => {
    if (translatedContent) {
      return (
        <>
          {(isIncludeOriginalMessage || translatedContent) && (
            <Divider style={{ margin: "16px 0", background: "#94A3B8" }} />
          )}
          Translated Text:
          <br />
          {renderLinks(translatedContent)}
        </>
      );
    }
  };

  const returnTranslatedContentGlobally = content => {
    if (content) {
      return (
        <>
          {isIncludeOriginalMessage && (
            <Divider style={{ margin: "16px 0", background: "#94A3B8" }} />
          )}
          Translated Text:
          <br />
          {renderLinks(content)}
        </>
      );
    }
  };

  const doTranslation = () => {
    console.log("doTranslation:message", c?.id);
    translateMessage({
      variables: {
        content: c?.content,
        messageId: c?.id,
      },
      onCompleted: res => {
        if (res?.translateMessage?.statusCode === 200) {
          setTranlatedContent(res?.translateMessage?.translatedContent);
          // message.info(res?.translateMessage?.message);
        } else {
          message.info(res?.translateMessage?.message);
        }
      },
    });
  };

  const RenderTranslation = () => {
    return (
      msgType !== "mms" && (
        <Tooltip title="Translate message">
          <span className="items-center">
            <Button type="text">
              <SVGIcon component={TranslateIcon} alt="Translate" onClick={() => doTranslation()} />
            </Button>
          </span>
        </Tooltip>
      )
    );
  };

  const RenderPhone = props => {
    const { direction, messagephone } = props;
    return (
      <div className={`${direction === "FROM_LEAD" ? "text-left" : "text-right"} clear-both`}>
        <span className="text-xs font-[400] text-white">
          {`Message ${direction === "FROM_LEAD" ? "received from" : "sent to"}: ${messagephone}`}
        </span>
      </div>
    );
  };

  const RenderRetry = ({ allowRetry = true }) => {
    const errorMessage = `${c.errorCode || ""} ${c.errorMessage || ""}`;
    return (
      <Typography variant="small" weight="medium">
        <Tooltip placement="top" title={isEmpty(errorMessage.trim()) ? null : errorMessage}>
          <span>
            <AntdIconV4
              type="ExclamationCircleFilled"
              className="text-red-500 text-[14px] mr-[5px]"
            />
          </span>
        </Tooltip>
        {lang.msgFailedSend}.{" "}
        {allowRetry && (
          <div className="text-red-500 cursor-pointer" onClick={e => handleRetry(e, c)}>
            <AntdIconV4 type="ReloadOutlined" className="text-red-500 text-[14px] mx-[5px]" />
            {lang.retry}
          </div>
        )}
        {/* <p className="text-[11px] mb-[2px] text-right mr-[15px]">
      {userIdMap
        ? c?.systemUserId
          ? userIdMap && userIdMap[c?.systemUserId]
          : userIdMap && userIdMap[c?.userId]
        : c.user.fullName}{" "}
      {lang.postedAt}{" "}
      {convertUtcToLocal(c?.dateSent, "ddd, MMM Do YYYY, h:mm A")}
      <br />
      {c?.errorMessage}
    </p> */}
      </Typography>
    );
  };
  const getMessageCategory = () => {
    // use icons from the JSX elements created from figma icons
    let msgCat = {
      title: "Notification",
      // icon: <BellIcon color="#A855F7" />,
      backgroundColor: "#DBEAFE",
    };
    if (msgContentType === MESSAGE_CONTENT_TYPES.WELCOME_MESSAGE.toLowerCase()) {
      // welcome Message
      msgCat = {
        title: "Welcome Message",
        icon: <HandShake />,
        backgroundColor: "#F3E8FF",
      };
    } else if (msgContentType === MESSAGE_CONTENT_TYPES.NEW_MESSAGE_RECEIVED.toLowerCase()) {
      msgCat = {
        title: "New Message Received",
        icon: <EnvalopeIcon />,
        backgroundColor: "#F3E8FF",
      };
    }
    // New Message reminder
    else if (msgContentType === MESSAGE_CONTENT_TYPES.NEW_MESSAGE_REMINDER.toLowerCase()) {
      msgCat = {
        title: "New Message Reminder",
        icon: <EnvalopeIcon />,
        backgroundColor: "#F3E8FF",
      };
    }

    // update appointment status
    // else if()
    // Appointment booked
    else if (
      [
        MESSAGE_CONTENT_TYPES.NEW_APPOINTMENT_CREATED.toLowerCase(),
        MESSAGE_CONTENT_TYPES.NEW_APPOINTMENT_CREATED_TO_LEAD.toLowerCase(),
      ].includes(msgContentType)
    ) {
      msgCat = {
        title: "Appointment Booked",
        icon: <Calendar12Icon />,
        backgroundColor: "#DBEAFE",
      };
    } else if (
      [MESSAGE_CONTENT_TYPES.APPOINTMENT_REMINDER_MESSAGE_TO_LEAD.toLowerCase()].includes(
        msgContentType
      )
    ) {
      msgCat = {
        title: "Appointment Reminder",
        icon: (
          <div className="flex flex-row items-center gap-1">
            <BellIcon color="#3B82F6" />
            <Calendar12Icon />
          </div>
        ),
        backgroundColor: "#DBEAFE",
      };
    } else if (
      [
        MESSAGE_CONTENT_TYPES.APPOINTMENT_CANCELLED.toLowerCase(),
        MESSAGE_CONTENT_TYPES.APPOINTMENT_CANCELLED_TO_LEAD.toLowerCase(),
      ].includes(msgContentType)
    ) {
      // Appointment Cancelled
      msgCat = {
        title: "Appointment Cancelled",
        backgroundColor: "#DBEAFE",
        icon: (
          <div className="flex flex-row items-center gap-1">
            <CancelledIcon />
            <Calendar12Icon />
          </div>
        ),
      };
    } else if (
      [
        MESSAGE_CONTENT_TYPES.APPOINTMENT_CONFIRMED.toLowerCase(),
        MESSAGE_CONTENT_TYPES.APPOINTMENT_CONFIRMED_TO_LEAD.toLowerCase(),
      ].includes(msgContentType)
    ) {
      msgCat = {
        title: "Appointment Confirmed",
        backgroundColor: "#DBEAFE",
        icon: (
          <div className="flex flex-row items-center gap-1">
            <CheckBoxIcon />
            <Calendar12Icon />
          </div>
        ),
      };
    } else if ([MESSAGE_CONTENT_TYPES.REVIEW_REQUEST_SENT.toLowerCase()].includes(msgContentType)) {
      msgCat = {
        title: <span className="text-nowrap">Review Request Sent</span>,
        icon: <SmileOutlined className="text-[#F59E0B]" />,
        backgroundColor: "#FEF3C7",
      };
    } else if (msgContentType.includes("campaign")) {
      msgCat = {
        title: "Campaign",
        icon: <BellIcon color="#A855F7" />,
        backgroundColor: "#DBEAFE",
      };
    } else if (msgContentType.includes("new lead message")) {
      msgCat = {
        title: "Nudge",
        icon: <BellIcon color="#A855F7" />,
        backgroundColor: "#DBEAFE",
      };
    } else if (msgContentType.includes("received message from lead")) {
      msgCat = {
        title: "Nudge",
        icon: <BellIcon color="#A855F7" />,
        backgroundColor: "#DBEAFE",
      };
    } else if (
      [MESSAGE_CONTENT_TYPES.SCHEDULED_MESSAGE_SENT.toLowerCase()].includes(msgContentType)
    ) {
      msgCat = {
        title: "Scheduled Message Sent",
        backgroundColor: "#CCFBF1",
        icon: <TimerIcon color="#14B8A6" />,
      };
    } else if ([MESSAGE_CONTENT_TYPES.SCHEDULED_MESSAGE.toLowerCase()].includes(msgContentType)) {
      msgCat = {
        title: "Scheduled Message",
        backgroundColor: "#CCFBF1",
        icon: <TimerIcon color="#14B8A6" />,
      };
    } else if (
      [MESSAGE_CONTENT_TYPES.SCHEDULED_MESSAGE_CANCELLED.toLowerCase()].includes(msgContentType)
    ) {
      msgCat = {
        title: "Scheduled Message Cancelled",
        backgroundColor: "#fbcccc",
        icon: <TimerIcon color="red" />,
      };
    } else {
      msgCat = {
        title: "Notification",
        icon: <BellIcon color="#A855F7" />,
        backgroundColor: "#DBEAFE",
      };
    }
    return msgCat;
  };
  const messageCategory = useMemo(getMessageCategory, [msgContentType]);
  return isEmpty(c) ? null : msgType !== "mms" ? (
    <div
      id={`msg-${messageIndex}`}
      className="my-4"
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={() => setVisibleTranslateIcon(true)}
      onMouseLeave={() => {
        setVisibleTranslateIcon(false);
        setVisible(false);
      }}
    >
      <Fragment key={c?.id}>
        {["appointment", "otto", "review"].includes(msgType) ? (
          <OttoMessage
            user={user}
            showMoreForCustomeAlert={showMoreForCustomeAlert}
            SetContentBy={SetContentBy}
            c={c}
            userIdMap={userIdMap}
            setShowMoreForCustomeAlert={setShowMoreForCustomeAlert}
            lang={lang}
            selectedLead={selectedLead}
            toPhone={toPhone}
            RenderRetry={RenderRetry}
            msgContentType={msgContentType}
            messageCategory={messageCategory}
            isOpenOttoMessage={isOpenOttoMessage}
            setisOpenOttoMessage={setisOpenOttoMessage}
            isReview={msgType === "review"}
          />
        ) : msgType !== "call" ? (
          <div>
            {c?.direction === "FROM_LEAD" ||
            (c?.direction === "FROM_OTTO" && msgType === "review") ? (
              <div className="flex justify-start" key={c?.id}>
                <div
                  className={
                    !isMobileView
                      ? "w-[70%] lg:w-[60%] max-w-[80%] break-words"
                      : "break-words w-full max-w-full"
                  }
                >
                  {c.messageType === "WIDGET_CHAT" && (
                    <div className="text-right">
                      <AntdIconV4
                        type="MobileFilled"
                        className="w-full text-center text-[#8e0b61]"
                      />
                    </div>
                  )}
                  <div className="absolute left-0 mx-1 flex flex-col mt-[5px]">
                    <Avatar className="bg-[#abc5de]">
                      {convertToInitials(selectedLead?.fullName)}
                    </Avatar>

                    {canViewTranslation && visibleTranslateIcon && <RenderTranslation />}
                  </div>
                  <div className="max-w-[530px]">
                    {msgType === "review" &&
                      (msgContentType?.includes("good") || msgContentType?.includes("bad")) && (
                        <div className="flex items-center justify-end">
                          {c?.contentType?.includes("Good") ? (
                            <SmileTwoTone twoToneColor="#52c41a" className="text-[20px]" />
                          ) : (
                            <FrownTwoTone twoToneColor="red" className="text-[20px]" />
                          )}
                        </div>
                      )}
                    <div className="text-[14px] flex flex-col  px-3 py-2 smaiChatRecieve text-[#1E293B] rounded-[4px] rounded-tl-none p-[1em] mx-[4px] ml-[40px] relative mt-[5px] md:float-none float-left">
                      <div className="flex justify-between items-center">
                        <div>
                          <span className="text-brand-500 font-[600] ">
                            {selectedLead?.fullName}
                          </span>
                        </div>
                        <div className="flex flex-row items-center gap-1">
                          <span className="text-[12px]">
                            {convertUtcToLocal(c?.dateReceived, "ddd, MMM Do YYYY, h:mm A")}
                          </span>
                          <Tooltip
                            title={
                              <RenderPhone
                                direction="FROM_LEAD"
                                messagephone={
                                  (c?.messageLog &&
                                    c?.messageLog.length > 0 &&
                                    c?.messageLog[0]?.fromPhone) ||
                                  ""
                                }
                              />
                            }
                          >
                            <PhoneFilled className="rotate-90" />
                          </Tooltip>
                        </div>
                      </div>
                      {msgType === "review" && (
                        <span className="absolute top-[-12px] left-[15px] py-[2px] px-[10px] bg-yellow-200 text-amber-700 rounded-[6px] text-[12px]">
                          {lang.review}
                        </span>
                      )}
                      <div className="text-[#1E293B] ">
                        {isMobileView
                          ? showMoreForReview
                            ? renderLinks(
                                isIncludeOriginalMessage
                                  ? c?.content
                                  : c?.translatedContent || c?.content
                              ) || ""
                            : `${renderLinks(
                                isIncludeOriginalMessage
                                  ? c?.content?.substring(0, 60)
                                  : c?.translatedContent?.substring(0, 60) || c?.content
                              )}`
                          : renderLinks(
                              isIncludeOriginalMessage
                                ? c?.content
                                : c?.translatedContent || c?.content
                            )}
                        {isMobileView && c?.content?.length > 60 ? (
                          showMoreForReview ? (
                            <span
                              className="showMoreButtonForRecievedMessage"
                              onClick={() => setShowMoreForReview(false)}
                            >
                              {lang.showLess}
                            </span>
                          ) : (
                            <span
                              className="showMoreButtonForRecievedMessage"
                              onClick={() => setShowMoreForReview(true)}
                            >
                              {lang.showMore}
                            </span>
                          )
                        ) : (
                          ""
                        )}
                        {(isIncludeOriginalMessage || translatedContent) && <br />}
                        {isIncludeOriginalMessage &&
                          c?.translatedContent &&
                          !translatedContent &&
                          c?.content !== c?.translatedContent &&
                          returnTranslatedContentGlobally(c?.translatedContent)}
                        {returnTranslatedContent()}
                      </div>
                    </div>
                  </div>
                  {/* <p className="text-left ml-[4px] mb-[4px] text-[11px]">
                    {recievedMessageInfo} <br />
                    {isMobileView ? (showMoreMessageInfo ? opportunity : "") : opportunity}
                    {isMobileView && showMoreToggle}
                  </p> */}

                  {/* {c?.leadOpportunity?.id && (
                  <p className="text-[11px] text-left mt-[1px] mb-[3px] ml-[4px]">
                    {lang.opportunity}: {c?.leadOpportunity?.name} ({handleOpportunityId(c)})
                  </p>
                )} */}
                </div>
              </div>
            ) : (
              <div className="flex justify-end" key={c?.id}>
                <div
                  className={
                    !isMobileView
                      ? "max-w-[80%] md:max-w-[65%] break-words"
                      : "break-words w-[80%] max-w-full "
                  }
                >
                  {c?.messageType === "WIDGET_CHAT" && (
                    <div className="text-right">
                      <AntdIconV4
                        type="MobileFilled"
                        className="w-full text-center text-[#007dae]"
                      />
                    </div>
                  )}
                  {/* <div className="flex items-center">
                    <Avatar
                      src={c?.user?.profilePic || ""}
                      className={`absolute right-0 mx-1 ${
                        c?.user?.profilePic?.length > 0 ? "" : "bg-[#f6df9c]"
                      }`}
                    >
                      <span className="text-[#64748B]">
                        {" "}
                        {convertToInitials(
                          !isEmpty(userIdMap)
                            ? c?.systemUserId
                              ? userIdMap && userIdMap[c?.systemUserId]
                              : userIdMap && userIdMap[c?.userId]
                            : user.fullName
                        )}
                      </span>
                    </Avatar>
                    <span
                      style={{
                        position: "absolute",
                        right: "0%",
                      }}
                    >
                      {canViewTranslation && visibleTranslateIcon && <RenderTranslation />}
                    </span>
                  </div> */}
                  <div className="absolute right-0 mx-1 flex flex-col mt-[5px]">
                    <Avatar
                      src={c?.user?.profilePic || ""}
                      className={`${c?.user?.profilePic?.length > 0 ? "" : "bg-[#f6df9c]"}`}
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      <span className="text-[#64748B]">
                        {" "}
                        {convertToInitials(
                          !isEmpty(userIdMap)
                            ? c?.systemUserId
                              ? userIdMap && userIdMap[c?.systemUserId]
                              : userIdMap && userIdMap[c?.userId]
                            : user.fullName
                        )}
                      </span>
                    </Avatar>
                    {canViewTranslation && visibleTranslateIcon && <RenderTranslation />}
                  </div>
                  <div className="text-[14px] md:float-none float-right max-w-[530px] px-3 py-2 flex flex-col smaiChatSent text-white rounded-[4px] rounded-tr-none relative break-words ml-[4px] mr-[40px]">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row items-center gap-1">
                        <Tooltip
                          title={
                            <RenderPhone
                              direction="TO_LEAD"
                              messagephone={
                                (c?.messageLog &&
                                  c?.messageLog.length > 0 &&
                                  c?.messageLog[0]?.toPhone) ||
                                ""
                              }
                            />
                          }
                        >
                          <PhoneFilled className="rotate-90" />
                        </Tooltip>
                        <span className="text-[12px] mr-[30px]">
                          {convertUtcToLocal(c?.dateSent, "ddd, MMM Do YYYY, h:mm A")}
                        </span>
                      </div>
                      <span className="text-[#FCD34D] text-[14px] font-[600]">
                        {!isEmpty(userIdMap)
                          ? c?.systemUserId
                            ? userIdMap && userIdMap[c?.systemUserId]
                            : userIdMap && userIdMap[c?.userId]
                          : c?.user?.fullName}
                      </span>
                    </div>
                    {c?.campaign && c?.campaign?.name !== "Default" && (
                      <span className="absolute top-[-12px] left-[15px] py-[2px] px-[10px] bg-orange-500 rounded-[6px] text-[12px]">
                        {c?.campaign?.name || ""}
                      </span>
                    )}
                    <div style={{ overflowWrap: "anywhere" }} className="text-white">
                      {renderLinks(
                        isIncludeOriginalMessage ? c?.content : c?.translatedContent || c?.content
                      )}
                      {isMobileView && c?.content?.length > 60 ? (
                        showMore ? (
                          <span className="showMoreButton" onClick={() => setShowMore(false)}>
                            {lang.showLess}
                          </span>
                        ) : (
                          <span className="showMoreButton" onClick={() => setShowMore(true)}>
                            {lang.showMore}
                          </span>
                        )
                      ) : (
                        ""
                      )}
                      {(isIncludeOriginalMessage || translatedContent) && <br />}
                      {isIncludeOriginalMessage &&
                        !translatedContent &&
                        c?.content !== c?.translatedContent &&
                        returnTranslatedContentGlobally(c?.translatedContent)}
                      {returnTranslatedContent()}
                    </div>
                    {/* <AntdIconV4
                      type={
                        c?.type === "new"
                          ? "LoadingOutlined"
                          : c?.type === "error" || c?.messageStatus === "FAILED"
                          ? ""
                          : "CheckOutlined"
                      }
                      className={`text-[14px] ml-[10px] ${
                        c?.type === "error" ? "text-red-500" : "text-white"
                      }`}
                    /> */}
                  </div>

                  {c?.type === "error" ||
                  c?.messageStatus === "FAILED" ||
                  c?.messageStatus === "UNDELIVERED" ? (
                    <span className="text-[11px] mb-[2px] text-right mr-[15px]">
                      <RenderRetry />
                    </span>
                  ) : c?.type === "new" ? null : (
                    <>
                      {/* <p className="text-[11px] mb-[2px] text-right mr-[15px]">{userSentMessage}</p>
                      {c?.leadOpportunity?.id && (
                        <p className="text-[11px] text-right mt-[1px] mb-[3px] mr-[15px]">
                          {isMobileView ? (showMoreMessageInfo ? opportunity : "") : opportunity}
                        </p>
                      )}
                      {isMobileView && showMoreToggleRight} */}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="bg-[#52c41ade] rounded-[15px] p-[10px] text-white flex flex-col text-center">
              <AntdIconV4 type="PhoneOutlined" className="mb-[1em]" />
              {selectedLead.fullName || ""} {lang.called} {c.user.firstName || ""}
              {c.user.lastName || ""} <br /> {lang.at.toLowerCase()}
              {convertUtcToLocal(c.dateSent, "ddd, MMM Do YYYY, h:mm A")}
            </div>
          </div>
        )}
      </Fragment>
    </div>
  ) : c.direction === "TO_LEAD" ? (
    <div className="flex justify-end w-full my-4" key={c?.id} id={`msg-${messageIndex}`}>
      <div
        className={
          !isMobileView
            ? "max-w-[80%] md:max-w-[65%] break-words "
            : "break-words  w-full max-w-full "
        }
      >
        <div className="absolute right-0 mx-1 flex flex-col mt-[5px]">
          <Avatar
            src={c?.user?.profilePic || ""}
            className={`${c?.user?.profilePic?.length > 0 ? "" : "bg-[#f6df9c]"}`}
          >
            <span className="text-[#64748B]">
              {" "}
              {convertToInitials(
                !isEmpty(userIdMap)
                  ? c?.systemUserId
                    ? userIdMap && userIdMap[c?.systemUserId]
                    : userIdMap && userIdMap[c?.userId]
                  : user.fullName
              )}
            </span>
          </Avatar>

          {canViewTranslation && visibleTranslateIcon && <RenderTranslation />}
        </div>
        <div className="text-[14px] max-w-[530px] px-3 py-2 flex flex-col smaiChatSent text-white rounded-[4px] rounded-tr-none relative break-words mr-[40px] ml-[4px] float-right md:float-none">
          <div className="flex justify-between items-center">
            <div className="flex flex-row gap-1 items-center">
              <Tooltip
                title={
                  <RenderPhone
                    direction="TO_LEAD"
                    messagephone={
                      (c?.messageLog && c?.messageLog.length > 0 && c?.messageLog[0]?.toPhone) || ""
                    }
                  />
                }
              >
                <PhoneFilled className="rotate-90" />
              </Tooltip>
              <span className="text-[12px] mr-[30px]">
                {convertUtcToLocal(c?.dateSent, "ddd, MMM Do YYYY, h:mm A")}
              </span>
            </div>
            <span className="text-[#FCD34D] text-[14px] font-[600]">
              {!isEmpty(userIdMap)
                ? c?.systemUserId
                  ? userIdMap && userIdMap[c?.systemUserId]
                  : userIdMap && userIdMap[c?.userId]
                : c.user.fullName}
            </span>
          </div>
          <div className="flex justify-center">
            {c?.mmsObjects?.length <= 1 ? (
              <MediaSharing
                alt="msgText"
                direction={c.direction}
                selectedMedia={{
                  mediaUrl: c?.mmsObjects[0]?.mediaUrl,
                  mediaType: c?.mmsObjects[0]?.mediaType,
                  size: c?.mmsObjects[0]?.size,
                  thumbnailUrl: c?.mmsObjects[0]?.thumbnail,
                }}
                width="250px"
                height="230px"
              />
            ) : (
              <MultipleMediaView
                messageDetails={c}
                media={c.mmsObjects}
                alt="msgText"
                direction={c.direction}
                width="250px"
                height="230px"
              />
            )}
          </div>
          {c.content && (
            <>
              {c?.campaign && c?.campaign?.name !== "Default" && (
                <span>{c?.campaign?.name || ""}</span>
              )}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  padding: "10px 0 0 0",
                }}
              >
                <span className="break-all">
                  {renderLinks(
                    isIncludeOriginalMessage
                      ? c?.content || c?.translatedContent
                      : c?.translatedContent || c?.content
                  ) || ""}
                </span>
                {(isIncludeOriginalMessage || translatedContent) && <br />}
                {isIncludeOriginalMessage &&
                  c?.content !== c?.translatedContent &&
                  !translatedContent &&
                  returnTranslatedContentGlobally(c?.translatedContent)}
                {returnTranslatedContent()}
                {/* <AntdIconV4
                  type={
                    c?.type === "new"
                      ? "loading"
                      : c?.type === "error" || c?.messageStatus === "FAILED"
                      ? ""
                      : "check"
                  }
                  style={{
                    fontSize: "14px",
                    marginLeft: 10,
                    color: `${c?.type === "error" ? "red" : "white"}`,
                  }}
                /> */}
              </div>
            </>
          )}
        </div>
        {(c?.type === "error" ||
          c?.messageStatus === "FAILED" ||
          c?.messageStatus === "UNDELIVERED") && (
          <p className="text-[11px] mb-[2px] text-right mr-[15px]">
            <RenderRetry />
          </p>
        )}
      </div>
    </div>
  ) : (
    <div className="flex justify-start my-4" key={c?.id} id={`msg-${messageIndex}`}>
      <div
        className={
          !isMobileView ? "max-w-[80%] md:max-w-[65%] break-words" : "break-words w-full max-w-full"
        }
      >
        <div className="absolute left-0 mx-1 flex flex-col mt-[5px]">
          <Avatar className="bg-[#abc5de]">{convertToInitials(selectedLead?.fullName)}</Avatar>
          {canViewTranslation && visibleTranslateIcon && <RenderTranslation />}
        </div>
        <div className="text-[14px] flex flex-col max-w-[530px] px-3 py-2 smaiChatRecieve text-[#1E293B] rounded-[4px] rounded-tl-none p-[1em] mx-[4px] ml-[40px] relative mt-[5px] md:float-none float-left">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-brand-500 font-[600] ">{selectedLead?.fullName}</span>
            </div>
            <div className="flex flex-row items-center gap-1">
              <span className="text-[12px]">
                {convertUtcToLocal(c?.dateReceived, "ddd, MMM Do YYYY, h:mm A")}
              </span>
              <Tooltip
                title={
                  <RenderPhone
                    direction="FROM_LEAD"
                    messagephone={
                      (c?.messageLog && c?.messageLog.length > 0 && c?.messageLog[0]?.fromPhone) ||
                      ""
                    }
                  />
                }
              >
                <PhoneFilled />
              </Tooltip>
            </div>
          </div>
          <div className="flex justify-center">
            {c?.mmsObjects?.length <= 1 ? (
              <MediaSharing
                alt="msgText"
                direction={c.direction}
                selectedMedia={{
                  mediaUrl: c?.mmsObjects[0]?.mediaUrl,
                  mediaType: c?.mmsObjects[0]?.mediaType,
                  size: c?.mmsObjects[0]?.size,
                  thumbnailUrl: c?.mmsObjects[0]?.thumbnail,
                }}
                width="250px"
                height="230px"
              />
            ) : (
              <MultipleMediaView
                messageDetails={c}
                media={c.mmsObjects}
                selectedMedia={c.mmsObjects && c.mmsObjects[0]}
                alt="msgText"
                direction={c.direction}
                width="250px"
                height="230px"
              />
            )}
          </div>
          {c.content && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                padding: "10px 0 0 0",
              }}
            >
              <span>
                {renderLinks(
                  isIncludeOriginalMessage ? c?.content : c?.translatedContent || c?.content
                ) || ""}
              </span>
              {(isIncludeOriginalMessage || translatedContent) && <br />}
              {isIncludeOriginalMessage &&
                c?.translatedContent &&
                !translatedContent &&
                c?.content !== c?.translatedContent &&
                returnTranslatedContentGlobally(c?.translatedContent)}
              {returnTranslatedContent()}
            </div>
          )}
        </div>
        {/* <p className="text-left ml-[4px] mb-[4px] text-[11px]">
          {recievedMessageInfo} <br />
          {isMobileView ? (showMoreMessageInfo ? opportunity : "") : opportunity}
          {isMobileView && showMoreToggle}
        </p> */}
      </div>
    </div>
  );
};

export default Messages;

function ReviewUI({ c }) {
  // const reviewStages = ["Invite Opened", "Good Experience", "Review Posted", "Review Message"];
  const sortedtracking = sortBy(c?.trackingDetails, "id");
  const invited = sortedtracking.find(item => item.action);
  const opened = sortedtracking.find(
    item =>
      item?.actionType?.toLowerCase() === "view" && item?.actionValue?.toLowerCase() === "start"
  );
  const clickGood = sortedtracking.find(
    item =>
      item?.actionType?.toLowerCase() === "click" && item?.actionValue?.toLowerCase() === "good"
  );
  const clickBad = sortedtracking.find(
    item =>
      item?.actionType?.toLowerCase() === "click" && item?.actionValue?.toLowerCase() === "bad"
  );
  const ottoUsed = sortedtracking.find(
    item =>
      item?.actionType?.toLowerCase() === "click" &&
      item?.actionValue?.toLowerCase() === "suggest_message_gen"
  );
  const clickPlatform = sortedtracking.find(
    item =>
      item?.actionType?.toLowerCase() === "click" &&
      (item?.actionValue?.toLowerCase() === "google" ||
        item?.actionValue?.toLowerCase() === "facebook" ||
        item?.actionValue?.toLowerCase() === "yelp" ||
        item?.actionValue?.toLowerCase() === "dealer_rater")
  );
  const TrackingContainer = ({ children }) => (
    <span className="flex flex-row gap-1 items-center text-xs font-bold text-grey-700">
      {children}
    </span>
  );
  return (
    <div className="w-[95%] h-full flex flex-col justify-center items-center mx-auto">
      {/* Review Stage UI */}
      <div className="flex flex-row flex-wrap w-full items-center justify-center gap-3 mb-2">
        {!isEmpty(opened) && (
          <Tooltip title="Lead opened the invite">
            <TrackingContainer>
              Invite Opened
              <GreenEnvalopeIcon />
            </TrackingContainer>
          </Tooltip>
        )}
        {!isEmpty(clickGood) && (
          <>
            <ArrowRightOutlined />
            <Tooltip title="Lead indicated that they had a satisfactory experience.">
              <TrackingContainer>
                Good Experience
                <SmileFilled className="text-brand-500" />
              </TrackingContainer>
            </Tooltip>
          </>
        )}
        {!isEmpty(clickBad) && (
          <>
            <ArrowRightOutlined />
            <Tooltip title="Lead indicated that they had an unsatisfactory experience.">
              <TrackingContainer>
                Bad Experience
                <SadIcon />
              </TrackingContainer>
            </Tooltip>
          </>
        )}
        {!isEmpty(ottoUsed) && (
          <>
            <ArrowRightOutlined />
            <TrackingContainer>
              Otto Written
              <Icon className="text-xs" component={EngagedAI} alt="SocialMiningAi" />
            </TrackingContainer>
          </>
        )}
        {!isEmpty(clickPlatform) && isEmpty(ottoUsed) && (
          <>
            <ArrowRightOutlined />
            <TrackingContainer>
              Self Written
              <PencilIcon color="#F59E0B" />
            </TrackingContainer>
          </>
        )}
        {!isEmpty(clickPlatform) && (
          <>
            <ArrowRightOutlined />
            <TrackingContainer>
              Customer opened
              <SVGIcon
                component={
                  clickPlatform?.actionValue === "GOOGLE"
                    ? GoogleIcon
                    : clickPlatform?.actionValue === "FACEBOOK"
                    ? FacebooksIcon
                    : clickPlatform?.actionValue === "YELP"
                    ? YelpIcon
                    : DealerraterIcon
                }
                className="h-[12px] w-[12px]"
                alt="Rating"
              />
            </TrackingContainer>
          </>
        )}
      </div>
    </div>
  );
}

function OttoMessage(props) {
  const {
    SetContentBy,
    c,
    userIdMap,
    selectedLead,
    toPhone,
    RenderRetry,
    messageCategory,
    isOpenOttoMessage,
    setisOpenOttoMessage,
    isReview,
  } = props;
  const isMobileView = useSelector(state => getMobileView(state));
  const messageContentType = c?.contentType?.toLowerCase();
  const showOttoMessage = isReview
    ? messageContentType === MESSAGE_CONTENT_TYPES.REVIEW_REQUEST_SENT.toLowerCase()
    : true;
  return showOttoMessage ? (
    <div className="sm:w-[95%] w-full h-full flex flex-col items-center justify-center mx-auto">
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-row w-full items-center">
          {/* <div
            className="h-0.5 sm:block hidden w-[25%]"
            style={{ backgroundColor: messageCategory.backgroundColor }}
          /> */}
          <LineDiv
            className={`flex items-center gap-1 mt-1 mb-2 w-full justify-center before:sm:content-[""] before:absolute  before:w-[92%] before:h-[1px] before:z-0 before:left-0 before:right-0 before:my-0 before:mx-auto`}
            colorprop={messageCategory.backgroundColor}
          >
            <div className="flex bg-white z-[999] py-0 px-[15px] w-[600px] flex-row items-center sm:justify-between justify-between gap-1">
              <div
                className={`text-[#64748B] sm:text-nowrap flex flex-col items-start justify-start ${
                  isMobileView && "w-1/4"
                } text-xs font-[400] leading-4`}
              >
                <span>
                  To:{" "}
                  {c?.direction === "TO_LEAD"
                    ? selectedLead?.fullName || ""
                    : c?.direction === "FROM_OTTO" &&
                      ((c?.dateReceived && `${c.user?.firstName} ${c.user?.lastName}`) ||
                        (c?.dateSent && (selectedLead?.fullName || "")))}
                </span>
              </div>
              <div
                style={{ backgroundColor: messageCategory.backgroundColor }}
                className={`text-center py-2 px-5 rounded-full cursor-pointer `}
                onClick={() => setisOpenOttoMessage(!isOpenOttoMessage)}
              >
                <div className="flex flex-row items-center justify-center gap-2">
                  {messageCategory.icon}{" "}
                  <span className="text-xs font-[600] text-grey-700">{messageCategory.title}</span>
                  <CaretDownOutlined
                    className={`cursor-pointer ${
                      isOpenOttoMessage ? "rotate-180" : ""
                    } transition-all duration-500 `}
                  />
                </div>
              </div>
              <div className="text-[#64748B] sm:text-nowrap w-auto 4 text-xs font-[400]">
                {convertUtcToLocal(c?.dateSent || c?.dateReceived, "ddd, MMM Do YYYY, h:mm A")}
              </div>
            </div>
          </LineDiv>
          {/* <div
            className="h-0.5 sm:block hidden w-[25%] "
            style={{ backgroundColor: messageCategory.backgroundColor }}
          /> */}
        </div>
      </div>
      {isOpenOttoMessage && (
        <>
          <div
            style={{ wordBreak: "break-word", backgroundColor: messageCategory.backgroundColor }}
            className="h-full sm:w-[60%] w-full whitespace-pre-wrap  p-4  rounded-lg flex flex-col justify-start"
          >
            <div className="flex flex-col justify-start items-start space-y-6">
              <div className="text-grey-700 text-[14px] font-normal leading-6">
                {renderLinks(SetContentBy(c, userIdMap), "black") || ""}
              </div>
            </div>
            <span className="text-xs font-[400] text-left mt-1">
              {isOpenOttoMessage && `To: ${toPhone}`}
            </span>
          </div>
        </>
      )}
      {(c?.type === "error" ||
        c?.messageStatus === "FAILED" ||
        c?.messageStatus === "UNDELIVERED") && (
        <div className="text-right w-full">
          <RenderRetry allowRetry={false} />
        </div>
      )}
      {isReview && <ReviewUI {...props} />}
    </div>
  ) : null;
}
