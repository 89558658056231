import * as React from "react";

const GreenEnvalopeIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#22C55E"
      d="m14.277 5.584-6-4a.5.5 0 0 0-.554 0l-6 4A.5.5 0 0 0 1.5 6v6.5a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V6a.5.5 0 0 0-.223-.416ZM6.046 9.5 2.5 12V6.97L6.045 9.5Zm1.023.5h1.864l3.54 2.5H3.528l3.54-2.5Zm2.887-.5L13.5 6.97V12L9.955 9.5Z"
    />
  </svg>
);
export default GreenEnvalopeIcon;
