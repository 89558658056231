import React from "react";
import { Alert } from "antd";

export const CrashAlert = ({ title, description, action }) => (
  <Alert
    type="error"
    message={title || "Something went wrong"}
    description={description || "Unexpected Error occured"}
  />
);
