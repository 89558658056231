/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  opportunityType: [],
  opportunityStatus: [],
  opportunitySource: [],
  opportunitySearch: "",
  opportunityVOI: [],
  userTypesOr: [],
  userTypesAnd: [],
};
const initialState = {
  opportunityFilters: initialFilters,
  opportunityPresetFilter: {},
};

const opportunitySlice = createSlice({
  name: "opportunity",
  initialState,
  reducers: {
    setOpportunityTypeFilter(state, action) {
      state.opportunityFilters.opportunityType = action.payload;
    },
    setOpportunitySourceFilter(state, action) {
      state.opportunityFilters.opportunitySource = action.payload;
    },
    setOpportunityStatusFilter(state, action) {
      state.opportunityFilters.opportunityStatus = action.payload;
    },
    setOpportunitySearchFilter(state, action) {
      state.opportunityFilters.opportunitySearch = action.payload;
    },
    resetOpportunityFilters(state, action) {
      state.opportunityFilters = initialFilters;
    },
    setPresetOpportunityFilters(state, action) {
      state.opportunityFilters = action.payload;
    },
    setOpportunityPresetFilter(state, action) {
      state.opportunityPresetFilter = action.payload;
    },
    setOpportunityVoiFilter(state, action) {
      state.opportunityFilters.opportunityVOI = action.payload;
    },
    setOpportunityOwnersOr(state, action) {
      state.opportunityFilters.userTypesOr = action.payload;
      state.opportunityFilters.userTypesAnd = [];
    },
    setOpportunityOwnersAnd(state, action) {
      state.opportunityFilters.userTypesAnd = action.payload;
      state.opportunityFilters.userTypesOr = [];
    },
  },
});

export const {
  setOpportunitySourceFilter,
  setOpportunityTypeFilter,
  setOpportunityStatusFilter,
  setOpportunitySearchFilter,
  resetOpportunityFilters,
  setPresetOpportunityFilters,
  setOpportunityPresetFilter,
  setOpportunityVoiFilter,
  setOpportunityOwnersAnd,
  setOpportunityOwnersOr,
} = opportunitySlice.actions;

export default opportunitySlice.reducer;
