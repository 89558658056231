import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { Col, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { PUSH_DEAL_TO_CRM } from "../../graphql/mutation";
import { Countries } from "../../library/countries";
import TextInput from "../../library/textInput";
import { USStates } from "../../library/usStates";
import CustomAlert from "../commonComponents/alert";
import DialogFooter from "../commonComponents/dialogFooter";
import { FormField } from "../engagements/leadEditModal";
import { CONDITION_OPTION, INTEREST_OPTION, LEAD_TYPE_OPTION } from "../../library/constants";
import useLang from "../../hooks/useLang";

const { TextArea } = Input;

const PhoneDiv = styled.div`
  input {
    border: 1px solid #d9d9d9;
    width: 373px;
    padding: 5px;
    border-radius: 4px;
  }
`;

const PushDealToCrm = props => {
  const { changeDealStatus, conversations, selectedDeal, onClose } = props;

  const [lang] = useLang();

  const combinedConversation = conversations.reduce((accumulator, conversation) => {
    const author = conversation.type === "NOTE" || conversation.type === "SENT" ? "Me" : "Customer";
    return `${accumulator}${accumulator.length > 0 ? "\n" : ""}[${author} @ ${dayjs(
      conversation.postTime
    ).format("HH:mm MM.DD.YYYY")}] ${conversation.message.trim().replace(/\s\s+/g, " ")}`;
  }, "");

  const pushToCrmFormInitialValues = {
    companyId: selectedDeal.companyId,
    dealId: selectedDeal.id,
    aingineDataId: selectedDeal.aingineDataId,
    typeOfLead: "",
    status: "",
    interest: "",
    year: "0000",
    make: "N/A",
    model: "N/A",
    contactFirstName: selectedDeal.firstName ? selectedDeal.firstName : "",
    contactLastName: selectedDeal.lastName ? selectedDeal.lastName : "",
    contactFullName: `${selectedDeal.firstName || ""} ${selectedDeal.lastName || ""}`,
    contactEmail: selectedDeal.email ? selectedDeal.email : "",
    contactPhoneNumber: selectedDeal.phone ? selectedDeal.phone : "",
    contactAddressLine1: "",
    contactAddressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    comments: combinedConversation,
  };

  const year = useRef("");
  const make = useRef("");
  const model = useRef("");
  const contactFirstName = useRef("");
  const contactLastName = useRef("");
  const contactFullName = useRef("");
  const contactEmail = useRef(`${selectedDeal.email}`);
  const contactPhoneNumber = useRef(`${selectedDeal.phone}`);
  const contactAddressLine1 = useRef("");
  const contactAddressLine2 = useRef("");
  const city = useRef("");
  const state = useRef("");
  const zip = useRef("");
  const country = useRef("");
  const comments = useRef(combinedConversation);

  const [error, setError] = useState("");
  const [pushToCrmForm, setPushToCrmForm] = useState(pushToCrmFormInitialValues);

  const [
    pushDealToCrm2,
    { loading: pushDealToCrmLoading, error: pushDealToCrmError, called: pushDealToCrmCalled },
  ] = useMutation(PUSH_DEAL_TO_CRM);

  const pushDealToCrm = value => {
    pushDealToCrm2(value);
  };

  useEffect(() => {
    if (pushDealToCrmCalled) {
      if (!pushDealToCrmError) {
        changeDealStatus("DEAL_WON");
      }
      //   setPushToCrmModalVisible(false);
    }
  }, [pushDealToCrmCalled]);

  const updatePushToCrmForm = (field, value) => {
    setPushToCrmForm(prevState => {
      const newState = { ...prevState };
      newState[field] = value;
      return newState;
    });
  };

  const validatePushToCrmSubmitValues = (pushToCrmSubmitValues, setError) => {
    let requirmentsSatisfied = true;
    if (
      // eslint-disable-next-line no-useless-escape
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(pushToCrmSubmitValues.contactEmail) ===
      false
    ) {
      setError(lang.invalidEmail);
      requirmentsSatisfied = false;
    }
    if (
      (!pushToCrmSubmitValues.contactEmail || pushToCrmSubmitValues.contactEmail === "") &&
      (!pushToCrmSubmitValues.contactPhoneNumber || pushToCrmSubmitValues.contactPhoneNumber === "")
    ) {
      requirmentsSatisfied = false;
      setError(lang.phoneMustEnter);
    }
    if (!pushToCrmSubmitValues.contactLastName || pushToCrmSubmitValues.contactLastName === "") {
      requirmentsSatisfied = false;
      setError(lang.lastNameMustEnter);
    }
    if (!pushToCrmSubmitValues.contactFirstName || pushToCrmSubmitValues.contactFirstName === "") {
      requirmentsSatisfied = false;
      setError(lang.firstNameMustEnter);
    }
    if (!pushToCrmSubmitValues.interest || pushToCrmSubmitValues.interest === "") {
      requirmentsSatisfied = false;
      setError(lang.interestMustEnter);
    }
    if (!pushToCrmSubmitValues.status || pushToCrmSubmitValues.status === "") {
      requirmentsSatisfied = false;
      setError(lang.stateMustEntered);
    }
    if (!pushToCrmSubmitValues.typeOfLead || pushToCrmSubmitValues.typeOfLead === "") {
      requirmentsSatisfied = false;
      setError(lang.typeOfLeadMustEnter);
    }
    return requirmentsSatisfied;
  };

  const handlePushToCrm = () => {
    const pushToCrmSubmitValues = {
      ...pushToCrmForm,
      year: pushToCrmForm.year ? pushToCrmForm.year : "0000",
      make: pushToCrmForm.make ? pushToCrmForm.make : "N/A",
      model: pushToCrmForm.model ? pushToCrmForm.model : "N/A",
    };
    if (validatePushToCrmSubmitValues(pushToCrmSubmitValues, setError)) {
      setError("");
      pushDealToCrm({
        variables: pushToCrmSubmitValues,
      });
    }
  };

  return (
    <div className="p-2">
      {error && <CustomAlert message={error} type="error" banner className="p-2" />}
      <Row className="py-2" gutter={[4, 16]}>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={8} xl={8}>
          <FormField label={lang.typeOfLead} isFieldRequired />
          <Select
            className="w-full"
            placeholder={lang.selectTypeTheLead}
            onChange={value => updatePushToCrmForm("typeOfLead", value)}
          >
            {LEAD_TYPE_OPTION.map(opt => (
              <Select.Option key={opt?.key} value={opt?.key} disabled={opt.disabled}>
                {opt.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={8} xl={8}>
          <FormField label={lang.condition} />
          <Select
            className="w-full"
            placeholder={lang.selectTheCondition}
            onChange={value => updatePushToCrmForm("status", value)}
          >
            {CONDITION_OPTION.map(opt => (
              <Select.Option key={opt?.key} value={opt?.key} disabled={opt.disabled}>
                {opt.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={8} xl={8}>
          <FormField label={lang.interest} isFieldRequired />

          <Select
            className="w-full"
            placeholder={lang.selectInterest}
            onChange={value => updatePushToCrmForm("interest", value)}
          >
            {INTEREST_OPTION.map(opt => (
              <Select.Option key={opt?.key} value={opt?.key} disabled={opt.disabled}>
                {opt.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="py-2" gutter={[4, 16]}>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={8} xl={8}>
          <TextInput
            className="w-full"
            reference={year}
            placeholder={lang.year}
            name="year"
            onChange={e => updatePushToCrmForm("year", e.target.value)}
          />
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={8} xl={8}>
          <TextInput
            reference={make}
            placeholder={lang.make}
            name="make"
            onChange={e => updatePushToCrmForm("make", e.target.value)}
          />
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={8} xl={8}>
          <TextInput
            reference={model}
            placeholder={lang.modal}
            name="model"
            onChange={e => updatePushToCrmForm("model", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="py-2" gutter={[4, 16]}>
        <Col className="mb-1" xs={24} sm={24} md={8} lg={8} xl={8}>
          <FormField label={lang.firstName} isFieldRequired />
          <TextInput
            reference={contactFirstName}
            placeholder={lang.contactFirstName}
            name="contactFirstName"
            value={pushToCrmForm.contactFirstName}
            onChange={e => updatePushToCrmForm("contactFirstName", e.target.value)}
          />
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={8} lg={8} xl={8}>
          <FormField label={lang.lastName} isFieldRequired />
          <TextInput
            reference={contactLastName}
            placeholder={lang.contactLastName}
            name="contactLastName"
            value={pushToCrmForm.contactLastName}
            onChange={e => updatePushToCrmForm("contactLastName", e.target.value)}
          />
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={8} lg={8} xl={8}>
          <FormField label={lang.fullName} />
          <TextInput
            reference={contactFullName}
            placeholder={lang.contactFullName}
            name="contactFullName"
            value={pushToCrmForm.contactFullName}
            onChange={e => updatePushToCrmForm("contactFullName", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="py-2" gutter={[4, 16]}>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormField label={lang.contactEmail} />
          <TextInput
            reference={contactEmail}
            placeholder={lang.contactEmail}
            name="contactEmail"
            value={pushToCrmForm.contactEmail}
            onChange={e => updatePushToCrmForm("contactEmail", e.target.value)}
          />
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormField label={lang.contactPhoneNumber} isFieldRequired />
          <PhoneDiv>
            <PhoneInput
              reference={contactPhoneNumber}
              international
              withCountryCallingCode
              countryCallingCodeEditable={false}
              defaultCountry="US"
              value={pushToCrmForm.contactPhoneNumber}
              placeholder={lang.enterPhone}
              onChange={e => updatePushToCrmForm("contactPhoneNumber", e)}
            />
          </PhoneDiv>
        </Col>
      </Row>

      <Row className="py-2" gutter={[4, 16]}>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormField label={lang.contactAddressLine1} />
          <TextInput
            reference={contactAddressLine1}
            placeholder={lang.contactAddressLine1}
            name="contactAddressLine1"
            value={pushToCrmForm.contactAddressLine1}
            onChange={e => updatePushToCrmForm("contactAddressLine1", e.target.value)}
          />
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormField label={lang.contactAddressLine2} />
          <TextInput
            reference={contactAddressLine2}
            placeholder={lang.contactAddressLine2}
            name="contactAddressLine2"
            value={pushToCrmForm.contactAddressLine2}
            onChange={e => updatePushToCrmForm("contactAddressLine2", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="py-2" gutter={[4, 16]}>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={6} xl={6}>
          <FormField label={lang.city} />
          <Input
            reference={city}
            placeholder={lang.city}
            name="city"
            value={pushToCrmForm.city}
            onChange={e => updatePushToCrmForm("city", e.target.value)}
          />
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={6} xl={6}>
          <FormField label={lang.stateLabel} isFieldRequired />
          <Select
            showSearch
            reference={state}
            className="w-full"
            placeholder={lang.stateLabel}
            value={pushToCrmForm.state}
            onChange={e => updatePushToCrmForm("state", e)}
          >
            {USStates.map((el, i) => (
              <Select.Option key={`key__${i}`} value={el.code}>
                {el.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={6} xl={6}>
          <FormField label={lang.zip} isFieldRequired />
          <Input
            reference={zip}
            placeholder={lang.zip}
            name="zip"
            value={pushToCrmForm.zip}
            onChange={e => updatePushToCrmForm("zip", e.target.value)}
          />
        </Col>
        <Col className="mb-1" xs={24} sm={24} md={12} lg={6} xl={6}>
          <FormField label={lang.country} isFieldRequired />
          <Select
            reference={country}
            className="w-full"
            placeholder={lang.country}
            value={pushToCrmForm?.country || "United States"}
            disabled={pushToCrmForm.country != null && pushToCrmForm.country !== ""}
            onChange={e => updatePushToCrmForm("country", e)}
          >
            {Countries.map((el, i) => (
              <Select.Option key={`key__${i}`} value={el.name}>
                {el.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="py-2" gutter={[4, 16]}>
        <Col span={24}>
          <TextArea
            rows={5}
            className="w-full"
            reference={comments}
            placeholder="Comments"
            name="comments"
            defaultValue={`${combinedConversation}`}
            onChange={e => updatePushToCrmForm("comments", e.target.value)}
          />
        </Col>
      </Row>
      <DialogFooter
        loading={pushDealToCrmLoading}
        handleConfirm={handlePushToCrm}
        handleCancel={onClose}
      />
    </div>
  );
};

export default PushDealToCrm;
