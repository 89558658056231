import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  promptText: "",
  dsSelectedSources: [],
  displaySources: [],
  expandedKeys: [],
  expandedKeysForCreatedPrompts: [],
  search: "",
  searchForCreatedPrompts: "",
};

export const promptSlice = createSlice({
  name: "prompt",
  initialState,
  reducers: {
    setPromptTextValue: (state, action) => {
      state.promptText = action.payload.promptText;
    },
    setDSSelectedSources: (state, action) => {
      state.dsSelectedSources = action.payload.dsSelectedSources;
    },
    setDisplaySources: (state, action) => {
      state.displaySources = action.payload.displaySources;
    },
    setExpandedKeys: (state, action) => {
      state.expandedKeys = action.payload.expandedKeys;
    },
    setExpandedKeysForCreatedPrompts: (state, action) => {
      state.expandedKeysForCreatedPrompts = action.payload.expandedKeysForCreatedPrompts;
    },
    resetPromptValues: (state, action) => {
      state.promptText = initialState.promptText;
      state.displaySources = initialState.displaySources;
      state.dsSelectedSources = initialState.dsSelectedSources;
      state.expandedKeys = initialState.expandedKeys;
    },
    onSourceSearch: (state, action) => {
      state.search = action.payload.search;
    },
    onSourceSearchForCreatedPrompts: (state, action) => {
      state.searchForCreatedPrompts = action.payload.searchForCreatedPrompts;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPromptTextValue,
  setDSSelectedSources,
  setDisplaySources,
  setExpandedKeys,
  resetPromptValues,
  setExpandedKeysForCreatedPrompts,
  onSourceSearch,
  onSourceSearchForCreatedPrompts,
} = promptSlice.actions;

export const getPromptText = state => state?.prompt?.promptText;
export const getPromptDSSources = state => state?.prompt?.dsSelectedSources;
export const getPromptDisplaySources = state => state?.prompt?.displaySources;
export const getExpandedKeys = state => state?.prompt?.expandedKeys;
export const getExpandedKeysForCreatedPrompts = state => state?.prompt?.expandedKeys;
export const getSearchKey = state => state?.prompt?.search;
export const getSearchKeyForCreatedPrompt = state => state?.prompt?.searchForCreatedPrompts;

export default promptSlice.reducer;
