import { useMutation } from "@apollo/client";
import { AutoComplete, message } from "antd";
import React, { useState } from "react";
import { ADD_SCREEN_NAME } from "../../graphql/mutation";
import TextInput from "../../library/textInput";
import CustomAlert from "../commonComponents/alert";
import DialogFooter from "../commonComponents/dialogFooter";
import CustomLabel from "../commonComponents/label";
import useLang from "../../hooks/useLang";

const validateInputs = inputs => {
  return (
    inputs.screenName.trim().length > 0 &&
    inputs.source.trim().length > 0 &&
    Number.isInteger(inputs.sourceId)
  );
};

const AddScreenNameForm = ({
  editableScreenName,
  setEditableScreenName,
  sources,
  refreshScreenNames,
  onClose,
}) => {
  const [lang] = useLang();

  const [sourceId, setSourceId] = useState(editableScreenName ? editableScreenName.sourceId : null);
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState("");
  const [screenName, setScreenName] = useState(
    editableScreenName ? editableScreenName.screenName : ""
  );
  const [source, setSource] = useState(editableScreenName ? editableScreenName.source : "");
  const [sourceUrl, setSourceUrl] = useState(
    editableScreenName ? editableScreenName.sourceUrl : ""
  );

  const [addScreenName, { loading: screenLoader }] = useMutation(ADD_SCREEN_NAME);

  const sourceOptions = sources ? sources.map(x => x.source) : [];

  const onSearch = searchText =>
    setSearchResults(
      sourceOptions.filter(source => source.toLowerCase().includes(searchText.toLowerCase()))
    );

  const handleAutoSelect = value => {
    const selectedFilter = sources.filter(source => source.source === value);
    setSource(selectedFilter[0].source);
    setSourceUrl(selectedFilter[0].sourceUrl);
    setSourceId(selectedFilter[0].sourceId);
  };

  const submitScreenName = async input => {
    try {
      if (editableScreenName) {
        await addScreenName({
          variables: {
            id: editableScreenName.id,
            screenName: input.screenName,
          },
        });
        message.success(lang.screenUpdated);
        setEditableScreenName(null);
        refreshScreenNames();
        onClose();
      } else {
        await addScreenName({
          variables: {
            source: input.source,
            screenName: input.screenName,
            sourceUrl: input.sourceUrl,
            sourceId: input.sourceId,
          },
        });
        message.success(lang.screenCreated);
        setEditableScreenName(null);
        refreshScreenNames();
        onClose();
      }
    } catch (err) {
      setError(lang.somethingWentWrong);
    }
  };

  const handleSubmitForm = () => {
    const inputs = {
      source,
      screenName,
      sourceUrl,
      sourceId,
    };
    if (validateInputs(inputs)) {
      setError("");
      submitScreenName(inputs);
    } else {
      setError(lang.makeSureValidt);
    }
  };

  return (
    <>
      {error && <CustomAlert type="error" message={error} />}

      {editableScreenName ? (
        <>
          <CustomLabel label={lang.sourceName} labelClass="mt-1 font-semibold" />
          <TextInput
            placeholder={lang.enterSourceName}
            disabled={!!editableScreenName}
            onChange={e => setSource(e.target.value)}
            defaultValue={editableScreenName ? editableScreenName.source : undefined}
          />
          <p className="py-2 text-[15px] primaryColorTheme">
            {lang.source}: {sourceUrl}
          </p>
        </>
      ) : (
        <>
          <CustomLabel label={lang.source} labelClass="mt-1 font-semibold" />
          <AutoComplete
            dataSource={searchResults}
            onSelect={handleAutoSelect}
            onSearch={onSearch}
            className="w-full"
            placeholder={lang.searchForumOrSource}
          />
          {sourceUrl && <p className="py-2 text-lg">{sourceUrl}</p>}
        </>
      )}
      <CustomLabel label={lang.screenNameTableLabel} labelClass="mt-2 font-semibold" />
      <TextInput
        placeholder={lang.enterScreenName}
        onChange={e => setScreenName(e.target.value)}
        defaultValue={editableScreenName ? editableScreenName.screenName : undefined}
      />
      <DialogFooter
        handleConfirm={handleSubmitForm}
        handleCancel={() => {
          setEditableScreenName(null);
          onClose();
        }}
        confirmBtnText={editableScreenName ? lang.update : lang.add}
        loading={screenLoader}
      />
    </>
  );
};

export default AddScreenNameForm;
