import React from "react";
import { useSelector } from "react-redux";
import { closeDialog } from "../../library/helpers";
import { getLoaderStatus } from "../../redux/slices/loaderSlice";
import AntdIconV4 from "../../utils/antdIcon";
import DialogFooter from "../commonComponents/dialogFooter";
import useLang from "../../hooks/useLang";

const ConfirmDialog = props => {
  const {
    label,
    content,
    confirmBtnText,
    cancelBtnText,
    okBtnType,
    loading,
    handleConfirm,
    // handleCancel,
    ExtraComponent,
    isOKGhost,
    icon,
  } = props;

  const [lang] = useLang();
  const confirmLoading = useSelector(state => getLoaderStatus(state));

  return (
    <div>
      <div className="flex items-center gap-2">
        <div>
          {icon || (
            <AntdIconV4 className="text-yellow-400 text-2xl" type="ExclamationCircleFilled" />
          )}
        </div>
        <p className="text-lg font-medium">{label || lang.areYouSure}</p>
      </div>
      <p className="pt-2 ml-[2rem]">{content || lang.dialogContent}</p>
      <DialogFooter
        confirmBtnText={confirmBtnText}
        danger={okBtnType}
        isOKGhost={isOKGhost}
        loading={loading || confirmLoading}
        cancelBtnText={cancelBtnText}
        handleConfirm={handleConfirm}
        ExtraComponent={ExtraComponent}
        handleCancel={() => closeDialog("confirmDialog")}
      />
    </div>
  );
};

export default ConfirmDialog;
