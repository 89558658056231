import { useMutation } from "@apollo/client";
import { Form, Input, message } from "antd";
import React from "react";
import { CLONE_CAMPAIGN } from "../../../graphql/mutation";
import { closeDialog } from "../../../library/helpers";
import DialogFooter from "../../commonComponents/dialogFooter";

const CampaignCloneModal = ({ campaign, refetchCampaigns = () => {}, handleCreateCampaign }) => {
  const [form] = Form.useForm();
  const [cloneCampaign, { loading: cloneLoader }] = useMutation(CLONE_CAMPAIGN);
  const handleFinish = async value => {
    const res = await cloneCampaign({
      variables: {
        campaignId: +campaign.id,
        name: value.campaignName,
      },
    });
    if (res.data.cloneCampaign.statusCode === 200) {
      refetchCampaigns();
      handleCreateCampaign({
        name: value.campaignName,
        id: res.data.cloneCampaign.campaign.id,
        status: campaign.status,
        source: campaign.source,
      });
      closeDialog("cloneCampaign");
      message.success(res.data.cloneCampaign.message);
    } else {
      message.error(res.data.cloneCampaign.message);
    }
  };
  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label="Campaign Name"
          name="campaignName"
          rules={[{ required: true, message: "Please enter campaign name" }]}
        >
          <Input name="campaignName" placeholder="Enter the clone campaign name" />
        </Form.Item>
      </Form>
      <DialogFooter
        loading={cloneLoader}
        handleCancel={() => closeDialog("cloneCampaign")}
        handleConfirm={() => form.submit()}
      />
    </>
  );
};

export default CampaignCloneModal;
