import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Modal } from "antd";
import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import AntdIconV4 from "../../../../utils/antdIcon";
import useLang from "../../../../hooks/useLang";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    background: #00000061;
    backdrop-filter: blur(5px);
    height: 95vh;
    width: 96.5vw;
  }

  .ant-modal-title {
    color: white !important;
  }

  .ant-modal-body {
    height: calc(100vh - 110px);
    text-align: center;
  }

  .ant-modal-header {
    background: transparent;
    border-bottom: none !important;
  }

  .anticon anticon-close ant-modal-close-icon {
    color: white !important;
  }

  @media only screen and (max-width: 800px) {
    .ant-modal-body {
      padding: 0em !important;
    }
  }
`;

export const SliderStyled = styled(Slider)`
  .slick-track {
    margin-top: 5%;
  }
  .slick-dots li.slick-active button:before {
    color: white !important;
    font-size: 20px;
  }
  .slick-dots li button:before {
    font-size: 20px !important;
  }
`;

export default function MultipleMediaViewer(props) {
  const { isModalOpen, handleCancel, allMedia } = props;

  const [lang] = useLang();

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    autoplay: true,
    pauseOnHover: true,
    speed: 500,
  };

  return (
    <ModalStyled
      visible={isModalOpen}
      width="97.5%"
      wrapClassName="mediaModal"
      onCancel={handleCancel}
      closeIcon={<AntdIconV4 type="CloseOutlined" className="text-white" />}
      footer={null}
      title={`${lang.showing} ${allMedia.length} ${lang.files}`}
    >
      <SliderStyled {...settings}>
        {allMedia.map(item => {
          return (
            <div key={item.mediaUrl}>
              {item?.mediaType?.includes("image") ? (
                <img
                  width="100%"
                  height="500px"
                  className="mt-[10px] object-contain"
                  src={item.mediaUrl}
                  alt={item.mediaUrl.split("/").at(-1)}
                />
              ) : (
                // <video
                //   width="100%"
                //   height="500px"
                //   controls
                //   poster={item.thumbnailUrl}
                //   src={item.mediaUrl}
                // >
                //   <source autostart="false" src={item.mediaUrl} type="video/mp4" />
                // </video>
                <h1>Video</h1>
              )}
            </div>
          );
        })}
      </SliderStyled>
    </ModalStyled>
  );
}
