/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-debugger */
/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable default-param-last */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-escape */
/* eslint-disable no-else-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-lonely-if */
import Icon, { CheckOutlined, CloseCircleFilled, SmileOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Col,
  Collapse,
  Drawer,
  Dropdown,
  Menu,
  Popover,
  Row,
  Skeleton,
  Select,
  Spin,
  Tooltip,
  Typography,
  message,
  Switch,
} from "antd";
import dayjs from "dayjs";
import { debounce, isEmpty, isUndefined, uniq, uniqBy } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EngagedAiLogo from "../../static/EngagedAi_trans_bg.svg";
import { UserContext } from "../amplify/authenticator-provider";
import CustomButton from "../components/commonComponents/button";
import { BottomActions } from "../components/engagements/activityCenter/BottomActions";
import { InputArea } from "../components/engagements/activityCenter/InputArea";
import AppointmentModal from "../components/engagements/activityCenter/appointments/appointmentModal";
import Attachments from "../components/engagements/activityCenter/attachments";
import ConversationSummary from "../components/engagements/activityCenter/conversationSummary";
import "../components/engagements/activityCenter/editor.css";
import { leadStatus, pageSize } from "../components/engagements/activityCenter/engagementActivity";
import {
  LeadStatus,
  ShowConsent,
  isConsentStatusEnable,
  leadOwners,
  vehicles,
} from "../components/engagements/activityCenter/engagementHelpers";
import LeadFilterBreadcrumbs from "../components/engagements/activityCenter/leadFilterBreadcrumbs";
import LeadListContainer from "../components/engagements/activityCenter/leadListContainer";
import LeadProfile from "../components/engagements/activityCenter/leadProfile";
import GifPickerComponent from "../components/engagements/activityCenter/mediaSharing/gifPicker";
import Messages from "../components/engagements/activityCenter/messages";
import MmsMediaButtons from "../components/engagements/activityCenter/mmsMediaButtons";
import OpportunityFilter from "../components/engagements/activityCenter/opporotunityLayer/opportunityFilter";
import TemplateDrawer from "../components/engagements/schedual/templateDrawer";
import {
  DropdownMenu,
  NotificationsDropdown,
  convertToInitials,
  profileMenu,
} from "../components/layoutComponent/layoutHelpers";
import {
  BROADCAST_UNREAD_COUNT,
  CREATE_LEAD_EMAIL,
  CREATE_LEAD_PHONE,
  CREATE_MESSAGE,
  CREATE_MMS,
  CREATE_SCHEDULED_MESSAGE,
  RETRY_MESSAGE_SEND,
  UPDATE_LEAD,
  UPDATE_LEAD_CONSENT,
  UPDATE_LEAD_CONVERSATION_STATUS,
  UPDATE_LEAD_EMAIL,
  UPDATE_LEAD_PHONE,
  TRANSLATE_MESSAGE,
  UPDATE_TRANSLATION_SETTINGS,
} from "../graphql/mutation";
import {
  EDIT_LEAD,
  GET_ALL_TEAMS,
  GET_ALL_USERS_ACTIVITY_CENTER,
  GET_CAMPAIGN_BY_LEAD,
  GET_CAMPAIGN_PAGINATED_LIST,
  GET_COMPANY_AFTER_MESSAGE_CONSENT_TEMPLATE,
  GET_COMPANY_CONSENT,
  GET_COMPANY_CONSENT_TEMPLATES,
  GET_CONSENT_TEMPLATE,
  GET_CRM_INTEGRATION_TYPE,
  GET_CRM_LEAD_STATUS,
  GET_DETECTED_LANGUAGE,
  GET_EXTENSION_LEAD_SOURCES,
  GET_EXTENSION_LIST_ACTIVITY_CENTER,
  GET_LEAD_ACTIVE_APPOINTMENT,
  GET_LEAD_DETAIL_ACTIVITY_CENTER,
  GET_LEAD_DETAIL_CLICK,
  GET_MESSAGES,
  GET_SCHEDULED_MESSAGES_FOR_LEAD,
  GET_SOCIAL_REVIEW_SETTINGS,
  GET_SUPPORTED_LANGUAGES,
  GET_TRANSLATION_SETTINGS,
} from "../graphql/query";
import { useInterval } from "../hooks";
import useLang from "../hooks/useLang";
import { isPlatformAdmin } from "../hooks/utils";
import LeftIcon from "../icons/js/LeftIcon";
import LocationIcon from "../icons/js/LocationIcon";
import NotificationIcon from "../icons/js/NotificationIcon";
import RightIcon from "../icons/js/RightIcon";
import SummaryIcon from "../icons/js/SummaryIcon";
import TimerIcon from "../icons/js/TimerIcon";
import { OptionContainer } from "../library/activityComponents";
import { colorConfig } from "../library/colorConfig";
import { AccessPermissionAction, MESSAGE_CONTENT_TYPES } from "../library/constants";
import {
  checkAccessPermission,
  closeDialog,
  openDialog,
  showConfirmationModal,
} from "../library/helpers";
import {
  getCacheApi,
  pushActiveLeadFilter,
  resetApiCacheStore,
  setAfterMessageText,
  setAllCampaigns,
  setCompanyConsentTemplate,
  setConsentTemplateSlice,
  setCrmLeadStatus,
  setCrmType,
  setLeadSources,
  setSelectedLeadPhone,
} from "../redux/slices/apiCacheSlice";
import { getMobileView } from "../redux/slices/isMobile";
import {
  addQuickFilter,
  setScheduledMessages,
  getUpdatedCountInfo,
  setSelectedLeadRedux,
  setSelectedLeadIdRedux,
  setLeadsRedux,
  addMessage,
  setSearchRedux,
} from "../redux/slices/leadSlice";
import { getLocationId, setLocationIdSlice } from "../redux/slices/locationSlice";
import AntdIconV4 from "../utils/antdIcon";
import { s3uploader } from "../utils/awsS3MediaUploader";
import { generateVideoThumbnails } from "../utils/videoThumbnailGenerator";
import { toTitleCase } from "../utils";
import TranslateIcon from "../../static/icons/Translate.svg";
import { SVGIcon } from "../library/basicComponents";
import CloseIconForTranslation from "../../static/icons/CloseIconForTranslation.svg";
import InfoIcon from "../../static/icons/info.svg";
import useCrmIntegration from "../hooks/useCrmIntegration";

let page = 1;
let lastLead = "";
let isSkipFilter = false;

const customizeRenderEmpty = lang => (
  <div style={{ textAlign: "center", color: "lightgray" }}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p style={{ color: "lightgray" }}>{lang.emptyMessageMsg}</p>
  </div>
);

const BrowserExtensionPopup = props => {
  const {
    setShowNotifModal,
    setSelectedNotif,
    getMoreNotifications,
    hasMore,
    markeNotifAsRead,
    notificationsCount,
    notifications,
    location,
    locationChatBot,
    lead = {},
    currentLocation,
    Role,
    location2,
    canchangeLocation,
    setLocation,
    getActiveCrmIntegration,
    setchanged,
    changed,
    gotLocationId = true,
    setPhone = () => {},
    setShowSupportModal,
    deleteFcmDevice,
    userFullName,
    refetchNotifications,
    refetchNotificationsCount,
    setNotifications,
  } = props;

  const { user, setUser, modal, notificationData, isUserIsBeingSet, persistor, authData } =
    useContext(UserContext);

  const [lang] = useLang();

  const dispatch = useDispatch();
  const isPlatformAdminUser = isPlatformAdmin(authData);
  const handleDispatch = locationId => {
    setSearchKey("");
    setQueryString("");
    setSelectedLead({});
    setLeadList([]);
    setLeadIdClick("");
    setPhone("");
    setIsClickonPotentialDuplicate("");
    localStorage.removeItem("clickLeadId");
    localStorage.removeItem("changedLocationLeadId");
    setVariables({ ...variables, dsEventId: "", dsEntityId: "", page: 1, search: "" });
    setSearch("");
    setEventId("");
    setEntityId("");
    setchanged(!changed);
    window.parent.postMessage(searchKey, "*");
    dispatch(setLocationIdSlice({ locationId: locationId }));
  };

  const text = useRef("");
  const messagesEndRef = useRef(null);
  const msgContainerRef = useRef(null);

  const urlParams = new URLSearchParams(locationChatBot);
  const paramLeadId = urlParams.get("leadId");
  const leadName = urlParams.get("leadName");
  const locationName = urlParams.get("locationName");
  const locaitonLocalId = localStorage.getItem("changedLocationLeadId");
  const customerId = urlParams.get("customerId");
  const dealerId = urlParams.get("dealerId");
  console.log("LEAD NAME, CUSTOMER ID, DEALER ID ==>", leadName, customerId, dealerId);
  let msgUnreadTimeOut;
  let timeReference;
  const { selectedLead, selectedLeadId, leads: leadList } = useSelector(state => state.lead);
  const locationIdslice = useSelector(state => getLocationId(state));
  const leadSourcesReduxData = useSelector(state => getCacheApi(state, "leadSources"));
  const afterMessageFromRedux = useSelector(state => getCacheApi(state, "afterMessageText"));
  const consentTemplateFromRedux = useSelector(state => getCacheApi(state, "consentTemplate"));
  const crmLeadStatusReduxData = useSelector(state => getCacheApi(state, "crmLeadStatus"));
  const userListReduxData = useSelector(state => getCacheApi(state, "userList"));
  const leadCountUpdatedData = useSelector(state => getUpdatedCountInfo(state));
  const companyConsentReduxData = useSelector(state =>
    getCacheApi(state, "companyConsentTemplateEvent")
  );
  const { leadScheduledMessages: scheduledMessages } = useSelector(state => state?.lead);
  const { leadSort, search } = useSelector(state => state.lead);
  const allCampaignsReduxData = useSelector(state => getCacheApi(state, "allCampaigns"));
  const actionDispatchTime = useSelector(state => getCacheApi(state, "expiryTime"));

  const [entityId, setEntityId] = useState(
    urlParams.get("entityId") !== "remove" ? urlParams.get("entityId") : ""
  );
  const [eventId, setEventId] = useState(
    urlParams.get("eventId") !== "remove" ? urlParams.get("eventId") : ""
  );
  useEffect(() => {
    setEntityId(urlParams.get("entityId") !== "remove" ? urlParams.get("entityId") : "");
    setEventId(urlParams.get("eventId") !== "remove" ? urlParams.get("eventId") : "");
  }, [urlParams]);
  const [leadFirstName, setLeadFirstName] = useState(leadName ? leadName.split(" ")[0] : "");
  const [leadLastName, setLeadLastName] = useState(
    leadName ? (leadName.split(" ")?.length > 0 ? leadName.split(" ").splice(1).join(" ") : "") : ""
  );
  const [queryString, setQueryString] = useState(
    (`${location?.split("/")[2]}`.replace("/", "") === "undefined" ||
    `${location?.split("/")[2]}`.replace("/", "") === "remove"
      ? ""
      : `${location?.split("/")[2]}`.replace("/", "")) || ""
  );
  const [activeLeadId, setActiveLeadId] = useState(
    localStorage.getItem("changedLocationLeadId") || false
  );
  const [isReview, setisReview] = useState(false);
  const [openAddModal, setAddPhoneModalVisible] = useState(false);
  const [userIdMap, setUserIdMap] = useState({});
  const [searchKey, setSearchKey] = useState(
    queryString !== "undefined" && queryString !== "remove" ? queryString : ""
  );

  const [showSidebar, setShowSideBar] = useState(true);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [textMode, settextMode] = useState(0);
  const [richTextValue, setrichTextValue] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [isGifOpen, setisGifOpen] = useState(false);
  const [showReviewRequestButton, setShowReviewRequestButton] = useState(true);
  const [activeOpp, setActiveOpp] = useState("#DEFAULTOPPID");
  const [activeOppName, setActiveOppName] = useState("");
  const [opportunityList, setOpportunityList] = useState([
    { filterId: null, id: "#DEFAULTOPPID", name: "All" },
  ]);
  const [mmsLoader, setmmsLoader] = useState(false);
  const [filterLastId, setFilterLastId] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [showLeadSection, setShowLeadSection] = useState(window.innerWidth > 1024);
  const [callLeadDetailApi, setCallLeadDetailAPI] = useState(window.innerWidth > 1024);
  const [showProfileSection, setShowProfileSection] = useState(window.innerWidth > 1024);
  const [changeState, setChangeState] = useState(true);
  const [leadCount, setLeadCount] = useState(0);
  const [openCRMModal, setPushToCrmModalVisible] = useState(false);
  const [openAppointmentDrawer, setOpenAppointmentDrawer] = useState(false);
  const [openSendReviewModal, setSendReviewVisible] = useState(false);
  const crmIntegration = useCrmIntegration();
  const [crmIntegrationData, setcrmIntegrationData] = useState("");
  const [openSetReviewTemplateUploadModal, setReviewTemplateUploadVisible] = useState(false);
  const [openTemplateModal, setTemplateModal] = useState({ visible: false });
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [refetchAppointment, setRefetchAppointment] = useState(false);
  const [inlineField, setInlineEditField] = useState("");
  const [openEditUserPhone, setOpenEditUserPhone] = useState(false);
  const [messageFilters, setMessageFilters] = useState({
    campaignIds: [],
    leadId: selectedLead && +selectedLead?.id,
    lastId: lastLead,
    page: 1,
    pageSize,
  });
  const [globalMessageData, setGlobalMessageData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const isMobileView = useSelector(state => getMobileView(state));
  const {
    quickFilters: selectedQuickFilters,
    addMyConversation,
    userFilters,
    campaignFilter,
  } = useSelector(state => state.lead.filters);
  const [isMsgScroll, setIsMsgScroll] = useState(false);
  const [disableConcentClick, setDisableConcentClick] = useState(true);
  const [apiCheck, setApiCheck] = useState(false);
  const [summaryVisible, setSummaryVisible] = useState({ visible: false, count: 0 });
  const [reviewInfo, setReviewInfo] = useState(null);
  const [leadCampaigns, setLeadCampaigns] = useState([]);
  const [consent, setConsent] = useState([]);
  const [consentTemplate, setConsentTemplate] = useState();
  const [selectedLeadID, setSelectedLeadID] = useState();
  const [openAppointmentPopup, setAppointmentPopupVisible] = useState(false);
  const [statusList, setstatusList] = useState([]);
  const [userLocation, setUserLocation] = useState("");
  const [isAppointmentChangeFromLead, setIsAppointmentChangeFromLead] = useState(false);
  const mediaRef = useRef(null);
  const videoRef = useRef(null);
  const documentRef = useRef(null);
  const [appintmentLead, setAppointmentLead] = useState({});
  const [notificationDrawer, setNotificationDrawer] = useState(false);
  const [implicit, setimplicit] = useState(
    consent.find(item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_OFF")?.isActive
  );
  const [explicit, setexplicit] = useState(
    consent.find(item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_ON")?.isActive
  );
  const [isAutoTranslate, setIsAutoTranslate] = useState(false);
  const [isIncludeOriginalMessage, setIsIncludeOriginalMessage] = useState(false);
  const [isTranslatePreviewShow, setIsTranslatePreviewShow] = useState(false);
  const [originalText, setOriginalText] = useState("");
  const [visibleTranslationSettings, setVisibleTranslationSetting] = useState(false);
  const canEdit = checkAccessPermission(AccessPermissionAction.LEAD_ADD_EDIT);
  const canViewTranslation = checkAccessPermission(
    AccessPermissionAction.TRANSLATION_MANAGEMENT_VIEW
  );
  isSkipFilter = !isUndefined(queryString) && !isEmpty(queryString) && queryString !== "remove";
  const [variables, setVariables] = useState({
    source: [],
    sourceOriginal: [],
    combinedSource: [],
    voi: [],
    page: 1,
    pageSize: 25,
    orderBy: "latest_chat",
    orderDirection: "desc",
    status: uniq([...selectedQuickFilters, ...selectedStatus]),
    campaignIds: [],
    dsEntityId: entityId,
    dsEventId: eventId,
    skipFilter: isSkipFilter,
  });

  const [btnClicked, setbtnClicked] = useState(false);
  const [mediaUploadLoader, setMediaUploadLoader] = useState(false);
  const [openPotentialLeadDialog, setOpenPotentialLeadDialog] = useState(false);
  const [isClickonPotentialDuplicate, setIsClickonPotentialDuplicate] = useState(false);
  const [sourcesData, setSourcesData] = useState({});
  const [afterMessageConsent, setAfterMessageConsent] = useState("");
  const [onlyFirstMessage, setonlyFirstMessage] = useState("");
  const [isActive, setisActive] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [cosentAtEnd, setcosentAtEnd] = useState(isActive);
  const [recording, setRecording] = useState({ visible: false, lead: {} });
  const [selectedMedia, setSelectedMedia] = useState("");
  const [videoThumbnails, setVideoThumbnails] = useState([]);
  const [IsLeadListVisible, setIsLeadListVisible] = useState(true);
  const [isLeadProfileVisible, setisLeadProfileVisible] = useState(true);

  const [getScheduleMessagesForLead] = useLazyQuery(GET_SCHEDULED_MESSAGES_FOR_LEAD, {
    variables: {
      leadId: selectedLead?.id || messageFilters?.leadId,
    },
    skip: !selectedLead?.id,
    onCompleted: res => {
      dispatch(setScheduledMessages([...res?.getScheduledMessagesForLead]));
    },
  });
  const [isTranslationForCustomer, setIsTranslationForCustomer] = useState(false);
  const [isTranslationForEmployee, setIsTranslationForEmployee] = useState(false);
  const [targetLangForCustomer, setTargetLangForCustomer] = useState("");
  const [targetLangForEmployee, setTargetLangForEmployee] = useState("");
  const [languages, setLanguages] = useState();
  const [stopLoaderForReceivedMessage, setStopLoaderForReceivedMessage] = useState(true);

  const { refetch: refetchSupportedLanguages } = useQuery(GET_SUPPORTED_LANGUAGES, {
    onCompleted: res => {
      if ((res?.getSupportedLanguages || []).length > 0) {
        const languages = structuredClone(res?.getSupportedLanguages);
        setLanguages(languages);
      }
    },
  });

  const handleLeadListVisible = () => {
    setIsLeadListVisible(prev => !prev);
  };
  const setSelectedLead = lead => {
    dispatch(setSelectedLeadRedux(lead));
  };
  const setSelectedLeadId = id => {
    dispatch(setSelectedLeadIdRedux(id));
  };
  const setSearch = search => {
    dispatch(setSearchRedux(search));
  };
  const setLeadList = list => {
    dispatch(setLeadsRedux(list));
  };
  useEffect(() => {
    if (!localStorage.getItem("previousLeadSearchTrack"))
      setSearch(queryString !== "undefined" && queryString !== "remove" ? queryString : "");
  }, [queryString]);

  const [getLeadSources, { data: leadSourcesData }] = useLazyQuery(GET_EXTENSION_LEAD_SOURCES, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      if (res.leadSources && res.leadSources.length > 0) {
        setSourcesData(leadSourcesData);
        dispatch(setLeadSources({ leadSources: res.leadSources }));
      }
    },
  });

  useEffect(() => {
    window.addEventListener("message", function (event) {
      // Check if the message is from the extension
      console.log("Received message from extension:", event);
      if (event.source === window.parent) {
        // Retrieve the data sent from the extension
        const messageData = event.data.data;

        // Process the message data
        console.log("Received message from extension:", messageData);

        // Optionally, you can send a response back to the extension if needed
        // window.parent.postMessage({ response: 'Response from iframe' }, '*');
      }
    });
  }, []);

  useEffect(() => {
    // The user may click the phone number on any page to trigger the browser
    // extension, so we may not know the location this opportunity belongs to.
    // Moreover, in DealerTeam, the customer may exist independent of any
    // location; only the opportunity is tied to a specific location. As we may
    // not even know if the trigger for the browser extension was on a specific
    // opportunity page, we cannot assume a location to be available in this
    // context.
    if (crmIntegration !== null && crmIntegration === "DT") {
      let ids = "";
      for (let i = 0; i < location2.length; i++) {
        const element = location2[i];
        ids = `${ids}${element.id}${location2.length - 1 === i ? "" : ","}`;
      }
      sessionStorage.setItem("currentLocationId", ids);
    }
  }, [location2, crmIntegration]);

  useEffect(() => {
    if (!leadSourcesReduxData.length) {
      getLeadSources();
    } else {
      setSourcesData(leadSourcesReduxData);
    }
    if (!crmLeadStatusReduxData.crmLeadStatus.length) {
      getCrmLeadStatusData();
    } else {
      setstatusList(crmLeadStatusReduxData.crmLeadStatus);
      setcrmIntegrationData(crmLeadStatusReduxData.crmIntegrationData);
    }
  }, [leadSourcesReduxData, crmLeadStatusReduxData]);

  useEffect(() => {
    const handleResize = () => {
      // Toggle sidebar based on the screen width breakpoint (e.g., 1000px)
      if (window.innerWidth <= 800) {
        setisLeadProfileVisible(false);
      } else {
        setisLeadProfileVisible(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setSelectedLeadPhoneToRedux = selectedLeadPhone => {
    dispatch(setSelectedLeadPhone({ selectedLeadPhone }));
  };

  const [getUsersByCompany] = useLazyQuery(GET_ALL_USERS_ACTIVITY_CENTER, {
    variables: { companyId: +user?.company?.id || 0 },
    fetchPolicy: "cache-first",
    onCompleted: res => {
      let obj = userIdMap;
      if (res?.getUsers?.data || []) {
        const users = res?.getUsers?.data || [];
        users.forEach(el => {
          const id = el?.id;
          obj = {
            ...obj,
            [id]: el?.fullName || "Otto",
          };
        });
        setUserIdMap({ ...obj });
        // dispatch(setUserList({ usersList: { ...obj }, companyId: user.company.id }));
      }
    },
  });

  const [getAfterMessageText] = useLazyQuery(GET_COMPANY_AFTER_MESSAGE_CONSENT_TEMPLATE, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      dispatch(setAfterMessageText({ afterMessageText: res?.getAfterMessageText }));
      setAfterMessageConsent(res?.getAfterMessageText?.content);
      setonlyFirstMessage(res?.getAfterMessageText?.onlyFirstMessage);
      setisActive(res?.getAfterMessageText?.isActive);
    },
  });

  useEffect(() => {
    if (user?.company?.id && consent.length === 0) {
      getCompanyConsentTemplateEvent();
    }
  }, [user?.company?.id, consent]);

  useEffect(() => {
    if (
      Object.entries(userListReduxData.users).length &&
      +user?.company?.id === +userListReduxData?.companyId
    ) {
      setUserIdMap({ ...userListReduxData.users });
    }
    if (allCampaignsReduxData?.length > 0) {
      setCampaigns([...allCampaignsReduxData]);
    } else {
      getAllCampaigns();
    }

    if (isEmpty(afterMessageFromRedux)) {
      getAfterMessageText();
    } else {
      setAfterMessageConsent(afterMessageFromRedux?.content);
      setonlyFirstMessage(afterMessageFromRedux?.onlyFirstMessage);
      setisActive(afterMessageFromRedux?.isActive);
      setcosentAtEnd(afterMessageFromRedux?.isActive);
    }
  }, [user?.company?.id, userListReduxData, allCampaignsReduxData, afterMessageFromRedux]);

  // Expire cached data periodically to fetch latest from server
  useInterval(() => {
    dispatch(resetApiCacheStore({}));
  }, parseInt(actionDispatchTime));

  const [getCrmLeadStatusData] = useLazyQuery(GET_CRM_LEAD_STATUS, {
    fetchPolicy: "network-only",
    onCompleted: resp => {
      setcrmIntegrationData(resp?.getCrmLeadStatus?.data);
      const leadStatus = (resp?.getCrmLeadStatus?.data || []).map(e => ({
        status: e?.status?.replace(/_/g, " "),
        statusTypeId: e?.statusTypeId,
        type: e.type,
        crmId: e?.id,
      }));
      setstatusList([...leadStatus]);
      dispatch(
        setCrmLeadStatus({
          crmLeadStatus: leadStatus,
          crmIntegration: resp?.getCrmLeadStatus?.crmIntegrationType,
          crmIntegrationData: resp?.getCrmLeadStatus?.data,
        })
      );
    },
  });

  console.log("browser-ext: user?.id, selectedLead?.id:", user?.id, selectedLead?.id);
  const [getTeamsByCompany, { refetch: refreshTeams }] = useLazyQuery(GET_ALL_TEAMS, {
    variables: {
      companyId: user?.company?.id,
    },
    onCompleted: res => {
      if (addMyConversation) {
        const isUserTeamLeader = res?.getTeams?.data?.find(item => +item?.leaderId === +user?.id);
        const status = uniq([...selectedQuickFilters, ...selectedStatus]);
        const hasMyConversation = status?.find(item => item === "MY_CONVERSATIONS");
        if (!isUserTeamLeader && !hasMyConversation) {
          status.push("MY_CONVERSATIONS");
          // setSelectedStatus(status);
          dispatch(addQuickFilter("MY_CONVERSATIONS"));
        } else if (isUserTeamLeader && hasMyConversation) {
          const newStatusList = status.filter(item => item !== "MY_CONVERSATIONS");
          setSelectedStatus(newStatusList);
        }
        refetchLeads();
      }
    },
  });
  const [isRefetching, setRefetching] = useState(false);
  const [refreshLeadList] = useLazyQuery(GET_EXTENSION_LIST_ACTIVITY_CENTER, {
    fetchPolicy: "network-only",
    variables: {
      ...variables,
      search,
      status: uniq([...selectedQuickFilters, ...selectedStatus]),
      campaignIds: variables?.campaignIds?.map(el => parseInt(el)),
      excludeDuplicate: true,
      userIds: [...userFilters],
      prioritySortOrder: leadSort,
      // skipFilter: !isEmpty(search),
    },
  });
  const prevYourReduxValueRef = useRef();
  const prevStatusRef = useRef();

  const {
    data: leadData,
    loading: leadsLoading,
    error: leadsError,
    refetch: refetchLeads,
  } = useQuery(GET_EXTENSION_LIST_ACTIVITY_CENTER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip:
      sessionStorage.getItem("currentLocationId") === null ||
      !sessionStorage.getItem("currentLocationId"),
    variables: {
      ...variables,
      search,
      dsEntityId: entityId || "",
      dsEventId: eventId || "",
      status: uniq([...selectedQuickFilters, ...selectedStatus]),
      campaignIds: variables?.campaignIds?.map(el => parseInt(el)),
      excludeDuplicate: true,
      userIds: [...userFilters],
      prioritySortOrder: leadSort,
      skipFilter: !isEmpty(search),
      leadId: paramLeadId,
      pageSize: variables.pageSize,
    },
    onCompleted: res => {
      let leadList = res?.getLeads?.data || [];
      let leadListCount = res?.getLeads?.count || [];
      if (isRefetching) {
        return;
      }
      setApiCheck(false);

      isSkipFilter = !isUndefined(queryString) && !isEmpty(queryString) && queryString !== "remove";
      if (res?.getLeads?.count > 0) {
        localStorage.setItem("previousLeadSearchTrack", searchKey);
        setLeadCount(res.getLeads.count);
        leadList = leadList.filter(x => x.id !== lead?.id);
        if (
          !(lead && Object.keys(lead).length === 0) &&
          !isEmpty(lead) &&
          search.length === 0 &&
          variables?.source.length === 0 &&
          variables?.voi.length === 0
        ) {
          handleLeadDetailProfile(lead?.id);
        } else {
          let leadInfo = selectedLead;
          if (!selectedLead?.id || isEmpty(selectedLead)) {
            if (activeLeadId) {
              const leads = structuredClone([...leadList]);
              setLeadList(leads);
              setSelectedLead({ ...leads[0], messages: [] });
              handleLeadDetailProfile(activeLeadId);
              setApiCheck(true);
              return;
            }

            const currentLead = leadList.filter(lead => +lead?.id === +activeLeadId);
            leadInfo = activeLeadId ? currentLead.length > 0 && currentLead[0] : leadList[0];
            setLeadList(structuredClone([...leadList]));
            setSelectedLead({ ...leadInfo, messages: [] });
            clearTimeout(msgUnreadTimeOut);
          } else {
            leadInfo = { ...leadList[0] };
            setSelectedLead({ ...leadList[0], messages: [] });
            clearTimeout(msgUnreadTimeOut);
          }

          page = 1;
          setMessageFilters({
            ...messageFilters,
            leadId: leadInfo?.id,
            lastId: lastLead,
            page: 1,
          });
        }
        if (!activeLeadId) {
          // The currentLocationId can sometimes be "undefined". This happens
          // when a notification that does not have location information is
          // clicked, usually in cases of DeaerTeam leads.
          if (sessionStorage.getItem("currentLocationId")?.includes(",")) {
            setLeadList([...leadList]);
            setSelectedLead({ ...leadList[0], messages: [] });
            clearTimeout(msgUnreadTimeOut);
          } else {
            if (
              sessionStorage.getItem("currentLocationId") === undefined ||
              sessionStorage.getItem("currentLocationId") === "undefined"
            ) {
              if (crmIntegration !== null && crmIntegration === "DT") {
                let ids = "";
                for (let i = 0; i < location2.length; i++) {
                  const element = location2[i];
                  ids = `${ids}${element.id}${location2.length - 1 === i ? "" : ","}`;
                }
                sessionStorage.setItem("currentLocationId", ids);
              }
            }
            const filterArr = leadList.filter(item => {
              if (
                item?.location?.id ===
                (locationIdslice || sessionStorage.getItem("currentLocationId"))
              ) {
                return item;
              }
              if (item?.leadOpportunities?.length > 0) {
                const locOpps = item?.leadOpportunities.filter(
                  lo =>
                    lo.location?.id ===
                    (locationIdslice || sessionStorage.getItem("currentLocationId"))
                );
                if (locOpps.length > 0) {
                  return item;
                }
              }
            });

            leadListCount = (filterArr || []).length;

            if (leadListCount > 0) {
              setLeadList(structuredClone([...filterArr]));
              setSelectedLead({ ...filterArr[0], messages: [] });
            }

            clearTimeout(msgUnreadTimeOut);
          }
        }
        setAddPhoneModalVisible(false);
        closeDialog("addLeadViaPhone");
        closeDialog("leadAccessPermissionMessage");
        if (leadSort !== prevYourReduxValueRef.current) {
          setLeadList([...leadList]);
          if (+leadList[0].id === +selectedLead?.id) {
            setSelectedLead({ ...leadList[0], ...selectedLead });
          } else {
            setSelectedLead({ ...leadList[0], messages: [] });
          }
          setActiveLeadId(leadList[0]?.id);
          setSelectedLeadID(leadList[0]?.id);
          prevYourReduxValueRef.current = leadSort;
        }
        if (uniq([...selectedQuickFilters, ...selectedStatus]) !== prevStatusRef) {
          setLeadList([...leadList]);
          if (+leadList[0].id === +selectedLead?.id) {
            setSelectedLead({ ...leadList[0], ...selectedLead });
          } else {
            setSelectedLead({ ...leadList[0], messages: [] });
          }
          setActiveLeadId(leadList[0]?.id);
          setSelectedLeadID(leadList[0]?.id);
          prevStatusRef.current = uniq([...selectedQuickFilters, ...selectedStatus]);
        }
      } else if (
        !isUndefined(queryString) &&
        !isEmpty(queryString) &&
        queryString !== "remove" &&
        res?.getLeads?.count === 0
      ) {
        setAddPhoneModalVisible(true);
        if (res?.getLeads?.hasNotPermitted) {
          openDialog("leadAccessPermissionMessage", {
            dialogHeader: lang.accessPermissionMessageDialogTitle,
            onClose: handleCloseAccessDialog,
          });
        } else {
          openDialog("addLeadViaPhone", {
            dialogHeader: "Confirm Phone To Text",
            phoneNumber: queryString,
            setQueryString: setQueryString,
            openModal: openAddModal && modal && apiCheck,
            statusList: statusList,
            setModalVisible: setAddPhoneModalVisible,
            variables: variables,
            data: leadList,
            sources: sourcesData?.leadSources || [],
            user: user,
            leadFirstName: leadFirstName,
            leadLastName: leadLastName,
            userLocation: userLocation,
            handleLeadCRMName: handleLeadCRMName,
            onSetLead: (item, mode, phoneLead = "") => {
              if (phoneLead === "phoneLead") {
                const itemData = { ...item, messages: [] };
                setSelectedLead(itemData);
                page = 1;
                setMessageFilters({
                  ...messageFilters,
                  leadId: item.id,
                  lastId: lastLead,
                  page: 1,
                });
                if (mode === "new") {
                  const tempLeads = structuredClone(leadList);
                  tempLeads.unshift(itemData);
                  setLeadList(structuredClone([...tempLeads]));
                }
              } else {
                if (selectedLead?.id === item?.id) {
                  setAddPhoneModalVisible(false);
                  return;
                }

                if (sessionStorage.getItem("currentLocationId") !== item?.location?.id) {
                  localStorage.setItem("changedLocationLeadId", item?.id);
                  sessionStorage.setItem("currentLocationId", item?.location?.id);
                  dispatch(setLocationIdSlice({ locationId: item?.location?.id }));
                  closeDialog("addLeadViaPhone");
                  return;
                }

                handleLeadDetailProfile(item?.id);
                handleScrollOnMovement();
              }
            },
            onLeadStatus: LeadStatus,
            onLeadOwners: leadOwners,
            onVehicles: vehicles,
            crmIntegration: crmIntegration,
            isFromChatbot: true,
            setSearchKey: setSearchKey,
            setVariables: setVariables,
            handleCancelBtn: handleCancel,
            eventId,
            locationName,
            entityId,
            isFromCRM: true,
            vinCustomerId: customerId,
            vinDealerId: dealerId,
          });
        }
        setSelectedLead({});
      }
      if (res?.getLeads?.count === 0 || leadListCount === 0) {
        setSelectedLead();
        setSelectedLeadId();
        setSelectedLeadID();
        setMessageFilters({
          ...messageFilters,
          leadId: undefined,
          page: 1,
        });
        setLeadList([]);
        setLeadCount(0);
      }
      setApiCheck(true);
    },
    onError: error => {
      console.log("err", error);
      isSkipFilter = true;
    },
  });

  const handleMediaSharing = mediaType => {
    if (mediaType === "image") {
      mediaRef.current.click();
    } else if (mediaType === "video") {
      videoRef.current.click();
    } else {
      documentRef.current.click();
    }
  };

  const resetSearch = () => {
    setSearchKey("");
    setVariables({ ...variables, page: 1, search: "" });
    setSearch("");
    setQueryString("");
    window.history.pushState("", "", "/browser-ext/");
    window.parent.postMessage(searchKey, "*");
    handleLeadCRMName();
  };

  const handleCancel = () => {
    resetSearch();
    closeDialog("addLeadViaPhone");
  };

  const handleCloseAccessDialog = () => {
    resetSearch();
    closeDialog("leadAccessPermissionMessage");
  };

  const [createMMS] = useMutation(CREATE_MMS, {
    onCompleted: res => {
      if (res.createMms.statusCode === 200) {
        setSelectedLead({
          ...selectedLead,
          messages: [
            ...selectedLead.messages,
            {
              ...res.createMms.leadMessage,
              mmsObjects: res.createMms.leadMessage.mmsObjects.map(media => ({
                compressedMediaUrl: media.url,
                mediaUrl: media.mediaUrl,
                mediaType: media.mediaType,
                size: media.size,
                thumbnail: media.thumbnail,
                compressedThumbnail: media.compressedThumbnail,
              })),
            },
          ],
        });
        textMode === 0 ? (text.current.value = "") : setrichTextValue("");
        message.success(res.createMms.message);
        setmmsLoader(false);
        setAttachments([]);
        text.current.value = "";
      }
    },
  });

  async function fetchData(files, fileType) {
    if (fileType.includes("video" || "quicktime")) {
      try {
        const response = await generateVideoThumbnails(files, 2);
        setVideoThumbnails(response[0]);
        const thumbnailUrl = await s3uploader(
          response,
          process.env.GATSBY_S3_BUCKET,
          state => {},
          true,
          sessionStorage.getItem("currentLocationId")?.includes(",")
            ? "all"
            : sessionStorage.getItem("currentLocationId"),
          user,
          selectedLead
        );
        return thumbnailUrl.url;
      } catch (e) {
        console.log("error in generateVideoThumbnails", e.message);
      }
    }
  }

  const handleMediaEvent = async (e, attachments, setAttachments) => {
    const fileType = "";
    const { files } = e.target;
    const filesarr = [];
    if (e.target.files.length === 0) return;
    for (let i = 0; i < e.target.files.length; i++) {
      filesarr.push(e.target.files[i]);
    }
    if (filesarr.length > 0) {
      setAttachments([
        ...attachments,
        ...filesarr.map(item => {
          return {
            file: item,
            preview: URL.createObjectURL(item),
            generateLink: true,
          };
        }),
      ]);
    }
  };
  const [messageCount, setmessageCount] = useState(0);
  const [getMessages, { refetch: refetchMessages, loading, error, data: leadMessagesData }] =
    useLazyQuery(GET_MESSAGES, {
      fetchPolicy: "network-only",
      variables: {
        ...messageFilters,
        leadId: selectedLead?.id || selectedLeadID || messageFilters?.leadId,
        // isUserMessage: isTranslationForEmployee,
        // isLeadMessage: isTranslationForCustomer,
        campaignIds: !isEmpty(campaignFilter)
          ? campaignFilter
          : (messageFilters.campaignIds || []).map(el => parseInt(el)),
        // targetLangForUser: targetLangForEmployee,
        // targetLangForLead: targetLangForCustomer,
      },
      onCompleted: res => {
        lastLead = res?.messages?.lastId || "";
        filterApplied && setFilterLastId(lastLead);
        const messageData = (res?.messages?.data || []).filter(
          item => item !== undefined && +item?.leadId === +selectedLead?.id
        );
        if (isEmpty(messageData)) {
          return setSelectedLead({ ...selectedLead, messages: [] });
        }
        // setmessageCount(messageData.count);
        setmessageCount(res?.messages?.count);
        let zeroIndexMsg;
        let messagePayload = messageData.map(el => {
          const findIndex = (selectedLead?.messages || []).findIndex(s => +s?.id === +el?.id);
          if (findIndex === 0) {
            zeroIndexMsg = el;
            return el;
          }
          if (findIndex === -1 && !isUndefined(el)) {
            return el;
          }
        });
        messagePayload = messagePayload?.filter(obj => obj !== null && obj !== undefined);
        if (filterApplied && messageData.length > 0 && !activeOpp.includes("#")) {
          setGlobalMessageData(prev => [...prev, ...messageData]);
        }

        let messageList;
        if (
          messageData.length > 0 &&
          messagePayload.length > 0 &&
          isEmpty(messageFilters.campaignIds)
        ) {
          if (activeOpp === "#DEFAULTOPPID") {
            messageList = selectedLead?.messages || [];

            messagePayload.map(el => {
              messageList = [...messageList, el];
            });
            const specificMsg = messageList.findIndex(msg => msg?.id === zeroIndexMsg?.id);
            if (specificMsg !== -1) {
              messageList[specificMsg] = { ...zeroIndexMsg };
            }
          } else {
            messageList = [...messageData];
          }
          const leadDetail = { ...selectedLead, messages: uniqBy(messageList, "id") };
          setSelectedLead({ ...leadDetail });

          setChangeState(!changeState);
        } else if (messageData.length > 0 && !isEmpty(messageFilters.campaignIds)) {
          setSelectedLead({ ...selectedLead, messages: [...messageData] });
          setChangeState(!changeState);
        } else {
          setSelectedLead({ ...selectedLead, messages: [] });
          setChangeState(!changeState);
        }

        if (messageData.length === 0 && filterApplied) {
          messageList = isMsgScroll ? [] : [...globalMessageData];
          const leadDetail = { ...selectedLead, messages: uniqBy(messageList, "id") };
          setSelectedLead({ ...leadDetail });

          setChangeState(!changeState);
        }
      },
    });

  const [getLeadActiveAppointment, { loading: activeSpinner }] = useLazyQuery(
    GET_LEAD_ACTIVE_APPOINTMENT,
    {
      fetchPolicy: "network-only",
    }
  );

  const [getReviewInfo] = useLazyQuery(GET_SOCIAL_REVIEW_SETTINGS, {
    variables: {
      companyId: user?.company?.id,
      locationId: selectedLead?.location?.id || lead?.location?.id,
    },
    onCompleted: res => {
      const reviewInfos = res?.getSocialReviewSettings || [];
      if (reviewInfos.length > 0) {
        setReviewInfo(reviewInfos[0]);
      }
    },
  });

  const [getAllCampaigns, { data: respCampaigns }] = useLazyQuery(GET_CAMPAIGN_PAGINATED_LIST, {
    fetchPolicy: "network-only",
    variables: { status: "Active" },
    onCompleted: () => {
      setCampaigns(respCampaigns?.getCampaigns?.data);
      dispatch(setAllCampaigns({ campaigns: respCampaigns?.getCampaigns?.data }));
    },
  });

  const [getCompanyConsentTemplateEvent] = useLazyQuery(GET_COMPANY_CONSENT, {
    variables: { companyId: user?.company?.id },
    fetchPolicy: "network-only",
    onCompleted: res => {
      const consentData = res?.getCompanyConsentTemplateEvent.map(item => {
        return {
          code: item?.consentTemplateEvent?.code,
          isActive: item?.companyConsentTemplateEvent?.isActive,
          templatetext: item?.companyConsentTemplateEvent?.templateText,
        };
      });
      setConsent(consentData);
      dispatch(setCompanyConsentTemplate({ companyId: user?.company?.id, consents: consentData }));
    },
  });

  const [editLeadDetails] = useLazyQuery(EDIT_LEAD, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setOpenEditPopup(true);
    },
  });

  const [getLeadDetail, { loading: spinner }] = useLazyQuery(GET_LEAD_DETAIL_ACTIVITY_CENTER, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setTargetLangForCustomer(data?.lead?.preferredLanguageCode);
      const leadRes = data?.lead;
      setSelectedLead({
        ...selectedLead,
        ...leadRes,
        messages: selectedLead && +selectedLead.id === +leadRes?.id ? selectedLead?.messages : [],
      });
    },
    onError: err => {
      console.log("Error in lead query", err);
    },
  });
  const refetchLeadDetails = async () => {
    const res = await getLeadDetail({
      variables: { leadId: +selectedLead?.id },
    });
    const leadRes = res.data.lead;
    setSelectedLead({
      ...selectedLead,
      ...leadRes,
      messages: selectedLead && +selectedLead.id === +leadRes?.id ? selectedLead?.messages : [],
    });
  };
  const [getLeadCampaigns] = useLazyQuery(GET_CAMPAIGN_BY_LEAD, {
    fetchPolicy: "network-only",
    variables: {
      leadId: +selectedLead?.id,
    },
    onCompleted: resp => {
      setLeadCampaigns(resp?.getCampaignByLead?.data || []);
    },
  });

  const [getConsentTemplate] = useLazyQuery(GET_COMPANY_CONSENT_TEMPLATES, {
    onCompleted: resp => {
      if (resp?.getConsentTemplateMessage?.statusCode === 200) {
        dispatch(
          setConsentTemplateSlice({ consentTemplate: resp?.getConsentTemplateMessage?.content })
        );
        setConsentTemplate(resp?.getConsentTemplateMessage?.content || null);
      }
    },
  });

  useEffect(() => {
    if (canViewTranslation && selectedLead?.id) {
      getTranslationSettings({
        variables: {
          leadId: selectedLead?.id,
        },
      });
    }
  }, [selectedLead?.id]);

  useEffect(() => {
    if (isEmpty(consentTemplateFromRedux)) {
      getConsentTemplate();
    } else {
      setConsentTemplate(consentTemplateFromRedux);
    }
  }, [consentTemplateFromRedux]);

  const [callConsentMessage, setCallConsentMessage] = useState(false);
  const [messageText, setmessageText] = useState("");
  const [getEngagementTemplateText] = useLazyQuery(GET_CONSENT_TEMPLATE, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      if (
        res.getEngagementTemplateText.statusCode === 200 &&
        res.getEngagementTemplateText.content !== "" &&
        callConsentMessage
      ) {
        onClickHandler(res.getEngagementTemplateText.content, selectedLead, user.id, true);
      }
    },
  });

  const [updateTranslationSetting] = useMutation(UPDATE_TRANSLATION_SETTINGS, {
    onCompleted: res => {
      if (res?.modifyTranslationSetting?.statusCode === 200) {
        setTargetLangForCustomer(
          res?.modifyTranslationSetting?.translationSetting?.customerLanguage
        );
        setTargetLangForEmployee(res?.modifyTranslationSetting?.translationSetting?.userLanguage);
        setIsAutoTranslate(
          res?.modifyTranslationSetting?.translationSetting?.isAutomaticTranslation
        );
        setIsIncludeOriginalMessage(
          res?.modifyTranslationSetting?.translationSetting?.isIncludeOriginalMessage
        );
        setIsTranslatePreviewShow(
          res?.modifyTranslationSetting?.translationSetting?.isTranslationPreview
        );
      }
    },
  });
  const [createScheduledMessage] = useMutation(CREATE_SCHEDULED_MESSAGE);
  const [translateMessage, { loading: translateMessageLoader }] = useMutation(TRANSLATE_MESSAGE);
  const [getDetectedLanguage, { loading: getDetectedLanguageLoader }] =
    useLazyQuery(GET_DETECTED_LANGUAGE);
  const [createMessage, { loading: messageLoader }] = useMutation(CREATE_MESSAGE);
  const [broadCastUpdateUnreadCount] = useMutation(BROADCAST_UNREAD_COUNT);
  const [retryMessageSend] = useMutation(RETRY_MESSAGE_SEND);
  const [updateLeadConsent, { loading: concentLoader }] = useMutation(UPDATE_LEAD_CONSENT, {
    onCompleted: res => {
      console.log("update lead consent", res);
      if (res?.updateLeadConsentStatus?.ok) {
        console.log(
          "after updating lead consent, getting lead details for selectedLead",
          selectedLead
        );
        const respLead = res?.updateLeadConsentStatus?.lead || {};
        setSelectedLead({
          ...selectedLead,
          textConsentStatus: respLead?.textConsentStatus || "PENDING",
          textConsentDate: respLead?.textConsentDate || dayjs(),
        });
        getLeadDetail({
          variables: { leadId: +selectedLead?.id },
        });
      }
    },
  });
  const [updateLeadConversionStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_LEAD_CONVERSATION_STATUS
  );
  const [updateLeadPhone, { loading: updatePhoneLoading }] = useMutation(UPDATE_LEAD_PHONE);
  const [createLeadPhone, { loading: phoneLoading }] = useMutation(CREATE_LEAD_PHONE);
  const [updateLeadEmail, { loading: updateEmailLoading }] = useMutation(UPDATE_LEAD_EMAIL);
  const [createLeadEmail, { loading: createEmailLoading }] = useMutation(CREATE_LEAD_EMAIL);

  const [updateLead] = useMutation(UPDATE_LEAD);

  const setOppDropdown = () => {
    const OppId = opportunity => {
      if (crmIntegration === "EL") {
        const elObject =
          opportunity?.elLeadOpportunities?.length > 0 && opportunity?.elLeadOpportunities[0];
        return `${elObject.elOpportunityId || ""}`;
      } else if (crmIntegration === "DS") {
        const dsObject =
          opportunity?.dsOpportunityEvents?.length > 0 && opportunity?.dsOpportunityEvents[0];
        return `${dsObject.dsEventId || ""}`;
      } else if (crmIntegration === "SF") {
        const sfLeadOpportunity =
          opportunity?.sfLeadOpportunities?.length > 0 && opportunity?.sfLeadOpportunities[0];
        return `${sfLeadOpportunity.sfOpportunityId || ""}`;
      } else if (crmIntegration === "DT") {
        const dtLeadOpportunity =
          opportunity?.dtLeadOpportunities?.length > 0 && opportunity?.dtLeadOpportunities[0];
        return `${dtLeadOpportunity.dtOpportunityId || ""}`;
      }
    };
    const oppoDropdown =
      selectedLead?.leadOpportunities?.length > 0 &&
      selectedLead?.leadOpportunities.map(opp => {
        return {
          id: opp.id,
          name: opp.name,
          opprId: OppId(opp),
          updatedDate: opp.updatedDate,
        };
      });

    if (oppoDropdown.length > 0) {
      oppoDropdown.push({
        id: "#DEFAULTOPPID",
        name: "All",
      });
    }

    setOpportunityList([...oppoDropdown]);
  };

  useEffect(() => {
    selectedLead?.leadOpportunities?.length > 0 && setOppDropdown();
    setmmsLoader(false);
  }, [selectedLead]);

  // useEffect(() => {
  //   !isUndefined(selectedLead?.id) && getLeadDetail({ variables: { leadId: +selectedLead?.id } });
  // }, [selectedLead?.id]);

  useEffect(() => {
    if (reviewInfo && reviewInfo.isActive) {
      setShowReviewRequestButton(true);
    } else {
      setShowReviewRequestButton(false);
    }
  }, [reviewInfo]);

  useEffect(() => {
    setimplicit(
      consent.find(item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_OFF")?.isActive
    );
    setexplicit(
      consent.find(item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_ON")?.isActive
    );
    setcosentAtEnd(consent.find(item => item.code === "CONSENT_MESSAGE_FOR_MESSAGE")?.isActive);
  }, [consent]);

  useEffect(() => {
    if (!isEmpty(selectedLead)) {
      +selectedLeadID !== +selectedLead?.id &&
        selectedLead?.id &&
        getLeadCampaigns({ variables: { leadId: +selectedLead?.id } });
      setSelectedLeadID(selectedLead?.id);
    }
    if ((selectedLead?.messages || []).length > 0 && page === 1) {
      scrollToBottom();
    }
    if (selectedLead?.unreadCount) {
      const selectedLeadIndex = (leadList || []).findIndex(lead => lead?.id === selectedLead?.id);
      if (selectedLeadIndex > -1) {
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          newState[selectedLeadIndex] = {
            ...newState[selectedLeadIndex],
            unreadCount: 0,
          };
          return newState;
        };
        setLeadList(arrayFn());
      }
    }
  }, [selectedLead]);

  useEffect(() => {
    // if(!gotLocationId) return;
    const location = user?.locations?.filter(loc => loc?.location?.id === currentLocation);
    if (location?.length > 0) {
      setUserLocation(location[0]?.location);
    }

    if (user?.company?.id) {
      getReviewInfo();
    }
  }, [user]);

  useEffect(() => {
    const oppName = opportunityList.find(opp => +opp.id === +activeOpp);
    setActiveOppName(oppName);
    if (oppName?.id && oppName?.id[0] !== "#") {
      setMessageFilters({
        ...messageFilters,
        leadOpportunityId: +oppName?.id,
        page: 1,
        lastId: filterLastId,
      });
      setFilterApplied(true);
    } else {
      const dummyMsgFilters = {
        ...messageFilters,
        page: 1,
        lastId: filterLastId,
      };
      delete dummyMsgFilters.leadOpportunityId;
      setFilterApplied(true);
      setMessageFilters({ ...dummyMsgFilters });
    }
  }, [activeOpp]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (user && user?.company?.id) {
      setTargetLangForEmployee(user?.preferredLanguageCode);
      getTeamsByCompany({
        variables: {
          companyId: user?.company.id,
        },
      });
      getUsersByCompany({
        variables: {
          companyId: user?.company.id,
        },
      });
      // getMessages();
    }
  }, [user]);

  const handleUnreadCount = () => {
    if (selectedLead?.messages?.length <= 3 && selectedLead?.id && leadList.length > 0) {
      msgUnreadTimeOut = setTimeout(() => {
        if (selectedLead?.message?.messageType === "APPOINTMENT" && leadList.length > 0) {
          if (
            selectedLead?.messages?.length <= 3 &&
            msgContainerRef?.current?.scrollTop === 0 &&
            !loading
          ) {
            setLeadList(prev => {
              const dummySelectedLead = { ...selectedLead };
              dummySelectedLead.unreadCount = 0;
              const selectedleadIndex = prev.findIndex(lead => lead?.id === dummySelectedLead?.id);
              const dummyLeadList = [...prev];
              dummyLeadList[selectedleadIndex] = dummySelectedLead;
              return dummyLeadList;
            });
          }
        } else {
          if (
            selectedLead?.messages?.length <= 5 &&
            msgContainerRef?.current?.scrollTop === 0 &&
            !loading &&
            leadList.length > 0
          ) {
            setLeadList(prev => {
              const dummySelectedLead = { ...selectedLead };
              dummySelectedLead.unreadCount = 0;
              const selectedleadIndex = prev.findIndex(lead => lead?.id === dummySelectedLead?.id);
              const dummyLeadList = [...prev];
              dummyLeadList[selectedleadIndex] = dummySelectedLead;
              return dummyLeadList;
            });
          }
        }
      }, 2500);
    }
  };

  const handleEditLead = lead => {
    editLeadDetails({
      variables: { leadId: lead.id },
    });
  };

  const updateDimensions = () => {
    setShowLeadSection(window.innerWidth > 1024);
    setCallLeadDetailAPI(window.innerWidth > 1024);
    setShowProfileSection(window.innerWidth > 1024);
  };

  const onMsgScroll = () => {
    // const scrollTop = filterApplied
    //   ? msgContainerRef.current.scrollTop + 10
    //   : msgContainerRef.current.scrollTop;
    // scrollTop === 10 ? setFilterApplied(true) : setFilterApplied(false);
    // scrollTop === 10 && setIsMsgScroll(true);
    // setFilterApplied(false);
    const { scrollTop } = msgContainerRef.current;
    if (
      selectedLead?.messages?.length < messageCount &&
      scrollTop === 0 &&
      !isEmpty(selectedLead?.messages)
    ) {
      // page = filterApplied ? 1 : page + 1;
      // setScrollBottom(scrollTop);
      page = selectedLead?.messages?.length > pageSize - 1 ? page + 1 : page;
      setMessageFilters({
        ...messageFilters,
        leadId: selectedLead?.id,
        lastId: filterApplied ? filterLastId : lastLead,
        page: page,
      });
    }
    timeReference = setTimeout(() => {
      if (selectedLead?.id && leadList?.length) {
        // TODO : come here for final code review
        // setLeadList(prev => {
        //   const dummySelectedLead = { ...selectedLead };
        //   dummySelectedLead.unreadCount = 0;
        //   const selectedleadIndex =
        //     leadList?.length > 0 &&
        //     leadList.findIndex(lead => +lead?.id === +dummySelectedLead?.id);
        //   const dummyLeadList = [...leadList];
        //   dummyLeadList[selectedleadIndex] = dummySelectedLead;
        //   return dummyLeadList;
        // });
      }
    }, 1000);
  };
  console.log("selectedLead?.messages?.length===>", selectedLead?.messages?.length);
  useEffect(() => {
    if (msgContainerRef.current?.scrollTop === 0 && page !== 1)
      if (selectedLead?.messages?.length % 20 === 0) {
        console.log("scroll is here");
        document.getElementById(`msg-19`)?.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("scroll is here");
        document
          .getElementById(`msg-${(selectedLead?.messages?.length % 20) - 1}`)
          ?.scrollIntoView({ behavior: "smooth" });
      }
  }, [selectedLead]);

  const handleWheel = event => {
    try {
      if (event.deltaY < 0) {
        // Mouse wheel scrolled upward
        // Your code here
        const scrollTop = filterApplied
          ? msgContainerRef.current.scrollTop + 10
          : msgContainerRef.current.scrollTop;
        const topPosition = filterApplied ? 10 : 0;
        if (scrollTop === topPosition && !loading && selectedLead.messages.length < messageCount) {
          page = selectedLead?.messages?.length > pageSize - 1 ? page + 1 : page;
          setMessageFilters({
            ...messageFilters,
            leadId: selectedLead?.id,
            lastId: filterApplied ? filterLastId : lastLead,
            page: page,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  let startY = 0;

  const handleTouchStart = event => {
    // Store the initial touch position
    startY = event.touches[0].clientY;
  };

  const handleTouchMove = event => {
    const currentY = event.touches[0].clientY;
    const deltaY = currentY - startY;
    // Check if the swipe is upwards and larger than a certain threshold (e.g., 50 pixels)
    if (deltaY > 100) {
      // Add your code here for handling the swipe up event
      const scrollTop = filterApplied
        ? msgContainerRef.current.scrollTop + 10
        : msgContainerRef.current.scrollTop;
      const topPosition = filterApplied ? 10 : 0;
      if (scrollTop === topPosition && !loading && selectedLead.messages.length < messageCount) {
        page = selectedLead?.messages?.length > pageSize - 1 ? page + 1 : page;
        setMessageFilters({
          ...messageFilters,
          leadId: selectedLead?.id,
          lastId: filterApplied ? filterLastId : lastLead,
          page: page,
        });
      }
    }
  };

  const fetchLeadData = () => {
    if (leadList.length > 0) {
      getScheduleMessagesForLead({
        variables: {
          leadId: selectedLead?.id || selectedLeadID,
        },
      });
      getMessages({
        variables: {
          ...messageFilters,
          leadId: selectedLead?.id || selectedLeadID,
          campaignIds: !isEmpty(campaignFilter)
            ? campaignFilter
            : (messageFilters.campaignIds || []).map(el => parseInt(el)),
        },
      });
      getLeadDetail({
        variables: {
          leadId: selectedLead?.id || selectedLeadID,
        },
      });
    }
  };

  useMemo(() => {
    if (selectedLead?.id) {
      fetchLeadData();
    }
  }, [selectedLead?.id, messageFilters]);

  useEffect(() => {
    if (notificationData) {
      if (notificationData.refresh) {
        setRefetching(true);
        refreshLeadList({
          variables: {
            ...variables,
            search: search?.trim(),
            status: isEmpty(search) ? uniq([...selectedQuickFilters, ...selectedStatus]) : [],
            campaignIds: variables?.campaignIds?.map(el => parseInt(el)),
            excludeDuplicate: true,
            userIds: [...userFilters],
            prioritySortOrder: leadSort,
            skipFilter: !isEmpty(search),
          },
        }).then(res => {
          setRefetching(false);
          const updatedLeadList = structuredClone(res?.data?.getLeads.data);
          const selectedLeadIndex = (updatedLeadList || []).findIndex(
            lead => lead?.id === selectedLead?.id
          );
          if (
            selectedLeadIndex > -1 &&
            +notificationData?.lead_id === +selectedLead?.id &&
            +notificationData?.user_id === +user?.id
          ) {
            updatedLeadList[selectedLeadIndex] = {
              ...updatedLeadList[selectedLeadIndex],
              unreadCount: 0,
            };
          }
          setLeadList(updatedLeadList);
          // setSelectedLead(updatedLeadList[0]);
        });
      }

      if (+notificationData?.lead_id === +selectedLead?.id) {
        broadCastUpdateUnreadCount({
          variables: {
            leadId: selectedLead?.id,
          },
        });
      }

      // if (notificationData?.lead_messages?.length > 0) {
      //   const msgContent = notificationData?.lead_messages[0]?.content?.toLowerCase();
      //   if (msgContent === "c" || msgContent === "r" || msgContent === "d") {
      //     // appointlabel was not being changed on lead list (left section)
      //     refetchLeads();
      //   }
      // }

      if (+notificationData?.lead_id === +selectedLead?.id) {
        const notificationMessages = notificationData?.lead_messages || [];
        if (notificationMessages.length > 0) {
          notificationMessages.forEach(notification => {
            const findIndex = (selectedLead?.messages || []).findIndex(
              s => +s?.id === +notification?.id
            );
            if (findIndex === -1) {
              const newMessage = {
                id: `${notification?.id}`,
                campaignId: notification?.campaign_id,
                content: notification?.content,
                translatedContent: notification?.translated_content,
                dateReceived: notification?.date_received,
                dateSent: notification?.date_sent,
                direction: notification?.direction,
                leadId: notification?.lead_id,
                userId: notification?.user_id,
                systemUserId: notification?.system_user_id,
                messageType: notification?.message_type || "",
                messageStatus: notification?.message_status || "",
                statusCode: notification?.status_code || "",
                errorCode: notification?.error_code || "",
                errorMessage: notification?.error_message || "",
                messageLog: [
                  {
                    id: notification?.message_log?.id,
                    messageId: notification?.message_log?.message_id,
                    toPhone: notification?.message_log?.to_phone,
                    fromPhone: notification?.message_log?.from_phone,
                  },
                ],
                mmsObjects: (notification?.medias || [])?.map(item => {
                  return {
                    id: item.id,
                    mediaType: item.media_type,
                    mediaUrl: item.media_url,
                    thumbnail: item.thumbnail,
                    size: item.size,
                  };
                }),
                leadOpportunity: { ...notification?.lead_opportunity },
                contentType: notification?.content_type,
              };
              const newObj = {
                ...selectedLead,
                messages: [...selectedLead.messages, { ...newMessage }],
                ...(notificationData?.text_consent_status
                  ? { textConsentStatus: notificationData?.text_consent_status }
                  : {}),
              };
              if (!selectedLead?.textConsentStatus) {
                console.log("🚀 ~ useEffect ~ notificationMessages: getLeadDetails");
                getLeadDetail({ variables: { leadId: selectedLead?.id } });
              }
              setSelectedLead({ ...newObj });
            }
          });
        }
      }
      if (notificationData?.lead_messages?.length > 0) {
        notificationData.lead_messages.forEach(notification => {
          const newMessage = {
            id: notification?.id,
            campaignId: notification?.campaign_id,
            content: notification?.content,
            translatedContent: notification?.translated_content,
            dateReceived: notification?.date_received,
            dateSent: notification?.date_sent,
            direction: notification?.direction,
            leadId: notification?.lead_id,
            userId: notification?.user_id,
            systemUserId: notification?.system_user_id,
            messageType: notification?.message_type || "",
            messageStatus: notification?.message_status || "",
            statusCode: notification?.status_code || "",
            errorCode: notification?.error_code || "",
            errorMessage: notification?.error_message || "",
            messageLog: {
              id: notification?.message_log?.id,
              messageId: notification?.message_log?.message_id,
              toPhone: notification?.message_log?.to_phone,
              fromPhone: notification?.message_log?.from_phone,
            },
            mmsObjects: (notification?.medias || [])?.map(item => {
              return {
                id: item.id,
                mediaType: item.type,
                mediaUrl: item.url,
                thumbnail: item.thumbnail,
                size: item.size,
              };
            }),
            leadOpportunity: { ...notification?.lead_opportunity },
            contentType: notification?.content_type,
            user: {
              id: user?.id,
              firstName: user?.firstName,
              lastName: user?.lastName,
            },
          };
          dispatch(addMessage({ id: newMessage.leadId, message: newMessage }));
        });
      }
      // if (notificationData?.text_consent_status) {
      //   // setSelectedLead(prev => {
      //   //   const clone = structuredClone(prev);
      //   //   clone.textConsentStatus = notificationData?.text_consent_status;
      //   //   return clone;
      //   // });
      // }
    }
  }, [notificationData]);

  const onClickHandler = (content, lead, userId, button = false) => {
    // const match = content.match(/{([^}]+)}/);
    // if (match) {
    //   message.error(lang.fillValueBeforeSend);
    //   return;
    // }

    if (!isEmpty(content) && !isEmpty(lead)) {
      if (!isEmpty(lead?.phoneNumbers)) {
        let messagePayload = {
          leadId: +lead?.id,
          userId: userId,
          direction: "TO_LEAD",
          content: content,
          reviewLink: content.includes("{ratingLink}") ? "TBD" : "",
          toPhone: lead?.phoneNumbers[0]?.phone,
          dateSent: dayjs().toISOString(),
          ...(isReview
            ? { messageType: "REVIEW", contentType: MESSAGE_CONTENT_TYPES.REVIEW_REQUEST_SENT }
            : {}),
          user: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
          },
        };

        if (activeOppName?.id && activeOppName.id[0] !== "#") {
          messagePayload.leadOpportunityId = +activeOppName.id;
        }
        const messageLog = {
          toPhone: lead?.phoneNumbers[0]?.phone,
        };
        let selectedLeadDetails = {
          ...selectedLead,
          textConsentStatus: lead?.textConsentStatus || selectedLead?.textConsentStatus,
        };
        if ((selectedLead?.messages || []).length <= 0) {
          selectedLeadDetails = { ...selectedLeadDetails, messages: [] };
        }
        const temp = structuredClone(selectedLeadDetails);
        if (!isReview) {
          temp.messages.push({
            ...messagePayload,
            type: "new",
          });
        }
        selectedLeadDetails = structuredClone(temp);
        selectedLeadDetails = {
          ...selectedLeadDetails,
          messages: selectedLeadDetails.messages.filter(item => item !== undefined),
        };
        page = 1;
        // setSelectedLead(selectedLeadDetails);
        if (eventId) {
          messagePayload = { ...messagePayload, dsEventId: eventId };
        } else {
          delete messagePayload.dsEventId;
        }
        createMessage({
          variables: {
            ...messagePayload,
          },
        }).then(res => {
          textMode === 0 ? (text.current.value = "") : setrichTextValue("");
          setmessageText("");
          if (textMode === 0) {
            // text.current.placeholder = lang.typeYourMessageHere;
            text.current.value = "";
            text.current.style.height = "0px";
          } else {
            setrichTextValue("");
          }
          setCallConsentMessage(false);
          if (res?.data?.createMessage?.statusCode === 200) {
            const selectedLeadIndex = (leadList || []).findIndex(
              lead => lead?.id === selectedLead?.id
            );
            if (selectedLeadIndex > -1) {
              const arrayFn = () => {
                const newState = structuredClone(leadList);
                newState[selectedLeadIndex] = {
                  ...newState[selectedLeadIndex],
                  unreadCount: 0,
                };
                return newState;
              };
              setLeadList(arrayFn());
            }
            dispatch(
              addMessage({ id: selectedLead.id, messages: { ...messagePayload, type: "new" } })
            );
            if (
              !isConsentStatusEnable(
                selectedLead?.textConsentStatus,
                user?.company?.isOptinConsentMethod
              )
            ) {
              updateLeadConsent({
                variables: { leadId: selectedLead?.id },
              });
            }

            const resMessage = res?.data?.createMessage?.leadMessage || {};
            const leadInfo = structuredClone({ ...selectedLeadDetails });
            if (!isReview) {
              const findIndex = leadInfo.messages.findIndex(el => el?.type === "new");
              if (findIndex > -1) {
                leadInfo.messages[findIndex] = {
                  ...resMessage,
                  leadOpportunity: resMessage?.leadOpportunity || { name: "", id: "" },
                };
              }
            } else {
              leadInfo.messages.push(resMessage);
            }
            setSelectedLead({ ...leadInfo });
            setChangeState(!changeState);
            setbtnClicked(true);
          } else {
            message.error(res?.data?.createMessage?.message || lang.msgSendFail);
            const leadInfo = { ...selectedLeadDetails };
            const findIndex = leadInfo.messages.findIndex(el => el?.type === "new");
            if (findIndex > -1) {
              leadInfo.messages[findIndex].type = "error";
            }
            setSelectedLead({ ...leadInfo });
            setChangeState(!changeState);
          }
        });
      } else {
        message.error("No phone number registered for this lead");
      }
    }
    if (!selectedLead?.textConsentStatus) {
      getLeadDetail({ variables: { leadId: selectedLead?.id } });
    }
    setisReview(false);
  };

  const handleRetry = (e, c) => {
    if (c?.id) {
      const leadDetail = structuredClone(selectedLead);
      const findIndex = leadDetail.messages.findIndex(el => +el?.id === +c?.id);
      if (findIndex > -1) {
        leadDetail.messages[findIndex] = {
          ...leadDetail.messages[findIndex],
          type: "new",
          messageStatus: "",
        };
        setSelectedLead({ ...leadDetail });
      }
      retryMessageSend({
        variables: {
          messageId: c.id,
        },
      }).then(resp => {
        if (resp?.data?.retryMessage?.statusCode === 200) {
          console.log("after retryMessageSend");
          getMessages();
        } else {
          message.error(resp?.data?.retryMessage?.message);
        }
      });
    }
  };

  const onClickReviewHandler = (content, reviewLink, lead, userId) => {
    if (!isEmpty(content)) {
      let messagePayload = {
        leadId: lead?.id,
        userId: userId,
        direction: "TO_LEAD",
        content: content,
        toPhone: lead?.phoneNumbers[0]?.phone,
        reviewLink,
      };
      let selectedLeadDetails = {
        ...selectedLead,
        textConsentStatus: lead?.textConsentStatus || selectedLead?.textConsentStatus,
      };
      if ((selectedLead?.messages || []).length <= 0) {
        selectedLeadDetails = { ...selectedLeadDetails, messages: [] };
      }

      page = 1;
      setSelectedLead(selectedLeadDetails);
      text.current.value = "";
      if (eventId) {
        messagePayload = { ...messagePayload, dsEventId: eventId };
      } else {
        delete messagePayload.dsEventId;
      }
      createMessage({
        variables: {
          ...messagePayload,
        },
      }).then(res => {
        if (res?.data?.createMessage?.statusCode === 200) {
          const selectedLeadIndex = (leadList || []).findIndex(
            lead => lead?.id === selectedLead?.id
          );
          if (selectedLeadIndex > -1) {
            const arrayFn = () => {
              const newState = structuredClone(leadList);
              newState[selectedLeadIndex] = {
                ...newState[selectedLeadIndex],
                unreadCount: 0,
              };
              return newState;
            };
            setLeadList(arrayFn());
          }
          const resMessage = res?.data?.createMessage?.leadMessage || {};
          messagePayload.content = resMessage?.content;
          selectedLeadDetails.messages.push({
            ...messagePayload,
            type: "new",
          });
          setSelectedLead(selectedLeadDetails);
          const leadDetails = structuredClone({ ...selectedLeadDetails });
          const findIndex = leadDetails.messages.findIndex(el => el?.type === "new");
          if (findIndex > -1) {
            leadDetails.messages[findIndex] = { ...resMessage };
            // leadDetails.messages[findIndex].type = "";
            // leadDetails.messages[findIndex].id = resMessage?.id;
            // leadDetails.messages[findIndex].dateSent = resMessage?.dateSent;
            // leadDetails.messages[findIndex].messageStatus = resMessage?.messageStatus;
            // leadDetails.messages[findIndex].messageType = resMessage?.messageType;
            // leadDetails.messages[findIndex].errorMessage = resMessage?.errorMessage;
            // leadDetails.messages[findIndex].errorCode = resMessage?.errorCode;
            // leadDetails.messages[findIndex].leadOpportunity = resMessage?.leadOpportunity ||
            //   resMessage?.lead_opportunity || { name: "", id: "" };
          }
          setSelectedLead({ ...leadDetails });
          setChangeState(!changeState);
        } else {
          message.error(res?.data?.createMessage?.message);
        }
      });
    }
  };

  const SetContentBy = item => {
    return item?.content || "";
  };

  const handleLogoClick = () => {
    let url = `${window.location.origin}/engagements/activity-center`;
    const loc =
      location2?.length > 0
        ? sessionStorage.getItem("currentLocationId")?.includes(",")
          ? "all"
          : locationIdslice || userLocation?.id
        : "";
    if (window.location.search.includes("entityId") || window.location.search.includes("eventId")) {
      window.open(
        `${
          window.location.origin
        }/engagements/activity-center${selectedLead?.phoneNumbers[0]?.phone.slice(2)}${
          window.location.search
        }}`,
        "_blank"
      );
    } else {
      if (selectedLead?.phoneNumbers?.length > 0) {
        url = `${url}/${selectedLead?.phoneNumbers[0]?.phone.slice(2)}?loc=${loc}`;
      } else {
        url =
          !isUndefined(queryString) && !isEmpty(queryString)
            ? `${url}/${queryString}?loc${loc}`
            : `${url}?loc=${loc}`;
      }
      window.open(url, "_blank");
    }
  };

  const handleOpportunityId = selectedLead => {
    if (
      selectedLead?.leadOpportunity?.dsOpportunityEvents?.length > 0 &&
      selectedLead?.leadOpportunity?.dsOpportunityEvents[0]?.dsEventId
    ) {
      return `#${String(selectedLead?.leadOpportunity?.dsOpportunityEvents[0]?.dsEventId).padStart(
        4,
        "0"
      )}`;
    }
    if (
      selectedLead?.leadOpportunity?.sfLeadOpportunities?.length > 0 &&
      selectedLead?.leadOpportunity?.sfLeadOpportunities[0]?.sfOpportunityId
    ) {
      return `#${String(
        selectedLead?.leadOpportunity?.sfLeadOpportunities[0]?.sfOpportunityId
      ).padStart(4, "0")}`;
    }
    // VIN
    if (
      selectedLead?.leadOpportunity?.vsLeadOpportunities?.length > 0 &&
      selectedLead?.leadOpportunity?.vsLeadOpportunities[0]?.vsLeadId
    ) {
      return `#${String(selectedLead?.leadOpportunity?.vsLeadOpportunities[0]?.vsLeadId).padStart(
        4,
        "0"
      )}`;
    }
    return `#SMAI${String(selectedLead?.leadOpportunity?.id).padStart(4, "0")}`;
  };

  const handleAppointmentModalClose = async (event, lead, isAPINeedTocall = false) => {
    event && event.stopPropagation && event.stopPropagation();
    setAppointmentLead({});
    try {
      let appointmentDetails;
      if (isAPINeedTocall) {
        // appointmentDetails = await getLeadActiveAppointment({
        //   variables: { leadId: lead?.id },
        // });
        setAppointmentPopupVisible(!openAppointmentPopup);
        setAppointmentLead(prev => {
          const newState = { ...prev, ...appointmentDetails?.data?.lead };
          !isAPINeedTocall && setAppointmentPopupVisible(!openAppointmentPopup);
          openDialog("appointmentForm", {
            dialogHeader:
              lead?.activeAppointment?.appointmentStatus === "CANCELLED"
                ? lang.appointmentCancelled
                : lang.editAppointment,
            lead: lead,
            visibleModal: openAppointmentPopup,
            appointment: lead?.activeAppointment,
            defaultDate: null,
            eventId: eventId,
            isFromCard: true,
            refetchLeads,
            setAppointmentModelVisible: handleAppointmentModalClose,
            leadOpportunities: lead?.leadOpportunities,
            redirect: async (e, updateDate, needToRedirect = true, leadId, isClickOnName) => {
              if (selectedLead?.id === leadId && isClickOnName) {
                closeDialog("appointmentForm");
                return;
              }
              if (leadId) {
                handleLeadDetailProfile(leadId);
                handleScrollOnMovement();
                return;
              }
              if (isEmpty(appintmentLead) && e) {
                // tabContext.setActiveTab("activity-center")
              } else {
                // setIsMsgApi(true);
                handleLeadDetailProfile(selectedLead?.id);
                handleScrollOnMovement();
                setShowLeadSection(false);
                setIsAppointmentChangeFromLead(prev => !prev);
              }
            },
          });
          return newState;
        });
        setAppointmentPopupVisible(!openAppointmentPopup);
      }
    } catch (err) {
      console.log("Error in getLeadActiveAppointment", err);
    }
  };

  const handleSetDefinedTemplate = msg => {
    text.current.value = msg;
    textMode === 0 ? (text.current.value = msg) : setrichTextValue(richTextValue + msg);
    // setmessageText(msg);
  };

  const updateLoading =
    updatePhoneLoading || phoneLoading || updateEmailLoading || createEmailLoading;

  const handleActiveToggle = async e => {
    await updateLeadConversionStatus({
      variables: { leadId: selectedLead?.id, disableConversation: !e },
    }).then(resp => {
      if (resp?.data?.enableDisableLeadConversation?.statusCode === 200) {
        const respLead = resp?.data?.enableDisableLeadConversation?.lead || {};
        setSelectedLead({
          ...selectedLead,
          ...(selectedLead.conversationStatus
            ? { conversationStatus: !e }
            : { conversationStatus: respLead.conversationStatus || {} }),
        });
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
          if (findIndex > -1) {
            if (newState[findIndex].conversationStatus) {
              newState[findIndex].conversationStatus.disableConversation = !e;
            } else {
              newState[findIndex] = {
                ...newState[findIndex],
                conversationStatus: respLead?.conversationStatus || {},
              };
            }
          }
          return newState;
        };
        setLeadList(arrayFn());
        message.success(`The Lead auto message feature has been updated successfully`);
      }
    });
  };

  const handleStatusFilter = status => {
    setSelectedStatus(status);
    setFilterApplied(true);
    setVariables({ ...variables, page: 1, status });
  };

  let concentTime;

  const sendContentMessage = async () => {
    let content =
      consentTemplate ||
      `${user?.company?.name || "The dealer"} wants to message you. Reply STOP to opt out.`;
    if (user?.company?.isOptinConsentMethod) {
      content = `${
        user?.company?.name || "The dealer"
      } wants to message you. Reply YES to opt in, or STOP to opt out.`;
    }
    await updateLeadConsent({ variables: { leadId: selectedLead?.id } })
      .then(resp => {
        if (resp?.data?.updateLeadConsentStatus?.ok) {
          const respLead = resp?.data?.updateLeadConsentStatus?.lead || {};
          setSelectedLead({
            ...selectedLead,
            textConsentStatus: respLead?.textConsentStatus || "PENDING",
            textConsentDate: respLead?.textConsentDate || dayjs(),
          });
          const arrayFn = () => {
            const newState = structuredClone(leadList);
            const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
            if (findIndex > -1) {
              newState[findIndex].textConsentStatus = respLead?.textConsentStatus;
              newState[findIndex].textConsentDate = respLead?.textConsentDate;
            }
            return newState;
          };
          setLeadList(arrayFn());
          message.success(`The consent has been sent successfully`);

          setTimeout(() => {
            clearTimeout(concentTime);
            setDisableConcentClick(true);
          }, 3000);
        }
      })
      .catch(() => {
        clearTimeout(concentTime);
        setDisableConcentClick(true);
      });
    onClickHandler(content, { ...selectedLead, textConsentStatus: "PENDING" }, user?.id);
  };
  const handleSendConsentClick = (e, status) => {
    status !== "PENDING" && setDisableConcentClick(prev => !prev);
    if (e) {
      e.preventDefault();
      if (status === "PENDING") {
        showConfirmationModal(
          "The consent already sent to customer, Would you like to send consent again?",
          "",
          () => {
            sendContentMessage();
          }
        );
      } else {
        sendContentMessage();
      }
    }

    concentTime = setTimeout(() => {
      setDisableConcentClick(true);
    }, 10000);
  };

  const handleSearch = e => {
    page = 0;
    lastLead = "";
    setSearchKey(e);
    setSelectedLead({});
    if (e) {
      setVariables({ ...variables, page: 1, dsEventId: "", dsEntityId: "", search: e });
      setSearch(e);
      setEventId("");
      setEntityId("");
    } else {
      setVariables({ ...variables, page: 1, dsEventId: "", dsEntityId: "" });
      setSearch(e);
      setEventId("");
      setEntityId("");
    }
    if (e === "" || e === undefined || e === null) {
      window.history.pushState("", "", `/browser-ext/`);
    }
  };

  useEffect(() => {
    if (leadCountUpdatedData?.lead_id) {
      const selectedLeadIndex = (leadList || []).findIndex(
        lead => +lead?.id === +leadCountUpdatedData?.lead_id
      );
      if (selectedLeadIndex > -1) {
        const array = () => {
          const newState = structuredClone(leadList);
          newState[selectedLeadIndex] = {
            ...newState[selectedLeadIndex],
            unreadCount: leadCountUpdatedData.unread_count,
          };
          return newState;
        };
        setLeadList(array());
      }
    }
  }, [leadCountUpdatedData]);

  useEffect(() => {
    if (
      !isUndefined(queryString) &&
      searchKey !== "" &&
      searchKey !== "remove" &&
      searchKey &&
      search !== searchKey
    ) {
      handleSearch(searchKey);
    }
  }, [searchKey]);

  useEffect(() => {
    setActiveLeadId(localStorage.getItem("changedLocationLeadId"));
  }, [locaitonLocalId]);

  function SuggestAMessage({ createSuggestedResponse, misc }) {
    return misc.loading ? (
      <Spin size="small" className="mr-2" />
    ) : (
      <Tooltip title="Suggest a message">
        <Icon
          className="text-sm mr-2"
          onClick={() => createSuggestedResponse()}
          component={EngagedAiLogo}
          alt="SocialMiningAi"
        />
      </Tooltip>
    );
  }
  useEffect(() => {
    const slug = window.location.pathname;
    const phoneNumber =
      `${slug?.split("/")[2]}`.replace("/", "") === "undefined" ||
      `${slug?.split("/")[2]}`.replace("/", "") === "remove"
        ? ""
        : `${slug?.split("/")[2]}`.replace("/", "") || "";
    phoneNumber && setSearchKey(phoneNumber);
  }, [window.location.href]);

  const handleOppFilter = filterName => {
    setActiveOpp(filterName);
  };
  const placeholder = () => {
    return buttonStyle()?.background === "#F59E0B"
      ? `${lang.clickOnYellowSendIcon}`
      : buttonStyle().background === "#EF4444"
      ? "Customer has opted out, you don’t have consent!"
      : `Use Otto AI bot to write your message or type your message here...`;
  };
  const textDisabled = () => {
    // if (selectedLead?.leadSource?.name === "Web Chat Widget") {
    //   if (connection?.connected) return false;
    //   return false;
    // }
    // console.log(
    //   "Text consent Log====> isConsentStatusEnable(selectedLead?.textConsentStatus, user?.company?.isOptinConsentMethod),cosentAtEnd,explicit ===>",
    //   isConsentStatusEnable(selectedLead?.textConsentStatus, user?.company?.isOptinConsentMethod),
    //   cosentAtEnd,
    //   explicit,
    //   selectedLead
    // );
    if (
      !isConsentStatusEnable(selectedLead?.textConsentStatus, user?.company?.isOptinConsentMethod)
    ) {
      if (implicit || explicit) {
        return true;
      }
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      return false;
    }
  };

  const handleSendReviewClick = () => {
    setSendReviewVisible(true);
    openDialog("reviewDialog", {
      handleConfirm: () => {
        closeDialog("reviewDialog");
      },
      oncancel: () => {
        closeDialog("reviewDialog");
      },
      lead: selectedLead,
      dialogHeader: "Send Review Invite Link",
      confirmBtnText: "Send",
      sendSendReview: payload => {
        onClickReviewHandler(`${payload.body}`, payload.reviewLink, selectedLead, user?.id);
      },
    });
  };

  const handleReviewTemplateUploadClick = () => {
    setReviewTemplateUploadVisible(true);
  };

  const handleAddAppointment = () => {
    setOpenAppointmentDrawer(true);
    setRefetchAppointment(false);
  };

  const tooltipTitle = () => {
    if (
      !isConsentStatusEnable(selectedLead?.textConsentStatus, user?.company?.isOptinConsentMethod)
    ) {
      const hasOptedOut = selectedLead?.textConsentStatus === "DECLINED";

      if (implicit || explicit) {
        return hasOptedOut ? "Lead has opted out" : "Send consent request";
      } else {
        return hasOptedOut ? "Lead has opted out" : "Send message";
      }
    } else {
      return "Send Message";
    }
  };
  const getLocationName = (initials = true) => {
    const id = sessionStorage.getItem("currentLocationId");
    if (id?.includes(",")) {
      return "All";
    } else {
      const obj = location2?.find(item => item.id === id);
      if (initials) {
        return convertToInitials(obj?.title);
      } else {
        return obj?.title;
      }
    }
  };
  const locations = () => {
    const id = sessionStorage.getItem("currentLocationId");
    const obj = location2.find(item => item.id === id);
    return (
      <Menu
        className="w-[200px]"
        onClick={info => {
          const e = info.key;
          localStorage.removeItem("changedLocationLeadId");
          localStorage.removeItem("clickLeadId");
          setPhone("");
          if (e === "all") {
            let ids = "";
            for (let i = 0; i < location2.length; i++) {
              const element = location2[i];
              ids = `${ids}${element.id}${location2.length - 1 === i ? "" : ","}`;
            }
            sessionStorage.setItem("currentLocationId", ids);
            dispatch(setLocationIdSlice({ locationId: ids }));
          } else {
            const locationId = location2[e].id;
            setLocation(locationId);
            sessionStorage.setItem("currentLocationId", locationId);
            const crmIntegrationType = getActiveCrmIntegration(locationId);
            setUser({
              ...user,
              locationId: locationId,
              company: { ...user.company, crmIntegrationType },
            });
            dispatch(setLocationIdSlice({ locationId: locationId }));
          }
          if (window.location.pathname?.includes("activity-center")) {
            window.history.pushState("", "", `/engagements/activity-center`);
          }

          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          dispatch(resetApiCacheStore({}));
          setchanged(!changed);
        }}
      >
        {location2?.length > 1 && (
          <Menu.Item key="all" value="all">
            {lang.all} {id === "all" && <CheckOutlined className="text-blue-400" />}
          </Menu.Item>
        )}
        {location2.map((item, index) => (
          <Menu.Item key={index}>
            <div className="flex justify-between">
              {item.title} {+id === +item.id && <CheckOutlined className="text-blue-600" />}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const buttonStyle = () => {
    const style = {
      background: selectedLead?.textConsentStatus === "DECLINED" ? "#EF4444" : "blue",
    };
    if (
      !isConsentStatusEnable(selectedLead?.textConsentStatus, user?.company?.isOptinConsentMethod)
    ) {
      if ((implicit || explicit) && selectedLead?.textConsentStatus !== "DECLINED") {
        if (selectedLead?.textConsentStatus === "ACCEPTED") {
          style.background = "#52c41a";
        } else {
          style.background = "#F59E0B";
        }
      }
      return style;
    }
    return style;
  };

  const buttonDisabled = () => {
    return selectedLead?.textConsentStatus === "DECLINED";
  };

  const sendConsentMessage = () => {
    if (implicit) {
      const { templatetext } = consent.find(
        item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_OFF"
      );
      getEngagementTemplateText({
        variables: {
          leadId: selectedLead?.id,
          content: templatetext,
        },
      });
    }
    if (explicit) {
      const { templatetext } = consent.find(
        item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_ON"
      );
      getEngagementTemplateText({
        variables: {
          leadId: selectedLead?.id,
          content: templatetext,
        },
      });
    }
  };

  const handleAddPhoneClick = () => {
    openDialog("addLeadViaPhone", {
      dialogHeader: lang.addPhone,
      phoneNumber: queryString,
      setQueryString: setQueryString,
      openModal: openAddModal && modal && apiCheck,
      statusList: statusList,
      setModalVisible: setAddPhoneModalVisible,
      variables: variables,
      data: leadList,
      sources: sourcesData?.leadSources || [],
      user: user,
      leadFirstName: leadFirstName,
      leadLastName: leadLastName,
      userLocation: userLocation,
      handleLeadCRMName: handleLeadCRMName,
      onSetLead: (item, mode, phoneLead = "") => {
        if (phoneLead === "phoneLead") {
          const itemData = { ...item, messages: [] };
          setSelectedLead(itemData);
          page = 1;
          setMessageFilters({
            ...messageFilters,
            leadId: item.id,
            lastId: lastLead,
            page: 1,
          });
          if (mode === "new") {
            if (!Array.isArray(leadList) || !Object.isExtensible(leadList)) {
              // Make a shallow copy of leadList if it's an array
              const tempLeadList = Array.isArray(leadList) ? [...leadList] : [];
              tempLeadList.unshift(itemData);
              // Replace the original leadList with the new, modified copy
              setLeadList(structuredClone([...tempLeadList]));
            } else {
              leadList.unshift(itemData);
            }
          }
        } else {
          if (selectedLead?.id === item?.id) {
            setAddPhoneModalVisible(false);
            return;
          }

          if (sessionStorage.getItem("currentLocationId") !== item?.location?.id) {
            localStorage.setItem("changedLocationLeadId", item?.id);
            sessionStorage.setItem("currentLocationId", item?.location?.id);
            dispatch(setLocationIdSlice({ locationId: item?.location?.id }));
            closeDialog("addLeadViaPhone");
            return;
          }

          handleLeadDetailProfile(item?.id);
          handleScrollOnMovement();
        }
      },
      onLeadStatus: LeadStatus,
      onLeadOwners: leadOwners,
      onVehicles: vehicles,
      crmIntegration: crmIntegration,
      isFromChatbot: true,
      setSearchKey: setSearchKey,
      setVariables: setVariables,
      handleCancelBtn: handleCancel,
    });
  };

  const handleUpdate = async payload => {
    if (payload) {
      let lead = structuredClone(selectedLead);
      if (payload?.key === "fullName") {
        const leadResp = await updateLead({
          variables: {
            leadId: selectedLead.id,
            fullName: payload?.value?.replace(/\s{2,}/g, " ").trim() || "",
            isSyncCheck: true,
            isPushCrm: true,
          },
        });
        if (leadResp?.data?.updateLead?.statusCode !== 200) {
          message.error(leadResp?.data?.updateLead?.message);
          return;
        }

        lead = {
          ...lead,
          fullName: leadResp?.data?.updateLead?.lead?.fullName,
        };
        getLeadDetail({ variables: { leadId: lead?.id } });
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
          if (findIndex > -1) {
            newState[findIndex].fullName = lead.fullName;
          }
          return newState;
        };
        setLeadList(arrayFn());
      }
      if (payload?.key === "phone") {
        let phoneResp = null;
        if (payload.id) {
          phoneResp = await updateLeadPhone({
            variables: {
              id: payload.id,
              phone: payload?.value || "",
              isSyncCheck: true,
              isPushCrm: true,
              phoneType: toTitleCase(payload.phoneType),
              leadId: selectedLead.id,
            },
          });
          if (phoneResp?.data?.updateLeadPhone?.statusCode !== 200) {
            message.error(phoneResp?.data?.updateLeadPhone?.message);
            return;
          }
          const index = lead.phoneNumbers.findIndex(
            item => toTitleCase(item.phoneType || item.lookupType) === payload.phoneType
          );
          lead.phoneNumbers[index] = {
            ...phoneResp?.data?.updateLeadPhone?.leadPhone,
          };
          getLeadDetail({ variables: { leadId: lead?.id } });
        } else {
          phoneResp = await createLeadPhone({
            variables: {
              leadId: parseInt(selectedLead?.id),
              phone: payload?.value || "",
              isSyncCheck: true,
              isPushCrm: true,
              phoneType: toTitleCase(payload.phoneType),
            },
          });
          if (phoneResp?.data?.createLeadPhone?.statusCode !== 200) {
            message.error(phoneResp?.data?.createLeadPhone?.message);
            return;
          }
          lead = {
            ...lead,
            phoneNumbers: [{ ...phoneResp?.data?.createLeadPhone?.leadPhone }],
          };
        }
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
          if (findIndex > -1) {
            const [phone] = lead.phoneNumbers;
            newState[findIndex].phoneNumbers[0] = phone;
          }
          return newState;
        };
        setLeadList(arrayFn());
      }
      if (payload?.key === "email") {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!payload?.value.match(emailRegex)) {
          message.error(lang.enterValidEmail);
          return;
        }
        let emailResp = null;
        if ((lead?.emails || []).length > 0 && lead?.emails[0] != null) {
          emailResp = await updateLeadEmail({
            variables: {
              id: parseInt(parseInt(lead?.emails[0].id)),
              email: payload?.value || "",
              isSyncCheck: true,
              isPushCrm: true,
            },
          });
          if (emailResp?.data?.updateLeadEmail?.statusCode !== 200) {
            message.error(emailResp?.data?.updateLeadEmail?.message);
            return;
          }
          lead.emails[0] = {
            ...emailResp?.data?.updateLeadEmail?.leadEmail,
          };
          getLeadDetail({ variables: { leadId: lead?.id } });
        } else {
          emailResp = await createLeadEmail({
            variables: {
              leadId: parseInt(selectedLead?.id),
              email: payload?.value || "",
              isSyncCheck: true,
              isPushCrm: true,
            },
          });
          if (emailResp?.data?.createLeadEmail?.statusCode !== 200) {
            message.error(emailResp?.data?.createLeadEmail?.message);
            return;
          }
          lead = {
            ...lead,
            emails: [{ ...emailResp?.data?.createLeadEmail?.leadEmail }],
          };
        }
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
          if (findIndex > -1) {
            const [email] = lead.emails;
            if (newState[findIndex]?.emails && newState[findIndex].emails[0]) {
              newState[findIndex].emails[0] = email;
            }
          }
          return newState;
        };
        setLeadList(arrayFn());
      }
      setSelectedLead(lead);

      setInlineEditField(null);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleLeadCRMName = () => {
    setLeadFirstName("");
    setLeadLastName("");
    setSearch(localStorage.getItem("previousLeadSearchTrack"));
  };

  const [leadIdClick, setLeadIdClick] = useState("");
  const [getLeadDetailClick] = useLazyQuery(GET_LEAD_DETAIL_CLICK, {
    fetchPolicy: "network-only",
    onCompleted: resp => {
      const leadDetail = resp?.lead;
      if (leadIdClick || localStorage.getItem("clickLeadId")) {
        let newLeadList = isClickonPotentialDuplicate
          ? leadData?.getLeads?.data?.length > 0 && [...leadData?.getLeads?.data]
          : leadList?.length > 0
          ? [...leadList]
          : leadData?.getLeads?.data?.length > 0 && [...leadData?.getLeads?.data];
        const activeLeadindex = newLeadList.findIndex(lead => +lead.id === +leadIdClick);

        if (activeLeadindex > -1) {
          newLeadList.splice(activeLeadindex, 1);
        }
        newLeadList.unshift(leadDetail);
        setSelectedLead({ ...leadDetail, messages: selectedLead.messages });
        setMessageFilters({
          campaignIds: [],
          leadId: leadIdClick,
          lastId: "",
          page: 1,
          pageSize: 20,
        });

        if (variables?.page > 1 && activeLeadindex === -1) {
          newLeadList = newLeadList.slice(0, newLeadList?.length - 1);
        }
        setSelectedLead({ ...leadDetail, messages: selectedLead.messages });
      }
    },
  });

  const handleLeadDetailProfile = async leadId => {
    setLeadIdClick(leadId);
    localStorage.setItem("clickLeadId", leadId);
    getLeadDetailClick({ variables: { leadId: leadId } });
    getMessages({
      variables: {
        ...messageFilters,
        campaignIds: !isEmpty(campaignFilter)
          ? campaignFilter
          : (messageFilters.campaignIds || []).map(el => parseInt(el)),
      },
    });
  };

  const handleScrollOnMovement = () => {
    document.querySelector(".antdList").scrollTop = 0;
  };

  const debounceFnSearchLead = useCallback(debounce(handleSearch, 300), []);

  const handlePotentialLeadPopup = () => {
    setOpenPotentialLeadDialog(prev => !prev);
  };

  const [getTranslationSettings] = useLazyQuery(GET_TRANSLATION_SETTINGS, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      if (res?.getTranslationSettings) {
        setTargetLangForCustomer(res?.getTranslationSettings?.customerLanguage);
        setTargetLangForEmployee(res?.getTranslationSettings?.userLanguage);
        setIsAutoTranslate(res?.getTranslationSettings?.isAutomaticTranslation);
        setIsIncludeOriginalMessage(res?.getTranslationSettings?.isIncludeOriginalMessage);
        setIsTranslatePreviewShow(res?.getTranslationSettings?.isTranslationPreview);
      }
    },
  });

  const renderConsentNote = () => {
    if (afterMessageFromRedux?.content) {
      if (!onlyFirstMessage) {
        return true;
      } else {
        if (selectedLead?.messages?.length) {
          return false;
        }
        return true;
      }
    }
    return false;
  };
  const handleRefetchAndCloseDrawer = flag => {
    try {
      setRefetchAppointment(true);
      setOpenAppointmentDrawer(flag);
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleKeyDown = event => {
    if (event.keyCode === 16) {
      // 16 is the key code for the Shift key
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const handleMMS = async mmsArr => {
    const messagePayload = {
      leadId: +selectedLead?.id,
      userId: +user.id,
      direction: "TO_LEAD",
      content: textMode === 0 ? text.current.value.trim() : richTextValue.trim(),
      toPhone:
        selectedLead?.phoneNumbers &&
        selectedLead?.phoneNumbers.length > 0 &&
        selectedLead?.phoneNumbers[0]?.phone.startsWith("+")
          ? selectedLead?.phoneNumbers[0]?.phone
          : `+${selectedLead?.phoneNumbers[0]?.phone}`,
      mmsObjects: mmsArr,
    };
    await createMMS({
      variables: messagePayload,
    });
  };
  const handleKeyUp = event => {
    // if (attachments.length > 0) {
    //   setmmsLoader(true);
    // }
    if (event.keyCode === 13 && event.shiftKey) {
      // 13 is the key code for the Enter key
      event.preventDefault();
      text.current.scrollTop = text.current.scrollHeight;
    } else if (event.keyCode === 13) {
      if (isEmpty(messageText.trim()) && isEmpty(text.current?.value?.trim())) {
        setmessageText(messageText.trim());
        return message.error(lang.pleaseEnterMessageToSend);
      }
      setmessageText(text.current.value);
      if (
        isConsentStatusEnable(selectedLead?.textConsentStatus, user?.company?.isOptinConsentMethod)
      ) {
        let msg;
        if (onlyFirstMessage) {
          // Consent text to be added only on the first message to lead
          if (selectedLead?.messages?.length > 0) {
            // Not the first message to lead, so consent text not required to be added.
            msg = text.current?.value.trim() || messageText.trim();
          } else {
            // Append consent text to the message
            msg =
              afterMessageConsent !== ""
                ? `${
                    textMode === 0 ? text.current.value.trim() : richTextValue.trim()
                  } ${afterMessageConsent}`
                : textMode === 0
                ? text.current.value.trim()
                : richTextValue.trim();
          }
        } else {
          // Consent text to be added on every message to lead
          msg =
            afterMessageConsent !== ""
              ? `${
                  textMode === 0 ? text.current.value.trim() : richTextValue.trim()
                } ${afterMessageConsent}`
              : textMode === 0
              ? text.current.value.trim()
              : richTextValue.trim();
        }
        onClickHandler(msg, selectedLead, user?.id);
      } else {
        event.preventDefault();
      }
    }
  };

  const handleActiveLeadFilterOnLogout = () => {
    dispatch(pushActiveLeadFilter({ activeLeadFilter: {} }));
  };
  const setLocationToNullOnLogout = () => {
    dispatch(setLocationIdSlice({ locationId: null }));
  };
  const findOpp = () => {
    return opportunityList.find(item => +item.id === +activeOpp);
  };
  const opportunityObj = useMemo(findOpp, [activeOpp, opportunityList]);
  return (
    <div className="py-[5px] px-[10px]">
      <div className=" flex items-center sticky py-2.5 top-0 bg-white z-10">
        <Icon
          onClick={() => handleLogoClick()}
          className="text-4xl cursor-pointer"
          component={EngagedAiLogo}
          alt="SocialMiningAi"
        />

        <div
          className={`flex items-center w-full ${
            window.innerWidth >= 800 ? "justify-end" : "justify-between"
          }`}
        >
          <div className="block lg:hidden text-center w-[35%]">
            {spinner ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              selectedLead?.id && (
                <div>
                  <div className="flex justify-start ml-3 flex-col">
                    <p className="text-sm text-left font-bold">{selectedLead?.fullName || ""}</p>
                    <p>{ShowConsent(selectedLead, user, true, queryString)}</p>
                  </div>
                  <span>
                    {crmIntegration === "SF" &&
                      selectedLead?.phoneNumbers?.length > 0 &&
                      selectedLead?.phoneNumbers[0]?.phone &&
                      (selectedLead?.phoneNumbers[0]?.potentialLeads?.length === 1
                        ? !selectedLead?.phoneNumbers[0]?.potentialLeads?.includes(
                            +selectedLead?.id
                          )
                        : selectedLead?.phoneNumbers[0]?.potentialLeads?.length
                        ? true
                        : false) && (
                        <Tooltip title="Potential Duplicate Leads">
                          <Icon
                            className="primaryColorTheme text-[20px]"
                            onClick={() => handlePotentialLeadPopup()}
                            type="usergroup-add"
                          />
                        </Tooltip>
                      )}
                  </span>
                </div>
              )
            )}
          </div>
          <div className="gap-2 flex items-center mr-[30px] flex-row flex-wrap">
            {Role !== "ADMIN" && (
              // <Select
              //   disabled={!canchangeLocation}
              //   onChange={e => {
              //     localStorage.removeItem("changedLocationLeadId");
              //     localStorage.removeItem("clickLeadId");
              //     setPhone("");
              //     if (e === "all") {
              //       let ids = "";
              //       for (let i = 0; i < location2.length; i++) {
              //         const element = location2[i];
              //         ids = `${ids}${element.id}${location2.length - 1 === i ? "" : ","}`;
              //       }
              //       sessionStorage.setItem("currentLocationId", ids);
              //       dispatch(setLocationIdSlice({ locationId: ids }));
              //     } else {
              //       setLocation(e);
              //       sessionStorage.setItem("currentLocationId", e);
              //       const crmIntegrationType = getActiveCrmIntegration(e);
              //       setUser({
              //         ...user,
              //         locationId: e,
              //         company: { ...user.company, crmIntegrationType },
              //       });
              //     }
              //     if (window.location.pathname?.includes("activity-center")) {
              //       window.history.pushState("", "", `/engagements/activity-center`);
              //     }
              //     dispatch(setLocationIdSlice({ locationId: e }));
              //     persistor.pause();
              //     persistor.flush().then(() => {
              //       return persistor.purge();
              //     });
              //     dispatch(resetApiCacheStore({}));
              //     setchanged(!changed);
              //   }}
              //   value={
              //     location2?.length > 0
              //       ? sessionStorage.getItem("currentLocationId")?.includes(",")
              //         ? "all"
              //         : sessionStorage.getItem("currentLocationId")
              //       : ""
              //   }
              //   className={`${
              //     window.innerWidth >= 800 ? "min-w-[200px] mr-2" : "min-w-[100px] mr-2"
              //   }`}
              // >
              //   {location2?.length > 1 && (
              //     <Select.Option key="all" value="all">
              //       {lang.all}
              //     </Select.Option>
              //   )}
              //   {(location2?.sort(dynamicSort("title")) || []).map(item => (
              //     <Select.Option key={item?.id} value={item?.id}>
              //       {item.title}
              //     </Select.Option>
              //   ))}
              // </Select>
              <Dropdown overlay={locations()} trigger={["click"]} placement="bottomRight">
                <Tooltip title={getLocationName(false)}>
                  <Button className="mr-2" shape="circle" type="text">
                    {/* {getLocationName()} */}
                    <LocationIcon className="text-[24px] text-[#64748B]" />
                  </Button>
                </Tooltip>
              </Dropdown>
            )}
            {/* <Dropdown
              overlay={
                <NotificationsDropdown
                  notifications={notifications}
                  setShowNotifModal={setShowNotifModal}
                  setSelectedNotif={setSelectedNotif}
                  getMoreNotifications={getMoreNotifications}
                  hasMore={hasMore}
                  markeNotifAsRead={markeNotifAsRead}
                  setSelectedLeadPhoneToRedux={setSelectedLeadPhoneToRedux}
                  locationIdslice={locationIdslice}
                  refetchNotifications={refetchNotifications}
                  notificationLoader={props.notificationLoader}
                  refetchNotificationsCount={refetchNotificationsCount}
                  setNotifications={setNotifications}
                />
              }
              trigger={["click"]}
              placement="bottomRight"
            >
              <DropdownMenu
                title="Notifications"
                href="#"
                style={{
                  marginRight: window.innerWidth >= 800 ? "0px" : "-17px",
                  marginBottom: "8px",
                }}
              >
               
              </DropdownMenu>
            </Dropdown> */}
            <Badge
              count={notificationsCount ? notificationsCount.getUnreadNotificationsCount : 0}
              style={{ backgroundColor: "red" }}
              // className="ml-2"
            >
              <NotificationIcon
                onClick={() => setNotificationDrawer(true)}
                className="text-[24px] mt-2 cursor-pointer text-[#64748B]"
              />
            </Badge>
            {/* <div className="flex items-center"> */}
            <Dropdown
              overlay={profileMenu(
                setShowSupportModal,
                deleteFcmDevice,
                handleActiveLeadFilterOnLogout,
                setLocationToNullOnLogout,
                isPlatformAdminUser,
                persistor,
                dispatch
              )}
              trigger={["click"]}
              placement="bottomRight"
            >
              <div className="bg-[#F1F5F9] ml-6 flex items-center p-1 rounded-[100px] cursor-pointer">
                <Avatar src={user?.profilePic || ""} size="small">
                  {convertToInitials(user?.fullName)}
                </Avatar>
              </div>
            </Dropdown>
          </div>
          {/* </div> */}
        </div>
      </div>
      <Spin
        spinning={activeSpinner || mediaUploadLoader}
        tip={
          mediaUploadLoader
            ? `${lang.uploadingMedia}...`
            : leadsLoading || !gotLocationId
            ? `${lang.searching}...`
            : activeSpinner
            ? lang.openingAppointmentForm
            : ""
        }
        className="fixed top-[50%] left-[50%] z-50 font-bold bg-white py-[15px] px-[30px]"
        style={{
          transform: "translateX(-50%) translateY(-50%)",
        }}
      />
      <div className="flex items-start gap-3 h-[91vh] 3xl:h-[93vh]">
        <div className="h-full relative">
          <div
            className={`hidden ${
              IsLeadListVisible ? "lg:block" : "lg:hidden"
            } lg:block h-full overflow-auto w-[25vw] antdList relative`}
          >
            <LeadListContainer
              setSummaryVisible={setSummaryVisible}
              showSidebar={showSidebar}
              setShowSideBar={setShowSideBar}
              selectedLead={selectedLead}
              leadsError={leadsError}
              leadsLoading={leadsLoading}
              setShowLeadSection={setShowLeadSection}
              leadList={leadList}
              setLeadList={setLeadList}
              variables={variables}
              leadCount={leadCount}
              lastLead={lastLead}
              setmessageText={setmessageText}
              setVariables={setVariables}
              setSelectedLead={setSelectedLead}
              setMessageFilters={payload => {
                text.current.value = "";
                setMessageFilters({ ...payload });
              }}
              messageFilters={messageFilters}
              sourcesData={sourcesData}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              handleSearch={debounceFnSearchLead}
              leadCampaigns={leadCampaigns}
              activeOpp={activeOpp}
              setActiveOpp={setActiveOpp}
              opportunityList={opportunityList}
              activeOppName={activeOppName}
              timeReference={timeReference}
              setOpportunityList={setOpportunityList}
              setFilterApplied={setFilterApplied}
              handleOppFilter={handleOppFilter}
              setShowProfileSection={setShowProfileSection}
              selectedStatus={uniq([...selectedQuickFilters, ...selectedStatus])}
              setSelectedStatus={setSelectedStatus}
              leadStatus={leadStatus}
              handleStatusFilter={handleStatusFilter}
              handleAppointmentModalClose={handleAppointmentModalClose}
              openAppointmentPopup={openAppointmentPopup}
              getLeadDetail={getLeadDetail}
              setSelectedLeadId={setSelectedLeadId}
              setbtnClicked={setbtnClicked}
              setRefetchAppointment={setRefetchAppointment}
              setActiveLeadId={setActiveLeadId}
              handleScrollOnMovement={handleScrollOnMovement}
              setQueryString={setQueryString}
              isFromChatbot
              eventId={eventId}
              IsLeadListVisible={IsLeadListVisible}
              handleLeadListVisible={handleLeadListVisible}
              // eslint-disable-next-line no-return-assign
              changePage={() => (page = 1)}
              spinner={spinner}
              setAttachments={setAttachments}
            />{" "}
          </div>
          <Tooltip
            placement="topLeft"
            title={IsLeadListVisible ? "Hide Conversations" : "Show Conversations"}
          >
            {IsLeadListVisible ? (
              <div
                className="hidden xl:block absolute py-[10px] z-[9] px-[6px] right-0 top-[55%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer"
                onClick={() => handleLeadListVisible()}
              >
                <LeftIcon />
              </div>
            ) : (
              <div
                className="hidden xl:block absolute py-[10px] z-[9] px-[6px] right-0 top-[55%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer"
                onClick={() => handleLeadListVisible()}
              >
                <RightIcon />
              </div>
            )}
          </Tooltip>
        </div>
        <div
          className="flex-1 flex flex-col h-full overflow-auto relative chatContainer"
          style={{
            borderLeft: `2px solid ${colorConfig.greyLightest}`,
            borderRight: `2px solid ${colorConfig.greyLightest}`,
          }}
        >
          <div
            // className="grow overflow-auto h-[71%]"
            className={`${
              !loading ? "grow overflow-auto h-[71%]" : "grow overflow-hidden h-[71%]"
            }`}
            ref={msgContainerRef}
            onScroll={onMsgScroll}
            // onWheel={handleWheel}
            // onTouchStart={handleTouchStart}
            // onTouchMove={handleTouchMove}
          >
            <Spin
              spinning={loading}
              tip={loading ? `${lang.loadingMessages}...` : `${lang.loadingMoreMessages}...`}
            >
              {activeOpp !== "#DEFAULTOPPID" && (
                <div className="sticky  top-0 bg-[#F1F5F9] z-[100] border-b-4 border-b-solid border-b-[#0000000f] text-center text-black ">
                  Opportunity: {opportunityObj?.name} #
                  {opportunityObj?.opprId || opportunityObj?.id}{" "}
                </div>
              )}
              <div className="m-[1em]">
                <div className="text-center">
                  {/* {loading && (
                  <Spin
                    spinning={loading}
                    tip={loading ? `${lang.loadingMessages}...` : `${lang.loadingMoreMessages}...`}
                  />
                )} */}
                </div>
                {
                  // !loading &&
                  !error &&
                    selectedLead &&
                    selectedLead?.messages?.length > 0 &&
                    selectedLead?.id &&
                    uniqBy(selectedLead?.messages, "id")
                      .filter(message => +message?.leadId === +selectedLead?.id)
                      .sort((a, b) => {
                        const dateA = dayjs(a.dateReceived || a.dateSent);
                        const dateB = dayjs(b.dateReceived || b.dateSent);
                        // Sort in descending order, so the latest message is at the bottom
                        return dateA.isBefore(dateB) ? -1 : 1;
                      })
                      .map((c, index) => (
                        <Messages
                          key={index}
                          SetContentBy={SetContentBy}
                          c={c}
                          userIdMap={userIdMap}
                          selectedLead={selectedLead}
                          handleOpportunityId={handleOpportunityId}
                          handleRetry={handleRetry}
                          messageIndex={index}
                          isTranslationForCustomer={isTranslationForCustomer}
                          isTranslationForEmployee={isTranslationForEmployee}
                          targetLangForCustomer={targetLangForCustomer}
                          targetLangForEmployee={targetLangForEmployee}
                          canViewTranslation={canViewTranslation}
                          isIncludeOriginalMessage={isIncludeOriginalMessage}
                          isAutoTranslate={isAutoTranslate}
                        />
                      ))
                }
                {(selectedLead?.messages || []).length <= 0 && customizeRenderEmpty(lang)}
              </div>
            </Spin>
            <div ref={messagesEndRef} />
          </div>
          {/* <div
            className="ml-[25px] flex flex-row mb-2"
            style={{
              maxWidth: isMobileView && "280px",
            }}
          >
            {canViewTranslation && (
              <Popover
                placement="top"
                content={
                  <>
                    <div style={{ width: 471 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <span
                          style={{
                            color: "#334155",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Translator Settings
                        </span>
                        <Button type="text">
                          <SVGIcon component={CloseIconForTranslation} alt="Close" />
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <span>Customer Speak:</span>
                        <Select
                          defaultValue=""
                          style={{ minWidth: 190 }}
                          onChange={value => {
                            page = 1;
                            if (value) {
                              setIsTranslationForCustomer(true);
                              setTargetLangForCustomer(value);
                              getMessages();
                            } else {
                              setTargetLangForCustomer("");
                              setIsTranslationForCustomer(false);
                            }
                          }}
                          options={languages?.map(item => ({
                            value: item?.languageCode,
                            label: item?.languageName,
                          }))}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <span>You Speak:</span>
                        <Select
                          defaultValue=""
                          style={{ minWidth: 190 }}
                          onChange={value => {
                            page = 1;
                            if (value) {
                              setIsTranslationForEmployee(true);
                              setTargetLangForEmployee(value);
                              getMessages();
                            } else {
                              setTargetLangForEmployee("");
                              setIsTranslationForEmployee(false);
                            }
                          }}
                          options={languages?.map(item => ({
                            value: item?.languageCode,
                            label: item?.languageName,
                          }))}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <span style={{ marginRight: 8 }}>
                          Automatic Translation:
                          <span className="absolute mt-[3px]">
                            <Tooltip title="The received message will be automatically translated without manually clicking translate.">
                              <SVGIcon component={InfoIcon} alt="info" />
                            </Tooltip>
                          </span>
                        </span>
                        <Switch
                          checked={isAutoTranslate}
                          onChange={value => setIsAutoTranslate(value)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <span style={{ marginRight: 8 }}>
                          Include original message:
                          <span className="absolute mt-[3px]">
                            <Tooltip title="This option defines if the original from the customer and message you typed will be included or not to the translation.">
                              <SVGIcon component={InfoIcon} alt="info" />
                            </Tooltip>
                          </span>
                        </span>
                        <Switch
                          checked={isIncludeOriginalMessage}
                          onChange={value => setIsIncludeOriginalMessage(value)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <span style={{ marginRight: 8 }}>
                          Translation Preview:
                          <span className="absolute mt-[3px]">
                            <Tooltip title="This option is for displaying a translate icon to translate the content of the message box.">
                              <SVGIcon component={InfoIcon} alt="info" />
                            </Tooltip>
                          </span>
                        </span>
                        <Switch
                          checked={isTranslatePreviewShow}
                          onChange={value => setIsTranslatePreviewShow(value)}
                        />
                      </div>
                    </div>
                  </>
                }
                trigger="click"
              >
                <Tooltip placement="topLeft" title="Translate Messages">
                  <Button
                    // onClick={() => setIsTranslateClick(!isTranslateClick)}
                    type="text"
                    className="bg-brand-100 mt-[5px]"
                    style={{
                      borderRadius: "1111px !important",
                    }}
                  >
                    <Icon className="text-xl" component={TranslateIcon} alt="Translate" />
                  </Button>
                </Tooltip>
              </Popover>
            )}
          </div> */}
          <div className="absolute right-[10px] top-[5px] z-[1000] flex flex-col">
            <Popover
              placement="left"
              title={
                <Typography variant="small" weight="bold" inline>
                  {lang.messageFilters}
                </Typography>
              }
              content={
                <LeadFilterBreadcrumbs
                  leadCampaigns={leadCampaigns}
                  filters={messageFilters}
                  setFilters={payload => {
                    page = 1;
                    setMessageFilters({ ...payload, page: 1 });
                  }}
                  opportunityList={opportunityList}
                  activeOpp={activeOpp}
                  setActiveOpp={setActiveOpp}
                  handleOppFilter={handleOppFilter}
                  activeOppName={activeOppName}
                  isFilterBreadcrumbs
                />
              }
              trigger="click"
            >
              <Tooltip placement="topLeft" title={lang.messageFilters}>
                <Badge count={messageFilters.campaignIds.length} style={{ backgroundColor: "red" }}>
                  <CustomButton
                    type="primary"
                    shape="circle"
                    icon={<AntdIconV4 type="FilterOutlined" />}
                    className="primaryBgTheme mb-[6px]"
                  />
                </Badge>
              </Tooltip>
            </Popover>
          </div>
          <OptionContainer>
            <Row gutter={24}>
              {!recording.visible ? (
                <Col span={24}>
                  <Spin
                    spinning={
                      spinner ||
                      leadsLoading ||
                      mmsLoader ||
                      (translateMessageLoader && stopLoaderForReceivedMessage)
                    }
                  >
                    {attachments.length > 0 && (
                      <Collapse className="reverse-collapse" defaultActiveKey={["1"]} accordion>
                        <Collapse.Panel key="1" header={`Attached files (${attachments.length})`}>
                          <Attachments
                            key={selectedLead?.id}
                            attachments={attachments}
                            setAttachments={setAttachments}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    )}
                    {summaryVisible.visible && (
                      <ConversationSummary
                        key={selectedLead?.id}
                        isDisabled={
                          !selectedLead?.id || messageLoader || loading || spinner || leadsLoading
                        }
                        selectedLead={selectedLead}
                        user={user}
                        summaryVisible={summaryVisible}
                      />
                    )}
                    <div>
                      <div className="flex justify-between mt-2 items-center">
                        <div className="flex flex-row items-center gap-1">
                          <div className="absolute mb-[125px]">
                            {canViewTranslation && (
                              <Popover
                                placement="top"
                                // title={
                                //   <Typography variant="small" weight="bold" inline>
                                //     Translate Filter
                                //   </Typography>
                                // }
                                visible={visibleTranslationSettings}
                                onVisibleChange={() => {
                                  setVisibleTranslationSetting(!visibleTranslationSettings);
                                }}
                                content={
                                  <>
                                    <div style={{ width: 471 }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: 8,
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "#334155",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          Translator Settings
                                        </span>
                                        <Button
                                          type="text"
                                          onClick={() => setVisibleTranslationSetting(false)}
                                        >
                                          <SVGIcon
                                            component={CloseIconForTranslation}
                                            alt="Close"
                                          />
                                        </Button>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: 8,
                                        }}
                                      >
                                        <span>Customer Speaks:</span>
                                        <Select
                                          disabled
                                          value={targetLangForCustomer}
                                          style={{ minWidth: 190 }}
                                          onChange={value => {
                                            page = 1;
                                            if (value) {
                                              setTargetLangForCustomer(value);
                                            } else {
                                              setTargetLangForCustomer("");
                                            }
                                            updateTranslationSetting({
                                              variables: {
                                                customerLanguage: value,
                                                userLanguage: targetLangForEmployee,
                                                isAutomaticTranslation: isAutoTranslate,
                                                isIncludeOriginalMessage: isIncludeOriginalMessage,
                                                isTranslationPreview: isTranslatePreviewShow,
                                                leadId: selectedLead?.id,
                                              },
                                            });
                                          }}
                                          options={languages?.map(item => ({
                                            value: item?.languageCode,
                                            label: item?.languageName,
                                          }))}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: 8,
                                        }}
                                      >
                                        <span>You Speak:</span>
                                        <Select
                                          disabled
                                          value={targetLangForEmployee}
                                          style={{ minWidth: 190 }}
                                          onChange={value => {
                                            page = 1;
                                            if (value) {
                                              setTargetLangForEmployee(value);
                                            } else {
                                              setTargetLangForEmployee("");
                                            }
                                            updateTranslationSetting({
                                              variables: {
                                                customerLanguage: targetLangForCustomer,
                                                userLanguage: value,
                                                isAutomaticTranslation: isAutoTranslate,
                                                isIncludeOriginalMessage: isIncludeOriginalMessage,
                                                isTranslationPreview: isTranslatePreviewShow,
                                                leadId: selectedLead?.id,
                                              },
                                            });
                                          }}
                                          options={languages?.map(item => ({
                                            value: item?.languageCode,
                                            label: item?.languageName,
                                          }))}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: 8,
                                        }}
                                      >
                                        <span style={{ marginRight: 8 }}>
                                          Automatic Translation:
                                          <span className="absolute mt-[3px]">
                                            <Tooltip title="The received message will be automatically translated without manually clicking translate.">
                                              <SVGIcon component={InfoIcon} alt="info" />
                                            </Tooltip>
                                          </span>
                                        </span>
                                        <Switch
                                          checked={isAutoTranslate}
                                          onChange={value => {
                                            setIsAutoTranslate(value);
                                            updateTranslationSetting({
                                              variables: {
                                                customerLanguage: targetLangForCustomer,
                                                userLanguage: targetLangForEmployee,
                                                isAutomaticTranslation: value,
                                                isIncludeOriginalMessage: isIncludeOriginalMessage,
                                                isTranslationPreview: isTranslatePreviewShow,
                                                leadId: selectedLead?.id,
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: 8,
                                        }}
                                      >
                                        <span style={{ marginRight: 8 }}>
                                          Include original message:
                                          <span className="absolute mt-[3px]">
                                            <Tooltip title="This option defines if the original from the customer and message you typed will be included or not to the translation.">
                                              <SVGIcon component={InfoIcon} alt="info" />
                                            </Tooltip>
                                          </span>
                                        </span>
                                        <Switch
                                          checked={isIncludeOriginalMessage}
                                          onChange={value => {
                                            setIsIncludeOriginalMessage(value);
                                            updateTranslationSetting({
                                              variables: {
                                                customerLanguage: targetLangForCustomer,
                                                userLanguage: targetLangForEmployee,
                                                isAutomaticTranslation: isAutoTranslate,
                                                isIncludeOriginalMessage: value,
                                                isTranslationPreview: isTranslatePreviewShow,
                                                leadId: selectedLead?.id,
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: 8,
                                        }}
                                      >
                                        <span style={{ marginRight: 8 }}>
                                          Translation Preview:
                                          <span className="absolute mt-[3px]">
                                            <Tooltip title="This option is for displaying a translate icon to translate the content of the message box.">
                                              <SVGIcon component={InfoIcon} alt="info" />
                                            </Tooltip>
                                          </span>
                                        </span>
                                        <Switch
                                          checked={isTranslatePreviewShow}
                                          onChange={value => {
                                            setIsTranslatePreviewShow(value);
                                            updateTranslationSetting({
                                              variables: {
                                                customerLanguage: targetLangForCustomer,
                                                userLanguage: targetLangForEmployee,
                                                isAutomaticTranslation: isAutoTranslate,
                                                isIncludeOriginalMessage: isIncludeOriginalMessage,
                                                isTranslationPreview: value,
                                                leadId: selectedLead?.id,
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                }
                                trigger="click"
                              >
                                <Tooltip placement="topLeft" title="Translate Messages">
                                  <Button
                                    // onClick={() => setIsTranslateClick(!isTranslateClick)}
                                    type="text"
                                    className="bg-brand-100"
                                    style={{
                                      borderRadius: "1111px !important",
                                    }}
                                  >
                                    <Icon
                                      className="text-xl"
                                      component={TranslateIcon}
                                      alt="Translate"
                                    />
                                  </Button>
                                </Tooltip>
                              </Popover>
                            )}
                          </div>
                          <Button
                            onClick={() =>
                              setSummaryVisible(prev => ({
                                visible: true,
                                count: prev.count + 1,
                              }))
                            }
                            type="text"
                            disabled={
                              !selectedLead?.id ||
                              messageLoader ||
                              loading ||
                              spinner ||
                              leadsLoading
                            }
                            className="flex items-center "
                          >
                            <SummaryIcon className="mr-2" />
                            {summaryVisible.visible
                              ? "Re-generate Conversation Summary"
                              : "Conversation Summary"}
                          </Button>
                          {summaryVisible.visible && (
                            <CloseCircleFilled
                              className="text-[#64748B] cursor-pointer"
                              onClick={() => setSummaryVisible({ visible: false, count: 0 })}
                            />
                          )}
                        </div>
                        <div className="flex">
                          {/* <Tabs
                            key={`text-tab ${selectedLead?.id}`}
                            size="small"
                            className={`${
                              attachments.length === 0 && "-mt-[0.5rem]"
                            } text-mode-tabs`}
                            activeKey={`${textMode}`}
                            onChange={e => settextMode(+e)}
                          >
                            <Tabs.TabPane key="0" tab="SMS" />
                            <Tabs.TabPane disabled key="1" tab="Chat / Email" />
                          </Tabs> */}
                          <OpportunityFilter
                            oppValue={activeOpp}
                            disabled={
                              !selectedLead?.id ||
                              messageLoader ||
                              loading ||
                              spinner ||
                              leadsLoading
                            }
                            handleFilter={handleOppFilter}
                            activeOppName={activeOppName}
                            opportunityList={opportunityList}
                          />
                        </div>
                      </div>
                      {scheduledMessages?.length > 0 && (
                        <>
                          <div className="ml-[15px] hidden sm:block mb-3 flex flex-row items-center">
                            <TimerIcon className="mr-2" /> {scheduledMessages?.length} messages
                            scheduled to be sent to{" "}
                            <span className="text-sm ml-1 font-[600]">
                              {" "}
                              {selectedLead?.fullName}
                            </span>
                            <span
                              className="text-sm font-[600] ml-1 cursor-pointer text-brand-500"
                              onClick={() =>
                                openDialog("viewScheduledMessages", {
                                  dialogHeader: `${lang.viewSchMsgPopupTitlePrefix}${selectedLead?.fullName}`,
                                  scheduledMessages,
                                  getMessages,
                                  width: "50vw",
                                  selectedLead,
                                  messageText,
                                })
                              }
                            >
                              {" "}
                              See all scheduled messages
                            </span>
                          </div>
                          <Button
                            onClick={() =>
                              openDialog("viewScheduledMessages", {
                                dialogHeader: `${lang.viewSchMsgPopupTitlePrefix}${selectedLead?.fullName}`,
                                scheduledMessages,
                                getMessages,
                                width: "50vw",
                                selectedLead,
                                messageText,
                              })
                            }
                            type="text"
                            className="block flex flex-row items-center gap-1 sm:hidden"
                          >
                            <TimerIcon />
                            View Sched. messages
                          </Button>
                        </>
                      )}
                      <InputArea
                        textMode={textMode}
                        buttonStyle={buttonStyle}
                        text={text}
                        key={selectedLead?.id}
                        selectedLead={selectedLead}
                        handleKeyDown={handleKeyDown}
                        handleKeyUp={handleKeyUp}
                        textDisabled={textDisabled}
                        messageLoader={messageLoader}
                        loading={loading}
                        spinner={spinner}
                        leadsLoading={leadsLoading}
                        lang={lang}
                        setmessageText={setmessageText}
                        placeholder={placeholder}
                        messageText={messageText}
                        richTextValue={richTextValue}
                        setrichTextValue={setrichTextValue}
                        renderConsentNote={renderConsentNote}
                        afterMessageFromRedux={afterMessageFromRedux}
                        handleSetDefinedTemplate={handleSetDefinedTemplate}
                        isEmojiOpen={isEmojiOpen}
                        setIsEmojiOpen={setIsEmojiOpen}
                        scheduledMessages={scheduledMessages}
                        refetchMessages={refetchMessages}
                        getMessages={getMessages}
                        user={user}
                        SuggestAMessage={SuggestAMessage}
                        tooltipTitle={tooltipTitle}
                        buttonDisabled={buttonDisabled}
                        mmsLoader={mmsLoader}
                        attachments={attachments}
                        setmmsLoader={setmmsLoader}
                        fetchData={fetchData}
                        setMediaUploadLoader={setMediaUploadLoader}
                        handleMMS={handleMMS}
                        onlyFirstMessage={onlyFirstMessage}
                        afterMessageConsent={afterMessageConsent}
                        onClickHandler={onClickHandler}
                        setCallConsentMessage={setCallConsentMessage}
                        sendConsentMessage={sendConsentMessage}
                        translateMessage={translateMessage}
                        languages={languages}
                        originalText={originalText}
                        setOriginalText={setOriginalText}
                        isTranslatePreviewShow={isTranslatePreviewShow}
                        setisReview={setisReview}
                      />
                    </div>
                  </Spin>
                </Col>
              ) : (
                <div style={{ display: "flex" }}>
                  <h1>Record View</h1>
                </div>
              )}
            </Row>
            <div className="w-full">
              {!isUserIsBeingSet && (!user?.phone || (user?.phone === "" && showLeadSection)) && (
                <Alert
                  className="sm:w-2/4 w-full text-[11px] "
                  message={
                    <div className="secondaryColorTheme" onClick={() => setOpenEditUserPhone(true)}>
                      {lang.dontHaveValidNumber}
                      <AntdIconV4 type="EditOutlined" />
                    </div>
                  }
                  type="error"
                />
              )}
            </div>{" "}
            <BottomActions
              attachments={attachments}
              handleMediaSharing={handleMediaSharing}
              mediaRef={mediaRef}
              videoRef={videoRef}
              documentRef={documentRef}
              setAttachments={setAttachments}
              textMode={textMode}
              handleSetDefinedTemplate={handleSetDefinedTemplate}
              text={text}
              messageText={messageText}
              textDisabled={textDisabled}
              setIsEmojiOpen={setIsEmojiOpen}
              isEmojiOpen={isEmojiOpen}
              selectedLead={selectedLead}
              user={user}
              setTemplateModal={setTemplateModal}
              handleSendReviewClick={handleSendReviewClick}
              handleAddAppointment={handleAddAppointment}
              richTextValue={richTextValue}
              setrichTextValue={setrichTextValue}
              SuggestAMessage={SuggestAMessage}
              setmessageText={setmessageText}
              setisReview={setisReview}
              activeOppName={activeOppName}
              opportunityList={opportunityList}
              crmIntegrationType={crmIntegration}
            />
          </OptionContainer>
        </div>
        <div className="relative h-full">
          <div
            className={` w-[22vw] overflow-auto h-full ${
              leadsLoading ? "pointer-events-none" : ""
            } ${isLeadProfileVisible ? "block" : "hidden"} transition-all ease-in-out delay-150`}
          >
            <LeadProfile
              // connection={connection}
              // setConnection={setConnection}
              // socket={socket}
              canEditPhone={canEdit}
              handleAddPhoneClick={handleAddPhoneClick}
              // handleBackClick={handleBackClick}
              selectedLead={selectedLead}
              // showDetails={showDetails}
              setPushToCrmModalVisible={setPushToCrmModalVisible}
              setOpenEditPopup={setOpenEditPopup}
              handleUpdate={handleUpdate}
              refetchAppointment={refetchAppointment}
              handleSendConsentClick={handleSendConsentClick}
              disableConcentClick={disableConcentClick}
              concentLoader={concentLoader}
              handleActiveToggle={handleActiveToggle}
              setInlineEditField={setInlineEditField}
              refetchLeadDetails={refetchLeadDetails}
              updateLoading={updateLoading}
              inlineField={inlineField}
              user={user}
              isUserBDC={false}
              updateStatusLoading={updateStatusLoading}
              setFilters={id => {
                page = 1;
                setMessageFilters({
                  ...messageFilters,
                  page: 1,
                  campaignIds: id ? [id] : [],
                });
              }}
              handleEditLead={lead => handleEditLead(lead)}
              setRefetchAppointment={setRefetchAppointment}
              setSelectedLead={setSelectedLead}
              setMessageFilters={setMessageFilters}
              messageFilters={messageFilters}
              refetchLeads={refetchLeads}
              crmLeadObj={selectedLead?.crmLeadObj}
              isAppointmentChangeFromLead={isAppointmentChangeFromLead}
              spinner={spinner}
              statusList={statusList}
              setstatusList={setstatusList}
              leadList={leadList}
              setShowLeadSection={setShowLeadSection}
              setOpportunityList={setOpportunityList}
              setFilterApplied={setFilterApplied}
              setSelectedLeadId={setSelectedLeadId}
              setActiveOpp={setActiveOpp}
              timeReference={timeReference}
              crmIntegration={crmIntegration}
              setLeadList={setLeadList}
              crmIntegrationData={crmIntegrationData}
              handleLeadDetailProfile={handleLeadDetailProfile}
              isFromChatbot
              setIsClickonPotentialDuplicate={setIsClickonPotentialDuplicate}
              handleAppointmentModalClose={handleAppointmentModalClose}
              getLeadDetail={getLeadDetail}
              eventId={eventId}
              getMessages={getMessages}
              languages={languages}
            />
          </div>
          <Tooltip
            placement="topLeft"
            title={isLeadProfileVisible ? "Hide Profile" : "Show Profile"}
          >
            {isLeadProfileVisible ? (
              <div
                className={`xl:block absolute py-[10px] z-[9] px-[6px]  right-auto top-[55%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer ${
                  isLeadProfileVisible ? "-left-[15px]" : "-left-[30px]"
                }`}
                onClick={() => setisLeadProfileVisible(prev => !prev)}
              >
                <RightIcon />
              </div>
            ) : (
              <div
                className={`xl:block absolute py-[10px] z-[9] px-[6px]  right-auto top-[55%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer ${
                  isLeadProfileVisible ? "-left-[15px]" : "-left-[30px]"
                }`}
                onClick={() => setisLeadProfileVisible(prev => !prev)}
              >
                <LeftIcon />
              </div>
            )}
          </Tooltip>
        </div>
        {openTemplateModal && (
          <TemplateDrawer
            openTemplateDrawer={openTemplateModal}
            setOpenTemplateDrawer={setTemplateModal}
            onApply={handleSetDefinedTemplate}
            leadId={selectedLead?.id}
          />
        )}

        {openAppointmentDrawer && (
          <AppointmentModal
            lead={selectedLead}
            eventId={eventId}
            refetchLeads={refetchLeads}
            openAppointmentDrawer={openAppointmentDrawer}
            setOpenDrawer={flag => {
              handleRefetchAndCloseDrawer(flag);
            }}
            assignedUserId={
              (selectedLead?.activeAppointment && selectedLead?.activeAppointment?.user?.id) ||
              user.id
            }
            leadOpportunities={selectedLead?.leadOpportunities}
            onClick={async (item, flag, updateDate, isClickOnName) => {
              if (item?.id === selectedLead?.id && isClickOnName) {
                setOpenAppointmentDrawer(false);
                return;
              }
              if (isClickOnName) {
                setOpenAppointmentDrawer(false);
                handleLeadDetailProfile(item?.id);
                handleScrollOnMovement();
                return;
              }
              handleLeadDetailProfile(item?.id);
              handleScrollOnMovement();
              setOpenAppointmentDrawer(false);
              setIsAppointmentChangeFromLead(prev => !prev);
            }}
          />
        )}

        {!IsLeadListVisible && (
          <Tooltip
            placement="topLeft"
            title={IsLeadListVisible ? "Hide Conversations" : "Show Conversations"}
          >
            <div
              className="fixed py-[10px] px-[6px] top-[50%] left-[1%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer"
              onClick={() => handleLeadListVisible()}
            >
              <RightIcon />
            </div>
          </Tooltip>
        )}
      </div>
      <Drawer
        title="Notifications"
        open={notificationDrawer}
        className="notification-drawer"
        width={isMobileView ? "100%" : "30%"}
        onClose={() => setNotificationDrawer(false)}
      >
        <NotificationsDropdown
          notifications={notifications}
          setShowNotifModal={setShowNotifModal}
          setSelectedNotif={setSelectedNotif}
          getMoreNotifications={getMoreNotifications}
          hasMore={hasMore}
          markeNotifAsRead={markeNotifAsRead}
          setSelectedLeadPhoneToRedux={setSelectedLeadPhoneToRedux}
          locationIdslice={locationIdslice}
          refetchNotifications={refetchNotifications}
          refetchNotificationsCount={refetchNotificationsCount}
          notificationLoader={props.notificationLoader}
          onClose={() => setNotificationDrawer(false)}
          handleDispatch={handleDispatch}
        />
      </Drawer>
    </div>
  );
};

export default BrowserExtensionPopup;
