/* eslint-disable no-unreachable */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Form, Input, message } from "antd";
import { isEmpty, uniqBy } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../amplify/authenticator-provider";
import {
  CREATE_LEAD,
  CREATE_LEAD_OPPORTUNITY,
  CREATE_LEAD_VEHICLES,
  CREATE_LEAD_VEHICLE_INVENTORY,
  DELETE_LEAD_VEHICLE_INVENTORY,
  DELETE_LEAD_VEHICLE_OF_INTEREST,
  SAVE_LEAD_NOTE,
  SYNC_UP_LEAD,
  UPDATE_LEAD_OPPORTUNITY,
  UPDATE_LEAD_V2,
  UPDATE_LEAD_VEHICLE_OF_INTEREST,
} from "../../graphql/mutation";
import {
  GET_ALL_USERS,
  GET_CRM_LEAD_STATUS,
  GET_LEAD_DETAIL_CLICK,
  GET_LEAD_SOURCES,
  GET_LEAD_SOURCES_NEW,
} from "../../graphql/query";
import useLang from "../../hooks/useLang";
import { AccessPermissionAction } from "../../library/constants";
import { checkAccessPermission, closeDialog } from "../../library/helpers";
import { getParentIds } from "../../library/utils";
import LeadCustomerInformationForm from "./leadCustomerInformationForm";
import LeadOpportunityInformationForm from "./leadOpportunityInformationForm";
import { toTitleCase } from "../../utils";

const MODAL_TITLE = `Do you want to continue?`;
const MODAL_CONTENT = `When clicked the OK button, it cannot be recovered`;

const { TextArea } = Input;

const LeadEditModal = props => {
  const {
    lead,
    refetch = () => {},
    refetchLeads = () => {},
    getLeads = () => {},
    refetchLeadOpportunities = () => {},
    crmIntegration = "",
    mode = "ADD",
    setSelectedLead,
    setLeadList,
  } = props;
  const [lang] = useLang();
  const dispatch = useDispatch();
  const { user, setleadIds } = useContext(UserContext);
  const [source, setSource] = useState("");
  const { leads } = useSelector(state => state.lead);

  const [isCrmIntegrated, setIsCrmIntegrated] = useState(!!crmIntegration);
  const [isSFOppo, setIsSFOppr] = useState(false);
  const [isDSOppo, setIsDSOppr] = useState(false);
  const [users, setUsers] = useState([]);
  const [CRMIntegrationType, setCRMIntegrationType] = useState("");

  useEffect(() => {
    setIsCrmIntegrated(!!CRMIntegrationType);
  }, [CRMIntegrationType]);

  const [getAllUsers] = useLazyQuery(GET_ALL_USERS, {
    fetchPolicy: "network-only",
    variables: { companyId: user.company.id, isGlobalLeadOwner: true },
    onCompleted: res => {
      const users = res?.getUsers?.data || [];
      const list = (users || []).map(el => ({
        value: el.id,
        label: `${el.fullName} (${el?.companyRole?.name || "N/A"})`,
      }));
      setUsers(list);
    },
  });

  const handleCrmOppr = () => {
    if (crmIntegration === "SF") {
      if (
        lead?.leadOpportunities?.length > 0 &&
        lead?.leadOpportunities[0]?.sfLeadOpportunities?.length > 0 &&
        lead?.leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId
      ) {
        setIsSFOppr(true);
      } else {
        setIsSFOppr(false);
      }
    }

    if (crmIntegration === "DS") {
      if (
        lead?.leadOpportunities?.length > 0 &&
        lead?.leadOpportunities[0]?.dsOpportunityEvents?.length > 0 &&
        lead?.leadOpportunities[0]?.dsOpportunityEvents[0]?.dsEventId
      ) {
        setIsDSOppr(true);
      } else {
        setIsDSOppr(false);
      }
    }
  };
  const [leadDescription, setLeadDescription] = useState("");
  const [leadNote, setLeadNote] = useState(
    (lead?.leadNoteslead || []).length > 0 ? lead?.leadNotes[0]?.note || "" : ""
  );
  useEffect(() => {
    if (isCrmIntegrated) {
      handleCrmOppr();
      getAllUsers();
    }
  }, [lead, crmIntegration, isCrmIntegrated]);
  const { selectedLead, selectedLeadId } = useSelector(state => state.lead);
  const [leadStatus, setLeadStatus] = useState(lead?.leadStatusTypeId || "");
  const [address, setAddress] = useState(
    (lead?.addresses?.length > 0 && lead?.addresses[0]?.addressLine1) || ""
  );
  const [city, setCity] = useState((lead?.addresses?.length > 0 && lead?.addresses[0]?.city) || "");
  const [state, setState] = useState(
    (lead?.addresses?.length > 0 && lead?.addresses[0]?.state) || ""
  );
  const [country, setCountry] = useState(
    (lead?.addresses?.length > 0 && lead?.addresses[0]?.country) || "United States"
  );

  const [leadStatusList, setLeadStatusList] = useState([]);

  // Uncomment these code for lead source , id lead is not available in list
  // useEffect(()=>{
  //   leadStatusList.length > 0 && handleLeadStatus()
  // }, [leadStatusList])

  // const handleLeadStatus = () => {
  //   const leadStatusIndex = leadStatusList.findIndex(lead => lead.id == leadStatus);
  //   if(leadStatusIndex === -1){
  //     setLeadStatusList([...leadStatusList, { id: +lead?.leadStatusType?.id, text: lead?.leadStatusType?.status }])
  //   }
  // }
  const [getLeadStatus, { data: CRMData }] = useLazyQuery(GET_CRM_LEAD_STATUS, {
    fetchPolicy: "network-only",
    onCompleted: resp => {
      let list = uniqBy(resp?.getCrmLeadStatus?.data || [], "status").map(e => ({
        text:
          resp?.getCrmLeadStatus?.crmIntegrationType === ""
            ? `${e.type} / ${e?.status.replace(/_/g, " ")}`
            : e?.status.replace(/_/g, " "),
        value: e?.statusTypeId,
      }));
      setCRMIntegrationType(resp?.getCrmLeadStatus?.crmIntegrationType);
      if (lead?.leadStatusTypeId) {
        const index = list.findIndex(item => item.value === lead?.leadStatusTypeId);
        if (index === -1) {
          const obj = resp?.getCrmLeadStatus?.data.find(
            item => item.statusTypeId === lead?.leadStatusTypeId
          );
          list = list.filter(item => item.text !== obj.status);
          list.push({
            text: obj.status.replace(/_/g, " "),
            value: obj.statusTypeId,
          });
        }
      }
      setLeadStatusList(list);
      const findIndex = list.findIndex(el => String(el.value) === String(leadStatus));
      if (findIndex === -1) {
        setLeadStatus("");
      }
    },
  });
  useEffect(() => {
    if (!lead) {
      if (CRMData?.getCrmLeadStatus?.crmIntegrationType === "SF") {
        const obj = leadStatusList.find(item => item.text === "Open");
        setLeadStatus(obj.value);
      } else if (CRMData?.getCrmLeadStatus?.crmIntegrationType === "DS") {
        const obj = leadStatusList.find(item => item.text === "Unqualified");
        setLeadStatus(obj.value);
      } else if (CRMData?.getCrmLeadStatus?.crmIntegrationType === "") {
        const obj = leadStatusList.find(item => item.text.includes("NEW SMAI LEAD"));
        setLeadStatus(obj.value);
      }
    }
  }, [leadStatusList]);

  useMemo(() => {
    getLeadStatus();
  }, [user]);
  const { data: sourcesData, loading: sourceLoader } = useQuery(GET_LEAD_SOURCES, {
    fetchPolicy: "cache-first",
    onCompleted: resp => {
      setSourceList(
        uniqBy(resp.leadSources || [], "name").map(el => ({
          text: el?.name,
          value: el?.id,
          isAddedFromCrm: el?.isAddedFromCrm,
        }))
      );
      if ((resp?.leadSources || []).length > 0) {
        resp?.leadSources.map(_source => {
          // if (_source.id === String(lead?.leadSource?.id)) {
          //   // eslint-disable-next-line no-debugger
          //   debugger;
          // }
          _source.id === String(lead?.leadSource?.id) && setSource(_source.id);
        });
      }
    },
  });
  const [sourceList, setSourceList] = useState([
    { text: lead?.leadSource?.name, value: lead?.leadSource?.id },
  ]);
  useEffect(() => {
    if (isEmpty(lead?.leadSource?.id)) {
      return;
    }
    setSource(lead?.leadSource?.id);
  }, [lead?.leadSource?.id]);
  // let sourceList = uniqBy(sourcesData?.leadSources || [], "name").map(el => ({
  //   text: el?.name,
  //   value: el?.id,
  //   isAddedFromCrm: el?.isAddedFromCrm,
  // }));
  // Uncomment these code for lead source , id lead is not available in list
  // let activeSourceIndex = sourceList.findIndex(least => least?.value == source);
  // if(activeSourceIndex == -1){
  //   sourceList = [...sourceList, {value: source, isAddedFromCrm: true, text: lead?.leadSource?.name}]
  // }

  // TODO: Need to verify again
  // if (isEmpty(lead)) {
  //   sourceList = (sourceList || []).filter(el => !el.isAddedFromCrm)
  // }
  const [createLead, { loading: createLoader }] = useMutation(CREATE_LEAD);
  const [updateLead, { loading: updateLoader }] = useMutation(UPDATE_LEAD_V2);
  const [updateLeadOpportunity, { loading: updateLeadOpportunityLoader }] =
    useMutation(UPDATE_LEAD_OPPORTUNITY);

  // const [createLeadPhone, { loading: phoneLoading }] = useMutation(CREATE_LEAD_PHONE);
  // const [updateLeadEmail, { loading: updateEmailLoading }] = useMutation(UPDATE_LEAD_EMAIL);
  // const [createLeadEmail, { loading: createEmailLoading }] = useMutation(CREATE_LEAD_EMAIL);
  // const [updateLeadAddress, { loading: updateAddressLoading }] = useMutation(UPDATE_LEAD_ADDRESS);
  // const [createLeadAddress, { loading: createAddressLoading }] = useMutation(CREATE_LEAD_ADDRESS);
  const [updateLeadVehicle] = useMutation(UPDATE_LEAD_VEHICLE_OF_INTEREST);
  const [deleteLeadVehicle] = useMutation(DELETE_LEAD_VEHICLE_OF_INTEREST);
  const [createVehicles] = useMutation(CREATE_LEAD_VEHICLES);
  const [createVehicleInventory] = useMutation(CREATE_LEAD_VEHICLE_INVENTORY);
  const [deleteVehicleInventory] = useMutation(DELETE_LEAD_VEHICLE_INVENTORY);
  const [saveLeadNote] = useMutation(SAVE_LEAD_NOTE);
  const [syncUpLead] = useMutation(SYNC_UP_LEAD);

  // const getLoading = () => {
  //   return (
  //     createLoader ||
  //     createLoading ||
  //     updatePhoneLoading ||
  //     phoneLoading ||
  //     updateEmailLoading ||
  //     createEmailLoading ||
  //     updateAddressLoading ||
  //     createAddressLoading ||
  //     saveNoteLoading
  //   );
  // };

  const handleScrollOnError = () => {
    document.querySelector(".ant-modal-wrap ").scrollTop = 0;
  };

  const [ownerList, setOwnerList] = useState([]);

  const [vehicleInfo, setVehicleInfo] = useState({});

  const [ownerIdsData, setOwnerIdsata] = useState({
    isOwnerIdsEmpty: false,
    isPrimaryIdEmpty: false,
  });

  const [leadData, setleadData] = useState({
    leadOpportunityUsers: [],
    vehiclesOfInterest: [],
    phones: [],
  });
  const [createLeadOpportunity, { loading: createLeadOpportunityLoader }] =
    useMutation(CREATE_LEAD_OPPORTUNITY);
  const [selectedOpportunity, setselectedOpportunity] = useState({
    leadOpportunityUsers: [],
    vehiclesOfInterest: [],
  });
  const [newOpportunity, setnewOpportunity] = useState(false);
  const [form] = Form.useForm();
  const [profileForm] = Form.useForm();
  const [opprtunityForm] = Form.useForm();

  useEffect(() => {
    if (mode === "EDIT") {
      setleadData({
        ...leadData,
        firstName: lead?.firstName,
        lastName: lead?.lastName,
        email: lead?.emails && lead?.emails.length > 0 && lead?.emails[0]?.email,
        phones:
          lead?.phoneNumbers.map(({ potentialLeads, lookupType, ...item }) => ({
            ...item,
            phoneType: toTitleCase(item.phoneType || lookupType),
          })) || [],
        addressLine1:
          (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.addressLine1) || "",
        addressLine2:
          (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.addressLine2) || "",
        city: (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.city) || "",
        state: (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.state) || "",
        postalCode:
          (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.postalCode) || "",
        country:
          (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.country) || "",
      });
      const resultObject = {};
      (lead?.phoneNumbers || []).forEach(item => {
        // Extract lookupType and phone from each item
        const { phoneType, phone, lookupType } = item;

        // Add the phone number to the resultObject with the lookupType as the key
        resultObject[toTitleCase(phoneType || lookupType)] = phone;
      });
      profileForm.setFieldsValue({
        firstName: lead?.firstName,
        lastName: lead?.lastName,
        email: lead?.emails && lead?.emails.length > 0 && lead?.emails[0]?.email,
        phones:
          lead?.phoneNumbers.map(({ potentialLeads, lookupType, ...item }) => ({
            ...item,
            phoneType: toTitleCase(item.phoneType || lookupType),
          })) || [],
        // phone: lead?.phoneNumbers && lead?.phoneNumbers.length > 0 && lead?.phoneNumbers[0]?.phone,
        addressLine1:
          (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.addressLine1) || "",
        addressLine2:
          (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.addressLine2) || "",
        city: (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.city) || "",
        state: (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.state) || "",
        postalCode:
          (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.postalCode) || "",
        country:
          (lead?.addresses && lead?.addresses.length > 0 && lead?.addresses[0]?.country) || "",
        ...resultObject,
      });
    }
  }, [mode, lead]);
  const handleSaveLead = async value => {
    try {
      if (mode === "EDIT") {
        /* empty */
      } else {
        const res = await createLead({
          variables: {
            ...leadData,
            vehicleOfInterests: leadData.vehiclesOfInterest,
            leadAddressObj: {
              addressLine1: leadData?.addressLine1,
              addressLine2: leadData?.addressLine2,
              city: leadData?.city,
              state: leadData?.state,
              country: leadData?.country,
              postalCode: leadData?.postalCode,
            },
            locationId: value.location,
            leadSourceType: "MANUAL",
          },
        });
        if (res.data.createLead.statusCode === 200) {
          closeDialog("leadFormDialog");
          message.success(res.data.createLead.message);
          refetch();
          refetchLeads();
          getLeads();
          refetchLeadOpportunities();
        } else {
          message.error(res.data.createLead.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const canAddEditOpportunity = checkAccessPermission(
    AccessPermissionAction.LEAD_OPPORTUNITY_MANAGEMENT_ADD_EDIT
  );

  const [leadSources, setleadSources] = useState([]);
  const { loading: leadSourcesLoader } = useQuery(GET_LEAD_SOURCES_NEW, {
    fetchPolicy: "network-only",
    variables: {
      companyId: user.company.id,
      // locationIds: sessionStorage.getItem("currentLocationId").split(","),
    },
    onCompleted: res => {
      setleadSources(res.getLeadSourcesNew);
    },
  });
  useEffect(() => {
    if (mode === "EDIT") {
      const opprtunity = lead.leadOpportunities[0];
      setselectedOpportunity(opprtunity);
      const vehicleObj = {};
      for (let index = 0; index < opprtunity?.vehiclesOfInterest?.length; index++) {
        const element = opprtunity.vehiclesOfInterest[index];
        for (const key of Object.keys(element)) {
          vehicleObj[`vehiclesOfInterest[${index}].${key}`] = element[key];
        }
      }
      const ownerObj = {};
      for (let index = 0; index < opprtunity?.leadOpportunityUsers?.length; index++) {
        const element = opprtunity.leadOpportunityUsers[index];
        ownerObj[`leadOpportunityUsers-${element.id}`] = element.userId;
      }
      opprtunityForm.setFieldsValue({
        ...opprtunity,
        opportunityName: opprtunity?.name,
        location: opprtunity?.location?.id,
        opportunityTypeId: +opprtunity?.opportunityTypeId,
        opportunitySourceId: getParentIds(leadSources, +opprtunity.opportunitySourceId),
        opportunityStatusId: +opprtunity?.opportunityStatusId,
        // ...vehicleObj,
        ...ownerObj,
      });
    } else {
      form.setFieldsValue({
        location: sessionStorage.getItem("currentLocationId").includes(",")
          ? null
          : sessionStorage.getItem("currentLocationId"),
      });
    }
    return () => setselectedOpportunity({ leadOpportunityUsers: [], vehiclesOfInterest: [] });
  }, [lead, mode, leadSources]);
  const [getLeadById] = useLazyQuery(GET_LEAD_DETAIL_CLICK);
  const updateOpportunity = async value => {
    try {
      if (newOpportunity) {
        const payload = {
          ...selectedOpportunity,
          locationId: value.location,
          name: selectedOpportunity.opportunityName,
          vehicleOfInterests: selectedOpportunity.vehiclesOfInterest,
          leadId: lead.id,
        };
        const res = await createLeadOpportunity({
          variables: {
            ...payload,
          },
        });
        if (res.data.createLeadOpportunity.statusCode === 200) {
          message.success(res.data.createLeadOpportunity.message);
          refetchLeads();
          getLeads();
          refetchLeadOpportunities();
        } else {
          message.error(res.data.createLeadOpportunity.message);
        }
      } else {
        const payload = {
          ...selectedOpportunity,
          leadOpportunityId: selectedOpportunity.id,
          locationId: value.location,
          name: selectedOpportunity.opportunityName || selectedOpportunity.name,
          vehicleOfInterests: selectedOpportunity.vehiclesOfInterest,
          leadOpportunityUsers: selectedOpportunity.leadOpportunityUsers.map(item => ({
            id: item.id,
            userId: item.userId,
            userTypeId: item.userTypeId,
          })),
        };
        const res = await updateLeadOpportunity({
          variables: {
            ...payload,
          },
        });
        if (res?.data?.updateLeadOpportunity?.statusCode === 200) {
          message.success(res?.data?.updateLeadOpportunity?.message);
          refetchLeads();
          getLeads();
          refetchLeadOpportunities();
        } else {
          message.error(res?.data?.updateLeadOpportunity?.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateLeadHandler = async value => {
    const payload = {
      leadId: lead.id,
      ...value,
      leadAddressObj: {
        addressLine1: value.addressLine1,
        addressLine2: value.addressLine2,
        city: value.city,
        state: value.state,
        country: value.country,
        postalCode: value.postalCode,
      },
      isSyncCheck: true,
      isPushCrm: false,
      phones: leadData.phones,
    };
    const res = await updateLead({
      variables: payload,
    });
    if (res.data.updateLead.statusCode === 200) {
      message.success(res.data.updateLead.message);

      if (setSelectedLead) {
        const leadRes = await getLeadById({
          variables: {
            leadId: lead.id,
          },
        });
        // setSelectedLead(prev => {
        //   return { ...prev, ...leadRes.data.lead };
        // });
        setSelectedLead({ ...selectedLead, ...leadRes.data.lead });
        const arrfn = () => {
          const temp = structuredClone(leads);
          const index = (temp || [])?.findIndex(item => +item.id === +lead.id);
          if (index !== -1) {
            temp[index] = {
              ...temp[index],
              ...leadRes.data.lead,
            };
          }
          return temp;
        };
        setLeadList(arrfn());
      } else {
        refetchLeads();
        getLeads();
        refetchLeadOpportunities();
      }
      closeDialog("leadFormDialog");
    }
  };

  const mostRecentOpportunity =
    (lead?.leadOpportunities || []).find(
      el => el.id === lead?.crmLeadObj?.activeLeadOpportunity?.id
    ) || lead?.leadOpportunities[0];

  return mode === "ADD" ? (
    <Form form={form} layout="vertical" onFinish={handleSaveLead}>
      <div className="h-[1px] w-full bg-grey-200 my-4" />
      <div className="flex flex-row w-full gap-4">
        <div className="w-1/2">
          <LeadCustomerInformationForm
            form={form}
            mode={mode}
            leadData={leadData}
            setleadData={setleadData}
            crmIntegration={crmIntegration}
          />
        </div>
        <div className=" w-[1px] bg-grey-200" />
        <div className="w-1/2">
          <LeadOpportunityInformationForm
            mode={mode}
            form={form}
            leadData={leadData}
            setleadData={setleadData}
            defaultOpportunity={mostRecentOpportunity}
            crmIntegration={crmIntegration}
            leadSources={leadSources}
            leadSourcesLoader={leadSourcesLoader}
            canAddEditOpportunity={canAddEditOpportunity}
          />
        </div>
      </div>
      {mode === "ADD" && (
        <div className="bg-grey-200 mt-3 p-6  flex flex-row items-center justify-end gap-1 ">
          <Button onClick={() => closeDialog("leadFormDialog")}>Cancel</Button>
          <Button loading={createLoader} type="primary" onClick={() => form.submit()}>
            Create Lead
          </Button>
        </div>
      )}
    </Form>
  ) : (
    <>
      <div className="h-[1px] w-full bg-grey-200 my-4" />
      <div className="flex flex-row w-full gap-4">
        <div className="w-1/2">
          <Form form={profileForm} onFinish={updateLeadHandler} layout="vertical">
            <LeadCustomerInformationForm
              mode={mode}
              loader={updateLoader}
              leadData={leadData}
              setleadData={setleadData}
              form={profileForm}
              crmIntegration={crmIntegration}
            />
          </Form>
        </div>
        <div className=" w-[1px] bg-grey-200" />
        <div className="w-1/2">
          <Form
            form={opprtunityForm}
            layout="vertical"
            onFinish={value => updateOpportunity(value)}
          >
            <LeadOpportunityInformationForm
              mode={mode}
              leadData={leadData}
              setleadData={setleadData}
              form={opprtunityForm}
              lead={lead}
              loader={updateLeadOpportunityLoader || createLeadOpportunityLoader}
              selectedOpportunity={selectedOpportunity}
              setselectedOpportunity={setselectedOpportunity}
              newOpportunity={newOpportunity}
              setnewOpportunity={setnewOpportunity}
              crmIntegration={crmIntegration}
              defaultOpportunity={lead?.leadOpportunities[0]}
              leadSources={leadSources}
              leadSourcesLoader={leadSourcesLoader}
              canAddEditOpportunity={canAddEditOpportunity}
            />
          </Form>
        </div>
      </div>
      {mode === "ADD" ? (
        <div className="bg-grey-200 mt-3 p-4  flex flex-row items-center justify-end gap-1 ">
          <Button onClick={() => closeDialog("leadFormDialog")}>Cancel</Button>
          <Button type="primary" onClick={() => form.submit()}>
            Create Lead
          </Button>
        </div>
      ) : (
        <div className="bg-grey-200 mt-3 p-2  flex flex-row items-center justify-end gap-1">
          <Button onClick={() => closeDialog("leadFormDialog")}>Cancel</Button>
        </div>
      )}
    </>
  );
};

export default LeadEditModal;

export const FormField = ({ label, isFieldRequired = false }) => {
  return (
    <label className="mb-1">
      {label}
      {isFieldRequired && <span className="requireFieldStar">*</span>}
    </label>
  );
};
