export const getLeadOpportunityStatus = record => {
  let opportunityStatus = `${record?.opportunityStatus?.name || "N/A"}`;
  if (record?.dtLeadOpportunities && record?.dtLeadOpportunities?.length > 0) {
    opportunityStatus =
      (record?.dtLeadOpportunities &&
        record?.dtLeadOpportunities.length > 0 &&
        record?.dtLeadOpportunities[0] &&
        record?.dtLeadOpportunities[0]?.dtOpportunityStage) ||
      "";
  } else if (record?.sfLeadOpportunities && record?.sfLeadOpportunities.length > 0) {
    opportunityStatus =
      (record?.sfLeadOpportunities &&
        record?.sfLeadOpportunities.length > 0 &&
        record?.sfLeadOpportunities[0] &&
        record?.sfLeadOpportunities[0] &&
        record?.sfLeadOpportunities[0]?.sfOpportunityStage) ||
      "";
  } else if (
    record?.opportunityStatus?.parentStatus &&
    record?.opportunityStatus?.parentStatus?.name !== ""
  ) {
    opportunityStatus = `${
      record?.opportunityStatus?.parentStatus?.name || ""
    } / ${opportunityStatus}`;
  }

  return opportunityStatus.replace(/_/g, " ");
};
