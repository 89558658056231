import { PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Collapse, Skeleton, message } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OPT_OUT_LEAD } from "../../../graphql/mutation";
import useLang from "../../../hooks/useLang";
import { AccessPermissionAction } from "../../../library/constants";
import { checkAccessPermission, closeDialog, openDialog } from "../../../library/helpers";
import { setSelectedLeadRedux } from "../../../redux/slices/leadSlice";
import { setLoaderStatus } from "../../../redux/slices/loaderSlice";
import { setLocationIdSlice } from "../../../redux/slices/locationSlice";
import AntdIconV4 from "../../../utils/antdIcon";
import CustomButton from "../../commonComponents/button";
import CustomLabel from "../../commonComponents/label";
import AppointmentList from "../appointmentList";
import { LeadNotes } from "./LeadNotes";
import CampignListView from "./leadCampaigns/campignListView";
import LeadConsent from "./leadConsent";
import LeadProfileDetails from "./leadProfileDetails";
import OpportunityLayer from "./opporotunityLayer/opportunityLayer";
import { NotesDrawer } from "./NotesDrawer";

const LeadProfile = props => {
  const {
    socket,
    connection,
    setConnection,
    handleBackClick,
    canEditPhone,
    handleAddPhoneClick,
    setActiveOpp,
    showDetails = true,
    setPushToCrmModalVisible,
    handleUpdate,
    refetchAppointment,
    handleSendConsentClick,
    handleActiveToggle,
    setInlineEditField,
    updateLoading,
    inlineField,
    user,
    updateStatusLoading = false,
    setFilters,
    setRefetchAppointment,
    refetchLeads,
    isAppointmentChangeFromLead,
    disableConcentClick,
    concentLoader,
    statusList,
    crmIntegration,
    crmIntegrationData,
    handleLeadDetailProfile,
    setIsClickonPotentialDuplicate,
    getLeadDetail,
    eventId,
    userIdMap,
    isFromChatbot = false,
    spinner,
    refetchLeadDetails = () => {},
    setLeadList,
    leadList,
    getMessages,
    activeOpportunity,
    languages,
  } = props;

  const dispatch = useDispatch();

  const [lang] = useLang();
  const { selectedLead, selectedLeadId } = useSelector(state => state.lead);
  const canEdit = checkAccessPermission(AccessPermissionAction.LEAD_ADD_EDIT);
  const canViewAppointment = checkAccessPermission(AccessPermissionAction.APPOINTMENT_VIEW);
  const canPushToCRM = checkAccessPermission(AccessPermissionAction.LEAD_MANAGEMENT_PUSH_TO_CRM);
  const canchangeLocation = checkAccessPermission(
    AccessPermissionAction.LEAD_MANAGEMENT_SEND_TO_STORE
  );
  const setSelectedLead = lead => {
    dispatch(setSelectedLeadRedux(lead));
  };
  const [leadNotesData, setleadNotesData] = useState({
    open: false,
    leadNote: null,
    selectedLead: null,
    mode: "",
  });
  const [optOutLead, { loading: optedOutLoader }] = useMutation(OPT_OUT_LEAD, {
    onCompleted: res => {
      if (res.optOutLead.statusCode === 200) {
        message.success(res.optOutLead.message);
        getLeadDetail({ variables: { leadId: +selectedLead?.id } });
      } else {
        message.error(res.optOutLead.message);
      }
    },
  });

  const handleOptedOut = async () => {
    dispatch(setLoaderStatus({ open: true }));
    await optOutLead({ variables: { leadId: +selectedLead?.id } });
    dispatch(setLoaderStatus({ open: false }));
    closeDialog("confirmDialog");
  };

  const handleLeadClick = (leadId, locationId) => {
    if (selectedLead?.id === leadId) {
      return;
    }
    if (+sessionStorage.getItem("currentLocationId") !== +locationId) {
      localStorage.setItem("changedLocationLeadId", leadId);
      sessionStorage.setItem("currentLocationId", locationId);
      setSelectedLead({});
      dispatch(setLocationIdSlice({ locationId: locationId }));
      setIsClickonPotentialDuplicate(true);
      refetchLeads();
      return;
    }

    handleLeadDetailProfile(leadId);
  };

  const handlePotentialLeadPopup = () => {
    openDialog("potentialDuplicateLeads", {
      dialogHeader: lang.potentialDuplicateLeads,
      setModalVisible: handlePotentialLeadPopup,
      isFromActivity: true,
      leadId: selectedLead?.id,
      selectedLeadPotentialIds: selectedLead?.phoneNumbers[0]?.potentialLeads,
      handleLeadClick: handleLeadClick,
      refetchLeads: refetchLeads,
      crmIntegrationData: crmIntegrationData,
      isFromLeadCenter: false,
      crmIntegrationType: crmIntegration,
    });
  };

  socket &&
    socket.on("webchat_disconnect", arg => {
      setConnection(arg);
    });
  const canAddEditOpportunity = checkAccessPermission(
    AccessPermissionAction.LEAD_OPPORTUNITY_MANAGEMENT_ADD_EDIT
  );
  return (
    <div>
      <div className="flex flex-col items-center w-full gap-2 pr-[7px]">
        <CustomButton
          handleClick={() =>
            sessionStorage.getItem("currentLocationId").includes(",")
              ? message.info(lang.selectLocationFromDropdown)
              : handleAddPhoneClick()
          }
          icon={<AntdIconV4 type="PlusOutlined" />}
          btnText={lang.addPhone}
          className="primaryBgTheme w-full py-1 rounded-[2px]"
          disabled={!canEditPhone}
        />
        <CustomButton
          handleClick={() =>
            openDialog("leadOpportunityFormDialog", {
              mode: "ADD",
              lead: selectedLead,
              width: "49.5vw",
              dialogHeader: (
                <div className="flex flex-col">
                  <span className="text-lg text-white font-[600]">
                    Add Opportunity for {selectedLead.fullName}
                  </span>
                  <span className="text-sm font-[400] text-white">
                    Provide the following details to create the opportunity
                  </span>
                </div>
              ),
              setSelectedLead,
              setLeadList,
              // refetchLeads,
              refetch: () => refetchLeadDetails(),
            })
          }
          icon={<AntdIconV4 type="PlusOutlined" />}
          disabled={isEmpty(selectedLead) || !canAddEditOpportunity}
          btnText="Add Opportunity"
          className="primaryBgTheme w-full py-1 rounded-[2px]"
          // disabled={!canEditPhone}
        />
      </div>

      {crmIntegration === "ADF" && (
        <div className="w-full px-1 pt-t">
          <CustomButton
            className="my-2 w-full push-to-crm-btn"
            // type="primary"
            handleClick={() =>
              canPushToCRM
                ? openDialog("pushToCrm", {
                    dialogHeader: "Push To CRM",
                    width: "85%",
                    setPushToCrmModalVisible: setPushToCrmModalVisible,
                    changeDealStatus: "",
                    conversations:
                      selectedLead && selectedLead?.messages?.length ? selectedLead?.messages : [],

                    selectedLead: selectedLead,
                    users: userIdMap,
                  })
                : message.error(lang.notAllowedToPushCRM)
            }
            btnText={lang.pushTOCRM}
          />
        </div>
      )}

      <Collapse
        bordered={false}
        className="leadProfileCollaps mt-2"
        expandIconPosition="left"
        defaultActiveKey={["1", "2", "7"]}
      >
        <Collapse.Panel
          header={
            <div className="flex justify-between font-medium">
              {lang.profile}
              {canEdit && (
                <AntdIconV4
                  type="EditOutlined"
                  className="secondaryColorTheme pl-1"
                  onClick={e => {
                    e.stopPropagation();
                    openDialog("leadFormDialog", {
                      handleConfirm: () => {
                        closeDialog("leadFormDialog");
                      },
                      mode: "EDIT",
                      dialogHeader: (
                        <div className="flex flex-col">
                          <span className="text-lg text-white font-[600]">{lang.editLead}</span>
                          <span className="text-sm font-[400] text-white">
                            Provide the customer and opportunity details to update the lead
                          </span>
                        </div>
                      ),
                      onCancel: () => {
                        closeDialog("leadFormDialog");
                      },
                      lead: selectedLead,
                      // refetchLeads: refetchLeads,
                      width: "95vw",
                      setSelectedLead,
                      setLeadList,
                    });
                  }}
                />
              )}
            </div>
          }
          key="1"
        >
          <LeadProfileDetails
            showDetails={showDetails}
            selectedLead={selectedLead}
            canEdit={canEdit}
            connection={connection}
            handleUpdate={handleUpdate}
            inlineField={inlineField}
            setInlineEditField={setInlineEditField}
            updateLoading={updateLoading}
            crmIntegration={crmIntegration}
            handlePotentialLeadPopup={handlePotentialLeadPopup}
            setLeadList={setLeadList}
            leadList={leadList}
            setSelectedLead={setSelectedLead}
            getMessages={getMessages}
            languages={languages}
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={<CustomLabel label={lang.consents} labelClass="font-medium" />}
          key="2"
        >
          <LeadConsent
            concentLoader={concentLoader}
            selectedLead={selectedLead}
            disableConcentClick={disableConcentClick}
            handleSendConsentClick={handleSendConsentClick}
            user={user}
            optedOutLoader={optedOutLoader}
            handleOptedOut={handleOptedOut}
            updateStatusLoading={updateStatusLoading}
            handleActiveToggle={handleActiveToggle}
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <div className="flex-row-centered items-center justify-between">
              <span className="font-medium">Lead Notes</span>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  setleadNotesData({ ...leadNotesData, open: true, selectedLead, mode: "ADD" });
                }}
                size="small"
                type="text"
                className="text-brand-500 font-bold"
              >
                <PlusOutlined />
                Add
              </Button>
            </div>
          }
          key={8}
        >
          <LeadNotes
            setSelectedLead={setSelectedLead}
            leadNotesData={leadNotesData}
            setleadNotesData={setleadNotesData}
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={<CustomLabel label={lang.opportunities} labelClass="font-medium" />}
          key="7"
        >
          <Skeleton loading={spinner} active avatar>
            <OpportunityLayer
              leadId={selectedLead?.id}
              showLinks
              selectedLead={selectedLead}
              statusList={statusList}
              crmIntegrationType={crmIntegration}
              crmIntegrationData={crmIntegrationData}
              refetchLeads={refetchLeads}
              onMessageFilter={camapign => setFilters(camapign)}
              onRefetch={() => setFilters(null)}
              leadOpportunities={selectedLead?.leadOpportunities}
              leadFullname={selectedLead?.fullName}
              crmLeadObj={selectedLead?.crmLeadObj}
              leadUsers={selectedLead?.leadUsers}
              refetchLeadDetails={refetchLeadDetails}
              setActiveOpp={setActiveOpp}
              oppValue={activeOpportunity}
            />
          </Skeleton>
        </Collapse.Panel>
        <Collapse.Panel
          header={<CustomLabel label={lang.campaigns} labelClass=" font-medium" />}
          key="5"
        >
          <CampignListView
            leadId={selectedLead?.id}
            showLinks
            onMessageFilter={camapign => setFilters(camapign)}
            onRefetch={() => setFilters(null)}
            handleLeadDetailProfile={handleLeadDetailProfile}
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={<CustomLabel label={lang.appointments} labelClass="font-medium" />}
          key="6"
        >
          <AppointmentList
            width="80px"
            leadId={selectedLead?.id}
            selectedLead={selectedLead}
            isFromActiviytCenter
            refetch={refetchAppointment}
            isAppointmentChangeFromLead={isAppointmentChangeFromLead}
            onRefetch={() => setRefetchAppointment(true)}
            canView={canViewAppointment}
            setSelectedLead={setSelectedLead}
            leadOpportunities={selectedLead?.leadOpportunities}
            eventId={eventId}
            onClick={() => {
              handleLeadDetailProfile(selectedLead?.id);
            }}
            handleLeadDetailProfile={handleLeadDetailProfile}
            passUserIds={false}
          />
        </Collapse.Panel>
      </Collapse>
      <style jsx>{`
        .leadProfileCollaps .ant-collapse-content-box {
          background-color: #fff !important;
        }
      `}</style>
      <NotesDrawer
        setSelectedLead={setSelectedLead}
        leadNotesData={leadNotesData}
        setleadNotesData={setleadNotesData}
      />
    </div>
  );
};

export default LeadProfile;
