import { Select } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import styled from "styled-components";
import "./schedule.css";

const { Option } = Select;
const mystyle = {
  color: "blue",
};

const OptionWarp = styled(Option)`
  border-left-style: solid;
  border-left-color: ${props =>
    props.stripcolor === "RESPONDED"
      ? "#8E0B61"
      : props.stripcolor === "WAITING_FOR_REPLY"
      ? "#007dae"
      : "#ff4500"};
  border-left-width: 0.5rem;
`;

const SelectSchedule = ({
  keyName,
  mode,
  value,
  onChange,
  placeholder,
  width = "100%",
  showAll = false,
  data = [],
  size = "default",
  disableRemove = false,
  disabled = false,
  showSearch = false,
  loading = false,
  isNeedToSearch = false,
  allowClear = false,
  hascolorCode = false,
  isFromAppointmentForm = false,
  isProspectFilter = false,
  isValueInt = false,
  className,
  getPopupContainer,
  handleSearch = () => {},
  showCompanyRole = false,
}) => {
  console.log(data);
  return (
    <Select
      key={keyName}
      allowClear={allowClear}
      mode={mode}
      size={size}
      maxTagCount="responsive"
      placeholder={placeholder}
      style={{ width }}
      onChange={onChange}
      value={value}
      disabled={disabled}
      showSearch={showSearch}
      className={className || "testing"}
      loading={loading}
      optionFilterProp="children"
      filterOption={(inputValue, option) => {
        return isNeedToSearch
          ? showCompanyRole
            ? (option?.children?.props?.children[0] ?? "")
                .toLowerCase()
                .includes(inputValue.toLowerCase()) ||
              (option?.children?.props?.children[2] ?? "")
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            : option?.props?.children?.props?.children
                ?.toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase())
          : console.log(inputValue, option);
      }}
      onSearch={handleSearch}
      getPopupContainer={getPopupContainer}
    >
      {showAll && <Option value="All">All</Option>}
      {(data || [])?.map(el => {
        const colorCode = el?.hasTemplate;
        return hascolorCode ? (
          <OptionWarp
            value={isValueInt ? +el.id : el.id}
            title={el?.id}
            disabled={el?.hasTemplate && value.includes(el.id)}
            colorCode={el?.id}
            className="testing"
          >
            {!colorCode && disableRemove ? (
              <span style={{ color: "blue" }}>{el.name || el?.label}</span>
            ) : (
              <span>{el.name || el?.label}</span>
            )}
          </OptionWarp>
        ) : (
          <Option
            value={isProspectFilter ? el?.label : isValueInt ? +el.id : el?.id}
            disabled={el?.hasTemplate && value.includes(el?.id)}
            className={mystyle}
            // stripcolor={el.id}
          >
            {!colorCode && disableRemove ? (
              <span style={{ color: "red" }}>{el?.name || el?.label}</span>
            ) : (
              <span style={{ color: isFromAppointmentForm ? "" : "#000000a6" }}>
                {el?.name || el?.label}{" "}
                {showCompanyRole &&
                  `${!isEmpty(el?.companyRole) ? `(${el?.companyRole?.name})` : ""}`}
              </span>
            )}
          </Option>
        );
      })}
    </Select>
  );
};
export default SelectSchedule;
