import "../../../CSS/concentAdmin.css";
import { useQuery } from "@apollo/client";
import { Card, Col, Input, Row, Select } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useContext, useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { UserContext } from "../../../amplify/authenticator-provider";
import { GET_VEHICLE_MAKES, GET_VEHICLE_MODELS } from "../../../graphql/query";
import { Container, ContainerNavigation } from "../../../library/basicComponents";
import { AccessPermissionAction } from "../../../library/constants";
import { checkAccessPermission, getVehicleYears } from "../../../library/helpers";
import SelectSchedule from "../../../library/selectSchedule";
import CustomTable from "../../commonComponents/table";
import AntdIconV4 from "../../../utils/antdIcon";
import { FormField } from "../../engagements/leadEditModal";
import CustomLabel from "../../commonComponents/label";
import CustomCheckbox from "../../commonComponents/checkbox";
import CustomButton from "../../commonComponents/button";
import { colorConfig } from "../../../library/colorConfig";
import useLang from "../../../hooks/useLang";

const { Option } = Select;

const ContainerNavigationWrap = styled(ContainerNavigation)`
  border-bottom: 0;
  padding: 0;
  .ant-card-body {
    padding: 12px;
  }
`;

const ContainerWrap = styled(Container)`
  margin-top: 10px;
  .ant-modal-body {
    padding-top: 10px;
  }
  border: 0;
`;

const CardWrapper = styled(Card)`
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 15%) !important;
  & .ant-card-head-title {
    color: ${colorConfig.secondaryDark} !important;
  }
  .ant-card-actions {
    background-color: #fff;
  }
  width: 100%;
`;

const customerInterestData = [
  { id: "BUY", name: "Buy" },
  { id: "SELL", name: "Sell" },
  { id: "TRADE", name: "Trade" },
  { id: "NONE", name: "None" },
];

const AddVehicleForm = ({
  vehicles,
  removeVehicle,
  loading = false,
  selectedVehicle = null,
  showPreviousVechile = true,
  vehicleCount = 0,
  setError,
  handleScrollOnError,
  setVehicles,
  vehicleInfo,
  setVehicleInfo,
  setIsVehicleInEditMode = () => {},
}) => {
  const { user } = useContext(UserContext);

  const [lang] = useLang();

  const yearData = getVehicleYears().map(el => ({ id: el, name: el }));
  const [editVehicle, setEditVehicle] = useState(null);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [id, setid] = useState("");
  const [year, setYear] = useState(null);
  const [vin, setVin] = useState("");
  const [stockNumber, setStockNumber] = useState("");
  const [customerInterest, setCustomerInterest] = useState("");
  const [previousVehicle, setPreviousVehicle] = useState(false);
  const [isPrimary, setPrimaryVehicle] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [makeData, setMakeData] = useState([]);
  const [modelVeriables, setModelVariables] = useState({
    search: "",
    year: year,
    makeName: "",
  });

  const handleEdit = payload => {
    if (payload) {
      setMake(payload?.make);
      setYear(payload?.year);
      setModel(payload?.model);
      setid(payload.id);
      setCustomerInterest(payload?.customerInterest);
      setModelVariables({
        ...modelVeriables,
        makeName: payload?.make,
        year: payload?.year,
      });
    }
    setEditVehicle(payload);
    setPreviousVehicle(payload?.isCurrent);
    setPrimaryVehicle(payload?.isPrimary || false);
    setIsVehicleInEditMode(true);
  };
  useEffect(() => {
    if (selectedVehicle) {
      handleEdit(selectedVehicle);
    }
  }, [selectedVehicle]);

  const { loading: modelLoading } = useQuery(GET_VEHICLE_MODELS, {
    variables: { ...modelVeriables },
    onCompleted: resp => {
      // eslint-disable-next-line no-underscore-dangle
      const _modelData = (resp?.getVehicleModels?.data || []).map(el => ({
        id: el.ModelName,
        name: el.ModelName,
      }));
      setModelData(_modelData);
    },
  });

  const [makeVeriables, setMakeVariables] = useState({
    page: 1,
    pageSize: 25,
  });

  const { loading: makeLoading } = useQuery(GET_VEHICLE_MAKES, {
    variables: { ...makeVeriables },
    onCompleted: res => {
      const makes = (res?.getVehicleMakes?.data || []).map(el => ({
        id: el.MakeName,
        name: el.MakeName,
      }));
      setMakeData(makes);
    },
  });

  const resetForm = () => {
    setMake("");
    setYear("");
    setModel("");
    setVin("");
    setStockNumber("");
    setCustomerInterest("");
    setEditVehicle(null);
    setPreviousVehicle(false);
    setPrimaryVehicle(false);
  };

  const submitVehicle = () => {
    if (isEmpty(make) || year === "" || customerInterest === "") {
      setError(lang.enterMakeModelYear);
      handleScrollOnError();
      return;
    }
    setError(false);

    const selectedVehicle = {
      id: editVehicle ? editVehicle?.id : null,
      make: make,
      model: model,
      year: year,
      vin: vin,
      stockNumber: stockNumber,
      customerInterest: customerInterest,
      isCurrent: ["SELL", "TRADE", "NONE"].includes(customerInterest) ? true : previousVehicle,
      isPrimary: isPrimary || false,
    };

    if (editVehicle) {
      const editArray = [...vehicles];
      const editArrayIndex = vehicles.findIndex(vehicle => vehicle?.id === editVehicle?.id);
      editArray[editArrayIndex] = selectedVehicle;
      setVehicles([...editArray]);
    } else {
      setVehicles([...vehicles, { ...selectedVehicle }]);
    }
    resetForm();
    setVehicleInfo({});
    setIsVehicleInEditMode(false);
  };

  const handleDeleteVehicle = (recordId, vehicleId) => {
    if (recordId) {
      removeVehicle(recordId);
    } else {
      const editArray = [...vehicles];
      editArray.splice(vehicleId, 1);
      setVehicles([...editArray]);
    }
  };

  const handleSearch = e => {
    setMakeVariables(prevState => {
      const newState = { ...prevState };
      newState.search = e;
      return newState;
    });
  };

  const canEdit = checkAccessPermission(AccessPermissionAction.LEAD_VEHICLE_ADD_EDIT);
  const canDelete = checkAccessPermission(AccessPermissionAction.LEAD_VEHICLE_DELETE);
  useEffect(() => {
    if (id) {
      setVehicleInfo({ ...vehicleInfo, id: id });
    } else {
      setVehicleInfo(prev => {
        delete prev.id;
        return prev;
      });
    }
  }, [id]);

  return (
    <ContainerWrap auto>
      <Row gutter={[4, 16]}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
          xl={{ span: 4 }}
          lg={{ span: 4 }}
          id="antdYearDropdown"
        >
          <FormField label={lang.year} isFieldRequired />
          <SelectSchedule
            getPopupContainer={() => document.getElementById("antdYearDropdown")}
            keyName="year"
            mode="single"
            value={year}
            placeholder={lang.selectYear}
            showAll={false}
            showSearch
            isNeedToSearch
            onChange={e => {
              setYear(e);
              setVehicleInfo({ ...vehicleInfo, year: e });
              setMake("");
              setModel("");
              setCustomerInterest("");
            }}
            data={yearData}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
          xl={{ span: 4 }}
          lg={{ span: 4 }}
          id="antdMakeDropdown"
        >
          <FormField label={lang.make} isFieldRequired />
          <Select
            getPopupContainer={() => document.getElementById("antdMakeDropdown")}
            key="keyName"
            size="medium"
            placeholder={lang.selectMake}
            className="antdLoader w-full"
            onChange={e => {
              setMake(e);
              setModel("");
              setModelVariables({
                ...modelVeriables,
                makeName: e,
                year: year,
              });
              setVehicleInfo({ ...vehicleInfo, make: e });
            }}
            value={make}
            showSearch
            onSearch={handleSearch}
            loading={makeLoading}
            disabled={!year}
            filterOption={(inputValue, option) =>
              option?.props?.children?.toString().toLowerCase().includes(inputValue.toLowerCase())
            }
          >
            {(makeData || []).map(el => {
              return (
                <Option key={el.name} value={el.id}>
                  {el.name}
                </Option>
              );
            })}
          </Select>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} xl={{ span: 4 }} lg={{ span: 4 }}>
          <FormField label={lang.model} />
          <div className="antdLoader" id="antdModelDropdown">
            <SelectSchedule
              keyName="model"
              getPopupContainer={() => document.getElementById("antdModelDropdown")}
              allowClear
              mode="single"
              value={model}
              placeholder={lang.selectModel}
              showAll={false}
              showSearch
              onChange={e => {
                setModel(e);
                setVehicleInfo({ ...vehicleInfo, model: e });
              }}
              data={modelData}
              isNeedToSearch
              loading={modelLoading}
              disabled={!make || modelLoading}
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} xl={{ span: 4 }} lg={{ span: 4 }}>
          <FormField label={lang.vin} />
          <Input
            placeholder={lang.vin}
            name="vin"
            value={vin}
            onChange={e => {
              setVin(e.target.value);
              setVehicleInfo({ ...vehicleInfo, vin: e.target.value });
            }}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} xl={{ span: 4 }} lg={{ span: 4 }}>
          <FormField label={lang.stockNumber} />
          <Input
            placeholder={lang.stockNumber}
            name="stockNumber"
            value={stockNumber}
            onChange={e => {
              setStockNumber(e.target.value);
              setVehicleInfo({ ...vehicleInfo, stockNumber: e.target.value });
            }}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
          xl={{ span: 4 }}
          lg={{ span: 4 }}
          id="antdInterestDropdown"
        >
          <FormField label={lang.customerInterest} isFieldRequired />
          <SelectSchedule
            keyName="interest"
            mode="single"
            value={customerInterest}
            placeholder={lang.selectCustomeInterest}
            showAll={false}
            showSearch
            isNeedToSearch
            onChange={e => {
              setCustomerInterest(e);
              setPreviousVehicle(["SELL", "TRADE", "NONE"].includes(e));
              setVehicleInfo({ ...vehicleInfo, customerInterest: e });
            }}
            data={customerInterestData}
            getPopupContainer={() => document.getElementById("antdInterestDropdown")}
            disabled={!make}
          />
        </Col>
      </Row>

      <div className="w-full flex flex-row justify-between px-[5px] py-[10px] items-center">
        <div
          className={`flex items-baseline w-full ${
            window.innerWidth > 768 ? "flex-row" : "flex-col"
          } `}
        >
          <CustomCheckbox
            handleChange={e => {
              setPrimaryVehicle(e.target.checked);
              setVehicleInfo({ ...vehicleInfo, isPrimary: e.target.checked });
            }}
            checked={isPrimary}
            checkboxLabel={lang.isPrimaryVehicle}
          />

          {showPreviousVechile ? (
            <CustomCheckbox
              handleChange={e => {
                setPreviousVehicle(e.target.checked);
              }}
              className={`${window.innerWidth > 768 ? "mt-[5px]" : "mt-0"}`}
              checked={previousVehicle}
              checkboxLabel={lang.previousOrCurrent}
            />
          ) : (
            <div> </div>
          )}
          <CustomButton
            type="primary"
            handleClick={submitVehicle}
            loading={loading}
            disabled={user.company.crmIntegrationType === "DS" && vehicleCount >= 3 && !editVehicle}
            className="ml-auto"
            btnText={editVehicle ? lang.updateVehicle : lang.addToList}
          />
        </div>
      </div>
      <VehicleTable
        vehiclesList={vehicles}
        onDeleteVehicle={handleDeleteVehicle}
        onEditVehicle={handleEdit}
        canEdit={canEdit}
        canDelete={canDelete}
      />
    </ContainerWrap>
  );
};

export default AddVehicleForm;

const VehicleTable = ({ vehiclesList, onDeleteVehicle, onEditVehicle, canEdit, canDelete }) => {
  const [lang] = useLang();

  let columns = [
    {
      title: lang.vehicleMake,
      dataIndex: "make",
    },
    {
      title: lang.vehicleModel,
      dataIndex: "model",
    },
    {
      title: lang.vehicleYear,
      dataIndex: "year",
    },
    {
      title: lang.customerInterest,
      dataIndex: "customerInterest",
    },
    {
      title: lang.isPrimary,
      dataIndex: "isPrimary",
      key: "isPrimary",
      render: (text, record) => {
        return <span>{record?.isPrimary ? lang.yes : lang.no}</span>;
      },
    },
    {
      title: lang.currentOrPreviousVehicle,
      dataIndex: "isCurrent",
      key: "isCurrent",
      render: (text, record) => {
        return <span>{record?.isCurrent ? lang.yes : lang.no}</span>;
      },
    },
    {
      title: lang.edit,
      key: "edit",
      render: (text, record) => (
        <span className="cursor-pointer" disabled={!canEdit} onClick={() => onEditVehicle(record)}>
          <EditOutlined type="DeleteOutlined" className="editIconColor" />
        </span>
      ),
    },
    {
      title: lang.delete,
      width: "85",
      key: "delbutton",
      render: (text, record, index) => (
        <span
          className="cursor-pointer"
          disabled={!canDelete}
          onClick={e => {
            e.stopPropagation();
            onDeleteVehicle(record?.id, index);
          }}
        >
          <AntdIconV4 type="DeleteOutlined" className="deleteBtnColor" />
        </span>
      ),
    },
  ];
  if (window.innerWidth <= 1024) {
    columns = [
      {
        width: "100%",
        key: "edit",
        render: (text, record, index) => {
          let voi = "";
          voi = `${voi + record?.make} `;
          voi = `${voi + record?.model} `;
          voi = `${voi + record?.year} `;

          return (
            <CardWrapper
              title={
                <ContainerNavigationWrap>
                  <div className="voiContainer contents whitespace-normal">{voi}</div>
                </ContainerNavigationWrap>
              }
              actions={[
                <div key={index}>
                  <AntdIconV4
                    className="labelColor"
                    type="EditOutlined"
                    onClick={() => onEditVehicle(record)}
                  />
                </div>,
                <div key={index}>
                  <DeleteOutlined
                    onClick={e => {
                      e.stopPropagation();
                      onDeleteVehicle(record?.id);
                    }}
                  />
                </div>,
              ]}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <CustomLabel labelClass="font-medium" label={`${lang.make}:`} />
                  {record?.make || ""}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <CustomLabel labelClass="font-medium" label={`${lang.model}:`} />
                  {record?.model || ""}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  <CustomLabel labelClass="font-medium" label={`${lang.year}:`} />
                  {record?.year || ""}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <CustomLabel labelClass="font-medium" label={`${lang.previousOrCurrent}:`} />
                  {record?.isCurrent ? lang.yes : lang.no}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <CustomLabel labelClass="font-medium" label={`${lang.isPrimaryVehicle}:`} />
                  {record?.isPrimary ? lang.yes : lang.no}
                </Col>
              </Row>
              <style jsx>{`
                .voiContainer {
                  wordwrap: break-word;
                  wordbreak: break-word;
                  width: 100%;
                  @media (max-width: 992px) {
                    width: 50px !important;
                  }
                }
              `}</style>
            </CardWrapper>
          );
        },
      },
    ];
  }

  return <CustomTable data={vehiclesList} rowKey="id" pagination={false} columns={columns} />;
};
