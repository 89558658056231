/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable no-use-before-define */
/* eslint-disable no-debugger */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/destructuring-assignment */
import Icon from "@ant-design/icons";
import { List, Tag, Tooltip } from "antd";
import { isEmpty, isUndefined, uniqBy } from "lodash";
import React, { useState } from "react";
import { formatPhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import styled from "styled-components";
import CloseIconBlue from "../../../../static/icons/CloseIconBlue.svg";
import { TagColored } from "../../../library/basicComponents";
import { colorConfig } from "../../../library/colorConfig";
import { Colors } from "../../../library/constants";
import AntdIconV4 from "../../../utils/antdIcon";

export const FilterTag = styled(TagColored)`
  background-color: ${props => (props.disabled ? Colors.lightGray : "none")};
  cursor: ${props => (props.disabled ? "not-allowed" : "default")} !important;
`;

/* eslint-disable no-else-return */
export const ConsentStatus = (consent, status) => {
  return `${
    consent === "ACCEPTED"
      ? "Opted-In"
      : consent === "PENDING" && status
      ? "Pending Consent"
      : consent === "PENDING" && !status
      ? "Implied Consent"
      : consent === "DECLINED"
      ? "Opted-Out"
      : "Send Consent"
  }`;
};

export const isConsentStatusEnable = (consent, status) => {
  return consent === "ACCEPTED" || (consent === "PENDING" && !status);
};

export const SetContentBy = (item, userIdMap) => {
  // const user = item?.systemUserId
  //   ? userIdMap && userIdMap[item?.systemUserId]
  //   : userIdMap && userIdMap[item?.userId];
  return item?.content || "";
};

export const getLeadStatus = (user, status, msg, spinner = false) => {
  const ellipsisStatus = status.length > 15 ? `${status.substring(0, 12)}...` : status;
  if (spinner) {
    return <Tag color="#2db7f5" />;
  }
  if (status === "ACTIVE" || status === "Open" || status.includes("ACTIVE")) {
    return user?.company?.crmIntegrationType === "SF" ? "Open" : ellipsisStatus;
    // : status.includes("ACTIVE")
    // ? "ACTIVE"
  } else if (status === "SOLD" || status === "Converted" || status.includes("SOLD")) {
    return user?.company?.crmIntegrationType === "SF" ? "Converted" : ellipsisStatus;
    // : status.includes("SOLD")
    // ? "SOLD"
  } else if (status.includes("Contacted")) {
    return (
      <Tooltip placement="topLeft" title={msg || status}>
        <Tag color="#87d068">{ellipsisStatus.replace(/_/g, " ")}</Tag>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip placement="topLeft" title={(msg || status).replace(/_/g, " ")}>
        <Tag color="#ff4500">{ellipsisStatus.replace(/_/g, " ")}</Tag>
      </Tooltip>
    );
  }
};

export const phoneIconColor = record => {
  const phoneObj = !isUndefined(
    record?.phoneNumbers && record?.phoneNumbers.length > 0 && record?.phoneNumbers[0]
  )
    ? record?.phoneNumbers[0]
    : {};
  const phoneType = phoneObj?.lookupType || "";
  if (["Invalid", "None"].includes(phoneType)) {
    return "red";
  }
  return colorConfig.secondaryDark;
};

export const displayPhone = (record, crmIntegration) => {
  const phoneObj =
    record?.phoneNumbers.find(
      item => (item?.phoneType || item?.lookupType)?.toLowerCase() === "mobile"
    ) ||
    record?.phoneNumbers?.find(item => item?.phoneType?.toLowerCase() === "work") ||
    record?.phoneNumbers?.find(item => item?.phoneType?.toLowerCase() === "home") ||
    record?.phoneNumbers?.find(
      item => item?.phoneType?.toLowerCase() === (crmIntegration === "DS" ? "other" : "mobile")
    );
  const phoneType = phoneObj?.lookupType || "";
  const phone = phoneObj?.phone || "";
  if (!isEmpty(phone) && phone != null) {
    if (["Invalid", "None"].includes(phoneType)) {
      return <Tooltip title="Invalid phone number">{phone}</Tooltip>;
    } else if (isValidPhoneNumber(phone)) {
      return isValidPhoneNumber(phone) && formatPhoneNumber(phone);
    }
  }
  return "N/A";
};

export const DealStatusIcon = ({ status, msg }) => {
  switch (status) {
    case "ACTIVE":
      return (
        <Tooltip placement="topLeft" title={msg}>
          <Tag className="m-0 p-0" color="green">
            Active
          </Tag>
        </Tooltip>
      );
    case "Open":
      return (
        <Tooltip placement="topLeft" title={msg}>
          <Tag className="m-0 p-0" color="blue">
            Open
          </Tag>
        </Tooltip>
      );
    case "SOLD":
      return (
        <Tooltip placement="topLeft" title={msg}>
          <Tag className="m-0 p-0" color="green">
            Lead Sold
          </Tag>
        </Tooltip>
      );
    case "Converted":
      return (
        <Tooltip placement="topLeft" title={msg}>
          <Tag className="m-0 p-0" color="green">
            Converted
          </Tag>
        </Tooltip>
      );
    case "ARCHIVED":
      return (
        <Tooltip placement="topLeft" title={msg}>
          Archived
        </Tooltip>
      );
    case "LOST":
      return (
        <Tooltip placement="topLeft" title={msg}>
          <Tag className="m-0 p-0" color="red">
            Lead Lost
          </Tag>
        </Tooltip>
      );
    default:
      return status;
  }
};

export const LeadStatus = (user, status, spinner = false) => {
  if (spinner) {
    return <Tag color="#2db7f5" />;
  }
  if ((status || "").toUpperCase().includes("ACTIVE") || status === "Open") {
    return (
      <>
        {" "}
        <Tag color="#2db7f5">
          {user?.company?.crmIntegrationType === "SF"
            ? "Open"
            : (status || "").includes("ACTIVE")
            ? "Active"
            : status}
        </Tag>
      </>
    );
  } else if ((status || "").toUpperCase() === "LOST") {
    return <Tag color="#cd201f">{status}</Tag>;
  } else if ((status || "").toUpperCase().includes("SOLD") || status === "Converted") {
    return (
      <>
        {" "}
        <Tag color="#87d068">
          {user?.company?.crmIntegrationType === "SF"
            ? "Converted"
            : (status || "").includes("SOLD")
            ? "Sold"
            : status}
        </Tag>
      </>
    );
  } else if ((status || "").includes("Contacted")) {
    return (
      <>
        {" "}
        <Tag color="#87d068">{status}</Tag>
      </>
    );
  } else if ((status || "").includes("Duplicate")) {
    return <Tag color="#cd201f"> {status}</Tag>;
  } else {
    return (
      <>
        {" "}
        <Tag color="#ff4500">{status}</Tag>
      </>
    );
  }
};

export const handleOwnerCRM = leadUsers => {
  const ownerArray =
    leadUsers?.length > 0 &&
    leadUsers.map(user => {
      if (user?.userType?.name) {
        return {
          label: `${user?.userType?.name}`,
          username: `${user?.user?.firstName} ${user?.user?.lastName} ${
            user?.user?.companyRole?.name ? `(${user?.user?.companyRole?.name})` : ""
          }`,
        };
      } else {
        return {
          label: "",
          username: `${user?.user?.firstName} ${user?.user?.lastName} ${
            user?.user?.companyRole?.name ? `(${user?.user?.companyRole?.name})` : ""
          }`,
        };
      }
    });
  const leadOwnerList =
    ownerArray?.length > 0 &&
    ownerArray.map((owner, index) => (
      <div key={index}>
        <p style={{ marginBottom: "2px" }}>
          <strong style={{ fontSize: "13px" }}>{owner?.label ? `${owner?.label}: ` : ""}</strong>
          <Tag style={{ marginBottom: 5 }} color="green">
            <span>{owner?.username}</span>
          </Tag>
        </p>
      </div>
    ));

  return leadOwnerList;
};

export const leadOwners = (owners, crmIntegration = "") => {
  if (crmIntegration === "DS" || crmIntegration === "SF") {
    return handleOwnerCRM(owners);
  } else {
    let list = [];
    if (owners.length > 0) {
      list = owners.map((el, i) => {
        const fullName = `${el?.user?.firstName} ${el?.user?.lastName} ${
          el?.user?.companyRole?.name ? `(${el?.user?.companyRole?.name})` : ""
        }`;
        return (
          <Tooltip key={i} placement="topLeft" title={i === 0 ? "Primary Owner" : ""}>
            <Tag style={{ marginBottom: 5 }} color={i === 0 ? "purple" : "geekblue"}>
              {fullName}
            </Tag>
          </Tooltip>
        );
      });
    }
    return list;
  }
};

export const vehicles = lead => {
  let list = [];
  const vehiclesOfInterest = uniqBy(lead?.leadVehicleInventory || [], v =>
    [v.year, v.make, v.model].join()
  );
  if (vehiclesOfInterest.length > 0) {
    list = vehiclesOfInterest.map(el => {
      let voi = "";
      if (!isUndefined(el.make) && el.make !== null && el.make !== undefined && el.make !== 0)
        voi = `${voi} ${el.make} `;
      if (!isUndefined(el.model) && el.model !== null && el.model !== undefined && el.model !== 0)
        voi = `${voi} ${el.model} `;
      if (
        !isUndefined(el.year) &&
        +el.year !== 0 &&
        el.year !== null &&
        el.year !== undefined &&
        +el.year !== 0
      )
        voi = `${voi} ${el.year} `;

      return voi === "" || voi === 0 ? (
        <span>No Vehicles</span>
      ) : (
        <Tooltip
          placement="topLeft"
          title={el?.isCurrent ? "Previous/Current Vehicle" : "Vehicle of Interest"}
        >
          <Tag color={el?.isCurrent ? "magenta" : "blue"}>{voi}</Tag>
        </Tooltip>
      );
    });
  }
  const vehicleOfInterest = uniqBy(lead?.vehicleOfInterest || [], v =>
    [v.year, v.make, v.model].join()
  );
  if (vehicleOfInterest.length > 0) {
    vehicleOfInterest.map(el => {
      let voi = "";
      if (
        !isUndefined(el.year) &&
        +el.year !== 0 &&
        el.year !== null &&
        el.year !== undefined &&
        +el.year !== 0 &&
        el.year !== ""
      )
        voi = `${voi} ${el.year} `;
      if (
        !isUndefined(el.make) &&
        el.make !== null &&
        el.make !== undefined &&
        el.make !== 0 &&
        el.make !== ""
      )
        voi = `${voi} ${el.make} `;
      if (
        !isUndefined(el.model) &&
        el.model !== null &&
        el.model !== undefined &&
        el.model !== 0 &&
        el.model !== ""
      )
        voi = `${voi} ${el.model} `;
      {
        voi !== "" && voi !== 0
          ? list.push(
              <Tooltip
                placement="topLeft"
                title={el?.isCurrent ? "Previous/Current Vehicle" : "Vehicle of Interest"}
              >
                <Tag className="break-words" color={el?.isCurrent ? "magenta" : "blue"}>
                  {voi}
                </Tag>
              </Tooltip>
            )
          : list.push(<span>No Vehicles</span>);
      }
    });
  }
  return list;
};

export const ConsentColor = (consent, status) => {
  const color = `${
    consent === "ACCEPTED"
      ? "green"
      : consent === "PENDING" && status
      ? "orange"
      : consent === "PENDING" && !status
      ? "green"
      : consent === "DECLINED"
      ? "red"
      : colorConfig.primaryDark
  }`;
  return color;
};

export const ConsentIconType = (consent, status) => {
  return `${
    consent === "ACCEPTED"
      ? "CheckCircleOutlined"
      : consent === "PENDING" && status
      ? "ClockCircleOutlined"
      : consent === "PENDING" && !status
      ? "CheckCircleOutlined"
      : consent === "DECLINED"
      ? "CloseCircleOutlined"
      : "ExclamationCircleOutlined"
  }`;
};

export const Filter = ({ filter, remove }) => (
  <FilterTag>
    {filter.type === "Template" ? JSON.parse(filter.value).value : filter.value}
    <Icon className="ml-1" component={CloseIconBlue} onClick={() => remove(filter)} />
  </FilterTag>
);

export const ConsentStatusColor = (consent, status) => {
  return `${
    consent === "ACCEPTED"
      ? "#52c41a"
      : consent === "PENDING" && status
      ? "#ffbf00"
      : consent === "PENDING" && !status
      ? "#52c41a"
      : consent === "DECLINED"
      ? "#FC5A5A"
      : "#EEEEF1"
  }`;
};

// eslint-disable-next-line default-param-last
export const ShowConsent = (selectedLead, user, isFromChatbot = false, queryString) => {
  let filterNumber = "";
  if (queryString) {
    [filterNumber] = (selectedLead?.phoneNumbers || [])?.filter(
      phone => phone?.phone === `+1${queryString}`
    );
  }
  return (
    <Tooltip title={ConsentStatus(selectedLead?.textConsentStatus)}>
      <span style={{ display: "flex" }} className="justify-start">
        <span
          style={{ marginLeft: isFromChatbot ? "0px" : "", fontSize: isFromChatbot ? "12px" : "" }}
        >
          {formatPhoneNumber(
            filterNumber?.phone
              ? filterNumber?.phone
              : selectedLead?.phoneNumbers
              ? selectedLead?.phoneNumbers[0]?.phone
              : "N/A"
          )}
        </span>
        <AntdIconV4
          type={ConsentIconType(
            selectedLead?.textConsentStatus,
            user?.company?.isOptinConsentMethod
          )}
          style={{
            color: ConsentStatusColor(selectedLead?.textConsentStatus),
            fontSize: isFromChatbot ? 15 : 20,
            marginLeft: 5,
            marginRight: "5px",
          }}
        />{" "}
      </span>
    </Tooltip>
  );
};

export const CustomCalendar = styled.div`
  width: 45px;
  height: 45px;
  position: relative;
  background-color: ${props =>
    props.status === "error"
      ? colorConfig.cancelAppointement
      : props.status === "success"
      ? colorConfig.successAppointement
      : props.status === "default"
      ? "#0EA5E9"
      : props.status === "noshow"
      ? colorConfig.noShowAppointment
      : props.status === "show"
      ? colorConfig.primaryDark
      : colorConfig.schedualStatusAppointment};
  border-radius: 4px;
  color: ${colorConfig.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top: 10px;
`;

export const CalMonth = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  background-color: #007dae;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 15px;
  border-radius: 0 0 12px 12px;
  color: #fff;
  font-size: 13px;
`;

export const CalDate = styled.div`
  position: relative;
  bottom: 5px;
  span {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const ListItemWrap = styled(List)`
  border-left-style: solid;
  border-left-color: ${props => (props.stripcolor ? props.stripcolor : "#007dae")};
  border-left-width: 0.3rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
`;

export const displayPhoneNumbers = phones => {
  const phoneNumbers = [];
  for (let i = 0; i < phones.length; i++) {
    const phoneObj = !isUndefined(phones[i]) ? phones[i] : {};
    const phoneType = phoneObj?.lookupType || "";
    const phone = phoneObj?.phone || "";
    if (!isEmpty(phone) && phone != null) {
      if (["Invalid", "None"].includes(phoneType)) {
        phoneNumbers.push(phone);
      } else if (isValidPhoneNumber(phone)) {
        phoneNumbers.push(formatPhoneNumber(phone));
      }
    }
  }
  const phoneStr = phoneNumbers.join(", ");
  return phoneStr;
};

const urlRegex = /(https?:\/\/[^\s]+)/g;

/**
 *
 * @param {text} text Content from which the links will be parsed
 * @param {*} color Link color, defaults to white
 * @returns JSX, to render the text with links inline
 */
export function renderLinks(text, color = "white") {
  const urls = text?.match(urlRegex);
  if (!urls) {
    return text; // No URLs found, return original text
  }

  const parts = text.split(urlRegex); // Split text by URLs
  const renderedText = parts.map((part, index) => {
    if (urls.includes(part)) {
      const link = part.endsWith(".") ? part.slice(0, -1) : part;
      return (
        <a
          href={link}
          style={{ color: color }}
          key={index}
          className=" hover:text-blue-200 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {part}
        </a>
      );
    }
    return part;
  });
  return renderedText;
}

export const TruncatedContent = ({ content, color = "white" }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const maxCount = 40;
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const RenderContent = () => {
    const truncatedContent = content.split(" ").slice(0, maxCount).join(" ");
    const displayContent = showFullContent
      ? content
      : `${truncatedContent} ${content.length > maxCount ? "\n..." : ""}`;

    const contentWithLinks = displayContent;

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: contentWithLinks }} />
        {content.length > maxCount && (
          <span className="cursor-pointer text-blue-500" onClick={toggleContent}>
            {showFullContent ? "Show Less" : "Show More"}
          </span>
        )}
      </div>
    );
  };

  return <RenderContent />;
};

export const eleadsLinkGenerator = leadFullName => {
  return `https://www.eleadcrm.com/evo2/fresh/elead-v45/elead_track/search/searchresults.asp?Go=2&searchexternal=&corpStore=false&st=0&lIUID=&etitle=&lDID=&PID=&origStrDo=&qs=true&q=${leadFullName.replace(
    / /g,
    "+"
  )}#`;
};
