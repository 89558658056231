/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
import { Image, Tooltip } from "antd";
import { sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import AlertTriangleIcon from "../../../icons/js/AlertTriangleIcon";
import { formatBytes } from "../../../library/utils";
import AntdIconV4 from "../../../utils/antdIcon";
import { ViewMediaPopup } from "./mediaSharing/ViewMediaPopup";
import { ModalStyled } from "./mediaSharing/multipleMediaViewer";

const Attachments = ({ attachments, setAttachments }) => {
  const [activekey, setactivekey] = useState(1);
  const [pdfModal, setpdfModal] = useState({
    visible: false,
    pdfUrl: "",
    mediaType: "",
  });
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const handleDeleteAttachment = index => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };
  const handleImageModalClose = () => {
    setCurrentImage({});
    setImageModalVisible(false);
  };
  const handleImageClick = image => {
    setCurrentImage(image);
    setImageModalVisible(true);
  };
  useEffect(() => {
    setactivekey(1);
  }, [attachments]);
  const totalSize = sumBy(
    attachments.map(item => item.file),
    "size"
  );
  return (
    <div>
      {attachments.length > 0 && (
        <div className="flex flex-row items-center gap-2.5">
          <Image.PreviewGroup>
            {attachments.map((attachment, index) => {
              const size = formatBytes(attachment.file.size);
              return (
                <div className="flex flex-col w-fit" key={index}>
                  <div className="inline-block w-fit relative rounded overflow-hidden">
                    {attachment.file.type.includes("image") ? (
                      <Image
                        className="aspect-video"
                        src={attachment.preview}
                        width="80px"
                        height="80px"
                      />
                    ) : attachment.file.type.includes("video") ? (
                      <video
                        style={{
                          width: "120px",
                          height: "80px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#F0F0F0",
                          cursor: "pointer",
                        }}
                        onClick={() => handleImageClick(attachment)}
                      >
                        <source src={attachment.preview} type="video/mp4" />
                      </video>
                    ) : (
                      <span
                        style={{
                          width: "240px",
                          height: "80px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#F0F0F0",
                        }}
                        className="p-5 flex-wrap cursor-pointer"
                        onClick={() =>
                          setpdfModal({
                            visible: true,
                            pdfUrl: attachment.preview,
                            mediaType: attachment.file.type,
                          })
                        }
                      >
                        {attachment.file.name}
                      </span>
                    )}
                    <button
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        zIndex: "1",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteAttachment(index)}
                    >
                      X
                    </button>
                  </div>
                  <span className="text-sm flex flex-row items-center gap-1 justify-center font-[500]">
                    {size}
                  </span>
                </div>
              );
            })}
          </Image.PreviewGroup>
        </div>
      )}
      <span className="my-1 flex flex-row items-center gap-1 ">
        Total Size: {formatBytes(totalSize, 2)}
        {totalSize > 5 * 1024 * 1024 && (
          <Tooltip title="Media file(s) exceeds the file size limit, the file(s) will be sent in form of link!">
            <AlertTriangleIcon className="text-red-500" />
          </Tooltip>
        )}
      </span>
      {imageModalVisible && (
        <ModalStyled
          width="97.5%"
          style={{ top: "20px" }}
          open={imageModalVisible}
          closeIcon={<AntdIconV4 type="CloseOutlined" className="text-white" />}
          onCancel={handleImageModalClose}
          footer={null}
          title="View Video"
        >
          <video width="100%" height="100%" controls>
            <source src={currentImage.preview} type={currentImage.file.type} />
          </video>
        </ModalStyled>
      )}
      {pdfModal.visible && <ViewMediaPopup pdfModal={pdfModal} setpdfModal={setpdfModal} />}
    </div>
  );
};

export default Attachments;
