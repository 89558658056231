import { useQuery } from "@apollo/client";
import { Col, Row, Select, Skeleton } from "antd";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { UserContext } from "../../../amplify/authenticator-provider";
import { GET_CRM_OWNER_DROPDOWN } from "../../../graphql/query";
import UserSelector from "../../commonComponents/userSelector";

const SelectWrap = styled(Select)`
  width: 100%;
  .ant-select-selection__placeholder {
    display: ${props => (props?.dropdownValue ? "none !important" : "block !important")};
  }
`;

const EditOwnerDialog = props => {
  const {
    isCrmOppr,
    crmIntegration,
    isLeadModel = false,
    fetchLoading = false,
    getCrmOwnerData = () => {},
    lead,
  } = props;
  const { user } = useContext(UserContext);
  const crmOpporType = !isCrmOppr || false;
  const [crmDropdownData, setCrmDropdownData] = useState([]);
  const [crmDropDown, setCRMdropdown] = useState([]);
  const [sfOwner, setSfOwner] = useState([]);
  const [colSpan, setColSpan] = useState(8);

  const { data: crmDropdown, loading: userTypesLoader } = useQuery(GET_CRM_OWNER_DROPDOWN, {
    variables: {
      companyId: user?.company?.id,
    },
    onCompleted: res => {
      const dropdownList = res?.getUserTypes;
      setCrmDropdownData(dropdownList);
    },
  });
  const handleDropdownValue = dropdownId => {
    if (lead) {
      const [ownerId] =
        lead &&
        lead?.leadUsers &&
        lead?.leadUsers?.length > 0 &&
        lead?.leadUsers?.filter(owner => +owner?.userType?.id === +dropdownId);
      return ownerId?.user?.id;
    }
  };

  const handleCRMdropdown = () => {
    const { leadUsers } = lead;
    let dropdownList = crmDropdownData.map(dropdown => {
      const selectedUser = leadUsers.find(item => +item.userType.id === +dropdown.id);
      return {
        label: dropdown?.name,
        placeholder: `Select ${dropdown?.name}`,
        required: true,
        isMultipleMode: false,
        value: handleDropdownValue(dropdown?.id) || "",
        keyName: dropdown?.name,
        id: dropdown?.id,
        opportunityTypeId: dropdown?.opportunityTypeId,
        selectedUser: isEmpty(selectedUser) ? {} : selectedUser.user,
      };
    });

    if (crmOpporType && crmIntegration !== "DS") {
      dropdownList = dropdownList.filter(dropdown => {
        if (dropdown.label !== "Salesperson 2" && dropdown.label !== "Opportunity Owner") {
          return dropdown;
        }
      });
    }

    if (crmIntegration === "SF" && !crmOpporType) {
      dropdownList = dropdownList.filter(dropdown => {
        if (dropdown.label !== "Lead Owner") {
          return dropdown;
        }
      });
    }

    if (crmIntegration === "SF") {
      dropdownList.unshift(dropdownList.pop());
    }
    setCRMdropdown([...dropdownList]);
    setColSpan(isLeadModel ? 24 / dropdownList?.length : 24);
  };
  useEffect(() => {
    (crmDropdownData || []).length > 0 && handleCRMdropdown();
  }, [crmDropdownData]);

  useEffect(() => {
    getCrmOwnerData(crmDropDown, sfOwner);
  }, [sfOwner, crmDropDown]);

  return (
    <div className="relative">
      <Skeleton active avatar loading={userTypesLoader}>
        <Row gutter={[4, 16]} className="mt-[10px]">
          {crmDropDown?.length > 0 &&
            crmDropDown.map(field => {
              return (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: colSpan }}
                  xl={{ span: colSpan }}
                  lg={{ span: colSpan }}
                  key={field.label}
                  id="antdOwnerDropdown"
                >
                  <label className="mb-1">
                    {field.label}
                    {field?.required && <span className="requireFieldStar ml-[2px]">*</span>}
                  </label>
                  <UserSelector
                    item={{ item: { user: field.selectedUser } }}
                    // name={`leadOpportunityUsers-${item.id}`}
                    placeholder={+field?.placeholder}
                    handleUsersSelection={value => {
                      setSfOwner([...sfOwner, { userId: value, userTypeId: field?.id }]);
                      field.value = value;
                    }}
                    value={isEmpty(field.value) ? null : +field.value}
                    allowClear
                    multiple={false}
                    showAll={false}
                    disabled={false}
                  />
                </Col>
              );
            })}
        </Row>
      </Skeleton>
    </div>
  );
};

export default EditOwnerDialog;
