import { useMutation } from "@apollo/client";
import { message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  CREATE_GENERIC_FILTER,
  DELETE_GENERIC_FILTER,
  UPDATE_GENERIC_FILTER,
} from "../../../graphql/mutation";
import useLang from "../../../hooks/useLang";
import { closeDialog, openDialog } from "../../../library/helpers";
import TextInput from "../../../library/textInput";
import { getGenericFilterId } from "../../../library/utils";
import { setLoaderStatus } from "../../../redux/slices/loaderSlice";
import AntdIconV4 from "../../../utils/antdIcon";
import CustomButton from "../../commonComponents/button";

const LeadPresetFilter = props => {
  const {
    userFilters,
    presetFilters,
    refetchFilters,
    setPresetFilters,
    tableMode,
    genericFilterTypes,
  } = props;
  const [lang] = useLang();

  const dispatch = useDispatch();

  const [createFilterName, setCreateFilterName] = useState("");
  const [localPresetFilter, setLocalPresetFilter] = useState([...presetFilters]);

  const [createGenericFilter, { loading: createFilterLoading }] =
    useMutation(CREATE_GENERIC_FILTER);

  const [updateGenericFilter] = useMutation(UPDATE_GENERIC_FILTER);

  const [deleteGenericFilter] = useMutation(DELETE_GENERIC_FILTER);

  const handleFilterUpdate = async (filterId, isFilterNameOnly = false) => {
    try {
      const updatedFilter = localPresetFilter.find(preset => preset.id === filterId);
      dispatch(setLoaderStatus({ open: true }));
      const resp = await updateGenericFilter({
        variables: {
          filterName: updatedFilter.filterName,
          id: updatedFilter.id,
          filterJson: isFilterNameOnly
            ? updatedFilter.genericFilterJson.filterJson
            : JSON.stringify(userFilters),
          isCurrent: updatedFilter.genericFilterJsonterJsonterJson.isCurrent,
        },
      });

      const res = await refetchFilters({
        variables: {
          genericFilterTypeId: getGenericFilterId(genericFilterTypes, tableMode),
        },
      });
      setPresetFilters(res?.data?.getUserLeadFilters);

      message.success(resp.data.updateGenericFilter.message);
    } catch (err) {
      console.log("Error in handleUpdateFilter", err);
      message.error(lang.somethingWentWrong);
    } finally {
      dispatch(setLoaderStatus({ open: false }));
      closeDialog("confirmDialog");
    }
  };

  const handleDeleteFilter = filterId => {
    openDialog("confirmDialog", {
      dialogHeader: lang.deletePresetFilter,
      confirmBtnText: lang.delete,
      okBtnType: true,
      isOKGhost: true,
      handleCancelBtn: () => closeDialog("confirmDialog"),
      handleConfirm: async () => {
        try {
          dispatch(setLoaderStatus({ open: true }));
          const resp = await deleteGenericFilter({
            variables: {
              id: filterId,
            },
          });
          message.success(resp.data.deleteGenericFilter.message);
          const filters = await refetchFilters({
            variables: {
              genericFilterTypeId: getGenericFilterId(genericFilterTypes, tableMode),
            },
          });
          setLocalPresetFilter([...filters.data.getUserLeadFilters]);
        } catch (err) {
          console.log("Error in handleDeleteFilter", err);
          message.error(lang.somethingWentWrong);
        } finally {
          dispatch(setLoaderStatus({ open: false }));
          closeDialog("confirmDialog");
        }
      },
    });
  };

  const handleUpdateFilter = filterId => {
    openDialog("confirmDialog", {
      dialogHeader: lang.updatePresetFilter,
      confirmBtnText: lang.update,
      handleCancelBtn: () => closeDialog("confirmDialog"),
      content: lang.updateFilterInfo,
      ExtraComponent: (
        <CustomButton
          tooltipTitle={lang.updatePresetFilterName}
          type="primary"
          className="bg-yellow-500 text-black border-none"
          btnText={lang.updatePresetFilterName}
          ghost
          handleClick={() => handleFilterUpdate(filterId, true)}
        />
      ),
      handleConfirm: async () => {
        handleFilterUpdate(filterId);
      },
    });
  };
  const handleCreateFilter = async () => {
    if (!createFilterName) {
      message.error(lang.createFilterError);
      return 0;
    }
    try {
      const res = await createGenericFilter({
        variables: {
          filterJson: JSON.stringify(userFilters),
          filterName: createFilterName,
          isCurrent: true,
          genericFilterTypeId: getGenericFilterId(genericFilterTypes, tableMode),
        },
      });
      if (res?.data?.createGenericFilter?.statusCode === 200) {
        message.success(res?.data?.createGenericFilter?.message);
        refetchFilters({
          variables: {
            genericFilterTypeId: getGenericFilterId(genericFilterTypes, tableMode),
          },
        });
        closeDialog("leadPresetFilters");
      } else {
        message.error(res?.data?.createGenericFilter?.message);
      }

      // create opportunity preset filter
    } catch (err) {
      console.log("Error in handleCreateFilter", err);
      message.error(lang.somethingWentWrong);
    }
  };

  const handleFilterpdate = (e, filterId) => {
    const presetFilters = [...localPresetFilter];
    const updatedFilterIndex = localPresetFilter.findIndex(preset => preset.id === filterId);
    presetFilters[updatedFilterIndex].filterName = e.target.value;
    setLocalPresetFilter([...presetFilters]);
    console.log("updatedFilterIndex", updatedFilterIndex);
  };

  return (
    <div>
      <div className="flex w-full items-end gap-2 mb-2">
        <TextInput
          containerClass="flex-auto"
          label={lang.filterName}
          value={createFilterName}
          onChange={e => setCreateFilterName(e.target.value)}
          require
          placeholder={lang.enterPresetFilterName}
        />
        <CustomButton
          type="primary"
          handleClick={handleCreateFilter}
          btnText={lang.createFilter}
          loading={createFilterLoading}
        />
      </div>
      {localPresetFilter.map(preset => (
        <div className="flex justify-between py-2 items-end gap-3" key={preset.id}>
          <TextInput
            containerClass="flex-auto"
            value={preset.filterName}
            onChange={e => handleFilterpdate(e, preset.id)}
            require
            placeholder={lang.enterPresetFilterName}
          />
          <div className="gap-2 flex items-center">
            <CustomButton
              tooltipTitle={lang.updateFilterPreset}
              type="primary"
              ghost
              handleClick={() => handleUpdateFilter(preset.id)}
              icon={<AntdIconV4 type="EditOutlined" />}
            />
            <CustomButton
              type="primary"
              tooltipTitle={lang.delete}
              ghost
              danger
              handleClick={() => handleDeleteFilter(preset.id)}
              icon={<AntdIconV4 type="DeleteOutlined" />}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default LeadPresetFilter;
