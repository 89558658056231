import React from "react";
import { useQuery } from "@apollo/client";
import useLang from "../../../hooks/useLang";
import { CampaignInfo, SourcesTags, StatusTags } from "./tags";
import { GET_LEAD_SOURCES } from "../../../graphql/query";

const viewSourcesModal = props => {
  const [lang] = useLang();
  const { campaignDetails, keyValue } = props;
  const { data: sourcesData } = useQuery(GET_LEAD_SOURCES);
  return (
    <CampaignInfo
      //   label={`${lang.sources}: `}
      value={
        // (campaignDetails?.campaignSelections || []).filter(el => el.type === "SOURCE").length >
        // 0 ? (
        <SourcesTags
          data={(campaignDetails?.campaignSelections || []).filter(el => el.type === keyValue)}
          sources={sourcesData?.leadSources || []}
        />
        // )
        // : (
        //   <Tag key="key_all">{lang.all}</Tag>
        // )
      }
    />
  );
};

export default viewSourcesModal;
