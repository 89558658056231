import React, { useState, useEffect } from "react";
import { Input } from "antd";

const EditableTextBox = ({ ckey, value, onSave, placeholder, disabled = false }) => {
  const [text, setText] = useState(value?.replace(/\s{2,}/g, " ").trim() || "");
  const [error, setError] = useState("");
  useEffect(() => {
    setText(value || "");
  }, [value]);

  const onKeyDown = event => {
    if (event?.key === "Enter" || event?.key === "Escape") {
      event.target.blur();
    }
  };
  const onBlur = event => {
    if (event.target.value.trim() === "") {
      setError("Please enter value");
    } else {
      onSave(text?.replace(/\s{2,}/g, " ").trim());
      setError("");
      setText(event.target.value?.replace(/\s{2,}/g, " ")?.trim());
    }
  };

  return (
    <div className="flex-1">
      {error && <span className="text-red-500 ml-[2px]">{error}</span>}
      <Input
        className="text-sm p-2 font-[400] text-grey-700"
        size="small"
        key={ckey}
        value={text}
        onChange={e => setText(e.target.value)}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

export default EditableTextBox;
