import { useMutation } from "@apollo/client";
import { Input, message } from "antd";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../amplify/authenticator-provider";
import { SAVE_ENAGEMENT_MESSAGE_TEMPLATE } from "../../../graphql/mutation";
import useLang from "../../../hooks/useLang";
import TextInput from "../../../library/textInput";
import CustomButton from "../../commonComponents/button";
import CustomCheckbox from "../../commonComponents/checkbox";
import CustomLabel from "../../commonComponents/label";

const CreateTemplate = props => {
  const {
    company = null,
    handleTemplateSection,
    createtemplate,
    template,
    settemplate,
    setIsTemplateInEditMode,
    isTemplateInEditMode = false,
  } = props;
  const [lang] = useLang();
  const htmlElRef = useRef(null);

  const { user } = useContext(UserContext);
  const [title, setTitle] = useState(template.title || "");
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(
    user && user.role ? user.role.isCompanyAdmin : company == null ? true : user.role == null
  );
  const [share, setShare] = useState({
    company: isTemplateInEditMode ? template.isCompanyShared : false,
    location: false,
  });
  const [messageTemplate, setMessage] = useState(template.message || "");
  useEffect(() => {
    setShare({ location: !isEmpty(template.location), company: template.isCompanyShared });
  }, [template]);

  const [saveEngagementMessageTemplate, { loading }] = useMutation(
    SAVE_ENAGEMENT_MESSAGE_TEMPLATE,
    {
      variables: {
        userId: parseInt(user.id),
        id: template.id || null,
        companyId: parseInt(company?.id || null),
        message: messageTemplate,
        title: title,
        isCompanyShared: share.company,
        ...(share.location && { locationId: +sessionStorage.getItem("currentLocationId") }),
      },
      onCompleted: res => {
        if (res.saveEngagementMessageTemplate.statusCode === 200) {
          message.success(lang.templateCreatedSucFully);
          setTitle("");
          setMessage("");
          handleTemplateSection(false);
        } else {
          message.error(lang.templateCreationFailed);
        }
      },
    }
  );

  const snippetList = [
    {
      snippetLabel: "{firstName}",
      snippetId: "{firstName}",
    },
    {
      snippetLabel: "{lastName}",
      snippetId: "{lastName}",
    },
    {
      snippetLabel: "{fullName}",
      snippetId: "{fullName}",
    },
    {
      snippetLabel: "{sourceName}",
      snippetId: "{sourceName}",
    },
    {
      snippetLabel: "{userName}",
      snippetId: "{userName}",
    },
    {
      snippetLabel: "{companyName}",
      snippetId: "{companyName}",
    },
    {
      snippetLabel: "{locationName}",
      snippetId: "{locationName}",
    },
    {
      snippetLabel: "{previousVehicle}",
      snippetId: "{previousVehicle}",
    },
    {
      snippetLabel: "{createAppointmentUrl}",
      snippetId: "{createAppointmentUrl}",
    },
    {
      snippetLabel: "{vehicleOfInterest}",
      snippetId: "{vehicleOfInterest}",
    },
    {
      snippetLabel: "{locationAddress}",
      snippetId: "{locationAddress}",
    },
    {
      snippetLabel: "{locationUrl}",
      snippetId: "{locationUrl}",
    },
  ];

  const handleAddKeyword = snippet => {
    const ctl = document.getElementById("Test");
    const startPos = ctl.selectionStart;

    const output = [
      messageTemplate.slice(0, startPos),
      `${snippet} `,
      messageTemplate.slice(startPos),
    ].join("");

    setMessage(output);
    if (htmlElRef.current) {
      htmlElRef.current.focus();
    }
  };

  const handleAddClick = () => {
    if (title === "") {
      return message.error(lang.enterTemplateTitle);
    }
    if (messageTemplate === "") {
      return message.error(lang.enterTemplateMessage);
    }
    saveEngagementMessageTemplate();
  };

  return (
    <div className="w-full">
      <TextInput
        placeholder={lang.messageTitle}
        onChange={e => setTitle(e.target.value)}
        value={title}
        label={lang.title}
        require
      />
      <div className="flex gap-2 mt-5 flex-wrap">
        <CustomLabel
          label={
            <div className="my-1 font-normal">
              {lang.message} <span className="requireFieldStar">*</span>
            </div>
          }
        />
        {snippetList.map((snippet, index) => (
          <CustomButton
            key={index}
            type="link labelColor"
            className="px-0.5"
            handleClick={() => handleAddKeyword(snippet?.snippetLabel)}
            btnText={snippet?.snippetLabel}
          />
        ))}
      </div>

      <Input.TextArea
        id="Test"
        ref={htmlElRef}
        placeholder={lang.typeResponseTemplate}
        rows={3}
        onChange={e => {
          setMessage(e.target.value);
        }}
        defaultValue={messageTemplate}
        value={messageTemplate}
      />

      <CustomCheckbox
        className="my-2"
        checked={share.company}
        handleChange={e => setShare({ ...share, company: e.target.checked, location: false })}
        disabled={!isCompanyAdmin || !createtemplate}
        checkboxLabel={lang.shareWithCompany}
      />
      <CustomCheckbox
        checked={share.location}
        className="py-2"
        handleChange={e => setShare({ ...share, location: e.target.checked, company: false })}
        disabled={share.company}
        checkboxLabel="Share with Location"
      />
      <div className="w-full text-right">
        <CustomButton
          btnText={lang.save}
          type="primary"
          loading={loading}
          handleClick={handleAddClick}
        />
      </div>
    </div>
  );
};

export default CreateTemplate;
