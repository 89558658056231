import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useSelector } from "react-redux";
import { closeDialog } from "../../library/helpers";
import { getLoaderStatus } from "../../redux/slices/loaderSlice";
import CustomAlert from "../commonComponents/alert";
import DialogFooter from "../commonComponents/dialogFooter";
import CustomLabel from "../commonComponents/label";
import useLang from "../../hooks/useLang";

const validateInputs = inputs => {
  return inputs.message.trim().length > 0;
};

const AddUserResponseTemplate = props => {
  const { editableResponseTemplate, setEditableResponseTemplate, submitResponseTemplate } = props;
  const [lang] = useLang();
  const confirmLoading = useSelector(state => getLoaderStatus(state));

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmitForm = () => {
    const inputs = {
      message,
    };
    if (validateInputs(inputs)) {
      setError("");
      submitResponseTemplate(inputs, editableResponseTemplate);
    } else {
      setError(lang.pleaseEnterMsg);
    }
  };

  useEffect(() => {
    setMessage(editableResponseTemplate ? editableResponseTemplate.message : "");
  }, [editableResponseTemplate]);

  return (
    <div>
      {error && <CustomAlert className="my-2" type="error" message={error} />}

      <CustomLabel label={lang.responseMessage} labelClass="my-1 font-medium" />
      <Input.TextArea
        rows={5}
        onChange={e => setMessage(e.target.value)}
        value={message}
        placeholder={lang.enterResponseTemplateMsg}
      />
      <DialogFooter
        confirmBtnText={editableResponseTemplate ? lang.update : lang.add}
        handleConfirm={handleSubmitForm}
        loading={confirmLoading}
        handleCancel={() => {
          setEditableResponseTemplate(null);
          closeDialog("createUserResponseTemplate");
        }}
      />
    </div>
  );
};

export default AddUserResponseTemplate;
