import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, List, Select, Skeleton, Tooltip, message } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../../amplify/authenticator-provider";
import { DELETE_CAMPAIGN, UPDATE_CAMPAIGN } from "../../../graphql/mutation";
import {
  GET_ALL_CAMPAIGN_SUMMARY,
  GET_CAMPAIGN_DETAILS,
  GET_CAMPAIGN_LEAD_SUMMARY,
  GET_CAMPAIGN_MESSAGES,
  GET_CAMPAIGN_PAGINATED_LIST,
  GET_CAMPAIGN_SCHEDULES,
  GET_CRM_LEAD_STATUS,
  GET_LEAD_SOURCES,
  GET_LEAD_SOURCES_DS,
} from "../../../graphql/query";
import useLang from "../../../hooks/useLang";
import DoorIcon from "../../../icons/DoorIcon";
import EngagedAiIcon from "../../../icons/EngagedAiIcon";
import PurpleEnvalopeIcon from "../../../icons/envalope";
import HashTagicon from "../../../icons/hashtagIcon";
import {
  AccessPermissionAction,
  MODAL_CONTENT,
  MODAL_TITLE,
  ordinalNumbers,
} from "../../../library/constants";
import FilterDropdown from "../../../library/filterDropdown";
import FilterTextBox from "../../../library/filterTextbox";
import { checkAccessPermission, openDialog, showConfirmationModal } from "../../../library/helpers";
import { convertUtcToLocal, firstUpperCaseWordParser } from "../../../library/utils";
import { toSnake } from "../../../utils";
import AntdIconV4 from "../../../utils/antdIcon";
import { CrashAlert } from "../../commonComponents/crashAlert";
import CustomLabel from "../../commonComponents/label";
import CustomTable from "../../commonComponents/table";
import CustomTabs from "../../commonComponents/tabs";
import { CampaignItem } from "./CampaignItem";
import { FirstRow } from "./FirstRow";
import { SecondRow } from "./SecondRow";
import { ThirdStep } from "./ThirdStep";
import CampaignStats from "./campaignStats";

export const LEAD_NAME = "fullName";
export const LEAD_OWNER = "Owners";
export const LEAD_STATUS = "leadStatusType";
export const LEAD_SOURCE = "Source";
export const LEAD_VEHICLE = "Vehicles";

const CampaignDetails = props => {
  try {
    const {
      campaign,
      onBackClick,
      onRedirect,
      handleLeadDetailProfile,
      onCancel = () => {},
      sources,
      onCampaignBySource,
      showAllCampaign = true,
    } = props;
    const dispatch = useDispatch();
    const { filters } = useSelector(state => state.campaign);
    const { selectedPresetDate } = filters;
    const { item: campaignDetails } = campaign;
    const [selectedCampaign, setselectedCampaign] = useState({ ...campaignDetails, messages: [] });
    const [isAllCampaign, setisAllCampaign] = useState(false);
    const [lang] = useLang();
    const [campaignList, setcampaignList] = useState([]);
    const searchInputRef = useRef("");
    const [activeTab, setActiveTab] = useState("details");
    const [campaignStatsData, setCampaignStatsData] = useState([]);
    const [crmIntegration, setcrmIntegration] = useState("");
    const [sourceFilterFlag, setSourceFilterFlag] = useState(false);
    const [dsSources, setdsSources] = useState([]);
    const { user, setUser } = useContext(UserContext);

    const [userFilters, setUserFiltes] = useState({
      [LEAD_NAME]: {
        orderDirection: "",
        orderBy: "",
      },
      [LEAD_OWNER]: {
        ownerIds: [],
        type: "or",
      },
      [LEAD_STATUS]: [],
      [LEAD_SOURCE]: [],
      [LEAD_VEHICLE]: [],
    });

    const { data: sourcesData } = useQuery(GET_LEAD_SOURCES);
    const [variables, setVariables] = useState({
      campaignId: selectedCampaign?.id,
      page: 1,
      pageSize: 25,
      search: "",
    });
    const { data: respStatus } = useQuery(GET_CRM_LEAD_STATUS);
    const leadStatusList = (respStatus?.getCrmLeadStatus?.data || []).map(e => ({
      name: e?.status.replace(/_/g, " "),
      id: e?.statusTypeId,
    }));

    const [getDsSources] = useLazyQuery(GET_LEAD_SOURCES_DS, {
      fetchPolicy: "network-only",
      variables: {
        companyId: parseInt(user?.company?.id),
      },
      onCompleted: resp => {
        setdsSources(resp?.getLeadSources);
      },
    });

    const [getLeadStatus] = useLazyQuery(GET_CRM_LEAD_STATUS, {
      fetchPolicy: "network-only",
      onCompleted: resp => {
        setcrmIntegration(resp?.getCrmLeadStatus?.crmIntegrationType);
      },
    });

    // const [getLeads, { data: leadData, loading: leadLoader, refetch: refetchLeads }] = useLazyQuery(
    //   LEAD_LISTING,
    //   {
    //     fetchPolicy: "network-only",
    //     variables: params,
    //     onCompleted: res => {
    //       setLeads([...res.getLeads.data]);
    //     },
    //     onError: err => {
    //       console.log("Error in LEAD_LISTING", err.message);
    //     },
    //   }
    // );
    useMemo(() => {
      getLeadStatus();
    }, [user]);

    useEffect(() => {
      if (user?.company?.id) {
        getDsSources();
      }
    }, [user]);
    useEffect(() => {
      const statsData = [
        {
          title: lang.avgAttemptResponse,
          count: selectedCampaign.leadMessageCount?.avgAttemptsBeforeResponse || 0,
          isPositive: true,
          change: 0.5,
          previous: 2,
          isPercentage: false,
          icon: <HashTagicon />,
        },
        {
          title: lang.optOutRate,
          count: selectedCampaign.leadMessageCount?.optOutRate || 0,
          isPositive: true,
          change: 1,
          previous: 3,
          isPercentage: true,
          icon: <DoorIcon />,
        },
        {
          title: "Appointment booked using OTTO",
          count: selectedCampaign.leadMessageCount?.ottoAppointmentSchCount,
          icon: <EngagedAiIcon />,
        },
        {
          title: "Appointment confirmed using OTTO",
          count: selectedCampaign.leadMessageCount?.ottoAppointmentConfCount,
          icon: <EngagedAiIcon />,
        },
      ];
      setCampaignStatsData(statsData);
    }, [selectedCampaign.leadMessageCount]);
    const { data: resp, loading: getCampaignSummaryLoader } = useQuery(GET_CAMPAIGN_LEAD_SUMMARY, {
      fetchPolicy: "network-only",
      variables: { ...variables, campaignId: isAllCampaign ? null : selectedCampaign?.id },
    });
    useQuery(GET_CAMPAIGN_MESSAGES, {
      variables: { campaignId: selectedCampaign.id },
      fetchPolicy: "network-only",
      onCompleted: res => {},
    });
    const leadData = resp?.getCampaignLeadSummary?.data || [];

    const leadSource = (record, crmIntegration, index) => {
      try {
        const name = record?.lead?.leadSource?.name || "Unknown";
        return crmIntegration === "DS" ? (
          <span key={index}>
            {name}{" "}
            {record?.crmLeadObj?.activeDsOpportunityEvent?.dsMarketingChannelName
              ? ` / ${record?.crmLeadObj?.activeDsOpportunityEvent?.dsMarketingChannelName}`
              : ""}
          </span>
        ) : (
          <span>
            {record?.lead.leadSourceType ? `${record?.lead.leadSourceType} / ` : ""}
            {name}
          </span>
        );
      } catch (err) {
        console.log(err);
      }
    };

    const handleNameSearch = e => {
      setVariables({ ...variables, page: 1, search: e });
    };

    const handleStatusSearch = e => {
      if (e) {
        setVariables({ ...variables, page: 1, status: e });
      }
    };

    const handleAttempSearch = e => {
      if (e) {
        setVariables({ ...variables, page: 1, attempt: e });
      }
    };

    const columnCount = () => {
      return window.innerWidth > 980
        ? 2
        : window.innerWidth <= 980 && window.innerWidth > 480
        ? 2
        : 1;
    };

    const onCustomerActivity = (item, campaigns = null) => {
      // tabContext.setActiveTab("activity-center");
      const payload = {
        data: item?.lead,
        tab: "activity-center",
        campaignIds: campaigns,
        isFromCampaignToActivity: true,
      };
      handleLeadDetailProfile && handleLeadDetailProfile(item?.lead?.id);
      onCancel();
      onRedirect && onRedirect(payload);
      !handleLeadDetailProfile && window.history.pushState("", "", `/engagements/activity-center`);
    };
    const [activeKeys, setactiveKeys] = useState([]);
    const COLUMNS = [
      {
        title: lang.name,
        dataIndex: "fullName",
        key: "fullName",
        defaultSortOrder: "ascend",
        sorter: true,
        render: (value, record) => {
          return (
            <div
              className="secondaryColorTheme cursor-pointer"
              onClick={() => onCustomerActivity(record, [selectedCampaign?.id])}
            >
              {record?.lead?.fullName || ""}
            </div>
          );
        },
        filterIcon: () => (
          <AntdIconV4
            type="SearchOutlined"
            className={`${variables.search ? "secondaryColorTheme" : ""}`}
          />
        ),
        filterDropdown: props => (
          <FilterTextBox
            {...props}
            searchInputRef={searchInputRef}
            confirm={e => handleNameSearch(e)}
          />
        ),
        onFilter: (value, record) => record?.fullName.includes(value),
      },
      {
        title: lang.status,
        dataIndex: "status",
        render: (value, record) => {
          return (
            <div style={{ cursor: "pointer" }}>
              <Tooltip
                title={
                  record?.status === "UNDELIVERED" || record?.status === "FAILED"
                    ? lang.twilioStatusDiscription
                    : null
                }
              >
                <span>{record?.status}</span>
              </Tooltip>
            </div>
          );
        },
        key: "status",
        sorter: true,
        filters: [
          {
            text: "UNCONTACTED",
            value: "UNCONTACTED",
          },
          {
            text: "QUEUED",
            value: "QUEUED",
          },
          {
            text: "SENT",
            value: "SENT",
          },
          {
            text: "FAILED",
            value: "FAILED",
          },
          {
            text: "DELIVERED",
            value: "DELIVERED",
          },
          {
            text: "RESPONDED",
            value: "RESPONDED",
          },
          {
            text: "ENGAGED",
            value: "ENGAGED",
          },
          {
            text: "OPT_OUT",
            value: "OPT_OUT",
          },
        ],
        filterDropdown: props => (
          <FilterDropdown {...props} dataIndex="Status" confirm={e => handleStatusSearch(e)} />
        ),
        filterIcon: () => {
          return (
            <AntdIconV4
              type="FilterOutlined"
              className={`${variables?.status?.length ? "secondaryColorTheme" : "text-[#bfbfbf]"} `}
            />
          );
        },
      },
      {
        title: lang.source,
        width: 150,
        dataIndex: ["leadStatusType"],
        sorter: true,
        key: "lead_source_type",
        // filtered: params?.source?.length > 0,
        filterIcon: () => {
          return <FilterOutlined style={{ color: sourceFilterFlag ? "#005278" : "grey" }} />;
        },
        filterDropdown: props => {
          return (
            <FilterDropdown
              {...props}
              // confirmBtn={e => handleSourceSearch(e)}
              dataIndex="Source"
              dsSources={dsSources}
              setdsSources={setdsSources}
              crmIntegration={crmIntegration}
              setVariables={setVariables}
              variables={variables}
              setflag={setSourceFilterFlag}
              setUserFiltes={setUserFiltes}
              userFilters={userFilters}
              // confirm={e => handleSourceSearch(e)}
            />
          );
        },
        render: (text, record, index) => {
          return leadSource(record, crmIntegration, index);
        },
      },
      {
        title: lang.lastMessageSent,
        dataIndex: "lastMessageSentDate",
        key: "lastMessageSentDate",
        sorter: true,
        render: (value, record) => {
          if (record?.lastMessageSentDate !== null) {
            // old code
            // return dayjs(record?.lastMessageSentDate).format(
            //   "MM/DD/YYYY hh:mm A"
            // )
            return convertUtcToLocal(record?.lastMessageSentDate, "ddd, MMMM Do YYYY, h:mm:ss A");
          }
          return "N/A";
        },
      },
      {
        title: lang.lastResponseReceived,
        dataIndex: "lastMessageReceivedDate",
        key: "lastMessageReceivedDate",
        sorter: true,
        render: (value, record) => {
          if (record?.lastMessageReceivedDate !== null) {
            // old code
            // return dayjs(record?.lastMessageReceivedDate).format(
            //   "MM/DD/YYYY hh:mm A"
            // )
            return convertUtcToLocal(
              record?.lastMessageReceivedDate,
              "ddd, MMMM Do YYYY, h:mm:ss A"
            );
          }
          return "N/A";
        },
      },
      {
        title: lang.attemptsBeforeResponse,
        dataIndex: "numAttemptsBeforeResponse",
        key: "numAttemptsBeforeResponse",
        align: "center",
        sorter: true,
        render: (value, record) => {
          return record?.numAttemptsBeforeResponse !== 0
            ? record?.numAttemptsBeforeResponse
            : "N/A";
        },
        filters: [
          {
            text: "N/A",
            value: "0",
          },
          {
            text: "1",
            value: "1",
          },
          {
            text: "2",
            value: "2",
          },
          {
            text: "3",
            value: "3",
          },
          {
            text: "4",
            value: "4",
          },
          {
            text: "> 4",
            value: ">4",
          },
        ],
        filterDropdown: props => (
          <FilterDropdown {...props} dataIndex="Attempts" confirm={handleAttempSearch} />
        ),
        filterIcon: () => {
          return (
            <AntdIconV4
              type="FilterOutlined"
              className={`${
                variables?.attempt?.length ? "secondaryColorTheme" : "text-[#bfbfbf]"
              } `}
            />
          );
        },
      },
    ];
    const [getCampaignList, { data: campaignResponse, refetch: refetchCampaigns, loading }] =
      useLazyQuery(GET_CAMPAIGN_PAGINATED_LIST, {
        fetchPolicy: "network-only",
      });
    const [getCampaignById, { loading: getCampaignByIdLoader }] =
      useLazyQuery(GET_CAMPAIGN_DETAILS);
    const [getAllCampaignSummary, { loading: getAllCampaignSummaryLoader }] =
      useLazyQuery(GET_ALL_CAMPAIGN_SUMMARY);
    const [getCampaignSchedules, { loading: messageLoader }] = useLazyQuery(GET_CAMPAIGN_SCHEDULES);
    const fetchCampaigns = async () => {
      const res = await getCampaignList();
      setcampaignList(res?.data?.getCampaigns?.data);
    };
    useEffect(() => {
      if (showAllCampaign) fetchCampaigns();
    }, [showAllCampaign]);
    const fetchCampaignById = async () => {
      // call the getCampaignList with filters like campaign id and start date and endDate
      // and set the selectedCampaign to make it work for the rest of the UI in same code
      try {
        const res = await getCampaignById({
          variables: {
            campaignId: selectedCampaign.id,
            startDate: dayjs().startOf(selectedPresetDate).toISOString(),
            endDate: dayjs().toISOString(),
          },
        });
        const messagesRes = await getCampaignSchedules({
          variables: {
            campaignId: selectedCampaign.id,
          },
        });
        const messages = messagesRes.data.campaignSchedules.map((item, index) => {
          const temp = structuredClone(item);
          const nullIndex = temp.campaignTemplates.findIndex(item => item.sourceId === null);
          temp.campaignTemplates[nullIndex] = {
            ...temp.campaignTemplates[nullIndex],
            sourceId: "all",
          };
          return temp;
        });
        setselectedCampaign({
          ...selectedCampaign,
          leadMessageCount: res?.data?.campaign?.data[0]?.leadMessageCount,
          messages: messages.map((item, index) => ({
            ...item,
            scheduleId: item.id,
            isChanged: false,
          })),
        });
      } catch (err) {
        console.log(err);
      }
    };
    const getTotalCountForAllKeys = array => {
      return _.reduce(
        array,
        (result, obj) => {
          _.forEach(obj.leadMessageCount, (value, key) => {
            result[key] = (result[key] || 0) + value;
          });
          return result;
        },
        {}
      );
    };
    const fetchAllCampaigns = async () => {
      // const res = await getAllCampaignSummary({});
      setselectedCampaign({
        ...selectedCampaign,
        leadMessageCount: getTotalCountForAllKeys(campaignList),
      });
    };
    useEffect(() => {
      if (selectedCampaign.id) {
        isAllCampaign ? fetchAllCampaigns() : fetchCampaignById();
      }
    }, [selectedCampaign.id, selectedPresetDate, isAllCampaign]);
    const [activeAccordianKeys, setactiveAccordianKeys] = useState(3);
    const { opportunityFilters } = useSelector(state => state.opportunity);
    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN);
    const TABS = [
      {
        label: lang.details,
        key: "details",
        children: (
          <CustomTable
            data={leadData}
            loading={getCampaignSummaryLoader}
            columns={COLUMNS}
            rowKey="id"
            onChange={(pagination, filters, sorter) => {
              const parsedSorter = {};

              switch (sorter.order) {
                case "ascend":
                  parsedSorter.orderDirection = "asc";
                  break;
                case "descend":
                  parsedSorter.orderDirection = "desc";
                  break;
                default:
                  parsedSorter.orderDirection = null;
                  break;
              }

              parsedSorter.orderBy = toSnake(sorter.columnKey) || "";

              const newVariables = {
                ...variables,
                ...parsedSorter,
                page: variables.pageSize !== pagination.pageSize ? 1 : pagination.current,
                pageSize: pagination.pageSize,
              };

              if (!parsedSorter.orderBy || !parsedSorter.orderDirection) {
                delete newVariables.orderBy;
                delete newVariables.orderDirection;
              }

              setVariables(newVariables);
            }}
            pagination={{
              defaultCurrent: variables.page,
              current: variables.page,
              defaultPageSize: variables.pageSize,
              pageSize: variables.pageSize,
              total: resp?.getCampaignLeadSummary?.count,
              showTotal: total => `${lang.total}: ${total} ${total === 1 ? lang.lead : lang.leads}`,
              pageSizeOptions: ["5", "25", "50"],
              showSizeChanger: true,
            }}
            scroll={{ x: 1200 }}
          />
        ),
      },
      {
        label: lang.messages,
        key: "messages",
        children: messageLoader ? (
          <Skeleton active paragraph={{ rows: 5 }} />
        ) : (
          // <LeadScheduleView
          //   currentCampaign={selectedCampaign}
          //   fromCampagin
          //   campaignBy={
          //     (selectedCampaign?.campaignSelections || []).length > 0 &&
          //     selectedCampaign?.campaignSelections[0].type
          //   }
          //   showHeaderSection={false}
          // />
          <ThirdStep
            sources={sources}
            campaignData={selectedCampaign}
            setCampaignData={setselectedCampaign}
            activeKeys={activeKeys}
            setactiveKeys={setactiveKeys}
            activeAccordianKeys={activeAccordianKeys}
            setaccordianDisabled={false}
            setactiveAccordianKeys={setactiveAccordianKeys}
            campaignByLead
            opportunityFilters={opportunityFilters}
            // createCampaign={createCampaign}
            updateCampaign={updateCampaign}
            showBack={false}
          />
        ),
        disabled: isAllCampaign,
      },
    ];
    const messageStats = [
      {
        label: "Message Sent",
        value: selectedCampaign?.leadMessageCount?.totalSent,
        difference: "5.5%",
        differenceType: true,
        icon: <PurpleEnvalopeIcon />,
      },
      {
        label: "Response Received",
        value: selectedCampaign?.leadMessageCount?.totalDelivered,
        difference: "2.0%",
        differenceType: false,
        icon: <PurpleEnvalopeIcon />,
      },
      {
        label: "Engagement",
        value: selectedCampaign?.leadMessageCount?.totalEngaged,
        difference: "3.5%",
        differenceType: true,
        icon: <PurpleEnvalopeIcon />,
      },
    ];

    const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);
    const handleActiveToggle = async (checked, item) => {
      const res = await updateCampaign({
        variables: {
          id: parseInt(item.id),
          userId: user?.id || 0,
          name: item?.name,
          textMessage: item?.textMessage,
          method: "Text",
          activeInd: checked ? "Active" : "Inactive",
        },
      });
      if (res.data.updateCampaign.statusCode === 200) {
        setselectedCampaign({ ...selectedCampaign, activeInd: checked ? "Active" : "Inactive" });
        message.success(
          `${lang.campaignSuccessfully} ${checked ? lang.activated : lang.inactivated}`
        );
      }
    };
    const handleDeleteClick = item => {
      showConfirmationModal(MODAL_TITLE, MODAL_CONTENT, () =>
        deleteCampaign({ variables: { id: item.id } }).then(async resp => {
          if (resp?.data?.deleteCampaign?.statusCode === 200) {
            // refetch();
            const res = await getCampaignList();
            setselectedCampaign(res?.data?.getCampaigns?.data[0]);
            message.success(
              `${resp?.data?.deleteCampaign?.message}, switching to first available campaign`
            );
          } else {
            message.success(lang.campaignCldntDelteInProgress);
          }
        })
      );
    };
    const handleCreateCampaign = data => {
      onCampaignBySource(data);
    };
    const handleCloneClick = e => {
      openDialog("cloneCampaign", {
        dialogHeader: `Clone ${e.name}`,
        campaign: e,
        handleCreateCampaign,
      });
    };
    const handleCampaignMenuClick = (value, item) => {
      switch (value.key) {
        case "1":
          showConfirmationModal(MODAL_TITLE, MODAL_CONTENT, () =>
            handleActiveToggle(item.activeInd !== "Active", item)
          );
          break;
        case "2":
          break;
        case "3":
          handleCloneClick(item);
          break;
        case "4":
          handleDeleteClick(item);
          break;
        default:
          break;
      }
    };

    const canView = checkAccessPermission(AccessPermissionAction.CAMPAIGN_VIEW);
    const messageObj = {
      title: "First message",
      numericValue: 10, // seconds
      temporalValue: "MINUTES",
      type: "ONCE",
      campaignTemplates: [],
      isChanged: true,
    };
    return (
      <div>
        <div
          className={` flex flex-row items-center ${
            showAllCampaign ? "justify-between bg-white" : "justify-end"
          } w-full mt-[-15px] p-5`}
        >
          {showAllCampaign && (
            <div className="flex flex-row items-center gap-2">
              <span className="text-xs font-[600] text-[#64748B]">Select Campaign</span>
              <Select
                loading={loading}
                value={
                  loading ? null : isAllCampaign ? "All" : selectedCampaign && selectedCampaign.id
                }
                className="w-[300px]"
                onChange={value => {
                  if (value !== "All") {
                    const obj = campaignList.find(item => item.id === value);
                    if (obj) setselectedCampaign({ ...obj });
                    setisAllCampaign(false);
                  } else {
                    setisAllCampaign(true);
                  }
                }}
              >
                <Select.Option value="All" key="all">
                  All
                </Select.Option>
                {(campaignList || []).map((item, index) => (
                  <Select.Option value={item.id} key={index}>
                    {item.name} ({firstUpperCaseWordParser(item.activeInd)})
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
          <Button onClick={onBackClick}>Back</Button>
        </div>
        <div className="pr-4">
          {/* First Row */}
          <FirstRow
            isAllCampaign={isAllCampaign}
            selectedCampaign={selectedCampaign}
            handleCampaignMenuClick={handleCampaignMenuClick}
            selectedPresetDate={selectedPresetDate}
            dispatch={dispatch}
          />
          <div className="flex flex-row items-center gap-4">
            <div className="w-1/2">
              {isAllCampaign ? (
                <div className="flex flex-col bg-white rounded-sm h-full items-stretch ">
                  <CampaignStats campaigns={campaignList} />
                </div>
              ) : (
                <CampaignItem
                  canView={canView}
                  index={selectedCampaign.id}
                  item={selectedCampaign}
                  lang={lang}
                  refetch={onBackClick}
                  sources={sources}
                  onCampaignBySource={onCampaignBySource}
                  search=""
                  showHeader={false}
                />
              )}
            </div>
            {getCampaignByIdLoader || getAllCampaignSummaryLoader ? (
              <Skeleton className="w-1/2" active paragraph={{ rows: 3 }} avatar />
            ) : (
              <div className="w-1/2 mt-2">
                <List
                  // className="mt-2"
                  grid={{ column: columnCount() }}
                  dataSource={campaignStatsData}
                  renderItem={(item, index) => (
                    <List.Item
                      className="shadow mx-2.5 text-center cursor-pointer bg-white p-4 rounded-lg"
                      key={index}
                      onClick={() => onCustomerActivity(null, [selectedCampaign?.id])}
                    >
                      <div className="flex flex-row items-center gap-4">
                        {item.icon}
                        <div className="flex flex-col items-start gap-1">
                          <CustomLabel
                            labelClass="text-sm font-[500] text-grey-500"
                            label={item.title}
                          />
                          <CustomLabel
                            labelClass="text-3xl font-[600] text-grey-700"
                            label={`${
                              item.isPercentage ? parseFloat(item.count).toFixed(2) : item.count
                            } ${item.isPercentage ? "%" : ""}`}
                          />
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            )}
          </div>
          {/* 2nd row */}
          <SecondRow messageStats={messageStats} getCampaignByIdLoader={getCampaignByIdLoader} />
          <div className="w-full">
            <CustomTabs
              defaultActiveKey={activeTab}
              tabBarExtraContent={
                activeTab === "messages" && (
                  <Button
                    // disabled={accordianDisabled.includes(3)}
                    onClick={e => {
                      e.stopPropagation();
                      setselectedCampaign({
                        ...selectedCampaign,
                        messages: [
                          ...selectedCampaign.messages,
                          {
                            ...messageObj,
                            sortOrder: selectedCampaign.messages.length,
                            title: `${ordinalNumbers[selectedCampaign.messages.length]} message`,
                          },
                        ],
                      });
                      setactiveKeys([...activeKeys, selectedCampaign?.messages?.length]);
                    }}
                    className="rounded-sm py-1"
                    type="primary"
                  >
                    <PlusOutlined />
                    Add Message
                  </Button>
                )
              }
              handleChange={key => {
                setActiveTab(key);
              }}
              tabItems={TABS}
            />
          </div>
        </div>
      </div>
    );
  } catch (err) {
    console.error(err);
    return <CrashAlert />;
  }
};

export default CampaignDetails;
