import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import isEmpty from "lodash/isEmpty";
import { Colors } from "../../../library/constants";
import { DEFAULT_COUNTRY } from "../../../config";
import "./editor.css";

const SpanWrap = styled.span`
  color: red;
  margin-left: 2px;
`;
const PhoneInputWrap = styled(PhoneInput)`
  input {
    background-color: transparent;
    border: 0;
    width: 120px;
  }
  padding: 8px 0;
  input:hover {
    background-color: ${Colors.lightGray};
    cursor: pointer;
    border: 1px solid ${Colors.lightGray};
  }
`;

const EditablePhoneInput = ({
  key,
  value,
  onSave,
  disabled = false,
  className = "editable-phone",
}) => {
  const [text, setText] = useState(value || "");
  const [error, setError] = useState("");

  useEffect(() => {
    setText(isEmpty(value) ? "N/A" : value || "");
  }, [value]);

  const onKeyDown = event => {
    if (event?.key === "Enter" || event?.key === "Escape") {
      onSave(text);
      event.target.blur();
    }
  };

  const onBlur = event => {
    if (text?.trim() === "") {
      setError("Please enter value");
    } else {
      setText(text);
    }
  };

  return (
    <>
      {error && <SpanWrap>{error}</SpanWrap>}
      <PhoneInputWrap
        international
        withCountryCallingCode
        countryCallingCodeEditable={false}
        defaultCountry={DEFAULT_COUNTRY}
        value={text}
        className={className}
        onChange={val => setText(val)}
        // onFocus={e => setEditable(true)}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder="N/A"
        disabled={disabled}
      />
    </>
  );
};

export default EditablePhoneInput;
