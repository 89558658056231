import * as React from "react";

const DoorIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={56} height={56} fill="none" {...props}>
    <rect width={56} height={56} fill="#FFEDD5" rx={28} />
    <path
      fill="#F97316"
      d="M26.25 37.625a.875.875 0 0 1-.875.875H19.25a1.75 1.75 0 0 1-1.75-1.75v-17.5a1.75 1.75 0 0 1 1.75-1.75h6.125a.875.875 0 1 1 0 1.75H19.25v17.5h6.125a.875.875 0 0 1 .875.875Zm11.994-10.244-4.375-4.375a.876.876 0 0 0-1.238 1.238l2.882 2.881H25.375a.875.875 0 1 0 0 1.75h10.138l-2.882 2.88a.876.876 0 0 0 1.238 1.24l4.375-4.376a.876.876 0 0 0 0-1.238Z"
    />
  </svg>
);
export default DoorIcon;
