import * as React from "react";

const HashTagicon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={56} height={56} fill="none" {...props}>
    <rect width={56} height={56} fill="#FFEDD5" rx={28} />
    <path
      fill="#F97316"
      d="M38.5 23.624h-5.316l.927-5.093a.875.875 0 0 0-1.722-.313l-.984 5.406h-5.22l.926-5.093a.875.875 0 0 0-1.722-.313l-.983 5.406H19.25a.875.875 0 1 0 0 1.75h4.838l-.955 5.25H17.5a.875.875 0 1 0 0 1.75h5.316l-.927 5.094a.875.875 0 0 0 1.72.313l.985-5.407h5.222l-.927 5.094a.875.875 0 0 0 1.72.313l.985-5.407h5.156a.875.875 0 1 0 0-1.75h-4.838l.955-5.25H38.5a.875.875 0 1 0 0-1.75Zm-8.367 7h-5.22l.954-5.25h5.22l-.954 5.25Z"
    />
  </svg>
);
export default HashTagicon;
