import * as React from "react";

const SortIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M10 10a.625.625 0 0 1-.625.625H3.75a.625.625 0 1 1 0-1.25h5.625A.625.625 0 0 1 10 10ZM3.75 5.625h10.625a.625.625 0 1 0 0-1.25H3.75a.625.625 0 0 0 0 1.25Zm4.375 8.75H3.75a.625.625 0 1 0 0 1.25h4.375a.625.625 0 1 0 0-1.25Zm9.817-1.692a.627.627 0 0 0-.884 0L15 14.74V8.75a.625.625 0 1 0-1.25 0v5.991l-2.058-2.058a.625.625 0 0 0-.884.884l3.125 3.125a.627.627 0 0 0 .884 0l3.125-3.125a.627.627 0 0 0 0-.884Z"
    />
  </svg>
);
export default SortIcon;
