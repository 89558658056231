/* eslint-disable no-unreachable */
/* eslint-disable no-useless-return */
/* eslint-disable default-param-last */
/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-else-return */
import { CloseCircleFilled, EditFilled, UndoOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, DatePicker, Input, Select, Tooltip, message } from "antd";
import dayjs from "dayjs";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { UserContext } from "../../../../amplify/authenticator-provider";
import {
  CREATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  UPDATE_APPOINTMENT,
} from "../../../../graphql/mutation";
import {
  GET_ALL_USERS_APPOINTMENT_FORM,
  GET_COMPANY_WORK_HOURS,
  GET_LEAD_INFO_APPOINTMENT,
  GET_LEAD_LIST_ACTIVITY_CENTER,
  GET_LOCATIONS,
} from "../../../../graphql/query";
import useLang from "../../../../hooks/useLang";
import RedirectIcon from "../../../../icons/js/RedirectIcon";
import { colorConfig } from "../../../../library/colorConfig";
import { AccessPermissionAction, timezones } from "../../../../library/constants";
import {
  checkAccessPermission,
  closeDialog,
  getLocationObj,
  openDialog,
} from "../../../../library/helpers";
import { convertUtcToLocal } from "../../../../library/utils";
import AntdIconV4 from "../../../../utils/antdIcon";
import CustomAlert from "../../../commonComponents/alert";
import CustomButton from "../../../commonComponents/button";
import CustomCheckbox from "../../../commonComponents/checkbox";
import CustomTimePicker from "../../../commonComponents/customTimePicker";
import CustomLabel from "../../../commonComponents/label";
import UserSelector from "../../../commonComponents/userSelector";
import { handleOpportunityDilaog } from "../opporotunityLayer/opportuityWarning";

const { TextArea } = Input;

const AppointmentStatus = styled.span`
  & .ant-tag {
    color: ${props =>
      props?.appointmentStatus === "SCHEDULED"
        ? "#2db7f5"
        : props?.appointmentStatus === "NO SHOWED" || props?.appointmentStatus === "NO_SHOWED"
        ? "#808080"
        : props?.appointmentStatus === "CONFIMRED"
        ? "green"
        : props?.appointmentStatus === "SHOWED"
        ? colorConfig.primaryDark
        : props?.appointmentStatus === "RESCHEDULED"
        ? "#2db7f5"
        : "#ff4d4f"} !important;
    border-color: ${props =>
      props?.appointmentStatus === "SCHEDULED"
        ? "#2db7f5"
        : props?.appointmentStatus === "NO SHOWED" || props?.appointmentStatus === "NO_SHOWED"
        ? "#808080"
        : props?.appointmentStatus === "CONFIMRED"
        ? "green"
        : props?.appointmentStatus === "SHOWED"
        ? colorConfig.primaryDark
        : props?.appointmentStatus === "RESCHEDULED"
        ? "#2db7f5"
        : "#ff4d4f"} !important;
    background: #fff;

    margin-left: 2px;
  }
`;

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

const AppointmentForm = props => {
  const {
    lead: selectedLead = null,
    showLeadChanger = false,
    appointment = null,
    setAppointmentModelVisible,
    defaultDate = dayjs().local(),
    redirect = null,
    activeAppointments = [],
    leadOpportunityId,
    leadOpportunities = [],
    onClose,
    eventId = false,
    allowLeadChange = true,
    mode = "EDIT",
    refreshLeadListData = () => {},
    refetchLeads = () => {},
    isFromCard = false,
  } = props;
  const { user, setrefetchAppointments, refetchAppointments } = useContext(UserContext);

  const [virtualObj, setvirtualObj] = useState({
    virtual: false,
    videoCallIntegrationType: null,
  });
  const [lang] = useLang();
  const virtualMeetingMode = [
    {
      title: "Zoom Video Call",
      value: "ZOOM",
    },
    {
      title: "Google Meet",
      value: "GOOGLE",
    },
  ];
  const [opprWithParent, setopprWithParent] = useState([]);
  const [opprWithoutParent, setopprWithoutParent] = useState([]);
  const [lead, setlead] = useState({ ...selectedLead });
  const [leadSearch, setleadSearch] = useState("");
  const [leadList, setleadList] = useState([]);
  const [allOppr, setAllOppr] = useState([]);
  const [opportunbityStatus, setOpportunityStatus] = useState([]);
  const [selectOpprortunity, setSelectOpportunity] = useState("");
  const [isAppoinmentConfirm, setIsAppoinmentCofirm] = useState(appointment?.isConfirmed || false);
  const [isAppoinmentConfirmLocal, setIsAppoinmentCofirmLocal] = useState(false);
  const [isChangedByUser, setIsChangedByUser] = useState(appointment?.isConfirmed || false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdownStatus, setDropdownStatus] = useState("");
  const [statusEditMode, setstatusEditMode] = useState(false);
  const [statusDropdownList, setStatusDropdownList] = useState([
    { key: "CANCELLED", value: "CANCELLED", label: "Cancel" },
  ]);
  const [userLocationsId, setUserLocationsId] = useState([]);
  // opportunity status list and selectedStatus
  const [oppostunityLists, setOpportunityList] = useState([]);
  const [selectedOppostunityLists, setSelectedOpportunityList] = useState(oppostunityLists[0]?.id);
  const [title, setTitle] = useState(user ? `Appointment at ${user?.company?.name}` : "");
  const [users, setUsers] = useState([
    {
      id: appointment?.user?.id ? appointment?.user?.id : user?.id,
      name: `${appointment?.user?.fullName ? appointment?.user?.fullName : user?.fullName} (${
        appointment?.user?.companyRole?.name
          ? appointment?.user?.companyRole?.name
          : user?.companyRole?.name
      })`,
    },
  ]);
  const [assignedUserId, setAssignedUserId] = useState(user?.id || "");
  const [visibleOpportunityDialog, setVisibleOpportunityDialog] = useState(false);
  const [openAppointmentConfirm, setOpenAppointmentConfirm] = useState(false);
  const [whileCreateNewOppr, setWhileCreateNewoppr] = useState([]);
  const [opportunityInfo, setOpportunityInfo] = useState({});
  const [isOpporDialogFlagChanged, setIsOpprDialogFlagChanged] = useState(false);
  const [opportunityWarningDialog, setOpportunityWarningDialog] = useState(false);
  const [leadInfo, setLeadInfo] = useState({});
  const [error, setError] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(defaultDate);
  const [appointmentTime, setAppointmentTime] = useState(
    !isEmpty(appointment)
      ? dayjs?.utc(appointment?.startDatetime)?.tz(appointment?.timezone)?.format("h:mm A")
      : dayjs().add(1, "hours").format("h:mm A")
  );

  const [note, setNote] = useState("");
  const [timezone, setTimezone] = useState(user?.company?.timezone || dayjs.tz.guess());
  const [vehicle, setVehicle] = useState();
  const [vehiclesOfInterests, setVehiclesOfInterests] = useState([]);
  const [leadOpportunity, setLeadOpportunity] = useState(
    String(
      appointment?.leadOpportunityId ||
        appointment?.leadOpportunity?.id ||
        leadOpportunities[0]?.id ||
        leadOpportunities[0]?.dsOpportunityEvents[0]?.dsEventId ||
        leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId ||
        ""
    )
  );
  useEffect(() => {
    if (!appointment) {
      setAppointmentDate(dayjs(defaultDate.toISOString()));
    }
  }, [defaultDate]);
  console.log(appointmentDate);
  const hadnleAppointmentDialog = () => {
    openDialog("appointmentConfirm", {
      dialogHeader: lang.appointmentConfirmation,
      handleCancelBtn: () => {
        closeDialog("appointmentConfirm");
        setIsAppoinmentCofirm(false);
      },
      handleConfirm: () => closeDialog("appointmentConfirm"),
    });
    // setOpenAppointmentConfirm(!openAppointmentConfirm);
  };

  const { data: respLocation } = useQuery(GET_LOCATIONS, {
    variables: { companyId: user?.company?.id },
    onCompleted: resp => {
      const location = (resp?.getLocations || []).find(
        el => +el.id === +sessionStorage.getItem("currentLocationId")
      );
      if (location) {
        setTitle(`${lang.appointment} ${lang.at.toLowerCase()} ${location?.title || ""}`);
      }
    },
  });

  const { data: leadData } = useQuery(GET_LEAD_INFO_APPOINTMENT, {
    variables: { leadId: +selectedLead?.id },
    onCompleted: res => {
      setLeadInfo(res?.lead);
    },
  });

  const { data: respWorkHrs } = useQuery(GET_COMPANY_WORK_HOURS, {
    variables: {
      companyId: user?.company?.id || 0,
      locationId: parseInt(sessionStorage.getItem("currentLocationId") || 0),
    },
  });
  const [createAppointment, { loading }] = useMutation(CREATE_APPOINTMENT);
  const [updateAppointment, { loading: updateLoading }] = useMutation(UPDATE_APPOINTMENT);
  const [deleteAppointment, { loading: deleteLoading }] = useMutation(DELETE_APPOINTMENT);

  const worksHours = respWorkHrs?.getCompanyWorkingHours || [];

  const { loading: fetchLoading } = useQuery(GET_ALL_USERS_APPOINTMENT_FORM, {
    fetchPolicy: "cache-first",
    variables: { companyId: user?.company?.id, isGlobalLeadOwner: true, isAllLocationUsers: true },
    onCompleted: res => {
      const users = res?.getUsers?.data || [];
      const list = (users || [])
        .filter(el => !el.isDisabled)
        .map(el => ({
          id: el.id,
          name: `${el.fullName} (${el?.companyRole?.name || user?.companyRole?.name || "N/A"}) `,
        }));
      setUsers(list);
      const findIndex = list.findIndex(el => +el.id === +appointment?.userId);
      if (findIndex > -1) {
        setAssignedUserId(String(list[findIndex]?.id));
      }
    },
  });
  const [getLeads, { data: leads, loading: leadsLoader }] = useLazyQuery(
    GET_LEAD_LIST_ACTIVITY_CENTER,
    {
      fetchPolicy: "network-only",
      variables: {
        search: leadSearch,
        page: 1,
        pageSize: 20,
      },
    }
  );
  useEffect(() => {
    if (lead) {
      const tempvehiclesOfInterests = [
        ...(lead?.leadVehicleInventory || []),
        ...(lead?.vehicleOfInterest || []),
      ];
      setVehiclesOfInterests(tempvehiclesOfInterests);
      setVehicle(tempvehiclesOfInterests.length > 0 ? String(tempvehiclesOfInterests[0].id) : "");
    }
  }, [lead]);
  useEffect(() => {
    if (!isEmpty(selectedLead)) {
      setlead({ ...selectedLead });
    } else {
      getLeads();
    }
  }, [selectedLead]);
  useEffect(() => {
    if (!isEmpty(leads?.getLeads?.data) && isArray(leads?.getLeads?.data)) {
      setleadList(leads.getLeads.data);
    }
  }, [leads?.getLeads?.data]);
  useEffect(() => {
    if (appointment?.appointmentStatus) {
      setDropdownStatus(appointment?.appointmentStatus);
    }
  }, [appointment?.appointmentStatus]);
  useEffect(() => {
    if (lead?.activeAppointment?.appointmentStatus && isFromCard) {
      setDropdownStatus(lead?.activeAppointment?.appointmentStatus);
    }
  }, [lead?.activeAppointment?.appointmentStatus]);
  useEffect(() => {
    if (appointment && !isEmpty(appointment) && mode === "EDIT") {
      setAppointmentDate(
        dayjs.utc(appointment?.startDatetime).tz(appointment?.timezone || dayjs.tz.guess()) ||
          dayjs()
      );
      setAppointmentTime(
        dayjs
          ?.utc(appointment?.startDatetime)
          ?.tz(appointment?.timezone || dayjs.tz.guess())
          ?.format("h:mm A") || dayjs()?.add(1, "hours")?.format("h:mm A")
      );
      setNote(appointment?.description || "");
      setTitle(appointment?.summary || "");
      setTimezone(appointment?.timezone || dayjs.tz.guess());

      const vehicles = appointment?.lead?.leadVehicleInventory || [];
      const voi = appointment?.lead?.vehicleOfInterest || [];
      const tempvehiclesOfInterests = [];
      vehicles.forEach(el => {
        if (tempvehiclesOfInterests.findIndex(e => +e.id === +el.id) === -1) {
          tempvehiclesOfInterests.push(el);
        }
      });
      voi.forEach(el => {
        if (tempvehiclesOfInterests.findIndex(e => +e.id === +el.id) === -1) {
          tempvehiclesOfInterests.push(el);
        }
      });

      setVehiclesOfInterests(tempvehiclesOfInterests);

      const vid = String(appointment?.discussedVoiId || "");
      const findIndex = vehiclesOfInterests?.findIndex(el => String(el.id) === vid.toString());
      if (!isEmpty(vid) && findIndex > -1) {
        setVehicle(vid);
      }
      const uid = String(appointment?.userId || "");
      if (!isEmpty(uid)) {
        setAssignedUserId(uid);
      }
    }
  }, [appointment]);

  const handleDeleteAppointment = e => {
    if (e && !isUndefined(appointment?.id)) {
      openDialog("confirmDialog", {
        label: lang.doYouWantToContinue,
        content: lang.clickOnOK,
        okBtnType: "danger",
        confirmBtnText: lang.yes,
        isOKGhost: true,
        handleConfirm: async () => {
          try {
            const resp = await deleteAppointment({
              variables: {
                appointmentId: appointment?.id,
              },
            });
            if (resp?.data?.deleteAppointment?.statusCode === 200) {
              message.success(resp?.data?.deleteAppointment?.message);
              setAppointmentModelVisible(true);
              setrefetchAppointments(!refetchAppointments);
              // refetchAppointmentList();
              onClose();
              closeDialog("confirmDialog");
              if (redirect) {
                redirect(
                  false,
                  false,
                  !window.location.pathname.includes("engagements/appointment"),
                  appointment?.lead?.id || lead?.id,
                  false
                );
              }
              closeDialog("appointmentForm");
            } else {
              message.error(resp?.data?.deleteAppointment?.message);
            }
          } catch (mutationError) {
            const error = JSON.parse(JSON.stringify(mutationError));
            message.error(error?.graphQLErrors && error?.graphQLErrors[0]?.message);
          }
        },
      });
    }
  };

  const workHoursTime = () => {
    const day = dayjs(appointmentDate).format("dddd");
    const workDay = worksHours.find(el => el.weekDay === day.toUpperCase());
    const hours = [];
    // const startHrs = workDay && dayjs(workDay.startTime, "HH:mm:ss").format("h:mm A");
    const startHrs =
      workDay && dayjs(workDay.startTime, "HH:mm:ss").add(30, "minute").format("h:mm A");
    if (startHrs) {
      hours.push(startHrs);
    }
    // const endHrs = workDay && dayjs(workDay.endTime, "HH:mm:ss").format("h:mm A");
    const endHrs =
      workDay && dayjs(workDay.endTime, "HH:mm:ss").subtract(30, "minute").format("h:mm A");
    if (endHrs) {
      hours.push(endHrs);
    }
    return hours;
  };

  const workingTime = workHoursTime(timezone);

  const handleOpportunityWarningDialog = (item, crmLink, opprId, opprName, isOpp = false) => {
    setOpportunityInfo({
      ...item[0],
      opprLink: crmLink,
      opprId: opprId,
      opprName: opprName,
      isOpp: isOpp,
      crmLeadObj: leadInfo?.crmLeadObj,
    });
    setOpportunityWarningDialog(true);
  };

  const handleOppDialog = () => {};

  const handleSaveAppointment = async (e, isConfirmBtn = false, isAppConfirm = false) => {
    isConfirmBtn && hadnleAppointmentDialog();
    const newtime = dayjs(appointmentTime, "h:mm A");
    const startTime = dayjs(workingTime[0], "h:mm A");
    const endTime = dayjs(workingTime[1], "h:mm A");

    if (newtime.isBefore(startTime) || newtime.isAfter(endTime)) {
      message.error(lang.selectTimeFromSlot);
      return;
    }

    if (appointment?.id) {
      if (appointmentDate.isBefore(dayjs().format("MM/DD/YYYY")) && !dropdownStatus) {
        message.error(lang.reschedualInPastDate);
        return;
      }

      if (dayjs().isSame(appointmentDate, "day")) {
        if (newtime.isBefore(dayjs().format()) && !dropdownStatus) {
          message.error(lang.reschedualInPasTime);
          return;
        }
      }
    }

    if (isUndefined(leadOpportunity)) {
      handleOppDialog();
      return;
    }

    if (e) {
      if (isEmpty(title)) {
        setError(lang.enterAppointmentTitle);
        return;
      } else if (isEmpty(appointmentDate) || isEmpty(appointmentTime)) {
        setError(lang.enterAppointmentStartDateTime);
        return;
      }
      const startDate = `${dayjs(appointmentDate).format("YYYY-MM-DD")} ${dayjs(
        appointmentTime,
        "h:mm A"
      ).format("HH:mm:ss")}`;

      if (!isUndefined(appointment?.id)) {
        let variables = {
          appointmentId: appointment?.id,
          userId: assignedUserId,
          summary: title,
          description: note,
          appointmentTimezone: timezone,
          startDate: dayjs
            .tz(startDate, timezone || dayjs.tz.guess())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          endDate: dayjs
            .tz(startDate, timezone || dayjs.tz.guess())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          leadOpportunityId:
            +appointment?.leadOpportunityId ||
            +appointment?.leadOpportunity?.id ||
            +leadOpportunity,
          isConfirmed: isAppConfirm || isAppoinmentConfirm,
          ...virtualObj,
          zoomObject: {
            topic: title,
            preSchedule: false,
            startTime: dayjs.tz(startDate, timezone || dayjs.tz.guess()).toISOString(),
            hostVideo: true,
            participantVideo: true,
            waitingRoom: true,
          },
        };
        if (!isEmpty(vehicle)) {
          variables.discussedVoiId = vehicle;
        }
        if (!isUndefined(e?.status)) {
          variables.status = e.status;
        }

        if (
          eventId &&
          appointment?.leadOpportunity?.dsOpportunityEvents?.length > 0 &&
          appointment?.leadOpportunity?.dsOpportunityEvents.findIndex(
            event => event?.dsEventId === eventId
          ) > -1
        ) {
          variables = { ...variables, dsEventId: eventId };
        } else {
          delete variables.eventId;
        }

        try {
          const resp = await updateAppointment({
            variables,
          });

          if (resp?.data?.updateAppointment?.statusCode === 200) {
            message.success(resp?.data?.updateAppointment?.message);
            setAppointmentModelVisible(true);
            setrefetchAppointments(!refetchAppointments);
            refreshLeadListData();
            // refetchAppointmentList();
            onClose();
            if (showLeadChanger) {
              return;
            }
            redirect(
              false,
              resp?.data?.updateAppointment?.appointment?.startDatetime,
              !window.location.pathname.includes("appointment"),
              appointment?.lead?.id || lead?.id
            );
          } else {
            message.error(resp?.data?.updateAppointment?.message);
          }
        } catch (mutationError) {
          const error = JSON.parse(JSON.stringify(mutationError));
          message.error(error?.graphQLErrors && error?.graphQLErrors[0]?.message);
        }
      } else {
        let variables = {
          leadId: lead?.id,
          userId: assignedUserId,
          summary: title,
          description: note,
          appointmentTimezone: timezone,
          startDate: dayjs
            .tz(startDate, timezone || dayjs.tz.guess())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          endDate: dayjs
            .tz(startDate, timezone || dayjs.tz.guess())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          leadOpportunityId: +leadOpportunity || +leadOpportunities[0]?.id,
          isConfirmed: isAppConfirm || isAppoinmentConfirm,
          ...virtualObj,
          zoomObject: {
            topic: title,
            preSchedule: false,
            startTime: dayjs.tz(startDate, timezone || dayjs.tz.guess()).toISOString(),
            hostVideo: true,
            participantVideo: true,
            waitingRoom: true,
          },
        };
        if (!isEmpty(vehicle)) {
          variables.discussedVoiId = vehicle;
        }

        const filterEvent = (
          lead?.crmLeadObj?.activeLeadOpportunity?.dsOpportunityEvents || []
        ).filter(ev => ev.dsEventId === eventId);

        if (eventId && filterEvent.length > 0) {
          variables = { ...variables, dsEventId: eventId };
        } else {
          delete variables.eventId;
        }

        try {
          const resp = await createAppointment({
            variables,
          });
          if (resp?.data?.createAppointment?.statusCode === 200) {
            message.success(resp?.data?.createAppointment?.message);
            setAppointmentModelVisible(true);
            refetchLeads();
            // refetchAppointmentList();
            setrefetchAppointments(!refetchAppointments);
            onClose();
            if (showLeadChanger) {
              return;
            }
            redirect(
              false,
              resp?.data?.createAppointment?.appointment?.startDatetime,
              true,
              appointment?.lead?.id || lead?.id
            );
          } else {
            message.error(resp?.data?.createAppointment?.message);
          }
        } catch (mutationError) {
          const error = JSON.parse(JSON.stringify(mutationError));
          message.error(error?.graphQLErrors && error?.graphQLErrors[0]?.message);
        }
      }
    }
  };
  console.log(dropdownStatus);
  const disabledControl = () => {
    return (
      appointment?.appointmentStatus === "CANCELLED" ||
      appointment?.appointmentStatus === "NO_SHOWED" ||
      appointment?.appointmentStatus === "SHOWED" ||
      sessionStorage.getItem("currentLocationId").includes(",")
    );
  };

  const disableWeekDay = date => {
    const day = dayjs(date).format("dddd");
    const index = worksHours.findIndex(el => el.weekDay === day.toUpperCase() && !el.isWorkingDay);
    return index > -1;
  };

  const onChange = e => {
    if (e) {
      setAppointmentDate(e);
      setIsAppoinmentCofirm(false);
      setIsChangedByUser(false);
    }
  };
  const onTimeChange = e => {
    if (e) {
      setAppointmentTime(e);
    }
    setIsAppoinmentCofirm(false);
    setIsChangedByUser(false);
  };

  const getAppointmentTimes = () => {
    const list = (activeAppointments || []).filter(el => {
      return (
        convertUtcToLocal(el?.startDatetime, "MM/DD/YYYY") ===
        dayjs(appointmentDate).format("MM/DD/YYYY")
      );
    });
    const hours = (list || []).map(el => {
      return convertUtcToLocal(el?.startDatetime, "h:mm A");
    });
    return hours;
  };

  const disabledHours = getAppointmentTimes();

  const canSchedule = checkAccessPermission(AccessPermissionAction.APPOINTMENT_SCHEDULE);
  const canReSchedule = checkAccessPermission(AccessPermissionAction.APPOINTMENT_UPDATE);
  const canCancel = checkAccessPermission(AccessPermissionAction.APPOINTMENT_CANCEL);

  useEffect(() => {
    appointment?.leadOpportunityId
      ? setLeadOpportunity(appointment?.leadOpportunityId)
      : setLeadOpportunity(
          leadOpportunities[0]?.id ||
            leadOpportunities[0]?.dsOpportunityEvents[0]?.dsEventId ||
            leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId
        );

    if (window.location.pathname.includes("appointment")) {
      if (!leadOpportunity) {
        if (!appointment?.leadOpportunityId) {
          handleOppDialog();
          return;
        }
      }
    } else {
      if (appointment?.leadOpportunityId) {
        return;
      }
      if (leadOpportunities.length === 0) {
        handleOppDialog();
      } else {
        if (leadOpportunities.length > 0) {
          return;
        } else if (isUndefined(leadOpportunityId)) {
          handleOppDialog();
        }
      }
    }
  }, [leadOpportunities.length, leadOpportunityId]);

  const handleLeadOpportunityList = () => {
    const findOppr = (leadData?.lead?.leadOpportunities || []).find(
      item => +item.id === +appointment?.leadOpportunityId
    );
    let oppArr = [];
    if (!findOppr && appointment?.leadOpportunity?.id) {
      oppArr.push({
        id: String(appointment?.leadOpportunity.id),
        name: appointment?.leadOpportunity.name,
        opportunityStatus: {},
        eventId: String(appointment?.leadOpportunity.id),
        isSmai: false,
      });
    }
    if (appointment?.leadOpportunityId && findOppr) {
      const findOppr = (leadData?.lead?.leadOpportunities || []).find(
        item => +item.id === +appointment?.leadOpportunityId
      );

      if (
        findOppr?.dsOpportunityEvents?.length > 0 &&
        findOppr?.dsOpportunityEvents[0]?.dsEventId
      ) {
        oppArr = {
          id: String(appointment?.leadOpportunityId),
          name: findOppr?.name,
          isSmai: false,
          opportunityStatus: findOppr?.opportunityStatus,
          eventId: String(findOppr?.dsOpportunityEvents[0]?.dsEventId),
        };
        return [{ ...oppArr }];
      }

      if (
        findOppr?.sfLeadOpportunities?.length > 0 &&
        findOppr?.sfLeadOpportunities[0]?.sfOpportunityId
      ) {
        oppArr = {
          id: String(appointment?.leadOpportunityId),
          name: findOppr?.name,
          isSmai: false,
          opportunityStatus: findOppr?.opportunityStatus,
          eventId: String(findOppr?.sfLeadOpportunities[0]?.sfOpportunityId),
        };
        return [{ ...oppArr }];
      }
      oppArr = {
        id: String(findOppr?.id),
        name: findOppr?.name,
        isSmai: true,
        opportunityStatus: findOppr?.opportunityStatus,
        eventId: String(findOppr?.id),
      };
      return [{ ...oppArr }];
    } else if (leadData?.lead?.leadOpportunities.length > 0) {
      const oppIdArray = leadData?.lead?.leadOpportunities.map(opp => {
        if (opp?.dsOpportunityEvents?.length > 0 && opp?.dsOpportunityEvents[0]?.dsEventId) {
          return {
            id: String(opp?.id),
            name: opp?.name,
            isSmai: false,
            opportunityStatus: opp?.opportunityStatus,
            eventId: String(opp?.dsOpportunityEvents[0]?.dsEventId),
          };
        } else if (
          opp?.sfLeadOpportunities?.length > 0 &&
          opp?.sfLeadOpportunities[0]?.sfOpportunityId
        ) {
          return {
            id: String(opp?.id),
            name: opp?.name,
            isSmai: false,
            opportunityStatus: opp?.opportunityStatus,
            eventId: String(opp?.dsOpportunityEvents[0]?.sfOpportunityId),
          };
        } else {
          return {
            id: String(opp?.id),
            name: opp?.name,
            isSmai: true,
            opportunityStatus: opp?.opportunityStatus,
            eventId: String(opp?.id),
          };
        }
      });
      return [...oppArr, ...oppIdArray];
    } else if (lead?.crmLeadObj?.activeLeadOpportunity) {
      return [
        ...oppArr,
        {
          id: lead?.crmLeadObj?.activeLeadOpportunity.id,
          name: lead?.crmLeadObj?.activeLeadOpportunity.name,
          opportunityStatus: lead?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus,
          eventId: String(lead?.crmLeadObj?.activeLeadOpportunity?.id),
          isSmai: false,
        },
      ];
    } else {
      return oppArr;
    }
  };
  const handleCheckbox = e => {
    setIsAppoinmentCofirm(e.target.checked);
    e.target.checked && hadnleAppointmentDialog();
  };

  const handleAppointmentForm = () => {
    let appointmentStatus = "";
    let confirmedBy = "";

    if (appointment?.isConfirmed) {
      appointmentStatus = "confirmed";
    } else {
      appointmentStatus = appointment?.appointmentStatus.toLowerCase();
    }

    if (appointment?.isLastActionByLead) {
      confirmedBy = appointment?.lead?.fullName;
    } else {
      if (appointment?.updatedBy?.fullName) {
        confirmedBy = appointment?.updatedBy?.fullName.toLowerCase();
      } else {
        confirmedBy = appointment?.createdBy?.fullName.toLowerCase();
      }
    }
    // Mon, June 6th 2022, 8:35:20 AM: Rescheduled by <Lead Name>
    const lastTimeStamp = convertUtcToLocal(
      appointment?.dateUpdated,
      "ddd, MMMM Do YYYY, h:mm:ss A"
    );
    return confirmedBy
      ? `${lastTimeStamp}: ${
          appointmentStatus[0]?.toUpperCase() + appointmentStatus.slice(1, appointmentStatus.length)
        } by ${confirmedBy || ""}`
      : "";
  };
  const allowEdit = () => {
    return sessionStorage?.getItem("currentLocationId")?.includes(",");
  };

  useEffect(() => {
    if (user && user?.locations?.length > 0) {
      const locationIDs = user?.locations.map(location => location?.location?.id);
      setUserLocationsId(locationIDs);
    }
  }, [user]);
  useEffect(() => {
    if (
      !isUndefined(appointment?.id) &&
      appointment?.appointmentStatus !== "CANCELLED" &&
      appointment?.appointmentStatus !== "NO_SHOWED" &&
      appointment?.appointmentStatus !== "SHOWED" &&
      // dayjs(appointment?.startDatetime).utc().isBefore(dayjs().utc())
      dayjs(`${dayjs(appointment?.startDatetime).format().split("+")[0]}Z`).isBefore(
        dayjs().utc().format()
      )
    ) {
      setStatusDropdownList([
        ...statusDropdownList,
        { key: "SHOWED", value: "SHOWED", label: "Showed" },
        { key: "NO_SHOWED", value: "NO_SHOWED", label: "No Show" },
      ]);
    }
  }, [appointment]);

  const handleStatusUpdateLabel = e => {
    setDropdownStatus(e);
  };

  const handleOpportunityIdDialog = oppr => {
    // DS
    if (
      (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
      leadInfo?.crmLeadObj?.dsExtractedLead?.dsLeadId
    ) {
      const opprId = oppr?.dsOpportunityEvents[0]?.dsEventId
        ? String(oppr?.dsOpportunityEvents[0]?.dsEventId)
        : leadInfo?.crmLeadObj?.dsExtractedLead?.dsLeadId
        ? String(leadInfo?.crmLeadObj?.dsExtractedLead?.dsLeadId).padStart(4, "0")
        : oppr?.id;

      let opprName;
      if (
        (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
        leadInfo?.crmLeadObj?.dsExtractedLead?.dsLeadId
      ) {
        opprName =
          oppr &&
          oppr?.dsOpportunityEvents &&
          oppr?.dsOpportunityEvents.length > 0 &&
          oppr?.dsOpportunityEvents[0]?.dsEventId
            ? `Sales Opportunity - ${oppr?.name}`
            : oppr?.name;
      }

      const oppLink = oppr?.dsOpportunityEvents[0]?.dsEventId
        ? `https://bb.dealersocket.com/#/crm/sales/view/${leadInfo?.crmLeadObj?.dsExtractedLead?.dsLeadId}/${opprId}`
        : `https://bb.dealersocket.com/#/crm/view/contact/${opprId}`;
      const oppLinkType =
        userLocationsId?.length > 0 && userLocationsId.includes(leadInfo?.location?.id) ? (
          <div
            className="flex flex-col justify-start items-baseline h-[40px] cursor-pointer"
            onClick={() => handleOpportunityWarningDialog(oppr, oppLink, opprId, opprName)}
          >
            <p className="flex secondaryColorTheme flex-col justify-start items-baseline h-[40px] cursor-pointer">
              DealerSocket Opportunity ID {String(opprId).padStart(4, "0")}
            </p>
          </div>
        ) : (
          <div
            className="flex flex-col justify-start items-baseline h-[20px]"
            onClick={() => {
              message.error(
                `You do not have permissions to view opportunity in the ${leadInfo?.location?.title}`
              );
            }}
          >
            <p>DealerSocket Opportunity ID {String(opprId).padStart(4, "0")}</p>
          </div>
        );

      return oppLinkType;
    }

    // SF
    if (
      (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
      leadInfo?.crmLeadObj?.sfExtractedLead?.sfLeadId
    ) {
      const opprId = oppr?.sfLeadOpportunities[0]?.sfOpportunityId
        ? String(oppr?.sfLeadOpportunities[0]?.sfOpportunityId)
        : leadInfo?.crmLeadObj?.sfExtractedLead?.sfLeadId
        ? String(leadInfo?.crmLeadObj?.sfExtractedLead?.sfLeadId)
        : oppr?.id;

      const oppLink = oppr?.sfLeadOpportunities[0]?.sfOpportunityId
        ? `https://usautosales.lightning.force.com/lightning/r/Opportunity/${opprId}/view`
        : `https://usautosales.lightning.force.com/lightning/r/Lead/${opprId}/view`;

      const isOpp =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId;

      let opprName;
      if (
        (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
        leadInfo?.crmLeadObj?.sfExtractedLead?.sfLeadId
      ) {
        opprName = oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? oppr?.name
          : `Lead: ${leadInfo?.leadFullname}`;
      }

      const sfoppType =
        userLocationsId?.length > 0 && userLocationsId.includes(leadInfo?.location?.id) ? (
          <div
            className="flex flex-col justify-start items-baseline h-[40px] cursor-pointer"
            onClick={() => handleOpportunityWarningDialog(oppr, oppLink, opprId, opprName, isOpp)}
          >
            <p className="flex secondaryColorTheme flex-col justify-start items-baseline h-[40px] cursor-pointer">
              #{String(opprId).padStart(4, "0")}
            </p>
          </div>
        ) : (
          <div
            className="flex flex-col justify-start items-baseline h-[20px]"
            onClick={() => {
              message.error(
                `${lang.donthavePermission} ${isOpp ? "opportunity" : "lead"} in the ${
                  leadInfo?.location?.title
                }`
              );
            }}
          >
            <p>#{String(opprId).padStart(4, "0")}</p>
          </div>
        );

      return sfoppType;
    }

    return (
      <div className="flex flex-col justify-start items-baseline h-[20px]">
        <div> {!oppr?.id && <p className="mb-0">Lead: {leadInfo?.leadFullname}</p>}</div>
        <div>{oppr?.id && <p>#SMAI{String(oppr?.id).padStart(4, "0")}</p>}</div>
      </div>
    );
  };

  const handleOpportunityId = oppr => {
    // DS
    try {
      if (
        (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
        leadInfo?.crmLeadObj?.dsExtractedLead?.dsLeadId
      ) {
        const oppLink = `https://bb.dealersocket.com/#/crm/sales/view/${leadInfo?.crmLeadObj?.dsExtractedLead?.dsLeadId}/${oppr.leadOpportunities[0].dsOpportunityEvents[0].dsEventId}`;

        const oppLinkType =
          userLocationsId?.length > 0 && userLocationsId.includes(leadInfo?.location?.id) ? (
            <Tooltip
              title={`DealerSocket Opportunity ID
            ${String(oppr.leadOpportunities[0].dsOpportunityEvents[0].dsEventId).padStart(4, "0")}`}
            >
              {" "}
              <RedirectIcon className="cursor-pointer" onClick={() => window.open(oppLink)} />
            </Tooltip>
          ) : (
            <Tooltip
              title={`DealerSocket Opportunity ID
            ${String(oppr.leadOpportunities[0].dsOpportunityEvents[0].dsEventId).padStart(4, "0")}`}
            >
              <RedirectIcon
                className="cursor-pointer"
                onClick={() =>
                  message.error(
                    `You do not have permissions to view opportunity in the ${leadInfo?.location?.title}`
                  )
                }
              />
            </Tooltip>
          );

        return oppLinkType;
      }

      // SF
      if (
        (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
        leadInfo?.crmLeadObj?.sfExtractedLead?.sfLeadId
      ) {
        const opprId =
          oppr?.sfLeadOpportunities?.length && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
            ? String(oppr?.sfLeadOpportunities[0]?.sfOpportunityId)
            : leadInfo?.crmLeadObj?.sfExtractedLead?.sfLeadId
            ? String(leadInfo?.crmLeadObj?.sfExtractedLead?.sfLeadId)
            : oppr?.id;

        const oppLink =
          oppr?.sfLeadOpportunities?.length && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
            ? `https://usautosales.lightning.force.com/lightning/r/Opportunity/${opprId}/view`
            : `https://usautosales.lightning.force.com/lightning/r/Lead/${opprId}/view`;

        const isOpp =
          oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId;

        const sfoppType =
          userLocationsId?.length > 0 && userLocationsId.includes(leadInfo?.location?.id) ? (
            <Tooltip title={`#${String(opprId).padStart(4, "0")}`}>
              <RedirectIcon className="cursor-pointer" onClick={() => window.open(oppLink)} />
            </Tooltip>
          ) : (
            <Tooltip title={`#${String(opprId).padStart(4, "0")}`}>
              <RedirectIcon
                className="cursor-pointer"
                onClick={() =>
                  message.error(
                    `You do not have permissions to view ${isOpp ? "opportunity" : "lead"} in the ${
                      leadInfo?.location?.title
                    }`
                  )
                }
              />
            </Tooltip>
          );

        return sfoppType;
      }

      return (
        <div className="flex flex-col justify-start items-baseline h-[20px]">
          <div> {!oppr?.id && <p className="mb-0">Lead: {leadInfo?.leadFullname}</p>}</div>
          <div>{oppr?.id && <p>#SMAI{String(oppr?.id).padStart(4, "0")}</p>}</div>
        </div>
      );
    } catch (err) {
      console.log(err);
    }
  };
  const tags = [
    ...(dropdownStatus !== "SHOWED" && dropdownStatus !== "NO_SHOWED"
      ? [
          {
            title: "Cancelled",
            value: "CANCELLED",
            style: {
              backgroundColor: "#F43F5E",
              color: "white",
            },
          },
        ]
      : []),
    ...(dayjs(`${dayjs(appointment?.startDatetime).format().split("+")[0]}Z`).isBefore(
      dayjs().utc().format()
    )
      ? [
          {
            title: "Showed",
            value: "SHOWED",
            style: {
              backgroundColor: "#22C55E",
              color: "white",
            },
          },
          {
            title: "No Showed",
            value: "NO_SHOWED",
            style: {
              backgroundColor: "#334155",
              color: "white",
            },
          },
        ]
      : appointment.appointmentStatus === "SHOWED"
      ? [
          {
            title: "Showed",
            value: "SHOWED",
            style: {
              backgroundColor: "#22C55E",
              color: "white",
            },
          },
        ]
      : appointment.appointmentStatus === "NO_SHOWED"
      ? [
          {
            title: "No Showed",
            value: "NO_SHOWED",
            style: {
              backgroundColor: "#334155",
              color: "white",
            },
          },
        ]
      : []),
  ];

  const opporlist =
    whileCreateNewOppr?.length > 0 ? whileCreateNewOppr : handleLeadOpportunityList();

  return (
    <>
      {error && (
        <div className="w-full">
          <CustomAlert message={error} type="error" className="p-2" showIcon />
        </div>
      )}
      <div className="bg-[#f1f5f9] p-4 rounded-[8px] flex flex-col gap-3">
        <div className="flex flex-row items-center justify-start gap-6">
          <div className="w-[35%] flex flex-col gap-2">
            {/* <CustomLabel label={`${lang.appointmentFor}`} labelClass="text-sm" /> */}
            <span className="text-sm">{lang.appointmentFor}</span>

            {!isEmpty(lead) ? (
              <div className="flex flex-row items-center gap-1">
                <CustomLabel
                  label={`${appointment?.lead?.fullName || lead?.fullName}`}
                  labelClass="my-1 font-medium secondaryColorTheme cursor-pointer"
                  onClick={() => {
                    if (redirect) {
                      setAppointmentModelVisible(false, false);
                      redirect(
                        true,
                        appointment?.startDatetime || "",
                        true,
                        appointment?.lead?.id || lead?.id,
                        true
                      );
                      onClose();
                    }
                  }}
                />
                {allowLeadChange && showLeadChanger && isUndefined(appointment?.id) && (
                  <CloseCircleFilled className="cursor-pointer" onClick={() => setlead(null)} />
                )}
              </div>
            ) : (
              <Select
                placeholder={lang.selectLead}
                showSearch
                onSearch={value => setTimeout(() => setleadSearch(value), 200)}
                loading={leadsLoader}
                className="w-full"
                onSelect={value => {
                  const lead = leadList.find(lead => +lead.id === +value);
                  console.log(lead);
                  setlead(lead);
                  setLeadOpportunity(lead?.crmLeadObj?.activeLeadOpportunity?.id);
                }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {(leadList || []).map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <div className="flex flex-col gap-2 mt-[-8px]">
            <span className="text-sm">Created By</span>
            <span className="text-sm font-[600]">
              {appointment?.createdBy?.fullName || user.fullName}
            </span>
          </div>
          <div className="ml-auto">
            {leadInfo?.crmLeadObj?.activeLeadOpportunity &&
              (localStorage.getItem("isOpporDialogFlagChangedLocal")
                ? handleOpportunityId(leadInfo?.crmLeadObj?.activeLeadOpportunity)
                : !handleOpportunityDilaog(user?.locations, user)
                ? handleOpportunityId(lead)
                : handleOpportunityIdDialog(leadInfo?.crmLeadObj?.activeLeadOpportunity))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-6">
          <div className="flex w-[35%] flex-col gap-2">
            <span className="text-sm">Location</span>
            <span className="text-sm font-[600]">{getLocationObj(user)?.location?.title}</span>
          </div>
          {!isUndefined(appointment?.id) && (
            <div className="flex flex-col gap-2">
              <span className="text-sm flex items-center gap-2">
                Status{" "}
                <UndoOutlined
                  className="cursor-pointer"
                  onClick={() => {
                    setDropdownStatus(() => {
                      if (lead?.activeAppointment?.appointmentStatus && isFromCard) {
                        return lead?.activeAppointment?.appointmentStatus;
                      }
                      if (appointment?.appointmentStatus) {
                        return appointment?.appointmentStatus;
                      }
                    });
                    setstatusEditMode(false);
                  }}
                />
              </span>
              {statusEditMode ? (
                <Select
                  disabled={disabledControl() || allowEdit()}
                  size="small"
                  value={dropdownStatus}
                  className="w-[150px]"
                  onSelect={value => {
                    handleStatusUpdateLabel(value);
                    setstatusEditMode(false);
                  }}
                >
                  {tags.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <span className="text-xs font-[600] text-grey-700 underline decoration-dotted">
                    {dropdownStatus}{" "}
                  </span>
                  <EditFilled
                    style={{
                      color: disabledControl() || allowEdit() ? "grey" : "black",
                    }}
                    className={`${
                      disabledControl() || allowEdit() ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={e => {
                      e.stopPropagation();
                      if (disabledControl() || allowEdit()) return;
                      setstatusEditMode(true);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!isEmpty(lead) && (
        <>
          <div className="w-full flex gap-2 my-1 items-center">
            <p className="font-normal">
              {lang.leadOpportunities} <span className="text-red-500">*</span>
            </p>
            {/* <p className="font-normal"></p> */}
          </div>
          <div className="w-full flex items-start gap-2" id="antdOpprDropdown">
            <Select
              className="flex-1 w-3/4 lg:w-full"
              value={String(leadOpportunity)}
              placeholder={lang.selectOpportunity}
              onChange={value => setLeadOpportunity(value)}
              // getPopupContainer={() => document.getElementById("antdOpprDropdown")}
              disabled={
                appointment?.id ||
                isEmpty(leadOpportunities) ||
                isEmpty(leadOpportunity) ||
                disabledControl() ||
                allowEdit()
              }
            >
              {(opporlist || []).map(opportunity => (
                <Select.Option key={opportunity?.id} value={opportunity?.id}>
                  {opportunity?.name} ({opportunity?.isSmai && "#SMAI"}
                  {String(opportunity?.eventId).padStart(3, "0")} /{" "}
                  {opportunity?.opportunityStatus?.parentStatus?.name} -{" "}
                  {opportunity?.opportunityStatus?.name || "Unknown"})
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="w-full grid grid-cols-3 gap-1 mt-1">
            <div>
              <div className="flex-1" id="antdDateDropdown">
                <p className="font-normal">
                  {lang.appointmentDate} <span className="requireFieldStar">*</span>
                </p>
                <DatePicker
                  format="MM/DD/YYYY"
                  getPopupContainer={() => document.getElementById("antdDateDropdown")}
                  value={dayjs.utc(appointmentDate).tz(appointment?.timezone || dayjs.tz.guess())}
                  placeholder={lang.selectDate}
                  onChange={onChange}
                  className="w-full"
                  disabledDate={current => {
                    return (
                      dayjs()
                        .tz(appointment?.timezone || dayjs.tz.guess())
                        .add(-1, "days") >= current || disableWeekDay(current)
                    );
                  }}
                  disabled={disabledControl() || allowEdit()}
                />
              </div>
            </div>
            <div>
              {" "}
              <div className="flex-1" id="antdTimeDropdown">
                <p className="font-normal">
                  {lang.appointmentTime} <span className="requireFieldStar">*</span>
                </p>
                <CustomTimePicker
                  fkey="appointment_time"
                  value={appointmentTime}
                  startTime={workingTime && workingTime[0]}
                  endTime={workingTime && workingTime[1]}
                  onChange={e => onTimeChange(e)}
                  placeholder={lang.selectTime}
                  getPopupContainer={() => document.getElementById("antdTimeDropdown")}
                  disabledTimes={disabledHours}
                  disabled={disabledControl() || allowEdit()}
                  defaultDate={appointmentDate}
                />
              </div>
            </div>
            <div>
              {" "}
              <div className="w-full" id="antdTimezoneDropdown">
                <p className="font-normal">
                  {lang.timezone} <span className="requireFieldStar">*</span>{" "}
                  <Tooltip
                    placement="topLeft"
                    title="This is the lead’s timezone, not the sales person’s timezone."
                  >
                    <span>
                      <AntdIconV4
                        type="QuestionCircleOutlined"
                        className="text-gray-500 ml-[5px]"
                      />
                    </span>
                  </Tooltip>
                </p>
                <Select
                  className="w-full"
                  value={timezone}
                  getPopupContainer={() => document.getElementById("antdTimezoneDropdown")}
                  placeholder={lang.selectTimezone}
                  onChange={value => setTimezone(value)}
                  disabled={disabledControl() || allowEdit()}
                >
                  {timezones.map(timezone => (
                    <Select.Option key={timezone.value} value={timezone.value}>
                      {timezone.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          <div className="mt-1" id="antdAssignedDropdown">
            <p className="font-normal">
              {lang.assignedTo} <span className="requireFieldStar">*</span>
            </p>

            <UserSelector
              placeholder="Select Assigned User"
              handleUsersSelection={value => setAssignedUserId(value)}
              value={+assignedUserId}
              multiple={false}
              showAll={false}
              item={{ item: { user: appointment.createdBy } }}
            />
          </div>

          <div className="mt-1">
            <p className="font-normal">
              {lang.title} <span className="requireFieldStar">*</span>
            </p>
            <Input
              placeholder={lang.appointmentTitle}
              name="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
              disabled={disabledControl() || allowEdit()}
            />
          </div>

          <div className="mt-1">
            <p className="font-normal">{lang.description}</p>
            <TextArea
              rows={2}
              placeholder={lang.descriptionPlaceholder}
              name="note"
              value={note}
              onChange={e => setNote(e.target.value)}
              disabled={disabledControl() || allowEdit()}
            />
          </div>

          {vehiclesOfInterests.length > 0 && (
            <div className="mt-1" id="antdVOIDropdown">
              <p className="font-normal">{lang.vehicleOfInterest}</p>
              <Select
                className="w-full"
                value={String(vehicle)}
                placeholder={lang.selectVehicle}
                getPopupContainer={() => document.getElementById("antdVOIDropdown")}
                onChange={value => setVehicle(value)}
                disabled={disabledControl() || allowEdit()}
              >
                {vehiclesOfInterests?.map(el => {
                  let voi = "";
                  if (
                    !isUndefined(el?.year) &&
                    el?.year !== 0 &&
                    el?.year !== null &&
                    el?.year !== undefined
                  )
                    voi = `${voi}${el.year} `;
                  if (!isUndefined(el?.make) && el?.make !== null && el?.make !== undefined)
                    voi = `${voi}${el.make} `;
                  if (!isUndefined(el?.model) && el?.model !== null && el?.model !== undefined)
                    voi = `${voi}${el.model} `;
                  return voi !== "" && +voi !== 0 ? (
                    <Select.Option key={el?.id} value={String(el?.id)}>
                      {voi}
                    </Select.Option>
                  ) : (
                    <Select.Option key={el?.id} value={String(el?.id)}>
                      No vehicles
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          )}

          <div className="mt-1">
            <CustomCheckbox
              checkboxLabel={
                <strong className={`text-[${disabledControl() ? "" : "blue"}]`}>
                  {lang.confirm}
                </strong>
              }
              checked={isAppoinmentConfirm}
              disabled={
                disabledControl() ||
                (appointment?.id && isChangedByUser && isAppoinmentConfirm) ||
                !canSchedule ||
                (!canReSchedule && appointment) ||
                allowEdit()
              }
              handleChange={e => handleCheckbox(e)}
            />

            {!isUndefined(appointment?.id) && <p className="mt-[2px]">{handleAppointmentForm()}</p>}
          </div>
        </>
      )}

      <div className="bg-[#faf8fc] mt-3 p-4 flex flex-col gap-3">
        <div className="w-full flex items-center justify-end">
          <FooterComponent
            lang={lang}
            appointment={appointment}
            setVisible={() => {
              setAppointmentModelVisible(false, false);
              onClose();
            }}
            saveAppointment={handleSaveAppointment}
            deleteAppointment={handleDeleteAppointment}
            loading={loading || updateLoading}
            deleteLoading={deleteLoading}
            canSchedule={canSchedule}
            canReSchedule={canReSchedule}
            canCancel={canCancel}
            isAppoinmentConfirm={isAppoinmentConfirm}
            allowEdit={allowEdit}
            isChangedByUser={isChangedByUser}
            hadnleAppointmentDialog={hadnleAppointmentDialog}
            disabledControl={disabledControl}
            dropdownStatus={dropdownStatus}
            onClose={onClose}
          />
        </div>
      </div>
    </>
  );
};
export default AppointmentForm;

const FooterComponent = ({
  appointment = null,
  setVisible,
  saveAppointment,
  deleteAppointment,
  loading = false,
  deleteLoading = false,
  canSchedule,
  canReSchedule,
  canCancel,
  isAppoinmentConfirm,
  allowEdit,
  isChangedByUser,
  hadnleAppointmentDialog,
  disabledControl,
  dropdownStatus,
  onClose,
  lang,
}) => {
  const [status, setStatus] = useState(undefined);
  const handleStatusUpdate = e => {
    saveAppointment({ status: e });
  };

  const handleAppointmentStatus = e => {
    if (dropdownStatus === "CANCELLED") {
      if (
        !isUndefined(appointment?.id) &&
        appointment?.appointmentStatus !== "CANCELLED" &&
        appointment?.appointmentStatus !== "NO_SHOWED" &&
        appointment?.appointmentStatus !== "SHOWED" &&
        dayjs(dayjs(appointment?.startDatetime).utc().local().format("MM/DD/YYYY")).isAfter(
          dayjs().utc().local().format("MM/DD/YYYY")
        )
      ) {
        deleteAppointment(e);
        return;
      } else {
        saveAppointment({ status: dropdownStatus });
        return;
      }
    } else if (dropdownStatus === "SHOWED" || dropdownStatus === "NO_SHOWED") {
      saveAppointment({ status: dropdownStatus });
      return;
    }

    saveAppointment(e);
  };

  const buttons = [
    <CustomButton
      key="back"
      handleClick={() => {
        setVisible(false);
        onClose();
      }}
      className="ml-2"
      btnText={lang.close}
    />,
  ];

  if (
    appointment?.appointmentStatus !== "CANCELLED" &&
    appointment?.appointmentStatus !== "NO_SHOWED" &&
    appointment?.appointmentStatus !== "SHOWED"
  ) {
    buttons.unshift(
      <CustomButton
        key="submit"
        type="primary"
        handleClick={handleAppointmentStatus}
        loading={loading || deleteLoading}
        disabled={!canSchedule || (!canReSchedule && appointment) || allowEdit()}
        btnText="Save"
      />
    );
  }

  return buttons;
};
