import { useSelector } from "react-redux";
import { getStatements } from "../redux/slices/languageSlice";

const useLang = () => {
  const lang = useSelector(state => getStatements(state));

  return [lang];
};

export default useLang;
