/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
import { UpOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Popover, Tooltip, message } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CREATE_SCHEDULED_MESSAGE } from "../../../graphql/mutation";
import SendIcon from "../../../icons/js/SendIcon";
import { colorConfig } from "../../../library/colorConfig";
import { openDialog } from "../../../library/helpers";
import { addScheduledMessage } from "../../../redux/slices/leadSlice";
import AntdIconV4 from "../../../utils/antdIcon";
import ChatMagic from "./chatMagic";
import "./editor.css";
import { InputTextAreaWrap, handleFiles } from "./engagementActivity";
import EmojiPicker from "./mediaSharing/emojiPicker";
import RichTextEditor from "./richTextEditor";
import { SVGIcon } from "../../../library/basicComponents";
import TranslateIcon from "../../../../static/icons/TranslateIcon.svg";

export function InputArea({
  textMode,
  buttonStyle,
  text,
  selectedLead,
  handleKeyDown,
  handleKeyUp,
  textDisabled,
  messageLoader,
  loading,
  spinner,
  leadsLoading,
  lang,
  setmessageText,
  placeholder,
  messageText,
  richTextValue,
  setrichTextValue,
  renderConsentNote,
  afterMessageFromRedux,
  handleSetDefinedTemplate,
  scheduledMessages,
  getMessages,
  user,
  tooltipTitle,
  buttonDisabled,
  mmsLoader,
  attachments,
  setmmsLoader,
  fetchData,
  setMediaUploadLoader,
  handleMMS,
  onlyFirstMessage,
  afterMessageConsent,
  onClickHandler,
  setCallConsentMessage,
  sendConsentMessage,
  translateMessage,
  originalText,
  setOriginalText,
  isTranslatePreviewShow,
  setisReview,
}) {
  const dispatch = useDispatch();
  const schedulePopupRef = useRef();
  const [createScheduledMessage, { loading: createScheduledMessageLoader }] =
    useMutation(CREATE_SCHEDULED_MESSAGE);

  const handleQuickSchedule = async date => {
    try {
      if (text.current.value === "") {
        return message.error("Please enter a message first");
      }
      const obj = {
        leadId: selectedLead?.id,
        message: text.current.value,
        scheduledDate: date,
        timezone: dayjs.tz.guess(),
      };
      const res = await createScheduledMessage({
        variables: { ...obj },
      });
      if (res.data.createScheduledMessage.statusCode === 200) {
        text.current.value = "";
        text.current.style.height = "0px";
        setmessageText("");
        message.success(res.data.createScheduledMessage.message);
        getMessages();
        dispatch(addScheduledMessage(res.data.createScheduledMessage.scheduledMessage));
      } else {
        message.error(res.data.createScheduledMessage.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const sendMenu = () => {
    return (
      <div className="flex flex-col items-start gap-2" onClick={e => e.stopPropagation()}>
        <span className="text-sm text-[#64748B] font-[400] ">Schedule Messages</span>
        <span
          className="text-[16px] font-[400] text-grey-700 cursor-pointer "
          onClick={() => {
            handleQuickSchedule(
              dayjs()
                .add(1, "day")
                .set("hour", 9)
                .set("minute", 0)
                .set("second", 0)
                .format("YYYY-MM-DDTHH:mm:ss")
            );
            schedulePopupRef.current.click();
          }}
        >
          Tomorrow at 9:00 AM
        </span>
        <span
          onClick={() => {
            handleQuickSchedule(
              dayjs()
                .day(8)
                .set("hour", 9)
                .set("minute", 0)
                .set("second", 0)
                .format("YYYY-MM-DDTHH:mm:ss")
            );
            schedulePopupRef.current.click();
          }}
          className="text-[16px] font-[400] text-grey-700 cursor-pointer "
        >
          Monday at 9:00 AM
        </span>
        <span
          onClick={() => {
            openDialog("scheduleMessage", {
              messageData: {
                message: textMode === 0 ? text.current.value : richTextValue,
                taskSendDatetime: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
              },
              mode: "ADD",
              dialogHeader: "Schedule Message",
              scheduledMessages,
              selectedLead,
              getMessages,
              setmessageText,
              reset: () => {
                const resetText = () => {
                  text.current.value = "";
                };
                if (textMode === 0) {
                  resetText();
                } else {
                  setrichTextValue("");
                }
              },
            });
            schedulePopupRef.current.click();
          }}
          className="text-[16px] font-[400] text-grey-700 cursor-pointer"
        >
          Select custom date & time
        </span>

        {scheduledMessages.length > 0 && (
          <>
            <div className="h-[1px] w-full bg-[#CBD5E1]" />
            <span
              className="cursor-pointer text-[16px] font-[500] text-brand-500"
              onClick={() =>
                openDialog("viewScheduledMessages", {
                  dialogHeader: `${lang.viewSchMsgPopupTitlePrefix}${selectedLead.fullName}`,
                  scheduledMessages,
                  getMessages,
                  width: "50vw",
                  selectedLead,
                  messageText,
                })
              }
            >
              Scheduled Messages ({scheduledMessages.length})
            </span>
          </>
        )}
      </div>
    );
  };
  useEffect(() => {
    if (createScheduledMessageLoader) message.loading("Scheduling Message...");
  }, [createScheduledMessageLoader]);

  useEffect(() => {
    if (!messageText) {
      setOriginalText("");
    }
  }, [messageText]);
  const [inputText, setinputText] = useState("");
  const [loaderFn, setloaderFn] = useState(false);

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <div style={{ display: "flex", flexGrow: "1" }} className="relative">
        {textMode === 0 && (
          <InputTextAreaWrap
            placeholderStyle={{
              color: buttonStyle()?.background === "#F59E0B" ? colorConfig.primaryDark : "",
            }}
            styling={buttonStyle()}
            className="relative pr-[140px]  pl-10 flex items-center rounded-[4px] pt-5 pb-5"
            id="text"
            ref={text}
            key={`msg_box_${selectedLead?.id}`}
            rows={1}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            disabled={
              textDisabled() ||
              !selectedLead?.id ||
              messageLoader ||
              loading ||
              spinner ||
              leadsLoading ||
              loaderFn
            }
            onChange={e => {
              if (e.target.value === "") {
                text.current.placeholder = lang.typeYourMessageHere;
                text.current.value = "";
                text.current.style.height = "0px";
                setisReview(false);
              }
              e.target.style.height = `${e.target.scrollHeight}px`;
              setinputText(e.target.value);
            }}
            placeholder={placeholder()}
            // value={inputText}
            // onChange={e => setmessageText(e.target.value)}
            name="text"
            maxLength={1000}
          />
        )}
        {textMode === 1 && (
          <RichTextEditor
            value={richTextValue}
            setValue={setrichTextValue}
            key={`rich_msg_box_${selectedLead?.id}`}
          />
        )}
        {textMode === 0 && buttonStyle().background !== "#EF4444" && (
          <span className="absolute italic text-gray-400 text-[12px] top-1 left-5">
            {renderConsentNote() && !loading && !leadsLoading && (
              <>
                <strong>({afterMessageFromRedux?.content})</strong> {lang.addedAtTheEndOfMsg}
              </>
            )}
          </span>
        )}
      </div>
      {textMode !== 2 && (
        <Popover
          content={
            <EmojiPicker
              key={`emjoi_picker_${selectedLead?.id}`}
              textMode={textMode}
              handleSetDefinedTemplate={handleSetDefinedTemplate}
              text={text}
              messageText={messageText}
            />
          }
          // open={isEmojiOpen}
          trigger="click"
        >
          <Tooltip title="Add Emoji">
            <Button type="text" disabled={textDisabled()} className="absolute left-0.5 bottom-3.5">
              <AntdIconV4
                type="SmileOutlined"
                key={`emoji_icon_${selectedLead?.id}`}
                className={`text-grey-700 text-[18px] ${
                  textDisabled() ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              />
            </Button>
          </Tooltip>
        </Popover>
      )}
      {textMode !== 2 && (
        <div className="flex absolute right-3 bottom-3 items-center gap-1 justify-center">
          {isTranslatePreviewShow && (
            <Tooltip title={originalText ? "Back to original text" : "Translate text"}>
              <spna
                onClick={() => {
                  if (!(text.current?.value.length > 0)) {
                    message.info("Please enter message to translate.");
                    return;
                  }
                  if (text.current?.value && !originalText) {
                    setOriginalText(text.current.value);
                    translateMessage({
                      variables: {
                        content: text.current?.value,
                        targetLanguage: selectedLead?.preferredLanguageCode,
                      },
                      onCompleted: res => {
                        if (res?.translateMessage?.statusCode === 200) {
                          setmessageText(res?.translateMessage?.translatedContent);
                          text.current.value = res?.translateMessage?.translatedContent;
                          message.info(res?.translateMessage?.message);
                        } else {
                          message.info(res?.translateMessage?.message);
                        }
                      },
                    });
                    return;
                  }
                  setmessageText(originalText);
                  text.current.value = originalText;
                  setOriginalText("");
                }}
              >
                <SVGIcon component={TranslateIcon} alt="Close" />
              </spna>
            </Tooltip>
          )}
          <ChatMagic
            text={text}
            key={`otto_comp_${selectedLead?.id}`}
            richTextValue={richTextValue}
            setRichTextValue={setrichTextValue}
            selectedLead={selectedLead}
            user={user}
            placeholder={placeholder}
            setmessageText={setinputText}
            displaySize="small"
            setloaderFn={setloaderFn}
            code="ENGAGEMENT_MESSAGE_SUGGESTION"
            messageText={messageText}
            disabled={
              textDisabled() ||
              !selectedLead?.id ||
              messageLoader ||
              loading ||
              spinner ||
              leadsLoading
            }
            loaderFn={loaderFn}
            setinputText={setinputText}
          />
          <Button
            // shape="circle"
            size="middle"
            style={{ ...buttonStyle(), padding: "5px" }}
            key={`attach_btn_${selectedLead?.id}`}
            loading={messageLoader || loading || spinner || leadsLoading || loaderFn || mmsLoader}
            disabled={
              buttonDisabled() ||
              !selectedLead?.id ||
              messageLoader ||
              loading ||
              spinner ||
              leadsLoading ||
              textMode !== 0 ||
              loaderFn
            }
          >
            <div className="flex flex-row items-center gap-2">
              {!(
                textDisabled() ||
                !selectedLead?.id ||
                messageLoader ||
                loading ||
                spinner ||
                leadsLoading ||
                loaderFn
              ) && (
                <>
                  {isEmpty(inputText) ? (
                    <Tooltip title="Schedule a message">
                      <span>
                        <UpOutlined
                          className="text-[#f1f1f1] cursor-not-allowed"
                          onClick={e => e.stopPropagation()}
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Schedule a message">
                      <span>
                        <Popover trigger="click" content={sendMenu()} showArrow>
                          <UpOutlined
                            ref={schedulePopupRef}
                            className="text-white"
                            onClick={e => e.stopPropagation()}
                          />
                        </Popover>
                      </span>
                    </Tooltip>
                  )}{" "}
                  <div className="h-[20px] w-[1px] bg-white" />
                </>
              )}
              <Tooltip title={tooltipTitle()}>
                <span
                  onClick={async () => {
                    if (mmsLoader) {
                      return message.info("Please wait while we complete the current process");
                    }
                    let mmsArr = [];
                    if (attachments.length > 0) {
                      setmmsLoader(true);
                      mmsArr = await handleFiles(
                        attachments,
                        user,
                        selectedLead,
                        fetchData,
                        setMediaUploadLoader
                      );
                    }
                    if (mmsArr.length > 0) {
                      return handleMMS(mmsArr);
                    }
                    if (!textDisabled()) {
                      if (
                        isEmpty(messageText.trim()) &&
                        isEmpty(textMode === 0 ? text.current.value : richTextValue)
                      ) {
                        return message.error(lang.pleaseEnterMessageToSend);
                      }
                      setmessageText(textMode === 0 ? text.current.value : richTextValue);
                      let msg;
                      if (onlyFirstMessage) {
                        if (selectedLead?.messages?.length > 0) {
                          msg = text.current?.value.trim() || messageText.trim();
                        } else {
                          msg =
                            afterMessageConsent !== ""
                              ? `${
                                  textMode === 0 ? text.current.value.trim() : richTextValue.trim()
                                } ${afterMessageConsent}`
                              : textMode === 0
                              ? text.current.value.trim()
                              : richTextValue.trim();
                        }
                      } else {
                        msg =
                          afterMessageConsent !== ""
                            ? `${
                                textMode === 0 ? text.current.value.trim() : richTextValue.trim()
                              } ${afterMessageConsent}`
                            : textMode === 0
                            ? text.current.value.trim()
                            : richTextValue.trim();
                      }
                      onClickHandler(msg, selectedLead, user?.id);
                    } else {
                      setCallConsentMessage(true);
                      sendConsentMessage();
                    }
                  }}
                >
                  {!(
                    messageLoader ||
                    loading ||
                    spinner ||
                    leadsLoading ||
                    loaderFn ||
                    mmsLoader
                  ) && <SendIcon color="white" className="text-[12px]" />}
                </span>
              </Tooltip>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
}
