import { useLazyQuery, useMutation } from "@apollo/client";
import { Avatar, List, Skeleton, Tag, Tooltip, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { REMOVE_LEAD_FROM_CAMPAIGN } from "../../../../graphql/mutation";
import { GET_CAMPAIGN_BY_LEAD } from "../../../../graphql/query";
import { openDialog } from "../../../../library/helpers";
// eslint-disable-next-line import/no-named-as-default-member
import useLang from "../../../../hooks/useLang";
import { setLoaderStatus } from "../../../../redux/slices/loaderSlice";
import AntdIconV4 from "../../../../utils/antdIcon";
import LeadCampaignViewModal from "./leadCampaignViewModal";

const ListContainer = styled.div`
  & .ant-list-item-meta {
    text-align: left;
    margin-bottom:0
  }
  & .ant-list-item-meta-title{
    font-size:16px;
    margin-bottom:0
  }
  & .ant-list-item-meta-description > p{
    margin:bottom:0.4rem;
  }
  & .ant-list-item-meta-content {
    margin:bottom:0;
    padding-top:0 !important;
  }
`;

const CampignListView = ({
  showLinks = false,
  onMessageFilter = null,
  refetchLeads = () => {},
  onClose = () => {},
  handleLeadDetailProfile = () => {},
}) => {
  const [lang] = useLang();
  const { selectedLead } = useSelector(state => state.lead);

  const dispatch = useDispatch();

  const [variables, setVariables] = useState({
    page: 1,
    pageSize: 25,
    leadId: +selectedLead?.id,
  });
  const [openCampaignPopup, setOpenCampaignPopup] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [getCampaign, { data, loading }] = useLazyQuery(GET_CAMPAIGN_BY_LEAD);

  const [removeLeadFromCampaign, { loading: removeLeadLoading }] =
    useMutation(REMOVE_LEAD_FROM_CAMPAIGN);

  useEffect(() => {
    setVariables({ ...variables, leadId: +selectedLead?.id });
    getCampaign({ variables: { ...variables, leadId: +selectedLead?.id } });
  }, [selectedLead?.id]);

  const count = data?.getCampaignByLead?.count || 0;
  const onTitleClick = item => {
    setOpenCampaignPopup(true);
    setSelectedCampaign(item);
  };

  const onRemoveLead = item => {
    openDialog("confirmDialog", {
      label: lang.doYouWantToContinue,
      content: lang.clickOnOK,
      okBtnType: "danger",
      loading: false,
      confirmBtnText: lang.yes,
      cancelBtnText: lang.no,
      isOKGhost: true,
      icon: <AntdIconV4 className="text-yellow-400 text-2xl" type="ExclamationCircleFilled" />,
      handleConfirm: async () => {
        dispatch(setLoaderStatus({ open: true }));
        try {
          const resp = await removeLeadFromCampaign({
            variables: {
              campaignId: parseInt(item.id),
              leadIds: [+selectedLead?.id],
            },
          });
          if (resp?.data?.removeLeadsFromCampaign?.statusCode === 200) {
            getCampaign();
            refetchLeads();
            onClose();
          } else {
            message.error(lang.somethingWentWrong);
          }
        } catch (err) {
          console.log("Error in onRemoveLead", err);
          message.error(lang.somethingWentWrong);
        } finally {
          dispatch(setLoaderStatus({ open: false }));
        }
      },
    });
  };
  return (
    <ListContainer>
      <Skeleton loading={loading || removeLeadLoading} active avatar>
        <List
          itemLayout="vertical"
          key="lead_grid_view"
          bordered
          dataSource={data?.getCampaignByLead?.data || []}
          pagination={
            count < 5
              ? null
              : {
                  defaultCurrent: variables.page,
                  current: variables.page,
                  defaultPageSize: variables.pageSize,
                  pageSize: variables.pageSize,
                  total: count || 0,
                  showTotal: total =>
                    `${lang.total}: ${total} ${
                      total === 1 ? lang.campaign.toLowerCase() : lang.campaigns.toLowerCase()
                    }`,
                  pageSizeOptions: ["5", "25", "50"],
                  showSizeChanger: true,
                  onChange: (page, pageSize) => {
                    const newVariables = {
                      ...variables,
                      page: page,
                      pageSize: pageSize,
                    };
                    setVariables(newVariables);
                  },
                  onShowSizeChange: (current, size) => {
                    const newVariables = {
                      ...variables,
                      page: current,
                      pageSize: size,
                    };
                    setVariables(newVariables);
                  },
                }
          }
          renderItem={item => {
            const links = [];
            if (showLinks) {
              links.push(
                <div className="labelColor cursor-pointer" onClick={() => onTitleClick(item)}>
                  <Tooltip placement="topLeft" title={lang.viewCampaignDetails}>
                    {lang.details}
                  </Tooltip>
                </div>
              );
              links.push(
                <div className="labelColor cursor-pointer" onClick={() => onMessageFilter(item.id)}>
                  <Tooltip placement="topLeft" title={lang.filterMsgByCampaign}>
                    {lang.message}
                  </Tooltip>
                </div>
              );
              links.push(
                <div
                  className="labelColor cursor-pointer"
                  onClick={e => {
                    e.stopPropagation();
                    onRemoveLead(item);
                  }}
                >
                  <Tooltip placement="topLeft" title={lang.removeLeadFromCampaign}>
                    {lang.remove}
                  </Tooltip>
                </div>
              );
            }
            return (
              <List.Item actions={links}>
                <List.Item.Meta
                  avatar={
                    <Avatar className="bg-gray-400" size={40}>
                      {item ? (item.name ? item.name.substring(0, 1).toUpperCase() : "C") : "C"}
                    </Avatar>
                  }
                  title={
                    <div className="flex items-center justify-between">
                      <Typography variant="small" weight="medium">
                        {(item?.name || "").toUpperCase()}
                      </Typography>
                      {!showLinks && (
                        <div>
                          <Tooltip placement="topLeft" title={lang.removeLeadFromCampaign}>
                            <AntdIconV4
                              type="DeleteOutlined"
                              className="m-[4px] text-[18px] text-red-500"
                              onClick={e => {
                                e.stopPropagation();
                                onRemoveLead(item);
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  }
                  description={
                    item.activeInd.toLowerCase() === "active" ? (
                      <Tag color="green">{lang.active}</Tag>
                    ) : (
                      <Tag color="gray">{lang.inactive}</Tag>
                    )
                  }
                />
              </List.Item>
            );
          }}
        />
      </Skeleton>
      {openCampaignPopup && (
        <LeadCampaignViewModal
          campaign={{ item: selectedCampaign }}
          visible={openCampaignPopup}
          onCancel={() => setOpenCampaignPopup(false)}
          handleLeadDetailProfile={handleLeadDetailProfile}
        />
      )}
    </ListContainer>
  );
};

export default CampignListView;
