/* eslint-disable no-useless-escape */
/* eslint-disable no-else-return */
import "react-phone-number-input/style.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import { Col, Collapse, Input, message, Row, Select, Spin } from "antd";
import { UserContext } from "../../../../amplify/authenticator-provider";
import { USStates } from "../../../../utils/usStates";
import {
  closeDialog,
  filterAndSortingData,
  showConfirmationModal,
} from "../../../../library/helpers";
import { GET_ALL_VEHICLES } from "../../../../graphql/query";
import { PUSH_DEAL_TO_CRM } from "../../../../graphql/mutation";
import CustomAlert from "../../../commonComponents/alert";
import { Countries } from "../../../../utils/countries";
import { FormField } from "../../leadEditModal";
import AntdIconV4 from "../../../../utils/antdIcon";
import DialogFooter from "../../../commonComponents/dialogFooter";
import CustomLabel from "../../../commonComponents/label";
import AddVehicleForm from "../../../life-events/customerList/AddVehicle";
import VehicleIntentory from "../../../life-events/customerList/VehicleInventory";
import useLang from "../../../../hooks/useLang";
import { toTitleCase } from "../../../../utils";

const PhoneDiv = styled.div`
  input {
    border: 1px solid #d9d9d9;
    width: 373px;
    padding: 5px;
    border-radius: 4px;
  }
`;

const MODAL_TITLE = `Do you want to continue?`;
const MODAL_CONTENT = `When clicked the OK button, it cannot be recovered`;

export const getAuthor = (conversation, selectedLead, users) => {
  let author = `${selectedLead.firstName} ${selectedLead.lastName}`;
  if (conversation?.dateSent) {
    author = selectedLead?.systemUserId
      ? "Otto"
      : users
      ? users[conversation?.userId] || "Otto"
      : "Me";
  }
  return author;
};

const PushToCrmModal = props => {
  const { pushToCrmModalVisible, setPushToCrmModalVisible, conversations, selectedLead, users } =
    props;

  const [lang] = useLang();

  const { user } = useContext(UserContext);
  const combinedConversation = conversations.reduce((accumulator, conversation) => {
    const author = getAuthor(conversation, selectedLead, users);
    const postTime = conversation?.dateSent ? conversation?.dateSent : conversation?.dateReceived;
    return `${accumulator}${
      accumulator != null && !isEmpty(accumulator) ? "\n" : ""
    }[${author} @ ${dayjs(postTime).format("HH:mm MM.DD.YYYY")}] ${
      conversation?.content != null ? conversation?.content.trim().replace(/\s\s+/g, " ") : ""
    }`;
  }, "");
  const getStateCode = state => {
    if (state && state.length > 2) {
      return USStates.find(el => el.name === state)?.code;
    } else {
      return state;
    }
  };

  const pushToCrmFormInitialValues = {
    companyId: selectedLead.companyId,
    dealId: selectedLead?.id,
    aingineDataId: null,
    typeOfLead: "",
    status: "",
    interest: "",
    year:
      (selectedLead?.vehicleOfInterest || []).length > 0
        ? selectedLead?.vehicleOfInterest[0]?.year || "0000"
        : "0000",
    make:
      (selectedLead?.vehicleOfInterest || []).length > 0
        ? selectedLead?.vehicleOfInterest[0]?.make || "N/A"
        : "N/A",
    model:
      (selectedLead?.vehicleOfInterest || []).length > 0
        ? selectedLead?.vehicleOfInterest[0]?.model || "N/A"
        : "N/A",
    contactFirstName: selectedLead.firstName ? selectedLead.firstName : "",
    contactLastName: selectedLead.lastName ? selectedLead.lastName : "",
    contactFullName: `${selectedLead.firstName ? selectedLead.firstName : ""} ${
      selectedLead.lastName ? selectedLead.lastName : ""
    }`,
    contactEmail:
      (selectedLead?.emails || []).length > 0 ? selectedLead?.emails[0]?.email || "N/A" : "",
    contactPhoneNumber:
      (selectedLead?.phoneNumbers || []).length > 0
        ? selectedLead?.phoneNumbers[0]?.phone || ""
        : "",
    contactAddressLine1:
      (selectedLead?.addresses || []).length > 0
        ? selectedLead?.addresses[0]?.addressLine1 || ""
        : "",
    contactAddressLine2:
      (selectedLead?.addresses || []).length > 0 ? selectedLead?.addresses[0].addressLine2 : "",
    city: (selectedLead?.addresses || []).length > 0 ? selectedLead?.addresses[0].city : "",
    state:
      (selectedLead?.addresses || []).length > 0
        ? getStateCode(selectedLead?.addresses[0].state)
        : "",
    zip: (selectedLead?.addresses || []).length > 0 ? selectedLead?.addresses[0].postalCode : "",
    country:
      (selectedLead?.addresses || []).length > 0
        ? selectedLead?.addresses[0].country || "United States"
        : "",
    comments: combinedConversation,
    additionalComments: "",
  };

  const vehiclesData = (selectedLead?.vehicleOfInterest || []).filter(el => el.model !== "");

  const [error, setError] = useState("");
  const [pushToCrmForm, setPushToCrmForm] = useState(pushToCrmFormInitialValues);
  const [yearData, setYearData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [makeData, setMakeData] = useState([]);
  const [vehicles, setVehicles] = useState(vehiclesData);
  const [vehicleInvetory, setVehicleInvetory] = useState(selectedLead?.leadVehicleInventory || []);

  const contactFirstName = useRef(pushToCrmFormInitialValues.firstName || "");
  const contactLastName = useRef(pushToCrmFormInitialValues.lastName || "");
  const contactFullName = useRef(pushToCrmFormInitialValues.contactFullName || "");
  const contactEmail = useRef(`${pushToCrmFormInitialValues.email}`);
  const contactPhoneNumber = useRef(`${pushToCrmFormInitialValues.phone}`);
  const contactAddressLine1 = useRef(`${pushToCrmFormInitialValues.addressLine1}`);
  const contactAddressLine2 = useRef("");
  const city = useRef("");
  const state = useRef("");
  const zip = useRef("");
  const country = useRef("");
  const comments = useRef(combinedConversation);
  const additionalComments = useRef("");

  const [
    pushDealToCrm,
    {
      data: pushDealToCrmData,
      loading: pushDealToCrmLoading,
      error: pushDealToCrmError,
      called: pushDealToCrmCalled,
    },
  ] = useMutation(PUSH_DEAL_TO_CRM);

  const { data } = useQuery(GET_ALL_VEHICLES, {
    onCompleted: resp => {
      const yearData = filterAndSortingData(resp?.vehicleOfInterest || [], "year");
      const modelData = filterAndSortingData(resp?.vehicleOfInterest || [], "model");
      const makeData = filterAndSortingData(resp?.vehicleOfInterest || [], "make");
      setYearData(yearData);
      setModelData(modelData);
      setMakeData(makeData);
    },
  });

  useEffect(() => {
    if (pushDealToCrmCalled) {
      if (!pushDealToCrmError) {
        setPushToCrmModalVisible(false);
        // message.success(`Lead has been pushed to CRM successfully`)
      } else if (String(pushDealToCrmError).includes("403")) {
        message.error(lang.pushCRMError);
      } else {
        message.error(lang.pushCrmApiError);
      }
    }
  }, [pushDealToCrmError]);

  const updatePushToCrmForm = (field, value) => {
    setPushToCrmForm(prevState => {
      const newState = { ...prevState };
      newState[field] = value;
      return newState;
    });
  };
  const validatePushToCrmSubmitValues = (pushToCrmSubmitValues, setError) => {
    let requirmentsSatisfied = true;
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(pushToCrmSubmitValues.contactEmail) ===
      false
    ) {
      setError(lang.invalidEmail);
      requirmentsSatisfied = false;
    }
    if (
      !pushToCrmSubmitValues.contactPhoneNumber ||
      pushToCrmSubmitValues.contactPhoneNumber === ""
    ) {
      requirmentsSatisfied = false;
      setError(lang.phoneMustEnter);
    }
    if (!pushToCrmSubmitValues.contactLastName || pushToCrmSubmitValues.contactLastName === "") {
      requirmentsSatisfied = false;
      setError(lang.lastNameMustEnter);
    }
    if (!pushToCrmSubmitValues.contactFirstName || pushToCrmSubmitValues.contactFirstName === "") {
      requirmentsSatisfied = false;
      setError(lang.firstNameMustEnter);
    }
    if (!pushToCrmSubmitValues.interest || pushToCrmSubmitValues.interest === "") {
      requirmentsSatisfied = false;
      setError(lang.interestMustEnter);
    }

    if (!pushToCrmSubmitValues.typeOfLead || pushToCrmSubmitValues.typeOfLead === "") {
      requirmentsSatisfied = false;
      setError(lang.typeOfLeadMustEnter);
    }
    if (
      !pushToCrmSubmitValues.contactAddressLine1 ||
      pushToCrmSubmitValues.contactAddressLine1 === ""
    ) {
      requirmentsSatisfied = false;
      setError(lang.addressLine1MustEnter);
    }
    if (pushToCrmSubmitValues.state == null || pushToCrmSubmitValues.state === "") {
      requirmentsSatisfied = false;
      setError(lang.stateMustEntered);
    }
    if (pushToCrmSubmitValues.country == null || pushToCrmSubmitValues.country === "") {
      requirmentsSatisfied = false;
      setError(lang.countryMustEntered);
    }
    return requirmentsSatisfied;
  };

  const handlePushToCrm = () => {
    const formValues = { ...pushToCrmForm };
    if (pushToCrmForm.additionalComments !== "") {
      // eslint-disable-next-line prefer-template
      formValues.comments = pushToCrmForm.comments + "\n" + pushToCrmForm.additionalComments;
    }
    const vehiclesData = [];
    if (user.company.crmIntegrationType === "DS" && vehicleInvetory.length > 0) {
      vehicleInvetory.forEach(el => {
        vehiclesData.push({
          vehicleId: el?.vehicleOfInterestId || el?.id,
          year: el?.vehicleOfInterest?.year || el?.year,
          model: el?.vehicleOfInterest?.model || el?.model,
          make: el?.vehicleOfInterest?.make || el?.make,
          interest: "Sell",
          stockNumber: el.stockNumber,
          vin: el.vin,
          isNew: el.isNew,
        });
      });
    }
    if (vehicles.length > 0 && vehiclesData.length < 3) {
      vehicles.forEach(el => {
        vehiclesData.push({
          vehicleId: el?.id || 0,
          year: el.year,
          model: el.model,
          make: el.make,
          interest: el.customerInterest,
          stockNumber: "",
          vin: "",
          isNew: "",
        });
      });
    }

    const pushToCrmSubmitValues = {
      ...formValues,
      year: pushToCrmForm.year ? pushToCrmForm.year : "0000",
      make: pushToCrmForm.make ? pushToCrmForm.make : "N/A",
      model: pushToCrmForm.model ? pushToCrmForm.model : "N/A",
      contactEmailType:
        (selectedLead?.emails || []).length > 0 ? selectedLead?.emails[0]?.emailType : "",
      contactPhoneNumberType:
        (selectedLead?.phoneNumbers || []).length > 0
          ? toTitleCase(selectedLead?.phoneNumbers[0]?.phoneType)
          : "",
      vehicles: vehiclesData,
    };
    if (validatePushToCrmSubmitValues(pushToCrmSubmitValues, setError)) {
      setError("");
      pushDealToCrm({
        variables: pushToCrmSubmitValues,
      }).then(resp => {
        if (resp?.data?.pushDealToCrm?.statusCode === 200) {
          message.success(resp?.data?.pushDealToCrm?.message);
          setPushToCrmModalVisible(false);
        } else {
          message.error(resp?.data?.pushDealToCrm?.message);
        }
      });
    }
  };
  const handleCancel = () => {
    setPushToCrmModalVisible(false);
  };

  const saveVehicle = payload => {
    if (payload) {
      if (payload?.id) {
        setVehicles(prevState => {
          const newState = [...prevState];
          const index = newState.findIndex(el => +el.id === +payload?.id);
          if (index > -1) {
            newState[index].make = payload?.make;
            newState[index].model = payload?.model;
            newState[index].year = payload?.year;
            newState[index].isCurrent = payload?.isCurrent;
            newState[index].isPrimary = payload?.isPrimary;
            newState[index].customerInterest = payload?.customerInterest;
          }
          return newState;
        });
      } else {
        setVehicles(prevState => {
          const newState = [...prevState];
          newState.push({
            id: new Date().valueOf(),
            make: payload?.make,
            model: payload?.model,
            year: payload?.year,
            isCurrent: payload.isCurrent,
            isPrimary: payload.isPrimary,
            customerInterest: payload?.customerInterest,
          });
          return newState;
        });
      }
    }
  };

  const removeVehicle = id => {
    showConfirmationModal(MODAL_TITLE, MODAL_CONTENT, () => {
      const findIndex = vehicles.findIndex(el => +el.id === +id);
      if (findIndex > -1) {
        setVehicles(prevState => {
          const newState = [...prevState];
          newState.splice(findIndex, 1);
          return newState;
        });
      }
    });
  };

  const saveVehicleInventory = payload => {
    if (payload) {
      setVehicleInvetory(prevState => {
        const newState = [...prevState];
        newState.push({ ...payload });
        return newState;
      });
    }
  };

  const removeVehicleInventory = id => {
    showConfirmationModal(MODAL_TITLE, MODAL_CONTENT, () => {
      const findIndex = vehicles.findIndex(el => +el.id === +id);
      if (findIndex > -1) {
        setVehicleInvetory(prevState => {
          const newState = [...prevState];
          newState.splice(findIndex, 1);
          return newState;
        });
      }
    });
  };

  const { Option } = Select;
  const { TextArea } = Input;

  const LEAD_TYPE_OPTION = [
    {
      key: "",
      value: "",
      label: "Select the type of lead",
      disabled: true,
    },
    {
      key: "sales",
      value: "sales",
      label: "Sales",
      disabled: false,
    },
    {
      key: "parts",
      value: "parts",
      label: "Parts",
      disabled: false,
    },
    {
      key: "service",
      value: "service",
      label: "Service",
      disabled: false,
    },
  ];

  const CONDITION_OPTION = [
    {
      key: "",
      value: "",
      label: "Select the condition",
      disabled: true,
    },
    {
      key: "new",
      value: "new",
      label: "New",
      disabled: false,
    },
    {
      key: "used",
      value: "used",
      label: "Used",
      disabled: false,
    },
  ];

  const INTEREST_OPTION = [
    {
      key: "",
      value: "",
      label: "Select the interest",
      disabled: true,
    },
    {
      key: "buy",
      value: "buy",
      label: "Buy",
      disabled: false,
    },
    {
      key: "sell",
      value: "sell",
      label: "Sell",
      disabled: false,
    },
  ];

  const handleScrollOnError = () => {
    document.querySelector(".ant-modal-wrap ").scrollTop = 0;
  };

  return (
    <div>
      {" "}
      {error && (
        <div className="py-2 w-full">
          <CustomAlert message={error} showIcon type="error" />
        </div>
      )}
      {pushDealToCrmLoading && (
        <div className="w-full text-center">
          <Spin spinning={pushDealToCrmLoading} />
        </div>
      )}
      <Collapse
        bordered={false}
        expandIconPosition="right"
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <AntdIconV4 type="CaretRightOutlined" rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel
          header={<CustomLabel label={lang.leadInfo} labelClass="font-bold" />}
          key="1"
        >
          <Row gutter={[4, 16]}>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={8} xl={8}>
              <FormField label="Type of Lead" isFieldRequired />
              <Select
                className="w-full"
                placeholder={lang.selectTypeTheLead}
                onChange={value => updatePushToCrmForm("typeOfLead", value)}
              >
                {LEAD_TYPE_OPTION.map(opt => (
                  <Option key={opt?.key} value={opt?.key} disabled={opt.disabled}>
                    {opt.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={8} xl={8}>
              <FormField label="Condition" />
              <Select
                className="w-full"
                placeholder={lang.selectTheCondition}
                onChange={value => updatePushToCrmForm("status", value)}
              >
                {CONDITION_OPTION.map(opt => (
                  <Option key={opt?.key} value={opt?.key} disabled={opt.disabled}>
                    {opt.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={8} xl={8}>
              <FormField label={lang.interest} isFieldRequired />

              <Select
                className="w-full"
                placeholder={lang.selectInterest}
                onChange={value => updatePushToCrmForm("interest", value)}
              >
                {INTEREST_OPTION.map(opt => (
                  <Option key={opt?.key} value={opt?.key} disabled={opt.disabled}>
                    {opt.label}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row gutter={[4, 16]}>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormField label={lang.firstName} isFieldRequired />
              <Input
                reference={contactFirstName}
                placeholder={lang.contactFirstName}
                name="contactFirstName"
                value={pushToCrmForm.contactFirstName}
                onChange={e => updatePushToCrmForm("contactFirstName", e.target.value)}
              />
            </Col>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormField label={lang.lastName} isFieldRequired />
              <Input
                reference={contactLastName}
                placeholder={lang.contactLastName}
                name="contactLastName"
                value={pushToCrmForm.contactLastName}
                onChange={e => updatePushToCrmForm("contactLastName", e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={[4, 16]}>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormField label={lang.fullName} />
              <Input
                reference={contactFullName}
                placeholder={lang.contactFullName}
                name="contactFullName"
                value={pushToCrmForm.contactFullName}
                onChange={e => updatePushToCrmForm("contactFullName", e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={[4, 16]}>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormField label={lang.contactEmail} />
              <Input
                reference={contactEmail}
                placeholder={lang.contactEmail}
                name="contactEmail"
                value={pushToCrmForm.contactEmail}
                onChange={e => updatePushToCrmForm("contactEmail", e.target.value)}
              />
            </Col>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormField label={lang.contactPhoneNumber} isFieldRequired />
              <PhoneDiv>
                <PhoneInput
                  reference={contactPhoneNumber}
                  international
                  withCountryCallingCode
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  value={pushToCrmForm.contactPhoneNumber}
                  placeholder={lang.enterPhone}
                  onChange={e => updatePushToCrmForm("contactPhoneNumber", e)}
                />
              </PhoneDiv>
            </Col>
          </Row>
          <Row gutter={[4, 16]}>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormField label={lang.contactAddressLine1} isFieldRequired />
              <Input
                reference={contactAddressLine1}
                placeholder={lang.contactAddressLine1}
                name="contactAddressLine1"
                value={pushToCrmForm.contactAddressLine1}
                onChange={e => updatePushToCrmForm("contactAddressLine1", e.target.value)}
              />
            </Col>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormField label={lang.contactAddressLine2} />
              <Input
                reference={contactAddressLine2}
                placeholder={lang.contactAddressLine2}
                name="contactAddressLine2"
                value={pushToCrmForm.contactAddressLine2}
                onChange={e => updatePushToCrmForm("contactAddressLine2", e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={[4, 16]}>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={6} xl={6}>
              <FormField label={lang.city} />
              <Input
                reference={city}
                placeholder={lang.city}
                name="city"
                value={pushToCrmForm.city}
                onChange={e => updatePushToCrmForm("city", e.target.value)}
              />
            </Col>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={6} xl={6}>
              <FormField label={lang.stateLabel} isFieldRequired />
              <Select
                showSearch
                reference={state}
                className="w-full"
                placeholder={lang.stateLabel}
                value={pushToCrmForm.state}
                onChange={e => updatePushToCrmForm("state", e)}
              >
                {USStates.map((el, i) => (
                  <Option key={`key__${i}`} value={el.code}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={6} xl={6}>
              <FormField label={lang.zip} isFieldRequired />
              <Input
                reference={zip}
                placeholder={lang.zip}
                name="zip"
                value={pushToCrmForm.zip}
                onChange={e => updatePushToCrmForm("zip", e.target.value)}
              />
            </Col>
            <Col className="mb-1" xs={24} sm={24} md={12} lg={6} xl={6}>
              <FormField label={lang.country} isFieldRequired />
              <Select
                reference={country}
                className="w-full"
                placeholder={lang.country}
                value={pushToCrmForm?.country || "United States"}
                disabled={pushToCrmForm.country != null && pushToCrmForm.country !== ""}
                onChange={e => updatePushToCrmForm("country", e)}
              >
                {Countries.map((el, i) => (
                  <Option key={`key__${i}`} value={el.name}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <CustomLabel
              label={user.company.crmIntegrationType === "DS" ? lang.voi : lang.vehicles}
              labelClass="font-bold"
            />
          }
          key="2"
        >
          <AddVehicleForm
            vehicles={vehicles || []}
            removeVehicle={removeVehicle}
            lead={selectedLead}
            vehicleCount={vehicleInvetory.length + vehicles.length}
            setError={setError}
            handleScrollOnError={handleScrollOnError}
            setVehicles={setVehicles}
            saveVehicle={saveVehicle}
            showPreviousVechile={false}
            setVehicleInfo={() => {}}
          />
        </Collapse.Panel>
        {user.company.crmIntegrationType === "DS" && (
          <Collapse.Panel
            header={<CustomLabel label={lang.voInventory} labelClass="font-bold" />}
            key="3"
          >
            <VehicleIntentory
              vehicles={vehicleInvetory}
              saveVehicle={saveVehicleInventory}
              removeVehicle={removeVehicleInventory}
            />
          </Collapse.Panel>
        )}
        <Collapse.Panel
          header={<CustomLabel label={lang.comments} labelClass="font-bold" />}
          key="4"
        >
          <Row gutter={[4, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FormField label={lang.comments} />
              <TextArea
                rows={5}
                readOnly
                reference={comments}
                placeholder={lang.comments}
                name="comments"
                defaultValue={`${combinedConversation}`}
                // onChange={e => updatePushToCrmForm("comments", e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={[4, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FormField label={lang.additionalComments} />
              <TextArea
                rows={5}
                reference={additionalComments}
                placeholder={lang.additionalComments}
                name="additionalComments"
                value={`${pushToCrmForm.additionalComments}`}
                onChange={e => updatePushToCrmForm("additionalComments", e.target.value)}
              />
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <DialogFooter
        handleConfirm={handlePushToCrm}
        confirmBtnText={lang.send}
        loading={pushDealToCrmLoading}
        handleCancel={() => closeDialog("pushToCrm")}
      />
    </div>
  );
};

export default PushToCrmModal;
