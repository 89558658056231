/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */
/* eslint-disable no-lone-blocks */
/* eslint-disable prefer-const */
/* eslint-disable no-unreachable */
/* eslint-disable no-use-before-define */
import { Button, Tooltip, message } from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { BulbOutlined, LoadingOutlined } from "@ant-design/icons";
import { isEmpty, isUndefined } from "lodash";
import { useQuery, useLazyQuery } from "@apollo/client";
import { convertUtcToLocal } from "../../../library/utils";
import useLang from "../../../hooks/useLang";
import { openai } from "../../../utils/openAi.config";
import { GET_AI_RESPONSE, GET_USE_CASES } from "../../../graphql/query";
import { SuggestAMessageButton } from "./suggestMessageButton";

export const getConversationHistoryPaginated = (startIndex, endIndex, selectedLead) => {
  let messages = "";
  try {
    const selectedMessages = (selectedLead.messages || []).slice(startIndex, endIndex);
    selectedMessages.map(item => {
      if (item?.direction === "TO_LEAD") {
        if (["APPOINTMENT", "OTTO"].includes(item.messageType)) {
          messages = `${messages} \n ${`Otto bot sent message to ${
            selectedLead.fullName
          } at ${convertUtcToLocal(item.dateSent, "ddd, MMMM Do YYYY, h:mm A")}: \n ${
            item.content
          } \n`}`;
        } else {
          const userFullName = `${item?.user?.firstName} ${item?.user?.lastName}`;
          messages = `${messages} \n ${`${userFullName} posted on ${convertUtcToLocal(
            item.dateSent,
            "ddd, MMMM Do YYYY, h:mm A"
          )}: - \n ${item.content}`} \n`;
        }
      } else if (item?.direction === "FROM_LEAD") {
        messages = `${messages} \n ${`${selectedLead.fullName} posted on ${convertUtcToLocal(
          item.dateRecieved,
          "ddd, MMMM Do YYYY, h:mm A"
        )}: -  \n ${item.content}`}\n`;
      } else if (item?.direction === "FROM_OTTO") {
        if (item.dateSent) {
          // to Lead
          messages = `${messages} \n ${`Otto Bot messaged ${
            selectedLead.fullName
          } at ${convertUtcToLocal(item.dateSent, "ddd, MMMM Do YYYY, h:mm A")}: -  \n ${
            item.content
          }`}\n`;
        } else if (item.dateRecieved) {
          const userFullName = `${item?.user?.firstName} ${item?.user?.lastName}`;
          messages = `${messages} \n ${`Otto Bot messaged ${userFullName} at ${convertUtcToLocal(
            item.dateSent,
            "ddd, MMMM Do YYYY, h:mm A"
          )}: -  \n ${item.content}`}\n`;
        }
      }
    });
  } catch (err) {
    console.log(err.message);
  }
  return messages;
};
export const getConversationHistory = selectedLead => {
  try {
    let messages = "";
    let selectedMessages = selectedLead.messages || [];
    if (selectedMessages.length > 50) {
      selectedMessages = selectedMessages.slice(-50);
    }
    (selectedLead.messages || []).map(item => {
      if (item?.direction === "TO_LEAD") {
        if (["APPOINTMENT", "OTTO"].includes(item?.messageType)) {
          messages = `${messages} \n ${`Otto bot sent message to ${
            selectedLead?.fullName
          } at ${convertUtcToLocal(item?.dateSent, "ddd, MMMM Do YYYY, h:mm A")}: \n ${
            item?.content
          } \n`}`;
        } else {
          const userFullName = `${item?.user?.firstName} ${item?.user?.lastName}`;
          messages = `${messages} \n ${`${userFullName} posted on ${convertUtcToLocal(
            item?.dateSent,
            "ddd, MMMM Do YYYY, h:mm A"
          )}: - \n ${item?.content}`} \n`;
        }
      } else if (item?.direction === "FROM_LEAD") {
        messages = `${messages} \n ${`${selectedLead?.fullName} posted on ${convertUtcToLocal(
          item?.dateRecieved,
          "ddd, MMMM Do YYYY, h:mm A"
        )}: -  \n ${item?.content}`}\n`;
      } else if (item?.direction === "FROM_OTTO") {
        const userFullName = `${item?.user?.firstName} ${item?.user?.lastName}`;
        messages = `${messages} \n ${`Otto Bot sent message to ${userFullName} at ${convertUtcToLocal(
          item?.dateRecieved,
          "ddd, MMMM Do YYYY, h:mm A"
        )}: -  \n ${item?.content}`}\n`;
      }
    });
    return `<conversation-start>${messages}<conversation-end>`;
  } catch (err) {
    console.log(err?.message);
  }
};
export const getOpportunityHistory = selectedLead => {
  try {
    const opportunityObj = selectedLead.leadOpportunities[0];
    let opportunityHistory = "";
    (selectedLead.leadOpportunities || []).map((opportunityObj, index) => {
      const crmDetails = () => {
        if (opportunityObj?.dtLeadOpportunities?.length > 0) {
          return "Dealer Team";
        }
        if (opportunityObj?.dsOpportunityEvents?.length > 0) {
          return "Dealer Socket";
        }
        if (opportunityObj?.sfLeadOpportunities?.length > 0) {
          return "Salesforce";
        }
      };
      opportunityHistory = `\n Opportunity #${index + 1}, \n Opportunity for lead "${
        selectedLead.fullName
      }" is \n "${opportunityObj.name}",\n from location "${
        opportunityObj.lead.location.title
      }", \n from "${crmDetails()}" CRM`;
    });
    return opportunityHistory;
  } catch (err) {
    console.log(err);
  }
};
export const vehiclesAsText = lead => {
  let list = [];
  if ((lead?.leadVehicleInventory || []).length > 0) {
    list = (lead?.leadVehicleInventory || []).map(el => {
      let voi = "";
      if (!isUndefined(el.make) && el.make !== null && el.make !== undefined && el.make !== 0)
        voi = `${voi} ${el.make} `;
      if (!isUndefined(el.model) && el.model !== null && el.model !== undefined && el.model !== 0)
        voi = `${voi} ${el.model} `;
      if (
        !isUndefined(el.year) &&
        +el.year !== 0 &&
        el.year !== null &&
        el.year !== undefined &&
        +el.year !== 0
      )
        voi = `${voi} ${el.year} `;

      return voi === "" || voi === 0 ? <span>No Vehicles</span> : voi;
    });
  }
  if ((lead?.vehicleOfInterest || []).length > 0) {
    lead?.vehicleOfInterest.map(el => {
      let voi = "";
      if (
        !isUndefined(el.year) &&
        +el.year !== 0 &&
        el.year !== null &&
        el.year !== undefined &&
        +el.year !== 0
      )
        voi = `${voi} ${el.year} `;
      if (!isUndefined(el.make) && el.make !== null && el.make !== undefined && el.make !== 0)
        voi = `${voi} ${el.make} `;
      if (!isUndefined(el.model) && el.model !== null && el.model !== undefined && el.model !== 0)
        voi = `${voi} ${el.model} `;

      {
        voi !== "" && voi !== 0 ? list.push(voi) : list.push("No vehicles");
      }
    });
  }
  return list;
};
export const getAppointmentHistory = selectedLead => {
  try {
    let appointment = "";
    if (selectedLead.activeAppointment) {
      appointment = `Appointment title : ${
        selectedLead.activeAppointment.summary
      } \n Appointment description : ${
        selectedLead.activeAppointment.description
      }\n Appointment date : ${dayjs
        .tz(selectedLead.activeAppointment.startDatetime, selectedLead.activeAppointment.timezone)
        .utc()
        .format("ddd, MMMM Do YYYY, h:mm A")} \n timezone : ${
        selectedLead.activeAppointment.timezone
      } \n vehicles : ${vehiclesAsText(selectedLead.activeAppointment.lead)}`;

      return `<appointment-start>\n ${appointment}<appointment-end>`;
    }
  } catch (err) {
    console.log(err);
  }
};

export const summarizeText = async textToSummarize => {
  let response = null;
  let summary = "";
  const prompt = textToSummarize;
  try {
    response = await openai.completions.create({
      model: "text-davinci-003", // specify the model to use
      prompt: prompt, // specify the prompt
      temperature: 0.8, // specify the randomness of the generated text
      max_tokens: 800, // specify the maximum number of tokens to generate
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 1,
    });
    summary = response.choices[0].text.replace(/^\s+|\s+$/g, "");
  } catch (error) {
    console.log("summarizeText:error", error);
    response = error?.response;
    console.log("summarizeText:error-response", response);
    if (response?.status === 400) {
      console.log("errorMessage", response?.data?.error?.message || "");
    }
  }
  return summary;
};

export const parseMessagesAndCheck = async selectedLead => {
  try {
    const numberOfMessages = (selectedLead.messages || []).length;
    let startIndex = 0;
    let endIndex = numberOfMessages > 50 ? 50 : numberOfMessages;
    let summary = "";
    do {
      const conversationHistory = getConversationHistoryPaginated(
        startIndex,
        endIndex,
        selectedLead
      );
      summary = `${summary}\nSummary so far: ${await summarizeText(
        `Summarize this conversation: ${conversationHistory}`
      )}`;
      startIndex += endIndex;
      endIndex += 50;
      // await new Promise(r => setTimeout(r, 2000));
    } while (startIndex < numberOfMessages);
    console.log("summary", summary);
    return summary;
  } catch (err) {
    console.log(err.message);
  }
};

const ChatMagic = ({
  text,
  selectedLead,
  user,
  setRichTextValue,
  displaySize,
  Component = null,
  code = "",
  postId = null,
  socialReviewId = null,
  placeholder,
  setmessageText,
  setloaderFn = () => {},
  disabled = false,
  loaderFn = () => {},
  setinputText = () => {},
}) => {
  // console.log("ChatMagic:code", code, "socialReviewId", socialReviewId, "postId", postId);
  // Build conversation history as a single string of text to pass to the GPT API
  const [lang] = useLang();
  const [misc, setmisc] = useState({
    loading: false,
  });
  const [generatedSummaryLead, setGeneratedSummaryLead] = useState(null);
  const [flag, setFlag] = useState(true);

  // console.log("ChatMagic:fetching useCases");
  const { data: useCases } = useQuery(GET_USE_CASES);
  // console.log("ChatMagic:fetched useCases", useCases);

  const [getAIResponse, { data: aiResponseData }] = useLazyQuery(GET_AI_RESPONSE, {
    fetchPolicy: "network-only",
    variables: {
      companyId: +user?.company?.id,
      applicationUseCaseId: +useCases?.getApplicationUseCases?.filter(
        useCase => useCase.code === code
      )[0]?.id,
      leadId: selectedLead?.id,
      postId: postId && +postId,
      socialReviewId: socialReviewId && +socialReviewId,
    },
    onCompleted: res => {
      console.log("ChatMagic:getAIResponse:onCompleted:res", res);
      console.log("ChatMagic:getAIResponse:onCompleted:aiResponseData", aiResponseData);
      if (res?.getAiResponse?.statusCode !== 200) {
        message.error(lang.ottoAiSuggestionFailedText);
      }

      setmisc({ ...misc, loading: false });
      setloaderFn(false);
    },
    onError: err => {
      console.log("ChatMagic:getAIResponse:onError:err", err);
      setmisc({ ...misc, loading: false });
      setloaderFn(false);
    },
  });

  useEffect(() => {
    setGeneratedSummaryLead(selectedLead);
  }, []);

  useEffect(() => {
    if (generatedSummaryLead?.id !== undefined && selectedLead?.id !== generatedSummaryLead?.id) {
      setmisc({
        loading: false,
      });
      text.current.value = "";
      text.current.style.height = "0px";
      setFlag(false);
    } else {
      setGeneratedSummaryLead(selectedLead);
      setFlag(true);
    }
  }, [selectedLead]);

  // Call the GPT API to suggest a response based on the conversation history
  const createSuggestedResponse = async () => {
    setmisc({ ...misc, loading: true });
    setloaderFn(true);
    if (!isEmpty(text?.current)) {
      text.current.placeholder = "Generating response...";
      text.current.value = "";
      text.current.style.height = "0px";
      text.current.focus();
    }
    // Initialize variables
    let textToType = "";
    let index = 0;
    // Get AI generated response from backend
    setloaderFn(true);
    const aiResponseResult = await getAIResponse();
    console.log(
      "createSuggestedResponse:after calling getAIResponse, aiResponseResult",
      aiResponseResult
    );
    console.log(
      "createSuggestedResponse:after calling getAIResponse, aiResponseData",
      aiResponseData
    );
    textToType = aiResponseResult?.data?.getAiResponse?.content;

    console.log("createSuggestedResponse:after calling getAIResponse, textToType", textToType);
    let typeCharacterflag = false;
    text.current.placeholder = placeholder ? placeholder() : "";
    if (textToType) typeCharacter();

    // Simulate typing effect
    function typeCharacter() {
      if (index < textToType.length) {
        if (!isEmpty(text?.current)) {
          text.current.style.height = `${text.current.scrollHeight}px`;
          if (isEmpty(text?.current?.value)) {
            if (typeCharacterflag) {
              setFlag(true);
              return;
            }
            typeCharacterflag = true;
            text.current.value = textToType.charAt(index);
          } else {
            text.current.value += textToType.charAt(index);
          }
          index++;
          if (index === textToType.length) {
            setmessageText(textToType);
            setloaderFn(false);
          }
          setTimeout(typeCharacter, 20); // Adjust the delay (in milliseconds) between characters here
        } else {
          setRichTextValue(textToType);
        }
      }
    }
  };

  return (
    <SuggestAMessageButton
      createSuggestedResponse={createSuggestedResponse}
      misc={misc}
      disabled={disabled}
      text={text}
      loaderFn={loaderFn}
      setloaderFn={setloaderFn}
      selectedLead={selectedLead}
      setinputText={setinputText}
    />
  );
};

export default ChatMagic;
