import * as React from "react";

const VehicleIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={220} height={116} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#D3B0FF"
        d="M197.617 81.622h-53.005a1.734 1.734 0 0 1-1.731-1.73V2.655c0-.938.763-1.73 1.731-1.73h53.005c.939 0 1.731.762 1.731 1.73v77.235c0 .939-.763 1.73-1.731 1.73ZM144.612 1.014a1.64 1.64 0 0 0-1.643 1.642v77.235c0 .91.734 1.643 1.643 1.643h53.005a1.64 1.64 0 0 0 1.643-1.643V2.656a1.64 1.64 0 0 0-1.643-1.642h-53.005ZM136.487 81.622H83.482c-.939 0-1.731-.763-1.731-1.73V2.655c0-.938.763-1.73 1.73-1.73h53.006c.939 0 1.731.762 1.731 1.73v77.235c0 .939-.792 1.73-1.731 1.73ZM83.482 1.014a1.64 1.64 0 0 0-1.643 1.642v77.235c0 .91.733 1.643 1.643 1.643h53.005a1.64 1.64 0 0 0 1.643-1.643V2.656a1.64 1.64 0 0 0-1.643-1.642H83.482ZM75.386 81.622H22.38c-.94 0-1.731-.763-1.731-1.73V2.655c0-.938.763-1.73 1.73-1.73h53.006c.939 0 1.73.762 1.73 1.73v77.235c0 .939-.791 1.73-1.73 1.73ZM22.38 1.014a1.64 1.64 0 0 0-1.643 1.642v77.235c0 .91.733 1.643 1.643 1.643h53.005a1.64 1.64 0 0 0 1.643-1.643V2.656a1.64 1.64 0 0 0-1.643-1.642H22.38ZM220 95.244H0v.03h220v-.03Z"
      />
      <path
        fill="#EBEBEB"
        d="M208.062 108.148h-16.339v.118h16.339v-.118ZM149.483 109.498H145.2v.117h4.283v-.117ZM33.235 104.395H11.91v.117h21.325v-.117ZM40.774 104.395h-3.139v.117h3.139v-.117ZM97.152 106.477h-46.23v.117h46.23v-.117Z"
      />
      <path
        fill="#D3B0FF"
        d="M179.754 35.051c-.733-.557-1.818-.616-2.61-.147-1.056-2.141-4.019-3.08-6.131-1.965-.557-2.317-2.816-4.077-5.192-4.048-2.376.03-4.605 1.848-5.104 4.195-.088.381-.117.762-.381 1.056-.44.44-1.144.293-1.76.234-1.936-.146-3.872 1.203-4.371 3.051l26.488-.088c.176-.91-.205-1.73-.939-2.288ZM97.034 18.598c-.733-.557-1.819-.616-2.61-.147-1.056-2.141-4.02-3.08-6.131-1.965-.558-2.318-2.816-4.078-5.192-4.048-2.376.03-4.605 1.848-5.104 4.194-.088.382-.118.763-.382 1.056-.44.44-1.143.294-1.76.235-1.936-.146-3.871 1.203-4.37 3.05l26.488-.087c.205-.939-.206-1.73-.939-2.288Z"
      />
      <path
        fill="#EEE0FF"
        d="M29.274 9.944a1.202 1.202 0 0 0-1.32-.088c-.528-1.085-1.994-1.555-3.05-.968-.264-1.144-1.408-2.053-2.611-2.024-1.173 0-2.317.939-2.552 2.083-.03.176-.059.381-.205.528-.206.205-.587.146-.88.117-.968-.088-1.936.587-2.2 1.525l13.229-.058c.147-.44-.059-.851-.41-1.115ZM159.72 11.731a1.202 1.202 0 0 0-1.32-.088c-.528-1.085-1.995-1.555-3.051-.968-.264-1.144-1.408-2.053-2.611-2.024-1.173 0-2.317.939-2.552 2.083-.029.176-.058.381-.205.528-.205.205-.587.146-.88.117-.968-.088-1.936.587-2.2 1.525l13.229-.058c.147-.44-.058-.822-.41-1.115ZM120.795 3.08a1.203 1.203 0 0 0-1.32-.088c-.528-1.085-1.995-1.555-3.051-.968C116.131.88 114.987 0 113.813 0c-1.173 0-2.317.939-2.552 2.083-.029.176-.058.381-.205.528-.205.205-.587.146-.88.117-.968-.088-1.936.587-2.2 1.525l13.229-.058c.147-.44-.058-.822-.41-1.115ZM36.813 50.483a1.203 1.203 0 0 0-1.32-.088c-.528-1.085-1.994-1.555-3.05-.968-.264-1.144-1.408-2.053-2.611-2.024-1.173 0-2.317.939-2.552 2.083-.03.176-.059.381-.205.528-.206.205-.587.146-.88.117-.968-.088-1.936.587-2.2 1.525l13.258-.029c.118-.44-.088-.85-.44-1.144ZM205.715 24.433a1.553 1.553 0 0 0-1.731-.088c-.704-1.437-2.669-2.053-4.077-1.32-.352-1.525-1.877-2.728-3.461-2.698-1.584.029-3.08 1.232-3.403 2.786-.059.235-.088.528-.264.704-.293.294-.763.206-1.173.176-1.291-.088-2.582.792-2.934 2.024l17.659-.058c.147-.616-.117-1.144-.616-1.526Z"
      />
      <path
        fill="#E7E7E7"
        d="M69.226 45.057V43.59a.22.22 0 0 0-.206-.147c-.087 0-.146.059-.146.147v1.613a.19.19 0 0 0 .176.176c.381 0 .704.323.704.704a.714.714 0 0 1-.704.704.714.714 0 0 1-.704-.704c0-.234-.382-.234-.352 0 .088.851 1.085 1.438 1.79.734.41-.411.38-1.086-.03-1.467-.118-.176-.323-.264-.528-.293Z"
      />
      <path
        fill="#EEE0FF"
        d="M43.647 34.586v3.168l-3.314-3.314-.176.175h-.235v29.92h4.165V34.587h-.44Zm-3.256 22.85 2.934 2.934H40.39v-2.933Zm0-7.45 2.934 2.934H40.42l-.029-2.934Zm0-7.421 2.934 2.933H40.42l-.029-2.933Zm0-7.421 2.934 2.933H40.42l-.029-2.933Zm2.904 3.373-2.933 2.933v-2.933h2.933Zm.352.352v2.933h-2.904l2.904-2.933Zm-.352 7.099L40.362 48.9v-2.934h2.933Zm.352.322v2.934h-2.904l2.904-2.934Zm-.352 7.099-2.933 2.933V53.39h2.933Zm.352.322v2.934h-2.904l2.904-2.934Zm-.352 7.1-2.933 2.932V60.81h2.933Zm-2.581 3.255 2.933-2.933v2.933h-2.933Zm2.933-4.048-2.904-2.933h2.933l-.029 2.933Zm0-7.421-2.933-2.934h2.933v2.934Zm0-7.422-2.933-2.933h2.933v2.934ZM40.392 24.729v5.808h.47v-2.23l2.375 2.376.176-.176h.235v-5.779h-3.256Zm2.435.469-1.995 1.994v-1.994h1.995Zm.352 4.781-1.995-1.994h1.995v1.994Zm-1.995-2.434 1.995-1.995v1.994h-1.995Z"
      />
      <path
        fill="#D3B0FF"
        d="M32.765 34.586H27.19v5.573h5.574v-5.573ZM69.138 42.624h-.323V31.917l-25.93-8.594h-1.701L27.28 31.947l-.177-.264 13.963-8.654h1.907l26.165 8.683v10.912Z"
      />
      <path
        fill="#EEE0FF"
        d="M65.97 34.85h.293l3.256-3.256H26.956v3.256H65.97Zm.44-2.816h1.995l-1.995 1.994v-1.994ZM27.426 34.38v-1.994l1.995 1.994h-1.995Zm2.317-.352-1.994-1.994h1.994v1.994Zm.47-1.994h1.994l-1.995 1.994v-1.994Zm2.317 2.346h-1.995l1.995-1.994v1.994Zm.47 0v-1.994l1.994 1.994h-1.995Zm2.316-.352-1.994-1.994h1.994v1.994Zm.47-1.994h1.995l-1.995 1.994v-1.994Zm2.317 2.346h-1.995l1.995-1.994v1.994Zm.47 0v-1.994l1.994 1.994h-1.995Zm2.317-.352-1.995-1.994h1.995v1.994Zm.47-1.994h1.994l-1.995 1.994v-1.994Zm2.316 2.346h-1.994l1.994-1.994v1.994Zm.47 0v-1.994l1.995 1.994h-1.995Zm2.317-.352-1.995-1.994h1.995v1.994Zm.47-1.994h1.994l-1.995 1.994v-1.994Zm2.317 2.346h-1.995l1.995-1.994v1.994Zm.47 0v-1.994l1.994 1.994h-1.995Zm2.316-.352-1.994-1.994h1.994v1.994Zm.47-1.994H54.5l-1.995 1.994v-1.994Zm2.317 2.346h-1.995l1.995-1.994v1.994Zm.47 0v-1.994l1.994 1.994h-1.995Zm2.317-.352-1.995-1.994h1.995v1.994Zm.47-1.994h1.994l-1.995 1.994v-1.994Zm2.317 2.346h-1.995l1.995-1.994v1.994Zm.469-2.346h1.995l-1.995 1.994v-1.994Zm2.317 2.346h-1.995l1.995-1.994v1.994Zm.47 0v-1.994l1.994 1.994h-1.995Zm.322-2.346h1.995v1.994l-1.995-1.994Z"
      />
      <path
        fill="#EEE0FF"
        d="M46.17 35.876h-8.565l.323-3.608c.088-.968.909-1.73 1.877-1.73h4.077c.968 0 1.76.703 1.878 1.671l.41 3.667Z"
      />
      <path
        fill="#F8F8F8"
        d="M40.626 32.031h-1.643v1.643h1.643V32.03ZM42.71 32.031h-1.644v1.643h1.643V32.03ZM44.792 32.031h-1.643v1.643h1.643V32.03Z"
      />
      <path
        fill="#D3B0FF"
        d="M42.005 24.964a1.115 1.115 0 1 0 0-2.23 1.115 1.115 0 0 0 0 2.23ZM69.05 44.559a.977.977 0 0 1-.968-.968v-.968h1.907v.968c.03.528-.41.968-.939.968ZM146.402 24.611H115.28v70.342h31.122V24.61Z"
      />
      <path fill="#D3B0FF" d="M121.234 17.072v7.539H140.418v-7.539h-19.184Z" />
      <path
        fill="#EEE0FF"
        d="M147.048 23.82h-32.414v.822h32.414v-.822ZM141.24 16.281h-20.798v.822h20.798v-.822Z"
      />
      <path fill="#EEE0FF" d="M139.92 24.611h-31.123v70.342h31.123V24.61Z" />
      <path fill="#EEE0FF" d="M114.751 17.072v7.539H133.935v-7.539h-19.184Z" />
      <path
        fill="#DDD6FE"
        d="M114.752 26.605h-3.99v3.99h3.99v-3.99ZM122.466 26.605h-3.989v3.99h3.989v-3.99ZM130.181 26.605h-3.99v3.99h3.99v-3.99ZM137.925 26.605h-3.989v3.99h3.989v-3.99ZM114.752 33.822h-3.99v3.99h3.99v-3.99ZM122.466 33.822h-3.989v3.99h3.989v-3.99ZM130.181 33.822h-3.99v3.99h3.99v-3.99ZM137.925 33.822h-3.989v3.99h3.989v-3.99ZM114.752 41.068h-3.99v3.99h3.99v-3.99ZM122.466 41.068h-3.989v3.99h3.989v-3.99ZM130.181 41.068h-3.99v3.99h3.99v-3.99ZM122.466 20.33h-3.989v3.99h3.989v-3.99ZM130.181 20.33h-3.99v3.99h3.99v-3.99ZM137.925 41.068h-3.989v3.99h3.989v-3.99ZM114.752 48.315h-3.99v3.989h3.99v-3.99ZM122.466 48.315h-3.989v3.989h3.989v-3.99ZM130.181 48.315h-3.99v3.989h3.99v-3.99ZM137.925 48.315h-3.989v3.989h3.989v-3.99ZM114.752 55.559h-3.99v3.989h3.99v-3.99ZM122.466 55.559h-3.989v3.989h3.989v-3.99ZM130.181 55.559h-3.99v3.989h3.99v-3.99ZM137.925 55.559h-3.989v3.989h3.989v-3.99ZM114.752 62.773h-3.99v3.99h3.99v-3.99ZM122.466 62.773h-3.989v3.99h3.989v-3.99ZM130.181 62.773h-3.99v3.99h3.99v-3.99ZM137.925 62.773h-3.989v3.99h3.989v-3.99ZM114.752 70.02h-3.99v3.989h3.99v-3.99ZM122.466 70.02h-3.989v3.989h3.989v-3.99ZM130.181 70.02h-3.99v3.989h3.99v-3.99ZM137.925 70.02h-3.989v3.989h3.989v-3.99ZM114.752 76.856h-3.99v3.989h3.99v-3.99ZM122.466 76.856h-3.989v3.989h3.989v-3.99ZM130.181 76.856h-3.99v3.989h3.99v-3.99ZM137.925 76.856h-3.989v3.989h3.989v-3.99ZM114.752 83.719h-3.99v3.99h3.99v-3.99ZM122.466 83.719h-3.989v3.99h3.989v-3.99ZM130.181 83.719h-3.99v3.99h3.99v-3.99ZM137.925 83.719h-3.989v3.99h3.989v-3.99ZM114.752 90.555h-3.99v3.989h3.99v-3.99ZM122.466 90.555h-3.989v3.989h3.989v-3.99ZM130.181 90.555h-3.99v3.989h3.99v-3.99ZM137.925 90.555h-3.989v3.989h3.989v-3.99Z"
      />
      <path
        fill="#EEE0FF"
        d="M140.565 23.82h-32.414v.822h32.414v-.822ZM134.757 16.281h-20.798v.822h20.798v-.822Z"
      />
      <path fill="#D3B0FF" d="M185.563 46.201h-28.307V95.16h28.307V46.2Z" />
      <path fill="#EEE0FF" d="M186.178 45.496h-29.48v.733h29.48v-.733Z" />
      <path fill="#EEE0FF" d="M179.666 46.201h-28.307V95.16h28.307V46.2Z" />
      <path
        fill="#DDD6FE"
        d="M156.816 48.02h-3.638v3.637h3.638V48.02ZM163.826 48.02h-3.637v3.637h3.637V48.02ZM170.866 48.02h-3.637v3.637h3.637V48.02ZM177.877 48.02h-3.637v3.637h3.637V48.02ZM156.816 54.62h-3.638v3.637h3.638v-3.638ZM163.826 54.62h-3.637v3.637h3.637v-3.638ZM170.866 54.62h-3.637v3.637h3.637v-3.638ZM177.877 54.62h-3.637v3.637h3.637v-3.638ZM156.816 61.191h-3.638v3.638h3.638V61.19ZM163.826 61.191h-3.637v3.638h3.637V61.19ZM170.866 61.191h-3.637v3.638h3.637V61.19ZM177.877 61.191h-3.637v3.638h3.637V61.19ZM156.816 67.791h-3.638v3.637h3.638v-3.637ZM163.826 67.791h-3.637v3.637h3.637v-3.637ZM170.866 67.791h-3.637v3.637h3.637v-3.637ZM177.877 67.791h-3.637v3.637h3.637v-3.637ZM156.816 74.361h-3.638V78h3.638V74.36ZM163.826 74.361h-3.637V78h3.637V74.36ZM170.866 74.361h-3.637V78h3.637V74.36ZM177.877 74.361h-3.637V78h3.637V74.36ZM156.816 80.96h-3.638v3.638h3.638v-3.637ZM163.826 80.96h-3.637v3.638h3.637v-3.637ZM170.866 80.96h-3.637v3.638h3.637v-3.637ZM177.877 80.96h-3.637v3.638h3.637v-3.637ZM156.816 87.533h-3.638v3.638h3.638v-3.638ZM163.826 87.533h-3.637v3.638h3.637v-3.638ZM170.866 87.533h-3.637v3.638h3.637v-3.638ZM177.877 87.533h-3.637v3.638h3.637v-3.638Z"
      />
      <path fill="#EEE0FF" d="M180.253 45.496h-29.48v.733h29.48v-.733Z" />
      <path fill="#D3B0FF" d="M102.93 41.361H71.808V95.16h31.122V41.36Z" />
      <path fill="#D3B0FF" d="M77.791 33.852v7.509H96.947v-7.51H77.792Z" />
      <path
        fill="#EEE0FF"
        d="M103.575 40.6H71.162v.82h32.413v-.82ZM97.767 33.031H76.97v.822h20.797v-.822Z"
      />
      <path fill="#EEE0FF" d="M96.447 41.361H65.325V95.16h31.122V41.36Z" />
      <path fill="#EEE0FF" d="M71.31 33.852v7.509h19.154v-7.51H71.309Z" />
      <path
        fill="#DDD6FE"
        d="M71.31 43.355h-3.99v3.99h3.99v-3.99ZM79.024 43.355h-3.99v3.99h3.99v-3.99ZM86.738 43.355H82.75v3.99h3.99v-3.99ZM94.453 43.355h-3.99v3.99h3.99v-3.99ZM71.31 50.602h-3.99v3.989h3.99V50.6ZM79.024 50.602h-3.99v3.989h3.99V50.6ZM86.738 50.602H82.75v3.989h3.99V50.6ZM94.453 50.602h-3.99v3.989h3.99V50.6ZM71.31 57.846h-3.99v3.989h3.99v-3.99ZM79.024 57.846h-3.99v3.989h3.99v-3.99ZM86.738 57.846H82.75v3.989h3.99v-3.99ZM79.024 37.107h-3.99v3.99h3.99v-3.99ZM86.738 37.107H82.75v3.99h3.99v-3.99ZM94.453 57.846h-3.99v3.989h3.99v-3.99ZM71.31 65.063h-3.99v3.989h3.99v-3.99ZM79.024 65.063h-3.99v3.989h3.99v-3.99ZM86.738 65.063H82.75v3.989h3.99v-3.99ZM94.453 65.063h-3.99v3.989h3.99v-3.99ZM71.31 72.309h-3.99v3.989h3.99v-3.99ZM79.024 72.309h-3.99v3.989h3.99v-3.99ZM86.738 72.309H82.75v3.989h3.99v-3.99ZM94.453 72.309h-3.99v3.989h3.99v-3.99ZM71.31 79.553h-3.99v3.99h3.99v-3.99ZM79.024 79.553h-3.99v3.99h3.99v-3.99ZM86.738 79.553H82.75v3.99h3.99v-3.99ZM94.453 79.553h-3.99v3.99h3.99v-3.99ZM71.31 86.799h-3.99v3.99h3.99v-3.99ZM79.024 86.799h-3.99v3.99h3.99v-3.99ZM86.738 86.799H82.75v3.99h3.99v-3.99ZM94.453 86.799h-3.99v3.99h3.99v-3.99Z"
      />
      <path
        fill="#EEE0FF"
        d="M97.093 40.6H64.68v.82h32.413v-.82ZM91.285 33.031H70.487v.822h20.798v-.822ZM43.589 64.007H23.906v30.947h31.416V50.309L43.59 64.007Z"
      />
      <path
        fill="#D3B0FF"
        d="M61.189 50.309h-5.867v44.645h5.867V50.309ZM30.037 70.283h-4.664v4.664h4.664v-4.664Z"
      />
      <path fill="#DDD6FE" d="M30.037 70.283h-4.165v4.664h4.165v-4.664Z" opacity={0.3} />
      <path fill="#D3B0FF" d="M30.037 78.144h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M30.037 78.144h-4.165v4.664h4.165v-4.664Z" opacity={0.3} />
      <path fill="#D3B0FF" d="M30.037 86.006h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M30.037 86.006h-4.165v4.664h4.165v-4.664Z" opacity={0.3} />
      <path fill="#DDD6FE" d="M30.536 86.006h-4.664v4.664h4.664v-4.664Z" opacity={0.2} />
      <path fill="#D3B0FF" d="M37.898 70.283h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M37.928 70.283h-4.165v4.664h4.165v-4.664Z" opacity={0.3} />
      <path fill="#D3B0FF" d="M37.898 78.144h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M37.928 78.144h-4.165v4.664h4.165v-4.664Z" opacity={0.3} />
      <path fill="#D3B0FF" d="M37.898 86.006h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M37.928 86.006h-4.165v4.664h4.165v-4.664Z" opacity={0.3} />
      <path fill="#DDD6FE" d="M38.427 86.006h-4.664v4.664h4.664v-4.664Z" opacity={0.2} />
      <path fill="#D3B0FF" d="M45.789 70.283h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M45.818 70.283h-4.165v4.664h4.165v-4.664Z" opacity={0.3} />
      <path fill="#D3B0FF" d="M45.789 78.144h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M45.818 78.144h-4.165v4.664h4.165v-4.664Z" opacity={0.3} />
      <path fill="#D3B0FF" d="M45.789 86.006h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M45.818 86.006h-4.165v4.664h4.165v-4.664Z" opacity={0.3} />
      <path fill="#DDD6FE" d="M46.317 86.006h-4.664v4.664h4.664v-4.664Z" opacity={0.2} />
      <path fill="#D3B0FF" d="M53.68 70.283h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M53.709 70.283h-4.166v4.664h4.166v-4.664Z" opacity={0.3} />
      <path fill="#D3B0FF" d="M53.68 78.144h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M53.709 78.144h-4.166v4.664h4.166v-4.664Z" opacity={0.3} />
      <path fill="#D3B0FF" d="M53.68 86.006h-4.664v4.664h4.664v-4.664Z" />
      <path fill="#DDD6FE" d="M53.709 86.006h-4.166v4.664h4.166v-4.664Z" opacity={0.3} />
      <path fill="#DDD6FE" d="M54.208 86.006h-4.664v4.664h4.664v-4.664Z" opacity={0.2} />
      <path
        fill="#F5F5F5"
        d="M187 113.52c0 .997-34.466 1.789-77 1.789-42.533 0-77-.792-77-1.789 0-.998 34.467-1.79 77-1.79 42.534 0 77 .792 77 1.79Z"
      />
      <path
        fill="#9747FF"
        d="M58.461 11.438c-4.165.527-10.501 1.935-15.459 4.517v.968s6.835-3.96 15.488-4.693l-.029-.793Z"
      />
      <path
        fill="#9747FF"
        d="M65.707 12.906a3.442 3.442 0 0 0-.88-.558c-.704-.293-5.046.352-6.512.587v-.616l-.03-.821c.998-.118 1.907-.176 2.67-.235 1.26-.059 2.17-.117 2.845-.117 1.026-.059 1.877.733 1.936 1.76-.03-.03-.03 0-.03 0Z"
      />
      <path fill="#263238" d="M53.886 12.67h-.675v2.933h.675V12.67Z" />
      <path
        fill="#263238"
        d="M43.003 22.908c-.294 0-.528-.264-.528-.557 0-.264 0-.822 2.786-3.315a10.16 10.16 0 0 1 1.232-.997c3.696-2.523 8.214-3.14 8.419-3.168a.511.511 0 0 1 .616.41.511.511 0 0 1-.41.616h-.06c-.058 0-4.487.616-7.949 2.992-.41.264-.762.587-1.114.91-1.82 1.642-2.376 2.405-2.435 2.61a.57.57 0 0 1-.557.499Z"
      />
      <path
        fill="#263238"
        d="M42.27 15.809h1.143c.03 0 .059.029.059.058v77.44h-1.29v-77.44c0-.03.029-.058.087-.058Z"
      />
      <path
        fill="#9747FF"
        d="M66.235 12.964v.03l-6.072.557-1.35-.557v-.088c1.467-.235 5.808-.88 6.513-.587.322.147.616.323.88.557.029.06.029.06.029.088Z"
      />
      <path
        fill="#DDD6FE"
        d="M66.235 12.964v.03l-6.072.557-1.35-.557v-.088c1.467-.235 5.808-.88 6.513-.587.322.147.616.323.88.557.029.06.029.06.029.088Z"
        opacity={0.6}
      />
      <path
        fill="#9747FF"
        d="M182.834 12.932c-4.165.528-10.501 1.936-15.459 4.517v.968s6.835-3.96 15.489-4.693l-.03-.792Z"
      />
      <path
        fill="#9747FF"
        d="M190.079 14.4a3.432 3.432 0 0 0-.88-.558c-.704-.293-5.045.352-6.512.587v-.645l-.029-.822c.997-.117 1.906-.175 2.669-.234 1.261-.059 2.171-.117 2.845-.117 1.027-.06 1.878.733 1.936 1.76-.029 0-.029 0-.029.029Z"
      />
      <path fill="#263238" d="M178.287 14.166h-.674v2.933h.674v-2.933Z" />
      <path
        fill="#263238"
        d="M167.405 24.404c-.293 0-.528-.264-.528-.557 0-.264 0-.822 2.787-3.315.381-.352.792-.704 1.232-.997 3.696-2.523 8.213-3.139 8.418-3.168a.51.51 0 0 1 .616.41.51.51 0 0 1-.41.616h-.059c-.059 0-4.488.616-7.949 2.992-.411.264-.763.587-1.115.91-1.819 1.642-2.376 2.405-2.435 2.61-.058.264-.264.499-.557.499Z"
      />
      <path
        fill="#263238"
        d="M166.642 17.305h1.144c.03 0 .059.029.059.058v77.44h-1.291v-77.44c.03-.029.059-.058.088-.058Z"
      />
      <path
        fill="#9747FF"
        d="M190.637 14.459v.029l-6.072.557-1.35-.557V14.4c1.467-.235 5.808-.88 6.512-.587.323.147.616.323.88.558.03.029.03.058.03.088Z"
      />
      <path
        fill="#DDD6FE"
        d="M190.637 14.459v.029l-6.072.557-1.35-.557V14.4c1.467-.235 5.808-.88 6.512-.587.323.147.616.323.88.558.03.029.03.058.03.088Z"
        opacity={0.6}
      />
      <path
        fill="#9747FF"
        d="m142.149 105.247 1.701-15.4 10.12-7.04h11.088l12.966 8.506.586 13.934-2.933.968-33.528 1.701v-2.669Z"
      />
      <path
        fill="#000"
        d="m142.149 105.247 1.701-15.4 10.12-7.04h11.088l12.966 8.506.586 13.934-2.933.968-33.528 1.701v-2.669Z"
        opacity={0.4}
      />
      <path
        fill="#263238"
        d="M160.366 113.311c7.517 0 13.61-6.08 13.61-13.581 0-7.501-6.093-13.582-13.61-13.582-7.517 0-13.611 6.081-13.611 13.582s6.094 13.581 13.611 13.581Z"
      />
      <path
        fill="#DDD6FE"
        d="M160.365 108.06a8.331 8.331 0 1 0 0-16.662 8.331 8.331 0 0 0 0 16.662Z"
        opacity={0.4}
      />
      <path
        fill="#DDD6FE"
        d="M161.04 92.925v4.253a.663.663 0 0 1-.674.675.664.664 0 0 1-.675-.675v-4.253c0-.382.293-.675.675-.675.381 0 .674.293.674.675ZM161.04 102.282v4.253a.664.664 0 0 1-.675.675.664.664 0 0 1-.675-.675v-4.253c0-.381.294-.675.675-.675.381.03.675.323.675.675ZM162.917 99.054a.672.672 0 0 1-.909-.264c-.176-.323-.088-.734.234-.91l3.696-2.141a.691.691 0 0 1 .91.235.69.69 0 0 1-.235.909l-3.696 2.17ZM153.883 103.456c-.176-.322-.088-.733.235-.909l3.696-2.141c.322-.176.733-.088.909.234.176.323.088.734-.235.91l-3.696 2.141a.64.64 0 0 1-.909-.235ZM166.848 103.485a.639.639 0 0 1-.909.235l-3.696-2.141a.64.64 0 0 1-.235-.91c.176-.322.587-.41.91-.234l3.696 2.141c.322.176.44.587.234.909ZM158.723 98.791c-.176.323-.587.411-.909.235l-3.696-2.141a.64.64 0 0 1-.235-.91.64.64 0 0 1 .909-.234l3.696 2.141c.323.176.411.587.235.91Z"
        opacity={0.3}
      />
      <path
        fill="#263238"
        d="M160.366 98.615a1.114 1.114 0 1 1 0 2.232 1.114 1.114 0 0 1 0-2.232Z"
      />
      <path
        fill="#9747FF"
        d="m39.688 105.454 1.701-15.4 10.12-7.04h11.088l12.966 8.506.586 13.934v2.669H39.688v-2.669Z"
      />
      <path
        fill="#000"
        d="m39.688 105.454 1.701-15.4 10.12-7.04h11.088l12.966 8.506.586 13.934v2.669H39.688v-2.669Z"
        opacity={0.5}
      />
      <path
        fill="#263238"
        d="M72.189 80.313s10.677-6.6 20.475-9.328c9.797-2.758 17.482-5.016 17.482-5.016L84.01 81.427 72.19 80.313Z"
      />
      <path
        fill="#9747FF"
        d="m185.504 80.049 2.552-2.552-.088-1.232h-10.795s-29.069-11.997-53.152-11.997c-24.082 0-42.973 15.458-42.973 15.458l-7.715-.41s-10.941.146-16.661.909c-9.768 1.32-26.723 5.69-31.797 12.613 0 0 2.845 11.88 9.562 16.456h9.035V99.262h.03c.175-7.685 6.423-13.874 14.167-13.874s13.992 6.189 14.168 13.874h.03v10.032h74.506V99.262h.03c.176-7.685 6.424-13.874 14.168-13.874 7.714 0 13.992 6.189 14.168 13.874h.029v7.744c4.752-.645 9.005-1.466 11.557-2.493 0 0 5.603-6.307 5.896-9.563l-6.717-14.901Z"
      />
      <path
        fill="#000"
        d="M111.115 85.417c-7.598.381-20.094-1.76-30.36-3.667-7.98.352-16.192.91-23.144 1.79C41.74 85.533 31.68 94.1 31.68 94.1l-6.805-1.262c.029.117 1.349 5.573 4.282 10.501 2.758.118 7.862.323 14.286.528v-4.605h.029c.176-7.685 6.424-13.875 14.168-13.875 7.744 0 13.992 6.19 14.168 13.875h.03v5.163c13.845.088 27.749-.147 36.666-1.203 23.467-2.757 31.445-4.4 40.539-20.65-.03.029-24.406 2.2-37.928 2.845Z"
        opacity={0.3}
      />
      <path
        stroke="#263238"
        strokeMiterlimit={10}
        strokeWidth={0.073}
        d="m87.413 80.341-7.802 8.741v13.024s41.624 1.438 51.539-5.133c0 0 6.922-11.352 7.421-13.757.469-2.406.939-5.398.939-5.398l-52.096 2.523Z"
      />
      <path
        fill="#263238"
        d="M25.637 95.478s2.875-1.437 5.28 2.816c2.405 4.253 2.17 5.221 2.17 5.221l-3.93-.176c-1.32-2.17-2.464-4.84-3.52-7.861Z"
      />
      <path
        fill="#D7504D"
        d="M185.65 80.342h-7.04s1.056 3.285 3.52 4.576c2.464 1.29 5.72.381 5.838.645.088.264-2.318-5.221-2.318-5.221Z"
      />
      <path
        fill="#DDD6FE"
        d="M57.61 83.54c15.87-1.994 38.222-2.405 49.339-2.141 11.118.264 80.99-5.104 80.99-5.104h-10.766l-22.762.323-73.363 3.138-7.715-.41s-10.941.146-16.661.909c-9.768 1.32-26.723 5.69-31.797 12.613l6.834 1.262c-.03-.03 10.032-8.566 25.902-10.59Z"
        opacity={0.3}
      />
      <path
        fill="#000"
        d="M192.016 95.537c-6.014 2.992-17.336 4.605-17.336 4.605v6.894c4.752-.646 9.005-1.467 11.557-2.494-.029 0 4.987-5.66 5.779-9.005Z"
        opacity={0.3}
      />
      <path
        fill="#DDD6FE"
        d="M27.984 92.31s2.787.646 3.315.47c.528-.176 3.373-2.493 3.373-2.493s-4.077-.763-6.688 2.024Z"
      />
      <path
        fill="#263238"
        d="M57.904 113.518c7.517 0 13.61-6.08 13.61-13.581 0-7.501-6.093-13.581-13.61-13.581-7.517 0-13.611 6.08-13.611 13.58 0 7.502 6.094 13.582 13.61 13.582Z"
      />
      <path
        fill="#DDD6FE"
        d="M57.903 108.267a8.33 8.33 0 1 0 0-16.662 8.33 8.33 0 0 0 0 16.662Z"
        opacity={0.4}
      />
      <path
        fill="#DDD6FE"
        d="M58.578 93.134v4.253a.664.664 0 0 1-.674.675.664.664 0 0 1-.675-.675v-4.253c0-.382.293-.675.675-.675.381 0 .674.293.674.675ZM58.578 102.489v4.283a.663.663 0 0 1-.674.674.663.663 0 0 1-.675-.674v-4.254c0-.381.293-.674.675-.674.381 0 .674.293.674.645ZM60.456 99.233a.672.672 0 0 1-.91-.264c-.176-.322-.088-.733.235-.909l3.696-2.141a.69.69 0 0 1 .91.234.69.69 0 0 1-.236.91l-3.695 2.17ZM51.421 103.663c-.176-.322-.088-.733.235-.909l3.696-2.141c.323-.176.733-.088.91.234.175.323.087.734-.236.91l-3.696 2.141a.64.64 0 0 1-.909-.235ZM64.416 103.693a.64.64 0 0 1-.91.234l-3.696-2.141a.64.64 0 0 1-.234-.909c.176-.323.586-.411.91-.235l3.695 2.141c.294.176.41.587.235.91ZM56.26 98.997c-.175.322-.586.41-.909.234l-3.696-2.141a.64.64 0 0 1-.235-.91.64.64 0 0 1 .91-.234l3.696 2.141a.64.64 0 0 1 .234.91Z"
        opacity={0.3}
      />
      <path fill="#263238" d="M57.904 98.82a1.114 1.114 0 1 1 0 2.229 1.114 1.114 0 0 1 0-2.229Z" />
      <path
        fill="#000"
        d="M71.808 109.322v-1.496s58.197.88 74.507-1.76v3.256H71.808ZM34.408 109.324h9.035v-1.056l-10.53-.147c.263.264 1.084.939 1.495 1.203Z"
        opacity={0.5}
      />
      <path
        fill="#263238"
        d="M87.413 80.343s58.109-2.288 67.027-3.725l1.29-2.61s-13.17-7.51-32.354-7.012c-19.214.5-35.963 13.347-35.963 13.347Z"
      />
      <path
        fill="#000"
        d="m139.391 68.404-1.466 9.592 3.696-.322.762-8.595-2.992-.675Z"
        opacity={0.3}
      />
      <path
        stroke="#263238"
        strokeMiterlimit={10}
        strokeWidth={0.073}
        d="m139.509 77.851 1.466-9.093"
      />
      <path fill="#000" d="m94.717 75.678.587 4.282-7.89.382 7.303-4.664Z" opacity={0.3} />
      <path
        fill="#9747FF"
        d="M93.397 77.144s-4.576.763-4.165 3.902c.41 3.11 6.541 1.613 6.659 1.437-.03 0-.323-4.165-2.494-5.339Z"
      />
      <path
        fill="#263238"
        d="M93.397 77.144c-1.613.352-3.55 1.115-4.077 2.816-.528 2.2 1.261 2.993 3.139 2.993 1.114 0 2.376-.147 3.402-.5-.264-1.906-.704-4.194-2.464-5.309Zm0 0c1.79 1.086 2.259 3.403 2.523 5.34-.147.087-.264.117-.44.175a11.69 11.69 0 0 1-3.022.381c-1.936.03-3.725-.85-3.197-3.08.528-1.73 2.493-2.463 4.136-2.816Z"
      />
      <path
        fill="#DDD6FE"
        d="m95.861 75.68 58.344-1.643s-16.368-7.128-31.034-6.307c-14.667.85-27.31 7.95-27.31 7.95Z"
        opacity={0.2}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h220v115.309H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default VehicleIcon;
