import * as React from "react";

const DoubleTickIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#3F83F8"
      d="m9.312 6.096-4.817 4.73a.645.645 0 0 1-.904 0L1.526 8.8a.645.645 0 1 1 .905-.921l1.613 1.583 4.365-4.287a.645.645 0 1 1 .904.92Zm5.17-.914a.645.645 0 0 0-.914-.008L9.204 9.462l-.423-.416a.645.645 0 1 0-.905.92l.876.86a.645.645 0 0 0 .904 0l4.818-4.73a.645.645 0 0 0 .008-.913v-.001Z"
    />
  </svg>
);
export default DoubleTickIcon;
