import { FileTextOutlined } from "@ant-design/icons";
import React from "react";
import VideoIcon from "../../../icons/js/VideoIcon";
import ImageIcon from "../../../icons/js/ImageIcon";

const IMAGE_FILE = "image/*";
const VIDEO_FILE = "video/*";
const DOCUMENT_FILE =
  "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf";

const MmsMediaButtons = props => {
  const { handleMediaSharing, mediaRef, videoRef, documentRef, handleMediaEvent } = props;
  return (
    <>
      <div className="flex gap-5 flex-col justify-between text-center">
        <span
          className="flex items-center cursor-pointer"
          onClick={() => handleMediaSharing("image")}
        >
          <ImageIcon className="text-[20px] mr-1.5" />
          Image
        </span>
        <span
          className="flex items-center cursor-pointer"
          onClick={() => handleMediaSharing("video")}
        >
          <VideoIcon className="text-[20px] mr-1.5" />
          Video
        </span>
        <span
          className="flex items-center cursor-pointer"
          onClick={() => handleMediaSharing("document")}
        >
          <FileTextOutlined className="text-[20px] mr-1.5" />
          Document
        </span>
      </div>
      <input
        type="file"
        style={{ display: "none" }}
        multiple
        ref={mediaRef}
        accept={IMAGE_FILE}
        onChange={handleMediaEvent}
      />
      <input
        type="file"
        style={{ display: "none" }}
        multiple
        ref={videoRef}
        accept={VIDEO_FILE}
        onChange={handleMediaEvent}
      />
      <input
        type="file"
        style={{ display: "none" }}
        multiple
        ref={documentRef}
        accept={DOCUMENT_FILE}
        onChange={handleMediaEvent}
      />
    </>
  );
};

export default MmsMediaButtons;
