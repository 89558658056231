import React from "react";
import { Tag } from "antd";
import { uniqBy } from "lodash";
import { colorConfig } from "../../../library/colorConfig";

export const SourcesTags = ({ data = [], sources = [], limit = false }) => {
  const tags = (data || []).map((el, i) => {
    if (limit && i >= 2) return;
    const obj = (sources || []).find(e => +e.id === +el.value);
    if (obj) {
      let { name } = obj;
      if (obj?.parentSource) {
        name = `${obj?.parentSource?.name} / ${name}`;
        if (obj?.parentSource?.parentSource) {
          name = `${obj?.parentSource?.parentSource?.name} / ${name}`;
        }
      }
      return (
        <Tag key={el.id}>
          <div className="truncate">{name}</div>{" "}
        </Tag>
      );
    }
  });
  return tags;
};

export const StatusTags = ({ data = [], statusList = [] }) => {
  const list = uniqBy(statusList, "name");
  const tagsLocal = (data || []).map(el => {
    const obj = list.find(e => e.id === el.value);
    if (obj) {
      return { id: el.id, name: obj?.name };
    }
  });

  const tags = uniqBy(tagsLocal || [], "name").map(el => {
    if (el) {
      return <Tag key={el.id}>{el.name}</Tag>;
    }
  });
  return tags;
};

export const sourcetagDisplay = sources => {
  return sources.map(source => {
    const color = level => {
      if (level === 1) {
        return "#90246c";
        // eslint-disable-next-line no-else-return
      } else if (level === 2) {
        return colorConfig.secondaryDark;
      } else if (level === 3) {
        return "grey";
      }
    };
    return (
      <div key={source.name} className="flex flex-col mb-[1em]">
        <Tag
          title={source.name}
          style={{ backgroundColor: `${color(source.level)}`, color: "white" }}
        >
          {source.name}
        </Tag>
      </div>
    );
  });
};

export const CampaignInfo = ({ label, value }) => (
  <p className="pb-1">
    {label} <span className="font-medium">{value}</span>
  </p>
);
