import * as React from "react";

const NotificationIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M17.328 13.745c-.433-.747-1.078-2.86-1.078-5.62a6.25 6.25 0 0 0-12.5 0c0 2.76-.645 4.873-1.079 5.62a1.25 1.25 0 0 0 1.08 1.88h3.187a3.125 3.125 0 0 0 6.124 0h3.188a1.25 1.25 0 0 0 1.078-1.88ZM10 16.875a1.875 1.875 0 0 1-1.767-1.25h3.534A1.876 1.876 0 0 1 10 16.875Zm-6.25-2.5C4.352 13.341 5 10.944 5 8.125a5 5 0 0 1 10 0c0 2.816.647 5.213 1.25 6.25H3.75Z"
    />
  </svg>
);
export default NotificationIcon;
