import * as React from "react";

const EngagementsIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="m15.436 10.083-4.032-1.485L9.92 4.563a1.244 1.244 0 0 0-2.335 0L6.1 8.593l-4.035 1.485a1.244 1.244 0 0 0 0 2.335l4.03 1.493 1.484 4.033a1.243 1.243 0 0 0 2.335 0l1.484-4.032 4.035-1.484a1.244 1.244 0 0 0 0-2.335l.004-.005Zm-4.468 2.651a1.24 1.24 0 0 0-.737.737l-1.484 4.02-1.481-4.023a1.24 1.24 0 0 0-.734-.734l-4.02-1.484 4.02-1.484a1.24 1.24 0 0 0 .734-.734l1.484-4.02 1.484 4.02a1.24 1.24 0 0 0 .737.737l4.02 1.484-4.023 1.481Zm.282-9.609a.625.625 0 0 1 .625-.625h1.25V1.25a.625.625 0 1 1 1.25 0V2.5h1.25a.625.625 0 0 1 0 1.25h-1.25V5a.625.625 0 0 1-1.25 0V3.75h-1.25a.625.625 0 0 1-.625-.625Zm8.125 3.75a.625.625 0 0 1-.625.625h-.625v.625a.625.625 0 0 1-1.25 0V7.5h-.625a.625.625 0 1 1 0-1.25h.625v-.625a.625.625 0 1 1 1.25 0v.625h.625a.625.625 0 0 1 .625.625Z"
    />
  </svg>
);
export default EngagementsIcon;
