import React, { useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const EmojiPicker = props => {
  const { handleSetDefinedTemplate, messageText, textMode, text } = props;
  const handleEmoji = e => {
    textMode === 0
      ? handleSetDefinedTemplate(text.current.value + e.native)
      : handleSetDefinedTemplate(e.native);
  };

  return (
    <Picker data={data} theme="light" previewPosition="none" onEmojiSelect={e => handleEmoji(e)} />
  );
};

export default EmojiPicker;
