import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import styled from "styled-components";
import { UserContext } from "../../../amplify/authenticator-provider";
import { GET_ALL_USERS } from "../../../graphql/query";
import { Container } from "../../../library/basicComponents";
import { AccessPermissionAction } from "../../../library/constants";
import { checkAccessPermission } from "../../../library/helpers";
import SelectSchedule from "../../../library/selectSchedule";
import useLang from "../../../hooks/useLang";

const ContainerWrap = styled(Container)`
  margin-top: 10px;
  .ant-modal-body {
    padding-top: 10px;
  }
  border: 0;
`;

const AddOwner = ({ setLeadOwner, setPrimaryOwner, lead, setOwnerIdsata, ownerIdsData }) => {
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [selectedOwnerIds, setLeadOwnerIds] = useState([]);
  const [primaryOwnerId, setPrimaryOwnerId] = useState();
  const [lang] = useLang();

  const { loading: fetchLoading } = useQuery(GET_ALL_USERS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: { companyId: user.company.id, isGlobalLeadOwner: true },
    onCompleted: res => {
      const users = res?.getUsers?.data || [];
      const list = (users || []).map(el => ({
        id: el.id,
        name: `${el.fullName} (${el?.companyRole?.name || "N/A"})`,
      }));
      setUsers(list);
    },
  });

  useEffect(() => {
    if (lead?.leadUsers) {
      const ownerIds = (lead?.leadUsers || []).map(el => el?.user?.id);
      const primaryId = (lead?.leadUsers || []).length > 0 ? lead?.leadUsers[0]?.user?.id : "";
      setLeadOwnerIds(ownerIds);
      setPrimaryOwnerId(String(primaryId));
      if (setLeadOwner) {
        setLeadOwner(ownerIds);
        setPrimaryOwner(primaryId);
      }
    }
  }, [lead]);

  const leadOwners = users.filter(el => selectedOwnerIds.includes(el.id));
  const canEdit = checkAccessPermission(AccessPermissionAction.LEAD_OWNER_ADD_EDIT);

  const handleOwners = () => {
    const usersId = users.map(user => user?.id);
    const missingIds = selectedOwnerIds.filter(f => !usersId.includes(f));
    let missingOwnerArray;
    if (missingIds.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const id of missingIds) {
        missingOwnerArray = lead?.leadUsers.filter(owner => +owner?.user?.id === +id);
      }
      const userFinalArray = missingOwnerArray.map(ow => {
        return {
          id: ow.id,
          name: `${ow?.firstName} ${ow?.lastName} ${
            ow?.companyRole ? `(${ow?.companyRole?.name})` : "(N/A)"
          }`,
        };
      });
      setUsers([...users, ...userFinalArray]);
    }
  };

  useEffect(() => {
    users.length > 0 && handleOwners();
  }, [users]);

  useEffect(() => {
    setOwnerIdsata &&
      setOwnerIdsata({
        ...ownerIdsData,
        isOwnerIdsEmpty: selectedOwnerIds.length > 0,
        isPrimaryIdEmpty: !!primaryOwnerId,
      });
  }, [selectedOwnerIds, primaryOwnerId]);

  return (
    <ContainerWrap auto>
      <Row gutter={[4, 16]}>
        <Col xs={24} sm={24} md={16} xl={16} lg={16} id="antdOwnerDropdown">
          <label className="mb-[5px]">
            Owners<span className="requireFieldStar ml-[2px]">*</span>
          </label>
          <p>
            <SelectSchedule
              getPopupContainer={() => document.getElementById("antdOwnerDropdown")}
              mode="multiple"
              value={users.length > 0 ? selectedOwnerIds : undefined}
              placeholder={lang.selectOwnersLabel}
              showAll={false}
              onChange={e => {
                if (setLeadOwner) {
                  setLeadOwner(e);
                }
                setLeadOwnerIds(e);
              }}
              data={users}
              width="98%"
              size="large"
              loading={fetchLoading}
              isNeedToSearch
              disabled={!canEdit}
            />
          </p>
        </Col>
        <Col xs={24} sm={24} md={8} xl={8} lg={8} id="antdPrimaryOwnerDropdown">
          <label className="mb-[5px]">
            Primary Owner<span className="requireFieldStar ml-[2px]">*</span>
          </label>
          <SelectSchedule
            getPopupContainer={() => document.getElementById("antdPrimaryOwnerDropdown")}
            value={users.length > 0 ? primaryOwnerId : undefined}
            placeholder="Select primary owner"
            showAll={false}
            onChange={e => {
              setPrimaryOwner(e);
              setPrimaryOwnerId(e);
            }}
            data={leadOwners}
            width="100%"
            size="large"
            loading={fetchLoading}
          />
        </Col>
        {/* <Col
          xs={24}
          sm={24}
          md={8}
          xl={8}
          lg={8}
          style={{ textAlign: "right", paddingTop: 25 }}
        >
          <InlineFormButton
            type={"primary"}
            onClick={submitVehicle}
            loading={loading}
          >
            {editVehicle ? "Update" : "Add"}
          </InlineFormButton>
        </Col> */}
      </Row>
      {/* </FormCntr> */}
      {/* <FormCntr></FormCntr>
      <ContactListTable
        vehiclesList={vehicles}
        onDeleteVehicle={removeVehicle}
        onEditVehicle={handleEdit}
      /> */}
    </ContainerWrap>
  );
};
export default AddOwner;
