import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { AccessPermissionAction } from "../../../../library/constants";
import { checkAccessPermission } from "../../../../library/helpers";

const OpportuityStage = props => {
  const {
    lead,
    APIHandler,
    oldStatus,
    crmIntegrationType = "",
    isSFOppr,
    isOpprOverall,
    opportunityItem,
    opportunityStatusList,
    loading,
    confirmModalOpen,
    setConfirmModalOpen,
    confirmLoader,
  } = props;

  const [oppStatus, setOppStatus] = useState(+opportunityItem.opportunityStatusId);
  const [stateLablel, setStateLabel] = useState("");

  useEffect(() => {
    setOppStatus(+opportunityItem.opportunityStatusId);
  }, [opportunityItem.opportunityStatusId]);

  const handleLeadStatusUpdation = () => {
    APIHandler(oppStatus, opportunityItem);
  };

  const handleConfirmBtn = () => {
    handleLeadStatusUpdation();
  };

  const canAddEditOpportunity = checkAccessPermission(
    AccessPermissionAction.LEAD_OPPORTUNITY_MANAGEMENT_ADD_EDIT
  );

  return (
    <div>
      <div>
        <Select
          loading={loading}
          value={+oppStatus}
          placeholder={isOpprOverall ? "Select Opportunity Stage" : "Select Status"}
          onChange={value => {
            setOppStatus(value);
            const obj = opportunityStatusList.find(item => +item.id === value);
            setStateLabel(obj.name || "");
            setConfirmModalOpen(true);
          }}
          disabled={
            !canAddEditOpportunity ||
            (crmIntegrationType === "SF" && isSFOppr && stateLablel?.toLowerCase() === "duplicate")
          }
          size="middle"
          style={{ width: "150px" }}
        >
          {(opportunityStatusList || []).map((opportunity, index) => (
            <Select.Option disabled={!opportunity.isActive} key={index} value={+opportunity.id}>
              {opportunity.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      {confirmModalOpen && (
        <Modal
          title="Change Opportunity Status"
          width={600}
          open={confirmModalOpen}
          // onOk={handleOKBtn}
          onCancel={() => setConfirmModalOpen(false)}
          // confirmLoading={updateLeadLoader}
          footer={null}
        >
          {/* <p>
            Do you want to change the {isSFOppr ? "stage" : "status"} from{" "}
            <strong>{oldStatus || ""}</strong> to <strong>{stateLablel}</strong> for{" "}
            <strong>{opportunityItem.name}</strong> of lead <strong>{lead.fullName}</strong>?
          </p> */}
          <div className="flex flex-col items-start gap-1">
            <span>
              Lead Name: <b>{lead.fullName}</b>
            </span>
            <span>
              Opportunity Name: <b>{opportunityItem.name}</b>
            </span>
            <span>
              Current Status: <b>{oldStatus}</b>
            </span>
            <span>
              New Status: <b>{stateLablel}</b>
            </span>
          </div>
          <div className="flex flex-row items-center gap-1 mt-1 justify-end">
            <Button
              key="submit"
              type="primary"
              loading={confirmLoader}
              onClick={() => handleConfirmBtn()}
            >
              Yes
            </Button>
            <Button key="back" onClick={() => setConfirmModalOpen(false)}>
              No
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OpportuityStage;
