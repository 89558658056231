/* eslint-disable react/destructuring-assignment */
import * as React from "react";

const Calendar12Icon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#3B82F6"}
      d="M20 3h-2.25v-.75a.75.75 0 1 0-1.5 0V3h-7.5v-.75a.75.75 0 0 0-1.5 0V3H5a1.5 1.5 0 0 0-1.5 1.5v15A1.5 1.5 0 0 0 5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 20 3Zm-9 14.25a.75.75 0 1 1-1.5 0v-4.787l-.414.208a.75.75 0 0 1-.672-1.342l1.5-.75A.751.751 0 0 1 11 11.25v6Zm5.25-.75a.75.75 0 1 1 0 1.5h-3a.75.75 0 0 1-.6-1.2l2.698-3.597a.75.75 0 1 0-1.248-.828.75.75 0 1 1-1.298-.75 2.25 2.25 0 1 1 3.744 2.48L14.75 16.5h1.5ZM5 7.5v-3h2.25v.75a.75.75 0 0 0 1.5 0V4.5h7.5v.75a.75.75 0 1 0 1.5 0V4.5H20v3H5Z"
    />
  </svg>
);
export default Calendar12Icon;
