import * as React from "react";

const CheckBoxIcon = props => (
  <div className="p-1 bg-brand-500 rounded-sm">
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={10} fill="none" {...props}>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m1.5 5.217 3.6 3.53 7.65-7.5"
      />
    </svg>
  </div>
);
export default CheckBoxIcon;
