import { Calendar, Form, Radio, Row, Switch, TimePicker } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import DialogFooter from "../../commonComponents/dialogFooter";
import { closeDialog } from "../../../library/helpers";
import { CREATE_USER_SCHEDULE, UPDATE_USER_SCHEDULE } from "../../../graphql/mutation";
import { getLocationId } from "../../../redux/slices/locationSlice";

export default function WorkingHourOverrideForm(props) {
  const {
    customHours,
    setcustomHours,
    edit,
    date,
    item,
    selectedCalendar,
    setIsCustomeHourChange,
  } = props;
  const [createUserWorkingHours] = useMutation(CREATE_USER_SCHEDULE);
  const [updateUserWorkingHours] = useMutation(UPDATE_USER_SCHEDULE);
  const locationIdslice = useSelector(state => getLocationId(state));
  const [dates, setDates] = useState([]);
  const [formData, setformData] = useState({
    startTime: dayjs("9:00", "HH:mm").toISOString(),
    endTime: dayjs("18:00", "HH:mm").toISOString(),
    date: dayjs().toISOString(),
    available: true,
    holidayType: "fullDay",
  });
  const handleSave = () => {
    console.log("dayjs====>", formData.startTime);
    console.log(
      "dayjs====>",
      `${dayjs(dayjs(formData?.date)?.$d).format("YYYY-MM-DD")}T${
        dayjs(formData?.startTime).toISOString().split("T")[1]
      }`
    );
    try {
      // if (edit) {
      //   setcustomHours(prev => {
      //     const temp = [...prev];
      //     const index = temp.findIndex(ele => dayjs(ele.date).isSame(item.date, "date"));
      //     temp[index] = formData;
      //     return [...temp];
      //   });
      // } else {
      //   setcustomHours(prev => {
      //     const temp = [...prev];
      //     temp.push(formData);
      //     return [...temp];
      //   });
      // }
      if (edit) {
        updateUserWorkingHours({
          variables: {
            id: item?.id,
            fromDatetime: `${dayjs(dayjs(formData?.date)?.$d).format("YYYY-MM-DD")}T${
              dayjs(formData?.startTime).toISOString().split("T")[1]
            }`,
            isAvailable: formData?.available,
            name: selectedCalendar,
            toDatetime: `${dayjs(dayjs(formData?.date)?.$d).format("YYYY-MM-DD")}T${
              dayjs(formData?.endTime).toISOString().split("T")[1]
            }`,
            locationId: locationIdslice,
            isFullDay: formData?.holidayType === "fullDay",
          },
          onCompleted: res => {
            if (res?.updateUserSchedule?.statusCode === 200) {
              closeDialog("dateOverride");
              setIsCustomeHourChange();
            }
          },
        });
      } else {
        createUserWorkingHours({
          variables: {
            fromDatetime: `${dayjs(dayjs(formData?.date)?.$d).format("YYYY-MM-DD")}T${
              dayjs(formData?.startTime).toISOString().split("T")[1]
            }`,
            isAvailable: formData?.available,
            name: selectedCalendar,
            toDatetime: `${dayjs(dayjs(formData?.date)?.$d).format("YYYY-MM-DD")}T${
              dayjs(formData?.endTime).toISOString().split("T")[1]
            }`,
            locationId: locationIdslice,
            isFullDay: formData?.holidayType === "fullDay",
          },
          onCompleted: res => {
            if (res?.createUserSchedule?.statusCode === 200) {
              closeDialog("dateOverride");
              setIsCustomeHourChange();
            }
          },
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    if (item) {
      // Edit custom working hours
      setformData(item);
    }
  }, [item]);
  function toValidArray(value) {
    const arr = Array.isArray(value) ? value : [value];

    return arr.filter(e => e != null && e !== undefined); // must be ==
  }

  const arrValues = useMemo(() => toValidArray(dates), [dates]);
  // eslint-disable-next-line no-unused-vars, no-underscore-dangle
  const _onChange = useCallback(
    selected => {
      const index = arrValues.findIndex(e => e.isSame(selected));
      const temp = [...arrValues];

      if (index !== -1) {
        temp.splice(index, 1);
      } else {
        temp.push(selected);
      }

      setDates(temp);
    },
    [arrValues, setDates]
  );
  const onDeselect = useCallback(
    oldSelect => {
      const newVal = arrValues.filter(e => !e.isSame(oldSelect));
      setDates(newVal);
    },
    [arrValues, setDates]
  );
  const customRenderDate = useCallback(
    current => {
      if (arrValues.some(e => current.isSame(e))) {
        return <div className="selectedDate">{current.format("DD")}</div>;
      }

      return <div>{current.format("DD")}</div>;
    },
    [arrValues]
  );
  return (
    <div>
      <Calendar
        fullscreen={false}
        // dateFullCellRender={customRenderDate}
        value={dayjs(formData?.date)}
        onSelect={date => {
          setformData({ ...formData, date: dayjs(date).toISOString() });
        }}
      />
      {formData?.date && (
        <div className="mt-3">
          <div className="flex justify-between">
            <b>What hours are you available or unavailable?</b>
            <Switch
              checkedChildren="Available"
              unCheckedChildren="Unavailable"
              checked={formData.available}
              onChange={e => setformData({ ...formData, available: e })}
            />
          </div>
          {console.log("holidayType=====>", formData && formData.holidayType)}
          <div className="mt-2">
            <Radio.Group
              name="holidayType"
              value={formData && formData.holidayType}
              onChange={e => setformData({ ...formData, holidayType: e.target.value })}
            >
              <Radio value="fullDay">Full Day</Radio>
              <Radio value="custom">Custom Time</Radio>
            </Radio.Group>
          </div>
          {/* {formData.available && ( */}
          {/* <> */}
          <Row>
            <TimePicker
              format="h:mm A"
              value={dayjs(formData.startTime)}
              onSelect={date => {
                setformData({ ...formData, startTime: dayjs(date).toISOString() });
              }}
              className="mt-4 w-full"
            />
          </Row>
          <Row>
            <TimePicker
              format="h:mm A"
              value={dayjs(formData.endTime)}
              onSelect={date => {
                setformData({ ...formData, endTime: dayjs(date).toISOString() });
              }}
              className="mt-4 w-full"
            />
          </Row>
          {/* </> */}
          {/* )} */}
        </div>
      )}
      {formData?.date && (
        <DialogFooter handleConfirm={handleSave} handleCancel={() => closeDialog("dateOverride")} />
      )}
    </div>
  );
}
