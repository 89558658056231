import * as React from "react";

const VideoIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#334155"
      stroke="#334155"
      strokeWidth={0.078}
      d="M12.825 8.887a.586.586 0 0 1 0 .976l-3.75 2.5a.587.587 0 0 1-.91-.488v-5a.586.586 0 0 1 .91-.488l3.75 2.5Zm-3.49 1.82v.073l.062-.04 1.998-1.332.049-.033-.049-.033-1.998-1.328-.061-.04v2.733ZM3.75 3.164h12.5A1.836 1.836 0 0 1 18.086 5v8.75a1.836 1.836 0 0 1-1.836 1.836H3.75a1.836 1.836 0 0 1-1.836-1.836V5A1.836 1.836 0 0 1 3.75 3.164ZM16.72 14.22a.664.664 0 0 0 .194-.47V5a.664.664 0 0 0-.664-.664H3.75A.664.664 0 0 0 3.086 5v8.75a.664.664 0 0 0 .664.664h12.5c.176 0 .345-.07.47-.194Zm-3.634 3.28a.586.586 0 0 1-.586.586h-5a.586.586 0 1 1 0-1.172h5a.586.586 0 0 1 .586.586Z"
    />
  </svg>
);
export default VideoIcon;
