/* eslint-disable default-param-last */

const DOMAINS = {
  usAutoSales: "https://usautosales.lightning.force.com",
  DealerSocket: "https://bb.dealersocket.com",
  VinSolution: "https://vinsolutions.app.coxautoinc.com",
  ELead: "https://www.eleadcrm.com",
  dealerTeam:
    process.env.GATSBY_APP_ENVIRONMENT === "prod"
      ? "https://aesvans.my.salesforce.com"
      : "https://socialminingai-devorg-dev-ed.lightning.force.com",
};
/**
 * @param crmType String (DS | SF | VIN)
 * @param isOpportunity Boolean (true: opportunity | false: lead)
 * @param opportunityId Int (Opportunity id)
 * @param leadId Int (Only Required for DS)
 * @param salesForceType String (Domain Salesforce CRM, required when crmType is SF)
 * @return Link (String)
 * */

export const crmLinkGeneration = (
  crmType,
  isOpportunity = true,
  opportunityId,
  leadId,
  salesForceType = "SF",
  companyId = 0
) => {
  try {
    let Link = null;
    if (crmType === "DS") {
      if (isOpportunity) {
        // opportunity link
        Link = `${DOMAINS.DealerSocket}/#/crm/sales/view/${leadId}/${opportunityId}`;
      } else {
        // lead link
        Link = `${DOMAINS.DealerSocket}/#/crm/view/contact/${leadId}`;
      }
    } else if (crmType === "SF") {
      if (isOpportunity) {
        // opportunity link
        Link = `${DOMAINS[salesForceType]}/lightning/r/Opportunity/${opportunityId}/view`;
      } else {
        // lead link
        Link = `${DOMAINS[salesForceType]}/lightning/r/Lead/${leadId}/view`;
      }
    } else if (crmType === "DT") {
      if (process.env.GATSBY_APP_ENVIRONMENT === "prod" && companyId === "167") {
        Link = `https://worktrailer.my.salesforce.com/lightning/r/dealer__Sales_Up__c/${opportunityId}/view`;
      } else if (isOpportunity) {
        // opportunity link
        Link = `${DOMAINS[salesForceType]}/lightning/r/dealer__Sales_Up__c/${opportunityId}/view`;
      } else {
        // lead link
        Link = `${DOMAINS[salesForceType]}/lightning/r/Account/${opportunityId}/view`;
      }
    } else if (crmType === "VIN") {
      Link = `${DOMAINS.VinSolution}/vinconnect/#/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=HIDE&rightpaneframe=/CarDashboard/Pages/CRM/CustomerDashboard.aspx?GlobalCustomerID=${opportunityId}`;
    } else if (crmType === "EL") {
      Link = `${
        DOMAINS?.ELead
      }/evo2/fresh/elead-v45/elead_track/search/searchresults.asp?Go=2&searchexternal=&corpStore=false&st=0&lIUID=&etitle=&lDID=&PID=&origStrDo=&qs=true&q=${(
        opportunityId || ""
      ).replace(/ /g, "+")}#`;
    }
    return Link;
  } catch (err) {
    console.error(err);
  }
};
