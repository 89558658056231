/* eslint-disable no-use-before-define */
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { Col, message, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../amplify/authenticator-provider";
import { UPDATE_LEAD } from "../../../graphql/mutation";
import { GET_ALL_USERS } from "../../../graphql/query";
import { closeDialog } from "../../../library/helpers";
import { getStatements } from "../../../redux/slices/languageSlice";
import DialogFooter from "../../commonComponents/dialogFooter";
import AddContactForm from "../../life-events/customerList/addOwner";
import EditOwnerDialog from "../../life-events/customerList/editOwnerDialog";

export default function EditLeadOwner({ lead, visibleModal, refetch, crmIntegration, type }) {
  const lang = useSelector(state => getStatements(state));
  const [leadOwners, setLeadOwners] = useState([]);
  const [primaryOwner, setPrimaryOwner] = useState();
  const isCrmIntegrated = !!crmIntegration;
  const [isSFOppo, setIsSFOppr] = useState(false);
  const [isDSOppo, setIsDSOppr] = useState(false);
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (crmIntegration) {
      handleCrmOppr();
      getAllUsers();
    }
  }, []);

  const [getAllUsers, { loading: fetchLoading }] = useLazyQuery(GET_ALL_USERS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: { companyId: user.company.id, isGlobalLeadOwner: true },
    onCompleted: res => {
      const users = res?.getUsers?.data || [];
      const list = (users || []).map(el => ({
        value: el.id,
        label: `${el.fullName} (${el?.companyRole?.name || "N/A"})`,
      }));
      setUsers(list);
    },
  });

  const handleCrmOppr = () => {
    if (crmIntegration === "SF") {
      if (
        lead?.leadOpportunities?.length > 0 &&
        lead?.leadOpportunities[0]?.sfLeadOpportunities?.length > 0 &&
        lead?.leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId
      ) {
        setIsSFOppr(true);
      } else {
        setIsSFOppr(false);
      }
    }

    if (crmIntegration === "DS") {
      // if (
      //   lead?.leadOpportunities?.length > 0 &&
      //   lead?.leadOpportunities[0]?.dsOpportunityEvents?.length > 0 &&
      //   lead?.leadOpportunities[0]?.dsOpportunityEvents[0]?.dsEventId
      // ){
      //   setIsDSOppr(true)
      // }else{
      //   setIsDSOppr(false)
      // }
      setIsDSOppr(false);
    }
  };

  const [updateLead, { loading: updateLoading }] = useMutation(UPDATE_LEAD);

  const [ownerList, setOwnerList] = useState([]);
  const [ownerObj, setOwnerObj] = useState({});

  const handleCrmOwnerValidation = () => {
    let isDialogClose = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const owner of ownerList) {
      if (!owner.value && owner.required) {
        isDialogClose = true;
        message.error(`Please ${owner?.placeholder} Field`);
        break;
      }
    }
    if (!isDialogClose) {
      const ownerListWithoutCrm =
        ownerList.length > 0 &&
        ownerList.map((owner, index) => {
          return {
            userTypeId: owner?.id,
            userId: owner?.value,
          };
        });

      updateLead({
        variables: {
          ...lead,
          primaryOwnerId: null,
          ownerIds: ownerListWithoutCrm,
        },
      }).then(leadResp => {
        if (leadResp?.data?.updateLead?.statusCode === 200) {
          message.success(leadResp?.data?.updateLead?.message);
          refetch();
          closeDialog("editOwnerDialog");
        }
      });
    }
    !isDialogClose && closeDialog("editOwnerDialog");
  };

  const getCrmOwnerData = (ownerList, APIObj) => {
    setOwnerList([...ownerList]);
    setOwnerObj([...APIObj]);
  };

  const handleSaveLead = () => {
    if (isEmpty(primaryOwner)) {
      message.error(lang.primaryOwnerError);
      return;
    }
    if (isEmpty(leadOwners)) {
      message.error(lang.ownersError);
      return;
    }
    const ownerListWithoutCrm =
      leadOwners.length > 0 &&
      leadOwners.map((owner, index) => {
        return {
          userTypeId: null,
          userId: owner,
        };
      });
    updateLead({
      variables: {
        ...lead,
        primaryOwnerId: primaryOwner,
        ownerIds: ownerListWithoutCrm,
      },
    }).then(leadResp => {
      if (leadResp?.data?.updateLead?.statusCode === 200) {
        message.success(leadResp?.data?.updateLead?.message);
        refetch();
        closeDialog("editOwnerDialog");
      }
    });
  };

  return (
    <Row>
      {isCrmIntegrated ? (
        <Col span={24}>
          <EditOwnerDialog
            lead={lead}
            lang={lang}
            getCrmOwnerData={getCrmOwnerData}
            crmIntegration={crmIntegration}
            fetchLoading={fetchLoading}
            users={users}
            isCrmOppr={
              crmIntegration === "SF" ? isSFOppo : crmIntegration === "DS" ? isDSOppo : false
            }
          />
        </Col>
      ) : (
        <Col span={24}>
          <AddContactForm
            lang={lang}
            setLeadOwner={setLeadOwners}
            setPrimaryOwner={setPrimaryOwner}
            lead={lead}
          />
        </Col>
      )}
      <DialogFooter
        loading={updateLoading}
        handleConfirm={!crmIntegration ? handleSaveLead : handleCrmOwnerValidation}
        handleCancel={() => closeDialog("editOwnerDialog")}
      />
    </Row>
  );
}
