import FacebookIcon from "../../static/icons/FacebookIcon.svg";
import TwitterIcon from "../../static/icons/TwitterIcon.svg";
import ForumIcon from "../../static/icons/ChatIcon2.svg";
import RedditIcon from "../../static/icons/RedditIcon.svg";
import CraiglistIcon from "../../static/icons/CraiglistIcon.svg";

export const Colors = {
  lightBlue: "#CDEBF9",
  blue: "#079BE2",
  lightBrandBlue: "#E6F0FF",
  primaryBrandBlue: "#006593",
  lightPrimaryBrandBlue: "#007dae",
  darkPrimaryBrandBlue: "#005278",
  lightPurple: "#E4B6D4",
  brandPurple: "#A70E72",
  darkBrandPurple: "#8E0B61",
  lightGreen: "#E2F9F0",
  green: "#3DD57F",
  lightOrange: "#FFEDC6",
  orange: "#FFBE42",
  darkOrange: "#d29e35",
  lightRed: "#FFE7E7",
  red: "#FC5A5A",
  darkRed: "#d25454",
  white: "#FFFFFF",
  backgroundGray: "#F5F6FA",
  veryLightGray: "#EEEEF1",
  lightGray: "#EEEEF1",
  mediumGray: "#BDC4CB",
  gray: "#C4C1C2",
  medDarkgray: "#999697",
  darkGray: "#5E5E5E",
  disabledGray: "#D2D3D8",
};

export const ChartColors = [
  Colors.green,
  Colors.orange,
  Colors.brandPurple,
  Colors.primaryBrandBlue,
  Colors.red,
  Colors.blue,
];

export const LightColorPairs = [
  {
    light: Colors.lightBrandBlue,
    standard: Colors.primaryBrandBlue,
  },
  {
    light: Colors.lightOrange,
    standard: Colors.orange,
  },
  {
    light: Colors.lightPurple,
    standard: Colors.brandPurple,
  },
  {
    light: Colors.lightGreen,
    standard: Colors.green,
  },
  {
    light: Colors.lightRed,
    standard: Colors.red,
  },
  {
    light: Colors.lightBlue,
    standard: Colors.blue,
  },
];

export const TagColors = tag => {
  switch (tag) {
    case "Buy Car":
      return ChartColors[0];
    case "Sell Car":
      return ChartColors[1];
    case "New":
      return ChartColors[2];
    case "Used":
      return ChartColors[3];
    case "Parts":
      return ChartColors[4];
    case "Service":
      return ChartColors[5];
    default:
      return null;
  }
};

// TODO: Corner roundness?
export const Outlines = {
  thick: "4px",
  regular: "2px",
  thin: "1px",
};

// TODO: Cards

// TODO: Separators
export const Separators = (weight, color) => {
  const weights = {
    thin: "1px",
    medium: "2px",
    thick: "3px",
  };
  return `${weights[weight]} solid ${Colors[color]}`;
};

export const ProspectStatus = {
  REJECTED: "Rejected",
  SAVED: "Saved",
  ACCEPTED: "Accepted",
  VIEWED: "Viewed",
};

// Add more if necessary
export const SourceFilters = ["Others", "Forums", "Craiglist", "Quora", "Yelp", "Facebook"];
export const SourceTypes = {
  FORUM: ForumIcon,
  CRAIGSLIST: CraiglistIcon,
  FACEBOOK: FacebookIcon,
  TWITTER: TwitterIcon,
  REDDIT: RedditIcon,
};

export const DealStatus = {
  ACTIVE: "Active",
  DEAL_WON: "Deal Won",
  DEAL_LOST: "Deal Lost",
  NO_RESPONSE: "No response",
  PUSHED_CRM: "Pushed to CRM",
  EXPIRED: "Expired",
  ARCHIVED: "Archive",
};

export const DealStatusFilters = ["Active", "Deal Won", "Deal Lost"];

export const RangeFilters = [
  { value: 1, name: "Today" },
  { value: 3, name: "3 Days" },
  { value: 7, name: "Week" },
  { value: 14, name: "2 weeks" },
  { value: null, name: "All Posts" },
];

export const DealStatusSelects = [
  { value: "ACTIVE", display: "Active" },
  { value: "DEAL_WON", display: "Deal Won" },
  { value: "DEAL_LOST", display: "Deal Lost" },
  { value: "ARCHIVED", display: "Archive" },
];

// TODO: complete with correct industries
export const Industries = ["Industry 1", "Industry 2", "Industry 3"];

export const BAR = "BAR";
export const LINE = "LINE";
export const TREE = "TREE";
export const graphTypes = { BAR, LINE, TREE };

export const timezones = [
  { value: "America/Juneau", label: "Alaska Daylight Time", shortName: "AST" },
  {
    value: "America/Chicago",
    label: "Central Daylight Time (North America)",
    shortName: "CST",
  },
  {
    value: "America/New_York",
    label: "Eastern Daylight Time (North America)",
    shortName: "EST",
  },
  {
    value: "Pacific/Honolulu",
    label: "Hawaii-Aleutian Daylight Time",
    shortName: "HAST",
  },
  {
    value: "America/Denver",
    label: "Mountain Daylight Time (North America)",
    shortName: "MST",
  },
  {
    value: "America/Los_Angeles",
    label: "Pacific Daylight Time (North America)",
    shortName: "PST",
  },
  { value: "Asia/Calcutta", label: "Asia/Calcutta", shortName: "IST" },
];

export const intervals = [
  { name: "30 min", value: "30" },
  { name: "1 Hour", value: "60" },
  { name: "2 Hours", value: "120" },
  { name: "4 Hours", value: "240" },
  { name: "8 Hours", value: "480" },
  { name: "24 Hours", value: "1440" },
];

export const FilterSetTypes = {
  ANALYTICS: "ANALYTICS",
  PROSPECTS: "PROSPECTS",
  EXPORT: "EXPORT",
  LIFE_EVENTS: "LIFE_EVENTS",
  GENERAL: "GENERAL",
  ENGAGEMENTS: "ENGAGEMENTS",
};

export const NOT_ENOUGH_DATA =
  "Not enough data to display.\n Let's work on some leads and check back later.";

// filters
export const KEYWORD = "Keyword";
export const LOCATION = "Location";
export const SOURCES = "Sources";
export const MAKE = "Make";
export const MULTISELECT = "Multiselect";
export const SELECT = "Select";
export const ALL = "All";

// personalized ads
export const COUNTS = [100, 200, 300, 400];
export const FREQUENCY = [
  ["1 Day", 1],
  ["1 Week", 7],
  ["1 Month", 30],
];

export const TypeFiltersSelects = [
  { value: "ALL", display: "All" },
  { value: "PROSPECTS", display: "Prospects" },
  { value: "LIFE_EVENT", display: "Life Events" },
];

export const LEAD_TYPE_OPTION = [
  {
    key: "",
    value: "",
    label: "Select the type of lead",
    disabled: true,
  },
  {
    key: "sales",
    value: "sales",
    label: "Sales",
    disabled: false,
  },
  {
    key: "parts",
    value: "parts",
    label: "Parts",
    disabled: false,
  },
  {
    key: "service",
    value: "service",
    label: "Service",
    disabled: false,
  },
];

export const CONDITION_OPTION = [
  {
    key: "",
    value: "",
    label: "Select the condition",
    disabled: true,
  },
  {
    key: "new",
    value: "new",
    label: "New",
    disabled: false,
  },
  {
    key: "used",
    value: "used",
    label: "Used",
    disabled: false,
  },
];

export const INTEREST_OPTION = [
  {
    key: "",
    value: "",
    label: "Select the interest",
    disabled: true,
  },
  {
    key: "buy",
    value: "buy",
    label: "Buy",
    disabled: false,
  },
  {
    key: "sell",
    value: "sell",
    label: "Sell",
    disabled: false,
  },
];
export const ordinalNumbers = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelfth",
  "Thirteenth",
  "Fourteenth",
  "Fifteenth",
  "Sixteenth",
  "Seventeenth",
  "Eighteenth",
  "Nineteenth",
  "Twentieth",
  "Twenty-First",
  "Twenty-Second",
  "Twenty-Third",
  "Twenty-Fourth",
  "Twenty-Fifth",
];
export const LABELS = [
  {
    label: "{firstName}",
    addKeyword: "{firstName}",
  },
  {
    label: "{lastName}",
    addKeyword: "{lastName}",
  },
  {
    label: "{sourceName}",
    addKeyword: "{sourceName}",
  },
  {
    label: "{companyName}",
    addKeyword: "{companyName}",
  },
  {
    label: "{vehicleOfInterest}",
    addKeyword: "{vehicleOfInterest}",
  },
  {
    label: "{tradeVehicle}",
    addKeyword: "{tradeVehicle}",
  },
  {
    label: "{locationName}",
    addKeyword: "{locationName}",
  },
  {
    label: "{createAppointmentUrl}",
    addKeyword: "{createAppointmentUrl}",
  },
];
export const AccessPermissionAction = {
  ACCESS_LOCATION_MANAGEMENT: "ACCESS_LOCATION_MANAGEMENT",
  LOCATION_VIEW: "LOCATION_MANAGEMENT_VIEW",
  LOCATION_ADD_EDIT: "LOCATION_MANAGEMENT_ADD_EDIT",
  LOCATION_DELETE: "LOCATION_MANAGEMENT_DELETE",
  LOCATION_MULTIPPLE_ACCESS: "ACCESS_LOCATION_MANAGEMENT_MULTIPPLE_ACCESS",
  TEMPLATE_VIEW: "TEMPLATE_MANAGEMENT_VIEW",
  TEMPLATE_ADD_EDIT: "TEMPLATE_MANAGEMENT_ADD_EDIT",
  TEMPLATE_DELETE: "TEMPLATE_MANAGEMENT_DELETE",
  TEMPLATE_TAB_VIEW: "TEMPLATE_MANAGEMENT_TAB_VIEW",
  CRM_INTEGRATION_VIEW: "CRM_INTEGRATION_MANAGEMENT_VIEW",
  CRM_INTEGRATION_ADD_EDIT: "CRM_INTEGRATION_MANAGEMENT_ADD_EDIT",
  CRM_INTEGRATION_DELETE: "CRM_INTEGRATION_MANAGEMENT_DELETE",
  APPOINTMENT_VIEW: "APPOINTMENT_MANAGEMENT_VIEW",
  APPOINTMENT_UPDATE: "APPOINTMENT_MANAGEMENT_UPDATE",
  APPOINTMENT_CANCEL: "APPOINTMENT_MANAGEMENT_CANCEL",
  APPOINTMENT_SCHEDULE: "APPOINTMENT_MANAGEMENT_SCHEDULE",
  APPOINTMENT_TAB_VIEW: "APPOINTMENT_MANAGEMENT_TAB_VIEW",
  CAMPAIGN_VIEW: "CAMPAIGN_MANAGEMENT_VIEW",
  CAMPAIGN_CREATE: "CAMPAIGN_MANAGEMENT_CREATE",
  CAMPAIGN_DELETE: "CAMPAIGN_MANAGEMENT_DELETE",
  CAMPAIGN_TAB_VIEW: "CAMPAIGN_MANAGEMENT_TAB_VIEW",
  REVIEW_SETTING_VIEW: "REVIEW_SETTING_MANAGEMENT_VIEW",
  REVIEW_SETTING_ADD_EDIT: "REVIEW_SETTING_MANAGEMENT_ADD_EDIT",
  REVIEW_SETTING_DELETE: "REVIEW_SETTING_MANAGEMENT_DELETE",
  PHONE_BOT_VIEW: "PHONE_BOT_MANAGEMENT_VIEW",
  PHONE_BOT_ADD_EDIT: "PHONE_BOT_MANAGEMENT_ADD_EDIT",
  PHONE_BOT_DELETE: "PHONE_BOT_MANAGEMENT_DELETE",
  WORKING_HOUR_VIEW: "WORKING_HOUR_MANAGEMENT_VIEW",
  WORKING_HOUR_UPDATE: "WORKING_HOUR_MANAGEMENT_UPDATE",
  LEAD_VIEW: "LEAD_MANAGEMENT_VIEW",
  LEAD_ADD_EDIT: "LEAD_MANAGEMENT_ADD_EDIT",
  LEAD_DELETE: "LEAD_MANAGEMENT_DELETE",
  LEAD_OWNER_VIEW: "LEAD_OWNER_MANAGEMENT_VIEW",
  LEAD_OWNER_ADD_EDIT: "LEAD_OWNER_MANAGEMENT_ADD_EDIT",
  LEAD_OWNER_DELETE: "LEAD_OWNER_MANAGEMENT_DELETE",
  LEAD_VEHICLE_VIEW: "LEAD_VEHICLE_MANAGEMENT_VIEW",
  LEAD_VEHICLE_ADD_EDIT: "LEAD_VEHICLE_MANAGEMENT_ADD_EDIT",
  LEAD_VEHICLE_DELETE: "LEAD_VEHICLE_MANAGEMENT_DELETE",
  LEAD_SEND_TO_STORE: "LEAD_SEND_TO_STORE",
  USER_VIEW: "USER_MANAGEMENT_VIEW",
  USER_ADD_EDIT: "USER_MANAGEMENT_ADD_EDIT",
  USER_DELETE: "USER_MANAGEMENT_DELETE",
  LEAD_CENTER_TAB_VIEW: "LEAD_CENTER_TAB_VIEW",
  ACTIVITY_CENTER_TAB_VIEW: "ACTIVITY_CENTER_TAB_VIEW",
  SCHEDULE_TAB_VIEW: "SCHEDULE_TAB_VIEW",
  DASHBOARD_TAB_VIEW: "DASHBOARD_TAB_VIEW",
  DASHBOARD_INDIVIDUAL_VIEW: "DASHBOARD_INDIVIDUAL_VIEW",
  DASHBOARD_COMPANY_WISE_VIEW: "DASHBOARD_COMPANY_WISE_VIEW",
  DASHBOARD_LOCATION_WISE_VIEW: "DASHBOARD_LOCATION_WISE_VIEW",
  LEAD_MANAGEMENT_PUSH_TO_CRM: "LEAD_MANAGEMENT_PUSH_TO_CRM",
  LEAD_MANAGEMENT_SEND_TO_STORE: "LEAD_MANAGEMENT_SEND_TO_STORE",
  USER_PROFILE_USER_INFORMATION: "USER_PROFILE_USER_INFORMATION",
  USER_PROFILE_USER_PASSWORD: "USER_PROFILE_USER_PASSWORD",
  TEAM_MANAGEMENT_CREATE: "TEAM_MANAGEMENT_CREATE",
  TEAM_MANAGEMENT_UPDATE: "TEAM_MANAGEMENT_UPDATE",
  TEAM_MANAGEMENT_VIEW: "TEAM_MANAGEMENT_VIEW",
  REGION_MANAGEMENT_CREATE: "REGION_MANAGEMENT_CREATE",
  REGION_MANAGEMENT_UPDATE: "REGION_MANAGEMENT_UPDATE",
  REGION_MANAGEMENT_VIEW: "REGION_MANAGEMENT_VIEW",
  REVIEW_MANAGEMENT_VIEW: "REVIEW_MANAGEMENT_VIEW",
  REVIEW_MANAGEMENT_RESPOND: "REVIEW_MANAGEMENT_RESPOND",
  REVIEW_MANAGEMENT_REQUEST_AND_TRACK: "REVIEW_MANAGEMENT_REQUEST_AND_TRACK",
  PROMPT_MANAGEMENT_VIEW: "PROMPT_MANAGEMENT_VIEW",
  PROMPT_MANAGEMENT_CREATE: "PROMPT_MANAGEMENT_CREATE",
  PROMPT_MANAGEMENT_ADD_EDIT: "PROMPT_MANAGEMENT_ADD_EDIT",
  PROMPT_MANAGEMENT_UPDATE: "PROMPT_MANAGEMENT_UPDATE",
  PROMPT_MANAGEMENT_DELETE: "PROMPT_MANAGEMENT_DELETE",
  LEAD_OPPORTUNITY_MANAGEMENT_VIEW: "LEAD_OPPORTUNITY_MANAGEMENT_VIEW",
  LEAD_OPPORTUNITY_MANAGEMENT_ADD_EDIT: "LEAD_OPPORTUNITY_MANAGEMENT_ADD_EDIT",
  LEAD_OPPORTUNITY_MANAGEMENT_DELETE: "LEAD_OPPORTUNITY_MANAGEMENT_DELETE",
  USER_CALENDAR_MANAGEMENT: "USER_CALENDAR_MANAGEMENT",
  TRANSLATION_MANAGEMENT_VIEW: "TRANSLATION_MANAGEMENT_VIEW",
  VDP_FEATURE_VIEW: "VDP_FEATURE_VIEW",
};

export const currencies = [
  { name: "United States Dollar", symbol: "$", code: "USD" },
  { name: "Euro", symbol: "€", code: "EUR" },
  { name: "Japanese Yen", symbol: "¥", code: "JPY" },
  { name: "British Pound Sterling", symbol: "£", code: "GBP" },
  { name: "Australian Dollar", symbol: "A$", code: "AUD" },
  { name: "Canadian Dollar", symbol: "CA$", code: "CAD" },
  { name: "Swiss Franc", symbol: "CHF", code: "CHF" },
  { name: "Chinese Yuan", symbol: "¥", code: "CNY" },
  { name: "Indian Rupee", symbol: "₹", code: "INR" },
  { name: "Brazilian Real", symbol: "R$", code: "BRL" },
  // Add more currencies as needed
];

export const EVENTS = "Events";

export const multiSelectFilterSelector = typeName => item =>
  item.typeName === typeName && item.type === MULTISELECT;
export const MODAL_CONTENT = "When clicked the OK button, it cannot be recovered";
export const MODAL_TITLE = "Do you want to continue?";

export const leadStats = [
  {
    label: "Total Leads",
    key: "totalLeads",
    color: "#DBEAFE",
  },
  {
    label: "Responded",
    key: "totalResponded",
    color: "#DCFCE7",
  },
  {
    label: "Reached",
    key: "totalDelivered",
    color: "#FEF3C7",
  },
  {
    label: "Engagements",
    key: "totalEngaged",
    color: "#FEE2E2",
  },
];

export const appointmentStats = [
  {
    label: "Scheduled",
    key: "scheduledAppointmentCount",
    color: "#DBEAFE",
  },
  {
    label: "Showed",
    key: "showedAppointmentCount",
    color: "#DCFCE7",
  },
  {
    label: "No-Showed",
    key: "noShowedAppointmentCount",
    color: "#FEF3C7",
  },
  {
    label: "Cancelled",
    key: "cancelledAppointmentCount",
    color: "#FEE2E2",
  },
];

export const SNIPPET_OPTIONS = [
  "{leadFullName}",
  "{leadFirstName}",
  "{leadLastName}",
  "{leadOwner}",
  "{appointmentSummary}",
  "{companyName}",
  "{appointmentDateTime}",
  "{locationUrl}",
  "{locationName}",
  "{locationAddress}",
  "{userName}",
  "{firstName}",
  "{lastName}",
  "{sourceName}",
  "{userName}",
  "{companyName}",
  "{locationName}",
  "{previousVehicle}",
  "{createAppointmentUrl}",
  "{vehicleOfInterest}",
  "{locationAddress}",
  "{locationUrl}",
  "{vehicleOfInterestDesc}",
];
export const MESSAGE_CONTENT_TYPES = {
  WELCOME_MESSAGE: "Welcome Message",
  // # LEAD
  NEW_MESSAGE_RECEIVED: "Received Message from Lead",
  NEW_MESSAGE_SENT: "Sent Message to Lead",
  CALLING_THE_LEAD: "Calling the lead",
  NEW_MESSAGE_REMINDER: "",
  // # REVIEW
  REVIEW_REQUEST_SENT: "Review Request sent to lead",
  REVIEW_RECEIVED_FROM_LEAD: "Received a review from a lead",
  GOOD_REVIEW_FROM_LEAD: "Lead has given a good review.",
  BAD_REVIEW_FROM_LEAD: "Lead has given a bad review.",
  CUSTOMER_COPIED_REVIEW_SUGGESTION: "Customer Copied Review Suggestion",
  CUSTOMER_GENERATED_REVIEW_SUGGESTION: "Customer Generated Review Suggestion",
  GOOGLE_REVIEW_LINK_CLICKED: "Google Review Link Clicked",
  FACEBOOK_REVIEW_LINK_CLICKED: "Facebook Review Link Clicked",
  YELP_REVIEW_LINK_CLICKED: "Yelp Review Link Clicked",
  // # APPOINTMENT
  SENT_APPOINTMENT_RESCHEDULE_LINK_TO_LEAD: "Sent Appointment Reschedule Link To Lead",
  NOT_ACTIVE_APPOINTMENT: "Not Active Appointments",
  NOT_ACTIVE_APPOINTMENT_TO_LEAD: "No Active Appointment Notification To Lead",
  APPOINTMENT_RESCHEDULE: "Rescheduled Appointment",
  APPOINTMENT_SCHEDULED_TO_USER: "Scheduled Appointment Notification To User",
  APPOINTMENT_CANCELLED: "Appointment Cancelled",
  APPOINTMENT_REMINDER_MESSAGE_TO_LEAD: "Appointment Reminder Message to Lead",
  NEW_APPOINTMENT_CREATED: "Create New Appointment",
  APPOINTMENT_CANCELLED_TO_LEAD: "Appointment Cancelled Notification To Lead",
  APPOINTMENT_SCHEDULED_TO_LEAD: "Scheduled Appointment Notification To Lead",
  APPOINTMENT_RESCHEDULE_TO_USER: "Rescheduled Appointment Notification To User",
  APPOINTMENT_CONFIRMED_TO_LEAD: "Confirmed Appointment Notification To Lead",
  NEW_APPOINTMENT_CREATED_TO_LEAD: "New Appointment Notification To Lead",
  REQUEST_TO_RESCHEDULE_APPOINTMENT: "Request to Reschedule Appointment",
  APPOINTMENT_CONFIRMED: "Appointment Confirmed",
  APPOINTMENT_CONFIRMED_NOTIFICATION_TO_USER: "Appointment Confirmed Notification To User",
  APPOINTMENT_CONFIRMED_NOTIFICATION_TO_LEAD: "Appointment Confirmed Notification To Lead",
  APPOINTMENT_RESCHEDULE_TO_LEAD: "Rescheduled Appointment Notification To Lead",
  APPOINTMENT_SCHEDULED: "Scheduled Appointment",
  // # CAMPAIGN
  CAMPAIGN_MESSAGE_TO_LEAD: "Campaign Message to Lead",
  SCHEDULED_MESSAGE_SENT: "Sent Scheduled Message To Lead",
  SCHEDULED_MESSAGE: "Scheduled Message To Lead",
  SCHEDULED_MESSAGE_CANCELLED: "Cancelled Scheduled Message To Lead",
};

export const NOTIFICATION_TYPES = {
  NEW_MESSAGE_FROM_LEAD: "NEW_MESSAGE_FROM_LEAD",
  APPOINTMENT_SCHEDULED: "APPOINTMENT_SCHEDULED",
  APPOINTMENT_RESCHEDULED: "APPOINTMENT_RESCHEDULED",
  APPOINTMENT_CONFIRMED: "APPOINTMENT_CONFIRMED",
  APPOINTMENT_SHOWED: "APPOINTMENT_SHOWED",
  APPOINTMENT_NO_SHOWED: "APPOINTMENT_NO_SHOWED",
  APPOINTMENT_CANCELLED: "APPOINTMENT_CANCELLED",
  REMINDER_MESSAGE: "REMINDER_MESSAGE",
  REVIEW_REQUEST: "REVIEW_REQUEST",
  RESPONSE_FROM_PROSPECT: "RESPONSE_FROM_PROSPECT",
  PROSPECTS: "PROSPECTS",
  LIFE_EVENTS: "LIFE_EVENTS",
  OTHER: "OTHER",
};
