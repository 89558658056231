/* eslint-disable no-use-before-define */
/* eslint-disable no-debugger */
import { useLazyQuery, useQuery } from "@apollo/client";
import dayGridPlugin from "@fullcalendar/daygrid"; //
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Alert, Badge, Col, Input, Popover, Radio, Row, Spin, Tag, Tooltip, message } from "antd";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import React, { useContext, useEffect, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { UserContext } from "../../amplify/authenticator-provider";
import { APPOINTMENT_LIST, GET_ALL_USERS, GET_COMPANY_WORK_HOURS } from "../../graphql/query";
import useLang from "../../hooks/useLang";
import { Container } from "../../library/basicComponents";
import { colorConfig } from "../../library/colorConfig";
import { AccessPermissionAction } from "../../library/constants";
import { checkAccessPermission, openDialog } from "../../library/helpers";
import SelectSchedule from "../../library/selectSchedule";
import { convertUtcToLocal, firstUpperCaseWordParser, getDifferedState } from "../../library/utils";
import {
  reducerSetAppointmentCalendarMode,
  reducerSetAppointmentRange,
  reducerSetAppointmentStatus,
  reducerSetAppointmentUserIds,
  reducerSetAppointmentUserMode,
} from "../../redux/slices/appointmentSlice";
import CustomButton from "../commonComponents/button";
import AppointmentList from "./appointmentList";
import "./calendar.css";
import UserSelector from "../commonComponents/userSelector";

const utc = require("dayjs/plugin/utc");

dayjs.extend(utc);
// import AppointmentForm from "./appointmentForm";
// import AppointmentList from "./appointmentList";

export const UlWrap = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  & .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & .ant-badge-status-text {
    font-size: 14px;
    background: #00a1c9;
    padding: 10px;
    color: #fff !important;
    border-radius: 12px;
  }
`;

export const LIWrap = styled.li`
  .ant-badge-status-text {
    background: ${props =>
      props.status === "error"
        ? colorConfig.cancelAppointement
        : props.status === "success"
        ? colorConfig.successAppointement
        : props.status === "default"
        ? colorConfig.secondaryDark
        : props.status === "noshow"
        ? colorConfig.noShowAppointment
        : props.status === "show"
        ? colorConfig.primaryDark
        : colorConfig.schedualStatusAppointment};
    color: ${colorConfig.WHITE};
  }

  @media (max-width: 992px) {
    height: 5px;
    margin: 2px 0;
    background: ${props =>
      props.status === "error"
        ? colorConfig.cancelAppointement
        : props.status === "success"
        ? colorConfig.successAppointement
        : props.status === "default"
        ? colorConfig.secondaryDark
        : props.status === "noshow"
        ? colorConfig.noShowAppointment
        : props.status === "show"
        ? colorConfig.primaryDark
        : colorConfig.schedualStatusAppointment};
    color: ${colorConfig.WHITE};
`;

const CalendarContainer = styled.div`
  border-right: 1px solid rgb(232, 232, 232);
  border-bottom: 1px solid rgb(232, 232, 232);
  padding: 10px 5px;
  & .ant-fullcalendar-fullscreen {
    font-family: Roboto, sans-serif !important;
    & .ant-fullcalendar-table .ant-fullcalendar-column-header-inner {
      font-size: 18px;
      font-weight: 600;
      padding: 10px 0;
    }
  }
  & .ant-fullcalendar-header {
    padding: 4px 16px 8px 0;
    . ant-radio-group {
      dispaly: none;
    }
  }
`;

const { Search } = Input;
let panelChangeDate = null;

const Appointments = props => {
  const {
    lead = null,
    onBackClick,
    onClick = () => {},
    leadOpportunities,
    handleLeadDetailProfile,
    eventId,
    assignedUserId,
    refetchLeads,
  } = props;

  const { user } = useContext(UserContext);
  const [lang] = useLang();
  const dispatch = useDispatch();
  const { filters } = useSelector(state => state.appointments);
  const { userIds, appointmentStatus, range, userMode, calendarMode } = filters;
  const { refetchAppointments, setrefetchAppointments } = useContext(UserContext);
  const [openAppointmentPopup, setAppointmentPopupVisible] = useState(false);
  const [filterSetting, setfilterSetting] = useState(2);
  const [appointment, setAppointment] = useState({});
  const calendarRef = React.createRef();
  const [defaultDate, setDefaultDate] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [search, setSearch] = useState("");
  const [userSearch, setuserSearch] = useState("");
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);
  const [userList, setUserList] = useState([
    { id: user?.id, name: `${user?.firstName} ${user?.lastName}`, ...user },
  ]);
  const [createdByUser, setcreatedByUser] = useState([]);
  useEffect(() => {
    if (user.id) {
      setSelectedUser([!isUndefined(assignedUserId) ? +assignedUserId : +user?.id]);
      setcreatedByUser([!isUndefined(assignedUserId) ? +assignedUserId : +user?.id]);
    }
  }, [assignedUserId, user.id]);

  const [filterValue, setfilterValue] = useState([
    "SCHEDULED",
    "NO_SHOWED",
    "SHOWED",
    "CANCELLED",
    "CONFIRMED",
    "RESCHEDULED",
    "VIRTUAL",
  ]);
  const [variables, setVariables] = useState({
    userIds: selectedUser,
    startDate: dayjs().utc().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().utc().endOf("month").format("YYYY-MM-DD"),
    companyId: user?.company?.id,
    createdByUserIds: createdByUser,
    appointmentStatus: filterValue,
    // page: 1,
    // pageSize: 10,
    orderBy: "id",
    orderDirection: "asc",
    dateFilter: null,
    leadOpportunities: [],
  });
  useEffect(() => {
    if (user) {
      setVariables({
        ...variables,
        companyId: user.company.id,
        createdByUserIds: createdByUser,
        userIds: selectedUser,
      });
    }
  }, [user, selectedUser, createdByUser]);
  const pagePath = window.location.pathname;
  const filterOption = sessionStorage.getItem("appointmentFilterType");

  useEffect(() => {
    if (filterOption) {
      setfilterSetting(+filterOption);
    }
  }, [filterOption]);

  useEffect(() => {
    // Your logic here
    // If you want to conditionally render a component, update the state.
    setShouldRenderComponent(true);
  }, []);
  const [calendarApi, setcalendarApi] = useState(null);
  useEffect(() => {
    if (calendarRef && calendarRef.current && calendarRef.current.getApi())
      setcalendarApi(calendarRef?.current?.getApi());
  }, [calendarRef, shouldRenderComponent]);

  const handleDateChanger = async () => {
    const calendarAPI = await getDifferedState(setcalendarApi);
    dispatch(
      reducerSetAppointmentRange({
        startDate: dayjs(calendarAPI?.currentData?.dateProfile?.activeRange?.start).format(
          "YYYY-MM-DD"
        ),
        endDate: dayjs(calendarAPI?.currentData?.dateProfile?.activeRange?.end)
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
      })
    );
    dispatch(reducerSetAppointmentCalendarMode(calendarAPI.currentData.currentViewType));
  };
  useEffect(() => {
    if (shouldRenderComponent && document) {
      const buttons = document?.querySelectorAll(".fc-button");
      buttons.forEach(button => {
        button.addEventListener("click", handleDateChanger);
      });
      return () => {
        buttons.forEach(button => {
          button.removeEventListener("click", handleDateChanger);
        });
      };
    }
  }, [document, shouldRenderComponent]);
  const { data: respWorkHrs } = useQuery(GET_COMPANY_WORK_HOURS, {
    variables: {
      companyId: user?.company?.id || 0,
      locationId: parseInt(sessionStorage.getItem("currentLocationId") || 0),
    },
  });
  const canAddUsers = checkAccessPermission("APPOINTMENT_MANAGEMENT_ALLOW_LOCATION_APPOINTMENTS");
  const canView = checkAccessPermission(AccessPermissionAction.APPOINTMENT_VIEW);

  const worksHours = respWorkHrs?.getCompanyWorkingHours || [];

  const { loading: userLoading } = useQuery(GET_ALL_USERS, {
    variables: {
      companyId: user?.company?.id || 0,
      isGlobalLeadOwner: true,
      isLocationFilter: true,
      isActive: true,
      search: userSearch,
      locationIds: sessionStorage.getItem("currentLocationId")?.split(","),
    },
    // fetchPolicy: "network-only",
    onCompleted: res => {
      const users = res?.getUsers?.data || [];
      const findIndex = users.findIndex(el => +el.id === +user?.id);
      if (findIndex > -1) {
        const user = [users[findIndex].id];
        setSelectedUser([+assignedUserId || +user]);
        setcreatedByUser([+assignedUserId || +user]);
        setVariables({ ...variables });
      }
      const userListArray = (res?.getUsers?.data || []).map(el => ({
        id: el.id,
        name: `${el.firstName} ${el.lastName}`,
        ...el,
      }));

      userListArray.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setUserList(userListArray);
    },
  });
  const [getUserAppointments, { data: resp, refetch, loading: loader }] = useLazyQuery(
    APPOINTMENT_LIST,
    {
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    getUserAppointments({
      variables: {
        userIds: userMode === 1 ? userIds : null,
        startDate: range.startDate,
        endDate: range.endDate,
        leadId: (lead && +lead?.id) || null,
        companyId: user?.company?.id,
        createdByUserIds: userMode === 0 ? userIds : null,
        appointmentStatus: appointmentStatus,
        orderBy: "id",
        orderDirection: "asc",
        leadOpportunities: [],
        search: search,
      },
    });
  }, [appointmentStatus, userMode, range, userIds, refetchAppointments, search]);
  const count = resp?.getUserAppointment?.count;

  const appointments = (resp?.getUserAppointment?.data || []).map(el => ({
    ...el,
    type:
      el.appointmentStatus === "CANCELLED"
        ? "error"
        : el.appointmentStatus === "SHOWED"
        ? "show"
        : el.appointmentStatus === "NO_SHOWED"
        ? "noshow"
        : el.isConfirmed
        ? "success"
        : dayjs(el?.startDatetime).format("MM/DD/YYYYTHH:mm") <
          dayjs().utc().format("MM/DD/YYYYTHH:mm")
        ? "default"
        : "processing",
    content: el.summary,
  }));

  const handleAppointmentModalClose = (status, isPopClosed = true) => {
    setAppointmentPopupVisible(false);
    setAppointment(null);
    if (isPopClosed) {
      setDefaultDate(null);
      panelChangeDate = null;
      if (status) {
        refetch();
      }
    }
  };

  const handleAppointmentDialog = (appointmentHeader, appointmentDetails, date) => {
    // eslint-disable-next-line no-debugger
    const tempAppointments = { ...appointmentDetails, id: appointmentDetails?.appointmentId };
    const leadAppointmentDetails =
      appointmentDetails && appointments?.find(item => +item.id === +tempAppointments.id)?.lead;
    openDialog("appointmentForm", {
      showLeadChanger: !appointmentDetails,
      dialogHeader: appointmentHeader || lang.createAppointment,
      lead: isEmpty(lead) ? leadAppointmentDetails : lead,
      eventId: eventId,
      visibleModal: openAppointmentPopup,
      appointment: tempAppointments || appointment,
      defaultDate: date || dayjs(),
      mode: "ADD",
      refetchLeads,
      setAppointmentModelVisible: handleAppointmentModalClose,
      handleDialogClose: () => handleAppointmentModalClose(false, false),
      leadOpportunities:
        leadOpportunities ||
        appointmentDetails?.lead?.leadOpportunities ||
        appointment?.lead?.leadOpportunities ||
        [],
      redirect: (e, updatedDate, needToRedirect = true, leadId = "", isClickOnName = false) => {
        if (isEmpty(lead) && e && needToRedirect) {
          try {
            onClick &&
              onClick({
                data: appointmentDetails?.lead || appointment?.lead || lead,
                tab: "activity-center",
                updatedDate,
              });
            // window.localStorage.setItem(
            //   "changedLocationLeadId",
            //   appointmentDetails?.lead?.id || appointment?.lead?.id || lead?.id
            // );
            !pagePath.includes("chat-bot") &&
              !pagePath.includes("browser-ext") &&
              window.history.pushState("", "", "/engagements/activity-center");
            // !pagePath.includes("chat-bot") &&
            //   !pagePath.includes("browser-ext") &&
            //   tabContext.setActiveTab("activity-center");
          } catch (err) {
            console.log(err);
          }
        } else if (needToRedirect) {
          try {
            onClick &&
              onClick(
                appointmentDetails?.lead || appointment?.lead || lead,
                true,
                updatedDate,
                isClickOnName
              );
            !pagePath.includes("chat-bot") &&
              !pagePath.includes("browser-ext") &&
              window.history.pushState("", "", "/engagements/activity-center");
            // !pagePath.includes("chat-bot") &&
            //   !pagePath.includes("browser-ext") &&
            //   tabContext.setActiveTab("activity-center");
          } catch (err) {
            console.log(err);
          }
        }
      },
      activeAppointments: (appointments || []).filter(el =>
        ["default", "processing"].includes(el.type)
      ),
      handleLeadDetailProfile: handleLeadDetailProfile,
      allowLeadChange: false,
    });
  };

  const handleEventClick = (e, item) => {
    if (e) {
      if (canView) {
        e.stopPropagation();
        setAppointment({ ...item });
        setAppointmentPopupVisible(true);
        handleAppointmentDialog("Edit Appointment", item, dayjs(item.startDatetime));
      } else {
        message.error(lang.permissionError);
      }
    }
  };
  const vehicles = vehicles => {
    let list = [];
    if (vehicles.length > 0) {
      list = vehicles.map(el => {
        let voi = "";
        if (!isUndefined(el.year) && el.year !== 0 && el.year !== null && el.year !== undefined)
          voi = `${voi + el.year} `;
        if (!isUndefined(el.make) && el.make !== null && el.make !== undefined && el.make !== 0)
          voi = `${voi + el.make} `;
        if (!isUndefined(el.model) && el.model !== null && el.model !== undefined && el.model !== 0)
          voi = `${voi + el.model} `;
        return voi === "" || voi === 0 ? (
          <span className="font-medium">{lang.noVehicles}</span>
        ) : (
          <Tooltip
            placement="topLeft"
            title={el?.isCurrent ? lang.previousOrCurrentVehicle : lang.vehicleOfInterest}
          >
            <p>
              <Tag color={el?.isCurrent ? "magenta" : "blue"}>{voi}</Tag>
            </p>
          </Tooltip>
        );
      });
    } else {
      return <span className="font-medium">N/A</span>;
    }
    return list;
  };

  const onSelectDate = (startDate, endDate) => {
    const isValidDay = worksHours.filter(day => {
      if (day.weekDay.toLowerCase() === startDate.format("dddd").toLowerCase()) {
        return day;
      }
    });
    if (isValidDay[0]?.isWorkingDay) {
      if (!isEmpty(lead) && isUndefined(appointment?.id)) {
        if (dayjs().format("YYYY-MM-DD") > startDate.format("YYYY-MM-DD")) {
          message.error(lang.appointmentInPast);
        } else {
          if (!canView) {
            return;
          }
          setDefaultDate(panelChangeDate || startDate);
          panelChangeDate = null;
          console.log(startDate);
          handleAppointmentDialog("", "", panelChangeDate || startDate);
        }
      }
    } else {
      message.error(lang.nonWorkingDay);
    }
  };

  const onPanelChange = value => {
    if (!isEmpty(value)) {
      setVariables({
        ...variables,
        startDate: value?.utc()?.startOf("month")?.format("YYYY-MM-DD"),
        endDate: value?.utc()?.endOf("month")?.format("YYYY-MM-DD"),
      });
    }
  };

  const canSchedule = checkAccessPermission(AccessPermissionAction?.APPOINTMENT_SCHEDULE);

  const handleUserList = (e, type) => {
    if (e?.includes("All")) {
      setSelectedUser("All");
      setcreatedByUser("All");
      setVariables({ ...variables, userIds: userList.map(item => +item.id) });
    } else {
      setSelectedUser(e.map(item => +item));
      setcreatedByUser(e);
      setVariables({ ...variables, userIds: e.map(item => +item) });
    }
  };
  const handleFilters = value => {
    if (appointmentStatus?.includes(value)) {
      // remove the filter
      dispatch(reducerSetAppointmentStatus(appointmentStatus.filter(item => item !== value)));
    } else {
      // add the filter
      dispatch(reducerSetAppointmentStatus([...appointmentStatus, value]));
      // setfilterValue([...filterValue, value]);
    }
    setSearch("");
  };

  const BADGES = [
    {
      badgeColor: colorConfig.steelBlue,
      onClickLabel: "SCHEDULED",
      tag: "Scheduled",
      includesColor: "blue",
    },
    {
      badgeColor: colorConfig.cyan,
      onClickLabel: "RESCHEDULED",
      tag: "Rescheduled",
      includesColor: "purple",
    },
    {
      badgeColor: colorConfig.green,
      onClickLabel: "CONFIRMED",
      tag: "Confirmed",
      includesColor: "green",
    },
    // {
    //   badgeColor: colorConfig.orange,
    //   onClickLabel: "VIRTUAL",
    //   tag: "Virtual",
    //   includesColor: "orange",
    // },
    {
      badgeColor: colorConfig.coral,
      onClickLabel: "CANCELLED",
      tag: "Cancelled",
      includesColor: "red",
    },
    {
      badgeColor: colorConfig.primaryDark,
      onClickLabel: "SHOWED",
      tag: "Showed",
      includesColor: "yellow",
    },
    {
      badgeColor: colorConfig.grey,
      onClickLabel: "NO_SHOWED",
      tag: "No Showed",
      includesColor: "grey",
    },
  ];
  const dateCellRender = value => {
    const list = (appointments || []).filter(el => {
      return convertUtcToLocal(el?.startDatetime, "MM/DD/YYYY") === value.format("MM/DD/YYYY");
    });

    const location = item => {
      const obj = user?.locations?.find(location => +location?.location?.id === +item.locationId);
      if (obj?.location?.title) {
        return <p>{obj?.location?.title}</p>;
      }
      return <p>{lang.noVehicles}</p>;
    };
    console.log(list);
    return (
      <UlWrap>
        {list.length > 0 &&
          list.map(item => {
            const popoverContent = (
              <div>
                <p className="mb-1">
                  {lang.leadName}: <span className="font-medium">{item?.lead?.fullName}</span>
                </p>
                <p className="mb-1">
                  {lang.phone}:{" "}
                  <span className="font-medium">
                    {item?.lead?.phoneNumbers &&
                      (formatPhoneNumber(item?.lead?.phoneNumbers[0]?.phone) || "N/A")}
                  </span>
                </p>
                <p className="mb-1">
                  {lang.emailLabel}:{" "}
                  <span className="font-medium">
                    {item?.lead?.emails && (item?.lead?.emails[0]?.email || "N/A")}
                  </span>
                </p>
                <p className="mb-1">
                  {lang.source}:{" "}
                  <span className="font-medium">{item?.lead?.leadSource?.name || ""}</span>
                </p>

                {item?.leadOpportunityId && item?.leadOpportunity?.name && (
                  <p className="mb-1">
                    {lang.opportunity}:{" "}
                    <span className="font-medium">
                      {" "}
                      <Tooltip placement="topLeft" title={lang.opportunity}>
                        <Tag color="green">{item?.leadOpportunity?.name}</Tag>
                      </Tooltip>
                    </span>
                  </p>
                )}

                {item?.user.fullName && (
                  <div className="flex mb-1 items-center gap-1">
                    <p>{lang.assignedTo}: &nbsp;</p>
                    <p className="font-medium">{`${item?.user.fullName} (${item?.user?.companyRole?.name})`}</p>
                  </div>
                )}
                {item?.locationId && (
                  <div className="flex mb-1 items-center gap-1">
                    <p>{lang.location}: </p>
                    <p className="font-medium">{location(item)}</p>
                  </div>
                )}

                <p>
                  {lang.vehicles}: {vehicles(item?.lead?.vehicleOfInterest)}
                </p>
              </div>
            );
            return (
              <LIWrap
                key={item.content}
                onClick={e => handleEventClick(e, item)}
                status={item.type}
              >
                {window.innerWidth > 992 ? (
                  <Popover content={popoverContent} title="Appointment Details">
                    <Badge
                      status={item.type}
                      text={`${convertUtcToLocal(item.startDatetime, "h:mm a")} - ${item.content}`}
                    />
                  </Popover>
                ) : (
                  <> </>
                )}
              </LIWrap>
            );
          })}
      </UlWrap>
    );
  };
  const renderAppointment = item => {
    // "timeGridWeek"
    // "dayGridMonth"
    // "timeGridDay"
    const location = item => {
      const obj = user?.locations?.find(location => +location?.location?.id === +item.locationId);
      if (obj?.location?.title) {
        return <p>{obj?.location?.title}</p>;
      }
      return <p>{lang.noVehicles}</p>;
    };
    const popoverContent = (
      <div>
        <p className="mb-1">
          {lang.leadName}: <span className="font-medium">{item?.lead?.fullName}</span>
        </p>
        <p className="mb-1">
          {lang.phone}:{" "}
          <span className="font-medium">
            {item?.lead?.phoneNumbers &&
              (formatPhoneNumber(item?.lead?.phoneNumbers[0]?.phone) || "N/A")}
          </span>
        </p>
        <p className="mb-1">
          {lang.emailLabel}:{" "}
          <span className="font-medium">
            {item?.lead?.emails && (item?.lead?.emails[0]?.email || "N/A")}
          </span>
        </p>
        <p className="mb-1">
          {lang.source}: <span className="font-medium">{item?.lead?.leadSource?.name || ""}</span>
        </p>
        <div className="flex flex-row gap-1">
          <p>Appointment Status: </p>
          <b>{firstUpperCaseWordParser(item.appointmentStatus)}</b>
        </div>

        {item?.leadOpportunityId && item?.leadOpportunity?.name && (
          <p className="mb-1">
            {lang.opportunity}:{" "}
            <span className="font-medium">
              {" "}
              <Tooltip placement="topLeft" title={lang.opportunity}>
                <Tag color="green">{item?.leadOpportunity?.name}</Tag>
              </Tooltip>
            </span>
          </p>
        )}

        {item?.user.fullName && (
          <div className="flex mb-1 items-center gap-1">
            <p>{lang.assignedTo}: &nbsp;</p>
            <p className="font-medium">{`${item?.user.fullName} (${item?.user?.companyRole?.name})`}</p>
          </div>
        )}
        {item?.locationId && (
          <div className="flex mb-1 items-center gap-1">
            <p>{lang.location}: </p>
            <p className="font-medium">{location(item)}</p>
          </div>
        )}

        <p>
          {lang.vehicles}: {vehicles(item?.lead?.vehicleOfInterest)}
        </p>
      </div>
    );
    const getStyle = appointmentDetails => {
      const status = appointmentDetails.appointmentStatus.toLowerCase();
      switch (status) {
        case "scheduled":
          return {
            backgroundColor: "#DBEAFE",
            borderColor: "#3B82F6",
          };
        case "rescheduled":
          return {
            backgroundColor: "#EDE9FE",
            borderColor: "#A855F7",
          };
        case "confirmed":
          return {
            backgroundColor: "#D1FAE5",
            borderColor: "#10B981",
          };
        case "cancelled":
          return {
            backgroundColor: "#FFE4E6",
            borderColor: "#F43F5E",
          };
        case "showed":
          return {
            backgroundColor: "#FEF3C7",
            borderColor: "#F59E0B",
          };
        case "no_showed":
          return {
            backgroundColor: "grey",
            borderColor: "#334155",
          };
        default:
          return {
            backgroundColor: "grey",
            borderColor: "#334155",
          };
      }
    };
    return (
      // <LIWrap
      //   key={item.appoinmentId}
      //   onClick={e => {
      //     handleEventClick(e, item);
      //   }}
      //   status={item.type}
      // >
      <Popover content={popoverContent} title="Appointment Details">
        {/* <Badge
            className="w-1/2"
            status={item.type}
            text={`${convertUtcToLocal(item.startDatetime, "h:mm a")} - ${item.content}`}
          /> */}
        <div
          key={item.appoinmentId}
          onClick={e => {
            handleEventClick(e, item);
          }}
          className="flex flex-row items-center h-full w-full"
        >
          <span
            className={`overflow-hidden ${
              calendarApi.currentData.currentViewType === "dayGridMonth"
                ? "w-full"
                : "w-full border-l-[3px] border-t-0 border-r-0 border-b-0 border-solid flex flex-col"
            } px-[6px] h-full`}
            style={{
              ...getStyle(item),
              color:
                calendarApi.currentData.currentViewType === "dayGridMonth"
                  ? "black"
                  : getStyle(item).borderColor,
            }}
          >
            {calendarApi.currentData.currentViewType === "dayGridMonth" ? (
              `${convertUtcToLocal(item.startDatetime, "h:mm a")} - ${item.content}`
            ) : (
              <div className="flex flex-col items-start">
                <span className="text-sm font-[500]">
                  {convertUtcToLocal(item.startDatetime, "h:mm a")}
                </span>
                <span className="text-sm font-[600]">{item.content}</span>
              </div>
            )}
          </span>
        </div>
      </Popover>
      // </LIWrap>
    );
  };
  return (
    <Container border={0} auto noMargin={!isEmpty(lead)}>
      <div className="block md:flex items-center justify-between">
        <div
          className="block rounded-[2px] border-[1px] p-5 border-solid border-grey-200  md:flex mb-2 items-center gap-2 w-full justify-between"
          id="antdUserDropdown"
        >
          <div className="flex flex-row items-center">
            <Radio.Group
              value={userMode}
              onChange={e => {
                // setfilterSetting(e.target.value);
                // sessionStorage.setItem("appointmentFilterType", e.target.value);
                dispatch(reducerSetAppointmentUserMode(e.target.value));
              }}
            >
              <Radio value={0}>{lang.createdBy}</Radio>
              <Radio value={1}>{lang.assignedTo}</Radio>
            </Radio.Group>
            {/* <SelectSchedule
              allowClear={false}
              showCompanyRole
              mode="multiple"
              maxTags="responsive"
              getPopupContainer={() => document.getElementById("antdUserDropdown")}
              value={userLoading ? [] : userIds.length === userList.length ? "All" : userIds}
              isValueInt
              placeholder={lang.selectUserCreateAppot}
              isNeedToSearch
              showAll
              showSearch
              width="250px"
              handleSearch={text => setuserSearch(text)}
              onChange={e => {
                if (e.includes("All")) {
                  dispatch(reducerSetAppointmentUserIds(userList.map(item => +item.id)));
                } else {
                  dispatch(reducerSetAppointmentUserIds(e));
                }
              }}
              data={userList}
              disabled={!canAddUsers}
              loading={userLoading}
            /> */}
            <UserSelector
              mode="multiple"
              placeholder="Select users"
              handleUsersSelection={data => dispatch(reducerSetAppointmentUserIds(data))}
              key="appointmentFilterUsers"
              value={userIds}
              className="w-[250px]"
              showAll
              maxTagCount={1}
            />
          </div>
          <div className="flex sm:flex-row flex-col">
            <span className="text-sm font-[400] text-grey-700">Status:</span>
            <div className="flex flex-wrap items-center gap-1">
              {BADGES.map((badge, index) => (
                <Tag
                  onClick={() => handleFilters(badge.onClickLabel)}
                  key={index}
                  className="cursor-pointer my-1 md:my-0"
                  color={appointmentStatus.includes(badge.onClickLabel) ? badge.includesColor : ""}
                >
                  {badge.tag}
                </Tag>
              ))}
            </div>
          </div>
          <div className="flex sm:flex-row flex-col gap-1 sm:mt-0 mt-1 ">
            {!window.location.href.includes("activity-center") ? (
              <>
                <CustomButton
                  btnText="Add Appointment"
                  type="primary"
                  handleClick={() => handleAppointmentDialog("Add Appointment")}
                  className="rounded-[2px]"
                  disabled={!canSchedule}
                />
                {onBackClick && <CustomButton handleClick={onBackClick} btnText={lang.back} />}
              </>
            ) : (
              <CustomButton
                btnText="Add Appointment"
                type="primary"
                handleClick={() => onSelectDate(dayjs(), dayjs().add(1, "hour"))}
                className="rounded-[2px]"
                disabled={!canSchedule}
              />
            )}
          </div>
        </div>
      </div>

      <Row gutter={[4, 24]}>
        <Col
          sm={24}
          md={24}
          lg={pagePath.includes("browser-ext") ? 17 : 18}
          xl={pagePath.includes("browser-ext") ? 17 : 18}
        >
          {" "}
          {/* <Calendar
              dateCellRender={dateCellRender}
              onSelect={date => {
                sessionStorage.getItem("currentLocationId").includes(",")
                  ? message.info(lang.selectLocationFromDropdown)
                  : onSelectDate(date);
              }}
              onPanelChange={e => {
                panelChangeDate = dayjs(e);
                onPanelChange(e);
              }}
              data={userList}
              width="20%"
              disabled
            /> */}
          <Spin spinning={loader} tip={`${lang.loading}...`}>
            {shouldRenderComponent && (
              <div className="mb-[30px] px-4 py-6 border-[1px] border-solid rounded-[2px] border-grey-200  ">
                <FullCalendar
                  headerToolbar={{
                    start: "prev,next title",
                    center: "",
                    end: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView={calendarMode}
                  selectable
                  events={appointments.map(item => ({
                    ...item,
                    date: dayjs
                      ?.utc(item?.startDatetime)
                      ?.tz(item?.timezone || dayjs.tz.guess())
                      .toISOString(),
                    appointmentId: item.id,
                  }))}
                  ref={calendarRef}
                  eventContent={info => renderAppointment(info.event.extendedProps)}
                  select={args => {
                    sessionStorage.getItem("currentLocationId").includes(",")
                      ? message.info(lang.selectLocationFromDropdown)
                      : onSelectDate(dayjs(args.start), dayjs(args.end));
                  }}
                  // eventClick={args => console.log(args)}
                />
              </div>
            )}
          </Spin>
        </Col>
        <Col
          sm={24}
          md={24}
          lg={pagePath.includes("browser-ext") ? 7 : 6}
          xl={pagePath.includes("browser-ext") ? 7 : 6}
        >
          {!isEmpty(lead) && (
            <Alert
              showIcon
              message={
                <>
                  Showing appointments for lead <strong>{lead.fullName}</strong>
                </>
              }
            />
          )}
          <div className="p-[1em] flex items-center justify-between border-solid border-t-0 border-l-0 border-r-0 border-b-[#e8e8e8]">
            <Search
              onChange={e => setSearch(e.target.value)}
              // onSearch={e => setSearch(e)}
              placeholder={lang.searchAppointment}
              allowClear
              value={search}
            />
          </div>
          <Spin spinning={loader}>
            <div className="p-2 overflow-y-auto appointmentList overflow-x-hidden max-h-[115vh] hide-scroll">
              <AppointmentList
                filters={filters}
                searchKey={search}
                leadOpportunities={leadOpportunities}
                canView={canView}
                appointmentsData={appointments}
                // count={appointments?.length || 0}
                count={count || 0}
                lead={lead}
                leadId={lead && +lead?.id}
                isFromAppointment
              />
            </div>
          </Spin>
        </Col>
      </Row>
      <style jsx>{`
        .appointmentList::-webkit-scrollbar {
          width: 7px;
          background-color: #f1f1f1;
        }

        .appointmentList::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 0px grey;
          border-radius: 3px;
        }

        .appointmentList::-webkit-scrollbar-thumb {
          border-radius: 3px;
          -webkit-box-shadow: inset 0 0 6px grey;
          background-color: #fcfcfc;
        }
      `}</style>
    </Container>
  );
};

export default Appointments;
