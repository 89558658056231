import React from "react";
import { Script } from "gatsby";
import { Amplify } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { ConfigProvider } from "antd";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import * as am4core from "@amcharts/amcharts4/core";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import resolvers from "./src/graphql/resolvers";
import typeDefs from "./src/graphql/typeDefs";
import { AuthenticatorProvider } from "./src/amplify";
import { store } from "./src/redux/store";
import ErrorBoundary from "./src/components/errorBoundry";

const persistor = persistStore(store);

const doNotAuthenticate = ["/foo", "/bar/baz"];

export const apolloClient = () => {
  return new ApolloClient({
    uri: process.env.GATSBY_API_URL
      ? process.env.GATSBY_API_URL.replace("graphql", "lead_graphql")
      : "http://localhost:5000/lead_graphql",
    cache: new InMemoryCache(),
    resolvers,
    typeDefs,
  });
};

const apolloClientWebChat = () => {
  return new ApolloClient({
    uri: "http://localhost:5000/chat_api",
    cache: new InMemoryCache(),
    resolvers,
    typeDefs,
  });
};

const posthogScript = () => {
  const defaultOptions = {
    apiHost: "https://app.posthog.com",
    enable_recording_console_log: true,
    session_recording: {
      maskTextSelector: "input[type=password]", // Masks all passowrd input elements
    },
  };
  const isEnabled = process.env.NODE_ENV === "production" || process.env.GATSBY_POSTHOG_API_KEY;
  if (!isEnabled) {
    console.log("PostHog Analytics not enabled");
    return false;
  }
  return (
    <Script
      id="posthog-js-script"
      dangerouslySetInnerHTML={{
        __html: `
          !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
          posthog.init('${process.env.GATSBY_POSTHOG_API_KEY}',${JSON.stringify({
          api_host: defaultOptions.apiHost,
          ...defaultOptions.initOptions,
        })})
        `,
      }}
      onLoad={() => console.log("Posthog script successfully loaded")}
      onError={() => console.log("Posthog script failed to load")}
    />
  );
};

export const wrapPageElement = ({ element, props }) => {
  am4core.options.commercialLicense = true;
  if (
    Array.isArray(doNotAuthenticate) &&
    (doNotAuthenticate.includes(props.path) ||
      props?.path?.indexOf("/acal/") !== -1 ||
      props?.path?.indexOf("/acal-status/") !== -1 ||
      props?.path?.indexOf("/media-gallery/") !== -1)
  ) {
    return (
      <ApolloProvider client={apolloClient()}>
        {element}
        {posthogScript()}
      </ApolloProvider>
    );
  }
  if (props.path.indexOf("/privacy-policy/") !== -1) {
    return (
      <ApolloProvider client={apolloClient()}>
        {element}
        {posthogScript()}
      </ApolloProvider>
    );
  }
  if (props.path.indexOf("/terms/") !== -1) {
    return (
      <ApolloProvider client={apolloClient()}>
        {element}
        {posthogScript()}
      </ApolloProvider>
    );
  }
  if (
    props.path.indexOf("/user-review/") !== -1 ||
    props.path.indexOf("/reviews-lists/") !== -1 ||
    props.path.indexOf("/shorten/") !== -1
  ) {
    return (
      <ConfigProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ApolloProvider client={apolloClient()}>
              {element}
              {posthogScript()}
            </ApolloProvider>
          </PersistGate>
        </Provider>
      </ConfigProvider>
    );
  }
  if (props?.path?.indexOf("/webchat/") !== -1) {
    return (
      <ApolloProvider client={apolloClientWebChat()}>
        {element}
        {posthogScript()}
      </ApolloProvider>
    );
  }
  if (props?.path?.indexOf("/asu/") !== -1) {
    return (
      <ApolloProvider client={apolloClient()}>
        {element}
        {posthogScript()}
      </ApolloProvider>
    );
  }
  // TODO: remove configuration values from being hardcoded.
  Amplify.configure({
    region: "us-east-1",
    userPoolId: process.env.GATSBY_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
  });
  Auth.configure({
    region: "us-east-1",
    userPoolId: process.env.GATSBY_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
  });
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <ConfigProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AuthenticatorProvider persistor={persistor}>{element}</AuthenticatorProvider>
            </PersistGate>
          </Provider>
          {posthogScript()}
        </ConfigProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};
