import * as React from "react";

const MessageIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M8.75 8a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm-3.5-.75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Zm5.5 0a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM14.5 8a6.502 6.502 0 0 1-9.555 5.739l-2.128.71a1 1 0 0 1-1.265-1.266l.71-2.128A6.5 6.5 0 1 1 14.5 8Zm-1 0a5.5 5.5 0 1 0-10.262 2.754.5.5 0 0 1 .041.409L2.5 13.5l2.338-.78a.492.492 0 0 1 .408.042A5.5 5.5 0 0 0 13.5 8Z"
    />
  </svg>
);
export default MessageIcon;
