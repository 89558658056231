import React from "react";
import { Select, Tooltip } from "antd";
import useLang from "../../../../hooks/useLang";

const { Option } = Select;

const OpportunityFilter = props => {
  const { handleFilter, oppValue, opportunityList, disabled, className } = props;
  const [lang] = useLang();

  return (
    <div className={className || "w-[100px] sm:w-[250px] ml-2 h-[40px]"}>
      <div className="w-full">
        <Select
          value={oppValue}
          disabled={disabled}
          placeholder={lang.selectOpportunity}
          onChange={value => {
            handleFilter(value);
          }}
          style={{ width: "100%" }}
        >
          {(opportunityList || []).map(opportunity => (
            <Option key={opportunity.id} value={opportunity.id}>
              <Tooltip
                placement="topLeft"
                title={`${opportunity.name} ${
                  opportunity.name === "All" ? "" : `# (${opportunity.opprId || opportunity.id})`
                }`}
              >
                {opportunity.name}{" "}
                {opportunity.name === "All" ? "" : `# (${opportunity.opprId || opportunity.id})`}
              </Tooltip>
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default OpportunityFilter;
