import { Modal } from "antd";
import { useEffect, useState, useContext } from "react";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import dayjs from "dayjs";
import { UserContext } from "../amplify/authenticator-provider";
import { getCognitoRoles } from "../hooks/utils";
import { closeModal, openModal } from "./rxjsSubjects";

export const formatDate = dateString =>
  new Date(dateString).toLocaleString("us-EN", {
    month: "numeric",
    year: "numeric",
    day: "numeric",
  });

export const showConfirmationModal = (title, content, cb) => {
  Modal.confirm({
    title,
    content,
    okType: "danger",
    onOk() {
      cb();
    },
    onCancel() {},
  });
};

// filters specific
export const filterByType = data => type => {
  if (!data) return [];
  return data.filter(item => item.type === type);
};

export const filterByTypeName = data => typeName => {
  if (!data) return [];
  return data.filter(item => item.typeName === typeName);
};

export const capitalize = string => String(string).charAt(0).toUpperCase() + string?.slice(1);

const GRAPHQL_ERROR = "GraphQL error: ";
export const displayGraphQLError = e =>
  e?.message ? e?.message.replace(GRAPHQL_ERROR, "") : "Unexpected Error";

export const usePersistedState = (key, defaultValue) => {
  const [state, setState] = useState(() =>
    JSON.parse(sessionStorage.getItem(key) || JSON.stringify(defaultValue))
  );
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
};

export const filterAndSortingData = (data, sortBy, filter = null) => {
  let localData = uniqBy(data || [], sortBy);
  if (filter) {
    localData = localData.filter(el => el[filter?.key] === filter?.value);
  }
  localData = orderBy(localData, [sortBy], ["asc"]).map(el => ({
    id: el[sortBy],
    name: el[sortBy],
  }));
  return localData;
};

export const getVehicleYears = () => {
  const years = [];
  for (let i = 1995; i <= dayjs().add(1, "years").year(); i++) {
    years.push(i);
  }
  return years;
};

export const checkAccessPermission = key => {
  const { user, authData } = useContext(UserContext);
  const accessPayload = authData?.signInUserSession?.accessToken?.payload;
  let access;
  // console.log("helpers:checkAccessPermission:accessPayload", accessPayload);
  // console.log("helpers:checkAccessPermission:getCognitoRoles", getCognitoRoles(accessPayload));
  if (getCognitoRoles(accessPayload)[0] === "ADMIN") {
    access = true;
  } else if (user && user.accessControlPermissions?.length > 0 && key) {
    const canAccess = user.accessControlPermissions.findIndex(el => el === key);
    access = canAccess > -1;
  }
  return access;
};

export const hasAdminAccess = () => {
  try {
    const { authData } = useContext(UserContext);
    const accessPayload = authData?.signInUserSession?.accessToken?.payload;
    return getCognitoRoles(accessPayload)[0] === "ADMIN";
  } catch (err) {
    console.log("ERROR:", err);
  }
  return false;
};

// for open dialog
export const openDialog = (...params) => {
  openModal.next([...params]);
};

// for close dialog
export const closeDialog = (...params) => {
  closeModal.next(...params);
};

// scroll to top
export const handleScrollToTop = position => {
  document.documentElement.scrollTop = position || 0;
};

// scroll to bottom
export const handleScrollToBottom = () => {
  document.documentElement.scrollTo = document.documentElement.scrollHeight;
};

export const validateUserInputs = user => {
  return user.firstName.trim() !== "" && user.lastName.trim() !== "";
};

export const isEmailValid = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getLocationObj = user => {
  try {
    const locationId = sessionStorage.getItem("currentLocationId");
    if (locationId.includes(",")) {
      return user.location[0];
    }
    const obj = user?.locations?.find(item => +item.location.id === +locationId);
    return obj;
  } catch (err) {
    console.log(err);
  }
};

export const getResourceSize = async url => {
  try {
    const res = await fetch(url, { method: "HEAD" });
    if (res.ok) {
      const contentLength = res.headers.get("Content-Length");
      if (contentLength) {
        return parseInt(contentLength, 10);
        // eslint-disable-next-line no-else-return
      } else {
        throw new Error("Content-Length could not found");
      }
    } else {
      throw new Error(`Failed to fetch resource. Status: ${res.status}`);
    }
  } catch (err) {
    console.log(err);
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
