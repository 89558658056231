import React from "react";

const TextInput = ({
  reference,
  placeholder = "",
  name,
  label = "",
  type = "text",
  onKeyUp = () => {},
  onChange = () => {},
  containerClass = "",
  value,
  children,
  className,
  postIcon, // icon will be append at the end of input
  key = "",
  min,
  labelClass,
  ...props
}) => {
  const size = props.small ? "small" : props.big ? "big" : "regular";
  return (
    <div className={containerClass}>
      {label && (
        <label id={name} className={`${labelClass || "my-1 font-normal"}`}>
          {label}
          {props?.require && <span className="m-0 text-red-500 ml-1">*</span>}
        </label>
      )}
      <div className={`phoneInputCss ${className} relative`}>
        <input
          type={type}
          value={value}
          key={key}
          ref={reference}
          className="w-full placeholder:text-slate-400"
          name={name}
          id={name}
          min={min}
          placeholder={placeholder}
          size={size}
          disabled={props.disabled}
          onKeyUp={onKeyUp}
          defaultValue={props.defaultValue}
          onChange={onChange}
          maxLength={props?.maxLength ? props.maxLength : ""}
        />
        {children}
        {postIcon && <div>{postIcon}</div>}
      </div>
    </div>
  );
};

export default TextInput;
