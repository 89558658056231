import React from "react";
import { Alert } from "antd";
import DialogFooter from "../commonComponents/dialogFooter";
import useLang from "../../hooks/useLang";

const AccessPermissionMessage = ({ onClose }) => {
  const [lang] = useLang();

  return (
    <div>
      <Alert
        className="mt-2 mb-2"
        message={<div>{lang.accessPermissionMessageDialogBody}</div>}
        type="info"
        showIcon
      />

      <DialogFooter
        showConfirmButton={false}
        confirmBtnText=""
        handleConfirm={null}
        handleCancel={onClose}
      />
    </div>
  );
};

export default AccessPermissionMessage;
