import * as React from "react";

const PersonalizedIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M16.766 15.517a2.5 2.5 0 1 0-3.533 0 3.125 3.125 0 0 0-1.337 1.822.625.625 0 1 0 1.208.321c.221-.83 1-1.41 1.896-1.41.895 0 1.675.58 1.896 1.41a.626.626 0 1 0 1.208-.32 3.125 3.125 0 0 0-1.338-1.823ZM15 12.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm3.125-5.625v2.5a.625.625 0 1 1-1.25 0v-2.5h-6.666a1.26 1.26 0 0 1-.75-.25L7.29 5H3.125v10.625h6.25a.625.625 0 1 1 0 1.25h-6.25a1.25 1.25 0 0 1-1.25-1.25V5a1.25 1.25 0 0 1 1.25-1.25h4.166c.27 0 .534.088.75.25l2.168 1.625h6.666a1.25 1.25 0 0 1 1.25 1.25Z"
    />
  </svg>
);
export default PersonalizedIcon;
