/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable default-param-last */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  message as NotificationMessage,
  Tag,
  Typography,
  Tooltip,
  Avatar,
  List,
  Spin,
  Input,
  Button,
  Skeleton,
} from "antd";
import { debounce, isEmpty, isUndefined } from "lodash";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";

import {
  CREATE_LEAD,
  CREATE_LEAD_PHONE,
  PULL_LEAD_FROM_CRM,
  SYNC_UP_LEAD,
} from "../../graphql/mutation";
import {
  GET_LEADS_BY_PHONE,
  GET_LEADS_PHONE_FROM_CRM,
  GET_LOCATIONS,
  GET_INITIAL_REVIEW_MESSAGE,
} from "../../graphql/query";
import { AccessPermissionAction, Colors } from "../../library/constants";
import { checkAccessPermission, closeDialog, showConfirmationModal } from "../../library/helpers";
import DialogFooter from "../commonComponents/dialogFooter";
import { displayPhoneNumbers, LeadStatus } from "../engagements/activityCenter/engagementHelpers";
import OpportunityWarning, {
  handleOpportunityDilaog,
} from "../engagements/activityCenter/opporotunityLayer/opportuityWarning";
import { crmLinkGeneration } from "../engagements/activityCenter/opporotunityLayer/crmLinks";
import TextInput from "../../library/textInput";
import { DEFAULT_COUNTRY } from "../../config";
import CustomLabel from "../commonComponents/label";
import CustomButton from "../commonComponents/button";
import useLang from "../../hooks/useLang";

const ModelDiv = styled.div`
  .ant-list-item-action {
    display: flex;
    justify-content: end;
  }
`;

const SendReviewInviteModal = props => {
  const {
    phoneNumber,
    setModalVisible,
    onSetLead,
    variables,
    sources,
    user,
    onLeadStatus,
    onLeadOwners,
    onVehicles,
    statusList,
    isSyncUp = true,
    crmIntegration = "",
    isFromChatbot = false,
    leadFirstName = "",
    leadLastName = "",
    handleLeadCRMName = () => {},
    userLocation = false,
    handleCancelBtn = () => {},
    sendSendReview,
    reviewResponse,
    oncancel,
  } = props;

  const [lang] = useLang();
  const { TextArea } = Input;
  const formatedPhone = isUndefined(phoneNumber)
    ? ""
    : phoneNumber.length === 10
    ? `+1${phoneNumber}`
    : phoneNumber.length === 11
    ? `+${phoneNumber}`
    : phoneNumber;

  const canchangeLocation = checkAccessPermission(
    AccessPermissionAction.LEAD_MANAGEMENT_SEND_TO_STORE
  );

  const [isOpporDialogFlagChanged, setIsOpprDialogFlagChanged] = useState(false);
  const [userLocationsId, setUserLocationsId] = useState([]);
  const [phone, setPhone] = useState(formatedPhone);
  const [firstName, setFirstName] = useState(leadFirstName || "");
  const [lastName, setLastName] = useState(leadLastName || "");
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [message, setmessage] = useState();
  const [reviewLink, setReviewLink] = useState("");
  const [misc, setmisc] = useState({
    state: false,
    data: {},
  });
  console.log(selectedLead);
  const [opportunityWarningDialog, setOpportunityWarningDialog] = useState(false);
  const [opportunityInfo, setOpportunityInfo] = useState({});
  const [isCrm, setisCrm] = useState(false);
  const [skipduplicate, setskipduplicate] = useState(false);

  const [createLead, { loading: createLeadLoader }] = useMutation(CREATE_LEAD);
  const [createLeadPhone, { loading: createLeadPhoneLoader }] = useMutation(CREATE_LEAD_PHONE);
  const [syncUpLead] = useMutation(SYNC_UP_LEAD);
  const [getLeadsPhoneFromCRM, { loading: crmLoading }] = useLazyQuery(GET_LEADS_PHONE_FROM_CRM, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      setLeads(res?.getLeadsByPhoneFromCrm || []);
      setisCrm(true);
    },
  });
  const { data: resp } = useQuery(GET_LOCATIONS, {
    variables: { companyId: user?.company?.id },
  });

  const [getInitialReviewMessage, { loading: reviewMessageLoading }] = useLazyQuery(
    GET_INITIAL_REVIEW_MESSAGE,
    {
      onCompleted: res => {
        if (res?.getInitialReviewMessage?.statusCode === 200) {
          setmessage(
            res?.getInitialReviewMessage?.content || "Please give us a review via this link"
          );
          setReviewLink(res?.getInitialReviewMessage?.reviewLink || "");
        } else {
          setmessage("Please give us a review via this link");
        }
      },
    }
  );

  const [pullCrmLead, { loading: crmPullLoader }] = useMutation(PULL_LEAD_FROM_CRM);

  const handleSearch = e => {
    setPhone(e);
  };

  const handleCancel = () => {
    if (isFromChatbot) {
      handleCancel();
    }
    handleLeadCRMName();
    closeDialog("addLeadViaPhone");
  };

  const handleSaveLead = () => {
    // console.log(selectedLead);
    // eslint-disable-next-line no-debugger
    sendSendReview({ body: message, reviewLink, selectedLead });
    oncancel();
  };

  const handleSave = async e => {
    if (Object.keys(selectedLead).length === 0) {
      if (e) {
        if (isEmpty(phone)) {
          NotificationMessage.error(lang.enterPhoneNumber);
          return;
        }
        if (isEmpty(firstName)) {
          NotificationMessage.error(lang.enterFirstNameLabel);
          return;
        }
        if (isEmpty(lastName)) {
          NotificationMessage.error(lang.enterLastNameLabel);
          return;
        }

        const defaultSource = sources.find(item => item.name === "SMAI")?.id || 7;

        try {
          const locationId = sessionStorage.getItem("currentLocationId");
          const res = await createLead({
            variables: {
              firstName: firstName,
              lastName: lastName,
              fullName: `${firstName} ${lastName}`,
              leadSourceType: "MANUAL",
              leadSourceOriginalId: defaultSource,
              phones: [{ phone: phone, phoneType: "Mobile" }],
              primaryOwnerId: user?.id,
              locationId,
              leadOpportunityUsers: [
                {
                  userTypeId: null,
                  userId: user?.id,
                },
              ],
            },
          });
          // eslint-disable-next-line no-debugger
          if (res?.data?.createLead?.statusCode === 200) {
            const lead = res?.data?.createLead?.lead || {};
            lead.phoneNumbers[0] = [];
            lead.phoneNumbers[0].phone = phone;
            setSelectedLead(lead);
            getInitialReviewMessage({
              variables: {
                leadId: lead?.id,
              },
            });
            if (!isEmpty(lead)) {
              try {
                const resp = await createLeadPhone({
                  variables: {
                    leadId: lead?.id,
                    phone: phone,
                  },
                });
                if (resp?.data?.createLeadPhone?.statusCode === 200) {
                  NotificationMessage.success(lang.phoneAdded);
                  if (!isEmpty(lead) && isSyncUp) {
                    syncUpLead({
                      variables: {
                        leadId: parseInt(lead?.id),
                      },
                    }).then(resp => {
                      if (resp?.data?.syncUpLead?.statusCode !== 200) {
                        NotificationMessage.error(resp?.data?.syncUpLead?.message);
                      }
                    });
                  }
                  const leadPhone = resp?.data?.createLeadPhone?.leadPhone || {
                    phone: phone,
                  };
                  // onSetLead(
                  //   {
                  //     ...lead,
                  //     phoneNumbers: [{ ...leadPhone }],
                  //   },
                  //   "new",
                  //   "phoneLead"
                  // );
                  setModalVisible(false);
                  //   closeDialog("addLeadViaPhone");
                } else {
                  NotificationMessage.error(resp?.data?.createLeadPhone?.message);
                }
              } catch (err) {
                console.log("Error in createLeadPhone", err);
              }
            }
          } else {
            NotificationMessage.error(res?.data?.createLead?.message);
          }
        } catch (err) {
          console.log("Error in createLead", err);
        }
      }
    } else {
      handleSaveLead();
    }
  };

  const pullToSMAI = (id, locationId, lead, leadPhone) => {
    pullCrmLead({
      variables: {
        crmLeadId: id,
        locationId: locationId,
      },
    }).then(res => {
      if (res?.pullLeadFromCrm?.statusCode === 200) {
        // onSetLead(
        //   {
        //     ...lead,
        //     phoneNumbers: [{ ...leadPhone }],
        //   },
        //   "new",
        //   "phoneLead"
        // );
        setModalVisible(false);
      }
    });
  };

  const onCreateLead = () => {
    if (!canchangeLocation) {
      NotificationMessage.error(lang.viewLeadCurrentLocation);
      return;
    }
    const location = (resp.getLocations || []).find(
      el => +el.id === +sessionStorage.getItem("currentLocationId")
    );
    const MODAL_CONTENT = `${lang.createSameLead} ${
      location?.title
    } ${lang.location.toLowerCase()}`;
    showConfirmationModal(lang.doYouWantToContinue, MODAL_CONTENT, () => console.log("OKKK"));
  };

  useEffect(() => {
    if (user && user?.locations?.length > 0) {
      const locationIDs = user?.locations.map(location => location?.location?.id);
      setUserLocationsId(locationIDs);
    }
  }, [user]);

  //   const getInitialReviewMessage = _selectedLead => {
  //     if (selectedLead !== _selectedLead) {

  //       setIsLoading(loading);
  //     }
  //   };

  const locationPermission = lead => {
    if (userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id)) {
      // onSetLead({ ...lead }, "new");
      closeDialog("addLeadViaPhone");
    } else {
      NotificationMessage.error(`${lang.leadPermission} ${lead?.location?.title}`);
    }
  };

  const handleOpporDialogFlagChanged = () => {
    setIsOpprDialogFlagChanged(true);
    localStorage.setItem("isOpporDialogFlagChangedLocal", "true");
  };

  const handleOpportunityWarningDialog = (
    item,
    crmLink,
    opprId,
    opprName,
    isOpp = false,
    crmLeadObj
  ) => {
    setOpportunityInfo({
      ...item,
      opprLink: crmLink,
      opprId: opprId,
      opprName: opprName,
      isOpp: isOpp,
      crmLeadObj: crmLeadObj,
    });
    setOpportunityWarningDialog(true);
  };

  const handleOpportunityIdDialog = (oppr, crmLeadObj, lead) => {
    // DS
    if (
      (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
      crmLeadObj?.dsExtractedLead?.dsLeadId
    ) {
      const opprId =
        oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId
          ? String(oppr?.dsOpportunityEvents[0]?.dsEventId)
          : crmLeadObj?.dsExtractedLead?.dsLeadId
          ? String(crmLeadObj?.dsExtractedLead?.dsLeadId).padStart(4, "0")
          : oppr?.id;

      let opprName;
      if (
        (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
        crmLeadObj?.dsExtractedLead?.dsLeadId
      ) {
        opprName =
          oppr &&
          oppr?.dsOpportunityEvents &&
          oppr?.dsOpportunityEvents.length > 0 &&
          oppr?.dsOpportunityEvents[0]?.dsEventId
            ? `${lang.salesOpportunity} - ${oppr?.name}`
            : oppr?.name;
      }

      const oppLink =
        oppr?.dsOpportunityEvents?.length > 0 &&
        crmLinkGeneration(
          "DS",
          Boolean(oppr?.dsOpportunityEvents[0]?.dsEventId),
          opprId,
          crmLeadObj?.dsExtractedLead?.dsLeadId
        );
      const dsOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme"
            onClick={() =>
              handleOpportunityWarningDialog(oppr, oppLink, opprId, opprName, false, crmLeadObj)
            }
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme"
            onClick={() => {
              NotificationMessage.error(`${lang.dontHaveOppr} ${lead?.location?.title}`);
            }}
          >
            <p>{lang.viewDetails}</p>
          </div>
        );
      return dsOppType;
    }

    // SF
    if (
      (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
      crmLeadObj?.sfExtractedLead?.sfLeadId
    ) {
      const opprId =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? String(oppr?.sfLeadOpportunities[0]?.sfOpportunityId)
          : crmLeadObj?.sfExtractedLead?.sfLeadId
          ? String(crmLeadObj?.sfExtractedLead?.sfLeadId)
          : oppr?.id;

      const oppLink =
        oppr?.sfLeadOpportunities?.length > 0 &&
        crmLinkGeneration(
          "SF",
          Boolean(oppr?.sfLeadOpportunities[0]?.sfOpportunityId),
          opprId,
          "",
          "usAutoSales"
        );

      const isOpp =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? true
          : false;

      let opprName;
      if (
        (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
        crmLeadObj?.sfExtractedLead?.sfLeadId
      ) {
        opprName = oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? oppr?.name
          : `${lang.leadLabel}: ${lead?.fullName}`; // asdklasjdasdlkaj
      }
      const sfOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme"
            onClick={() =>
              handleOpportunityWarningDialog(oppr, oppLink, opprId, opprName, isOpp, crmLeadObj)
            }
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme"
            onClick={() => {
              NotificationMessage.error(
                `${lang.donthavePermission} ${
                  isOpp ? lang.opportunity.toLowerCase() : lang.lead
                } in the ${lead?.location?.title}`
              );
            }}
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        );

      return sfOppType;
    }

    // VIN
    if (
      (oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId) ||
      crmLeadObj?.vsExtractedLead?.vsContactId
    ) {
      const opprId =
        oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId
          ? String(oppr?.vsLeadOpportunities[0]?.vsContactId)
          : crmLeadObj?.vsExtractedLead?.vsContactId
          ? String(crmLeadObj?.vsExtractedLead?.vsContactId)
          : oppr?.id;

      const oppLink =
        oppr?.vsLeadOpportunities?.length > 0 &&
        crmLinkGeneration(
          "VIN",
          Boolean(oppr?.vsLeadOpportunities[0]?.vsContactId),
          opprId,
          "",
          "VinSolution"
        );

      const isOpp =
        oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsLeadId
          ? true
          : false;

      let opprName;
      if (
        (oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsLeadId) ||
        crmLeadObj?.vsExtractedLead?.vsLeadId
      ) {
        opprName = oppr?.vsLeadOpportunities[0]?.vsLeadId
          ? oppr?.name
          : `${lang.leadLabel}: ${lead?.fullName}`; // asdklasjdasdlkaj
      }
      const sfOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme"
            onClick={() =>
              handleOpportunityWarningDialog(oppr, oppLink, opprId, opprName, isOpp, crmLeadObj)
            }
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme"
            onClick={() => {
              NotificationMessage.error(
                `${lang.donthavePermission} ${
                  isOpp ? lang.opportunity.toLowerCase() : lang.lead
                } in the ${lead?.location?.title}`
              );
            }}
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        );

      return sfOppType;
    }

    return "";
  };

  const handleOpportunityId = (oppr, crmLeadObj, lead) => {
    // DS
    if (
      (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
      crmLeadObj?.dsExtractedLead?.dsLeadId
    ) {
      const opprId =
        oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId
          ? String(oppr?.dsOpportunityEvents[0]?.dsEventId)
          : crmLeadObj?.dsExtractedLead?.dsLeadId
          ? String(crmLeadObj?.dsExtractedLead?.dsLeadId).padStart(4, "0")
          : oppr?.id;

      const oppLink =
        oppr?.dsOpportunityEvents?.length > 0 &&
        crmLinkGeneration(
          "DS",
          Boolean(oppr?.dsOpportunityEvents[0]?.dsEventId),
          opprId,
          crmLeadObj?.dsExtractedLead?.dsLeadId
        );

      const dsOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <a href={oppLink} target="_blank">
            <div className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme">
              <p>{lang.viewDetails}</p>
            </div>
          </a>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme"
            onClick={() => {
              NotificationMessage.error(`${lang.dontHaveOppr} ${lead?.location?.title}`);
            }}
          >
            <p>{lang.viewDetails}</p>
          </div>
        );
      return dsOppType;
    }

    // SF
    if (
      (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
      crmLeadObj?.sfExtractedLead?.sfLeadId
    ) {
      const opprId =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? String(oppr?.sfLeadOpportunities[0]?.sfOpportunityId)
          : crmLeadObj?.sfExtractedLead?.sfLeadId
          ? String(crmLeadObj?.sfExtractedLead?.sfLeadId)
          : oppr?.id;

      const oppLink =
        oppr?.sfLeadOpportunities?.length > 0 &&
        crmLinkGeneration(
          "SF",
          Boolean(oppr?.sfLeadOpportunities[0]?.sfOpportunityId),
          opprId,
          "",
          "usAutoSales"
        );

      const isOpp =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? true
          : false;

      const sfOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <a href={oppLink} target="_blank">
            <div className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme">
              <p>{lang.viewDetails}</p>
            </div>
          </a>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme"
            onClick={() => {
              NotificationMessage.error(
                `You do not have permissions to view ${isOpp ? "opportunity" : "lead"} in the ${
                  lead?.location?.title
                }`
              );
            }}
          >
            <p>{lang.viewDetails}</p>
          </div>
        );

      return sfOppType;
    }

    // VIN
    if (
      (oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId) ||
      crmLeadObj?.vsExtractedLead?.vsContactId
    ) {
      const opprId =
        oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId
          ? String(oppr?.vsLeadOpportunities[0]?.vsContactId)
          : crmLeadObj?.vsExtractedLead?.vsContactId
          ? String(crmLeadObj?.vsExtractedLead?.vsContactId)
          : oppr?.id;

      const oppLink =
        oppr?.vsLeadOpportunities?.length > 0 &&
        crmLinkGeneration(
          "VIN",
          Boolean(oppr?.vsLeadOpportunities[0]?.vsContactId),
          opprId,
          "",
          "VinSolution"
        );

      const isOpp =
        oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsLeadId
          ? true
          : false;

      const sfOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <a href={oppLink} target="_blank">
            <div className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme">
              <p>{lang.viewDetails}</p>
            </div>
          </a>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secodaryColorTheme"
            onClick={() => {
              NotificationMessage.error(
                `You do not have permissions to view ${isOpp ? "opportunity" : "lead"} in the ${
                  lead?.location?.title
                }`
              );
            }}
          >
            <p>{lang.viewDetails}</p>
          </div>
        );

      return sfOppType;
    }

    return "";
  };

  return (
    <>
      <ModelDiv>
        {!isEmpty(selectedLead) ? (
          <div className="text-end">
            <CustomButton
              btnText={lang.changeLead}
              handleClick={() => {
                setSelectedLead({});
              }}
            />
            <Spin tip="Loading..." spinning={reviewMessageLoading} className="w-full">
              <div>
                <div className="flex gap-1">
                  <b>Full Name: </b> &nbsp;
                  <p>{selectedLead.fullName}</p>
                </div>
                <div className="flex gap-1">
                  <b>Phone Number: </b> &nbsp;
                  <p>{selectedLead?.phoneNumbers[0]?.phone}</p>
                </div>
                <div className="flex flex-wrap gap-2">
                  <b>Preferred Review Sites: </b>
                  {reviewResponse?.getSocialReviewSettings?.map((socialReview, i) =>
                    reviewResponse?.getSocialReviewSettings?.length - 1 !== i ? (
                      <a key={i} href={socialReview?.reviewPostUrl || "N/A"}>
                        <strong>{socialReview?.platform ? socialReview.platform : "N/A"}</strong>
                      </a>
                    ) : (
                      <a key={i} href={socialReview?.reviewPostUrl || "N/A"}>
                        <strong>{socialReview?.platform ? socialReview.platform : "N/A"}</strong>
                      </a>
                    )
                  )}
                </div>
                <div className="flex flex-col items-start">
                  <b className="mt-2">Message:</b>
                  <TextArea
                    rows={4}
                    value={message}
                    placeholder="Enter the review invite message here"
                    onChange={e => setmessage(e.target.value)}
                  />
                </div>
              </div>
            </Spin>
          </div>
        ) : (
          <>
            <div>
              <p className="mt-1 font-normal w-full">
                {lang.phoneNumberLabel}
                <span className="requireFieldStar">*</span>
              </p>
              <div className="phoneInput">
                <PhoneInput
                  defaultCountry={DEFAULT_COUNTRY}
                  initialValueFormat="national"
                  value={phone}
                  placeholder={lang.enterPhone}
                  onChange={e => {
                    handleSearch(e);
                  }}
                />
              </div>
            </div>
            {!isEmpty(phone) && (
              <FoundLeadsJsx
                selectedLead={selectedLead}
                leads={leads}
                phone={phone}
                skipduplicate={skipduplicate}
                crmIntegration={crmIntegration}
                handleOpportunityId={handleOpportunityId}
                user={user}
                handleOpportunityIdDialog={handleOpportunityIdDialog}
                onCreateLead={onCreateLead}
                lang={lang}
                locationPermission={locationPermission}
                isCrm={isCrm}
                setSelectedLead={setSelectedLead}
                getInitialReviewMessage={getInitialReviewMessage}
                onLeadStatus={onLeadStatus}
                pullToSMAI={pullToSMAI}
                onLeadOwners={onLeadOwners}
                onVehicles={onVehicles}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                getLeadsPhoneFromCRM={getLeadsPhoneFromCRM}
                setLeads={setLeads}
                setisCrm={setisCrm}
                variables={variables}
                crmLoading={crmLoading}
                crmPullLoader={crmPullLoader}
              />
            )}
          </>
        )}
        {/* {isEmpty(selectedLead) && leads.length > 0 && !isEmpty(phone) && !skipduplicate && (
          <Typography className="w-full mt-1" variant="small" weight="medium">
            {isCrm ? lang.matchPhoneInCrm : lang.matchWithLeadsInCrm}
          </Typography>
        )} */}
        {/* <Spin
          spinning={loading || crmLoading || crmPullLoader}
          tip={`${lang.loading}...`}
          className="w-full flex justify-center"
        />{" "} */}
      </ModelDiv>
      {opportunityWarningDialog && (
        <OpportunityWarning
          opportunityInfo={opportunityInfo}
          crmIntegrationType={crmIntegration}
          crmLeadObj={opportunityInfo?.crmLeadObj}
          visibleModal={opportunityWarningDialog}
          handleOKBtn={() => setOpportunityWarningDialog(false)}
          setModalVisible={() => setOpportunityWarningDialog(false)}
          handleOpporDialogFlagChanged={handleOpporDialogFlagChanged}
        />
      )}
      <DialogFooter
        disabled={isEmpty(selectedLead) && leads.length > 0 && !skipduplicate}
        cancelBtnText={lang.cancel}
        handleConfirm={handleSave}
        handleCancel={() => closeDialog("sendReviewInvite")}
        loading={reviewMessageLoading || createLeadLoader}
        showConfirmButton={
          !(isEmpty(selectedLead) && leads.length > 0 && !skipduplicate) && !isEmpty(phone)
        }
      />
      <style jsx>{`
        .phoneInput input {
          border: 1px solid #d9d9d9;
          width: 373px;
          padding: 5px;
          border-radius: 4px;
        }
      `}</style>
    </>
  );
};

export default SendReviewInviteModal;
function FoundLeadsJsx({
  selectedLead,
  leads,
  phone,
  skipduplicate,
  crmIntegration,
  handleOpportunityId,
  user,
  handleOpportunityIdDialog,
  onCreateLead,
  lang,
  locationPermission,
  isCrm,
  setSelectedLead,
  getInitialReviewMessage,
  onLeadStatus,
  pullToSMAI,
  onLeadOwners,
  onVehicles,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  variables,
  setisCrm,
  setLeads,
  getLeadsPhoneFromCRM,
  crmLoading,
  crmPullLoader,
}) {
  console.log(selectedLead);
  const { loading } = useQuery(GET_LEADS_BY_PHONE, {
    fetchPolicy: "network-only",
    variables: {
      pageSize: 5,
      page: 1,
      phone: phone,
    },
    onCompleted: resp => {
      if (resp?.getLeadsSearch?.data.length > 0) {
        setisCrm(false);
        setLeads(resp?.getLeadsSearch?.data || []);
      } else {
        getLeadsPhoneFromCRM({
          variables: {
            phone: phone,
          },
        });
      }
    },
  });

  return (
    <>
      {loading || crmLoading || crmPullLoader ? (
        <div className="flex mt-2">
          <Skeleton active avatar loading={loading || crmLoading || crmPullLoader} />
        </div>
      ) : (
        <>
          {isEmpty(selectedLead) && leads.length > 0 && !isEmpty(phone) && !skipduplicate && (
            <div className="w-full">
              <List
                itemLayout="vertical"
                dataSource={leads}
                pagination={false}
                renderItem={item => {
                  const links = [];
                  crmIntegration?.length > 0 &&
                    links.push(
                      localStorage.getItem("isOpporDialogFlagChangedLocal")
                        ? handleOpportunityId(
                            item?.crmLeadObj?.activeLeadOpportunity,
                            item?.crmLeadObj,
                            item
                          )
                        : !handleOpportunityDilaog(user?.locations, user)
                        ? handleOpportunityId(
                            item?.crmLeadObj?.activeLeadOpportunity,
                            item?.crmLeadObj,
                            item
                          )
                        : handleOpportunityIdDialog(
                            item?.crmLeadObj?.activeLeadOpportunity,
                            item?.crmLeadObj,
                            item
                          )
                    );
                  if (+sessionStorage.getItem("currentLocationId") !== +item?.location?.id) {
                    links.push(
                      <div className="labelColor m-0" onClick={() => onCreateLead(item)}>
                        <Tooltip placement="topLeft" title={lang.addPhoneToLocation}>
                          <Typography
                            variant="regular"
                            weight="medium"
                            color={Colors.primaryBrandBlue}
                          >
                            {lang.addToLocation}
                          </Typography>
                        </Tooltip>
                      </div>
                    );
                  }
                  const email = item?.emails ? item?.emails[0]?.email || "" : "";

                  const leadStatus = item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus
                    ?.parentStatus
                    ? `${
                        item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.parentStatus
                          ?.name
                      } / ${
                        item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name || ""
                      }`.replace(/_/g, " ")
                    : item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name || "";

                  return (
                    <List.Item className="px-0" actions={links}>
                      <List.Item.Meta
                        avatar={
                          <Avatar size={40}>
                            {item?.fullName ? item?.fullName.substring(0, 1).toUpperCase() : "X"}
                          </Avatar>
                        }
                        title={
                          <div className="labelColor flex flex-wrap justify-between">
                            <div className="flex ml-2">
                              <CustomLabel
                                onClick={() => {
                                  locationPermission(item);
                                }}
                                label={
                                  !isCrm
                                    ? item?.fullName || ""
                                    : `${item?.firstName} ${item?.lastName}`
                                }
                                labelClass="labelColor font-medium"
                              />
                              <CustomButton
                                className="ml-2"
                                btnText={lang.selectLead}
                                key={item.id}
                                handleClick={() => {
                                  setSelectedLead(item);
                                  getInitialReviewMessage({
                                    variables: {
                                      leadId: item.id,
                                    },
                                  });
                                }}
                              />
                            </div>
                            {!isCrm ? (
                              <div>
                                <Tooltip placement="topLeft" title={leadStatus}>
                                  {leadStatus}
                                </Tooltip>
                              </div>
                            ) : (
                              <div>
                                <Tag
                                  onClick={() =>
                                    pullToSMAI(item?.leadId, item?.locationId, item, item.mobile)
                                  }
                                >
                                  {lang.pullToSmai}
                                </Tag>
                              </div>
                            )}
                          </div>
                        }
                        description={
                          <>
                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.contacts}:{" "}
                              <strong>
                                {!isCrm
                                  ? displayPhoneNumbers(item?.phoneNumbers || [])
                                  : item.mobile}
                              </strong>
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.emailLabel}:{" "}
                              <strong>
                                {!isCrm ? (email === "" ? "N/A" : email) : item.email}
                              </strong>
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.source}:{" "}
                              <strong>{`${
                                item?.crmLeadObj?.activeLeadOpportunity?.opportunitySource?.name ||
                                "N/A"
                              }`}</strong>
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.location}:{" "}
                              <strong>
                                {!isCrm
                                  ? `${item?.location?.title || ""} (${
                                      item?.location?.locationType || lang.store
                                    })`
                                  : item.location}
                              </strong>
                            </div>
                            <div className="m-0 w-full mb-[6px] items-start">
                              {lang.status}:{" "}
                              <span>
                                {LeadStatus(
                                  user,
                                  item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name
                                )}
                              </span>
                            </div>
                            {(item?.leadUsers || []).length > 0 && !isCrm ? (
                              <>
                                {(crmIntegration === "SF" || crmIntegration === "DS") && (
                                  <div className="m-0 w-full mb-[6px] items-start">
                                    {lang.owners}:
                                  </div>
                                )}
                                <div className="m-0 w-full mb-[6px] items-start">
                                  {crmIntegration === "" && <>{lang.owners}:</>}{" "}
                                  <p className="text-left">
                                    {item?.leadUsers.length > 0
                                      ? onLeadOwners(item?.leadUsers || [], crmIntegration)
                                      : "N/A"}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <div className="m-0 w-full mb-[6px] items-start">
                                {lang.owner}: <p className="text-left">{item.owner}</p>
                              </div>
                            )}
                            {(item?.crmLeadObj?.activeLeadOpportunity?.vehicleOfInterest || [])
                              .length > 0 && (
                              <div className="m-0 w-full mb-[6px] items-start">
                                <p>
                                  {lang.vehicles}:{" "}
                                  <span className="text-left">
                                    {onVehicles(
                                      item?.crmLeadObj?.activeLeadOpportunity?.vehicleOfInterest ||
                                        []
                                    )}
                                  </span>
                                </p>{" "}
                              </div>
                            )}
                          </>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </div>
          )}

          {isEmpty(selectedLead) && (leads.length <= 0 || skipduplicate) && !isEmpty(phone) && (
            <>
              <div className="w-full">
                <p className="mt-1 font-normal w-full">
                  {lang.firstName}
                  <span className="requireFieldStar">*</span>
                </p>

                <TextInput
                  placeholder={lang.firstName}
                  name="firstName"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </div>
              <div className="w-full">
                <p className="mt-1 font-normal w-full">
                  {lang.lastName}
                  <span className="requireFieldStar">*</span>
                </p>
                <TextInput
                  placeholder={lang.lastName}
                  name="lastName"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
