import * as React from "react";

const PauseIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#334155"
      stroke="#334155"
      strokeWidth={0.078}
      d="M14 15.04h.04V5.46h-2.08V15.04H14Zm-6.5 0h.04V5.46H5.46V15.04H7.5Zm4.25-11h2.5a1.21 1.21 0 0 1 1.21 1.21v10a1.21 1.21 0 0 1-1.21 1.21h-2.5a1.21 1.21 0 0 1-1.21-1.21v-10a1.21 1.21 0 0 1 1.21-1.21Zm-6.5 0h2.5a1.21 1.21 0 0 1 1.21 1.21v10a1.21 1.21 0 0 1-1.21 1.21h-2.5a1.21 1.21 0 0 1-1.21-1.21v-10a1.21 1.21 0 0 1 1.21-1.21Z"
    />
  </svg>
);
export default PauseIcon;
