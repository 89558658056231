import { useSelector } from "react-redux";

const useCrmIntegration = () => {
  const crmIntegration = useSelector(
    state => state?.apiCache?.apiCache?.crmLeadStatus?.crmIntegration
  );

  return crmIntegration;
};

export default useCrmIntegration;
