import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Input, message } from "antd";
import { SUBMIT_SUPPORT_TICKET } from "../../graphql/mutation";
import CustomAlert from "../commonComponents/alert";
import DialogFooter from "../commonComponents/dialogFooter";
import CustomLabel from "../commonComponents/label";
import useLang from "../../hooks/useLang";

const SupportModal = ({ onClose }) => {
  const [lang] = useLang();

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [error, setError] = useState("");

  const [submitSupport] = useMutation(SUBMIT_SUPPORT_TICKET);

  const handleSupportSubmit = () => {
    if (subject.trim() && body.trim()) {
      setError("");
      submitSupport({
        variables: {
          subject,
          message: body,
        },
      }).then(() => {
        message.success(lang.msgSent);
        onClose();
      });
    } else {
      setError(lang.filloutAllFields);
    }
  };

  return (
    <div>
      <CustomLabel label={lang.sendUsMsg} labelClass="text-[15px] font-semibold" />
      <CustomLabel label={lang.smaiWillContact} labelClass="text-[15px] pt-1" />

      <CustomLabel label={lang.subject} labelClass="text-[15px] pt-1" />
      <Input onChange={e => setSubject(e.target.value)} name="subject" />

      <CustomLabel label={lang.message} labelClass="text-[15px] pt-1" />
      <Input.TextArea rows={5} onChange={e => setBody(e.target.value)} name="body" />

      {error && <CustomAlert className="pt-2" message={error} type="error" />}
      <DialogFooter
        confirmBtnText={lang.send}
        handleConfirm={handleSupportSubmit}
        handleCancel={onClose}
      />
    </div>
  );
};

export default SupportModal;
