/* eslint-disable react/destructuring-assignment */
import * as React from "react";

const HandShake = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <g fill={props.color || "#A855F7"} clipPath="url(#a)">
      <path d="M6.034 5.424C5.15 4.54 1.623 4.54.9 4.54c-.24 0-.401.16-.401.401v8.822c0 .24.16.401.401.401h3.208c.16 0 .32-.16.4-.32.081-.241 1.364-5.454 1.605-8.1 0-.16 0-.241-.08-.321Zm2.726 4.49c0 .08.08.161.241.241.882.321 1.444.16 1.925-.641.08-.08.16-.16.32-.16.241 0 .321-.08.562-.241.24-.16.481-.321.962-.562h.16c.08 0 .241.08.321.08.562.562 1.604 1.444 2.727 2.326 1.203.963 2.486 1.925 3.368 2.807l-.24-1.042c-.642-1.765-1.524-4.01-2.085-5.053-.642-.32-1.604-.722-2.647-1.123-.641-.32-1.203-.48-1.684-.48-.802 0-1.524.4-2.646 1.603-.722.562-1.364 1.684-1.284 2.246Zm15.398-4.57c-3.93 0-6.416.801-6.576.801-.08 0-.24.16-.24.241-.08.08 0 .24 0 .32.641 1.043 2.806 6.577 3.127 7.94.08.16.24.321.401.321h3.208c.24 0 .401-.16.401-.4V5.743c.08-.24-.08-.4-.32-.4Z" />
      <path d="M15.498 11.599a56.981 56.981 0 0 1-2.647-2.165c-.24.08-.4.24-.481.32-.24.16-.401.321-.802.401-.722.963-1.604 1.203-2.727.802-.561-.16-.721-.561-.802-.802-.24-.962.722-2.325 1.444-3.047.32-.321.722-.642 1.042-.882H6.917c-.321 2.726-1.524 7.699-1.604 7.94 0 .08-.08.08-.08.16l1.042 1.042c2.005 1.684 4.571 3.449 4.812 3.609.481.32.962.481 1.443.481.321 0 .802 0 1.203-.24l-1.924-1.925a.388.388 0 0 1 0-.561c.16-.16.4-.16.561 0l2.005 2.004c.24.241.722.321.962 0 .321-.24.321-.721 0-1.042l-2.406-2.406a.388.388 0 0 1 0-.561c.08-.08.241-.16.401-.08l3.369 3.368c.16.16.4.24.561.24.16 0 .401-.08.561-.24a.775.775 0 0 0 0-1.123l-3.689-3.85c0-.16 0-.24.08-.4.161-.161.402-.161.562 0 0 0 .24.4.32.48l3.61 3.61c.16.16.4.24.561.24.16 0 .401-.08.561-.24a.775.775 0 0 0 0-1.123c.08-.08-4.33-4.01-4.33-4.01Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h24v24H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default HandShake;
