import * as React from "react";

const CloneIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="#334155"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.416 12.708a1.458 1.458 0 0 1-1.458-1.458V5.625c0-.92.746-1.667 1.667-1.667h5.625c.805 0 1.458.653 1.458 1.459m-3.75 10.625h5.417c.92 0 1.666-.747 1.666-1.667V8.958c0-.92-.746-1.666-1.666-1.666H8.958c-.92 0-1.667.746-1.667 1.666v5.417c0 .92.747 1.667 1.667 1.667Z"
    />
  </svg>
);
export default CloneIcon;
