/* eslint-disable no-unreachable */
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  concat,
  from,
} from "@apollo/client";
import { onError } from "apollo-link-error";
import { withAuthenticator, useTheme, View, Image, Text, Heading } from "@aws-amplify/ui-react";
import { navigate } from "gatsby";
import Layout from "../components/layout";
import resolvers from "../graphql/resolvers";
import typeDefs from "../graphql/typeDefs";
import { getLocationId } from "../redux/slices/locationSlice";
import { colorConfig } from "../library/colorConfig";
import { COPY_RIGHTS, GRAPHQL_URL, SMAI_LOGO } from "../config";
import DialogHoc from "../components/hoc/Dialog/dialogHOC";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) {
    console.log(`[NetworkError error]: Message: ${networkError.message}`);
    // if (networkError instanceof Error && networkError.message.includes("Failed to fetch")) {
    //   return navigate("/404.html");
    // }
  }
});

const getJwtToken = async () => {
  return new Promise(resolve => {
    const storedToken = sessionStorage.getItem("token");
    const exp = sessionStorage.getItem("exp");
    let isExpired = false;
    if (exp) {
      const expiredDate = new Date(parseInt(exp));
      isExpired = expiredDate <= new Date();
    }
    if (!storedToken || !exp || isExpired) {
      Auth.currentSession()
        .then(session => session.getAccessToken())
        .then(accessToken => {
          const jwtToken = accessToken.getJwtToken();
          sessionStorage.setItem("token", jwtToken);
          sessionStorage.setItem("exp", (accessToken.payload.exp * 1000).toString());
          resolve(jwtToken);
        });
    } else {
      resolve(storedToken);
    }
  });
};

const refreshToken = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const storedToken = sessionStorage.getItem("token");
    const exp = sessionStorage.getItem("exp");
    let almostExpired = false;
    if (exp) {
      const expiredDate = new Date(parseInt(exp) - 15 * 60 * 1000);
      almostExpired = expiredDate <= new Date();
    }
    if (!storedToken || !exp || almostExpired) {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          const { accessToken } = session;
          const jwtToken = accessToken.getJwtToken();
          sessionStorage.setItem("token", jwtToken);
          sessionStorage.setItem("exp", (accessToken.payload.exp * 1000).toString());
          resolve(jwtToken);
        });
      } catch (e) {
        console.log("Unable to refresh token", e);
        reject();
      }
    } else {
      resolve(storedToken);
    }
  });
};

export const UserContext = React.createContext();

const AuthenticatorProvider = props => {
  const { persistor, children, authData, authState } = props;

  const locationIdslice = useSelector(state => getLocationId(state));

  const [client, setClient] = useState(null);
  const [token, setToken] = useState(null);
  const [auth, setAuth] = useState(null);
  const [modal, setmodal] = useState(false);
  const [fullScreen, setMaximizeScreen] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const [leadIds, setleadIds] = useState([]);
  const [loadingAuth, setloadingAuth] = useState(false);
  const [addnewLead, setAddNewLead] = useState(false);
  const [newLead, setNewLead] = useState(null);
  const [socketMsgSelectedLead, setSocketMsgSelectedLead] = useState("");
  const [socket, setSocket] = useState(null);
  const [refetchAppointments, setrefetchAppointments] = useState(false);
  const [isUserIsBeingSet, setIsUserIsBeingSet] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [user, setUser] = useState({
    email: "",
    fullName: "",
    phone: "",
    isDisabled: false,
    locationId: "",
    company: {
      name: "",
      isDisabled: false,
      aingineSourceId: null,
      isOptinConsentMethod: false,
      crmIntegrationType: "",
    },
    role: {
      name: "default",
      canCreateTeams: false,
      canCreateUsers: false,
      canViewProspects: false,
    },
    teamsLeader: null,
    accessControlPermissions: [],
  });

  const updateUser = _loginUser => {
    let loginUser = { ..._loginUser };
    loginUser.token = token;

    const allActiveUserAccounts = loginUser.userAccounts.filter(res => {
      if (!res.company.isDisabled && !res.isDisabled) {
        return true;
      }
    });

    const allUserAccounts = allActiveUserAccounts.map(res => {
      return res.company;
    });

    let userRoleObj = loginUser.role;
    // if(typeof loginUser.company.userRoles.length >0 ){
    //   userRoleObj=loginUser.company.userRoles[0].role;
    // }
    if (loginUser?.company?.userRoles?.length > 0) {
      const found = loginUser.company.userRoles.find(x => x.userId === Number(loginUser.id));
      userRoleObj = found ? found.role : userRoleObj;
    }

    const getActiveCrmIntegration = company => {
      let type = "";
      let { locationId } = loginUser;
      if (sessionStorage?.getItem("currentLocationId")?.includes(",")) {
        //
      } else {
        locationId = sessionStorage.getItem("currentLocationId");
      }
      if ((company?.crmIntegration || []).length > 0) {
        const findIndex = (company?.crmIntegration).findIndex(
          el => el.active && +el.locationId === +locationId
        );
        if (findIndex > -1) {
          type = company?.crmIntegration[findIndex].integrationType;
        }
      }
      return type;
    };

    const userTemp = {
      ...loginUser,
      id: loginUser.id,
      email: loginUser.email,
      fullName: loginUser.fullName,
      phone: loginUser.phone,
      firstName: loginUser.firstName,
      lastName: loginUser.lastName,
      isDisabled: loginUser.isDisabled,
      locationId: loginUser.locationId,
      company: {
        id: loginUser.company.id,
        name: loginUser.company.name,
        isDisabled: loginUser.company.isDisabled,
        aingineSourceId: loginUser.company.aingineSourceId,
        timezone: loginUser.company.timezone,
        isOptinConsentMethod: loginUser.company.isOptinConsentMethod,
        crmIntegrationType: getActiveCrmIntegration(loginUser?.company),
        isOpenCrmWarningPopup: loginUser.company.isOpenCrmWarningPopup,
      },
      // role: loginUser.role,
      role: userRoleObj,
      teamsLeader: loginUser.teamsLeader,
      userAccounts: allUserAccounts.sort((a, b) => (a.name > b.name ? 1 : -1)),
      accessControlPermissions: user.accessControlPermissions,
      companyRole: loginUser?.companyRole || {},
      location: loginUser?.location,
      locations: loginUser?.locations,
    };
    loginUser = userTemp;
    setIsUserIsBeingSet(false);
    setUser(loginUser);
  };

  const updateUserWithPermissions = (_loginUser, permissions) => {
    let loginUser = { ..._loginUser };
    loginUser.token = token;

    const allActiveUserAccounts = (loginUser.userAccounts || []).filter(res => {
      if (!res.company.isDisabled && !res.isDisabled) {
        return true;
      }
    });

    const allUserAccounts = allActiveUserAccounts.map(res => {
      return res.company;
    });

    let userRoleObj = loginUser.role;
    // if(typeof loginUser.company.userRoles.length >0 ){
    //   userRoleObj=loginUser.company.userRoles[0].role;
    // }
    if (loginUser?.company?.userRoles?.length > 0) {
      const found = loginUser.company.userRoles.find(x => x.userId === Number(loginUser.id));
      userRoleObj = found ? found.role : userRoleObj;
    }

    const getActiveCrmIntegration = company => {
      let type = "";
      let { locationId } = loginUser;
      if (sessionStorage?.getItem("currentLocationId")?.includes(",")) {
        //
      } else {
        locationId = sessionStorage.getItem("currentLocationId");
      }
      if ((company?.crmIntegration || []).length > 0) {
        const findIndex = (company?.crmIntegration).findIndex(
          el => el.active && +el.locationId === +locationId
        );
        if (findIndex > -1) {
          type = company?.crmIntegration[findIndex].integrationType;
        }
      }
      return type;
    };

    const userTemp = {
      ...loginUser,
      id: loginUser.id,
      email: loginUser.email,
      fullName: loginUser.fullName,
      phone: loginUser.phone,
      firstName: loginUser.firstName,
      lastName: loginUser.lastName,
      isDisabled: loginUser.isDisabled,
      locationId: loginUser.locationId,
      // company: {
      //   id: loginUser?.company?.id,
      //   name: loginUser?.company?.name,
      //   isDisabled: loginUser.company.isDisabled,
      //   aingineSourceId: loginUser.company.aingineSourceId,
      //   timezone: loginUser.company.timezone,
      //   isOptinConsentMethod: loginUser.company.isOptinConsentMethod,
      //   crmIntegrationType: getActiveCrmIntegration(loginUser?.company),
      //   isOpenCrmWarningPopup: loginUser.company.isOpenCrmWarningPopup,
      // },
      company:
        {
          ...loginUser?.company,
          crmIntegrationType: getActiveCrmIntegration(loginUser?.company),
        } || {},
      // role: loginUser.role,
      role: userRoleObj,
      teamsLeader: loginUser.teamsLeader,
      userAccounts: allUserAccounts.sort((a, b) => (a.name > b.name ? 1 : -1)),
      accessControlPermissions: permissions,
      companyRole: loginUser?.companyRole || {},
      location: loginUser?.location,
      locations: loginUser?.locations,
    };
    loginUser = userTemp;
    setIsUserIsBeingSet(false);
    setUser(loginUser);
  };

  const httpLink = new HttpLink({ uri: GRAPHQL_URL });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const sesstionToken = sessionStorage.getItem("token");
    const tokenInfo = sesstionToken || token;

    let customHeader = {
      authorization: tokenInfo ? `Bearer ${tokenInfo}` : "",
      refreshToken: sessionStorage.getItem("refreshToken"),
    };
    if (locationIdslice != null || sessionStorage.getItem("currentLocationId") !== null) {
      customHeader = {
        ...customHeader,
        sLocationId:
          locationIdslice === "all"
            ? sessionStorage.getItem("currentLocationId")
            : locationIdslice !== null
            ? locationIdslice
            : sessionStorage.getItem("currentLocationId"),
      };
    }
    // eslint-disable-next-line no-unused-vars
    operation.setContext(({ headers = {} }) => ({
      headers: { ...customHeader },
    }));
    // let isTokenRefreshing = false;
    return forward(operation);
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(cognitoUser => {
      setAuth(cognitoUser);
      setloadingAuth(true);
    });
  }, []);
  const getRefreshToken = async () => {
    const currentSession = await Auth.currentSession();
    sessionStorage.setItem("refreshToken", currentSession.getRefreshToken().token);
  };
  useEffect(() => {
    sessionStorage.setItem("isLogin", true);
    getRefreshToken();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("isLogin")) {
      getJwtToken().then(t => {
        setToken(t);
      });
      const links = ApolloLink.from([errorLink, httpLink]);
      setClient(
        new ApolloClient({
          cache: new InMemoryCache({
            addTypename: false,
          }),
          link: concat(authMiddleware, links),
          resolvers,
          typeDefs,
        })
      );

      const refreshInterval = setInterval(() => {
        refreshToken().then(newToken => {
          // setToken(newToken);
          // sessionStorage.setItem("token", newToken);
          Auth.currentSession().then(() => {
            Auth.currentAuthenticatedUser().then(() => {
              // setAuth(cognitoUser)
            });
          });
        });
      }, 5 * 60 * 1000);

      return () => clearInterval(refreshInterval);
    }
  }, [token, locationIdslice]);

  const authContent = auth || authData;

  return client && loadingAuth ? (
    <ApolloProvider client={client}>
      <UserContext.Provider
        value={{
          user: user,
          fullScreen,
          setMaximizeScreen,
          notificationData,
          socket,
          setSocket,
          setNotificationData,
          setUser,
          authData: authContent,
          leadIds,
          setleadIds,
          socketMsgSelectedLead,
          setSocketMsgSelectedLead,
          modal: modal,
          setmodal: setmodal,
          isUserIsBeingSet,
          persistor: persistor,
          newLead,
          setNewLead,
          addnewLead,
          setAddNewLead,
          isFullScreen,
          setIsFullScreen,
          refetchAppointments,
          setrefetchAppointments,
        }}
      >
        <DialogHoc />
        <Layout
          authData={authContent}
          updateUser={updateUser}
          updateUserWithPermissions={updateUserWithPermissions}
        >
          {React.cloneElement(children, {
            authState: authState,
            authData: authContent,
          })}
        </Layout>
      </UserContext.Provider>
    </ApolloProvider>
  ) : null;
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="smai logo" src={SMAI_LOGO} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text
          color={{
            base: colorConfig.WHITE,
          }}
        >
          {COPY_RIGHTS}
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          color={colorConfig.WHITE}
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
  },
};

export default withAuthenticator(AuthenticatorProvider, {
  components,
  hideSignUp: true,
});
