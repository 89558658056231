import { Checkbox } from "antd";
import React from "react";

const CustomCheckbox = props => {
  const { handleChange, className, checkboxLabel, checked = false, disabled } = props;
  return (
    <Checkbox className={className} disabled={disabled} checked={checked} onChange={handleChange}>
      {checkboxLabel}
    </Checkbox>
  );
};

CustomCheckbox.defaultProps = {
  disabled: false,
  checked: false,
  className: "",
};

export default CustomCheckbox;
