import { Avatar } from "antd";
import React from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { timezones } from "../../../library/constants";
import CustomCheckbox from "../../commonComponents/checkbox";
import CustomLabel from "../../commonComponents/label";
import useLang from "../../../hooks/useLang";

const CompanyProfileInfo = ({ company, handleCheckbox, checkbox }) => {
  const [lang] = useLang();

  const renderTimezone = e => {
    const obj = timezones.find(el => el.value === e);
    return obj ? obj.label : "N/A";
  };

  return (
    <div className="p-[15px] py-1">
      <Avatar
        className="rounded-full w-[100px] h-[100px]"
        size={100}
        src={company?.profilePic ? company.profilePic : "/icons/Avatar.svg"}
      />

      <CustomLabel
        label={lang.companyInfo}
        labelClass="mt-2 font-medium text-[17px] secondaryColorTheme"
      />
      <p className="font-normal py-1 text-[14px] mt-1">
        {lang.companyName}: <span className="font-medium">{company?.name}</span>
      </p>
      <p className="font-normal py-1 text-[14px] mt-1">
        {lang.phone}:
        <span className="font-medium">{formatPhoneNumber(company?.phone) || "N/A"}</span>
      </p>
      <p className="font-normal py-1 text-[14px] mt-1">
        {lang.website}: <span className="font-medium">{company?.website}</span>
      </p>
      <CustomLabel
        label={lang.companyAddressLabel}
        labelClass="mt-2 font-medium text-[17px] secondaryColorTheme"
      />
      <p className="font-normal py-1 text-[14px] mt-1">
        {lang.city}: <span className="font-medium">{company?.city}</span>
      </p>
      <p className="font-normal py-1 text-[14px] mt-1">
        {lang.state}:
        <span className="font-medium">{formatPhoneNumber(company?.state) || "N/A"}</span>{" "}
      </p>
      <p className="font-normal py-1 text-[14px] mt-1">
        {lang.postalCode}: <span className="font-medium">{company?.postalCode}</span>
      </p>
      <p className="font-normal py-1 text-[14px] mt-1">
        {lang.country}: <span className="font-medium">{company?.country}</span>
      </p>
      <p className="font-normal py-1 text-[14px] mt-1">
        {lang.locationUrl}:{" "}
        <a href={company?.locationLink} target="_blank" className="font-medium" rel="noreferrer">
          {company?.locationLink}
        </a>
      </p>
      <p className="font-normal py-1 text-[14px] mt-1">
        {lang.timezone}: <span className="font-medium">{renderTimezone(company?.timezone)}</span>
      </p>
      <CustomLabel
        label={lang.companyPreference}
        labelClass="mt-2 font-medium text-[17px] secondaryColorTheme"
      />
      <CustomCheckbox
        className="font-medium"
        handleChange={handleCheckbox}
        checkboxLabel={lang.popupSwitchToLocation}
        checked={checkbox}
      />
    </div>
  );
};

export default CompanyProfileInfo;
