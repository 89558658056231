import Icon from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Checkbox, message } from "antd";
import { cloneDeep, isEmpty } from "lodash";
import React, { useContext, useRef, useState } from "react";
import EngagedAI from "../../../../static/EngagedAi_trans_bg.svg";
import { UserContext } from "../../../amplify/authenticator-provider";
import { GET_AI_RESPONSE, GET_USE_CASES } from "../../../graphql/query";
import { LABELS } from "../../../library/constants";
import TemplateDrawer from "../schedual/templateDrawer";
import { handleSaveMessage } from "./commonFunctions";
import { CREATE_CAMPAIGN_SCHEDULES, UPDATE_CAMPAIGN_SCHEDULES } from "../../../graphql/mutation";
import useLang from "../../../hooks/useLang";

export const Form = ({
  internalIndex,
  sourceId,
  campaignData,
  index,
  setCampaignData,
  activeKeys,
  setactiveKeys,
}) => {
  const [lang] = useLang();
  const textRef = useRef(null);
  const afterMessageTextRef = useRef(null);
  const [templateDrawer, settemplateDrawer] = useState({ visible: false });
  const [loading, setLoading] = useState(false);
  const [loadingAfterWorkHour, setLoadingAfterWorkHour] = useState(false);
  const { data: useCases } = useQuery(GET_USE_CASES);
  const { user } = useContext(UserContext);
  const [isAfterHour, setIsAfterHour] = useState(false);
  const [messageIndex, setMessageIndex] = useState(null);

  const buildCampaignInput = () => {
    const campaignInput = {
      campaignId: campaignData?.id,
      sourceIds: campaignData?.source?.map(item => item[0]),
      statusIds: campaignData?.status,
      schedules: [],
      startDuration: campaignData?.type,
      messageIndex: index,
      title: campaignData?.title,
      description: "",
      engagementMethod: campaignData?.method,
      startDate: campaignData?.startDate,
      endDate: campaignData?.endDate,
    };
    (campaignData?.messages).forEach((message, index) => {
      campaignInput.schedules.push({
        index: index,
        title: message?.title,
        templateText: message?.campaignTemplates[0]?.templateText,
        repeat: message?.type === "REPEAT",
        numericValue: message?.numericValue,
        temporalValue: message?.temporalValue,
        isAfterHour: message?.isAfterHour,
      });
    });
    return campaignInput;
  };

  const [getAIResponse, { data: aiResponseData }] = useLazyQuery(GET_AI_RESPONSE, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      if (res?.getAiResponse?.statusCode === 200) {
        if (!isAfterHour) {
          textRef.current.value = res?.getAiResponse?.content;
        } else {
          afterMessageTextRef.current.value = res?.getAiResponse?.content;
        }
      } else message.error(lang.ottoAiSuggestionFailedText);

      setLoading(false);
      setLoadingAfterWorkHour(false);
    },
    onError: err => {
      setLoading(false);
      setLoadingAfterWorkHour(false);
    },
  });
  const handleKeyWord = text => {
    const elem = document.getElementById(`textArea-${internalIndex}`);
    const startPos = elem.selectionEnd;
    const output = [
      textRef.current.value.slice(0, startPos),
      `${text} `,
      textRef.current.value.slice(startPos),
    ].join("");
    setCampaignData(prev => {
      const temp = structuredClone(prev).messages;
      temp[index] = {
        ...temp[index],
        isChanged: true,
      };
      temp[index].campaignTemplates[internalIndex === "all" ? 0 : internalIndex + 1] = {
        templateText: output,
        sourceId: sourceId,
      };
      return { ...prev, messages: temp };
    });
    if (textRef.current) {
      textRef.current.focus();
    }
  };
  const [createCampaignSchedules, { loading: createCampaignSchedulesLoader }] =
    useMutation(CREATE_CAMPAIGN_SCHEDULES);
  const [updateCampaignSchedules, { loading: updateCampaignSchedulesLoader }] =
    useMutation(UPDATE_CAMPAIGN_SCHEDULES);
  const handleKeyWordAfterTextMessage = text => {
    const elem = document.getElementById("textArea-afterMessage");
    const startPos = elem.selectionEnd;
    const output = [
      afterMessageTextRef.current.value.slice(0, startPos),
      `${text} `,
      afterMessageTextRef.current.value.slice(startPos),
    ].join("");
    setCampaignData(prev => {
      const temp = structuredClone(prev).messages;
      temp[index] = {
        ...temp[index],
        isChanged: true,
      };
      temp[index].campaignTemplates[internalIndex === "all" ? 0 : internalIndex + 1] = {
        ...temp[index].campaignTemplates[internalIndex === "all" ? 0 : internalIndex + 1],
        afterHourTemplateText: output,
      };
      return { ...prev, messages: temp };
    });
    if (afterMessageTextRef.current) {
      afterMessageTextRef.current.focus();
    }
  };
  const saveMessage = async index => {
    if (isEmpty(campaignData.messages[index].title)) {
      message.error("Please enter the title of the message");
      return;
    }
    if (
      campaignData.messages[index].campaignTemplates.length > 0 &&
      !isEmpty(campaignData.messages[index]?.campaignTemplates[0]?.templateText)
    ) {
      // call the createCampaignSchedules
      // const messageObj = { ...campaignData.messages[index], sortOrder: index };
      const messageObj = cloneDeep({ ...campaignData.messages[index], sortOrder: index });
      delete messageObj.createId;
      delete messageObj.isChanged;
      if (campaignData.messages[index].scheduleId) {
        // update Single Campaign Schedule
        const payload = cloneDeep(campaignData.messages[index]);
        delete payload.createId;
        delete payload.isChanged;
        const res = await updateCampaignSchedules({
          variables: { message: { ...payload, sortOrder: index } },
        });
        if (res.data.updateCampaignSchedules.statusCode) {
          setCampaignData(prev => {
            const temp = structuredClone(prev);
            temp.messages[index] = {
              ...temp.messages[index],
              isChanged: false,
            };
            return temp;
          });
          message.success("Message successfully updated");
          return true;
        }
      } else {
        // create Single campaign Schedule
        const res = await createCampaignSchedules({
          variables: {
            campaignId: campaignData.id,
            message: messageObj,
          },
        });
        if (res.data.createCampaignSchedules.statusCode) {
          setCampaignData(prev => {
            const temp = structuredClone(prev);
            temp.messages[index] = {
              ...temp.messages[index],
              scheduleId: res.data.createCampaignSchedules.campaignSchedule.id,
              isChanged: false,
            };
            return temp;
          });
          message.success("Message successfully created");
          return true;
        }
      }
      return;
    }
    message.error("Please enter the atleast one template text!");
    return false;
  };
  return (
    <>
      <div className="flex flex-col items-start">
        <span>Click the desired snippet to add to the text message</span>
        <div className="flex flex-row flex-wrap gap-2 my-[15px]">
          {LABELS.map((item, index) => (
            <span
              onClick={() => handleKeyWord(item.addKeyword)}
              key={index}
              className="cursor-pointer text-sm text-brand-500 font-[400]"
            >
              {item.label}
            </span>
          ))}
        </div>
      </div>
      <div className="flex flex-col w-full relative">
        <textarea
          ref={textRef}
          value={
            campaignData?.messages[index]?.campaignTemplates[
              internalIndex === "all" ? 0 : internalIndex + 1
            ]?.templateText
          }
          onChange={e => {
            setCampaignData(prev => {
              const temp = structuredClone(prev).messages;
              temp[index] = {
                ...temp[index],
                isChanged: true,
              };
              temp[index].campaignTemplates[internalIndex === "all" ? 0 : internalIndex + 1] = {
                ...temp[index].campaignTemplates[internalIndex === "all" ? 0 : internalIndex + 1],
                templateText: e.target.value,
                sourceId: sourceId,
              };
              return { ...prev, messages: temp };
            });
          }}
          id={`textArea-${internalIndex}`}
          placeholder="Enter the campaign message"
          className="w-full min-h-[50px] px-2 py-1 rounded-sm border-[1px] border-solid border-grey-200 focus-visible:border-grey-200 pr-12"
        />
        <span className="absolute right-2 top-2 flex">
          <Button
            key="campaignMessageSuggestion"
            loading={loading}
            className="text-lg"
            onClick={() => {
              setIsAfterHour(false);
              setMessageIndex(index);
              setLoading(true);
              getAIResponse({
                variables: {
                  companyId: +user?.company?.id,
                  applicationUseCaseId: +useCases?.getApplicationUseCases?.filter(
                    useCase => useCase.code === "CAMPAIGN"
                  )[0]?.id,
                  campaignInput: buildCampaignInput(),
                },
              });
            }}
            // disabled={disabled}
            type="text"
          >
            <Icon component={EngagedAI} alt="SocialMiningAi" />
          </Button>
        </span>
      </div>
      {index === 0 && (
        <div className="flex flex-col mt-4">
          <Checkbox
            checked={
              campaignData.messages[index].campaignTemplates[
                internalIndex === "all" ? 0 : internalIndex + 1
              ]?.isAfterHour
            }
            onChange={e =>
              setCampaignData(prev => {
                const temp = structuredClone(prev).messages;
                temp[index] = {
                  ...temp[index],
                  isChanged: true,
                };
                temp[index].campaignTemplates[internalIndex === "all" ? 0 : internalIndex + 1] = {
                  ...temp[index].campaignTemplates[internalIndex === "all" ? 0 : internalIndex + 1],
                  isAfterHour: e.target.checked,
                  afterHourTemplateText: "",
                };
                return { ...prev, messages: temp };
              })
            }
          >
            After work hours message
          </Checkbox>
          {campaignData.messages[index].campaignTemplates[
            internalIndex === "all" ? 0 : internalIndex + 1
          ]?.isAfterHour && (
            <>
              <div className="flex flex-col items-start">
                <div className="flex flex-row items-center justify-between w-full">
                  <span>Click the desired snippet to add to the after message text</span>
                  <Button onClick={() => settemplateDrawer({ visible: true })}>
                    Select Template
                  </Button>
                </div>
                <div className="flex flex-row flex-wrap gap-2 my-[15px]">
                  {LABELS.map((item, index) => (
                    <span
                      onClick={() => handleKeyWordAfterTextMessage(item.addKeyword)}
                      key={index}
                      className="cursor-pointer text-sm text-brand-500 font-[400]"
                    >
                      {item.label}
                    </span>
                  ))}
                </div>
              </div>
              <div className="flex flex-col w-full relative">
                <textarea
                  ref={afterMessageTextRef}
                  value={
                    campaignData.messages[index].campaignTemplates[
                      internalIndex === "all" ? 0 : internalIndex + 1
                    ]?.afterHourTemplateText
                  }
                  onChange={e =>
                    setCampaignData(prev => {
                      const temp = structuredClone(prev).messages;
                      temp[index] = {
                        ...temp[index],
                        isChanged: true,
                      };
                      temp[index].campaignTemplates[
                        internalIndex === "all" ? 0 : internalIndex + 1
                      ] = {
                        ...temp[index].campaignTemplates[
                          internalIndex === "all" ? 0 : internalIndex + 1
                        ],
                        afterHourTemplateText: e.target.value,
                      };
                      return { ...prev, messages: temp };
                    })
                  }
                  id="textArea-afterMessage"
                  placeholder="Enter the campaign message"
                  className="w-full min-h-[50px] px-2 py-1 rounded-sm border-[1px] border-solid border-grey-200 focus-visible:border-grey-200"
                />
                <span className="absolute right-2 top-2 flex">
                  <Button
                    key="campaignAfterHourMessageSuggestion"
                    loading={loadingAfterWorkHour}
                    className="text-lg"
                    onClick={() => {
                      setIsAfterHour(true);
                      setMessageIndex(index);
                      setLoadingAfterWorkHour(true);
                      getAIResponse({
                        variables: {
                          companyId: +user?.company?.id,
                          applicationUseCaseId: +useCases?.getApplicationUseCases?.filter(
                            useCase => useCase.code === "CAMPAIGN"
                          )[0]?.id,
                          campaignInput: buildCampaignInput(),
                        },
                      });
                    }}
                    // disabled={disabled}
                    type="text"
                  >
                    <Icon component={EngagedAI} alt="SocialMiningAi" />
                  </Button>
                </span>
              </div>
            </>
          )}
        </div>
      )}
      <div className="text-right w-full mt-2">
        <Button
          type="primary"
          loading={createCampaignSchedulesLoader || updateCampaignSchedulesLoader}
          onClick={() => handleSaveMessage(setactiveKeys, index, () => saveMessage(index))}
        >
          Save message
        </Button>
      </div>
      {templateDrawer.visible && (
        <TemplateDrawer
          openTemplateDrawer={templateDrawer}
          setOpenTemplateDrawer={settemplateDrawer}
          onApply={textMessage => {
            setCampaignData(prev => {
              const temp = structuredClone(prev).messages;
              temp[index] = {
                ...temp[index],
                isChanged: true,
              };
              temp[index].campaignTemplates[internalIndex === "all" ? 0 : internalIndex + 1] = {
                ...temp[index].campaignTemplates[internalIndex === "all" ? 0 : internalIndex + 1],
                afterHourTemplateText: textMessage,
              };
              return { ...prev, messages: temp };
            });
          }}
        />
      )}
    </>
  );
};
