import * as React from "react";

const CRMIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={191.999}
    height={191.999}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 2000 2000"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={199.406}
        x2={1800.59}
        y1={1000}
        y2={1000}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#366",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#033",
          }}
        />
      </linearGradient>
    </defs>
    <path
      d="M1247 1312c-29 0-23-38-23-57-10 25-16 57-38 57-23 0-29-32-39-57 0 19 6 57-23 57-13 0-23-11-23-23v-152c0-25 35-32 44-9l41 99 40-99c9-23 44-16 44 9v152c0 12-10 23-23 23zm-191 0c-7 0-13-3-18-9l-46-59h-36c0 24 8 68-23 68-12 0-23-11-23-23v-152c0-13 11-23 23-23h80c67 0 88 90 31 122 18 23 51 52 26 71-4 3-9 5-14 5zm-53-114c13 0 29-2 29-19 0-11-9-19-19-19h-57v38h47zm589-112h-98l-63 52c9 49 9 100 0 150l165 135c9 7 11 19 6 29l-94 162c-6 10-18 14-28 10l-200-76c-38 33-82 58-129 75l-35 211c-1 11-11 19-22 19H906c-11 0-21-8-22-19l-34-211c-48-17-91-42-130-75l-200 76c-10 4-22 0-28-10l-94-162c-5-10-3-22 6-29l165-135c-8-50-8-101 0-150l-63-52h-98c-115 0-209-93-209-208 0-106 79-193 181-207-43-129 50-268 188-274 1-138 113-250 252-250 103 0 195 63 233 157 112-69 262-16 306 108 165-68 334 93 275 262 96 19 167 103 167 204 0 115-94 208-209 208zm-121-40 81-66-74-129-194 74c-30 11-43-45-161-84-8-3-14-10-16-18l-33-205H926l-33 205c-5 31-60 14-153 97-7 6-16 8-24 5l-194-74-74 129 161 131c17 14-1 41-1 102 0 60 18 87 1 102l-161 131 74 128 194-73c30-12 43 45 161 84 8 2 14 9 16 18l33 205h148l33-205c6-32 61-15 153-98 7-6 16-7 24-4l194 73 74-128-161-131c-17-15 1-42 1-102 0-122-47-64 79-167zm80-6h41c90 0 163-73 163-162 0-87-68-158-155-163-16 0-27-19-19-33 77-144-85-300-225-217-14 7-31-1-34-16-25-122-173-171-266-91-12 11-33 4-37-12-23-90-106-154-199-154-121 0-217 106-206 226 3 26-22 24-36 24-124 0-200 134-144 240 7 13 3 33-26 33-90 0-163 73-163 163 0 89 73 162 163 162h42l-46-37c-9-7-11-20-6-29l94-163c6-10 18-14 28-10l200 76c39-33 82-58 130-75l34-211c1-11 11-19 22-19h188c11 0 21 8 22 19l35 211c47 17 90 42 129 75l200-76c10-4 22 0 28 10l94 163c5 9 3 22-6 29l-45 37zm-551 523c-193 0-350-157-350-350 0-194 157-351 350-351s350 157 350 351c0 193-157 350-350 350zm0-655c-168 0-305 137-305 305s137 304 305 304 305-136 305-304-137-305-305-305zm-192 404c-119 0-116-198 0-198 51 0 105 58 67 78-30 15-30-32-67-32-56 0-58 106 0 106 37 0 37-48 67-32 38 19-16 78-67 78z"
      style={{
        fill: "url(#a)",
      }}
    />
  </svg>
);
export default CRMIcon;
