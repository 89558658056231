/* eslint-disable no-unreachable */
/* eslint-disable react/destructuring-assignment */
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Tag, message } from "antd";
import _, { cloneDeep, isEmpty, uniqBy } from "lodash";
import React, { useEffect } from "react";
import {
  CREATE_CAMPAIGN_SCHEDULES,
  DELETE_CAMPAIGN_SCHEDULE,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SCHEDULES,
} from "../../../graphql/mutation";
import DragIcon from "../../../icons/js/DragIcon";
import { ordinalNumbers } from "../../../library/constants";
import { showConfirmationModal } from "../../../library/helpers";
import { firstUpperCaseWordParser } from "../../../library/utils";
import { Panel } from "./Panel";
import { handleSaveMessage } from "./commonFunctions";

export function ThirdStep({
  campaignData,
  setCampaignData,
  sources,
  activeKeys,
  setactiveKeys,
  campaignByLead,
  setactiveAccordianKeys,
  showBack = true,
}) {
  /**
   *
   * @param {Message} item Message
   * @param {Int} index Index to that message
   * @param {Object} attributes Drag and Drop Attribute
   * @param {Object} listeners Drag and Drop Listener
   * @returns
   */

  return (
    <div className="bg-white p-6 rounded-lg">
      {" "}
      <CustomCollapse
        campaignData={campaignData}
        activeKeys={activeKeys}
        setactiveKeys={setactiveKeys}
        sources={sources}
        setCampaignData={setCampaignData}
        campaignByLead={campaignByLead}
        setactiveAccordianKeys={setactiveAccordianKeys}
        showBack={showBack}
      />
    </div>
  );
}
function CustomCollapse({
  campaignData,
  activeKeys,
  setactiveKeys,
  sources,
  setCampaignData,
  campaignByLead,
  setactiveAccordianKeys,
  showBack,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),

    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [updateCampaign, { loading: sequenceLoader }] = useMutation(UPDATE_CAMPAIGN, {
    fetchPolicy: "network-only",
  });
  async function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id.sortOrder !== over.id.sortOrder) {
      const temp = structuredClone(campaignData);
      const items = temp.messages;
      const oldIndex = items.findIndex(item => item.sortOrder === active.id.sortOrder);
      const newIndex = items.findIndex(item => item.sortOrder === over.id.sortOrder);
      const sortedMessages = arrayMove(items, oldIndex, newIndex);
      const payload = {
        id: campaignData.id,
        name: campaignData.name,
        method: campaignData.method,
        messages: _.cloneDeep(sortedMessages).map((item, index) => {
          delete item.createId;
          delete item.isChanged;
          return { ...item, sortOrder: index };
        }),
      };
      const res = await updateCampaign({
        fetchPolicy: "network-only",
        variables: {
          ...payload,
        },
      });
      if (res.data.updateCampaign.statusCode === 200) {
        setCampaignData(prev => {
          return { ...temp, messages: sortedMessages };
        });
        message.success("Campaign messages sequence changed successfully");
      } else {
        message.error(res.data.updateCampaign.message);
      }
    }
  }
  useEffect(() => {
    if (sequenceLoader) message.loading("Sequencing messages, please wait...");
  }, [sequenceLoader]);
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={event => {
        handleDragEnd(event);
      }}
    >
      <SortableContext items={campaignData.messages} strategy={verticalListSortingStrategy}>
        {campaignData.messages.map((item, index) => (
          <SortableItem
            key={index}
            index={index}
            activeKeys={activeKeys}
            setactiveKeys={setactiveKeys}
            item={item}
            sources={sources}
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            campaignByLead={campaignByLead}
          />
        ))}
      </SortableContext>
      {showBack && (
        <div className="text-right">
          <Button onClick={() => setactiveAccordianKeys(2)}>Go back</Button>
        </div>
      )}
    </DndContext>
  );
}
function SortableItem({
  index,
  activeKeys,
  setactiveKeys,
  item,
  sources,
  campaignData,
  setCampaignData,
  campaignByLead,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const Header = ({ item, index, attributes, listeners }) => {
    const BlueishText = ({ children }) => <span style={{ color: "blue" }}>{children}</span>;
    const highlightTextInBraces = text => {
      const regex = /{([^}]+)}/g;

      return text.split(regex).map((part, index) => {
        // Check if the part is inside curly braces
        if (index % 2 === 1) {
          return <BlueishText key={index}>{`{${part}}`}</BlueishText>;
        }
        return part;
      });
    };
    const sourceIds =
      campaignData?.messages.length > 0 &&
      campaignData?.messages[index]?.campaignTemplates
        ?.map(item => item.sourceId)
        .filter(item => item !== "all");
    const [deleteCampaignSchedule] = useMutation(DELETE_CAMPAIGN_SCHEDULE);
    const filterMessage = () => {
      setactiveKeys(activeKeys.filter(item => item !== index));
      setCampaignData({
        ...campaignData,
        messages: [...campaignData.messages.filter((item, i) => i !== index)],
      });
    };
    const confirmDelete = async () => {
      const res = await deleteCampaignSchedule({
        variables: {
          id: item.scheduleId,
        },
      });
      if (res.data.deleteCampaignSchedule.ok) {
        filterMessage();
      }
    };
    const handleDelete = async e => {
      e.stopPropagation();
      if (item.scheduleId) {
        // delete from the server
        showConfirmationModal(
          "Delete this campaign message?",
          "Are you sure you want to delete this message, this operation cannot be undone",
          confirmDelete
        );
      } else {
        filterMessage();
      }
    };
    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2 items-center">
            {campaignData.messages.length > 1 && (
              <Button type="text" {...attributes} {...listeners}>
                <DragIcon className="cursor-move" />
              </Button>
            )}
            <span>
              <span className="font-[600]">{item.title || `${ordinalNumbers[index]} message`}</span>{" "}
              after
            </span>
            <span className="font-[600]">
              {item?.numericValue || ""} {firstUpperCaseWordParser(item?.temporalValue || "")}
            </span>
            {item.isChanged && <i>Unsaved Changes</i>}
          </div>
          <div className="flex flex-row items-center gap-2">
            <DeleteOutlined
              className="text-red-600"
              onClick={e => {
                handleDelete(e);
              }}
            />
            <DownOutlined
              className={`${
                activeKeys.includes(index) ? "" : "-rotate-90"
              } cursor-pointer transition duration-300 `}
            />
          </div>
        </div>
        {!activeKeys.includes(index) &&
          !isEmpty(
            campaignData?.messages[index]?.campaignTemplates?.find(item => item?.sourceId === "all")
              ?.templateText
          ) && (
            <div className="bg-white p-2 flex flex-col items-start gap-2 rounded-sm">
              <span className="text-sm font-[400] ">
                {highlightTextInBraces(
                  campaignData?.messages[index]?.campaignTemplates?.find(
                    item => item?.sourceId === "all"
                  )?.templateText
                )}
              </span>
              <div className="flex flex-row">
                <Tag className="rounded-sm px-2 py-1 bg-brand-100 text-brand-500" key={0}>
                  All Sources
                </Tag>
                {uniqBy(sources, "id")
                  .filter(item => sourceIds.includes(item.id))
                  .map((item, index) => (
                    <Tag
                      className="rounded-sm px-2 py-1 bg-brand-100 text-brand-500"
                      key={index + 1}
                    >
                      {item.name}
                    </Tag>
                  ))}
              </div>
              {/* <div className="text-right w-full">
                <Button
                  type="primary"
                  loading={createCampaignSchedulesLoader || updateCampaignSchedulesLoader}
                  onClick={e => {
                    e.stopPropagation();
                    handleSaveMessage(setactiveKeys, index, () => saveMessage(index));
                  }}
                >
                  Save message
                </Button>
              </div> */}
            </div>
          )}
      </div>
    );
  };
  return (
    <div
      className="bg-[#F8FAFC] rounded-[4px] border-[1px] border-solid border-brand-500 mb-2 p-3 transition-all duration-500 ease-in-out"
      ref={setNodeRef}
      style={style}
    >
      <span
        onClick={() => {
          if (activeKeys.includes(index)) {
            // remove the key
            setactiveKeys(prev => prev.filter(key => key !== index));
          } else {
            // add the key
            setactiveKeys(prev => [...prev, index]);
          }
        }}
        className="mb-2 cursor-pointer"
      >
        {/* {header(item, index, attributes, listeners)} */}
        <Header item={item} index={index} attributes={attributes} listeners={listeners} />
      </span>
      {activeKeys.includes(index) && (
        <Panel
          key={index}
          item={item}
          index={index}
          sources={sources}
          activeKeys={activeKeys}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          setactiveKeys={setactiveKeys}
          campaignByLead={campaignByLead}
        />
      )}
    </div>
  );
}
