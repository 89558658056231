import React from "react";
import styled from "styled-components";
import { Button, Drawer, Typography } from "antd";
import CampaignDetails from "../../campaigns/campaignDetails";

const DrawerBody = styled.div`
  & .ant-list-item {
    &:last-child {
      border-bottom: 1px solid #f1ecec;
    }
  }
`;

const DrawerFooter = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  border-top: 1px solid rgb(232, 232, 232);
`;

const LeadCampaignViewModal = props => {
  const { visible, onCancel, campaign, handleLeadDetailProfile } = props;
  return (
    <Drawer
      title={
        <Typography variant="h4" weight="medium">
          {campaign?.name}
        </Typography>
      }
      placement="right"
      closable={false}
      open={visible}
      width="95%"
      onClose={() => onCancel()}
    >
      <div className="bg-[#f1f5f9] pl-2">
        <CampaignDetails
          handleLeadDetailProfile={handleLeadDetailProfile}
          campaign={campaign}
          onCancel={onCancel}
          onBackClick={onCancel}
          showAllCampaign={false}
        />
      </div>
      <DrawerFooter>
        <Button style={{ marginRight: 20 }} onClick={() => onCancel()}>
          Close
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
export default LeadCampaignViewModal;
