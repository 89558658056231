import { Badge, Progress, Spin, Switch, Tooltip } from "antd";
import React from "react";
import { openDialog } from "../../../library/helpers";
import { convertUtcToLocal } from "../../../library/utils";
import AntdIconV4 from "../../../utils/antdIcon";
import CustomLabel from "../../commonComponents/label";
import {
  ConsentColor,
  ConsentIconType,
  ConsentStatus,
  ConsentStatusColor,
} from "./engagementHelpers";
import useLang from "../../../hooks/useLang";

const LeadConsent = props => {
  const {
    concentLoader,
    optedOutLoader,
    handleOptedOut,
    selectedLead,
    disableConcentClick,
    handleSendConsentClick,
    user,
    updateStatusLoading,
    handleActiveToggle,
  } = props;

  const [lang] = useLang();

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <Spin spinning={concentLoader} />
      {selectedLead?.textConsentStatus === "ACCEPTED" && (
        <CustomLabel
          label={`${lang.consentExpiresIn} ${selectedLead?.consentExpireDay} ${lang.days}`}
          labelClass="font-medium"
        />
      )}
      <div
        onClick={e => {
          if (["DECLINED", "ACCEPTED"].includes(selectedLead?.textConsentStatus)) {
            return;
          }
          selectedLead &&
            disableConcentClick &&
            handleSendConsentClick(e, selectedLead?.textConsentStatus);
        }}
        disabled={["DECLINED", "ACCEPTED"].includes(selectedLead?.textConsentStatus)}
      >
        <Progress
          type="circle"
          disabled
          percent={100}
          strokeColor={ConsentStatusColor(
            selectedLead?.textConsentStatus,
            user?.company?.isOptinConsentMethod
          )}
          format={() => (
            <>
              <AntdIconV4
                style={{
                  fontSize: "20px",
                  marginBottom: 10,
                  color: ConsentColor(
                    selectedLead?.textConsentStatus,
                    user?.company?.isOptinConsentMethod
                  ),
                }}
                type={ConsentIconType(
                  selectedLead?.textConsentStatus,
                  user?.company?.isOptinConsentMethod
                )}
              />
              <p
                className="text-[12px] font-medium"
                style={{
                  color: ConsentColor(
                    selectedLead?.textConsentStatus,
                    user?.company?.isOptinConsentMethod
                  ),
                }}
              >{` ${ConsentStatus(
                selectedLead?.textConsentStatus,
                user?.company?.isOptinConsentMethod
              )}`}</p>
            </>
          )}
        />
      </div>
      <div className="my-[5px] mx-0">
        <span>{lang.textStatus}: </span>
        <span>
          <Switch
            style={{
              background:
                ConsentStatus(
                  selectedLead?.textConsentStatus,
                  user?.company?.isOptinConsentMethod
                ) === "Opted-In" ||
                ConsentStatus(
                  selectedLead?.textConsentStatus,
                  user?.company?.isOptinConsentMethod
                ) === "Implied Consent"
                  ? "#52c41a"
                  : ConsentStatus(
                      selectedLead?.textConsentStatus,
                      user?.company?.isOptinConsentMethod
                    ) === "Pending Consent"
                  ? "#ffbf00"
                  : ConsentStatus(
                      selectedLead?.textConsentStatus,
                      user?.company?.isOptinConsentMethod
                    ) === "Opted-Out"
                  ? "#fc5a5a"
                  : "#eeeef1",
            }}
            disabled={
              ConsentStatus(
                selectedLead?.textConsentStatus,
                user?.company?.isOptinConsentMethod
              ) === "Opted-Out" ||
              ConsentStatus(
                selectedLead?.textConsentStatus,
                user?.company?.isOptinConsentMethod
              ) === "Send Consent"
            }
            checked={
              ConsentStatus(
                selectedLead?.textConsentStatus,
                user?.company?.isOptinConsentMethod
              ) === "Opted-In" ||
              ConsentStatus(
                selectedLead?.textConsentStatus,
                user?.company?.isOptinConsentMethod
              ) === "Implied Consent" ||
              ConsentStatus(
                selectedLead?.textConsentStatus,
                user?.company?.isOptinConsentMethod
              ) === "Pending Consent"
            }
            onChange={() => {
              openDialog("confirmDialog", {
                label: lang.warning,
                content: lang.consentChangeWarning,
                okBtnType: "danger",
                confirmBtnText: lang.yes,
                isOKGhost: true,
                handleConfirm: () => handleOptedOut(),
                optedOutLoader: optedOutLoader,
              });
            }}
            size="small"
          />
        </span>
      </div>
      {selectedLead?.textConsentDate && (
        <CustomLabel
          label={
            selectedLead?.textConsentStatus === "ACCEPTED"
              ? `${lang.consent} ${lang.optedIn} ${lang.on} ${convertUtcToLocal(
                  selectedLead?.textConsentDate,
                  "MM/DD/YYYY hh:mm A"
                )}`
              : selectedLead?.textConsentStatus === "DECLINED"
              ? `${lang.consent} ${lang.optedOut} ${lang.on} ${convertUtcToLocal(
                  selectedLead?.textConsentDate,
                  "MM/DD/YYYY hh:mm A"
                )}`
              : selectedLead?.textConsentStatus === "PENDING"
              ? `${lang.consent} ${lang.sent.toLowerCase()} ${lang.on} ${convertUtcToLocal(
                  selectedLead?.textConsentDate,
                  "MM/DD/YYYY hh:mm A"
                )}`
              : ""
          }
          labelClass="font-medium"
        />
      )}
      <p className="text-left">
        <Badge className="mx-2" color="#52c41a" text={lang.optedInLabel} />
        <Badge className="mx-2" color="#ffbf00" text={lang.pending} />
        <Badge className="mx-2" color="#FC5A5A" text={lang.optedOutLabel} />
        <Badge className="mx-2" color="#EEEEF1" text={lang.noConsent} />
      </p>
      <div className="flex items-center gap-1">
        <Tooltip title={lang.ottoBotSwitch}>
          <div>
            <AntdIconV4 type="InfoCircleOutlined" className="mr-[5px]" />
          </div>
        </Tooltip>
        <CustomLabel label={`${lang.ottoBot}:`} className="font-medium" />
        <Switch
          checked={!selectedLead?.conversationStatus?.disableConversation}
          onChange={e => handleActiveToggle(e)}
          loading={updateStatusLoading}
          size="small"
        />
      </div>
      <CustomLabel
        label={`${
          !selectedLead?.conversationStatus?.disableConversation ? lang.enabled : lang.disabled
        } ${lang.by} ${user?.fullName || ""} on ${convertUtcToLocal(
          selectedLead?.conversationStatus?.createdOn,
          "MMMM Do YYYY"
        )}`}
        className="font-medium"
      />
    </div>
  );
};

export default LeadConsent;
