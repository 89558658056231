import * as React from "react";

const TemplateIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M16.25 1.875H5.625a2.5 2.5 0 0 0-2.5 2.5V17.5a.625.625 0 0 0 .625.625H15a.624.624 0 1 0 0-1.25H4.375a1.25 1.25 0 0 1 1.25-1.25H16.25a.624.624 0 0 0 .625-.625V2.5a.625.625 0 0 0-.625-.625Zm-6.875 1.25h3.75V8.75l-1.5-1.125a.625.625 0 0 0-.75 0l-1.5 1.125V3.125Zm6.25 11.25h-10c-.439 0-.87.115-1.25.335V4.375a1.25 1.25 0 0 1 1.25-1.25h2.5V10a.625.625 0 0 0 1 .5l2.125-1.594 2.126 1.594a.625.625 0 0 0 .999-.5V3.125h1.25v11.25Z"
    />
  </svg>
);
export default TemplateIcon;
