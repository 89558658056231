import React from "react";
import { Radio, Tooltip } from "antd";

const RadioBtnGroups = props => {
  const {
    radioListTitle,
    activeSort,
    handleSortingFunction = () => {},
    clearFilters,
    radioGrp = [],
    isOwnerRadio = false,
    flexDireaction,
    close,
  } = props;

  const onChange = e => {
    handleSortingFunction(e.target.value);
    !isOwnerRadio && clearFilters();
    close && close();
  };

  return (
    <div className="py-[10px]">
      <div className="text-[15px] font-medium">{radioListTitle}</div>
      <Radio.Group
        className={`flex ${flexDireaction || "flex-col"} text-[12px]`}
        onChange={onChange}
        value={activeSort}
      >
        {radioGrp.length > 0 &&
          radioGrp.map((radioLbl, index) => (
            <Tooltip placement="topLeft" key={index} title={radioLbl?.tooltipText || ""}>
              <Radio className="py-[2px] text-[14px]" value={radioLbl.value}>
                {radioLbl.label}
              </Radio>
            </Tooltip>
          ))}
      </Radio.Group>
    </div>
  );
};

export default RadioBtnGroups;
