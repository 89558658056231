import { Skeleton } from "antd";
import React from "react";
import VehicleIcon from "../../../icons/VehicleIcon";
import { CrashAlert } from "../../commonComponents/crashAlert";

export function SecondRow({ messageStats, getCampaignByIdLoader }) {
  try {
    return (
      <div className="flex flex-row items-stretch my-2 gap-2">
        <div className="gap-2 flex flex-row items-center  flex-[60%] ">
          {messageStats.map((item, index) =>
            getCampaignByIdLoader ? (
              <Skeleton key={index} active paragraph={{ rows: 1 }} avatar />
            ) : (
              <div
                key={index}
                className="p-3 flex-1 h-full gap-5 flex flex-row items-center rounded-lg bg-white"
              >
                {item.icon}
                <div className="flex flex-col items-start justify-start gap-1 ">
                  <span className="text-sm font-[600] text-grey-500">{item.label}</span>
                  <span className="text-2xl font-[600] text-grey-700">{item.value}</span>
                </div>
              </div>
            )
          )}
        </div>
        <div className="flex p-3 flex-[40%] bg-[#DDD6FE] rounded-lg">
          <div className="flex flex-row justify-evenly w-full items-center gap-2">
            <div className="flex flex-col justify-start gap-2 ">
              <span className="text-xl font-[600] text-[#4C1D95]">
                Summary about Sell and Revenue
              </span>
              <div className="flex flex-row items-center ">
                <div className="flex flex-col gap-1 justify-start">
                  <span className="text-sm font-[500] text-grey-500">No. of Car Sold</span>
                  <span className="text-2xl text-grey-700 font-[600]">Coming soon!</span>
                </div>
                <div className="h-[56px] mx-[42px] w-0.5 bg-white" />
                <div className="flex flex-col gap-1 justify-start">
                  <span className="text-sm font-[500] text-grey-500">Revenue Generated</span>
                  <span className="text-2xl text-grey-700 font-[600]">Coming soon!</span>
                </div>
              </div>
            </div>
            <VehicleIcon className="w-[220px] h-[115px]" />
          </div>
        </div>
      </div>
    );
  } catch (err) {
    console.log(err);
    return <CrashAlert />;
  }
}
