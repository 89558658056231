import * as React from "react";

const LeftIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#3B82F6"
      d="m1.433 10.443 7.5 7.5A.625.625 0 0 0 10 17.5v-3.124h1.875a.625.625 0 0 0 .625-.625v-7.5a.625.625 0 0 0-.625-.626H10V2.5a.625.625 0 0 0-1.067-.442l-7.5 7.5a.625.625 0 0 0 0 .885ZM8.75 4.009V6.25a.625.625 0 0 0 .625.625h1.875v6.25H9.375a.625.625 0 0 0-.625.626v2.24l-5.991-5.99L8.75 4.009Zm7.5 9.742v-7.5a.625.625 0 1 1 1.25 0v7.5a.625.625 0 1 1-1.25 0Zm-2.5 0v-7.5a.625.625 0 1 1 1.25 0v7.5a.625.625 0 1 1-1.25 0Z"
    />
  </svg>
);
export default LeftIcon;
