/* eslint-disable no-unreachable */
import React from "react";

/**
 *
 * @param {Function} setactiveKeys SetState function
 * @param {Int} index Panel index
 * @param {Function} handler create/update handler, with complete payload
 */
export const handleSaveMessage = async (setactiveKeys, index, handler) => {
  const res = await handler();
  if (res) {
    setactiveKeys(prev => prev.filter(item => item !== index));
  }
};
