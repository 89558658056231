export const getLeadOpportunitySource = opportunitySource => {
  let leadSource = `${opportunitySource?.name || "N/A"}`;
  if (opportunitySource?.parentSource && opportunitySource?.parentSource?.name !== "") {
    leadSource = `${opportunitySource?.parentSource?.name || ""} / ${leadSource}`;
  }
  if (
    opportunitySource?.parentSource?.parentSource &&
    opportunitySource?.parentSource?.parentSource?.name !== ""
  ) {
    leadSource = `${opportunitySource?.parentSource?.parentSource?.name || ""} / ${leadSource}`;
  }
  return (leadSource || "").replace(/_/g, " ");
};
