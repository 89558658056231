/* eslint-disable react/no-danger */
/* eslint-disable no-unneeded-ternary */

import { Select, Tag, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UserContext } from "../../../amplify/authenticator-provider";
import useLang from "../../../hooks/useLang";
import { firstUpperCaseWordParser, parseLocation } from "../../../library/utils";
import AntdIconV4 from "../../../utils/antdIcon";
import EditablePhoneInput from "./editablePhoneInput";
import EditableTextBox from "./editableTextBox";
import LeadSummaryOpenAi from "./leadSummaryOpenAi";
import { toTitleCase } from "../../../utils";
import { UPDATE_LEAD_PREFFREDLANGUAGE } from "../../../graphql/mutation";

const LeadProfileDetails = props => {
  const {
    showDetails,
    inlineField,
    selectedLead,
    updateLoading,
    canEdit,
    connection,
    handleUpdate,
    setInlineEditField,
    crmIntegration,
    handlePotentialLeadPopup,
    setLeadList,
    leadList,
    setSelectedLead,
    getMessages,
    languages,
  } = props;

  const [lang] = useLang();
  const { user } = useContext(UserContext);
  const [phones, setphones] = useState([]);
  const [updateLeadPreferredLanguage] = useMutation(UPDATE_LEAD_PREFFREDLANGUAGE);
  const findPhone = key => {
    const obj = (phones || [])?.find(item => item?.phoneType?.toLowerCase() === key?.toLowerCase());
    return obj;
  };
  useEffect(() => {
    setphones(
      selectedLead?.phoneNumbers?.map(item => ({
        ...item,
        phoneType: toTitleCase(item.phoneType || item.lookupType),
      })) || []
    );
  }, [selectedLead]);
  const phoneArr = ["Mobile", "Work", crmIntegration === "DS" ? "Other" : "Home"];

  return (
    <div>
      <div className="w-full flex flex-col mb-2">
        <strong className="text-[#5e5e5e]">{lang.name}</strong>
        <EditableTextBox
          ckey="fullName"
          value={selectedLead?.fullName || ""}
          placeholder="Enter name "
          onSave={val => handleUpdate({ key: "fullName", value: val })}
          disabled={!canEdit}
        />
      </div>
      {connection?.connected && (
        <Tooltip title="Connected">
          <span className="h-[10px] w-[10px] bg-[#0080009e] rounded block" />
        </Tooltip>
      )}
      {showDetails && (
        <div className="items-center text-[#5e5e5e] leading-6">
          <div className="w-full flex flex-col mb-2">
            <strong>{lang.emailLabel}</strong>
            <EditableTextBox
              ckey="email"
              value={selectedLead?.emails && selectedLead?.emails[0]?.email}
              placeholder={lang.enterEmail}
              onSave={val => handleUpdate({ key: "email", value: val })}
              isEdit={inlineField === "email"}
              onCancel={() => setInlineEditField("")}
              onSetEditable={() => setInlineEditField("email")}
              loading={updateLoading}
              disabled={!canEdit}
            />
          </div>
          <strong>Phone Numbers:</strong>
          <div className="flex flex-col items-start">
            {phoneArr.map((item, index) => (
              <div key={index} className="flex flex-row w-full items-center justify-between gap-2">
                <span>{firstUpperCaseWordParser(item)}</span>
                <EditablePhoneInput
                  key={`mobilePhone-${item}`}
                  value={findPhone(item)?.phone || "N/A"}
                  className="w-3/4"
                  onSave={val =>
                    handleUpdate({
                      key: "phone",
                      value: val,
                      id: findPhone(item)?.id,
                      phoneType: item,
                    })
                  }
                  disabled={!canEdit}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col py-1 mb-2">
            <strong>{lang.address}</strong>
            <div className="text-left">{parseLocation(selectedLead)}</div>
          </div>
          <div className="flex flex-col py-1 mb-2">
            <strong>{lang.preferredlang}</strong>
            <div className="text-left">
              <Select
                style={{ minWidth: 190 }}
                onChange={value => {
                  updateLeadPreferredLanguage({
                    variables: {
                      leadId: selectedLead?.id,
                      preferredLanguageCode: value,
                    },
                    onCompleted: res => {
                      if (res?.updateLeadPreferredLanguage?.statusCode === 200) {
                        getMessages();
                        const arrayFn1 = () => {
                          const clone = structuredClone(selectedLead);
                          clone.preferredLanguageCode =
                            res?.updateLeadPreferredLanguage?.preferredLanguageCode;
                          return clone;
                        };
                        setSelectedLead(arrayFn1());
                        const arrayFn = () => {
                          const clone = structuredClone(leadList);
                          const newLeadList = clone?.map(item => {
                            if (+selectedLead?.id === +item?.id) {
                              return {
                                ...item,
                                preferredLanguageCode:
                                  res?.updateLeadPreferredLanguage?.preferredLanguageCode,
                              };
                            }
                            return item;
                          });
                          return newLeadList;
                        };
                        setLeadList(arrayFn());
                      }
                    },
                  });
                }}
                value={selectedLead?.preferredLanguageCode}
                placeholder={lang.preferredlang}
              >
                {(languages || []).map(item => (
                  <Select.Option key={item?.id} value={item?.languageCode}>
                    {item.languageName}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="flex py-1 items-center mb-2">
            <LeadSummaryOpenAi key={selectedLead?.id} selectedLead={selectedLead} user={user} />
          </div>
          {crmIntegration === "SF" &&
            selectedLead?.phoneNumbers?.length > 0 &&
            selectedLead?.phoneNumbers[0]?.phone &&
            (selectedLead?.phoneNumbers[0]?.potentialLeads?.length === 1
              ? !selectedLead?.phoneNumbers[0]?.potentialLeads?.includes(+selectedLead?.id)
              : selectedLead?.phoneNumbers[0]?.potentialLeads?.length
              ? true
              : false) && (
              <div
                onClick={() => handlePotentialLeadPopup()}
                className="primaryColorTheme py-1 decoration-solid cursor-pointer hover:underline"
              >
                {lang.potentialDuplicateLeads}
                <span>
                  <AntdIconV4
                    type="UserAddOutlined"
                    className="ml-[7px] primaryColorTheme text-[18px]"
                  />
                </span>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default LeadProfileDetails;
