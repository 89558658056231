import React from "react";
import { Spin } from "antd";
import PhoneInput from "react-phone-number-input";
import CustomButton from "../commonComponents/button";
import { LoginContainter, PhoneDiv, TitleStyle } from "./layoutHelpers";
import Logo from "../../../static/Logo.svg";
import useLang from "../../hooks/useLang";
import { Logo2_PNG, BASE_URL_FOR_STATIC_ICONS_AND_IMAGES } from "../../config";

const UpdatePhone = props => {
  const { phoneSpinner, handleSavePhone, loading, mobileaddModal, setmobileaddModal, skip } = props;
  const [lang] = useLang();

  return (
    <LoginContainter>
      <Spin
        spinning={phoneSpinner}
        className="fixed top-[50%] left-[50%]"
        style={{
          transform: "translateX(-50%) translateY(-50%)",
        }}
      />
      <div className="form-phone">
        <Logo
          // src="images/Logo2.png"
          src={BASE_URL_FOR_STATIC_ICONS_AND_IMAGES + Logo2_PNG}
        />
        <TitleStyle>{lang.addYourPhone}</TitleStyle>
        <PhoneDiv>
          <PhoneInput
            className="pt-[10px] pr-[65px] pb-[15px]"
            international
            withCountryCallingCode
            countryCallingCodeEditable={false}
            defaultCountry="US"
            placeholder={lang.enterPhoneNumber}
            value={mobileaddModal?.data?.phone}
            onChange={e => {
              setmobileaddModal(prev => {
                prev.data.phone = e;
                return { ...prev };
              });
            }}
          />
        </PhoneDiv>
        <p className="mt-[30px] text-[12px] py-[5px] px-[10px]">{lang.phoneNotified}</p>
        <div className="flex mt-[50px] justify-center">
          {" "}
          <CustomButton
            handleClick={() => handleSavePhone()}
            loading={loading}
            type="primary"
            className="mr-[20px]"
            btnText={lang.addPhoneBot}
          />
          <br />
          <br />
          <CustomButton btnText={lang.skip} handleClick={() => skip()} type="text" />
        </div>
      </div>
      <style jsx>{`
        .form-phone {
          margin: 0 auto;
          width: 500px;
          height: 100%;
          border: 1px solid #fff;
          background: #fff;
          padding: 2em;
          text-align: center;
          @media (max-width: 678px) {
            width: 100%;
            text-align: center;
          }
        }
      `}</style>
    </LoginContainter>
  );
};

export default UpdatePhone;
