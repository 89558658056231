import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./Toast.css";

const Toast = props => {
  const {
    toastList,
    autoDelete,
    dismissTime,
    isFromLayout = false,
    onClickofToast = () => {},
  } = props;
  const [list, setList] = useState(toastList);

  const deleteToast = id => {
    const listItemIndex = list.findIndex(e => e.id === id);
    const toastListItem = toastList.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };

  useEffect(() => {
    setList([...toastList]);

    // eslint-disable-next-line
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, 8 * 1000);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [toastList, autoDelete, dismissTime, list]);

  const handleLeadMsgClick = toast => {
    if (isFromLayout) {
      onClickofToast(toast?.lead_id || "");
      deleteToast(toast.id);
    }
  };

  return (
    <div className="notification-container top-right">
      {list.map((toast, i) => (
        <div
          key={i}
          className="notification toast top-right"
          style={{ backgroundColor: toast.backgroundColor }}
          onClick={() => handleLeadMsgClick(toast)}
        >
          <button
            onClick={e => {
              deleteToast(toast.id);
              e.stopPropagation();
            }}
            type="button"
          >
            X
          </button>
          <div className="notification-image">
            <img src={toast.icon} alt="" />
          </div>
          <div>
            <p className="notification-title">{toast.title}</p>
            <p className="notification-message">{toast.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

Toast.propTypes = {
  toastList: PropTypes.instanceOf(Array),
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

Toast.defaultProps = {
  autoDelete: true,
  dismissTime: 5,
  toastList: [],
};

export default Toast;
