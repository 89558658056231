/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  applicationUseCases: [],
  applicationLevels: [],
};

const aiSlice = createSlice({
  name: "aiSlice",
  initialState,
  reducers: {
    setAiApplicationUseCases(state, action) {
      state.applicationUseCases = action.payload;
    },
    setApplicationLevels(state, action) {
      state.applicationLevels = action.payload;
    },
  },
});

export const { setAiApplicationUseCases, setApplicationLevels } = aiSlice.actions;

export default aiSlice.reducer;
