/* eslint-disable react/destructuring-assignment */
import Icon, { FilePdfOutlined, PhoneOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { Alert, Button, Popover, message } from "antd";
import React from "react";
import EngagedAI from "../../../../static/EngagedAi_trans_bg.svg";
import { GET_INITIAL_REVIEW_MESSAGE, GET_ENGAGEMENT_TEMPLATE_TEXT } from "../../../graphql/query";
import AppointmentIcon from "../../../icons/js/AppointmentIcon";
import AttachmentIcon from "../../../icons/js/AttachmentIcon";
import CalendarIcon from "../../../icons/js/CalendarIcon";
import CarIcon from "../../../icons/js/CarIcon";
import GIFIcon from "../../../icons/js/GIFIcon";
import PhoneCircleIcon from "../../../icons/js/PhoneCircleIcon";
import ReviewTemplateIcon from "../../../icons/js/ReviewTemplateIcon";
import TemplateIcon from "../../../icons/js/TemplateIcon";
import { checkAccessPermission, openDialog } from "../../../library/helpers";
import { handleMediaEvent } from "./engagementActivity";
import GifPickerComponent from "./mediaSharing/gifPicker";
import MmsMediaButtons from "./mmsMediaButtons";
import { AccessPermissionAction } from "../../../library/constants";

export function BottomActions({
  attachments,
  handleMediaSharing,
  mediaRef,
  videoRef,
  documentRef,
  setAttachments,
  text,
  textDisabled,
  selectedLead,
  user,
  setTemplateModal,
  handleSendReviewClick,
  handleAddAppointment,
  showReviewRequestButton = true,
  setmessageText,
  setisReview,
  activeOppName,
  opportunityList,
  crmIntegrationType,
}) {
  const [getMessageText] = useLazyQuery(GET_ENGAGEMENT_TEMPLATE_TEXT, {
    fetchPolicy: "cache-first",
  });
  const canShareVDP = checkAccessPermission(AccessPermissionAction.VDP_FEATURE_VIEW);
  const handleSendAppointmentLink = async () => {
    const res = await getMessageText({
      variables: {
        leadId: selectedLead.id,
        content: `${text.current.value} {createAppointmentUrl}`,
      },
    });
    if (res.data.getEngagementTemplateText.statusCode === 200) {
      setmessageText(res.data.getEngagementTemplateText.content);
      text.current.value = res.data.getEngagementTemplateText.content;
    } else {
      message.error(res.data.getEngagementTemplateText.message);
    }
  };
  const Attachments = () => {
    return (
      <div className="flex flex-col justify-center  gap-5">
        {!attachments.some(file => file?.file?.type?.includes("gif") || false) && (
          <MmsMediaButtons
            handleMediaSharing={handleMediaSharing}
            mediaRef={mediaRef}
            videoRef={videoRef}
            documentRef={documentRef}
            handleMediaEvent={e => handleMediaEvent(e, attachments, setAttachments)}
          />
        )}{" "}
        {attachments.length === 0 && (
          <div key="gifPicker" className="icon-wrapper">
            <Popover
              showArrow={false}
              content={
                <GifPickerComponent
                  attachments={attachments}
                  setAttachments={setAttachments}
                  selectedLead={selectedLead}
                  user={user}
                />
              }
              trigger="click"
              destroyTooltipOnHide
            >
              <span className="flex items-center justify-start cursor-pointer">
                <GIFIcon className="text-[20px] mr-1.5" />
                Select GIF
              </span>
            </Popover>
          </div>
        )}{" "}
      </div>
    );
  };
  const [getReviewMessage] = useLazyQuery(GET_INITIAL_REVIEW_MESSAGE, {
    fetchPolicy: "network-only",
  });
  const handleReviewInvite = async () => {
    const res = await getReviewMessage({
      variables: {
        leadId: selectedLead.id,
        leadOpportunityId: +activeOppName?.id,
      },
    });
    if (res?.data?.getInitialReviewMessage?.statusCode === 200) {
      text.current.value = res?.data?.getInitialReviewMessage?.content;
      setmessageText(res?.data?.getInitialReviewMessage?.content);
      setisReview(true);
    } else {
      message.error("Failed to generate review message. Please contact support.");
    }
  };
  console.log("crmIntegrationType", crmIntegrationType);
  const OttoLinks = () => {
    return (
      <div className="flex flex-col justify-center gap-5">
        {crmIntegrationType === "EL" && (
          <span
            onClick={() => {
              openDialog("documentSelector", {
                width: "620px",
                dialogHeader: "Print Dealer Documents",
                leadId: selectedLead?.id,
                activeOppName: activeOppName,
                opportunityList: opportunityList,
              });
            }}
            className="flex items-center justify-start cursor-pointer"
          >
            <FilePdfOutlined className="text-[20px] mr-1.5" />
            Print Dealer Document
          </span>
        )}

        <span
          onClick={() => handleSendAppointmentLink()}
          className="flex items-center justify-start cursor-pointer"
        >
          <CalendarIcon className="text-[20px] mr-1.5" />
          Send Appointment Link
        </span>
        {showReviewRequestButton && (
          <span
            onClick={() => handleReviewInvite()}
            className="flex items-center justify-start cursor-pointer"
          >
            <ReviewTemplateIcon className="text-[20px] mr-1.5" />
            Review Invite
          </span>
        )}
        <span
          onClick={() => setTemplateModal({ visible: true })}
          className="flex items-center justify-start cursor-pointer"
        >
          <TemplateIcon className="text-[20px] mr-1.5" />
          Select Template
        </span>
        {canShareVDP && (
          <span
            onClick={() => {
              openDialog("vdpSelect", {
                width: "620px",
                dialogHeader: "Select Vehicle From Inventory",
                text,
                setmessageText,
              });
            }}
            className="flex items-center justify-start cursor-pointer"
          >
            <CarIcon className="text-[20px] mr-1.5" />
            VDP
          </span>
        )}
      </div>
    );
  };
  const CallLinks = () => {
    return (
      <div className="flex flex-col justify-center gap-5">
        <span
          // onClick={() => handleSendReviewClick()}
          className="flex items-center justify-start cursor-pointer"
        >
          <PhoneOutlined className="text-[20px] rotate-90 mr-1.5" />
          Voice Call
        </span>
        <span
          // onClick={() => setTemplateModal(true)}
          className="flex items-center justify-start cursor-pointer"
        >
          <VideoCameraOutlined className="text-[20px] mr-1.5" />
          Video Call
        </span>
      </div>
    );
  };
  const ErrorPopover = (
    <Alert type="error" showIcon message="Lead has opted out, you cannot message this lead now!" />
  );
  return (
    <div className="flex pt-2 justify-between w-full pb-2">
      <div className="flex sm:flex-row flex-col gap-1">
        {/* <Button
              shape="circle"
              className="text-[18px] mb-[-5px]"
              icon={hideToolbar ? <UpCircleOutlined /> : <DownCircleOutlined />}
              onClick={() => sethideToolbar(!hideToolbar)}
            /> */}
        {!textDisabled() && (
          <>
            <Popover
              content={textDisabled() ? ErrorPopover : Attachments}
              overlayClassName="w-[200px]"
              // open={attachmentVisible}
              trigger={["click", "hover"]}
              showArrow={false}
            >
              <Button
                // onClick={() => setattachmentVisible(!attachmentVisible)}
                type="text"
                className="flex items-center"
              >
                <AttachmentIcon className=" mr-2" />
                Attachment
              </Button>
            </Popover>
            <Popover
              content={textDisabled() ? ErrorPopover : OttoLinks}
              overlayClassName="w-[200px]"
              // open={attachmentVisible}
              trigger={["click", "hover"]}
              showArrow={false}
            >
              <Button
                // onClick={() => setattachmentVisible(!attachmentVisible)}
                type="text"
                className="flex items-center"
              >
                <Icon className="text-sm" component={EngagedAI} alt="SocialMiningAi" />
                Otto Links
              </Button>
            </Popover>

            {/* TODO: Hide it as not working yet 
            <Popover
              content={textDisabled() ? ErrorPopover : CallLinks}
              overlayClassName="w-[200px]"
              // open={attachmentVisible}
              trigger={["click", "hover"]}
              showArrow={false}
            >
              <Button
                // onClick={() => setattachmentVisible(!attachmentVisible)}
                type="text"
                className="flex items-center"
              >
                <PhoneCircleIcon className=" mr-2" />
                Calls
              </Button>
            </Popover> */}
          </>
        )}
      </div>
      <div className="flex sm:flex-row flex-col">
        <Button
          type="text"
          disabled={textDisabled()}
          onClick={e => handleAddAppointment(e)}
          className="mr-2 flex items-center"
        >
          <AppointmentIcon className="text-sm mr-1" /> Add Appointment
        </Button>
      </div>
    </div>
  );
}
