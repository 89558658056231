import { Radio } from "antd";
import React from "react";
import { firstUpperCaseWordParser } from "../../../library/utils";
import { setSelectedPresetDate } from "../../../redux/slices/campaignSlice";
import { CrashAlert } from "../../commonComponents/crashAlert";
import { CampaignOptions } from "./CampaignItem";
import { StatusTag } from "./listView";

export function FirstRow({
  isAllCampaign,
  selectedCampaign,
  handleCampaignMenuClick,
  selectedPresetDate,
  dispatch,
}) {
  try {
    return (
      <div className="py-3 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-between w-1/3">
          <span className="text-base font-[600] text-grey-700">
            {isAllCampaign ? "All Campaign" : selectedCampaign.name}
          </span>
          {!isAllCampaign && (
            <div className="flex flex-row items-center gap-1">
              <StatusTag item={selectedCampaign} />
              <CampaignOptions
                item={selectedCampaign}
                handleCampaignMenuClick={handleCampaignMenuClick}
              />
            </div>
          )}
        </div>
        <div className="flex flex-row items-center gap-2">
          <Radio.Group
            value={selectedPresetDate}
            onChange={e => dispatch(setSelectedPresetDate(e.target.value))}
            className="flex flex-row justify-center gap-2 "
          >
            {["week", "month", "year"].map((item, index) => (
              <Radio.Button className="rounded-sm" key={index} value={item}>
                {firstUpperCaseWordParser(item)}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      </div>
    );
  } catch (err) {
    console.error(err);
    return <CrashAlert />;
  }
}
