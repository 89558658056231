/* eslint-disable no-unused-vars */
import { Avatar, Button, message, Select, Upload } from "antd";
import React, { useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { DEFAULT_COUNTRY } from "../../../config";
import { timezones } from "../../../library/constants";
import TextInput from "../../../library/textInput";
import { Countries } from "../../../utils/countries";
import { beforeUpload, getBase64 } from "../../../utils/upload";
import CustomLabel from "../../commonComponents/label";
import useLang from "../../../hooks/useLang";

const CompanyProfileForm = props => {
  const { company, setCompany, setAwsPictuer, companyPicture, setCompanyPicture } = props;

  const [lang] = useLang();

  const logoUploadRef = useRef("");

  // const [loading, setLoading] = useState(false);

  const [companyForm, setCompanyForm] = useState({
    name: {
      placeholder: lang.companyName,
      label: lang.companyName,
      value: company.name,
      onChangeLabel: "name",
      type: "text",
    },
    phone: {
      placeholder: lang.phoneNumber,
      label: lang.phone,
      value: company.phone,
      onChangeLabel: "phone",
      type: "phone",
    },
    website: {
      placeholder: lang.enterWebsite,
      label: lang.website,
      value: company.website,
      onChangeLabel: "website",
      type: "text",
    },
    address: {
      placeholder: lang.enterAddress,
      label: lang.addressLine1,
      value: company.address,
      onChangeLabel: "address",
      type: "text",
    },
    addressDetail: {
      placeholder: lang.enterAddressLine2,
      label: lang.addressLine2,
      value: company.addressDetail,
      onChangeLabel: "addressDetail",
      type: "text",
    },
    city: {
      placeholder: lang.enterCityLabel,
      label: lang.city,
      value: company.city,
      onChangeLabel: "city",
      type: "text",
    },
    state: {
      placeholder: lang.enterStateLabel,
      label: lang.stateProvince,
      value: company.state,
      onChangeLabel: "state",
      type: "text",
    },
    postalCode: {
      placeholder: lang.addPostalCode,
      label: lang.postalCode,
      value: company.postalCode,
      onChangeLabel: "postalCode",
      type: "text",
    },
    country: {
      placeholder: lang.chooseCountry,
      label: lang.country,
      value: company.country,
      onChangeLabel: "country",
      selectData: Countries,
      type: "select",
    },
    locationLink: {
      placeholder: lang.enterLocationLink,
      label: lang.mapLink,
      value: company.locationLink,
      onChangeLabel: "locationLink",
      type: "text",
    },
    timezone: {
      placeholder: lang.chooseTimezone,
      label: lang.timezone,
      value: company.timezone,
      onChangeLabel: "timezone",
      selectData: timezones,
      type: "select",
    },
  });

  const updateCompanyField = (field, value) => {
    if (field !== "profilePic") {
      setCompanyForm(prevState => {
        const newState = { ...prevState };
        newState[field].value = value;
        return newState;
      });
    }
    setCompany(prevState => {
      const newState = { ...prevState };
      newState[field] = value;
      return newState;
    });
  };

  return (
    <div className="py-2">
      <input
        type="file"
        ref={logoUploadRef}
        accept="image/*"
        onChange={e => {
          setAwsPictuer(e.target.files[0]);
          const input = e.target;
          const reader = new FileReader();
          reader.onload = () => {
            const dataURL = reader.result;
            setCompanyPicture(dataURL);
          };
          reader.readAsDataURL(input.files[0]);
        }}
        className="hidden"
      />

      <Button
        type="text"
        onClick={() => logoUploadRef.current.click()}
        style={{ height: "inherit", border: "none" }}
      >
        <Avatar
          size={100}
          shape="circle"
          src={companyPicture || company.profilePic || "/icons/Avatar.svg"}
        />
      </Button>

      <div className="mt-2">
        {Object.entries(companyForm).map(([key, field]) =>
          field.type === "text" ? (
            <TextInput
              key={field.label}
              label={field.label}
              value={field.value}
              placeholder={field.placeholder}
              onChange={e => updateCompanyField(field.onChangeLabel, e.target.value)}
              containerClass="mt-2"
            />
          ) : field.type === "select" ? (
            <div className="mt-2">
              <CustomLabel label={field.label} labelClass="mt-1 font-normal" />
              <Select
                className="w-full"
                showSearch
                placeholder={field.placeholder}
                value={field.value}
                onSearch={value => updateCompanyField(field.onChangeLabel, value)}
                onChange={value => updateCompanyField(field.onChangeLabel, value)}
              >
                {field?.selectData?.map(option => (
                  <Select.Option
                    key={field.label === lang.timezone ? option.value : option.name}
                    value={field.label === lang.timezone ? option.value : option.name}
                  >
                    {field.label === lang.timezone ? option.label : option.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ) : (
            <>
              <CustomLabel label={lang.phone} labelClass="mt-1 font-normal" />
              <PhoneInput
                international
                withCountryCallingCode
                countryCallingCodeEditable={false}
                defaultCountry={DEFAULT_COUNTRY}
                className="phoneInputCss"
                placeholder="Phone number"
                value={company?.phone}
                onChange={e => updateCompanyField(field.onChangeLabel, e)}
              />
            </>
          )
        )}
      </div>
    </div>
  );
};

export default CompanyProfileForm;
