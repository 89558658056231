export const colorConfig = {
  // primary color
  primary: "#013475",
  primaryDark: "#013475",
  primaryDarker: "#013475",
  primaryLight: "#013475",
  primaryLighter: "#013475",

  // secondary color
  secondary: "#013475",
  secondaryDark: "#013475",
  secondaryDarker: "#013475",
  secondaryLight: "#013475",
  secondaryLighter: "#013475",

  // asset color
  asset_section_header: "#f91e29",
  asset_label_color: "#f91e29",
  asset_option_color: "#000",

  // accent color
  accentSuccess: "#52c41a",
  accentError: "#ff4d4f",
  accentWarning: "#faad14",
  accentInfo: "#1677ff",

  // greys
  greyDefault: "#777777",
  greyDark: "#555555",
  greyDarker: "#333333",
  greyLight: "#999999",
  greyLighter: "#BBBBBB",
  greyLightest: "#F5F5F5",

  // constants
  WHITE: "#fff",
  BLACK: "#000",
  ANTD_THEME_COLOR: "#f91e29",

  // Antd Tags
  magenta: "magenta",
  steelBlue: "#2db7f5",
  cyan: "cyan",
  green: "green",
  orange: "orange",
  coral: "#ff4d4f",
  grey: "grey",

  // Appointement status colors
  cancelAppointement: "#F87171",
  successAppointement: "green",
  noShowAppointment: "grey",
  unknownStatusAppointement: "#2db7f5",
  schedualStatusAppointment: "#4ADE80",

  COMPONENT_BG: "#fff",
};
