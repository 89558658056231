import React, { useContext, useRef } from "react";
import { Dropdown, message } from "antd";
import dayjs from "dayjs";
import Icon from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ButtonCustom, SVGIcon } from "../../library/basicComponents";
import TextInput from "../../library/textInput";
import AirplaneIconWhite from "../../../static/icons/AirplaneIconWhite.svg";
import ResponseIcon from "../../../static/icons/ResponseIcon.svg";
import { ProspectStatus } from "../../library/constants";
import { colorConfig } from "../../library/colorConfig";
import CustomButton from "../commonComponents/button";
import useLang from "../../hooks/useLang";
import { UserContext } from "../../amplify/authenticator-provider";
import ChatMagic from "../engagements/activityCenter/chatMagic";

const ResponseTemplateCntr = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: ${colorConfig.white};
  box-shadow: -4px 6px 4px 0px rgba(0, 0, 0, 0.35);
`;

const ResponseTemplateItem = styled.div`
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${colorConfig.lightBrandBlue};
  }
  & > p {
    width: 100%;
    padding: 12px 20px;
    margin: 0 auto;
  }
`;

const responsesMenu = (userFilters, handleResponseMenuClick) => {
  return (
    <ResponseTemplateCntr>
      {userFilters &&
        userFilters.me.responseTemplates &&
        userFilters.me.responseTemplates.map(item => (
          <ResponseTemplateItem key={item.id} onClick={() => handleResponseMenuClick(item.message)}>
            <p>{item.message}</p>
          </ResponseTemplateItem>
        ))}
    </ResponseTemplateCntr>
  );
};

const InputTextAreaWrap = styled.textarea`
  background: rgb(245, 245, 245) !important;
  border-radius: 4px;
  border: ${`1px solid ${colorConfig.greyLight}`};
  color: #000000;
  width: 100%;
  padding: 5px 10px;
  min-height: 35px;
  resize: vertical;
`;
const ResponseProspect = props => {
  const {
    selectedPost,
    response,
    changeProspectStatus,
    userFilters,
    handleResponseMenuClick,
    handleInitialResponse,
  } = props;
  const { user } = useContext(UserContext);
  const text = useRef("");
  const prompt = `You are "${user.fullName}", representative of an auto dealership named ${
    user.company.name
  }. You saw a message posted by a user, with the username "${
    selectedPost.author
  }", in the forum "${selectedPost.source}" on ${dayjs(selectedPost.timestamp).format(
    "MM.DD.YYYY"
  )}. Your goal is to help the users with their questions in the forum and potentially steer them 
  towards buying products or getting services at your business. As this is an online forum, be 
  respectful and do not market or sell anything about your business overtly. Using these 
  guidelines, generate a response that can be posted in the style of a forum message that seems 
  like a natural conversation rather than a system generated message. The message posted on the 
  forum is below: \n ${selectedPost.body}.`;
  const [lang] = useLang();
  return (
    <div>
      {selectedPost && (
        <div>
          <div className="flex justify-between font-bold">
            <p>{lang.prospectPost}</p>
            <p>{dayjs(selectedPost.timestamp).format("MM.DD.YYYY")}</p>
          </div>
          <p className="text-start py-2">{selectedPost.body}</p>
        </div>
      )}
      <div className="flex pt-3 gap-2 w-full items-center">
        <InputTextAreaWrap
          id="text"
          ref={text}
          rows="1"
          placeholder={lang.typeResponse}
          name="response"
          containerClass="flex-1"
          small
        />
        <Dropdown
          overlay={responsesMenu(userFilters, handleResponseMenuClick)}
          placement="bottomRight"
        >
          <ButtonCustom shape="circle">
            <SVGIcon component={ResponseIcon} alt="Response" />
          </ButtonCustom>
        </Dropdown>
        <CustomButton
          type="primary"
          tooltipTitle={lang.gotoPost}
          shape="circle"
          handleClick={() => {
            // // eslint-disable-next-line no-unreachable
            if (text.current.value === "") {
              return message.error("Please enter a repsonse message");
            }
            const responseMessage = text.current.value;
            navigator.clipboard
              .writeText(text.current.value)
              .then(() => {
                window.open(selectedPost.url);
              })
              .catch(err => {
                console.log(err);
              });
            if (responseMessage.trim()) {
              handleInitialResponse(responseMessage, selectedPost.id);
            }
          }}
          icon={<Icon component={AirplaneIconWhite} />}
          alt="Accept"
        />
        <ChatMagic
          text={text}
          user={user}
          prompt={prompt}
          code="PROSPECT_MESSAGE_SUGGESTION"
          postId={selectedPost.id}
        />
      </div>
    </div>
  );
};

export default ResponseProspect;
