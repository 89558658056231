import React from "react";
import { Table } from "antd";

const CustomTable = props => {
  const {
    columns,
    data,
    rowKey,
    rowClassName,
    className,
    tableSize,
    onRow,
    loading,
    pagination,
    expandedRowRender,
    rowSelection,
    scroll,
    onChange,
    bordered,
    getPopupContainer,
  } = props;

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={rowKey}
      rowClassName={rowClassName}
      className={className}
      size={tableSize}
      onRow={onRow}
      loading={loading}
      pagination={pagination}
      expandedRowRender={expandedRowRender}
      rowSelection={rowSelection}
      scroll={scroll}
      onChange={onChange}
      bordered={bordered}
      getPopupContainer={getPopupContainer}
    />
  );
};

CustomTable.defaultProps = {
  tableSize: "small",
  onRow: () => {},
  loading: { spinning: false, tip: "" },
  expandedRowRender: false,
  onChange: () => {},
  scroll: null,
  bordered: true,
};

export default CustomTable;
