import { DownloadOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import AntdIconV4 from "../../../../utils/antdIcon";
import { mediaDownloader } from "../../../../utils/mediaDownloader";
import { ModalStyled } from "./multipleMediaViewer";

export function ViewMediaPopup({ pdfModal, setpdfModal }) {
  return (
    <ModalStyled
      title={null}
      width="97.5%"
      open={pdfModal.visible}
      onCancel={() => setpdfModal({ visible: false, pdfUrl: "" })}
      wrapClassName="mediaModal"
      footer={null}
      closeIcon={
        <div className="flex flex-row items-center gap-2 pr-5">
          <Tooltip title="Download file">
            <DownloadOutlined
              onClick={e => {
                e.stopPropagation();
                mediaDownloader(pdfModal?.pdfUrl, pdfModal?.mediaType?.split("/")[1]);
              }}
              className="text-white text-lg"
            />
          </Tooltip>
          <AntdIconV4 type="CloseOutlined" className="text-white text-lg" />
        </div>
      }
    >
      <iframe
        allowFullScreen
        src={pdfModal.pdfUrl}
        className="h-[85vh] w-[92vw] mt-[35px] media-view-iframe"
        title="PDF"
      />
    </ModalStyled>
  );
}
