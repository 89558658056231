import React from "react";
import CustomButton from "./button";
import useLang from "../../hooks/useLang";

const DialogFooter = ({
  loading,
  handleConfirm,
  type,
  handleCancel,
  ExtraComponent = "",
  confirmBtnText,
  cancelBtnText,
  extraComponent,
  isOKGhost = false,
  danger = false,
  disabled = false,
  showConfirmButton = true,
  align = "right",
}) => {
  const [lang] = useLang();
  return (
    <div className="bg-[#faf8fc] mt-3 p-4 flex flex-col gap-3">
      <div
        className={`flex bottom-0 ${
          align === "right" ? "justify-end" : "justify-start"
        } gap-2 w-full`}
      >
        {ExtraComponent && ExtraComponent}
        {showConfirmButton && (
          <CustomButton
            btnText={confirmBtnText || lang.save}
            key="submit"
            loading={loading}
            type={type || "primary"}
            handleClick={handleConfirm}
            ghost={isOKGhost}
            danger={danger}
            disabled={disabled}
          />
        )}

        <CustomButton
          btnText={cancelBtnText || lang.cancel}
          key="back"
          handleClick={handleCancel}
        />
      </div>
    </div>
  );
};

export default DialogFooter;
