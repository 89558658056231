/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import GifPicker from "gif-picker-react";
import { getResourceSize } from "../../../../library/helpers";

const GifPickerComponent = ({ attachments, setAttachments }) => {
  const createMessage = async gifData => {
    setAttachments([
      ...attachments,
      {
        file: {
          name: gifData.description,
          type: "image/gif",
          size: await getResourceSize(gifData.url),
        },
        preview: gifData.url,
        generateLink: false,
      },
    ]);
  };
  return (
    <GifPicker
      tenorApiKey={process.env.GATSBY_GIF_API_KEY || "AIzaSyC0VeXBI15-c38X1V7ouw-NII9THsbcB0M"}
      onGifClick={gif => createMessage(gif)}
    />
  );
};

export default GifPickerComponent;
// AIzaSyC0VeXBI15-c38X1V7ouw-NII9THsbcB0M
