/* eslint-disable no-use-before-define */
import dayjs from "dayjs";
import { groupBy, isEmpty, map, sumBy } from "lodash";

const MILISECONDS_DAY = 86400000;

// Filters Constant
export const THREE_MONTHS = "THREE_MONTHS";
export const ONE_MONTH = "ONE_MONTH";
export const TWO_WEEKS = "TWO_WEEKS";
export const THIS_MONTH = "THIS_MONTH";
export const THIS_WEEK = "THIS_WEEK";
export const LAST_MONTH = "LAST_MONTH";
export const LAST_WEEK = "LAST_WEEK";

// Actions
export const SET_PERIOD = "SET_PERIOD";
export const SET_CHANGE = "SET_CHANGE";
export const SET_TEAM = "SET_TEAM";
export const SET_GRAPH_TYPE = "SET_GRAPH_TYPE";

export const activityAnalysis = (data, range) => {
  const SENT = ["SENT", "DELIVERED"];
  const RECEIVED = ["RESPONDED"];
  const ENGAGED = ["ENGAGED"];
  const APPOINTMENT = ["APPOINTMENT"];
  const rangedData = filterByRange(data, range);

  const previousDate = substractDays(new Date().setHours(0, 0, 0, 0), range / 2);
  const previousData = filterByRange(data, range, previousDate);

  // actual
  const actualSent = countByStatus(rangedData)(SENT);
  const actualReceived = countByStatus(rangedData)(RECEIVED);
  const actualEnagaged = countByStatus(rangedData)(ENGAGED);
  const actualAppointment = countByStatus(rangedData)(APPOINTMENT);

  const totalMessages = actualSent + actualReceived;

  // previous
  const previousSent = countByStatus(previousData)(SENT);
  const previousReceived = countByStatus(previousData)(RECEIVED);
  const previousEngaged = countByStatus(previousData)(ENGAGED);
  const previousAppointment = countByStatus(previousData)(APPOINTMENT);
  const previousTotalLeads = previousSent + previousReceived;

  const respRate =
    actualSent > 0 && actualReceived > 0
      ? Number((actualReceived / totalMessages) * 100).toFixed(2)
      : 0;

  const prevRespRate =
    previousSent > 0 && previousTotalLeads > 0 && previousReceived > 0
      ? Number((previousReceived / previousTotalLeads) * 100).toFixed(2)
      : 0;

  return [
    {
      title: "Total Messages",
      count: totalMessages,
      change: getChange(totalMessages, previousTotalLeads),
      previous: previousTotalLeads,
    },
    {
      title: "Total Messages Sent",
      count: actualSent,
      change: getChange(actualSent, previousSent),
      previous: previousSent,
    },
    {
      title: "Response Rate",
      count: respRate,
      change: getChange(respRate, prevRespRate),
      previous: prevRespRate,
      isPercentage: true,
    },
    {
      title: "Engagements",
      count: actualEnagaged,
      change: getChange(actualEnagaged, previousEngaged),
      previous: previousEngaged,
      compareType: true,
      isEngagements: false,
    },
    {
      title: "Appointments",
      count: actualAppointment,
      change: getChange(actualAppointment, previousAppointment),
      previous: previousAppointment,
      compareType: true,
      isEngagements: false,
    },
  ];
};

export const responseByEngagement = data => {
  const newData = [...data];

  const summedData = map(groupBy(newData, "attempts"), (groupData, attempt) => {
    const name = getAttemptName(attempt);
    return { type: name, value: sumBy(groupData, "count") };
  });

  const summed = summedData.filter(el => el.type);

  if (!isEmpty(summed)) {
    return summed;
  }
  return [];
};

export const leadBySource = data => {
  const summed = map(groupBy(data, "source"), (groupData, idx) => {
    return { id: idx, summed: sumBy(groupData, "count") };
  });

  if (!isEmpty(summed)) {
    const graphdata = summed.map(field => {
      return {
        type: field.id,
        value: field.summed,
      };
    });
    return graphdata;
  }
  return [];
};

export const appointmentsAnalytics = data => {
  const localData = (data || []).map(el => ({
    ...el,
    previous: el.prevCount,
    change: el.growthRate.toFixed(0) || getChange(el.count, el.prevCount),
  }));

  return localData;
};

export const appointmentsBySalesPerson = data => {
  const summed = map(groupBy(data, "userName"), (groupData, key) => {
    return { id: key, summed: sumBy(groupData, "count") };
  });

  if (!isEmpty(summed)) {
    const graphdata = summed.map(field => {
      return {
        type: field.id,
        value: field.summed,
      };
    });
    return graphdata || [];
  }
  return [];
};

export const leadAnalysis = (data, range) => {
  const UNRESPONDED = ["SENT", "DELIVERED", "OPT_PUT"];
  const RESPONDED = ["RESPONDED"];
  const ENGAGED = ["ENGAGED"];

  const rangedData = filterByRange(data, range / 2);
  const previousDate = substractDays(new Date().setHours(0, 0, 0, 0), range / 2);
  const previousData = filterByRange(data, range / 2, previousDate);

  // actual
  const actualUnresponded = countByStatus(rangedData)(UNRESPONDED);
  const actualEngaged = countByStatus(rangedData)(ENGAGED);
  const actualResponded = countByStatus(rangedData)(RESPONDED);
  const totalLeads = countByStatus(rangedData)("N/A");

  // previous
  const previousTotalLeads = countByStatus(previousData)("N/A");

  return [
    {
      title: "Total New Leads",
      count: totalLeads,
      change: getChange(totalLeads, previousTotalLeads),
      previous: previousTotalLeads,
    },
    {
      title: "Responded/Unresponded",
      count: actualResponded,
      previous: actualUnresponded,
      compareType: true,
    },
    {
      title: "Single Response/Engagements",
      count: actualResponded - actualEngaged,
      previous: actualEngaged,
      compareType: true,
    },
  ];
};

export const filterByRange = data =>
  (data || []).map(item => {
    const localDate = item.date;

    return {
      ...item,
      date: localDate, // we manually set the time to zero because of possible time discrepancies
    };
  });

export const substractDays = (date, days) => {
  return zeroDate(date) - MILISECONDS_DAY * days;
};

const zeroDate = date => (date ? new Date(date) : new Date().setHours(0, 0, 0, 0));

export const getChange = (newValue, oldValue) => {
  const change = Number((((newValue - oldValue) / oldValue) * 100).toFixed(0));
  if (Number.isNaN(change)) return "-";
  if (change === Infinity) return "*";
  return change;
};

// filters the data and get the count based on the status
export const countByStatus = data => status => {
  if (data.length <= 0) return 0;

  return filterByStatus(data)(status).reduce((acc, curr) => acc + curr.count, 0);
};

export const filterByStatus = data => status => {
  if (data.length <= 0) return [];

  if (typeof status === "string") {
    status = [status];
  }

  return data.filter(kpi => status.indexOf(kpi.status) >= 0).flat();
};

export const getAttemptName = attempt => {
  let name = "";
  switch (attempt) {
    case "1":
      name = "1st Attempt";
      break;
    case "2":
      name = "2nd Attempt";
      break;
    case "3":
      name = "3rd Attempt";
      break;
    case "4":
      name = "4th Attempt";
      break;
    case "5":
      name = "5th Attempt";
      break;
    default:
      break;
  }
  return name;
};

const changeMenu = {
  THIS_MONTH: {
    label: "This Month",
    range: +dayjs().daysInMonth(),
  },
  LAST_MONTH: {
    label: "Last Month",
    range: +dayjs().subtract(1, "months").daysInMonth(),
  },
  LAST_WEEK: { label: "Last Week", range: 7 },
  THIS_WEEK: { label: "This Week", range: 7 },
};

export const periodsMenu = {
  THIS_MONTH: {
    label: "This Month",
    range: +dayjs().daysInMonth(),
  },
  LAST_MONTH: {
    label: "Last Month",
    range: +dayjs().subtract(1, "months").daysInMonth(),
  },
  LAST_WEEK: { label: "Last Week", range: 7 },
  THIS_WEEK: { label: "This Week", range: 7 },
};
