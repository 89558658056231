/* eslint-disable react/no-children-prop */
import { CheckOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Badge, Button, Checkbox, Input, List, Modal, Popover, Radio, Tag, Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../../amplify/authenticator-provider";
import { colorCode } from "../../../config";
import { UPDATE_LEAD } from "../../../graphql/mutation";
import useLang from "../../../hooks/useLang";
import FilterIcon from "../../../icons/js/FilterIcon";
import SortIcon from "../../../icons/js/SortIcon";
import { getCacheApi, setSelectedLeadPhone } from "../../../redux/slices/apiCacheSlice";
import {
  addQuickFilter,
  removeQuickFilter,
  setLeadSortOrder,
  setSearchRedux,
} from "../../../redux/slices/leadSlice";
import AntdIconV4 from "../../../utils/antdIcon";
import CustomLabel from "../../commonComponents/label";
import LeadFilterBreadcrumbs from "./leadFilterBreadcrumbs";
import LeadList from "./leadList";

const LeadListContainer = props => {
  const {
    setShowLeadSection,
    selectedLead,
    leadsLoading,
    leadList,
    setLeadList,
    variables,
    leadCount,
    setVariables,
    setSelectedLead,
    setMessageFilters,
    messageFilters,
    sourcesData,
    searchKey,
    handleSearch,
    setSearchKey,
    activeOpp,
    setActiveOpp,
    opportunityList,
    timeReference,
    setOpportunityList,
    setFilterApplied,
    handleOppFilter,
    handleStatusFilter,
    leadStatus,
    selectedStatus,
    setSelectedStatus,
    openAppointmentPopup,
    handleAppointmentModalClose,
    setSelectedLeadId,
    setbtnClicked,
    setActiveLeadId,
    handleScrollOnMovement,
    changePage,
    setQueryString = () => {},
    isFromChatbot = false,
    handleLeadListVisible,
    IsLeadListVisible,
    spinner,
    campaignFilters,
    queryString,
    entityId,
    eventId,
    handleLeadListDrawer = () => {},
    onClose,
    setAttachments,
    refetchLeads,
    setmessageText = () => {},
    setSummaryVisible,
    handleUnpinLead = () => {},
    selectedLeadsFromLeadCenter = [],
  } = props;
  const quickFilters = [
    {
      title: "Inbox",
      backgroundColor: "processing",
      value: "RESPONDED",
    },
    {
      title: "Sent",
      backgroundColor: "success",
      value: "WAITING_FOR_REPLY",
    },
    {
      title: "Archived",
      backgroundColor: "error",
      value: "NO_RESPONSE_NEEDED",
    },
  ];
  const urlParams = new URLSearchParams(window.location.search);
  const presetLeadFilter = urlParams.get("presetFilter");

  const { user, modal, newLead, setNewLead, addnewLead, setAddNewLead } = useContext(UserContext);
  const dispatch = useDispatch();
  const [lang] = useLang();
  const selectedLeadPhoneReduxData = useSelector(state => getCacheApi(state, "selectedLeadPhone"));
  const { quickFilters: selectedQuickFilters, userFilters } = useSelector(
    state => state.lead.filters
  );
  const { leads, leadSort, search } = useSelector(state => state.lead);
  const [visible, setVisible] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [noResponseNeededLoading, setNoResponseNeededLoading] = useState(false);
  const [setLeadAsNoResponseNeeded, { data: respLoctions }] = useMutation(UPDATE_LEAD, {
    onCompleted: resp => {
      if (resp?.updateLead?.statusCode === 200) {
        setNoResponseNeededLoading(false);
        refetchLeads();
      }
    },
  });
  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const filterCount =
    (variables?.campaignIds || []).length +
    (variables.search && variables.search !== "undefined" ? 1 : 0) +
    (presetLeadFilter && presetLeadFilter !== "undefined" ? 1 : 0) +
    (selectedStatus || []).length +
    userFilters.length;

  useEffect(() => {
    if (newLead !== null && addnewLead) {
      const array = () => {
        const temp = structuredClone(leads);
        newLead.fullName = newLead.full_name;
        temp.unshift(newLead);
        return [...temp];
      };
      setLeadList(array());
      setAddNewLead(false);
      setNewLead(null);
    }
  }, [newLead, addnewLead]);

  useEffect(() => {
    setSearchKey(selectedLeadPhoneReduxData);
  }, [selectedLeadPhoneReduxData]);

  useEffect(() => {
    if (!window.location.search) dispatch(setSelectedLeadPhone({ selectedLeadPhone: searchKey }));
    // return () => dispatch(setSelectedLeadPhone({ selectedLeadPhone: "" }));
  }, [searchKey]);

  useEffect(() => {
    return () => dispatch(setSelectedLeadPhone({ selectedLeadPhone: "" }));
  }, []);

  useEffect(() => {
    setLeadData(leadList);
  }, [leadList]);

  const setSearch = search => {
    setVariables({ ...variables, page: 1 });
    dispatch(setSearchRedux(search));
  };
  const SortOptionsContainer = () => {
    const [sortOption, setsortOption] = useState(leadSort);
    // prioritySortOrder
    return (
      <>
        <h3>Sort options</h3>
        <Radio.Group
          value={sortOption}
          onChange={e => {
            setsortOption(e.target.value);
            dispatch(setLeadSortOrder(e.target.value));
          }}
          className="flex flex-col"
        >
          <Radio value="default">Default</Radio>
          <Radio value="lastMessage">Most Recent Message</Radio>
        </Radio.Group>
      </>
    );
  };
  const handleQuickFilters = value => {
    if ((selectedQuickFilters || [])?.includes(value)) {
      dispatch(removeQuickFilter(value));
    } else {
      dispatch(addQuickFilter(value));
    }
  };
  const isBrowserExt = window.location.pathname.includes("browser-ext");

  return (
    <>
      <div className="sticky top-0 bg-white z-[999]">
        <div className="flex items-center">
          <Input
            size="large"
            placeholder={lang.searchLeadBy}
            name="search"
            className="z-10 bg-white rounded-none "
            suffix={
              <>
                {!isEmpty(search) && (
                  <AntdIconV4
                    type="CloseCircleOutlined"
                    onClick={() => {
                      isFromChatbot && window.parent.postMessage(search, "*");
                      setSearchKey("");
                      setQueryString("");
                      handleSearch("");
                      setSearch("");
                      localStorage.setItem("previousLeadSearchTrack", "");
                      // window.history.pushState("", "", `/engagements/activity-center`);
                    }}
                    className="cursor-pointer text-white text-[14px] mr-[5px] rounded-full bg-[#999696]"
                  />
                )}
                <Tooltip title={lang.searchLeadBy}>
                  <AntdIconV4
                    type="SearchOutlined"
                    onClick={() => {
                      localStorage.setItem("previousLeadSearchTrack", "");
                      search && handleSearch(search);
                    }}
                    className="cursor-pointer"
                  />
                </Tooltip>
              </>
            }
            value={search}
            onChange={e => {
              // dispatch(setSelectedLeadPhone(e.target.value));
              if (isEmpty(e.target.value)) {
                if (window.location.pathname.includes("browser-ext")) {
                  window.history.pushState("", "", `/browser-ext/`);
                } else {
                  window.history.pushState("", "", `/engagements/activity-center`);
                }
              }
              setSearch(e.target.value);
            }}
          />
          <Popover trigger="click" placement="right" content={<SortOptionsContainer />}>
            <Button className="mx-1 flex items-center gap-2 rounded-none h-[39px]">
              {!isBrowserExt && "Sort"}
              <SortIcon />
            </Button>
          </Popover>
          <Popover
            placement="right"
            title={<CustomLabel label={lang.leadFilters} labelClass="font-semibold" />}
            open={visible}
            onOpenChange={handleVisibleChange}
            content={
              <LeadFilterBreadcrumbs
                variables={variables}
                setVariables={setVariables}
                setSearchKey={setSearchKey}
                opportunityList={opportunityList}
                activeOpp={activeOpp}
                handleOppFilter={handleOppFilter}
                setActiveOpp={setActiveOpp}
                setShowLeadSection={setShowLeadSection}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                leadStatus={leadStatus}
                handleStatusFilter={handleStatusFilter}
                handleVisibleChange={handleVisibleChange}
                isFromChatbot={isFromChatbot}
                campaignFilters={campaignFilters}
                queryString={queryString}
                entityId={entityId}
                eventId={eventId}
              />
            }
            trigger="click"
          >
            <Tooltip placement="topLeft" title={lang.moreLeadFilters}>
              <Button className="flex items-center gap-2 rounded-none h-[39px]">
                {!isBrowserExt && "Filter"}
                <Badge count={filterCount} className="mt-2" style={{ backgroundColor: "red" }}>
                  <FilterIcon />
                </Badge>
              </Button>
            </Tooltip>
          </Popover>
        </div>
        {/* <Spin spinning={leadsLoading}> */}
        <div className=" flex items-center my-2 ">
          <div className="flex w-full">
            {quickFilters.map((item, index) => (
              <Tag
                onClick={() => handleQuickFilters(item.value)}
                className="cursor-pointer w-full rounded-none flex items-center justify-center gap-1  text-center"
                key={index}
                icon={(selectedQuickFilters || [])?.includes(item.value) ? <CheckOutlined /> : null}
                color={
                  (selectedQuickFilters || [])?.includes(item.value)
                    ? item.backgroundColor
                    : "#E2E8F0"
                }
                style={
                  !(selectedQuickFilters || [])?.includes(item.value)
                    ? { backgroundColor: "#E2E8F0", color: "#334155" }
                    : ""
                }
              >
                {item.title}
              </Tag>
            ))}
          </div>
        </div>
        {/* </Spin> */}
      </div>
      <List
        className="cursor-pointer"
        itemLayout="horizontal"
        loading={leadsLoading}
        dataSource={leadData}
        locale={{ emptyText: lang.emptyLeadsMsg }}
        pagination={
          (leadData || []).length === 0
            ? null
            : leadCount < 5
            ? null
            : {
                defaultCurrent: variables.page,
                current: variables.page,
                defaultPageSize: variables.pageSize,
                pageSize: variables.pageSize,
                total: leadCount,
                showTotal: total =>
                  `${lang.total}: ${total} ${total === 1 ? lang.lead : lang.leads}`,
                pageSizeOptions: ["5", "25", "50"],
                showSizeChanger: true,
                onChange: (page, pageSize) => {
                  const newVariables = {
                    ...variables,
                    page: page,
                    pageSize: pageSize,
                  };
                  localStorage.removeItem("clickLeadId");
                  setVariables(newVariables);
                  setActiveOpp("#DEFAULTOPPID");
                  handleScrollOnMovement();
                  setSelectedLead({});
                  localStorage.removeItem("changedLocationLeadId");
                  setActiveLeadId(false);
                  setLeadData([]);
                },
                onShowSizeChange: (current, size) => {
                  const newVariables = {
                    ...variables,
                    page: current,
                    pageSize: size,
                  };
                  localStorage.removeItem("clickLeadId");
                  setVariables(newVariables);
                },
                size: "small",
              }
        }
        renderItem={(item, i) => {
          const { messages } = item;
          const findLastMessage = (messages, n) => {
            if (n >= 0) {
              const message = messages[n];

              if (message !== undefined && message !== null) {
                return message;
              }
              return findLastMessage(messages, n - 1);
            }
            return null; // Return null if no valid message is found in the entire array.
          };

          const isPinLeadFromLeadCenter =
            (selectedLeadsFromLeadCenter || []).findIndex(el => el?.id === item?.id) > -1;
          const TitleComponent = () => {
            return (
              <div className="flex flex-col">
                <div className="flex justify-between mb-2">
                  <span className="text-grey-700 w-1/2">{item?.fullName}</span>
                  <Checkbox
                    className="w-1/2 flex justify-end items-center"
                    checked={item?.messageStatusType?.status === "NO_RESPONSE_NEEDED"}
                    loading={noResponseNeededLoading}
                    disabled={item?.messageStatusType?.status === "NO_RESPONSE_NEEDED"}
                    onChange={e => {
                      e.stopPropagation();
                      Modal.confirm({
                        title: "Are you sure?",
                        icon: <ExclamationCircleFilled />,
                        content: (
                          <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: `${lang.noResponseNeededConfirmationMessage.replace(
                                "__leadName__",
                                item?.fullName
                              )}`,
                            }}
                          />
                        ),
                        onOk() {
                          setNoResponseNeededLoading(true);
                          setLeadAsNoResponseNeeded({
                            variables: {
                              leadId: item?.id,
                              isNoResponseNeeded:
                                item?.messageStatusType?.status !== "NO_RESPONSE_NEEDED",
                            },
                          });
                        },
                        onCancel() {
                          console.log("Cancel");
                        },
                      });
                    }}
                    children={<span className="text-[12px] font-normal">No response needed?</span>}
                  />
                  {isPinLeadFromLeadCenter && (
                    <Tooltip title="Remove lead">
                      <AntdIconV4
                        type="PushpinFilled"
                        style={{ color: "#334155" }}
                        ml={4}
                        onClick={() => handleUnpinLead(item)}
                      />
                    </Tooltip>
                  )}
                </div>

                <div className="w-full mt-4 mb-3 h-[1px] bg-[#cbd5e1]" />
              </div>
            );
          };
          return (
            <List
              key={item?.id}
              className={`leadBox border-solid border-l-[6px] border-[1px] my-3 p-3 mr-2 rounded-[4px] mb-[0.2rem] ${
                selectedLead && selectedLead?.id === item?.id ? "selectedLead" : "none"
              }`}
              style={{
                borderLeftColor: colorCode[item?.status] ? colorCode[item?.status] : "#007dae",
              }}
              onClick={() => {
                setSummaryVisible &&
                  setSummaryVisible({
                    visible: false,
                    count: 0,
                  });
                // setLeadList(prev => {
                //   const newState = [...prev];
                //   newState[i] = { ...prev[i], unreadCount: 0 };
                //   return [...newState];
                // });
              }}
            >
              <List.Item.Meta
                title={<TitleComponent item={item} />}
                description={
                  <LeadList
                    messageObject={(item?.messages || []).length > 0 ? item?.messages[0] : {}}
                    user={user}
                    item={item}
                    isFromChatbot={isFromChatbot}
                    sourcesData={sourcesData}
                    activeOpp={activeOpp}
                    setActiveOpp={setActiveOpp}
                    openAppointmentPopup={openAppointmentPopup}
                    handleAppointmentModalClose={handleAppointmentModalClose}
                    spinner={spinner}
                    selectedLeadId={selectedLead?.id}
                    refetchLeads={refetchLeads}
                    selectedLead={selectedLead}
                  />
                }
                onClick={() => {
                  setAttachments([]);
                  setmessageText("");
                  if (selectedLead?.id === item?.id) {
                    return;
                  }
                  const itemData = { ...item, messages: [] };
                  localStorage.getItem("changedLocationLeadId") &&
                    localStorage.removeItem("changedLocationLeadId");
                  itemData.unreadCount = 0;
                  setSelectedLead(itemData);
                  changePage();

                  const dummyPayload = { ...messageFilters };
                  delete dummyPayload.leadOpportunityId;
                  setMessageFilters({
                    ...dummyPayload,
                    page: 1,
                    leadId: item?.id,
                    lastId: "",
                  });
                  setShowLeadSection(false);
                  setOpportunityList([
                    {
                      filterId: null,
                      id: "#DEFAULTOPPID",
                      name: "All",
                    },
                  ]);
                  setFilterApplied(false);
                  setActiveOpp("#DEFAULTOPPID");
                  setSelectedLeadId(item?.id);
                  clearTimeout(timeReference);
                  setbtnClicked(false);
                  handleLeadListDrawer();
                }}
              />
            </List>
          );
        }}
      />
    </>
  );
};

export default LeadListContainer;
