import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { EDIT_USER_LOCATION } from "../../../graphql/mutation/mutation";
import DialogFooter from "../../commonComponents/dialogFooter";
import { setLocationIdSlice } from "../../../redux/slices/locationSlice";
import useLang from "../../../hooks/useLang";

const EditLeadLocation = props => {
  const { user, isFromActivity = false, leadId, refetchLeads, handleCancelBtn } = props;

  const dispatch = useDispatch();

  const [lang] = useLang();

  const [userActiveLocations, setUserActiveLocations] = useState([]);
  const [activeLocationId, setActiveLocationId] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [updateLocation] = useMutation(EDIT_USER_LOCATION, {});

  useEffect(() => {
    const userActiveLocationsData =
      user &&
      user.locations?.length > 0 &&
      user?.locations.map(location => {
        return location?.location;
      });
    setUserActiveLocations([...userActiveLocationsData]);

    const currentLocation = userActiveLocationsData.find(
      loc => +loc.id === +sessionStorage.getItem("currentLocationId")
    );
    setActiveLocationId(currentLocation?.id || userActiveLocationsData[0]?.id || "");
  }, [user]);

  const handleLocation = e => {
    setActiveLocationId(e);
  };

  const handleOKBtn = async () => {
    setConfirmLoading(prev => !prev);
    try {
      await updateLocation({
        variables: {
          leadId: +leadId,
          locationId: +activeLocationId,
        },
      });
      handleCancelBtn();
      message.success(lang.leadLocationChanged);
      if (isFromActivity) {
        localStorage.setItem("changedLocationLeadId", leadId);
        sessionStorage.setItem("currentLocationId", activeLocationId);
        dispatch(setLocationIdSlice({ locationId: activeLocationId }));
        // window.location.reload();
      } else {
        refetchLeads();
      }
    } catch (err) {
      message.error(lang.somethingWentWrong);
    }
  };

  return (
    <div>
      <Select
        className="w-full"
        onChange={handleLocation}
        value={activeLocationId}
        placeholder={lang.selectLocation}
        showArrow
      >
        {(userActiveLocations || []).map(location => (
          <Select.Option key={location.id} value={location.id}>
            {location.title}
          </Select.Option>
        ))}
      </Select>
      <DialogFooter
        disabled={+sessionStorage.getItem("currentLocationId") === +activeLocationId}
        confirmBtnText={lang.sendToStore}
        handleConfirm={handleOKBtn}
        handleCancel={() => handleCancelBtn()}
        loading={confirmLoading}
      />
    </div>
  );
};

export default EditLeadLocation;
