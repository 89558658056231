import { Divider } from "antd";
import React from "react";

const CustomDivider = props => {
  const { className, dashed, orientation } = props;
  return <Divider className={className || "my-2"} dashed={dashed} orientation={orientation} />;
};

CustomDivider.defaultProps = {
  dashed: false,
  orientation: "",
};

export default CustomDivider;
