/* eslint-disable react/destructuring-assignment */
import { Avatar, Badge, Drawer, Dropdown, Select, Spin } from "antd";
import { Link } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../amplify/authenticator-provider";
import useLang from "../../hooks/useLang";
import { getCognitoRoles, isPlatformAdmin } from "../../hooks/utils";
import NotificationIcon from "../../icons/js/NotificationIcon";
import {
  pushActiveLeadFilter,
  resetApiCacheStore,
  setSelectedLeadPhone,
} from "../../redux/slices/apiCacheSlice";
import { setDrawerStatus } from "../../redux/slices/drawerSlice";
import { getMobileView } from "../../redux/slices/isMobile";
import { getLocationId, setLocationIdSlice } from "../../redux/slices/locationSlice";
import AntdIconV4 from "../../utils/antdIcon";
import { dynamicSort } from "../../utils/dataSorting";
import MobileNotifications from "../NavigationContainer/mobileNotifications";
import NavigationContainer from "../NavigationContainer/navigationContainer";
import Toast from "../toast/Toast";
import "./layout.css";
import {
  NotificationsDropdown,
  convertToInitials,
  handleNotifMenuClick,
  profileMenu,
  titleMap,
} from "./layoutHelpers";
import Logo from "./logoWithText.png";

const MainLayout = props => {
  const {
    accessPayload,
    permissionLeader,
    list,
    checkValue,
    autoDeleteTime,
    setSocketMsgSelectedLead,
    canchangeLocation,
    setPhone,
    location2,
    setLocation,
    getActiveCrmIntegration,
    setUser,
    persistor,
    currentPath,
    user,
    setchanged,
    changed,
    notifications,
    setShowNotifModal,
    setSelectedNotif,
    getMoreNotifications,
    hasMore,
    markeNotifAsRead,
    notificationsCount,
    children,
    handleProfileMenuClick,
    setShowSupportModal,
    deleteFcmDevice,
    userFullName,
    setContentStyle,
    contentStyle,
    showNotifModal,
    selectedNotif,
    locationId,
    refetchNotifications,
    refetchNotificationsCount,
    setNotifications,
  } = props;

  const dispatch = useDispatch();
  const [lang] = useLang();
  const isMobileView = useSelector(state => getMobileView(state));
  const locationIdslice = useSelector(state => getLocationId(state));
  const [bindedIsFullScreen, setBindedIsFullScreen] = useState(true);
  const { isFullScreen, setIsFullScreen, authData } = useContext(UserContext);
  const [notificationDrawer, setNotificationDrawer] = useState(false);

  const isPlatformAdminUser = isPlatformAdmin(authData);

  useEffect(() => {
    if (isMobileView) {
      if (window.location.pathname.includes("activity-center")) {
        setIsFullScreen(false);
      } else {
        setIsFullScreen(true);
      }
    }
  }, [window.location.href]);

  useEffect(() => {
    // console.log("inside useEffect==>", isFullScreen);
    isMobileView && setBindedIsFullScreen(isFullScreen);
  }, [isFullScreen]);

  const handleNotificationDrawer = () => {
    setNotificationDrawer(prev => !prev);
  };

  const setSelectedLeadPhoneToRedux = selectedLeadPhone => {
    dispatch(setSelectedLeadPhone({ selectedLeadPhone }));
  };

  const handleActiveLeadFilterOnLogout = () => {
    dispatch(pushActiveLeadFilter({ activeLeadFilter: {} }));
  };

  const setLocationToNullOnLogout = () => {
    dispatch(setLocationIdSlice({ locationId: null }));
  };

  return (
    <div className="min-h-screen">
      <Spin
        spinning={getCognitoRoles(accessPayload)[0] !== "ADMIN" && permissionLeader}
        className="fixed top-[50%] left-[50%]"
        style={{
          transform: "translateX(-50%) translateY(-50%)",
        }}
      />
      {((isMobileView && isFullScreen) || !isMobileView) && (
        <div className="flex gap-2 sticky top-0 z-50 bg-white py-1 px-3 h-14 justify-between items-center w-full">
          <div className="flex items-center gap-2">
            <Link to="/prospect">
              {/* <Icon className="text-4xl" component={EngagedAI} alt="SocialMiningAi" /> */}
              <img src={Logo} alt="Logo" className="w-[150px]" />
            </Link>
            {isMobileView &&
              (window.location.pathname.includes("settings") ||
                window.location.pathname.includes("admin")) && (
                <AntdIconV4
                  type="MenuOutlined"
                  onClick={() => dispatch(setDrawerStatus({ open: true }))}
                  className="cursor-pointer text-2xl flex-1 font-semibold md:hidden"
                />
              )}
            <Toast
              toastList={list}
              autoDelete={checkValue}
              autoDeleteTime={autoDeleteTime}
              onClickofToast={leadId => setSocketMsgSelectedLead(leadId)}
              isFromLayout
            />
          </div>
          <div className="flex items-center gap-2">
            {getCognitoRoles(accessPayload)[0] !== "ADMIN" && (
              <Select
                disabled={!canchangeLocation}
                onChange={e => {
                  localStorage.removeItem("changedLocationLeadId");
                  localStorage.removeItem("clickLeadId");
                  setPhone("");
                  if (e === "all") {
                    let ids = "";
                    for (let i = 0; i < location2.length; i++) {
                      const element = location2[i];
                      ids = `${ids}${element.id}${location2.length - 1 === i ? "" : ","}`;
                    }
                    sessionStorage.setItem("currentLocationId", ids);
                    dispatch(setLocationIdSlice({ locationId: ids }));
                  } else {
                    setLocation(e);
                    sessionStorage.setItem("currentLocationId", e);
                    const crmIntegrationType = getActiveCrmIntegration(e);
                    setUser({
                      ...user,
                      locationId: e,
                      company: { ...user.company, crmIntegrationType },
                    });
                  }
                  if (window.location.pathname?.includes("activity-center")) {
                    window.history.pushState("", "", `/engagements/activity-center`);
                  }
                  dispatch(setLocationIdSlice({ locationId: e }));
                  persistor.pause();
                  persistor.flush().then(() => {
                    return persistor.purge();
                  });
                  dispatch(resetApiCacheStore({}));
                  setchanged(!changed);
                }}
                value={
                  location2?.length > 0
                    ? sessionStorage.getItem("currentLocationId")?.includes(",")
                      ? "all"
                      : sessionStorage.getItem("currentLocationId")?.toString().trim()
                    : ""
                }
                className={`${window.innerWidth >= 800 ? "min-w-[200px]" : "min-w-[100px]"}`}
              >
                {location2?.length > 1 && (
                  <Select.Option key="all" value="all">
                    {lang.all}
                  </Select.Option>
                )}
                {(location2?.sort(dynamicSort("title")) || []).map(item => (
                  <Select.Option
                    key={item?.id?.toString().trim()}
                    value={item?.id?.toString().trim()}
                  >
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            )}

            <div className="mr-3">
              <Badge
                count={notificationsCount ? notificationsCount.getUnreadNotificationsCount : 0}
              >
                <NotificationIcon
                  onClick={() => setNotificationDrawer(true)}
                  className="text-[24px] cursor-pointer mx-2 mt-[5px] text-[#64748B]"
                />
              </Badge>
            </div>
            <Drawer
              title="Notifications"
              open={notificationDrawer}
              className="notification-drawer"
              width={isMobileView ? "100%" : "30%"}
              onClose={() => setNotificationDrawer(false)}
            >
              <NotificationsDropdown
                notifications={notifications}
                setShowNotifModal={setShowNotifModal}
                setSelectedNotif={setSelectedNotif}
                getMoreNotifications={getMoreNotifications}
                hasMore={hasMore}
                markeNotifAsRead={markeNotifAsRead}
                setSelectedLeadPhoneToRedux={setSelectedLeadPhoneToRedux}
                locationIdslice={locationIdslice}
                refetchNotifications={refetchNotifications}
                notificationLoader={props.notificationLoader}
                refetchNotificationsCount={refetchNotificationsCount}
                onClose={() => setNotificationDrawer(false)}
                setNotifications={setNotifications}
              />
            </Drawer>
            {/* <div
              className="cursor-pointer"
              onClick={() =>
                handleProfileMenuClick({ key: "support" }, setShowSupportModal, deleteFcmDevice)
              }
            >
              <AntdIconV4
                className="text-[24px] mx-2 text-[#64748B]"
                title="Support"
                type="QuestionCircleOutlined"
              />
            </div> */}
            <div className="flex items-center gap-3">
              <Dropdown
                overlay={profileMenu(
                  setShowSupportModal,
                  deleteFcmDevice,
                  handleActiveLeadFilterOnLogout,
                  setLocationToNullOnLogout,
                  isPlatformAdminUser,
                  persistor,
                  dispatch
                )}
                trigger={["click"]}
                placement="bottomRight"
              >
                <div className="bg-[#F1F5F9] flex items-center p-1 pr-3 rounded-[100px] cursor-pointer">
                  <Avatar src={user.profilePic} size="small" className="mr-2">
                    {convertToInitials(userFullName)}
                  </Avatar>
                  {!isMobileView && <span className="text-sm">{userFullName}</span>}
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      )}

      <div
        className={`flex sticky${
          isFullScreen ? "top-[56px]" : "top-[5px]"
        }z-10 md:top-[0] md:relative`}
      >
        <NavigationContainer
          setContentStyle={setContentStyle}
          accessPayload={accessPayload}
          activeTab={titleMap[currentPath]}
        />
      </div>
      <div
        className={`flex-1 contentContainer ${!isMobileView && "py-3 pl-[0.5rem] pr-[6px]"} ${
          !isMobileView ? (contentStyle ? "md:pl-[11rem]" : "md:pl-[4rem]") : ""
        } ${isMobileView ? "h-[calc(100vh-40px)]" : "h-[calc(100vh-56px)]"}`}
        id="mainLayout"
      >
        {children}
      </div>
      {/* {showNotifModal && (
        <NotificationModal
          visible={showNotifModal}
          setVisible={setShowNotifModal}
          notification={selectedNotif}
          markAsRead={markeNotifAsRead}
        />
      )} */}
      {/* <Drawer
        title={
          <div className="text-lg" onClick={handleNotificationDrawer}>
            {lang.notifications}
          </div>
        }
        className="mobile-drawer"
        placement="left"
        width="100%"
        onClose={handleNotificationDrawer}
        open={notificationDrawer}
      >
        <MobileNotifications
          notifications={notifications}
          setShowNotifModal={setShowNotifModal}
          setSelectedNotif={setSelectedNotif}
          getMoreNotifications={getMoreNotifications}
          hasMore={hasMore}
          markeNotifAsRead={markeNotifAsRead}
          locationIdslice={locationIdslice}
          handleDispatch={() => {
            localStorage.removeItem("changedLocationLeadId");
            dispatch(setLocationIdSlice({ locationId }));
            handleNotificationDrawer();
          }}
          handleNotifMenuClick={handleNotifMenuClick}
          setSelectedLeadPhoneToRedux={setSelectedLeadPhoneToRedux}
          refetchNotifications={refetchNotifications}
        />
      </Drawer> */}
    </div>
  );
};

export default MainLayout;
