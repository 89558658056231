import React from "react";
import DialogFooter from "../../../commonComponents/dialogFooter";
import useLang from "../../../../hooks/useLang";
// import "../CSS/adminUser.css";

const AppointmentConfirDialog = props => {
  const { handleConfirm, handleCancelBtn, isModalVisible, handleOk, setIsAppoinmentCofirmLocal } =
    props;

  const [lang] = useLang();

  return (
    <div id="modalCss" className="modalWrapper">
      <p>Have you talked to customer to confirm the appointment?</p>
      <DialogFooter
        cancelBtnText={lang.no}
        confirmBtnText={lang.yes}
        handleConfirm={handleConfirm}
        handleCancel={handleCancelBtn}
      />
    </div>
  );
};

export default AppointmentConfirDialog;
