import React from "react";
import { Drawer } from "antd";
import DialogFooter from "./dialogFooter";
import { DrawerFooter } from "../../library/activityComponents";

const CustomDrawer = ({ data, setdata, content = <>No content</>, showFooter = true }) => {
  return (
    <Drawer
      open={data?.drawerVisible}
      width={data?.width}
      title={data?.title}
      onClose={() => setdata({ ...data, drawerVisible: false })}
    >
      <div className="max-h-[75vh] h-[75vh] overflow-auto">{content}</div>
      {showFooter && (
        <>
          <DrawerFooter />
          <div className="mt-auto h-[100vh] fixed bottom-[-630px] right-[30px]">
            <DialogFooter
              confirmBtnText="Save Changes"
              handleConfirm={() => console.log("Called")}
              loading={false}
              handleCancel={() => setdata({ ...data, drawerVisible: false })}
            />
          </div>
        </>
      )}
    </Drawer>
  );
};

export default CustomDrawer;
