/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    selectedPresetDate: "month",
  },
};

const campaignSlice = createSlice({
  name: "campaignSlicer",
  initialState,
  reducers: {
    setSelectedPresetDate(state, action) {
      state.filters.selectedPresetDate = action.payload;
    },
  },
});

export const { setSelectedPresetDate } = campaignSlice.actions;

export default campaignSlice.reducer;
