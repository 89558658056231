/* eslint-disable react/destructuring-assignment */
import React from "react";

export default function ViewPDF(props) {
  return (
    <div className="bg-transparent">
      <div className="text-center">
        <iframe src={props.pdfUrl} className="h-[80vh] w-[90vw]" title="PDF" />
      </div>
    </div>
  );
}
