/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-promise-executor-return */
import React, { useEffect, useState } from "react";
import { Modal, Tag, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/client";
import useLang from "../../../hooks/useLang";
import { getMobileView } from "../../../redux/slices/isMobile";
import { GET_AI_RESPONSE, GET_USE_CASES } from "../../../graphql/query";

const ConversationSummary = ({ isDisabled, selectedLead, user, summaryVisible, flex = true }) => {
  const [lang] = useLang();
  const [isShowConversationSummarySection, setIsShowConversationSummarySection] = useState(false);
  const [isSummaryModelOpen, setIsSummaryModelOpen] = useState(false);
  const [conversationSummaryText, setConversationSummaryText] = useState(
    lang.conversationSummaryInProgress
  );

  const [conversationSummaryTags, setConversationSummaryTags] = useState([]);

  const isMobileView = useSelector(state => getMobileView(state));

  useEffect(() => {
    if (isMobileView) {
      setIsShowConversationSummarySection(false);
    } else {
      setIsShowConversationSummarySection(true);
    }
  }, [isMobileView]);

  const { data: useCases } = useQuery(GET_USE_CASES);

  const [getAIResponse] = useLazyQuery(GET_AI_RESPONSE, {
    fetchPolicy: "network-only",
    variables: {
      companyId: +user?.company?.id,
      applicationUseCaseId: +useCases?.getApplicationUseCases?.filter(
        useCase => useCase.code === "ENGAGEMENT_CONVERSATION_SUMMARY"
      )[0]?.id,
      leadId: selectedLead?.id,
      regenerate: summaryVisible && summaryVisible?.count > 1,
      generateTasks: true,
    },
    onCompleted: res => {
      console.log("ConversationSummary:getAIResponse::onCompleted:res====>", res);
      setConversationSummaryText(res?.getAiResponse?.content);
      setConversationSummaryTags(res?.getAiResponse?.futureTasks);
      if (res?.getAiResponse?.statusCode === 200) {
        setConversationSummaryText(res?.getAiResponse?.content);
        setConversationSummaryTags(res?.getAiResponse?.futureTasks);
      } else {
        setConversationSummaryText(lang.ottoAiSummaryFailedText);
        setConversationSummaryTags([]);
      }
    },
    onError: err => {
      console.log("ConversationSummary:getAIResponse::onError:res====>", err);
      setConversationSummaryText(lang.conversationSummaryFailed);
      setConversationSummaryTags([]);
    },
  });

  useEffect(() => {
    setConversationSummaryTags([]);
  }, [summaryVisible && summaryVisible?.count]);

  // Summarize conversation for selected lead
  const summarizeConversation = async () => {
    console.log("ConversationSummary:summarizeConversation for selectedLead", selectedLead);
    setConversationSummaryText(lang.conversationSummaryInProgress);
    if (!isShowConversationSummarySection) {
      setIsShowConversationSummarySection(true);
    }
    console.log("ConversationSummary:summarizeConversation:calling getAIResponse...");
    await getAIResponse();
  };

  useEffect(() => {
    console.log("ConversationSummary:useEffect:summaryVisible?.count", summaryVisible?.count);
    if (!isDisabled) {
      summarizeConversation();
    }
  }, [summaryVisible?.count]);

  return (
    <div className={isMobileView ? "w-full" : "w-full flex-col"}>
      <div className={`${flex && "flex"} items-center`}>
        {isShowConversationSummarySection && (
          <div className="flex flex-col mt-2 p-4 w-full border-[1px] rounded-[4px] border-grey-200 border-solid bg-grey-200">
            <div>{conversationSummaryText}</div>
            <div className="flex flex-row flex-wrap">
              {conversationSummaryTags?.map((tag, i) => (
                <Tooltip key={i} title={tag}>
                  <Tag className="mt-2 bg-[#cbd5e1] text-grey-700 rounded-[2px]">
                    <div>{tag}</div>
                  </Tag>
                </Tooltip>
              ))}
            </div>
          </div>
        )}
      </div>
      {isSummaryModelOpen && isMobileView && (
        <Modal
          title={lang.conversationsummery}
          open={isSummaryModelOpen}
          onCancel={() => {
            setIsSummaryModelOpen(false);
            setIsShowConversationSummarySection(false);
          }}
          maskClosable={false}
          footer={null}
        >
          <p>{conversationSummaryText}</p>
          <p>
            {conversationSummaryTags?.map(tag => (
              <Tag key={tag}>
                <span className="table whitespace-normal">{tag}</span>
              </Tag>
            ))}
          </p>
        </Modal>
      )}
    </div>
  );
};

export default ConversationSummary;
