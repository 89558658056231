/* eslint-disable no-unreachable */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useQuery } from "@apollo/client";
import { List, Tag } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../amplify/authenticator-provider";
import { GET_CAMPAIGN_PAGINATED_LIST } from "../../../graphql/query";
import useLang from "../../../hooks/useLang";
import { AccessPermissionAction } from "../../../library/constants";
import { checkAccessPermission } from "../../../library/helpers";
import { firstUpperCaseWordParser } from "../../../library/utils";
import { CampaignItem } from "./CampaignItem";
import { CrashAlert } from "../../commonComponents/crashAlert";

const ListView = props => {
  try {
    const {
      key,
      onSetCampaignDetailsView,
      activeInd,
      onCampaignBySource,
      sources,
      search,
      statusData,
    } = props;
    const [variables, setVariables] = useState({
      page: 1,
      pageSize: 100,
    });
    const [lang] = useLang();
    const {
      data: resp,
      refetch: refetchCampaigns,
      loading,
    } = useQuery(GET_CAMPAIGN_PAGINATED_LIST, {
      fetchPolicy: "network-only",
      variables: {
        ...variables,
        status: activeInd || null,
        search,
      },
    });
    const campaigns = resp?.getCampaigns?.data;

    const handleTitleClick = item => {
      onSetCampaignDetailsView({ item: item, resp });
    };

    const canView = checkAccessPermission(AccessPermissionAction.CAMPAIGN_VIEW);

    // measuring how many columns should be render in one row
    const [columnGrid, setColumnGrid] = useState(1);
    const memoizedGrid = useMemo(() => columnGrid, [columnGrid]);

    const handleGrids = () => {
      if (window.innerWidth > 1333) {
        setColumnGrid(2);
      } else if (window.innerWidth >= 1033 && window.innerWidth < 1333) {
        setColumnGrid(2);
      } else if (window.innerWidth <= 1024 && window.innerWidth > 800) {
        setColumnGrid(1);
      } else if (window.innerWidth <= 800 && window.innerWidth > 510) {
        setColumnGrid(1);
      } else {
        setColumnGrid(1);
      }
    };

    useEffect(() => {
      handleGrids();
      window.addEventListener("resize", handleGrids);

      return () => {
        window.removeEventListener("resize", handleGrids);
      };
    }, []);

    return (
      <List
        key={key}
        grid={{
          column: memoizedGrid,
        }}
        dataSource={campaigns}
        loading={{ spinning: loading, tip: `Loading ${activeInd || "All"} Campaigns...` }}
        pagination={
          resp?.getCampaigns?.count < 5
            ? null
            : {
                current: variables.page,
                pageSize: variables.pageSize,
                total: resp?.getCampaigns?.count || 0,
                showTotal: total =>
                  `${lang.total}: ${total} ${
                    total === 1 ? lang.campaign.toLowerCase() : lang.campaigns.toLowerCase()
                  }`,
                pageSizeOptions: ["5", "25", "50", "100"],
                showSizeChanger: true,
                onChange: (page, pageSize) => {
                  const newVariables = {
                    ...variables,
                    page: page,
                    pageSize: pageSize,
                  };
                  setVariables(newVariables);
                },
                onShowSizeChange: (current, size) => {
                  const newVariables = {
                    ...variables,
                    page: current,
                    pageSize: size,
                  };
                  setVariables(newVariables);
                },
              }
        }
        renderItem={(item, index) => {
          return (
            <List.Item key={index} className="mr-1">
              <CampaignItem
                index={index}
                handleTitleClick={handleTitleClick}
                item={item}
                canView={canView}
                resp={resp}
                lang={lang}
                refetch={refetchCampaigns}
                sources={sources}
                onCampaignBySource={onCampaignBySource}
                search={search}
                statusData={statusData}
              />
            </List.Item>
          );
        }}
      />
    );
  } catch (err) {
    console.error(err);
    return <CrashAlert />;
  }
};

export default ListView;

export const TextLabel = ({ label, value }) => {
  return (
    <div className="flex flex-row items-center">
      <div className="text-xs font-[600] text-[#64748B]">{label}</div>{" "}
      <span className="mx-1">:</span>
      <div className="text-sm font-[400] text-grey-700">{value}</div>
    </div>
  );
};
/**
 *
 * @param {item} Campaign item
 * @returns React.JSX.Element
 */
export const StatusTag = ({ item }) => {
  try {
    /**
     * @returns backgroundColor & color
     */
    const color = () => {
      switch (item.activeInd.toLowerCase()) {
        case "active":
          return {
            backgroundColor: "#10B981",
            color: "white",
          };
          break;
        case "inactive":
          return {
            backgroundColor: "#64748B",
            color: "white",
          };
        case "paused":
          return {
            backgroundColor: "#F59E0B",
            color: "white",
          };
          break;
        case "complete":
          return {
            backgroundColor: "#3B82F6",
            color: "white",
          };
          break;
        case "draft":
          return {
            backgroundColor: "#E2E8F0",
            color: "#334155",
          };
        default:
          break;
      }
    };
    return (
      <Tag style={color()} className="rounded-[100px] flex flex-row items-center gap-1">
        <span className="w-2 h-2 rounded-full m-2" style={{ backgroundColor: color()?.color }} />{" "}
        {firstUpperCaseWordParser(item.activeInd)}
      </Tag>
    );
  } catch (err) {
    console.error(err);
    return <CrashAlert />;
  }
};
