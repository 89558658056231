/* eslint-disable no-lonely-if */
/* eslint-disable no-useless-return */
/* eslint-disable no-debugger */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
import { DeleteOutlined, PlusOutlined, StarOutlined, UndoOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Alert,
  Button,
  Cascader,
  Dropdown,
  Form,
  Image,
  Input,
  Select,
  Skeleton,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import _, { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserContext } from "../../amplify/authenticator-provider";
import {
  GET_ALL_USERS,
  GET_FILTER_USERS,
  GET_LOCATION_VEHICLE_INVENTORY,
  GET_OPPORTUNITY_STATUS,
  GET_OPPORTUNITTIES_TYPES,
  GET_OPPORTUNITY_USER_TYPE,
} from "../../graphql/query";
import { firstUpperCaseWordParser } from "../../library/utils";
import { InventoryVehicleForm, VINVehicleForm, YearMakeModelJSX } from "./vehicleForms";
import UserSelector from "../commonComponents/userSelector";
import { checkAccessPermission } from "../../library/helpers";
import { AccessPermissionAction } from "../../library/constants";
import useCrmIntegration from "../../hooks/useCrmIntegration";

export default function LeadOpportunityInformationForm({
  leadData,
  setleadData,
  lead = {},
  mode,
  form,
  selectedOpportunity,
  setselectedOpportunity,
  newOpportunity,
  setnewOpportunity,
  showOpportunityDropdown = true,
  loader,
  defaultOpportunity,
  leadSources,
  crmIntegration,
  leadSourcesLoader,
}) {
  const [opportunityStatusList, setOpportunityStatusList] = useState([]);
  const [opportunityTypes, setopportunityTypes] = useState([]);
  const [userTypes, setuserTypes] = useState([]);
  const [userSearch, setuserSearch] = useState("");
  const [userList, setuserList] = useState([]);
  const { user } = useContext(UserContext);
  const { data: vdpData, loading: vdpLoader } = useQuery(GET_LOCATION_VEHICLE_INVENTORY, {
    variables: {
      locationId: sessionStorage.getItem("currentLocationId"),
      // search,
    },
  });
  const [noError, setNoError] = useState(true);
  const [getUserTypes, { loading: userTypesLoader }] = useLazyQuery(GET_OPPORTUNITY_USER_TYPE, {
    fetchPolicy: "network-only",
    variables: {
      locationIds: sessionStorage?.getItem("currentLocationId")?.split(","),
    },
    onCompleted: res => {
      setuserTypes(res.getOpportunityUserTypes);
    },
    onError: () => {
      setNoError(false);
    },
  });
  const { loading: opportunityTypesLoader } = useQuery(GET_OPPORTUNITTIES_TYPES, {
    variables: {
      industryId: 1,
    },
    onCompleted: res => {
      setopportunityTypes(res.getOpportunityTypes);
    },
  });
  const canAddEditOpportunity = checkAccessPermission(
    AccessPermissionAction.LEAD_OPPORTUNITY_MANAGEMENT_ADD_EDIT
  );
  const { loading: userLoader } = useQuery(GET_FILTER_USERS, {
    variables: {
      companyId: user.company.id,
      search: userSearch,
    },
    onCompleted: res => {
      setuserList(res.getUsers.data);
    },
  });
  const { loading: opportunityStatusLoader } = useQuery(GET_OPPORTUNITY_STATUS, {
    variables: {
      locationIds: sessionStorage?.getItem("currentLocationId")?.split(","),
      fetchAll: true,
    },
    onCompleted: resp => {
      const list = (resp?.getOpportunityStatus || []).map(e => ({
        name: e?.parentStatus ? `${e.parentStatus.name} / ${e?.name}` : e?.name.replace(/_/g, " "),
        id: e?.id,
      }));
      setOpportunityStatusList(list);
    },
  });
  useEffect(() => {
    // if (isEmpty(lead)) {
    if (user.company.id) getUserTypes();
    // }
  }, [user.company.id]);

  const items = [
    {
      key: "inventory",
      label: "Pick From Inventory",
    },
    {
      key: "vin",
      label: "Enter vin",
    },
    {
      key: "ymm",
      label: "Enter Year Make Model",
    },
  ];
  const onMenuClick = (e, setState = setleadData) => {
    setState(prev => {
      const temp = structuredClone(prev);
      temp.vehiclesOfInterest.push({
        inputType: e.key,
        customerInterest: e.key === "inventory" ? "BUY" : "",
      });
      return temp;
    });
  };

  const InterestDropdown = ({ index, state = leadData, setState = setleadData }) => (
    <Form.Item
      rules={[{ required: true, message: "Please select Interest" }]}
      name={`vehiclesOfInterest[${index}].customerInterest`}
      className="w-1/4"
      label="Interest"
    >
      <Select
        className="vehicle-selector"
        name={`vehiclesOfInterest[${index}].customerInterest`}
        placeholder="Interest"
        value={state?.vehiclesOfInterest[index]?.customerInterest || null}
        onChange={value =>
          setState(prev => {
            const temp = structuredClone(prev);
            temp.vehiclesOfInterest[index].customerInterest = value;
            return temp;
          })
        }
      >
        {["BUY", "SELL", "TRADE", "NONE"].map((item, index) => (
          <Select.Option key={index} value={item}>
            {firstUpperCaseWordParser(item)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
  const currentYear = dayjs().year();

  const years = Array.from({ length: 30 }, (_, index) => currentYear - index);

  // if mode is edit,selectedOpportunity is not null, and newopportunity is false, then manage the state of selectedOpportunity
  return (
    <div className="flex flex-wrap">
      <span className="bg-[#F8FAFC] p-2 w-full flex flex-row justify-between items-center text-sm font-[600] text-grey-700 ">
        Opportunity Information
        {mode === "EDIT" && (
          <div className="flex flex-row items-center gap-1">
            <Switch
              disabled={!canAddEditOpportunity}
              onChange={checked => {
                setnewOpportunity(checked);
                if (checked) {
                  setselectedOpportunity({ leadOpportunityUsers: [], vehiclesOfInterest: [] });
                  form.resetFields();
                  form.setFieldValue("location", sessionStorage.getItem("currentLocationId"));
                } else {
                  setselectedOpportunity(defaultOpportunity);
                  const activeUsers = (defaultOpportunity?.leadOpportunityUsers || []).filter(
                    user => user.isActive
                  );
                  const ownerObj = {};
                  for (let index = 0; index < activeUsers.length; index++) {
                    const element = activeUsers[index];
                    ownerObj[`leadOpportunityUsers-${element.id}`] = element.userId;
                  }
                  form.setFieldsValue({
                    ...defaultOpportunity,
                    opportunityName: defaultOpportunity?.name,
                    location: defaultOpportunity?.location?.id,
                    opportunityTypeId: +defaultOpportunity?.opportunityTypeId,
                    opportunitySourceId: +defaultOpportunity?.opportunitySourceId,
                    opportunityStatusId: +defaultOpportunity?.opportunityStatusId,
                    // ...vehicleObj,
                    ...ownerObj,
                  });
                }
              }}
              checked={newOpportunity}
            />{" "}
            New Opportunity
          </div>
        )}
      </span>
      <OpportunityForm
        mode={mode}
        newOpportunity={newOpportunity}
        state={mode === "EDIT" ? selectedOpportunity : leadData}
        setState={mode === "EDIT" ? setselectedOpportunity : setleadData}
        form={form}
        opportunityTypes={opportunityTypes}
        leadStatusList={opportunityStatusList}
        leadSources={leadSources}
        userLoader={userLoader}
        leadSourcesLoader={leadSourcesLoader}
        opportunityStatusLoader={opportunityStatusLoader}
        opportunityTypesLoader={opportunityTypesLoader}
        userTypesLoader={userTypesLoader}
        userTypes={userTypes}
        userList={userList}
        items={items}
        onMenuClick={onMenuClick}
        InterestDropdown={InterestDropdown}
        vehicleInventoryLoader={vdpLoader}
        vehicleInventoryList={vdpData?.getVehicleInventory?.data || []}
        years={years}
        lead={lead}
        noError={noError}
        setNoError={setNoError}
        showOpportunityDropdown={showOpportunityDropdown}
        defaultOpportunity={defaultOpportunity}
        crmIntegration={crmIntegration}
        canAddEditOpportunity={canAddEditOpportunity}
      />

      {mode === "EDIT" && (
        <div className="bg-[#F8FAFC] mt-2 w-full flex justify-end gap-2 p-4 ">
          <Button
            disabled={!canAddEditOpportunity}
            loading={loader}
            type="primary"
            onClick={() => form.submit()}
          >
            Save Opportunity Info
          </Button>
        </div>
      )}
    </div>
  );
}
function OpportunityForm({
  mode,
  newOpportunity,
  state,
  setState,
  form,
  opportunityTypes,
  leadStatusList,
  leadSources,
  userTypesLoader,
  userTypes,
  userList,
  items,
  onMenuClick,
  InterestDropdown,
  vehicleInventoryLoader,
  years,
  lead,
  vehicleInventoryList,
  showOpportunityDropdown,
  noError,
  setNoError,
  defaultOpportunity,
  leadSourcesLoader,
  userLoader,
  canAddEditOpportunity,
  opportunityTypesLoader,
  opportunityStatusLoader,
}) {
  const crmIntegration = useCrmIntegration();
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (mode === "ADD" && newOpportunity) {
      if (sessionStorage.getItem("currentLocationId").includes(",")) {
        form.setFieldsValue({ location: null });
      }
    } else {
      if (sessionStorage.getItem("currentLocationId").includes(",")) {
        form.setFieldsValue({ location: null });
      }
      // form.setFieldsValue({ location: lead?.location?.id || lead?.lead?.location?.id });
    }
  }, [newOpportunity, lead, mode]);
  const handleOpportunityChange = value => {
    const opprtunity = lead?.leadOpportunities?.find(item => item.id === value);
    setState(opprtunity);
    const ownerObj = {};
    for (let index = 0; index < opprtunity.leadOpportunityUsers.length; index++) {
      const element = opprtunity.leadOpportunityUsers[index];
      ownerObj[`leadOpportunityUsers-${element.id}`] = element.userId;
    }
    form.setFieldsValue({
      ...opprtunity,
      opportunityName: opprtunity.name,
      location: opprtunity.location.id,
      opportunityTypeId: +opprtunity.opportunityTypeId,
      opportunitySourceId: +opprtunity.opportunitySourceId,
      opportunityStatusId: +opprtunity.opportunityStatusId,
      ...ownerObj,
    });
  };
  const DeleteIcon = ({ index, settempLeadData, className = "" }) => (
    <DeleteOutlined
      disabled={!canAddEditOpportunity}
      className={`text-red-500 cursor-pointer ${className}`}
      onClick={() => {
        settempLeadData(prev => {
          const temp = structuredClone(prev);
          if (_.has(temp.vehiclesOfInterest[index], "id")) {
            temp.vehiclesOfInterest[index] = {
              ...temp.vehiclesOfInterest[index],
              isDeleted: true,
            };
          } else {
            temp.vehiclesOfInterest.splice(index, 1);
          }
          return temp;
        });
      }}
    />
  );
  const leadUserTypeIds = (state?.leadOpportunityUsers || [])
    .map(item => +item?.userTypeId || null)
    .sort();
  const userTypeIds = (userTypes || []).map(item => +item.id).sort();
  useEffect(() => {
    if (mode === "EDIT") {
      setNoError(_.isEqual(leadUserTypeIds, userTypeIds));
    }
  }, [mode, userTypes]);
  const handleOpportunityId = lead => {
    if (crmIntegration === "DS") {
      // handle DS opportuinty Id
      return `${
        (lead?.dsOpportunityEvents &&
          lead?.dsOpportunityEvents.length > 0 &&
          `#${lead?.dsOpportunityEvents[0]?.dsEventId}`) ||
        ""
      }`;
    }
    if (crmIntegration === "SF") {
      return `${
        (lead?.sfLeadOpportunities &&
          lead?.sfLeadOpportunities.length > 0 &&
          `#${lead?.sfLeadOpportunities[0]?.sfOpportunityId}`) ||
        ""
      }`;
    }
    if (crmIntegration === "VIN") {
      return `${
        (lead?.vsLeadOpportunities &&
          lead?.vsLeadOpportunities.length > 0 &&
          `#${lead?.vsLeadOpportunities[0]?.vsLeadId}`) ||
        ""
      }`;
    }
    if (crmIntegration === "EL") {
      return `${
        (lead.elLeadOpportunities &&
          lead.elLeadOpportunities.length > 0 &&
          `#${lead.elLeadOpportunities[0].elOpportunityId}`) ||
        ""
      }`;
    }
    return `SMAI #${lead.id}`;
  };
  return (
    <div className="flex justify-between w-full flex-wrap">
      {mode === "EDIT" && !newOpportunity && showOpportunityDropdown && (
        <Form.Item className="w-full" label="Select Opportunity">
          <Select value={state.id} onChange={value => handleOpportunityChange(value)}>
            {(lead?.leadOpportunities || []).map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name} ({handleOpportunityId(item)})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        rules={[{ required: true, message: "Please select a location" }]}
        className="w-full"
        name="location"
        label="Location"
      >
        <Select
          name="location"
          value={state.location}
          onChange={value => setState({ ...state, location: value })}
          disabled={
            !canAddEditOpportunity && newOpportunity
              ? false
              : !sessionStorage.getItem("currentLocationId").includes(",") || mode === "EDIT"
          }
        >
          {(user.locations || []).map((item, index) => (
            <Select.Option key={index} value={item?.location?.id}>
              {item?.location?.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className="w-full"
        name="opportunityName"
        rules={[
          {
            required: mode === "EDIT" && !newOpportunity,
            message: "Opportunity Name is required",
          },
        ]}
        label="Opportunity Name"
      >
        <Input
          disabled={!canAddEditOpportunity}
          name="opportunityName"
          // value={state.opportunityName}
          onChange={e => setState({ ...state, opportunityName: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        className="w-full"
        name="opportunityTypeId"
        rules={[{ required: true, message: "Opportunity Type is required" }]}
        label="Opportunity Type"
      >
        <Select
          disabled={!canAddEditOpportunity}
          name="opportunityTypeId"
          value={opportunityTypesLoader ? null : state.opportunityTypeId}
          loading={opportunityTypesLoader}
          onChange={value => setState({ ...state, opportunityTypeId: value })}
        >
          {opportunityTypes.map((item, index) => (
            <Select.Option key={index} value={+item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className="w-full"
        name="opportunityStatusId"
        rules={[{ required: true, message: "Opportunity Status is required" }]}
        label="Opportunity Status"
      >
        <Select
          disabled={!canAddEditOpportunity}
          name="opportunityStatusId"
          value={opportunityStatusLoader ? null : state.opportunityStatusId}
          loading={opportunityStatusLoader}
          onChange={value => setState({ ...state, opportunityStatusId: value })}
        >
          {(leadStatusList || []).map((item, index) => (
            <Select.Option key={index} value={+item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className="w-full"
        name="opportunitySourceId"
        rules={[{ required: true, message: "Opportunity Source is required" }]}
        label="Opportunity Source"
      >
        {/* <Select
          name="opportunitySourceId"
          value={state.opportunitySourceId}
          onChange={value => setState({ ...state, opportunitySourceId: value })}
          // onChange={value => console.log(value)}
        >
          {(leadSources || []).map((item, index) => (
            <Select.Option key={index} value={+item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select> */}
        <Cascader
          name="opportunitySourceId"
          disabled={!canAddEditOpportunity}
          loading={leadSourcesLoader}
          // value={[+state.opportunitySourceId]}
          fieldNames={{ label: "name", value: "key", children: "childs" }}
          expandTrigger="hover"
          options={leadSources}
          changeOnSelect
          onChange={value => {
            if (value && value.length > 0)
              setState({ ...state, opportunitySourceId: value[value.length - 1] });
            else {
              setState({ ...state, opportunitySourceId: null });
            }
          }}
        />
      </Form.Item>
      <span className="bg-[#F8FAFC] p-2 w-full text-sm font-[600] text-grey-700">
        Opportunity Users
      </span>
      <div className="flex flex-col w-full ">
        {userTypesLoader ? (
          <Skeleton active />
        ) : newOpportunity || mode === "ADD" ? (
          (userTypes || []).map((item, index) => (
            <UserSelector
              required={isEmpty(item.required) ? false : item.required}
              key={index}
              placeholder={`Select ${item.name}`}
              handleUsersSelection={value =>
                setState(prev => {
                  const temp = [...prev.leadOpportunityUsers];
                  temp[index] = {
                    ...temp[index],
                    userTypeId: +item.id,
                    userId: value,
                    userType: item.name,
                  };
                  return { ...prev, leadOpportunityUsers: temp };
                })
              }
              allowClear
              multiple={false}
              showAll={false}
              disabled={!canAddEditOpportunity}
              validation
              item={item}
            />
          ))
        ) : (
          (state?.leadOpportunityUsers || [])
            .filter(el => el.isActive)
            .map((item, index) => (
              <UserSelector
                key={index}
                required={isEmpty(item.required) ? false : item.required}
                name={`leadOpportunityUsers-${item.id}`}
                placeholder={`Select ${item.userType.name}`}
                handleUsersSelection={value =>
                  setState(prev => {
                    const temp = [...prev.leadOpportunityUsers];
                    temp[index] = {
                      ...temp[index],
                      userId: value,
                    };
                    return { ...prev, leadOpportunityUsers: temp };
                  })
                }
                allowClear
                multiple={false}
                showAll={false}
                disabled={!canAddEditOpportunity}
                validation
                item={{ name: item.userType.name, item: item }}
              />
            ))
        )}
      </div>
      <div className="bg-[#F8FAFC] flex flex-row justify-between items-center p-2 w-full">
        <span className="text-sm font-[600] text-grey-700">Opportunity Vehicles</span>
        <Dropdown
          menu={{
            items,
            onClick: e => onMenuClick(e, setState),
          }}
          disabled={!canAddEditOpportunity}
        >
          <Button type="primary" className="p-2 flex flex-row items-center">
            <PlusOutlined />
          </Button>
        </Dropdown>
      </div>
      <div className="flex flex-col w-full gap-2 mt-2">
        {(state?.vehiclesOfInterest || [])?.map((item, index) =>
          item.isDeleted ? (
            <Alert
              type="info"
              showIcon
              message={
                <>
                  <span className="font-[600]">
                    {item.year || ""} {item.make || ""} {item.model || ""}
                  </span>
                  <span> will be deleted when you save the opportunity</span>
                </>
              }
              key={index}
              closable
              closeIcon={
                <Tooltip title="Undo delete">
                  <UndoOutlined
                    onClick={() =>
                      setState(prev => {
                        const temp = structuredClone(prev);
                        temp.vehiclesOfInterest[index] = {
                          ...temp.vehiclesOfInterest[index],
                          isDeleted: false,
                        };
                        return temp;
                      })
                    }
                    className="text-[#1677ff]"
                  />
                </Tooltip>
              }
            />
          ) : (
            <div key={index} className="w-full">
              {item.id ? (
                <div
                  key={index}
                  className="border-[1px] border-grey-200 border-solid  rounded-lg flex flex-row justify-between items-center gap-2 py-4 pl-6"
                >
                  <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-col items-center justify-center gap-2">
                      <Image
                        src="https://imgd.aeplcdn.com/0X0/n/cw/ec/51378/s5-sportback-exterior-right-front-three-quarter-5.jpeg?q=80"
                        height="48px"
                        width="48px"
                        className="rounded-full"
                      />
                      <Tag className="bg-brand-500 text-white border-none">
                        {item.customerInterest}
                      </Tag>
                    </div>
                    <div className="flex flex-col items-start">
                      <span className="text-sm font-[600] text-grey-700">
                        {item.year || ""} {item.make || ""} {item.model || ""}
                      </span>
                      <span className="text-xs font-[400] text[#64748B]">
                        {item.vin || "VIN not available"}
                      </span>
                      <span className="text-xs font-[400] text[#64748B]">
                        {item.stockNumber || "Stock Number not available"}
                      </span>
                      <span className="text-xs font-[400] text[#64748B]">
                        {item.budget || "Budget not available"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-3 mr-3">
                    <StarOutlined
                      disabled={!canAddEditOpportunity}
                      onClick={() =>
                        setState(prev => {
                          const temp = structuredClone(prev);
                          const prevIndex = temp.vehiclesOfInterest.findIndex(
                            item => item?.isPrimary
                          );
                          if (prevIndex !== -1) {
                            temp.vehiclesOfInterest[prevIndex].isPrimary = false;
                          }
                          temp.vehiclesOfInterest[index].isPrimary = true;
                          return temp;
                        })
                      }
                      className={`scale-150 ${
                        item.isPrimary ? "text-yellow-400" : "bg-transparent"
                      }`}
                    />
                    <DeleteIcon className="scale-150" index={index} settempLeadData={setState} />
                  </div>
                </div>
              ) : item.inputType === "inventory" ? (
                <InventoryVehicleForm
                  InterestDropdown={
                    <InterestDropdown index={index} setState={setState} state={state} />
                  }
                  index={index}
                  vehicleInventoryLoader={vehicleInventoryLoader}
                  DeleteIcon={<DeleteIcon index={index} settempLeadData={setState} />}
                  leadData={state}
                  vehicleInventoryList={vehicleInventoryList}
                  setleadData={setState}
                />
              ) : item.inputType === "vin" ? (
                <VINVehicleForm
                  InterestDropdown={
                    <InterestDropdown index={index} setState={setState} state={state} />
                  }
                  setState={setState}
                  state={state}
                  index={index}
                  item={item}
                  // setvehicles={setvehicles}
                  DeleteIcon={<DeleteIcon index={index} settempLeadData={setState} />}
                  leadData={state}
                  setleadData={setState}
                />
              ) : (
                <YearMakeModelJSX
                  setState={setState}
                  state={state}
                  DeleteIcon={<DeleteIcon index={index} settempLeadData={setState} />}
                  index={index}
                  InterestDropdown={
                    <InterestDropdown index={index} setState={setState} state={state} />
                  }
                  item={item}
                  years={years}
                  key={index}
                  leadData={state}
                  setleadData={setState}
                />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
}
