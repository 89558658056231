import { gql } from "@apollo/client";

export const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $name: String!
    $phone: String
    $address: String
    $city: String
    $state: String
    $postalCode: String
    $country: String
    $timezone: String
    $locationLink: String
    $isOptinConsentMethod: Boolean
    $isOpenCrmWarningPopup: Boolean
    $profilePic: String
  ) {
    createCompany(
      name: $name
      phone: $phone
      address: $address
      city: $city
      state: $state
      postalCode: $postalCode
      country: $country
      timezone: $timezone
      locationLink: $locationLink
      isOptinConsentMethod: $isOptinConsentMethod
      isOpenCrmWarningPopup: $isOpenCrmWarningPopup
      profilePic: $profilePic
    ) {
      company {
        id
        name
        phone
        address
        city
        state
        postalCode
        country
        timezone
        locationLink
        isGlobalCrm
        isOpenCrmWarningPopup
        profilePic
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UPDATECompany(
    $id: Int!
    $name: String!
    $phone: String!
    $address: String!
    $city: String!
    $state: String!
    $postalCode: String!
    $country: String!
    $industry: String
    $website: String
    $addressDetail: String
    $isDisabled: Boolean
    $automaticEngagement: Boolean
    $timezone: String
    $locationLink: String
    $isOptinConsentMethod: Boolean
    $isOpenCrmWarningPopup: Boolean
    $profilePic: String
  ) {
    updateCompany(
      id: $id
      name: $name
      phone: $phone
      address: $address
      city: $city
      state: $state
      postalCode: $postalCode
      country: $country
      industry: $industry
      website: $website
      addressDetail: $addressDetail
      isDisabled: $isDisabled
      automaticEngagement: $automaticEngagement
      timezone: $timezone
      locationLink: $locationLink
      isOptinConsentMethod: $isOptinConsentMethod
      isOpenCrmWarningPopup: $isOpenCrmWarningPopup
      profilePic: $profilePic
    ) {
      ok
      company {
        id
        name
        phone
        address
        city
        state
        postalCode
        country
        website
        industry
        addressDetail
        isDisabled
        dateCreated
        userCount
        automaticEngagement
        timezone
        locationLink
        isOptinConsentMethod
        isGlobalCrm
        isOpenCrmWarningPopup
        profilePic
      }
    }
  }
`;
export const GET_REFRESH_TOKEN = gql`
  mutation generateAccessToken($refreshToken: String!) {
    generateAccessToken(refreshToken: $refreshToken) {
      statusCode
      message
      accessToken
    }
  }
`;
export const ADD_USER = gql`
  mutation CreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $companyId: Int
    $roleId: Int
    $phone: String
    $locationId: Int!
    $locationIds: [Int]
    $companyRoleId: Int
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      companyId: $companyId
      roleId: $roleId
      phone: $phone
      locationId: $locationId
      locationIds: $locationIds
      companyRoleId: $companyRoleId
    ) {
      statusCode
      message
      user {
        id
        email
        fullName
        phone
        companyRoleId
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: Int!
    $firstName: String!
    $lastName: String!
    $email: String
    $oldPass: String
    $newPass: String
    $roleId: Int
    $companyId: Int
    $phone: String
    $locationId: Int
    $locationIds: [Int]
    $companyRoleId: Int
    $permissionActionIds: [Int]
    $profilePic: String
    $preferredLanguageCode: String
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      oldPass: $oldPass
      newPass: $newPass
      roleId: $roleId
      companyId: $companyId
      phone: $phone
      locationId: $locationId
      locationIds: $locationIds
      companyRoleId: $companyRoleId
      permissionActionIds: $permissionActionIds
      profilePic: $profilePic
      preferredLanguageCode: $preferredLanguageCode
    ) {
      statusCode
      message
      user {
        id
        email
        fullName
        phone
        companyRoleId
      }
    }
  }
`;

export const UPDATE_USER_FILTERS = gql`
  mutation UpdateUserFilters(
    $filters: [FilterInputs]!
    $userId: Int
    $setType: String
    $isDynamicFilter: Boolean
    $isRemoved: Boolean
  ) {
    updateUserFilters(
      filters: $filters
      userId: $userId
      setType: $setType
      isDynamicFilter: $isDynamicFilter
      isRemoved: $isRemoved
    ) {
      userFilters {
        id
        value
        type
        typeName
        companyFilterId
        filterType {
          isDynamic
        }
      }
    }
  }
`;
export const CREATE_USAGE_EVENT = gql`
  mutation CreateUsageEvent($verb: String!, $context: String, $duration: Float!, $eventId: Int) {
    createUsageEvent(verb: $verb, context: $context, duration: $duration, eventId: $eventId) {
      event {
        id
        timestamp
        verb
        subjectId
        context
        duration
      }
    }
  }
`;

export const SAVE_USER_FILTER_SET = gql`
  mutation SaveUserFilterSet($name: String!, $setType: String!) {
    saveUserFilterSet(name: $name, setType: $setType) {
      filterSet {
        id
        userId
        name
        setType
      }
    }
  }
`;

export const UPDATE_USER_FILTER_SET = gql`
  mutation UpdateUserFilterSet($delete: Boolean, $id: Int!, $name: String!, $setType: String!) {
    updateUserFilterSet(delete: $delete, id: $id, name: $name, setType: $setType) {
      ok
    }
  }
`;

export const SELECT_FILTER_SET = gql`
  mutation SelectFilterSet($id: Int!, $setType: String!) {
    selectFilterSet(filterSetId: $id, setType: $setType) {
      userFilters {
        type
        typeName
        value
        companyFilterId
        id
        setType
      }
    }
  }
`;

export const PROSPECT_ACTION = gql`
  mutation ProspectAction($action: String!, $id: Int!, $postType: String) {
    prospectAction(action: $action, aingineDataId: $id, postType: $postType) {
      ok
    }
  }
`;

export const UPDATE_DEAL = gql`
  mutation UpdateDeal(
    $email: String
    $firstName: String
    $followupDate: DateTime
    $id: Int!
    $lastName: String
    $location: String
    $phone: String
    $status: String
    $strength: String
    $userId: Int
    $allowNotifications: Boolean
  ) {
    updateDeal(
      email: $email
      firstName: $firstName
      followupDate: $followupDate
      id: $id
      lastName: $lastName
      location: $location
      phone: $phone
      status: $status
      strength: $strength
      userId: $userId
      allowNotifications: $allowNotifications
    ) {
      ok
      deal {
        id
        userId
        companyId
        aingineDataId
        screenName
        firstName
        lastName
        email
        phone
        location
        url
        profileUrl
        source
        strength
        status
        dateCreated
        followupDate
        subscribed
        allowNotifications
        tags
        conversations {
          id
          type
          message
          postTime
          aingineUserId
          aingineDataId
        }
      }
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation AddDealComment($dealId: Int!, $message: String!) {
    addDealComment(dealId: $dealId, message: $message) {
      ok
      deal {
        id
        userId
        companyId
        aingineDataId
        screenName
        firstName
        lastName
        email
        phone
        location
        url
        profileUrl
        source
        strength
        status
        dateCreated
        followupDate
        conversations {
          id
          type
          message
          postTime
          aingineUserId
          aingineDataId
        }
      }
    }
  }
`;
export const SAVE_FILTER_TYPE = gql`
  mutation SaveFilterType($id: Int, $name: String!, $type: String!, $filterField: String!) {
    saveFilterType(id: $id, name: $name, type: $type, filterField: $filterField) {
      ok
      filterType {
        id
        name
        type
        filterField
        optionsCount
      }
    }
  }
`;

export const DELETE_FILTER_TYPE = gql`
  mutation DeleteFilterType($filterId: Int!) {
    deleteFilterType(filterId: $filterId) {
      ok
    }
  }
`;

export const SAVE_SELECTION_OPTION = gql`
  mutation SaveSelectionOption($filterTypeId: Int!, $value: String!, $query: String) {
    saveSelectionOption(filterTypeId: $filterTypeId, value: $value, query: $query) {
      ok
      selectionOption {
        id
        value
        query
      }
    }
  }
`;

export const REMOVE_SELECTION_OPTION = gql`
  mutation RemoveSelectionOption($id: Int!) {
    removeSelectionOption(id: $id) {
      ok
    }
  }
`;

export const SAVE_COMPANY_FILTERS = gql`
  mutation SaveCompanyFilters($filters: [CompanyFilterInput]!, $companyId: Int!) {
    saveCompanyFilters(filters: $filters, companyId: $companyId) {
      ok
      conflicts {
        id
        selectionOption {
          id
          value
          filterType {
            id
            name
          }
        }
      }
    }
  }
`;

export const SAVE_RESPONSE_TEMPLATE = gql`
  mutation SaveResponseTemplate(
    $id: Int
    $isInitialResponse: Boolean!
    $message: String!
    $userId: Int
  ) {
    saveResponseTemplate(
      id: $id
      isInitialResponse: $isInitialResponse
      message: $message
      userId: $userId
    ) {
      ok
      responseTemplate {
        id
        message
        isInitialResponse
      }
    }
  }
`;

export const DELETE_REPONSE_TEMPLATE = gql`
  mutation DeleteResponseTemplate($id: Int!, $userId: Int) {
    deleteResponseTemplate(id: $id, userId: $userId) {
      ok
    }
  }
`;

export const UPDATE_USER_DISABLED_STATUS = gql`
  mutation UpdateUserDisabledStatus($userId: Int!, $isDisabled: Boolean!) {
    updateUserDisabledStatus(userId: $userId, isDisabled: $isDisabled) {
      ok
    }
  }
`;
export const UPDATE_USER_DISABLED_COMPANY_STATUS = gql`
  mutation UpdateUserDisabledCompanyStatus($userId: Int!, $companyId: Int!, $isDisabled: Boolean!) {
    updateUserDisabledCompanyStatus(
      userId: $userId
      companyId: $companyId
      isDisabled: $isDisabled
    ) {
      ok
    }
  }
`;
export const CREATE_TEAM = gql`
  mutation createTeam(
    $companyId: Int!
    $name: String!
    $leaderId: Int!
    $members: [TeamMemberInputs]!
  ) {
    createTeam(companyId: $companyId, name: $name, leaderId: $leaderId, members: $members) {
      team {
        id
        name
        leaderId
        members {
          id
          memberId
        }
      }
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($id: Int!, $name: String!, $leaderId: Int!, $members: [TeamMemberInputs]!) {
    updateTeam(id: $id, name: $name, leaderId: $leaderId, members: $members) {
      team {
        id
        name
        leaderId
        members {
          id
          memberId
        }
      }
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: Int!) {
    deleteTeam(id: $id) {
      ok
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation CreateRole(
    $companyId: Int
    $name: String!
    $canCreateUsers: Boolean!
    $canCreateTeams: Boolean!
    $canViewProspects: Boolean!
    $isCompanyAdmin: Boolean!
    $canViewAutoAnalytics: Boolean!
    $canViewAdExport: Boolean!
    $canViewClm: Boolean!
    $canViewGle: Boolean!
    $canViewEngagements: Boolean!
  ) {
    createRole(
      companyId: $companyId
      name: $name
      canCreateUsers: $canCreateUsers
      canCreateTeams: $canCreateTeams
      canViewProspects: $canViewProspects
      isCompanyAdmin: $isCompanyAdmin
      canViewAutoAnalytics: $canViewAutoAnalytics
      canViewAdExport: $canViewAdExport
      canViewClm: $canViewClm
      canViewGle: $canViewGle
      canViewEngagements: $canViewEngagements
    ) {
      ok
    }
  }
`;

export const EDIT_ROLE = gql`
  mutation EditRole(
    $companyId: Int
    $roleId: Int!
    $name: String!
    $canCreateUsers: Boolean!
    $canCreateTeams: Boolean!
    $canViewProspects: Boolean!
    $isCompanyAdmin: Boolean!
    $canViewAutoAnalytics: Boolean!
    $canViewAdExport: Boolean!
    $canViewClm: Boolean!
    $canViewGle: Boolean!
    $canViewEngagements: Boolean!
  ) {
    editRole(
      companyId: $companyId
      roleId: $roleId
      name: $name
      canCreateUsers: $canCreateUsers
      canCreateTeams: $canCreateTeams
      canViewProspects: $canViewProspects
      isCompanyAdmin: $isCompanyAdmin
      canViewAutoAnalytics: $canViewAutoAnalytics
      canViewAdExport: $canViewAdExport
      canViewClm: $canViewClm
      canViewGle: $canViewGle
      canViewEngagements: $canViewEngagements
    ) {
      ok
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($companyId: Int!, $roleId: Int!) {
    deleteRole(companyId: $companyId, roleId: $roleId) {
      ok
    }
  }
`;

export const ADD_SCREEN_NAME = gql`
  mutation AddScreenName(
    $screenName: String!
    $source: String
    $sourceId: Int
    $sourceUrl: String
    $id: Int
  ) {
    addScreenName(
      screenName: $screenName
      source: $source
      sourceId: $sourceId
      sourceUrl: $sourceUrl
      id: $id
    ) {
      ok
      screenName {
        id
        screenName
      }
    }
  }
`;
export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      statusCode
      message
    }
  }
`;
export const CREATE_CRM_INTEGRATION = gql`
  mutation CreateCrmIntegration(
    $companyId: Int!
    $integrationType: String!
    $adfEmail: String!
    $crmDealerId: String!
    $crmFranchiseId: String!
    $elSubscriptionId: String!
    $vsLeadSourceId: String!
    $sfApiUrl: String!
    $sfApiKey: String!
    $sfApiUser: String!
    $locationId: Int
    $isGlobalCrm: Boolean
  ) {
    createCrmIntegration(
      companyId: $companyId
      integrationType: $integrationType
      adfEmail: $adfEmail
      crmDealerId: $crmDealerId
      elSubscriptionId: $elSubscriptionId
      vsLeadSourceId: $vsLeadSourceId
      sfApiUrl: $sfApiUrl
      sfApiKey: $sfApiKey
      sfApiUser: $sfApiUser
      crmFranchiseId: $crmFranchiseId
      locationId: $locationId
      isGlobalCrm: $isGlobalCrm
    ) {
      statusCode
      message
      crmIntegrations {
        id
        companyId
        integrationType
        adfEmail
        crmDealerId
        vsLeadSourceId
        sfApiUrl
        sfApiKey
        sfApiUser
        sfCertificateKey
        elSubscriptionId
        crmFranchiseId
        locationId
        location {
          id
          title
          locationType
        }
      }
    }
  }
`;
export const UPDATE_CRM_INTEGRATION = gql`
  mutation updateCrmIntegration(
    $crmIntegrationId: Int!
    $integrationType: String!
    $adfEmail: String!
    $crmDealerId: String!
    $crmFranchiseId: String!
    $elSubscriptionId: String!
    $vsLeadSourceId: String!
    $sfApiUrl: String!
    $sfApiKey: String!
    $sfApiUser: String!
    $locationId: Int
  ) {
    updateCrmIntegration(
      crmIntegrationId: $crmIntegrationId
      integrationType: $integrationType
      adfEmail: $adfEmail
      crmDealerId: $crmDealerId
      elSubscriptionId: $elSubscriptionId
      vsLeadSourceId: $vsLeadSourceId
      sfApiUrl: $sfApiUrl
      sfApiKey: $sfApiKey
      sfApiUser: $sfApiUser
      crmFranchiseId: $crmFranchiseId
      locationId: $locationId
    ) {
      statusCode
      message
      crmIntegrations {
        id
        companyId
        integrationType
        adfEmail
        crmDealerId
        vsLeadSourceId
        sfApiUrl
        sfApiKey
        sfApiUser
        sfCertificateKey
        elSubscriptionId
        crmFranchiseId
        locationId
        location {
          id
          title
          locationType
        }
      }
    }
  }
`;

export const DELETE_CRM_INTEGRATION = gql`
  mutation DeleteCrmIntegration($crmIntegrationId: Int!) {
    deleteCrmIntegration(crmIntegrationId: $crmIntegrationId) {
      statusCode
      message
    }
  }
`;

export const UPDATE_DEAL_SUBSCRIPTIOM = gql`
  mutation UpdateDealSubscription($dealId: Int!, $subscribed: Boolean!) {
    updateDealSubscription(dealId: $dealId, subscribed: $subscribed) {
      ok
    }
  }
`;

export const SAVE_INITIAL_SENT = gql`
  mutation SaveInitialConversationSent($aingineId: Int!, $response: String!) {
    saveSentConversation(aingineId: $aingineId, response: $response) {
      ok
    }
  }
`;

export const PUSH_DEAL_TO_CRM = gql`
  mutation PushDealToCrm(
    $companyId: Int!
    $dealId: Int!
    $aingineDataId: Int
    $typeOfLead: String!
    $status: String
    $interest: String!
    $year: String!
    $make: String!
    $model: String!
    $contactFirstName: String!
    $contactLastName: String!
    $contactFullName: String
    $contactEmail: String
    $contactPhoneNumber: String!
    $contactAddressLine1: String!
    $contactAddressLine2: String
    $city: String
    $state: String!
    $zip: String
    $country: String!
    $comments: String!
    $vehicles: [VehicleInputs]
  ) {
    pushDealToCrm(
      companyId: $companyId
      dealId: $dealId
      aingineDataId: $aingineDataId
      typeOfLead: $typeOfLead
      status: $status
      interest: $interest
      year: $year
      make: $make
      model: $model
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactFullName: $contactFullName
      contactEmail: $contactEmail
      contactPhoneNumber: $contactPhoneNumber
      contactAddressLine1: $contactAddressLine1
      contactAddressLine2: $contactAddressLine2
      city: $city
      state: $state
      zip: $zip
      country: $country
      comments: $comments
      vehicles: $vehicles
    ) {
      statusCode
      message
    }
  }
`;

export const SUBMIT_SUPPORT_TICKET = gql`
  mutation SubmitSupportTicket($message: String!, $subject: String!) {
    submitSupportTicket(message: $message, subject: $subject) {
      ok
    }
  }
`;

export const RESEND_INVITE = gql`
  mutation ResendInvite($userId: Int!) {
    resendInvite(userId: $userId) {
      ok
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($id: Int!, $read: Boolean) {
    updateNotification(id: $id, read: $read) {
      ok
    }
  }
`;

export const UPDATE_NOTIFICATIONS = gql`
  mutation updateNotification($notificationInputs: [NotificationInputs]!, $leadIds: [Int]) {
    updateNotification(notificationInputs: $notificationInputs, leadIds: $leadIds) {
      statusCode
      message
    }
  }
`;

export const DELETE_EXPORT_CONFIG = gql`
  mutation deleteExportConfig($id: Int!) {
    deleteExportConfig(id: $id) {
      ok
    }
  }
`;

export const CREATE_EXPORT_CONFIG = gql`
  mutation createExportConfig(
    $email: String!
    $filters: [FilterInputs]!
    $name: String!
    $minimumCount: Int
    $emailTime: Time
    $frequency: Int
    $startDate: DateTime
    $endDate: DateTime
    $timezone: String
  ) {
    createExportConfig(
      email: $email
      filters: $filters
      name: $name
      minimumCount: $minimumCount
      emailTime: $emailTime
      frequency: $frequency
      startDate: $startDate
      endDate: $endDate
      timezone: $timezone
    ) {
      exportConfig {
        id
        userId
        name
        email
        minimumCount
        count
        frequency
        lastExported
        filters {
          typeName
          value
        }
        exports {
          id
          exportConfigId
          createdAt
          name
          path
        }
      }
    }
  }
`;

export const UPDATE_EXPORT_CONFIG = gql`
  mutation updateExportConfig(
    $id: Int!
    $email: String!
    $filters: [FilterInputs]!
    $name: String!
    $minimumCount: Int
    $emailTime: Time
    $frequency: Int
    $timezone: String
  ) {
    updateExportConfig(
      id: $id
      email: $email
      filters: $filters
      name: $name
      minimumCount: $minimumCount
      frequency: $frequency
      emailTime: $emailTime
      timezone: $timezone
    ) {
      exportConfig {
        id
        frequency
        userId
        name
        email
        minimumCount
        count
        lastExported
        filters {
          typeName
          value
        }
      }
    }
  }
`;

export const DELETE_EXPORT = gql`
  mutation deleteExport($id: Int!) {
    deleteExport(id: $id) {
      ok
    }
  }
`;

export const DOWNLOAD_EXPORT_FILE = gql`
  mutation downloadExportFile($id: Int!) {
    downloadExportFile(id: $id) {
      url
    }
  }
`;

export const UPDATE_PREDICTION_REVIEW = gql`
  mutation updatePrediction($postId: String!, $review: Boolean) {
    updatePrediction(postId: $postId, review: $review) {
      ok
    }
  }
`;

// NOTE: Apollo Resolver
export const UPDATE_PREDICTION_REVIEW_RESOLVER = gql`
  mutation UpdatePredictionReview($id: String!, $review: Boolean) {
    updatePredictionReview(id: $id, review: $review) @client
  }
`;

export const UPDATE_PROFILE_DATA = gql`
  mutation UpdateProfileData($id: Int!) {
    updateProfileData(id: $id) @client
  }
`;
export const CREATE_PERSON = gql`
  mutation CreatePerson($companySourceId: Int!, $personData: PersonInput) {
    createPerson(companySourceId: $companySourceId, personData: $personData) {
      person {
        id
      }
      ok
    }
  }
`;
export const CREATE_PERSON_USER_ACCOUNT = gql`
  mutation CreatePersonUserAccount($personData: CreateUserAccountInput, $personId: Int!) {
    createPersonUserAccount(userAccountData: $personData, personId: $personId) {
      ok
    }
  }
`;

export const UPDATE_USER_ACCOUNT = gql`
  mutation updateUserAccount($personData: UpdateUserAccountInput) {
    updateUserAccount(userAccountData: $personData) {
      ok
    }
  }
`;
export const UNASSIGN_USER_ACCOUNT = gql`
  mutation unassignUserAccount($personId: Int!, $accountId: Int!) {
    unassignUserAccount(personId: $personId, accountId: $accountId) {
      ok
    }
  }
`;
export const UPDATE_USER_ROLES = gql`
  mutation addCompanyReference($companyId: Int!, $roleId: Int!, $userId: Int!) {
    addCompanyReference(companyId: $companyId, roleId: $roleId, userId: $userId) {
      ok
    }
  }
`;
export const ADD_USER_ACCOUNT = gql`
  mutation addUserAccounts($companyId: Int!, $status: String!, $userId: Int!, $roleId: Int!) {
    addUserAccounts(companyId: $companyId, status: $status, userId: $userId, roleId: $roleId) {
      userAccountId
    }
  }
`;
export const UPDATE_USER_DEFAULT_COMPANY_ID = gql`
  mutation updateUserDefaultCompany($companyId: Int!, $userId: Int!) {
    updateUserDefaultCompany(companyId: $companyId, userId: $userId) {
      ok
    }
  }
`;

export const CREATE_LEAD = gql`
  mutation CreateLead(
    $dsEntityId: String
    $dsEventId: String
    $email: String
    $emailType: String
    $firstName: String!
    $isPushCrm: Boolean
    $isSyncCheck: Boolean
    $lastName: String!
    $leadSourceType: String!
    $locationId: Int
    $opportunityName: String
    $opportunitySourceId: Int
    $opportunityStatusId: Int
    $opportunityTypeId: Int
    $leadOpportunityUsers: [UserMapping]
    $phones: [LeadPhonesObj]!
    $vehicleOfInterests: [VehicleOfInterestInputObject]
    $leadAddressObj: LeadAddressInputObj
    $crmIntegrationId: Int
    $vsContactId: String
    $vsDealerId: String
    $elCustomerId: String
  ) {
    createLead(
      dsEntityId: $dsEntityId
      dsEventId: $dsEventId
      email: $email
      emailType: $emailType
      firstName: $firstName
      isPushCrm: $isPushCrm
      isSyncCheck: $isSyncCheck
      lastName: $lastName
      leadSourceType: $leadSourceType
      locationId: $locationId
      opportunityName: $opportunityName
      opportunitySourceId: $opportunitySourceId
      opportunityStatusId: $opportunityStatusId
      opportunityTypeId: $opportunityTypeId
      leadOpportunityUsers: $leadOpportunityUsers
      phones: $phones
      vehicleOfInterests: $vehicleOfInterests
      leadAddressObj: $leadAddressObj
      crmIntegrationId: $crmIntegrationId
      vsContactId: $vsContactId
      vsDealerId: $vsDealerId
      elCustomerId: $elCustomerId
    ) {
      statusCode
      message
      lead {
        id
        fullName
        firstName
        lastName
        dateOfBirth
        leadSourceType
        leadSourceOriginalId
        companyId
        leadFileId
        crmIntegrationId
        status
        emailConsent
        emailConsentDate
        textConsent
        textConsentDate
        textConsentStatus
        otherSource
        disableConversation
        leadStatusTypeId
        locationId
        leadStatusType {
          id
          type
          status
        }
        emails {
          id
          leadId
          email
          emailType
        }
        phoneNumbers {
          id
          leadId
          phone
          phoneType
          lookupType
        }
        addresses {
          id
          leadId
          locationText
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
        vehicleOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        leadVehicleInventory {
          id
          make
          model
          year
          trim
          stockNumber
          vin
        }
        leadSource {
          name
          id
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }

        leadUsers {
          userType {
            id
            useCase
            name
          }
          user {
            id
            firstName
            lastName
          }
        }
        location {
          id
          title
          timezone
        }
        crmLeadObj {
          crmIntegrationType
          crmStatus
          parentCrmStatus
          activeLeadOpportunity {
            id
            opportunityStatus {
              id
              name
              parentStatus {
                id
                name
              }
            }
            opportunitySource {
              id
              name
              parentSource {
                id
                name
                parentSource {
                  id
                  name
                }
              }
            }
            vehiclesOfInterest {
              id
              leadId
              year
              make
              model
              trim
              description
              budget
              customerInterest
              inputType
              isCurrent
              isPrimary
              stockNumber
              vin
            }
          }
          customerType {
            id
            type
          }
          customerSubType {
            id
            type
          }
          dsExtractedLead {
            id
            dsLeadId
            dsSource
          }
          activeDsOpportunityEvent {
            id
            dsEventId
            dsMarketingChannelName
          }
          sfExtractedLead {
            id
            sfLeadId
          }
          mfExtractedLead {
            id
            mfProspectId
            mfCustomerId
          }
          activeSfOpportunityEvent {
            id
            sfOpportunityId
          }
        }
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation UpdateLead(
    $email: String
    $emailType: String
    $firstName: String
    $isPushCrm: Boolean
    $isSyncCheck: Boolean
    $lastName: String
    $leadAddressObj: LeadAddressInputObj
    $leadId: Int!
    $phones: [LeadPhonesObj]
    $isNoResponseNeeded: Boolean
  ) {
    updateLead(
      email: $email
      emailType: $emailType
      firstName: $firstName
      isPushCrm: $isPushCrm
      isSyncCheck: $isSyncCheck
      lastName: $lastName
      leadAddressObj: $leadAddressObj
      leadId: $leadId
      phones: $phones
      isNoResponseNeeded: $isNoResponseNeeded
    ) {
      statusCode
      message
      lead {
        id
        fullName
        firstName
        lastName
        dateOfBirth
        leadSourceType
        leadSourceOriginalId
        companyId
        leadFileId
        crmIntegrationId
        status
        emailConsent
        emailConsentDate
        textConsent
        textConsentDate
        textConsentStatus
        otherSource
        disableConversation
        leadStatusTypeId
        locationId
        leadStatusType {
          id
          type
          status
        }
        emails {
          id
          leadId
          email
          emailType
        }
        phoneNumbers {
          id
          leadId
          phone
          phoneType
          lookupType
        }
        addresses {
          id
          leadId
          locationText
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
        vehicleOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        leadVehicleInventory {
          id
          make
          model
          year
          trim
          stockNumber
          vin
        }
        leadSource {
          name
          id
          parentSource {
            id
            name
            parentSource {
              id
              name
            }
          }
        }

        leadUsers {
          userType {
            id
            useCase
            name
          }
          user {
            id
            firstName
            lastName
          }
        }
        location {
          id
          title
          timezone
        }
        crmLeadObj {
          crmIntegrationType
          crmStatus
          parentCrmStatus
          activeLeadOpportunity {
            id
            opportunityStatus {
              id
              name
              parentStatus {
                id
                name
              }
            }
            opportunitySource {
              id
              name
              parentSource {
                id
                name
                parentSource {
                  id
                  name
                }
              }
            }
            vehiclesOfInterest {
              id
              leadId
              year
              make
              model
              trim
              description
              budget
              customerInterest
              inputType
              isCurrent
              isPrimary
              stockNumber
              vin
            }
          }
          customerType {
            id
            type
          }
          customerSubType {
            id
            type
          }
          dsExtractedLead {
            id
            dsLeadId
            dsSource
          }
          activeDsOpportunityEvent {
            id
            dsEventId
            dsMarketingChannelName
          }
          sfExtractedLead {
            id
            sfLeadId
          }
          mfExtractedLead {
            id
            mfProspectId
            mfCustomerId
          }
          activeSfOpportunityEvent {
            id
            sfOpportunityId
          }
        }
      }
    }
  }
`;

export const UPDATE_LEAD_V2 = gql`
  mutation updateLead(
    $email: String
    $emailType: String
    $firstName: String
    $isPushCrm: Boolean
    $isSyncCheck: Boolean
    $lastName: String
    $leadId: Int!
    $phones: [LeadPhonesObj]
    $leadAddressObj: LeadAddressInputObj
  ) {
    updateLead(
      email: $email
      emailType: $emailType
      firstName: $firstName
      isPushCrm: $isPushCrm
      isSyncCheck: $isSyncCheck
      lastName: $lastName
      leadId: $leadId
      phones: $phones
      leadAddressObj: $leadAddressObj
    ) {
      statusCode
      message
    }
  }
`;

export const DELETE_LEAD = gql`
  mutation DeleteLead($leadId: Int!, $isSyncCheck: Boolean, $isPushCrm: Boolean) {
    deleteLead(leadId: $leadId, isSyncCheck: $isSyncCheck, isPushCrm: $isPushCrm) {
      statusCode
      message
    }
  }
`;

export const CREATE_LEAD_EMAIL = gql`
  mutation CreateLeadEmail(
    $leadId: Int!
    $email: String!
    $emailType: String
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
  ) {
    createLeadEmail(
      leadId: $leadId
      email: $email
      emailType: $emailType
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
    ) {
      statusCode
      message
      leadEmail {
        id
        email
        emailType
      }
    }
  }
`;

export const UPDATE_LEAD_EMAIL = gql`
  mutation UpdateLeadEmail(
    $id: Int!
    $email: String!
    $emailType: String
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
  ) {
    updateLeadEmail(
      leadEmailId: $id
      email: $email
      emailType: $emailType
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
    ) {
      statusCode
      message
      leadEmail {
        id
        email
        emailType
      }
    }
  }
`;

export const DELETE_LEAD_EMAIL = gql`
  mutation DeleteLeadEmail($id: Int!, $isSyncCheck: Boolean, $isPushCrm: Boolean) {
    deleteLeadEmail(leadEmail: $id, isSyncCheck: $isSyncCheck, isPushCrm: $isPushCrm) {
      statusCode
      message
    }
  }
`;

export const CREATE_LEAD_PHONE = gql`
  mutation CreateLeadPhone(
    $leadId: Int!
    $phone: String!
    $phoneType: String
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
  ) {
    createLeadPhone(
      leadId: $leadId
      phone: $phone
      phoneType: $phoneType
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
    ) {
      statusCode
      message
      leadPhone {
        id
        phone
        phoneType
      }
    }
  }
`;

export const UPDATE_LEAD_PHONE = gql`
  mutation UpdateLeadPhone(
    $id: Int!
    $phone: String!
    $phoneType: String
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
    $leadId: Int!
  ) {
    updateLeadPhone(
      leadPhoneId: $id
      phone: $phone
      phoneType: $phoneType
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
      leadId: $leadId
    ) {
      statusCode
      message
      leadPhone {
        id
        phone
        phoneType
      }
    }
  }
`;

export const DELETE_LEAD_PHONE = gql`
  mutation DeleteLeadPhone($id: Int!, $isSyncCheck: Boolean, $isPushCrm: Boolean) {
    deleteLeadPhone(leadPhoneId: $id, isSyncCheck: $isSyncCheck, isPushCrm: $isPushCrm) {
      statusCode
      message
    }
  }
`;

export const CREATE_LEAD_ADDRESS = gql`
  mutation CreateLeadAddress(
    $leadId: Int!
    $addressLine1: String
    $addressLine2: String
    $city: String
    $country: String
    $locationText: String
    $postalCode: String
    $state: String
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
  ) {
    createLeadAddress(
      leadId: $leadId
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      country: $country
      locationText: $locationText
      postalCode: $postalCode
      state: $state
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
    ) {
      statusCode
      message
      leadAddress {
        id
        leadId
        locationText
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
    }
  }
`;
export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign(
    $activeInd: String
    $campaignLeads: [Int]
    $endDate: DateTime
    $isAcceptTerms: Boolean
    $isDisabled: Boolean
    $isPrioritize: Boolean
    $messages: [CampaignMessage]
    $method: String
    $name: String!
    $source: [Int]
    $startDate: DateTime
    $status: [Int]
    $textMessage: String
  ) {
    createCampaign(
      activeInd: $activeInd
      campaignLeads: $campaignLeads
      endDate: $endDate
      isAcceptTerms: $isAcceptTerms
      isDisabled: $isDisabled
      isPrioritize: $isPrioritize
      messages: $messages
      method: $method
      name: $name
      source: $source
      startDate: $startDate
      status: $status
      textMessage: $textMessage
    ) {
      statusCode
      message
      campaign {
        id
        name
      }
    }
  }
`;

export const CLONE_CAMPAIGN = gql`
  mutation CloneCampaign($campaignId: Int!, $name: String!) {
    cloneCampaign(campaignId: $campaignId, name: $name) {
      statusCode
      message
      campaign {
        id
        name
      }
    }
  }
`;

export const UPDATE_LEAD_ADDRESS = gql`
  mutation UpdateLeadAddress(
    $id: Int!
    $locationText: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $state: String
    $postalCode: String
    $country: String
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
  ) {
    updateLeadAddress(
      id: $id
      locationText: $locationText
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      state: $state
      postalCode: $postalCode
      country: $country
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
    ) {
      leadAddress {
        id
        leadId
        locationText
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      statusCode
      message
    }
  }
`;

export const DELETE_LEAD_ADDRESS = gql`
  mutation DeleteLeadAddress($id: Int!, $isSyncCheck: Boolean, $isPushCrm: Boolean) {
    deleteLeadAddress(id: $id, isSyncCheck: $isSyncCheck, isPushCrm: $isPushCrm) {
      statusCode
      message
    }
  }
`;

export const CREATE_LEAD_VEHICLE_OF_INTEREST = gql`
  mutation CreateLeadVehicleOfInterest(
    $leadId: Int!
    $voiObject: VehicleOfInterestInputObject!
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
  ) {
    createLeadVehicleOfInterest(
      leadId: $leadId
      voiObject: $voiObject
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
    ) {
      statusCode
      message
      leadVehicleOfInterest {
        id
        leadId
        budget
        description
        make
        model
        trim
        year
        vin
        stockNumber
        isCurrent
        customerInterest
        inputType
        isPrimary
      }
    }
  }
`;

export const UPDATE_LEAD_VEHICLE_OF_INTEREST = gql`
  mutation UpdateLeadVehicleOfInterest(
    $id: Int!
    $voiObject: VehicleOfInterestInputObject!
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
  ) {
    updateLeadVehicleOfInterest(
      id: $id
      voiObject: $voiObject
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
    ) {
      leadVehicleOfInterest {
        id
        budget
        description
        make
        model
        year
        trim
        isCurrent
        customerInterest
        inputType
        isPrimary
        year
        vin
      }
      statusCode
      message
    }
  }
`;

export const DELETE_LEAD_VEHICLE_OF_INTEREST = gql`
  mutation DeleteLeadVehicleOfInterest($id: Int!, $isSyncCheck: Boolean, $isPushCrm: Boolean) {
    deleteLeadVehicleOfInterest(id: $id, isSyncCheck: $isSyncCheck, isPushCrm: $isPushCrm) {
      statusCode
      message
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $activeInd: String
    $campaignLeads: [Int]
    $endDate: DateTime
    $id: Int!
    $isAcceptTerms: Boolean
    $isDisabled: Boolean
    $isPrioritize: Boolean
    $messages: [CampaignMessage]
    $method: String
    $name: String!
    $source: [Int]
    $startDate: DateTime
    $status: [Int]
    $textMessage: String
  ) {
    updateCampaign(
      activeInd: $activeInd
      campaignLeads: $campaignLeads
      endDate: $endDate
      id: $id
      isAcceptTerms: $isAcceptTerms
      isDisabled: $isDisabled
      isPrioritize: $isPrioritize
      messages: $messages
      method: $method
      name: $name
      source: $source
      startDate: $startDate
      status: $status
      textMessage: $textMessage
    ) {
      statusCode
      message
      campaign {
        id
        name
        campaignSelections {
          id
          type
          value
        }
      }
    }
  }
`;
export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($id: Int!) {
    deleteCampaign(id: $id) {
      statusCode
      message
    }
  }
`;

export const UPDATE_CAMPAIGN_SCHEDULE = gql`
  mutation UpdateCampaignSchedules(
    $id: Int!
    $type: String!
    $numericValue: Int!
    $temporalValue: String!
    $title: String!
    $sortOrder: Int!
  ) {
    updateCampaignSchedules(
      id: $id
      type: $type
      numericValue: $numericValue
      temporalValue: $temporalValue
      title: $title
      sortOrder: $sortOrder
    ) {
      campaignSchedule {
        id
        type
        numericValue
        temporalValue
        title
        sortOrder
      }
    }
  }
`;
export const CREATE_CAMPAIGN_SCHEDULES = gql`
  mutation createCampaignSchedules($campaignId: Int!, $message: CampaignMessage!) {
    createCampaignSchedules(campaignId: $campaignId, message: $message) {
      statusCode
      campaignSchedule {
        id
      }
    }
  }
`;
export const UPDATE_CAMPAIGN_SCHEDULES = gql`
  mutation updateCampaignSchedules($message: CampaignMessage) {
    updateCampaignSchedules(message: $message) {
      statusCode
      campaignSchedule {
        id
      }
    }
  }
`;
export const CREATE_CAMPAIGN_SCHEDULE = gql`
  mutation CreateCampaignSchedule(
    $campaignId: Int!
    $type: String!
    $numericValue: Int!
    $temporalValue: String!
    $title: String!
    $sortOrder: Int!
  ) {
    createCampaignSchedule(
      campaignId: $campaignId
      type: $type
      numericValue: $numericValue
      temporalValue: $temporalValue
      title: $title
      sortOrder: $sortOrder
    ) {
      statusCode
      campaignSchedule {
        id
        type
        numericValue
        temporalValue
        title
        sortOrder
      }
    }
  }
`;

export const CREATE_CAMPAIGN_TEMPLATE = gql`
  mutation CreateCampaignTemplates(
    $campaignId: Int!
    $scheduleId: Int!
    $sources: [Int]!
    $templateText: [String]!
    $afterHourTemplateText: [String]!
    $isAfterHour: Boolean!
  ) {
    createCampaignTemplates(
      campaignId: $campaignId
      scheduleId: $scheduleId
      sources: $sources
      templateText: $templateText
      afterHourTemplateText: $afterHourTemplateText
      isAfterHour: $isAfterHour
    ) {
      campaignTemplates {
        id
      }
    }
  }
`;
export const UPDATE_CAMPAIGN_SCHEDULE_TEMPLATE = gql`
  mutation UpdateCampaignScheduleTemplates(
    $campaignId: Int!
    $scheduleId: Int!
    $sources: [Int]!
    $templateText: [String]!
    $afterHourTemplateText: [String]!
    $isAfterHour: Boolean!
  ) {
    updateCampaignScheduleTemplates(
      campaignId: $campaignId
      scheduleId: $scheduleId
      sources: $sources
      templateText: $templateText
      afterHourTemplateText: $afterHourTemplateText
      isAfterHour: $isAfterHour
    ) {
      ok
      campaignTemplates {
        id
      }
    }
  }
`;
export const UPDATE_CAMPAIGN_SCHEDULE_SORT_ORDERS = gql`
  mutation UpdateCampaignSchedulesSortorder($schedules: [Int]!, $sortOrder: [Int]!) {
    updateCampaignSchedulesSortorder(schedules: $schedules, sortOrder: $sortOrder) {
      ok
      campaignSchedules {
        id
        type
        numericValue
        temporalValue
        title
        sortOrder
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_TEMPLATE = gql`
  mutation UpdateCampaignTemplate(
    $id: Int!
    $scheduleId: Int
    $sourceId: Int
    $templateText: String
    $isActive: Boolean
  ) {
    updateCampaignTemplate(
      id: $id
      scheduleId: $scheduleId
      sourceId: $sourceId
      templateText: $templateText
      isActive: $isActive
    ) {
      campaignTemplate {
        id
      }
    }
  }
`;
export const DELETE_CAMPAIGN_TEMPLATE = gql`
  mutation DeleteCampaignTemplate($id: Int!) {
    deleteCampaignTemplate(id: $id) {
      ok
    }
  }
`;
export const DELETE_CAMPAIGN_SCHEDULE = gql`
  mutation DeleteCampaignSchedule($id: Int!) {
    deleteCampaignSchedule(id: $id) {
      ok
    }
  }
`;

export const DELETE_CAMPAIGN_SCHEDULE_OPTION = gql`
  mutation DeleteCampaignScheduleOption($id: Int!) {
    deleteCampaignScheduleOption(id: $id) {
      ok
    }
  }
`;

// export const CREATE_CAMPAIGN_SCHEDULE_OPTION = gql`
//   mutation CreateCampaignScheduleOption(
//     $type: String!
//     $numericValue: Int!
//     $temporalValue: String!
//   ) {
//     createCampaignScheduleOption(
//       type: $type
//       numericValue: $numericValue
//       temporalValue: $temporalValue
//     ) {
//       campaignScheduleOption {
//         id
//       }
//     }
//   }
// `
export const CREATE_CAMPAIGN_SELECTIONS = gql`
  mutation CreateCampaignSelections(
    $campaignId: Int!
    $type: String!
    $values: [SelectionInputs]!
    $secondaryType: String
    $secondaryValues: [SelectionInputs]
  ) {
    createCampaignSelections(
      campaignId: $campaignId
      type: $type
      values: $values
      secondaryType: $secondaryType
      secondaryValues: $secondaryValues
    ) {
      campaignSelections {
        id
      }
    }
  }
`;
// export const CREATE_CAMPAIGN_SCHEDUEL = gql`
//   mutation CreateCampaignSchedule($campaignId: Int!, $schedulesOptionId: Int!) {
//     createCampaignSchedule(
//       campaignId: $campaignId
//       schedulesOptionId: $schedulesOptionId
//     ) {
//       campaignSchedule {
//         id
//         schedulesOptionId
//         campaignSchedulesOption {
//           id
//           type
//           numericValue
//           temporalValue
//         }
//       }
//     }
//   }
// `
export const CREATE_CAMPAIGN_LEAD_SUMMARY = gql`
  mutation CreateCampaignLeadSummary($campaignId: Int!) {
    createCampaignLeadSummary(campaignId: $campaignId) {
      ok
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation CreateMessage(
    $leadId: Int!
    $userId: Int!
    $channelId: Int
    $campaignId: Int
    $campaignTemplateId: Int
    $direction: String
    $content: String!
    $toPhone: String!
    $reviewLink: String
    $leadOpportunityId: Int
    $dsEventId: String
    $messageType: String
  ) {
    createMessage(
      leadId: $leadId
      userId: $userId
      channelId: $channelId
      campaignId: $campaignId
      campaignTemplateId: $campaignTemplateId
      direction: $direction
      content: $content
      toPhone: $toPhone
      reviewLink: $reviewLink
      leadOpportunityId: $leadOpportunityId
      dsEventId: $dsEventId
      messageType: $messageType
    ) {
      statusCode
      message
      leadMessage {
        id
        systemUserId
        leadId
        userId
        user {
          firstName
          lastName
          profilePic
        }
        channelId
        campaignId
        campaignTemplateId
        direction
        dateSent
        dateReceived
        content
        messageType
        errorCode
        errorMessage
        contentType
        statusCode
        messageStatus
        messageLog {
          id
          fromPhone
          toPhone
        }
        trackingDetails {
          id
          actionType
          actionValue
        }
        leadOpportunity {
          id
          name
          opportunityType {
            id
            name
            parentType {
              id
              name
            }
          }
          opportunityStatus {
            id
            name
            parentStatus {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_MESSAGE_LOG = gql`
  mutation CreateMessageLog($messageId: Int!, $toPhone: String!) {
    createMessageLog(messageId: $messageId, toPhone: $toPhone) {
      messageLog {
        id
        fromPhone
        toPhone
      }
    }
  }
`;

export const CREATE_CHANNEL = gql`
  mutation CreateChannel($name: String!, $channelType: String!) {
    createChannel(name: $name, channelType: $channelType) {
      channel {
        id
        name
        channelType
      }
    }
  }
`;

export const UPDATE_CHANNEL = gql`
  mutation UpdateChannel($id: Int!, $name: String!, $channelType: String!) {
    updateChannel(id: $id, name: $name, channelType: $channelType) {
      channel {
        id
        name
        channelType
      }
      ok
    }
  }
`;

export const DELETE_CHANNEL = gql`
  mutation DeleteChannel($id: Int!) {
    deleteChannel(id: $id) {
      ok
    }
  }
`;

export const UPDATE_CRM_INTEGRATION_USER = gql`
  mutation UpdateCrmIntegrationUser($userId: Int!, $crmIntegrationId: Int!, $crmUserId: String!) {
    updateCrmIntegrationUser(
      userId: $userId
      crmIntegrationId: $crmIntegrationId
      crmUserId: $crmUserId
    ) {
      ok
    }
  }
`;

export const SAVE_ENAGEMENT_MESSAGE_TEMPLATE = gql`
  mutation SaveEngagementMessageTemplate(
    $userId: Int!
    $companyId: Int
    $title: String!
    $message: String!
    $id: Int
    $isActive: Boolean
    $isCompanyShared: Boolean
    $locationId: Int
  ) {
    saveEngagementMessageTemplate(
      userId: $userId
      companyId: $companyId
      title: $title
      message: $message
      id: $id
      locationId: $locationId
      isActive: $isActive
      isCompanyShared: $isCompanyShared
    ) {
      message
      statusCode
      engagementMessageTemplate {
        id
      }
    }
  }
`;

export const DELETE_ENAGEMENT_MESSAGE_TEMPLATE = gql`
  mutation DeleteEngagementMessageTemplate($id: Int!, $userId: Int!) {
    deleteEngagementMessageTemplate(id: $id, userId: $userId) {
      message
      statusCode
    }
  }
`;
export const CREATE_APPOINTMENT = gql`
  mutation CreateAppointment(
    $leadId: Int!
    $userId: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $summary: String!
    $description: String
    $appointmentTimezone: String!
    $discussedVoiId: Int
    $leadOpportunityId: Int!
    $isConfirmed: Boolean
    $dsEventId: String
  ) {
    createAppointment(
      leadId: $leadId
      userId: $userId
      startDate: $startDate
      endDate: $endDate
      summary: $summary
      description: $description
      appointmentTimezone: $appointmentTimezone
      discussedVoiId: $discussedVoiId
      leadOpportunityId: $leadOpportunityId
      isConfirmed: $isConfirmed
      dsEventId: $dsEventId
    ) {
      appointment {
        id
        dateUpdated
        startDatetime
      }
      statusCode
      message
    }
  }
`;
export const UPDATE_APPOINTMENT = gql`
  mutation UpdateAppointment(
    $appointmentId: Int!
    $userId: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $summary: String!
    $description: String
    $appointmentTimezone: String!
    $status: String
    $discussedVoiId: Int
    $leadOpportunityId: Int!
    $isConfirmed: Boolean
    $dsEventId: String
  ) {
    updateAppointment(
      appointmentId: $appointmentId
      userId: $userId
      startDate: $startDate
      endDate: $endDate
      summary: $summary
      description: $description
      appointmentTimezone: $appointmentTimezone
      appointmentStatus: $status
      discussedVoiId: $discussedVoiId
      leadOpportunityId: $leadOpportunityId
      isConfirmed: $isConfirmed
      dsEventId: $dsEventId
    ) {
      appointment {
        id
        startDatetime
        timezone
        description
        leadOpportunityId
        summary
        isConfirmed
        discussedVoiId
        # content
        userId
        appointmentStatus
        lead {
          id
          fullName
          phoneNumbers {
            phone
          }
          emails {
            id
            email
          }
          leadSource {
            id
            name
          }
          vehicleOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
          leadVehicleInventory {
            id
            make
            model
            year
          }
          leadOpportunities {
            id
            name
            sfLeadOpportunities {
              id
              sfOpportunityId
              sfOpportunityStage
            }
            vsLeadOpportunities {
              id
              vsContactId
              vsLeadId
              vsLeadStatusMapping {
                vsLeadStatusType
                vsLeadStatus
              }
            }
            elLeadOpportunities {
              id
              elOpportunityId
              elLeadId
            }
            mfLeadOpportunities {
              id
              mfProspectId
              mfCustomerId
              mfLeadId
            }
            dsOpportunityEvents {
              id
              dsEventId
              dsEntitySource
              dsMarketingChannelName
              dsTrackingCode
            }
            dtLeadOpportunities {
              dtOpportunityId
              dtOpportunityStage
              dtLeadSource {
                leadSource {
                  name
                }
              }
            }
            opportunityStatus {
              id
              name
              parentStatus {
                name
                id
              }
            }
          }
        }
        leadOpportunityId
        leadOpportunity {
          id
          name
          leadId
          dsOpportunityEvents {
            id
            dsEventId
            dsEntitySource
            dsMarketingChannelName
            dsTrackingCode
          }
          sfLeadOpportunities {
            id
            sfOpportunityId
            sfOpportunityStage
          }
          vsLeadOpportunities {
            id
            vsContactId
            vsLeadId
            vsLeadStatusMapping {
              vsLeadStatusType
              vsLeadStatus
            }
          }
          elLeadOpportunities {
            id
            elOpportunityId
            elLeadId
          }
          mfLeadOpportunities {
            id
            mfProspectId
            mfCustomerId
            mfLeadId
          }
          opportunityType {
            id
            name
            parentType {
              id
              name
            }
          }
          opportunityStatus {
            id
            name
            parentStatus {
              id
              name
            }
          }
        }
        dateUpdated
        createdBy {
          id
          fullName
          dateCreated
        }
        updatedBy {
          id
          fullName
          dateCreated
        }
        user {
          id
          fullName
          companyRole {
            id
            name
          }
        }
        locationId
      }
      statusCode
      message
    }
  }
`;
export const RESCHEDULE_APPOINTMENT = gql`
  mutation rescheduleAppointment(
    $appointmentId: Int
    $startDate: DateTime!
    $endDate: DateTime!
    $appointmentTimezone: String!
    $leadId: Int!
    $userId: Int!
    $discussedVoiId: Int
    $isConfirmed: Boolean
  ) {
    rescheduleAppointment(
      appointmentId: $appointmentId
      startDate: $startDate
      endDate: $endDate
      appointmentTimezone: $appointmentTimezone
      leadId: $leadId
      userId: $userId
      discussedVoiId: $discussedVoiId
      isConfirmed: $isConfirmed
    ) {
      appointment {
        id
        startDatetime
        endDatetime
        status
        appointmentStatus
      }
      statusCode
      message
    }
  }
`;

export const UPDATE_LEAD_CONSENT = gql`
  mutation UpdateLeadConsentStatus($leadId: Int!) {
    updateLeadConsentStatus(id: $leadId) {
      ok
      lead {
        id
        textConsentDate
        textConsentStatus
      }
    }
  }
`;

export const DELETE_APPOINTMENT = gql`
  mutation DeleteAppointment($appointmentId: Int!) {
    deleteAppointment(appointmentId: $appointmentId) {
      statusCode
      message
    }
  }
`;

export const CREATE_LEAD_VEHICLES = gql`
  mutation createLeadVehicleOfInterests(
    $leadId: Int!
    $voiObject: [VehicleOfInterestInputObject]!
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
  ) {
    createLeadVehicleOfInterests(
      leadId: $leadId
      voiObjects: $voiObject
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
    ) {
      statusCode
      message
    }
  }
`;

export const UPDATE_COMPANY_WORK_HOURS = gql`
  mutation updateWorkingHours(
    $companyId: Int!
    $inputWorkingHours: [WorkingHourInputs]!
    $locationId: Int
  ) {
    updateWorkingHours(
      companyId: $companyId
      inputWorkingHours: $inputWorkingHours
      locationId: $locationId
    ) {
      statusCode
      message
      workingHours {
        id
        companyId
        weekDay
        isWorkingDay
        startTime
        endTime
      }
    }
  }
`;
export const CREATE_CAMPANY_PHONE_BOT = gql`
  mutation CreateTwilioPhoneService(
    $phone: String!
    $serviceName: String!
    $type: String!
    $description: String
    $companyId: Int!
    $locationId: Int!
    $twilioAccountSid: String
    $twilioAuthToken: String
  ) {
    createTwilioPhoneService(
      phone: $phone
      serviceName: $serviceName
      type: $type
      description: $description
      companyId: $companyId
      locationId: $locationId
      twilioAccountSid: $twilioAccountSid
      twilioAuthToken: $twilioAuthToken
    ) {
      statusCode
      message
      twilioPhoneService {
        id
        companyId
        userId
        type
        serviceName
        description
        createdOn
        isActive
        twilioAccountSid
        twilioAuthToken
        user {
          id
          firstName
          lastName
          email
          phone
          companyId
        }
      }
    }
  }
`;
export const UPDATE_CAMPANY_PHONE_BOT = gql`
  mutation UpdateTwilioPhoneService(
    $id: Int!
    $phone: String!
    $serviceName: String!
    $type: String!
    $description: String
    $isActive: Boolean
    $twilioAccountSid: String
    $twilioAuthToken: String
  ) {
    updateTwilioPhoneService(
      twilioPhoneServiceId: $id
      phone: $phone
      serviceName: $serviceName
      type: $type
      description: $description
      isActive: $isActive
      twilioAccountSid: $twilioAccountSid
      twilioAuthToken: $twilioAuthToken
    ) {
      statusCode
      message
      twilioPhoneService {
        id
        companyId
        userId
        type
        serviceName
        description
        createdOn
        isActive
        twilioAccountSid
        twilioAuthToken
        user {
          id
          firstName
          lastName
          email
          phone
          companyId
        }
      }
    }
  }
`;

export const DELETE_PHONE_BOT = gql`
  mutation DeleteTwilioPhoneService($id: Int!) {
    deleteTwilioPhoneService(twilioPhoneServiceId: $id) {
      statusCode
      message
    }
  }
`;
export const SAVE_LEAD_NOTE = gql`
  mutation SaveLeadNote($leadId: Int!, $note: String!) {
    saveLeadNote(leadId: $leadId, note: $note) {
      statusCode
      message
    }
  }
`;

export const UPDATE_LEAD_CONVERSATION_STATUS = gql`
  mutation enableDisableLeadConversation($leadId: Int!, $disableConversation: Boolean!) {
    enableDisableLeadConversation(leadId: $leadId, disableConversation: $disableConversation) {
      statusCode
      message
      lead {
        id
        conversationStatus {
          id
          userId
          disableConversation
          createdOn
        }
      }
    }
  }
`;

export const CREATE_FCM_DEVICE = gql`
  mutation createFcmDevice($registrationId: String!) {
    createFcmDevice(registrationId: $registrationId) {
      statusCode
      message
    }
  }
`;

export const DELETE_FCM_DEVICE = gql`
  mutation deleteFcmDevice($registrationId: String!) {
    deleteFcmDevice(registrationId: $registrationId) {
      statusCode
      message
    }
  }
`;
export const UPDATE_COMPANY_NUDGE_SETTINGS = gql`
  mutation updateCompanyNudgeEvent(
    $companyId: Int!
    $companyNudgeEventInputs: [CompanyNudgeEventInputs]!
  ) {
    updateCompanyNudgeEvent(
      companyId: $companyId
      companyNudgeEventInputs: $companyNudgeEventInputs
    ) {
      statusCode
      message
      companyNudgeEvents {
        nudgeEvent {
          id
          code
          title
        }
        companyNudgeEvent {
          id
          nudgeEventId
          startDelay
          startDelayType
          frequency
          frequencyType
          firstTemplateText
          reminderTemplateText
          parentTemplateText
          isSms
          isEmail
          isWebPush
          isActive
          reminderCount
        }
      }
    }
  }
`;
export const CREATE_REGION = gql`
  mutation createCompanyRegion($companyId: Int!, $name: String!, $isDefault: Boolean!) {
    createCompanyRegion(companyId: $companyId, name: $name, isDefault: $isDefault) {
      statusCode
      message
      region {
        id
        name
      }
    }
  }
`;
export const UPDATE_REGION = gql`
  mutation UpdateCompanyRegion(
    $regionId: Int!
    $name: String!
    $isDefault: Boolean!
    $isActive: Boolean
  ) {
    updateCompanyRegion(
      regionId: $regionId
      name: $name
      isDefault: $isDefault
      isActive: $isActive
    ) {
      statusCode
      message
      region {
        id
        name
      }
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation createCompanyLocation(
    $companyId: Int!
    $regionId: Int!
    $isDefault: Boolean!
    $locationType: String!
    $title: String!
    $phone: String
    $email: String
    $addressLine1: String
    $city: String
    $state: String
    $postalCode: String
    $country: String
    $geoLink: String
    $website: String
    $timezone: String
    $inventoryUrlFormat: String
    $openaiApiKey: String
    $notes: String
  ) {
    createCompanyLocation(
      companyId: $companyId
      regionId: $regionId
      isDefault: $isDefault
      locationType: $locationType
      title: $title
      phone: $phone
      email: $email
      addressLine1: $addressLine1
      city: $city
      state: $state
      postalCode: $postalCode
      country: $country
      geoLink: $geoLink
      website: $website
      timezone: $timezone
      inventoryUrlFormat: $inventoryUrlFormat
      openaiApiKey: $openaiApiKey
      notes: $notes
    ) {
      statusCode
      message
      location {
        id
        companyId
        regionId
        locationType
        title
        phone
        email
        addressLine1
        city
        state
        postalCode
        country
        geoLink
        website
        timezone
        isActive
      }
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateCompanyLocation(
    $locationId: Int!
    $regionId: Int!
    $isDefault: Boolean!
    $isActive: Boolean
    $locationType: String
    $title: String!
    $phone: String
    $email: String
    $addressLine1: String
    $city: String
    $state: String
    $postalCode: String
    $country: String
    $geoLink: String
    $website: String
    $timezone: String
    $inventoryUrlFormat: String
    $openaiApiKey: String
    $notes: String
  ) {
    updateCompanyLocation(
      locationId: $locationId
      isDefault: $isDefault
      locationType: $locationType
      title: $title
      phone: $phone
      email: $email
      addressLine1: $addressLine1
      city: $city
      state: $state
      postalCode: $postalCode
      country: $country
      geoLink: $geoLink
      website: $website
      timezone: $timezone
      isActive: $isActive
      regionId: $regionId
      inventoryUrlFormat: $inventoryUrlFormat
      openaiApiKey: $openaiApiKey
      notes: $notes
    ) {
      statusCode
      message
      location {
        id
        companyId
        regionId
        locationType
        title
        phone
        email
        addressLine1
        city
        state
        postalCode
        country
        geoLink
        website
        timezone
        isActive
      }
    }
  }
`;

export const SYNC_UP_LEAD = gql`
  mutation syncUpLead($leadId: Int!) {
    syncUpLead(leadId: $leadId) {
      statusCode
      message
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation CreateReview(
    $email: String
    $head: String
    $body: String
    $reviewType: String
    $reviewUrl: String!
  ) {
    createReview(
      email: $email
      head: $head
      body: $body
      reviewType: $reviewType
      reviewUrl: $reviewUrl
    ) {
      statusCode
      message
    }
  }
`;

export const CREATE_REVIEW_TEMPLATE = gql`
  mutation CreateReviewTemplate($image: String!) {
    createReviewTemplate(image: $image) {
      statusCode
      message
    }
  }
`;

export const CREATE_MMS_MESSAGE = gql`
  mutation CreateMmsMessage(
    $leadId: Int!
    $userId: Int!
    $channelId: Int
    $campaignId: Int
    $campaignTemplateId: Int
    $direction: String
    $content: String!
    $toPhone: String!
    $imageUrl: String!
  ) {
    createMmsMessage(
      leadId: $leadId
      userId: $userId
      channelId: $channelId
      campaignId: $campaignId
      campaignTemplateId: $campaignTemplateId
      direction: $direction
      content: $content
      toPhone: $toPhone
      imageUrl: $imageUrl
    ) {
      statusCode
      message
      leadMessage {
        id
        systemUserId
        leadId
        userId
        channelId
        campaignId
        campaignTemplateId
        direction
        dateSent
        dateReceived
        content
        errorCode
        errorMessage
        statusCode
        messageStatus
        messageType
        messageLog {
          id
          fromPhone
          toPhone
        }
      }
    }
  }
`;

export const CREATE_REVIEW_INFO = gql`
  mutation CreateReviewInfo(
    $companyId: Int!
    $locationId: Int!
    $reviewSource: String!
    $accountId: String
    $accessToken: String
    $reviewPostLink: String
    $sourceLink: String
    $accountLocationId: String
  ) {
    createReviewInfo(
      companyId: $companyId
      locationId: $locationId
      reviewSource: $reviewSource
      accountId: $accountId
      accessToken: $accessToken
      reviewPostLink: $reviewPostLink
      sourceLink: $sourceLink
      accountLocationId: $accountLocationId
    ) {
      statusCode
      message
      reviewInfo {
        id
        companyId
        locationId
        reviewSource
        sourceLink
        reviewPostLink
        accessToken
        accountId
        accountLocationId
        isActive
      }
    }
  }
`;

export const UPDATE_REVIEW_INFO = gql`
  mutation UpdateReviewInfo(
    $reviewInfoId: Int!
    $companyId: Int!
    $locationId: Int!
    $reviewSource: String!
    $accountId: String
    $accessToken: String
    $reviewPostLink: String
    $sourceLink: String
    $accountLocationId: String
    $isActive: Boolean
  ) {
    updateReviewInfo(
      reviewInfoId: $reviewInfoId
      companyId: $companyId
      locationId: $locationId
      reviewSource: $reviewSource
      accountId: $accountId
      accessToken: $accessToken
      reviewPostLink: $reviewPostLink
      sourceLink: $sourceLink
      accountLocationId: $accountLocationId
      isActive: $isActive
    ) {
      statusCode
      message
      reviewInfo {
        id
        companyId
        locationId
        reviewSource
        sourceLink
        reviewPostLink
        accessToken
        accountId
        accountLocationId
        isActive
      }
    }
  }
`;
export const REMOVE_LEAD_FROM_CAMPAIGN = gql`
  mutation removeLeadsFromCampaign(
    $campaignId: Int!
    $leadIds: [Int]!
    $isExcludeLastWeek: Boolean
  ) {
    removeLeadsFromCampaign(
      campaignId: $campaignId
      leadIds: $leadIds
      isExcludeLastWeek: $isExcludeLastWeek
    ) {
      statusCode
      message
      campaignLeads {
        id
        firstName
        lastName
        campaignObj {
          campaigns {
            id
            name
            activeInd
          }
          activelyCampaign
        }
      }
    }
  }
`;
export const UPDATE_COMPANY_APPOINTMENT_SETTING = gql`
  mutation updateCompanyAppointmentSettingEvent(
    $companyId: Int!
    $companyAppointmentSettingEventInputs: [CompanyAppointmentSettingEventInputs]!
  ) {
    updateCompanyAppointmentSettingEvent(
      companyId: $companyId
      companyAppointmentSettingEventInputs: $companyAppointmentSettingEventInputs
    ) {
      statusCode
      message
      companyAppointmentSettingEvents {
        appointmentSettingEvent {
          id
          code
          title
          isActive
        }
        companyAppointmentSettingEvent {
          id
          appointmentSettingEventId
          companyId
          templateText
          isSms
          isEmail
          isActive
        }
      }
    }
  }
`;
export const CREATE_COMPANY_ROLE = gql`
  mutation CreateCompanyRole(
    $companyId: Int!
    $name: String!
    $parentRoleId: Int
    $permissionActionIds: [Int]
    $locationIds: [Int]
    $isOpenCrmWarningPopup: Boolean
  ) {
    createCompanyRole(
      companyId: $companyId
      name: $name
      parentRoleId: $parentRoleId
      permissionActionIds: $permissionActionIds
      locationIds: $locationIds
      isOpenCrmWarningPopup: $isOpenCrmWarningPopup
    ) {
      statusCode
      message
      companyRole {
        id
        name
        isOpenCrmWarningPopup
        companyId
        parentRoleId
        isSmaiRole
        isActive
        locations {
          location {
            id
            title
          }
        }
      }
    }
  }
`;
export const UPDATE_COMPANY_ROLE = gql`
  mutation UpdateCompanyRole(
    $companyRoleId: Int!
    $name: String!
    $parentRoleId: Int
    $permissionActionIds: [Int]
    $locationIds: [Int]
    $isOpenCrmWarningPopup: Boolean
    $isActive: Boolean
  ) {
    updateCompanyRole(
      companyRoleId: $companyRoleId
      name: $name
      parentRoleId: $parentRoleId
      permissionActionIds: $permissionActionIds
      locationIds: $locationIds
      isOpenCrmWarningPopup: $isOpenCrmWarningPopup
      isActive: $isActive
    ) {
      statusCode
      message
      companyRole {
        id
        name
        isOpenCrmWarningPopup
        companyId
        parentRoleId
        isSmaiRole
        isActive
        locations {
          location {
            id
            title
          }
        }
      }
    }
  }
`;

export const DELETE_COMPANY_ROLE = gql`
  mutation DeleteCompanyRole($companyRoleId: Int!) {
    deleteCompanyRole(companyRoleId: $companyRoleId) {
      statusCode
      message
    }
  }
`;

export const UPDATE_COMPANY_CONSENT_TEMPLATE = gql`
  mutation updateCompanyConsentTemplateEvent(
    $companyId: Int!
    $companyConsentTemplateEventInputs: [CompanyConsentTemplateEventInputs]!
  ) {
    updateCompanyConsentTemplateEvent(
      companyId: $companyId
      companyConsentTemplateEventInputs: $companyConsentTemplateEventInputs
    ) {
      statusCode
      message
      companyConsentTemplateEvents {
        consentTemplateEvent {
          id
          code
          title
          isActive
        }
        companyConsentTemplateEvent {
          id
          consentTemplateEventId
          companyId
          templateText
          afterTextMode
          isActive
          onlyFirstMessage
        }
      }
    }
  }
`;
export const UPDATE_COMPANY_REVIEW_TEMPLATE = gql`
  mutation updateCompanyReviewTemplateEvent(
    $companyId: Int!
    $companyReviewTemplateEventInputs: [CompanyReviewTemplateEventInputs]!
  ) {
    updateCompanyReviewTemplateEvent(
      companyId: $companyId
      companyReviewTemplateEventInputs: $companyReviewTemplateEventInputs
    ) {
      statusCode
      message
      companyReviewTemplateEvents {
        reviewTemplateEvent {
          id
          code
          title
          isActive
        }
        companyReviewTemplateEvent {
          id
          reviewTemplateEventId
          companyId
          templateText
          isActive
        }
      }
    }
  }
`;
export const UPDATE_COMPANY_GENERAL_TEMPLATE_SETTINGS = gql`
  mutation updateGeneralSetting(
    $companyId: Int!
    $companyGeneralTemplateSettingInputs: [CompanyGeneralTemplateSettingInputs]!
    $isSendSmsAppointment: Boolean!
    $isSendSmsNewLead: Boolean!
    $isSendSmsSoldLead: Boolean!
    $isSendEmailAppointment: Boolean!
    $isSendEmailNewLead: Boolean!
    $isSendEmailSoldLead: Boolean!
  ) {
    updateGeneralSetting(
      companyId: $companyId
      companyGeneralTemplateSettingInputs: $companyGeneralTemplateSettingInputs
      isSendSmsAppointment: $isSendSmsAppointment
      isSendSmsNewLead: $isSendSmsNewLead
      isSendSmsSoldLead: $isSendSmsSoldLead
      isSendEmailAppointment: $isSendEmailAppointment
      isSendEmailNewLead: $isSendEmailNewLead
      isSendEmailSoldLead: $isSendEmailSoldLead
    ) {
      statusCode
      message
      companyGeneralTemplateSettingEvents {
        generalTemplateSettingEvent {
          id
          code
          title
          isActive
        }
        companyGeneralTemplateSetting {
          id
          templateText
          isNudge
          isAppointment
          isConsent
          isReview
          isOtto
          isActive
        }
      }
      crmIntegrationSetting {
        id
        crmIntegrationId
        isSendSmsAppointment
        isSendSmsSoldLead
        isSendSmsNewLead
        isSendEmailAppointment
        isSendEmailSoldLead
        isSendEmailNewLead
      }
    }
  }
`;
export const CREATE_LEAD_VEHICLE_INVENTORY = gql`
  mutation createDsLeadVehicleInventory($leadId: Int!, $dsVehicleInventoryId: Int!) {
    createDsLeadVehicleInventory(leadId: $leadId, dsVehicleInventoryId: $dsVehicleInventoryId) {
      statusCode
      message
      dsLeadVehicleInventory {
        id
        dsVehicleInventoryId
        leadVehicleOfInterestId
      }
    }
  }
`;

export const DELETE_LEAD_VEHICLE_INVENTORY = gql`
  mutation deleteDsLeadVehicleInventory($leadVehicleOfInterestId: Int!) {
    deleteDsLeadVehicleInventory(leadVehicleOfInterestId: $leadVehicleOfInterestId) {
      statusCode
      message
    }
  }
`;
export const RETRY_MESSAGE_SEND = gql`
  mutation retryMessage($messageId: Int!) {
    retryMessage(messageId: $messageId) {
      statusCode
      message
      leadMessage {
        id
        systemUserId
        leadId
        userId
        channelId
        campaignId
        campaignTemplateId
        direction
        dateSent
        dateReceived
        content
        errorCode
        errorMessage
        statusCode
        messageStatus
        messageType
        messageLog {
          id
          fromPhone
          toPhone
        }
      }
    }
  }
`;

export const EDIT_USER_LOCATION = gql`
  mutation sendLeadToStore($leadId: Int!, $locationId: Int!) {
    sendLeadToStore(leadId: $leadId, locationId: $locationId, isSyncCheck: true, isPushCrm: true) {
      statusCode
      message
      lead {
        id
        firstName
        lastName
        location {
          id
          title
        }
      }
    }
  }
`;
export const CREATE_OPPORTUNITY = gql`
  mutation createLeadOpportunity(
    $leadId: Int!
    $opportunityTypeId: Int!
    $opportunityStatusId: Int!
    $name: String!
  ) {
    createLeadOpportunity(
      leadId: $leadId
      opportunityTypeId: $opportunityTypeId
      name: $name
      opportunityStatusId: $opportunityStatusId
    ) {
      statusCode
      message
      leadOpportunity {
        id
        name
        opportunityType {
          id
          name
          parentType {
            id
            name
          }
        }
        opportunityStatus {
          id
          name
          parentStatus {
            id
            name
          }
        }
      }
    }
  }
`;
export const UPDATE_USER_PHONE = gql`
  mutation updateUserPhone($phone: String!) {
    updateUserPhone(phone: $phone) {
      statusCode
      message
      user {
        id
        phone
      }
    }
  }
`;

export const CREATE_COMPANY_LEAD_SOURCE = gql`
  mutation createCompanyLeadSource(
    $companyId: Int!
    $consentOption: String!
    $name: String!
    $parentId: Int
  ) {
    createCompanyLeadSource(
      companyId: $companyId
      consentOption: $consentOption
      name: $name
      parentId: $parentId
    ) {
      statusCode
      message
      leadSource {
        id
        name
        parentSource {
          id
          name
        }
      }
      companyLeadSource {
        id
        consentOption
      }
    }
  }
`;
export const UPDATE_COMPANY_LEAD_SOURCE = gql`
  mutation updateCompanySourceConsentOption(
    $emails: [String]!
    $companySourceConsentOptions: [CompanySourceConsentOption]
    $companyId: Int!
  ) {
    updateCompanySourceConsentOption(
      emails: $emails
      companySourceConsentOptions: $companySourceConsentOptions
      companyId: $companyId
    ) {
      statusCode
      message
    }
  }
`;

export const PULL_LEAD_FROM_CRM = gql`
  mutation pullCrmLead($crmLeadId: String!, $locationId: Int!) {
    pullCrmLead(crmLeadId: $crmLeadId, locationId: $locationId) {
      statusCode
      message
      lead {
        id
        firstName
        lastName
        fullName
        otherSource
        unreadCount
        leadCreatedDate
        leadSourceOriginalId
        textConsentStatus
        status
        location {
          id
        }
        leadNotes {
          id
          note
          createdOn
        }
        messages {
          id
          dateReceived
          dateSent
        }
        phoneNumbers {
          id
          phone
          phoneType
          lookupType
        }
        leadVehicleInventory {
          id
          make
          model
          year
        }
        vehicleOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        leadSource {
          name
          id
          parentSource {
            id
            name
          }
        }
        leadNotes {
          id
          note
          createdOn
        }
        activeAppointment {
          id
          startDatetime
          isConfirmed
          appointmentStatus
        }
        crmLeadObj {
          crmStatus
          parentCrmStatus
          activeLeadOpportunity {
            opportunityStatus {
              id
              name
              parentStatus {
                id
                name
              }
            }
            opportunitySource {
              id
              name
              parentSource {
                id
                name
                parentSource {
                  id
                  name
                }
              }
            }
            vehiclesOfInterest {
              id
              leadId
              year
              make
              model
              trim
              description
              budget
              customerInterest
              inputType
              isCurrent
              isPrimary
              stockNumber
              vin
            }
          }
        }
        leadStatusType {
          id
          type
        }
        leadOpportunities {
          id
          name
          createdDate
          updatedDate
          leadOpportunityUsers {
            id
            userTypeId
            userId
            isActive
            user {
              id
              fullName
              companyRole {
                id
                name
              }
            }
            userType {
              name
            }
          }
          vehiclesOfInterest {
            id
            leadId
            year
            make
            model
            trim
            description
            budget
            customerInterest
            inputType
            isCurrent
            isPrimary
            stockNumber
            vin
          }
          opportunityTypeId
          opportunityStatusId
          opportunitySourceId
          opportunityType {
            id
            name
          }
          opportunityStatus {
            id
            name
          }
          opportunitySource {
            id
            name
            parentSource {
              id
              name
              parentSource {
                id
                name
              }
            }
          }
          dsOpportunityEvents {
            id
            dsEventId
            dsEntitySource
            dsMarketingChannelName
            dsTrackingCode
          }
          sfLeadOpportunities {
            id
            sfOpportunityId
            sfOpportunityStage
          }
          vsLeadOpportunities {
            id
            vsContactId
            vsLeadId
            vsLeadStatusMapping {
              vsLeadStatusType
              vsLeadStatus
            }
          }
          elLeadOpportunities {
            id
            elOpportunityId
            elLeadId
          }
          mfLeadOpportunities {
            id
            mfProspectId
            mfCustomerId
            mfLeadId
          }
        }
      }
    }
  }
`;
export const UPDATE_OPPORTUNITY_STAGE_NAME = gql`
  mutation updateSfLeadStage($leadOpportunityId: Int!, $stageName: String!) {
    updateSfLeadStage(leadOpportunityId: $leadOpportunityId, stageName: $stageName) {
      statusCode
      message
    }
  }
`;

export const OPPORTUNITY_REDIRECTION_DIALOG = gql`
  mutation updateOpenCrmWarningPopup($locationId: Int!, $isOpenCrmWarningPopup: Boolean!) {
    updateOpenCrmWarningPopup(
      locationId: $locationId
      isOpenCrmWarningPopup: $isOpenCrmWarningPopup
    ) {
      statusCode
      message
    }
  }
`;

export const CREATE_LEAD_Without_Phone_Lookup = gql`
  mutation createLeadWithoutPhoneLookup(
    $fullName: String!
    $leadSourceType: String!
    $firstName: String
    $lastName: String
    $leadSourceOriginalId: Int
    $phone: String
    $leadStatusTypeId: Int
    $leadStatusDescription: String
    $otherSource: String
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
    $primaryOwnerId: Int
    $ownerIds: [UserMapping]
  ) {
    createLeadWithoutPhoneLookup(
      fullName: $fullName
      leadSourceType: $leadSourceType
      firstName: $firstName
      lastName: $lastName
      leadSourceOriginalId: $leadSourceOriginalId
      phone: $phone
      leadStatusTypeId: $leadStatusTypeId
      leadStatusDescription: $leadStatusDescription
      otherSource: $otherSource
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
      primaryOwnerId: $primaryOwnerId
      ownerIds: $ownerIds
    ) {
      statusCode
      message
      lead {
        id
        fullName
        firstName
        lastName
        dateOfBirth
        leadSourceType
        leadSourceOriginalId
        companyId
        leadFileId
        crmIntegrationId
        status
        emailConsent
        emailConsentDate
        textConsent
        textConsentDate
        textConsentStatus
        otherSource
        leadStatusTypeId
        leadStatusType {
          id
          type
          status
        }
        emails {
          id
          leadId
          email
          emailType
        }
        phoneNumbers {
          id
          leadId
          phone
          phoneType
          lookupType
        }
        addresses {
          id
          leadId
          locationText
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
        vehicleOfInterest {
          id
          leadId
          year
          make
          model
          trim
          description
          budget
          customerInterest
          inputType
          isCurrent
          isPrimary
          stockNumber
          vin
        }
        leadSource {
          id
          name
        }
        leadUsers {
          userType {
            id
            useCase
            name
          }
          user {
            id
            email
            firstName
            lastName
            companyRole {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const CREATE_LEAD_PHONE_WITHOUT_LOOKUP = gql`
  mutation createLeadPhoneWithoutPhoneLookup(
    $leadId: Int!
    $phone: String!
    $phoneType: String
    $isSyncCheck: Boolean
    $isPushCrm: Boolean
  ) {
    createLeadPhoneWithoutPhoneLookup(
      leadId: $leadId
      phone: $phone
      phoneType: $phoneType
      isSyncCheck: $isSyncCheck
      isPushCrm: $isPushCrm
    ) {
      statusCode
      message
      leadPhone {
        id
        phone
        phoneType
      }
    }
  }
`;
export const ADD_REVIEW_TAGS = gql`
  mutation createReviewUser($reviewId: Int!, $taggedUserIds: [Int]) {
    createReviewUser(reviewId: $reviewId, taggedUserIds: $taggedUserIds) {
      statusCode
      message
    }
  }
`;

export const CREATE_MMS = gql`
  mutation createMms(
    $userId: Int!
    $leadId: Int!
    $channelId: Int
    $campaignId: Int
    $campaignTemplateId: Int
    $direction: String
    $content: String
    $toPhone: String!
    $reviewLink: String
    $leadOpportunityId: Int
    $systemUserId: Int
    $mmsObjects: [Media]!
  ) {
    createMms(
      leadId: $leadId
      userId: $userId
      channelId: $channelId
      campaignId: $campaignId
      campaignTemplateId: $campaignTemplateId
      direction: $direction
      content: $content
      toPhone: $toPhone
      reviewLink: $reviewLink
      leadOpportunityId: $leadOpportunityId
      systemUserId: $systemUserId
      medias: $mmsObjects
    ) {
      statusCode
      message
      leadMessage {
        id
        systemUserId
        leadId
        userId
        channelId
        campaignId
        campaignTemplateId
        direction
        dateSent
        dateReceived
        content
        messageType
        errorCode
        errorMessage
        statusCode
        messageStatus
        messageLog {
          id
          fromPhone
          toPhone
        }
        mmsObjects {
          id
          mediaUrl
          compressedMediaUrl
          thumbnail
          size
          mediaType
        }
        leadOpportunity {
          id
          name
          opportunityType {
            id
            name
            parentType {
              id
              name
            }
          }
          opportunityStatus {
            id
            name
            parentStatus {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_GENERIC_FILTER = gql`
  mutation createGenericFilter(
    $filterJson: String!
    $filterName: String
    $isCurrent: Boolean
    $genericFilterTypeId: Int!
  ) {
    createGenericFilter(
      filterJson: $filterJson
      filterName: $filterName
      isCurrent: $isCurrent
      genericFilterTypeId: $genericFilterTypeId
    ) {
      statusCode
      message
    }
  }
`;

export const CREATE_WEB_CHAT_WIDGET = gql`
  mutation createWebChatWidget(
    $initialText: String
    $isPhoneRequired: Boolean!
    $locationId: Int!
    $presetQuestionInd: Boolean!
    $questions: [String]
    $switchToSmsInd: Boolean!
    $userIds: [Int]
    $teamId: Int
  ) {
    createWebChatWidget(
      initialText: $initialText
      isPhoneRequired: $isPhoneRequired
      locationId: $locationId
      presetQuestionInd: $presetQuestionInd
      questions: $questions
      switchToSmsInd: $switchToSmsInd
      userIds: $userIds
      teamId: $teamId
    ) {
      statusCode
      message
    }
  }
`;

export const UPDATE_GENERIC_FILTER = gql`
  mutation updateGenericFilter(
    $filterJson: String
    $id: Int
    $filterName: String
    $isCurrent: Boolean
    $genericFilterTypeId: Int
  ) {
    updateGenericFilter(
      filterJson: $filterJson
      id: $id
      filterName: $filterName
      isCurrent: $isCurrent
      genericFilterTypeId: $genericFilterTypeId
    ) {
      statusCode
      message
    }
  }
`;

export const UPDATE_WEB_CHAT_WIDGET = gql`
  mutation updateWebChatWidget(
    $initialText: String
    $isPhoneRequired: Boolean!
    $locationId: Int!
    $presetQuestionInd: Boolean!
    $questions: [String]
    $switchToSmsInd: Boolean!
    $userIds: [Int]
    $teamId: Int
  ) {
    updateWebChatWidget(
      initialText: $initialText
      isPhoneRequired: $isPhoneRequired
      locationId: $locationId
      presetQuestionInd: $presetQuestionInd
      questions: $questions
      switchToSmsInd: $switchToSmsInd
      userIds: $userIds
      teamId: $teamId
    ) {
      statusCode
      message
    }
  }
`;

export const DELETE_GENERIC_FILTER = gql`
  mutation deleteGenericFilter($id: Int!) {
    deleteGenericFilter(id: $id) {
      statusCode
      message
    }
  }
`;

export const CREATE_DUPLICATE_LEAD = gql`
  mutation createDuplicateLead($leadId: Int, $locationId: Int) {
    createDuplicateLead(leadId: $leadId, locationId: $locationId) {
      statusCode
      message
    }
  }
`;

export const UPDATE_APPOINTMENT_STATUS = gql`
  mutation updateAppointmentStatus(
    $appointmentId: Int!
    $latestAppointmentStatus: String!
    $leadId: Int!
    $userId: Int!
  ) {
    updateAppointmentStatus(
      appointmentId: $appointmentId
      latestAppointmentStatus: $latestAppointmentStatus
      leadId: $leadId
      userId: $userId
    ) {
      appointment {
        id
      }
      statusCode
      message
    }
  }
`;
export const UPDATE_APPOINTMENT_STATUS_WITH_TOKEN = gql`
  mutation updateAppointmentStatusWithToken(
    $appointmentToken: String!
    $newAppointmentStatus: String!
  ) {
    updateAppointmentStatusWithToken(
      appointmentToken: $appointmentToken
      newAppointmentStatus: $newAppointmentStatus
    ) {
      statusCode
      message
      redirectUrl
    }
  }
`;
//  If not found dsLeadId, firstName,lastName , dont call this mutation

export const ADD_LEAD_FROM_EXTENSION = gql`
  mutation addLeadFromExtension(
    $dsLeadId: String
    $firstName: String!
    $lastName: String!
    $location: String!
    $phone: String!
    $userId: Int!
  ) {
    addLeadFromExtension(
      dsLeadId: $dsLeadId
      firstName: $firstName
      lastName: $lastName
      location: $location
      phone: $phone
      userId: $userId
    ) {
      statusCode
      message
      # lead {
      #   id
      #   fullName
      #   firstName
      #   lastName
      #   leadSourceType
      #   leadSourceOriginalId
      #   companyId
      #   locationId
      #   leadFileId
      #   crmIntegrationId
      #   leadStatusTypeId
      #   leadSource {
      #     id
      #     name
      #   }
      #   phoneNumbers {
      #     id
      #     phone
      #     phoneType
      #   }
      # }
    }
  }
`;

export const OPT_OUT_LEAD = gql`
  mutation optOutLead($leadId: Int!) {
    optOutLead(leadId: $leadId) {
      statusCode
      message
    }
  }
`;

export const SAVE_NOTIFICATION_CONFIG = gql`
  mutation SaveNotificationConfig(
    $notificationsAllowed: Boolean!
    $id: Int
    $dndStart: Time
    $dndEnd: Time
    $timezone: String
    $app: Boolean
    $sms: Boolean
    $responses: Boolean
    $email: Boolean
    $interval: String
    $details: [NotificationConfigDetailInputs]
  ) {
    saveNotificationConfig(
      notificationsAllowed: $notificationsAllowed
      id: $id
      dndStart: $dndStart
      dndEnd: $dndEnd
      timezone: $timezone
      app: $app
      sms: $sms
      responses: $responses
      email: $email
      interval: $interval
      details: $details
    ) {
      ok
    }
  }
`;
export const CREATE_REVIEW_RESPONSE = gql`
  mutation createReviewResponse($comment: String!, $reviewId: Int!) {
    createReviewResponse(
      comment: $comment

      reviewId: $reviewId
    ) {
      statusCode
      message
      reviewResponse {
        id
        message
      }
    }
  }
`;
export const UPDATE_REVIEW_RESPONSE = gql`
  mutation updateReviewResponse($comment: String!, $id: Int!) {
    updateReviewResponse(comment: $comment, id: $id) {
      statusCode
      message
      reviewResponse {
        id
        message
      }
    }
  }
`;
export const CREATE_SOCIAL_REVIEW_SETTING = gql`
  mutation createSocialReviewSetting(
    $companyId: Int!
    $locationId: Int!
    $platform: String!
    $accountId: String
    $accessToken: String
    $reviewPostUrl: String
    $platformUrl: String
    $accountLocationId: String
    $isOauthConnected: Boolean
    $siteName: String
    $isPrimary: Boolean
    $readOnlyFacebookPagesFetch: Boolean
    $pageId: String
    $pageName: String
  ) {
    createSocialReviewSetting(
      companyId: $companyId
      locationId: $locationId
      platform: $platform
      accountId: $accountId
      accessToken: $accessToken
      reviewPostUrl: $reviewPostUrl
      platformUrl: $platformUrl
      accountLocationId: $accountLocationId
      isOauthConnected: $isOauthConnected
      siteName: $siteName
      isPrimary: $isPrimary
      readOnlyFacebookPagesFetch: $readOnlyFacebookPagesFetch
      pageId: $pageId
      pageName: $pageName
    ) {
      statusCode
      message
      socialReviewSetting {
        id
        companyId
        locationId
        platform
        platformUrl
        reviewPostUrl
        accessToken
        accountId
        accountLocationId
        isActive
      }
      accounts
      pages
    }
  }
`;

export const UPDATE_SOCIAL_REVIEW_SETTING = gql`
  mutation updateSocialReviewSetting(
    $socialReviewSettingsId: Int!
    $companyId: Int!
    $locationId: Int!
    $platform: String!
    $accountId: String
    $accessToken: String
    $reviewPostUrl: String
    $platformUrl: String
    $accountLocationId: String
    $isActive: Boolean # $readOnlyGoogleAccountFetch: Boolean
    $isOauthConnected: Boolean
    $siteName: String
    $isPrimary: Boolean
    $readOnlyFacebookPagesFetch: Boolean
    $readOnlyGoogleAccountFetch: Boolean
    $pageId: String
    $pageName: String
    $businessName: String
  ) {
    updateSocialReviewSetting(
      socialReviewSettingsId: $socialReviewSettingsId
      companyId: $companyId
      locationId: $locationId
      platform: $platform
      accountId: $accountId
      accessToken: $accessToken
      reviewPostUrl: $reviewPostUrl
      platformUrl: $platformUrl
      accountLocationId: $accountLocationId
      isActive: $isActive # readOnlyGoogleAccountFetch: $readOnlyGoogleAccountFetch
      isOauthConnected: $isOauthConnected
      siteName: $siteName
      isPrimary: $isPrimary
      readOnlyFacebookPagesFetch: $readOnlyFacebookPagesFetch
      readOnlyGoogleAccountFetch: $readOnlyGoogleAccountFetch
      pageId: $pageId
      pageName: $pageName
      businessName: $businessName
    ) {
      statusCode
      message
      accounts
      pages
      socialReviewSetting {
        id
        companyId
        locationId
        platform
        platformUrl
        reviewPostUrl
        accessToken
        accountId
        accountLocationId
        isActive
      }
    }
  }
`;
export const CREATE_AUTH_URL = gql`
  mutation createAuthUrl(
    $companyId: Int!
    $locationId: Int!
    $platform: String!
    $reviewPostUrl: String!
    $isOauthConnected: Boolean
    $isPrimary: Boolean
  ) {
    createAuthUrl(
      companyId: $companyId
      locationId: $locationId
      platform: $platform
      reviewPostUrl: $reviewPostUrl
      isOauthConnected: $isOauthConnected
      isPrimary: $isPrimary
    ) {
      statusCode
      message
      authUrl
    }
  }
`;

export const REVIEW_TRACKING = gql`
  mutation createReviewTracking(
    $actionType: ReviewTrackingActionType
    $actionValue: ReviewTrackingActionValue
    $companyId: Int!
    $leadId: Int
    $locationId: Int
    $userId: Int
    $code: String
    $reviewUrl: String
  ) {
    createReviewTracking(
      actionType: $actionType
      actionValue: $actionValue
      companyId: $companyId
      locationId: $locationId
      leadId: $leadId
      userId: $userId
      shortenCode: $code
      reviewUrl: $reviewUrl
    ) {
      statusCode
      message
    }
  }
`;

export const CREATE_SCHEDULED_MESSAGE = gql`
  mutation createScheduledMessage(
    $leadId: Int!
    $scheduledDate: DateTime!
    $timezone: String!
    $message: String!
  ) {
    createScheduledMessage(
      leadId: $leadId
      scheduledDate: $scheduledDate
      timezone: $timezone
      message: $message
    ) {
      statusCode
      message
      scheduledMessage {
        id
        leadId
        user {
          firstName
          lastName
        }
        taskSendDatetime
        message
      }
    }
  }
`;

export const UPDATE_SCHEDULED_MESSAGE = gql`
  mutation updateScheduledMessage(
    $id: Int!
    $scheduledDate: DateTime!
    $timezone: String!
    $message: String!
  ) {
    updateScheduledMessage(
      id: $id
      scheduledDate: $scheduledDate
      timezone: $timezone
      message: $message
    ) {
      statusCode
      message
      scheduledMessage {
        id
        leadId
        user {
          firstName
          lastName
        }
        taskSendDatetime
        message
      }
    }
  }
`;

export const DELETE_SCHEDULED_MESSAGE = gql`
  mutation deleteScheduledMessage($id: Int!, $timezone: String!) {
    deleteScheduledMessage(id: $id, timezone: $timezone) {
      statusCode
      message
    }
  }
`;

export const CREATE_REVIEW_LEAD = gql`
  mutation createReviewLead($leadId: Int!, $socialReviewId: Int!) {
    createReviewLead(leadId: $leadId, socialReviewId: $socialReviewId) {
      statusCode
      message
    }
  }
`;
export const DELETE_REVIEW_LEAD = gql`
  mutation deleteReviewLead($socialReviewId: Int!) {
    deleteReviewLead(socialReviewId: $socialReviewId) {
      statusCode
      message
    }
  }
`;
export const CREATE_VDP_LINK_INVENTORY = gql`
  mutation createVdpLinksVehicleInventory($locationId: Int!, $vehicleIds: [Int]!) {
    createVdpLinksVehicleInventory(locationId: $locationId, vehicleIds: $vehicleIds) {
      message
      statusCode
      vdpLinks
    }
  }
`;

export const CREATE_CAMPAIGN_V2 = gql`
  mutation createCampaign(
    $activeInd: String
    $campaignLeads: [Int]
    $endDate: DateTime
    $isDisabled: Boolean
    $isPrioritize: Boolean
    $messages: [CampaignMessage]
    $method: String
    $name: String!
    $source: [Int]
    $startDate: DateTime
    $status: [Int]
    $textMessage: String
  ) {
    createCampaign(
      activeInd: $activeInd
      campaignLeads: $campaignLeads
      endDate: $endDate
      isDisabled: $isDisabled
      isPrioritize: $isPrioritize
      messages: $messages
      method: $method
      name: $name
      source: $source
      startDate: $startDate
      status: $status
      textMessage: $textMessage
    ) {
      statusCode
      message
    }
  }
`;
export const UPDATE_LEAD_OPPORTUNITY = gql`
  mutation updateLeadOpportunity(
    $addressType: String
    $dsEntityId: Int
    $dsEventId: Int
    $isPushCrm: Boolean
    $isSyncCheck: Boolean
    $leadOpportunityId: Int!
    $leadOpportunityUsers: [UserMapping]
    $leadSourceType: String
    $locationId: Int
    $opportunityName: String
    $opportunitySourceId: Int
    $opportunityStatusId: Int
    $opportunityTypeId: Int
    $vehicleOfInterests: [VehicleOfInterestInputObject]
  ) {
    updateLeadOpportunity(
      addressType: $addressType
      dsEntityId: $dsEntityId
      dsEventId: $dsEventId
      isPushCrm: $isPushCrm
      isSyncCheck: $isSyncCheck
      leadOpportunityId: $leadOpportunityId
      leadOpportunityUsers: $leadOpportunityUsers
      leadSourceType: $leadSourceType
      locationId: $locationId
      opportunityName: $opportunityName
      opportunitySourceId: $opportunitySourceId
      opportunityStatusId: $opportunityStatusId
      opportunityTypeId: $opportunityTypeId
      vehicleOfInterests: $vehicleOfInterests
    ) {
      statusCode
      message
    }
  }
`;

export const CREATE_LEAD_OPPORTUNITY = gql`
  mutation createLeadOpportunity(
    $leadId: Int!
    $leadOpportunityUsers: [UserMapping]
    $name: String!
    $opportunityStatusId: Int!
    $opportunityTypeId: Int!
    $locationId: Int
    $opportunitySourceId: Int!
    $vehicleOfInterests: [VehicleOfInterestInputObject]
  ) {
    createLeadOpportunity(
      leadId: $leadId
      leadOpportunityUsers: $leadOpportunityUsers
      name: $name
      opportunityStatusId: $opportunityStatusId
      opportunityTypeId: $opportunityTypeId
      locationId: $locationId
      opportunitySourceId: $opportunitySourceId
      vehicleOfInterests: $vehicleOfInterests
    ) {
      statusCode
      message
    }
  }
`;

export const DELETE_LEAD_OPPORTUNITY = gql`
  mutation deleteLeadOpportunity($leadOpportunityId: Int!) {
    deleteLeadOpportunity(leadOpportunityId: $leadOpportunityId) {
      statusCode
      message
    }
  }
`;

export const CREATE_PROMPT = gql`
  mutation createPrompt(
    $applicationLevelId: Int!
    $applicationUseCaseId: Int!
    $companyId: Int
    $leadSourceOriginalIds: [Int]
    $locationId: Int
    $promptText: String!
    $companyRoleId: Int
    $teamId: Int
    $userId: Int
  ) {
    createPrompt(
      applicationLevelId: $applicationLevelId
      applicationUseCaseId: $applicationUseCaseId
      companyId: $companyId
      leadSourceOriginalIds: $leadSourceOriginalIds
      locationId: $locationId
      promptText: $promptText
      companyRoleId: $companyRoleId
      teamId: $teamId
      userId: $userId
    ) {
      statusCode
      message
    }
  }
`;

export const UPDATE_PROMPT = gql`
  mutation updatePrompt($id: Int!, $leadSourceOriginalIds: [Int], $promptText: String!) {
    updatePrompt(id: $id, leadSourceOriginalIds: $leadSourceOriginalIds, promptText: $promptText) {
      statusCode
      message
    }
  }
`;

export const DELETE_PROMPT = gql`
  mutation deletePrompt($id: Int!) {
    deletePrompt(id: $id) {
      statusCode
      message
    }
  }
`;

// add calenders mutation
export const MODIFIED_COMPANY_SCHEDULE = gql`
  mutation modifyCompanySchedule($companyId: Int, $schedule: WorkingHoursScheduleInput) {
    modifyCompanySchedule(companyId: $companyId, schedule: $schedule) {
      statusCode
      message
    }
  }
`;

export const MODIFIED_LOCATION_SCHEDULE = gql`
  mutation modifyLocationSchedule($locationId: Int, $locationSchedule: WorkingHoursScheduleInput) {
    modifyLocationSchedule(locationId: $locationId, locationSchedule: $locationSchedule) {
      statusCode
      message
    }
  }
`;

export const CREATE_USER_SCHEDULE = gql`
  mutation createUserSchedule(
    $fromDatetime: DateTime
    $isAvailable: Boolean
    $name: String
    $toDatetime: DateTime
    $locationId: Int!
    $isFullDay: Boolean
  ) {
    createUserSchedule(
      fromDatetime: $fromDatetime
      isAvailable: $isAvailable
      name: $name
      toDatetime: $toDatetime
      locationId: $locationId
      isFullDay: $isFullDay
    ) {
      statusCode
      message
    }
  }
`;

export const UPDATE_USER_SCHEDULE = gql`
  mutation updateUserSchedule(
    $fromDatetime: DateTime
    $id: Int!
    $isAvailable: Boolean
    $name: String
    $toDatetime: DateTime
    $locationId: Int!
    $isFullDay: Boolean
  ) {
    updateUserSchedule(
      fromDatetime: $fromDatetime
      id: $id
      isAvailable: $isAvailable
      name: $name
      toDatetime: $toDatetime
      locationId: $locationId
      isFullDay: $isFullDay
    ) {
      statusCode
      message
    }
  }
`;

export const DELETE_USER_SCHEDULE = gql`
  mutation deleteUserSchedule($id: Int) {
    deleteUserSchedule(id: $id) {
      statusCode
      message
    }
  }
`;

export const UPDATE_USER_WORKING_HOURS = gql`
  mutation updateUserWorkingHours($inputWorkingHours: [WorkingHourInputs]!, $locationId: Int!) {
    updateUserWorkingHours(inputWorkingHours: $inputWorkingHours, locationId: $locationId) {
      statusCode
      message
      workingHours {
        id
        userId
        weekDay
        isWorkingDay
        startTime
        endTime
        createdOn
        isActive
        isDeleted
        user {
          id
          email
          firstName
          lastName
          companyId
        }
      }
    }
  }
`;
// end add calenders mutation

export const DELETE_NOTIFICATIONS = gql`
  mutation deleteNotification($notificationIds: [Int]) {
    deleteNotification(notificationIds: $notificationIds) {
      statusCode
      message
    }
  }
`;

export const BROADCAST_UNREAD_COUNT = gql`
  mutation updateUnreadCount($leadId: Int) {
    updateUnreadCount(leadId: $leadId) {
      statusCode
      unreadCount
      message
    }
  }
`;

export const TRANSLATE_MESSAGE = gql`
  mutation translateMessage(
    $content: String!
    $sourceLanguage: String
    $targetLanguage: String
    $messageId: Int
  ) {
    translateMessage(
      content: $content
      sourceLanguage: $sourceLanguage
      targetLanguage: $targetLanguage
      messageId: $messageId
    ) {
      statusCode
      message
      translatedContent
    }
  }
`;

export const UPDATE_LEAD_PREFFREDLANGUAGE = gql`
  mutation updateLeadPreferredLanguage($leadId: Int!, $preferredLanguageCode: String!) {
    updateLeadPreferredLanguage(leadId: $leadId, preferredLanguageCode: $preferredLanguageCode) {
      statusCode
      message
      preferredLanguageCode
    }
  }
`;

export const UPDATE_TRANSLATION_SETTINGS = gql`
  mutation modifyTranslationSetting(
    $customerLanguage: String
    $userLanguage: String
    $isAutomaticTranslation: Boolean
    $isIncludeOriginalMessage: Boolean
    $isTranslationPreview: Boolean
    $leadId: Int!
  ) {
    modifyTranslationSetting(
      customerLanguage: $customerLanguage
      userLanguage: $userLanguage
      isAutomaticTranslation: $isAutomaticTranslation
      isIncludeOriginalMessage: $isIncludeOriginalMessage
      isTranslationPreview: $isTranslationPreview
      leadId: $leadId
    ) {
      statusCode
      message
      translationSetting {
        customerLanguage
        userLanguage
        isAutomaticTranslation
        isIncludeOriginalMessage
        isTranslationPreview
      }
    }
  }
`;
export const CREATE_NOTE = gql`
  mutation createNote(
    $leadId: Int!
    $leadOpportunityId: Int
    $note: String!
    $noteType: LeadNotesType!
    $userId: Int
  ) {
    createNote(
      leadId: $leadId
      leadOpportunityId: $leadOpportunityId
      note: $note
      noteType: $noteType
      userId: $userId
    ) {
      message
      statusCode
      leadNote {
        id
        leadId
        leadOpportunity {
          id
          name
        }
        note
        createTs
        updateTs
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;
export const DELETE_NOTE = gql`
  mutation deleteNote($id: Int!) {
    deleteNote(id: $id) {
      message
      statusCode
    }
  }
`;
export const UPDATE_NOTE = gql`
  mutation updateNote(
    $id: Int!
    $leadId: Int
    $leadOpportunityId: Int
    $note: String
    $noteType: LeadNotesType!
    $userId: Int
  ) {
    updateNote(
      id: $id
      leadId: $leadId
      leadOpportunityId: $leadOpportunityId
      note: $note
      noteType: $noteType
      userId: $userId
    ) {
      message
      statusCode
      leadNote {
        id
        leadId
        leadOpportunity {
          id
          name
        }
        note
        createTs
        updateTs
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;
