/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
/* eslint-disable no-else-return */
/* eslint-disable no-use-before-define */
import { useLazyQuery, useQuery } from "@apollo/client";
import { Tag, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../amplify/authenticator-provider";
import { GET_CRM_LEAD_STATUS_TYPE, GET_LEAD_OPPORTUNITY } from "../../graphql/query";
import { toKebabCase } from "../../utils";
import CustomTable from "../commonComponents/table";
import { crmLinkGeneration } from "../engagements/activityCenter/opporotunityLayer/crmLinks";
import { handleOpportunityDilaog } from "../engagements/activityCenter/opporotunityLayer/opportuityWarning";
import useLang from "../../hooks/useLang";
import { closeDialog } from "../../library/helpers";
import { getLeadOpportunitySource } from "../engagements/leadSourceHelper";
import { getLeadOpportunityStatus } from "../engagements/leadStatusHelper";
import { eleadsLinkGenerator } from "../engagements/activityCenter/engagementHelpers";

const CrmLeadOpportunities = props => {
  const { leadId, isFromLeadCenter, crmIntegrationType, onCustomerActivity } = props;

  const { user } = useContext(UserContext);

  const [lang] = useLang();
  const [CRMType, setCRMType] = useState("");
  const [opportunityWarningDialog, setOpportunityWarningDialog] = useState(false);
  const [isOpporDialogFlagChanged, setIsOpprDialogFlagChanged] = useState(false);
  const [opportunityInfo, setOpportunityInfo] = useState({});

  const handleOpprClick = oppr => {
    if (!crmIntegrationType && !user?.crmIntegrationTypeInfo) {
      return;
    }
    let opprName;
    let opprId;
    const isOpp =
      !!oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId;
    if (
      (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
      opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId
    ) {
      opprName =
        oppr &&
        oppr?.dsOpportunityEvents &&
        oppr?.dsOpportunityEvents.length > 0 &&
        oppr?.dsOpportunityEvents[0]?.dsEventId
          ? `Sales Opportunity - ${oppr?.name}`
          : oppr?.name;
    }

    if (
      (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
      opportunityData?.lead?.crmLeadObj?.sfExtractedLead?.sfLeadId
    ) {
      opprName = oppr?.sfLeadOpportunities[0]?.sfOpportunityId
        ? oppr?.name
        : `Lead: ${oppr?.lead?.fullName}`;
    }

    if (crmIntegrationType === "DS" || user?.crmIntegrationTypeInfo === "DS") {
      opprId =
        oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId
          ? String(oppr?.dsOpportunityEvents[0]?.dsEventId)
          : opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId
          ? String(opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId).padStart(4, "0")
          : oppr?.id;
    }

    if (crmIntegrationType === "SF" || user?.crmIntegrationTypeInfo === "SF") {
      opprId =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? String(oppr?.sfLeadOpportunities[0]?.sfOpportunityId)
          : opportunityData?.lead?.crmLeadObj?.sfExtractedLead?.sfLeadId
          ? String(opportunityData?.lead?.crmLeadObj?.sfExtractedLead?.sfLeadId)
          : oppr?.id;
    }

    if (crmIntegrationType === "VIN" || user?.crmIntegrationTypeInfo === "VIN") {
      opprId =
        oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId
          ? String(oppr?.vsLeadOpportunities[0]?.vsContactId)
          : opportunityData?.lead?.crmLeadObj?.vsExtractedLead?.vsContactId
          ? String(opportunityData?.lead?.crmLeadObj?.vsExtractedLead?.vsContactId)
          : oppr?.id;
    }

    setOpportunityInfo({ ...oppr, opprName: opprName, isOpp: isOpp, opprId: opprId });
    setOpportunityWarningDialog(true);
  };

  const handleOpportunityName = record => {
    if (crmIntegrationType === "SF" || user?.crmIntegrationTypeInfo === "SF") {
      if (
        record?.sfLeadOpportunities.length > 0 &&
        record?.sfLeadOpportunities[0]?.sfOpportunityId
      ) {
        return record?.name;
      }
      return `Lead: ${record?.lead?.fullName}`;
    }
    return record?.name;
  };

  const Link = record => {
    // DS
    if (
      (record?.dsOpportunityEvents?.length > 0 && record?.dsOpportunityEvents[0]?.dsEventId) ||
      opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId
    ) {
      const opprId = record?.dsOpportunityEvents[0]?.dsEventId
        ? String(record?.dsOpportunityEvents[0]?.dsEventId)
        : opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId
        ? String(opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId).padStart(4, "0")
        : record?.id;

      const oppLink = crmLinkGeneration(
        "DS",
        Boolean(record?.dsOpportunityEvents[0]?.dsEventId),
        opprId,
        opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId,
        user?.company?.id
      );
      return oppLink;
    }

    // SF
    if (
      (record?.sfLeadOpportunities?.length > 0 &&
        record?.sfLeadOpportunities[0]?.sfOpportunityId) ||
      opportunityData?.lead?.crmLeadObj?.sfExtractedLead?.sfLeadId
    ) {
      const opprId = record?.sfLeadOpportunities[0]?.sfOpportunityId
        ? String(record?.sfLeadOpportunities[0]?.sfOpportunityId)
        : opportunityData?.lead?.crmLeadObj?.sfExtractedLead?.sfLeadId
        ? String(opportunityData?.lead?.crmLeadObj?.sfExtractedLead?.sfLeadId)
        : record?.id;

      const oppLink = crmLinkGeneration(
        "SF",
        Boolean(record?.sfLeadOpportunities[0]?.sfOpportunityId),
        opprId,
        "",
        "usAutoSales",
        user?.company?.id
      );

      return oppLink;
    } else if (
      (record?.dtLeadOpportunities?.length > 0 &&
        record?.dtLeadOpportunities[0]?.dtOpportunityId) ||
      opportunityData?.lead?.crmLeadObj?.dtExtractedLead?.dtLeadId
    ) {
      const oppLink = crmLinkGeneration(
        "DT",
        Boolean(record?.dtLeadOpportunities[0]?.dtOpportunityId),
        record?.dtLeadOpportunities[0].dtOpportunityId,
        "",
        "dealerTeam",
        user?.company?.id
      );
      return oppLink;
    }

    // VIN SOLUTION
    if (
      (record?.vsLeadOpportunities?.length > 0 && record?.vsLeadOpportunities[0]?.vsContactId) ||
      opportunityData?.lead?.crmLeadObj?.vsExtractedLead?.vsContactId
    ) {
      const oppId =
        record?.vsLeadOpportunities[0].vsContactId ||
        opportunityData?.lead?.crmLeadObj?.vsExtractedLead?.vsContactId;
      const oppLink = crmLinkGeneration(
        "VIN",
        Boolean(record?.vsLeadOpportunities[0]?.vsContactId),
        oppId,
        "",
        "VinSolution",
        user?.company?.id
      );

      return oppLink;
    }

    if (
      record?.elLeadOpportunities?.length > 0 &&
      record?.elLeadOpportunities[0]?.elOpportunityId
    ) {
      // EL
      return eleadsLinkGenerator(record?.lead?.fullName);
    }
    return "";
  };

  const isOpprDialog = localStorage.getItem("isOpporDialogFlagChangedLocal")
    ? true
    : !handleOpportunityDilaog(user?.locations, user);

  const { data: opportunityData, loading: getOpportunityLoader } = useQuery(GET_LEAD_OPPORTUNITY, {
    variables: {
      leadId: leadId,
    },
    skip: !leadId,
  });

  const [getCrmType] = useLazyQuery(GET_CRM_LEAD_STATUS_TYPE, {
    fetchPolicy: "network-only",
    onCompleted: resp => {
      console.log(resp);
      setCRMType(resp?.getCrmLeadStatus?.crmIntegrationType);
    },
  });

  useEffect(() => {
    if (!crmIntegrationType && !user?.crmIntegrationTypeInfo) {
      getCrmType();
    }
    if (user.crmIntegrationTypeInfo) setCRMType(user.crmIntegrationTypeInfo);
  }, [crmIntegrationType, user?.crmIntegrationTypeInfo]);

  const UserName = record => {
    const users = (record?.leadOpportunityUsers || []).filter(leadUser => leadUser.isActive);
    return users.map((item, i) => (
      <Tooltip key={i} title={item?.userType?.name || ""}>
        <Tag style={{ color: i % 2 === 0 ? "green" : "blue" }}>{`${item?.user?.firstName || ""} ${
          item?.user?.lastName || ""
        }`}</Tag>
      </Tooltip>
    ));
  };

  const COLUMNS = [
    {
      title: lang.opportunityId,
      render: (text, record) => {
        const Id = () => {
          if (
            (record?.dsOpportunityEvents?.length > 0 &&
              record?.dsOpportunityEvents[0]?.dsEventId) ||
            opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId
          ) {
            const opprId = record?.dsOpportunityEvents[0]?.dsEventId
              ? String(record?.dsOpportunityEvents[0]?.dsEventId)
              : opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId
              ? String(opportunityData?.lead?.crmLeadObj?.dsExtractedLead?.dsLeadId).padStart(
                  4,
                  "0"
                )
              : record?.id;
            return `DealerSocket Opportunity ID ${opprId}`;
          }
          if (
            (record?.sfLeadOpportunities?.length > 0 &&
              record?.sfLeadOpportunities[0]?.sfOpportunityId) ||
            opportunityData?.lead?.crmLeadObj?.sfExtractedLead?.sfLeadId
          ) {
            const opprId = record?.sfLeadOpportunities[0]?.sfOpportunityId
              ? String(record?.sfLeadOpportunities[0]?.sfOpportunityId)
              : opportunityData?.lead?.crmLeadObj?.sfExtractedLead?.sfLeadId
              ? String(opportunityData?.lead?.crmLeadObj?.sfExtractedLead?.sfLeadId)
              : record?.id;
            return opprId;
          }
          if (
            (record?.dtLeadOpportunities?.length > 0 &&
              record?.dtLeadOpportunities[0]?.dtOpportunityId) ||
            opportunityData?.lead?.crmLeadObj?.dtExtractedLead?.dtLeadId
          ) {
            const opprId = record?.dtLeadOpportunities[0]?.dtOpportunityId
              ? String(record?.dtLeadOpportunities[0]?.dtOpportunityId)
              : opportunityData?.lead?.crmLeadObj?.dtExtractedLead?.dtLeadId
              ? String(opportunityData?.lead?.crmLeadObj?.dtExtractedLead?.dtLeadId)
              : record?.id;
            return opprId;
          }
          if (
            (record?.vsLeadOpportunities?.length > 0 && record?.vsLeadOpportunities[0]?.vsLeadId) ||
            opportunityData?.lead?.crmLeadObj?.vsExtractedLead?.vsLeadId
          ) {
            const opprId = record?.vsLeadOpportunities[0]?.vsLeadId
              ? String(record?.vsLeadOpportunities[0]?.vsLeadId)
              : opportunityData?.lead?.crmLeadObj?.vsExtractedLead?.vsLeadId
              ? String(opportunityData?.lead?.crmLeadObj?.vsExtractedLead?.vsLeadId)
              : record?.id;
            return `VinSolution  #${opprId}`;
          }
          if (
            record.elLeadOpportunities.length > 0 &&
            record.elLeadOpportunities[0].elOpportunityId
          ) {
            return `# ${record.elLeadOpportunities[0].elOpportunityId}`;
          }
          return `SMAI ${record?.id}`;
        };
        if (Link(record)) {
          return isOpprDialog ? (
            <a href={Link(record)} target="_blank" rel="noreferrer">
              {Id()}
            </a>
          ) : (
            <div
              className="cursor-pointer secondaryColorTheme"
              onClick={() => handleOpprClick(record)}
            >
              {Id()}
            </div>
          );
        } else {
          return isOpprDialog ? (
            <span
              className="cursor-pointer labelColor"
              onClick={() => {
                onCustomerActivity(leadId);
                closeDialog("crmLeadOpportunities");
              }}
            >
              {Id()}
            </span>
          ) : (
            <div
              className="cursor-pointer secondaryColorTheme"
              onClick={() => handleOpprClick(record)}
            >
              {Id()}
            </div>
          );
        }
      },
    },
    {
      title: lang.opportunityName,
      render: (text, record) => {
        return <span>{handleOpportunityName(record)}</span>;
      },
    },
    {
      title: lang.opportunityUser,
      render: (text, record) => {
        return <span>{UserName(record)}</span>;
      },
    },
    {
      title: lang.opportunityType,
      render: (text, record) => {
        return <span>{record?.opportunityType?.name ? record?.opportunityType?.name : "N/A"}</span>;
      },
    },
    {
      title: lang.opportunityStatus,
      render: (text, record) => {
        return <span>{getLeadOpportunityStatus(record)}</span>;
      },
    },
    {
      title: lang.opportunitySource,
      render: (text, record) => (
        <span>{getLeadOpportunitySource(record?.opportunitySource || {})}</span>
      ),
    },
  ];

  return (
    <div>
      {!isFromLeadCenter && (
        <div className="p-[1em] w-full flex items-center justify-between border border-solid border-b-[#eeeef1] border-x-0 border-t-0">
          <p data-testid="name">{toKebabCase("Lead Opportunity")}</p>
        </div>
      )}
      <CustomTable
        columns={COLUMNS}
        loading={getOpportunityLoader}
        pagination={
          opportunityData?.lead.leadOpportunities?.length <= 5
            ? false
            : {
                onChange: page => {
                  console.log(page);
                },
                pageSize: 5,
              }
        }
        data={opportunityData?.lead.leadOpportunities}
      />
    </div>
  );
};

export default CrmLeadOpportunities;
