/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialFilters = {
  setPlatforms: true,
  platforms: [],
  users: [],
  dateRange: {
    startDate: dayjs().subtract(1, "year").toISOString(),
    endDate: dayjs().toISOString(),
  },
  rating: [],
  status: ["All"],
  isAiGenerated: false,
};
const initialState = {
  filters: initialFilters,
};

const reviewSlice = createSlice({
  name: "reviewsSlice",
  initialState,
  reducers: {
    reducerSetPlatform(state, action) {
      state.filters.platforms = action.payload;
    },
    reducerSetPlatformsFlag(state, action) {
      state.filters.setPlatforms = action.payload;
    },
    reducerSetUsers(state, action) {
      state.filters.users = action.payload;
    },
    reducerSetDate(state, action) {
      state.filters.dateRange = action.payload;
    },
    reducerSetRating(state, action) {
      state.filters.rating = action.payload;
    },
    reducerSetStatus(state, action) {
      state.filters.status = action.payload;
    },
    reducerResetFilters(state, action) {
      state.filters = initialFilters;
    },
    reducerIsAiGenerated(state, action) {
      state.filters.isAiGenerated = action.payload;
    },
  },
});

export const {
  reducerSetPlatform,
  reducerSetUsers,
  reducerSetDate,
  reducerSetRating,
  reducerSetStatus,
  reducerResetFilters,
  reducerSetPlatformsFlag,
  reducerIsAiGenerated,
} = reviewSlice.actions;

export default reviewSlice.reducer;
