export const migrations = {
  1: state => ({
    apiCache: {
      ...state.apiCache,
      quickFilters: ["RESPONDED"],
    },
  }),
  2: state => {
    delete state.apiCache.quickFilters;
    return state;
  },
};

export const leadMigrations = {
  0: state => ({
    lead: state.lead,
  }),
  1: state => ({
    lead: state.selectedLead,
  }),
};

export const reviewMigrations = {
  0: state => ({
    reviews: { ...state.reviews, filters: { ...state.reviews.filters, isAiGenerated: false } },
  }),
};
