import React from "react";
import * as AntdIcons from "@ant-design/icons";

// dynamically render antd v4 icons
const AntdIconV4 = ({ type, ...rest }) => {
  // ? type will be eg. if you want to render a icon <SaveOutlined /> than pass type as props and value will be SaveOutlined.

  const AntdIcon = AntdIcons[type || "EllipsisOutlined"];

  const onClick = e => {
    if (rest.onClick) {
      rest.onClick(e);
      return 0;
    }
  };

  return rest?.style || rest?.className ? (
    <AntdIcon
      style={{ ...rest?.style }}
      disabled={rest?.disabled}
      className={rest.className}
      onClick={onClick}
      rotate={rest?.rotate}
      twoToneColor={rest?.twoToneColor}
      theme={rest?.theme}
    />
  ) : (
    <AntdIcon onClick={onClick} rotate={rest?.rotate} disabled={rest?.disabled} />
  );
};

export default AntdIconV4;
