import * as React from "react";

const ProspectsIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M19.125 11.75a.627.627 0 0 1-.875-.125A4.032 4.032 0 0 0 15 10a.626.626 0 0 1 0-1.25 1.875 1.875 0 1 0-1.816-2.344.627.627 0 0 1-1.21-.312 3.125 3.125 0 1 1 5.135 3.087c.85.367 1.589.951 2.143 1.693a.623.623 0 0 1-.127.876Zm-4.21 4.813a.625.625 0 1 1-1.08.625 4.453 4.453 0 0 0-7.67 0 .625.625 0 1 1-1.08-.625 5.629 5.629 0 0 1 2.635-2.338 3.75 3.75 0 1 1 4.56 0 5.628 5.628 0 0 1 2.636 2.338ZM10 13.75a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM5.625 9.375A.625.625 0 0 0 5 8.75a1.875 1.875 0 1 1 1.816-2.344.625.625 0 1 0 1.21-.312A3.125 3.125 0 1 0 2.892 9.18 5.307 5.307 0 0 0 .75 10.874a.625.625 0 1 0 1 .751A4.032 4.032 0 0 1 5 10a.625.625 0 0 0 .625-.625Z"
    />
  </svg>
);
export default ProspectsIcon;
