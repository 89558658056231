import React, { useEffect, useState } from "react";
import { Input } from "antd";
import AntdIconV4 from "../utils/antdIcon";
import RadioBtnGroups from "./radioBtnGroups";
import CustomButton from "../components/commonComponents/button";
import useLang from "../hooks/useLang";

const FilterTextBox = ({
  selectedKeys,
  confirmBtn = () => {},
  confirm,
  clearFilters,
  searchInputRef,
  searchKeyword,
  dataIndex = false,
  handleSortingFunction = () => {},
  activeSort = "",
  setActiveSort,
  activeSortingOrder,
  setActiveSortingOrder,
  userFilters,
  close, // antd filterDropdownProps give this function to close custome filter dropdown
  isCheckAll = 0,
}) => {
  const [lang] = useLang();

  const [searchText, setSearchText] = useState(searchKeyword || "");
  const [activeSortValue, setActiveSortValue] = useState(false);

  const handleReset = () => {
    setSearchText("");
    confirmBtn("");
    confirm("");
    dataIndex === "leadCenterLeads" && setActiveSort && setActiveSortValue(true);
    dataIndex === "leadCenterLeads" && setActiveSort && setActiveSort("latest_chat");
    dataIndex === "leadCenterLeads" && setActiveSortingOrder && setActiveSortingOrder("desc");
    dataIndex === "leadCenterLeads" && handleSortingFunction && handleSortingFunction("desc", true);
    clearFilters();
    close();
  };

  const handleSearch = () => {
    confirmBtn(searchText);
    confirm(searchText);
    close();
  };

  const handleChange = e => {
    setSearchText(e.target.value ? e.target.value : "");
  };

  useEffect(() => {
    if (isCheckAll > 0) {
      setSearchText("");
    }
  }, [isCheckAll]);

  const radioGrp = [
    { label: "Most Recent Created Date", value: "lead_created_date" },
    { label: "Name", value: "full_name" },
    { label: "Most Recent Contacted Date", value: "latest_chat" },
    { label: "Lead Status Type", value: "lead_status_type" },
  ];

  const sortingOpt = [
    { label: "Ascending", value: "asc" },
    { label: "Descending", value: "desc" },
  ];

  useEffect(() => {
    if (userFilters?.fullName?.orderDirection && userFilters?.fullName?.orderBy) {
      if (!activeSortValue) {
        setActiveSortingOrder(userFilters?.fullName?.orderDirection);
        setActiveSort(userFilters?.fullName?.orderBy);
      } else setActiveSortValue(false);
    }
  }, [userFilters]);

  return (
    <div className="p-4">
      <Input
        ref={searchInputRef}
        placeholder={lang.searchFullName}
        value={searchText}
        onChange={e => handleChange(e)}
        onPressEnter={handleSearch}
        className="mb-[8px]"
      />
      {dataIndex === "leadCenterLeads" && (
        <RadioBtnGroups
          radioListTitle={`${lang.leadsByOrder}:`}
          clearFilters={clearFilters}
          radioGrp={sortingOpt}
          activeSort={activeSortingOrder}
          handleSortingFunction={handleSortingFunction}
          close={close}
        />
      )}
      {dataIndex === "leadCenterLeads" && (
        <RadioBtnGroups
          radioListTitle={`${lang.sortingLeadsBy}:`}
          clearFilters={clearFilters}
          radioGrp={radioGrp}
          activeSort={activeSort}
          handleSearch={handleSearch}
          handleSortingFunction={handleSortingFunction}
          close={close}
        />
      )}
      <div className="mt-[10px] border border-solid border-t-[#f1ecec] border-x-0 border-b-0 py-[10px] px-0">
        <CustomButton
          type="primary"
          handleClick={() => handleSearch(selectedKeys, confirmBtn, "fullName")}
          icon={<AntdIconV4 type="SearchOutlined" />}
          size="small"
          btnText={lang.search}
          className="mr-2"
        />
        <CustomButton btnText={lang.reset} handleClick={handleReset} size="small" />
      </div>
    </div>
  );
};
export default FilterTextBox;
