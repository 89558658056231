/* eslint-disable no-debugger */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Image, Skeleton, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { formatBytes } from "../../../../library/utils";
import AntdIconV4 from "../../../../utils/antdIcon";
import { mediaDownloader } from "../../../../utils/mediaDownloader";
import CustomButton from "../../../commonComponents/button";
import AudioPlayer from "../../../smai-calling/audioPlayer/audioComp";
import { ViewMediaPopup } from "./ViewMediaPopup";
import "./image.css";
import MediaViewer from "./mediaViewer";

const MediaSharing = props => {
  const { selectedMedia, height, width, altText, className = "", direction } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoThumbnails, setVideoThumbnails] = useState([]);
  const [pdfModal, setpdfModal] = useState({
    visible: false,
    pdfUrl: "",
    mediaType: "",
  });
  // useEffect(() => {
  //   async function fetchData() {
  //     if (selectedMedia?.mediaType?.includes("video")) {
  //       try {
  //         const response = await generateVideoThumbnails(selectedMedia.file, 2);
  //         setVideoThumbnails(response[0]);
  //         const mediaUrl = await s3uploader(
  //           response,
  //           process.env.GATSBY_S3_BUCKET,
  //           state => {},
  //           true
  //         );
  //         selectedMedia.file && setVideoThumbnails(mediaUrl);
  //       } catch (e) {
  //         console.log("error in generateVideoThumbnails", e);
  //       }
  //     }
  //   }
  //   // fetchData()
  // }, [selectedMedia]);

  const handleMediaUI = mediaInfo => {
    if (mediaInfo?.mediaType?.includes("image")) {
      console.log(
        "mediaSharing:selectedMedia.compressedMediaUrl",
        selectedMedia.compressedMediaUrl
      );
      return (
        <div className="relative">
          <Image
            className={`${className} cursor-pointer`}
            src={selectedMedia.compressedMediaUrl || selectedMedia.mediaUrl}
            alt={altText}
            loading="lazy"
            height={height}
            placeholder={<Skeleton.Image active className="w-[250px] h-[250px]" />}
            // preview={{
            //   toolbarRender: (_, { transform: { scale }, actions }) => (
            //     <CustomImageToolbar selectedMedia={selectedMedia} actions={actions} scale={scale} />
            //   ),
            // }}
          />
          <AntdIconV4
            onClick={() =>
              mediaDownloader(selectedMedia.mediaUrl, selectedMedia?.mediaType?.split("/")[1])
            }
            type="DownloadOutlined"
            className="bg-[#000] text-white right-[15px] top-[15px] text-[31px] absolute rounded-[5px]"
          />
        </div>
      );
      // eslint-disable-next-line no-else-return
    } else if (mediaInfo?.mediaType?.includes("video")) {
      return (
        <video
          width={width}
          height={height}
          className="cursor-pointer"
          controls
          poster={selectedMedia.compressedThumbnail || selectedMedia?.thumbnailUrl || ""}
          // onClick={() => setIsModalOpen(true)}
        >
          <source
            src={selectedMedia.compressedMediaUrl || selectedMedia.mediaUrl}
            type="video/mp4"
          />
        </video>
      );
    } else if (mediaInfo?.mediaType?.includes("audio")) {
      return <AudioPlayer audioLink={mediaInfo.mediaUrl} direction={direction} />;
    } else {
      return (
        <div className="flex items-center justify-between text-white rounded-[15px] rounded-br-[0px] relative">
          <div className="flex items-center">
            <CustomButton
              shape="circle"
              icon={<AntdIconV4 type="DownloadOutlined" />}
              className="bg-[#e6f7ff]"
              handleClick={() => {
                mediaDownloader(selectedMedia?.mediaUrl, selectedMedia?.mediaType?.split("/")[1]);
              }}
              tooltipTitle="download document"
            />
            <span
              onClick={() =>
                setpdfModal({
                  visible: true,
                  pdfUrl: selectedMedia.mediaUrl,
                  mediaType: selectedMedia?.mediaType,
                })
              }
              className="ml-[10px] cursor-pointer"
            >
              {selectedMedia?.mediaUrl?.substring(selectedMedia?.mediaUrl?.lastIndexOf("/") + 1)}
            </span>
          </div>
          <span className="text-[10px] text-[#fafafa] ml-[10px]">
            {formatBytes(selectedMedia?.size)}
          </span>
        </div>
      );
    }
  };

  return (
    <div
      className={`flex items-end ${direction === "TO_LEAD" ? "justify-end" : "justify-start"} p-0 `}
    >
      <Image.PreviewGroup> {handleMediaUI(selectedMedia)}</Image.PreviewGroup>
      {isModalOpen && (
        <MediaViewer
          isModalOpen={isModalOpen}
          handleCancel={() => setIsModalOpen(false)}
          media={selectedMedia}
          videoThumbnails={videoThumbnails}
        />
      )}
      {pdfModal.visible && <ViewMediaPopup pdfModal={pdfModal} setpdfModal={setpdfModal} />}
    </div>
  );
};

export default MediaSharing;

export const CustomImageToolbar = ({ selectedMedia, scale, actions }) => (
  <Space size={12} className="toolbar-wrapper">
    <Tooltip title="Download Image">
      <DownloadOutlined
        onClick={() =>
          mediaDownloader(selectedMedia.mediaUrl, selectedMedia?.mediaType?.split("/")[1])
        }
      />
    </Tooltip>
    <SwapOutlined rotate={90} onClick={actions.onFlipY} />
    <SwapOutlined onClick={actions.onFlipX} />
    <RotateLeftOutlined onClick={actions.onRotateLeft} />
    <RotateRightOutlined onClick={actions.onRotateRight} />
    <ZoomOutOutlined disabled={scale === 1} onClick={actions.onZoomOut} />
    <ZoomInOutlined disabled={scale === 50} onClick={actions.onZoomIn} />
  </Space>
);
