import Icon, { QuestionCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Tooltip } from "antd";
import { Link, navigate } from "gatsby";
import React, { useContext, useState } from "react";
import { UserContext } from "../../amplify/authenticator-provider";
import { getCognitoRoles } from "../../hooks/utils";
import AnalyticsIcon from "../../icons/js/AnalyticsIcon";
import ConversationIcon from "../../icons/js/ConversationIcon";
import EngagementsIcon from "../../icons/js/EngagementsIcon";
import PersonalizedIcon from "../../icons/js/PersonalizedIcon";
import ProspectsIcon from "../../icons/js/ProspectsIcon";
import ReviewsIcon from "../../icons/js/ReviewsIcon";
import { openDialog } from "../../library/helpers";
import AntdIconV4 from "../../utils/antdIcon";

const NavigationContainer = props => {
  const { setContentStyle, accessPayload, activeTab } = props;

  const { user } = useContext(UserContext);

  const activeSlug = window.location.pathname;

  const NAVIGATION_OPTIONS = [
    {
      id: 1,
      label: "Admin",
      link: "/admin-dashboard",
      // icon: CogIcon,
      icon: <SettingOutlined />,
      type: "setting",
      isAdminSetting: getCognitoRoles(accessPayload)[0] === "ADMIN",
    },
    {
      id: 2,
      label: "Analytics",
      link: "/analytics/kpis",
      // icon: AnalyticsIcon,
      icon: <AnalyticsIcon />,
      type: "pieChart",
      isAdminSetting: getCognitoRoles(accessPayload)[0] !== "ADMIN",
    },
    {
      id: 3,
      label: "Prospects",
      link: user?.role?.canViewProspects ? "/prospect" : "/no-permission",
      // icon: ProspectsIcon,
      icon: <ProspectsIcon />,
      type: "funnelPlot",
      isAdminSetting: getCognitoRoles(accessPayload)[0] !== "ADMIN" && user?.role?.canViewProspects,
    },
    {
      id: 4,
      label: "Engagements",
      link: "/engagements/dashboard",
      urlPart: "engagements",
      // icon: EngagementIcon,
      icon: <EngagementsIcon />,
      type: "mail",
      isAdminSetting:
        getCognitoRoles(accessPayload)[0] !== "ADMIN" && user?.role?.canViewEngagements,
    },
    {
      id: 5,
      label: "Conversations",
      link: "/conversation",
      // icon: ConversationsIcon,
      icon: <ConversationIcon />,
      type: "message",
      isAdminSetting:
        getCognitoRoles(accessPayload)[0] !== "ADMIN" &&
        (user?.role?.canViewClm || user?.role?.canViewProspects),
    },
    {
      id: 6,
      label: "Reviews",
      link: "/reviews",
      // icon: StarIcon,
      icon: <ReviewsIcon />,
      type: "star",
      isAdminSetting:
        getCognitoRoles(accessPayload)[0] !== "ADMIN" && user?.role?.canViewEngagements,
    },
    {
      id: 7,
      label: "Personalized",
      link: "/personalized-ads",
      // icon: ExportIcon,
      icon: <PersonalizedIcon />,
      type: "download",
      isAdminSetting: getCognitoRoles(accessPayload)[0] !== "ADMIN" && user?.role?.canViewAdExport,
    },
  ];

  const [isNavInFullWidth, setIsNavInFullWidth] = useState(false);

  const handleNavigationWidth = () => {
    setIsNavInFullWidth(prev => !prev);
    setContentStyle(prev => !prev);
  };

  // mobile navigation options
  const items = NAVIGATION_OPTIONS.map(nav => {
    if (getCognitoRoles(accessPayload)[0] !== "ADMIN") {
      if (nav.label !== "Admin") {
        return {
          key: nav.id,
          label: (
            <div
              className="flex gap-2 py-1 px-2 font-medium text-[17px]"
              onClick={() => navigate(nav.link)}
            >
              {/* <Icon className="text-xl leading-none" component={nav.icon} /> */}
              {nav.icon}
              <div>{nav.label}</div>
            </div>
          ),
        };
      }
    } else {
      return {
        key: nav.id,
        label: (
          <div
            className="flex gap-2 py-1 px-2 font-medium text-[17px]"
            onClick={() => navigate(nav.link)}
          >
            <Icon className="text-xl leading-none text-blue-500" component={nav.icon} />
            <div>{nav.label}</div>
          </div>
        ),
      };
    }
  });

  return (
    <>
      <div className="fixed left-0 z-10 bg-white hidden md:block h-[calc(100vh-3rem)]">
        <div
          className={`${
            isNavInFullWidth ? "w-40" : "w-[57px]"
          } flex flex-col justify-between text-center h-full transition-all duration-75`}
        >
          <div className="flex flex-col">
            {NAVIGATION_OPTIONS.map(link => (
              <Tooltip title={link.label} key={link.id} placement="right">
                <Link
                  className={`py-3 no-underline w-full cursor-pointer ${
                    isNavInFullWidth ? "flex items-center px-3" : ""
                  } transition-all duration-75 ${
                    activeSlug.includes(
                      link.link === "/engagements/dashboard" ? link.urlPart : link.link
                    )
                      ? "primaryLightBgTheme"
                      : ""
                  } hover:bg-blue-200 ${!link.isAdminSetting ? "hidden" : ""}`}
                  to={link.link}
                >
                  {/* <Avatar className="sideBarBG" title={link.label} shape="square">
                    <Icon className="text-xl leading-none" component={link.icon} />
                  </Avatar> */}
                  {link.icon}
                  {isNavInFullWidth && <p className="m-0 pl-2 text-sm">{link.label}</p>}
                </Link>
              </Tooltip>
            ))}
          </div>
          <div>
            <Tooltip placement="right" title="Support">
              <div
                className={`py-3 no-underline w-full cursor-pointer ${
                  isNavInFullWidth ? "flex items-center px-3" : ""
                } transition-all duration-75`}
                onClick={() =>
                  openDialog("supportModal", {
                    dialogHeader: "Need to report an issue? Have a question?",
                  })
                }
              >
                {/* <Avatar className="sideBarBG" title={link.label} shape="square">
                    <Icon className="text-xl leading-none" component={link.icon} />
                  </Avatar> */}
                <QuestionCircleOutlined className="h-5 w-5 text-grey-500" />
                {isNavInFullWidth && <p className="m-0 pl-2 text-sm">Support</p>}
              </div>
            </Tooltip>
            <AntdIconV4
              onClick={() => handleNavigationWidth()}
              className="mb-5 h-12 flex justify-center items-center hover:bg-blue-300 hover:font-bold"
              type={isNavInFullWidth ? "LeftOutlined" : "RightOutlined"}
            />
          </div>
        </div>
      </div>

      <Dropdown
        menu={{ items }}
        className="cursor-pointer w-full text-center text-sm py-1 text-white primaryBgTheme md:hidden"
        placement="bottom"
        trigger="click"
      >
        <div>{activeTab}</div>
      </Dropdown>
    </>
  );
};

export default NavigationContainer;
