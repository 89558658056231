import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal, Checkbox, message } from "antd";
import { OPPORTUNITY_REDIRECTION_DIALOG } from "../../../../graphql/mutation";
import { crmLinkGeneration } from "./crmLinks";

const OpportunityWarning = props => {
  const {
    visibleModal,
    handleOKBtn,
    setModalVisible,
    opportunityInfo,
    crmLeadObj,
    crmIntegrationType = "",
    handleOpporDialogFlagChanged = () => {},
  } = props;

  const [changeDialogOpen] = useMutation(OPPORTUNITY_REDIRECTION_DIALOG);

  const [checkbox, setCheckbox] = useState(localStorage.getItem("opportunityDialog") === "true");

  const deleaerSocektLink = "https://bb.dealersocket.com/";
  const salesForceLink = "https://usautosales.my.salesforce.com/";

  const handleCheckbox = async e => {
    setCheckbox(e.target.checked);
    localStorage.setItem("opportunityDialog", e.target.checked);
    const response = await changeDialogOpen({
      variables: {
        locationId: +sessionStorage.getItem("currentLocationId"),
        isOpenCrmWarningPopup: false,
      },
    });
    if (response?.data?.updateOpenCrmWarningPopup?.statusCode === 200) {
      message.success(response?.data?.updateOpenCrmWarningPopup?.message);
      handleOpporDialogFlagChanged();
    } else {
      message.error("Something went wrong.");
    }
  };
  const handleCRMLeadRedirection = record => {
    // DS
    if (
      (record?.dsOpportunityEvents?.length > 0 && record?.dsOpportunityEvents[0]?.dsEventId) ||
      crmLeadObj?.dsExtractedLead?.dsLeadId
    ) {
      const opprId =
        record &&
        record?.dsOpportunityEvents &&
        record?.dsOpportunityEvents?.length > 0 &&
        record?.dsOpportunityEvents[0]?.dsEventId
          ? String(record?.dsOpportunityEvents[0]?.dsEventId)
          : crmLeadObj?.dsExtractedLead?.dsLeadId
          ? String(crmLeadObj?.dsExtractedLead?.dsLeadId).padStart(4, "0")
          : record?.id;

      const oppLink =
        record &&
        record?.dsOpportunityEvents &&
        record?.dsOpportunityEvents.length > 0 &&
        record?.dsOpportunityEvents[0]?.dsEventId
          ? `https://bb.dealersocket.com/#/crm/sales/view/${crmLeadObj?.dsExtractedLead?.dsLeadId}/${opprId}`
          : `https://bb.dealersocket.com/#/crm/view/contact/${opprId}`;

      return oppLink;
    }

    // SF
    if (
      (record?.sfLeadOpportunities?.length > 0 &&
        record?.sfLeadOpportunities[0]?.sfOpportunityId) ||
      crmLeadObj?.sfExtractedLead?.sfLeadId
    ) {
      const opprId =
        record?.sfLeadOpportunities?.length > 0 && record?.sfLeadOpportunities[0]?.sfOpportunityId
          ? String(record?.sfLeadOpportunities[0]?.sfOpportunityId)
          : crmLeadObj?.sfExtractedLead?.sfLeadId
          ? String(crmLeadObj?.sfExtractedLead?.sfLeadId)
          : record?.id;

      const oppLink =
        record?.sfLeadOpportunities?.length > 0 && record?.sfLeadOpportunities[0]?.sfOpportunityId
          ? `https://usautosales.lightning.force.com/lightning/r/Opportunity/${opprId}/view`
          : `https://usautosales.lightning.force.com/lightning/r/Lead/${opprId}/view`;

      return oppLink;
    }

    // VIN
    if (
      (record?.vsLeadOpportunities?.length > 0 && record?.vsLeadOpportunities[0]?.vsContactId) ||
      crmLeadObj?.vsExtractedLead?.vsContactId
    ) {
      const opprId =
        record?.vsLeadOpportunities?.length > 0 && record?.vsLeadOpportunities[0]?.vsContactId
          ? String(record?.vsLeadOpportunities[0]?.vsContactId)
          : crmLeadObj?.vsExtractedLead?.vsContactId
          ? String(crmLeadObj?.vsExtractedLead?.vsContactId)
          : record?.id;

      const oppLink =
        record?.vsLeadOpportunities?.length > 0 && record?.vsLeadOpportunities[0]?.vsContactId
          ? crmLinkGeneration(
              "VIN",
              Boolean(record?.vsLeadOpportunities[0]?.vsContactId),
              opprId,
              "",
              "VinSolution"
            )
          : "";

      return oppLink;
    }

    return "";
  };

  return (
    <div>
      <Modal
        title={`Go to ${crmIntegrationType === "SF" ? "Salesforce" : "DealerSocket"}`}
        open={visibleModal}
        onOk={handleOKBtn}
        onCancel={setModalVisible}
        footer={[
          <Button key="submit" type="primary">
            <a href={handleCRMLeadRedirection(opportunityInfo)} rel="noreferrer" target="_blank">
              Go to{" "}
              {crmIntegrationType === "DS"
                ? "Sales Opportunity"
                : opportunityInfo?.isOpp
                ? "opportunity"
                : "lead"}
            </a>
          </Button>,
          <Button key="back" onClick={() => setModalVisible()}>
            Cancel
          </Button>,
        ]}
      >
        <div style={{ fontSize: "16px", color: "red" }}>
          {`Make sure in ${crmIntegrationType === "SF" ? "Salesforce" : "DealerSocket"}`}, you are
          in <strong>{opportunityInfo?.lead?.location?.title} </strong>location.
        </div>
        <p style={{ marginTop: "10px", marginBottom: "2px" }}>
          <strong>{opportunityInfo?.opprName}</strong>
        </p>
        <p style={{ marginTop: "2px", marginBottom: "4px" }}>
          <div>
            {crmIntegrationType === "DS" && "DealerSocket Opportunity ID "}
            <strong>{opportunityInfo?.opprId}</strong>
          </div>
        </p>
        <p>
          <a
            href={crmIntegrationType === "SF" ? salesForceLink : deleaerSocektLink}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
          >
            Access {crmIntegrationType === "SF" ? "Salesforce" : "DealerSocket"}
          </a>
        </p>
        <Checkbox checked={checkbox} onChange={handleCheckbox}>
          Do not show dialog again.
        </Checkbox>
      </Modal>
    </div>
  );
};

export default OpportunityWarning;

export const handleOpportunityDilaog = (userLocations, user) => {
  if (user?.company?.isOpenCrmWarningPopup) {
    if (user?.companyRole?.isOpenCrmWarningPopup) {
      const [currentLocationDetail] = userLocations.filter(
        location => location?.location?.id === sessionStorage.getItem("currentLocationId")
      );
      return currentLocationDetail?.isOpenCrmWarningPopup;
    }

    return false;
  }

  return false;
};
