/* eslint-disable no-else-return */
/* eslint-disable no-debugger */
/* eslint-disable no-use-before-define */
/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
/* eslint-disable default-param-last */
/* eslint-disable no-underscore-dangle */
import { Checkbox, Input, Spin, Tree } from "antd";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import CustomButton from "../components/commonComponents/button";
import CustomCheckbox from "../components/commonComponents/checkbox";
import CustomLabel from "../components/commonComponents/label";
import useLang from "../hooks/useLang";
import { getLocationId } from "../redux/slices/locationSlice";
import { TreeComponent } from "./TreeComponent";
import { LoadingIcon } from "./basicComponents";
import RadioBtnGroups from "./radioBtnGroups";

const { TreeNode } = Tree;

const FilterDropdownList = styled.div`
  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px grey;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px grey;
    background-color: #fcfcfc;
  }
`;

export const StyledTree = styled.div`
  .ant-tree-treenode-switcher-close {
    height: 30px !important;
    padding-top: 0px !important;
  }
`;

const sortingOpt = [
  {
    label: "OR",
    value: "or",
    tooltipText: "Can select multiple owner from each dropdown",
  },
  {
    label: "AND",
    value: "and",
    tooltipText: "Can select only one owner from each dropdown",
  },
];

const dummyArr = [
  "Qualify",
  "Approval",
  "Select Vehicle",
  "Worksheet Submitted",
  "Deal Approved",
  "Awaiting Stimulus",
  "Layaway",
  "Delivered",
  "DEAD",
  "Closed Won",
  "Duplicate",
];

const FilterDropdown = ({
  setflag,
  filSel,
  dataIndex,
  filters,
  setSelectedKeys,
  clearFilters,
  confirmBtn,
  selectedKeys,
  loading = false,
  onSearch,
  crmType = "",
  dsSources,
  setdsSources,
  crmIntegration,
  setVariables,
  variables,
  statusList,
  isCheckAll = 0,
  crmDropdownData = [],
  setIsOwnerFilterApplied = () => {},
  setUserFiltes = () => {},
  userFilters,
  close,
  confirm,
  activeLeadFilter = false,
  opportunitySource = [],
}) => {
  const [lang] = useLang();

  const [selected, setSelected] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [vehicleFiltersValue, setvehicleFiltersValue] = useState({
    voi: true,
    customer: true,
  });
  const [checkedAll, setCheckedAll] = useState(false);
  const [expandedSourceNodes, setExpandedSourceNodes] = useState([]);
  const [expandedStatusNodes, setExpandedStatusNodes] = useState([]);
  const [usersFilter, setUsersFilter] = useState([]);
  const [searchKeyword, onSetSearch] = useState("");
  const [stageFilters, setstageFilters] = useState([]);
  const [parsedKeys, setparsedKeys] = useState({
    parent: [],
    Layer2: [],
    Layer3: [],
  });
  const [sourceKeys, setSourceKeys] = useState([]);
  const [statusKeys, setStatusKeys] = useState([]);
  const [andArray, setAndArray] = useState([]);
  const [activeSort, setActiveSort] = useState(sortingOpt[0]?.value);
  const [expandedNodeData, setExpandedNodeData] = useState(["4"]);
  const locationIdslice = useSelector(state => getLocationId(state));

  useEffect(() => {
    setSelected([]);
    setSelectedOwner([]);
    // confirm([]);
    clearFilters();
    // setflag && setflag(false);
    setparsedKeys({ parent: [], Layer2: [], Layer3: [] });
    setCheckedAll(false);
    setAndArray([]);
    setUsersFilter([]);
    setActiveSort(sortingOpt[0]?.value);
    setIsOwnerFilterApplied(false);
    setExpandedSourceNodes([]);
    setSourceKeys([]);
    setUserFiltes({ ...userFilters, Source: [] });
  }, [locationIdslice]);

  useEffect(() => {
    onSetSearch("");
    return () => {
      onSetSearch("");
    };
  }, []);

  useEffect(() => {
    if (crmIntegration === "DS") {
      const keyValues =
        dataIndex === "Source" ? [...(variables.dsSources || [])] : [...(opportunitySource || [])];
      setSourceKeys(keyValues);
    }
  }, [variables]);

  useEffect(() => {
    if (filSel && filSel.length > 0) {
      setSelected(filSel);
    }
    return () => {
      setSelected([]);
    };
  }, [filSel]);

  useEffect(() => {
    if (userFilters && userFilters?.Vehicles && dataIndex === "Vehicles") {
      setSelected([...userFilters.Vehicles].map(item => item.text));
    } else if (
      userFilters &&
      (userFilters.leadStatusTypes?.length || userFilters?.leadStatusType?.length) &&
      dataIndex === "leadStatusType"
    ) {
      setSelected(
        userFilters?.leadStatusType?.length > 0 && activeLeadFilter?.id
          ? [...userFilters?.leadStatusType]
          : [...userFilters.leadStatusTypes]
      );
    } else if (userFilters && userFilters?.opprVehicles && dataIndex === "Vehicles") {
      setSelected([...userFilters.opprVehicles].map(item => item.text));
    }
  }, [userFilters, activeLeadFilter]);

  // useEffect(() => {
  //   if (isCheckAll > 0) {
  //     setSelected([]);
  //   }
  // }, [isCheckAll]);

  useEffect(() => {
    if (userFilters?.stageNames) {
      setstageFilters([...userFilters?.stageNames]);
    }
  }, [userFilters]);

  useEffect(() => {
    // setkeys(variables?.source);
    if (variables?.userTypesAnd) {
      setActiveSort("and");
      setUsersFilter((variables?.userTypesAnd || []).map(owner => owner?.userId?.toString()));
    } else if (variables?.userTypesOr) {
      setActiveSort("or");
      setUsersFilter(variables?.userTypesOr[0]?.userIds?.map(userId => userId?.toString()));
    } else {
      setActiveSort("or");
      setUsersFilter([]);
    }
    // setSelected(variables?.Source?.map(source => +source) || selectedKeys || filSel || []);
  }, [variables]);

  useEffect(() => {
    handleExpandNodeCheck();
  }, [selectedOwner]);

  const onCheckOwners = keys => {
    if (activeSort === "and") {
      // filter elements that are present in both (keys and andArray)
      const namesToDeleteSet = new Set(keys);

      const newArr = andArray.filter(name => {
        return namesToDeleteSet.has(name);
      });

      const newCheckboXIndex = keys.length - 1;
      const grpValue = keys.find(key => key.split("-")[0] === keys[newCheckboXIndex].split("-")[0]);
      const grpIndex = keys.indexOf(grpValue);
      let tempoArray = [...newArr];

      tempoArray[grpIndex] = keys[newCheckboXIndex];
      tempoArray = tempoArray.filter(check => check !== undefined && check !== "");
      setAndArray(tempoArray);
      setSelectedOwner(tempoArray);
    } else {
      setSelectedOwner([...keys]);
    }
  };

  const handleSortingFunction = radiovalue => {
    setActiveSort(radiovalue);
    setSelectedOwner([]);
  };

  const handleExpandNodeCheck = () => {
    let expandedNode = [];
    expandedNode = [
      ...new Set(
        selectedOwner.map(owner => {
          if (owner.includes("-")) {
            return owner.split("-")[0];
          }
        })
      ),
    ];
    setExpandedNodeData([...expandedNode]);
  };
  const handleExpandNode = (isExpandEvent = false, collapsNode = "", expanded) => {
    let expandedNode = [];
    if (expanded) {
      expandedNode = expandedNode.reduce(
        (c, v) => c.concat(c.some(e => e === v) ? [] : [v]),
        collapsNode
      );
    } else {
      expandedNode = expandedNode.filter(val => collapsNode.includes(val));
    }
    if (expandedNode?.length === 0) {
      expandedNode = [...expandedNode, ...collapsNode];
    }

    if (collapsNode.length === 0 && !expanded) {
      expandedNode = [];
    }
    setExpandedNodeData([...expandedNode]);
  };

  const renderOptions = () => {
    return ["Source", "opportunitySource", "opportunityType"].includes(dataIndex) ? (
      <TreeComponent
        expandedNodes={expandedSourceNodes}
        setExpandedNodes={setExpandedSourceNodes}
        keys={sourceKeys}
        list={dsSources}
        setkeys={setSourceKeys}
        title="name"
        value="key"
        child1="parents"
        child2="childs"
      />
    ) : dataIndex.includes("Owners") ? (
      <>
        <RadioBtnGroups
          radioListTitle="Selecting owners"
          clearFilters={clearFilters}
          radioGrp={sortingOpt}
          activeSort={activeSort}
          isOwnerRadio
          handleSortingFunction={handleSortingFunction}
          flexDireaction="flex-row"
        />
        {!crmIntegration ? (
          <Checkbox.Group
            className="flex flex-col ant-checkbox"
            value={usersFilter}
            options={crmDropdownData}
            onChange={checkedValues => setUsersFilter(checkedValues)}
          />
        ) : (
          <StyledTree>
            <Tree
              key={dataIndex}
              checkable
              onCheck={(keys, e) => onCheckOwners(keys, e)}
              checkedKeys={selectedOwner}
              expandedKeys={expandedNodeData}
              onExpand={(expandedKeys, { expanded, node }) =>
                handleExpandNode(true, expandedKeys, expanded, node)
              }
            >
              {crmDropdownData?.map(item => {
                return (
                  item?.name !== "Unknown" && (
                    <TreeNode
                      title={item?.name}
                      key={item.id}
                      checkable={!(activeSort === "and")}
                      // disabled={!(item?.users?.length > 0)}
                    >
                      {sortBy(item?.users || [], "fullName")?.map(user => {
                        return <TreeNode key={`${item.id}-${user?.id}`} title={user?.fullName} />;
                      })}
                    </TreeNode>
                  )
                );
              })}
            </Tree>
          </StyledTree>
        )}
      </>
    ) : dataIndex === "leadStatusType" ? (
      <TreeComponent
        expandedNodes={expandedStatusNodes}
        setExpandedNodes={setExpandedStatusNodes}
        keys={statusKeys}
        list={statusList}
        setkeys={setStatusKeys}
        title="text"
        value="value"
        child1="subStatus"
      />
    ) : (
      dataIndex === "Vehicles" && (
        <Checkbox.Group
          className="flex flex-col items-start"
          onChange={value => setSelected(value)}
          value={selected}
          key={dataIndex}
        >
          {filters.map((item, index) => (
            <Checkbox className="ml-0" key={index} value={item.value}>
              {item.text}
            </Checkbox>
          ))}
        </Checkbox.Group>
      )
    );
  };

  const onConfirm = () => {
    onSetSearch("");
    onSearch && onSearch("");
    if (dataIndex === "opportunitySource" || dataIndex === "opportunityType") {
      confirmBtn(sourceKeys);
    } else if (dataIndex === "Source") {
      setUserFiltes({ ...userFilters, [dataIndex]: sourceKeys });
      const temp = sourceKeys?.map(item => item);
      if (crmIntegration !== "DS") {
        setVariables({ ...variables, source: temp });
        setUserFiltes({ ...userFilters, Source: temp });
        // createNewFilter();
      } else {
        setVariables({ ...variables, dsSources: temp });
        setUserFiltes({ ...userFilters, dsSources: temp });
        // createNewFilter();
      }
      setflag && setflag(true);
      if (temp?.length <= 0) {
        setflag && setflag(false);
      }
      // confirm({ closeDropdown: true });
    } else if (dataIndex === "leadStatusType") {
      setUserFiltes({ ...userFilters, leadStatusTypes: statusKeys });
      setVariables({ ...variables, leadStatusTypes: statusKeys });
    } else if (dataIndex === "Owners") {
      if (!crmIntegration) {
        const APIPayload = usersFilter.map(userId => {
          return {
            userTypeId: null,
            userId: +userId,
          };
        });

        const APIPayloadOR = usersFilter.map(userId => {
          return +userId;
        });
        const APIVariables = { ...variables };
        if (activeSort === "and") {
          delete APIVariables["userTypesOr"];
          APIVariables["userTypesAnd"] = APIPayload;
        } else {
          delete APIVariables["userTypesAnd"];
          APIVariables["userTypesOr"] = [{ userIds: APIPayloadOR, userTypeId: null }];
        }
        if (usersFilter?.length === 0) {
          delete APIVariables["userTypesOr"];
          delete APIVariables["userTypesAnd"];
        }
        setVariables({ ...APIVariables });
        setIsOwnerFilterApplied(true);
        setUserFiltes({
          ...userFilters,
          [dataIndex]: {
            type: activeSort,
            ownerIds: !crmIntegration ? usersFilter : selectedOwner,
          },
        });
        // clearFilters();
        confirm({ closeDropdown: true });
        return;
      } else {
        const IdArray = [];
        let dropdownUserIds = [];
        let APIObj = {};
        let APIPayload = [];
        const filterPayload =
          selectedOwner?.length > 0 &&
          selectedOwner.map((owner, index) => {
            if (owner.includes("-")) {
              const objectKey = owner.split("-")[0];
              const objectvalue = owner.split("-")[1];
              if (IdArray.includes(objectKey)) {
                dropdownUserIds.push(objectvalue);
                APIObj = {
                  ...APIObj,
                  [objectKey]:
                    Object.keys(APIObj)?.length === 0
                      ? [...dropdownUserIds]
                      : [...APIObj[objectKey], ...dropdownUserIds],
                };
                dropdownUserIds = [];
              } else {
                APIObj = {
                  ...APIObj,
                  [objectKey]: [objectvalue],
                };
              }
              IdArray.push(objectKey);
              setIsOwnerFilterApplied(true);
            }
          });
        for (const [key, user] of Object.entries(APIObj)) {
          APIPayload = [
            ...APIPayload,
            {
              userTypeId: +key,
              [activeSort === "and" ? "userId" : "userIds"]:
                activeSort === "and"
                  ? user.map(item => Number(item))[0]
                  : user.map(item => Number(item)),
            },
          ];
        }
        const APIVariables = { ...variables };
        if (activeSort === "and") {
          delete APIVariables["userTypesOr"];
          APIVariables["userTypesAnd"] = APIPayload;
        } else {
          delete APIVariables["userTypesAnd"];
          APIVariables["userTypesOr"] = APIPayload;
        }
        if (selectedOwner?.length === 0) {
          delete APIVariables["userTypesOr"];
          delete APIVariables["userTypesAnd"];
        }
        setVariables({ ...APIVariables });
        clearFilters();
      }
      setUserFiltes({
        ...userFilters,
        [dataIndex]: {
          type: activeSort,
          ownerIds: !crmIntegration ? usersFilter : selectedOwner,
        },
      });
    } else if (dataIndex === "Vehicles") {
      const selectedVoi = filters.filter(el => {
        return selected.some(f => {
          return el.value === f;
        });
      });
      // setUserFiltes(selectedVoi.map(el => el.vehicle));
      confirm(selectedVoi.map(el => el.vehicle));
    } else {
      // eslint-disable-next-line no-lonely-if
      if (selected.length === 0) {
        // confirmBtn([]);
        confirm([]);
        // confirm({ closeDropdown: true });
        setflag && setflag(false);
        setUserFiltes({ ...userFilters, [dataIndex]: null });
        if (dataIndex === "leadStatusType") {
          setUserFiltes({ ...userFilters, leadStatusTypes: null });
        }
      } else {
        console.log("Called", statusKeys);
        setSelectedKeys(selected);
        setUserFiltes({
          ...userFilters,
          [dataIndex]: selected.filter(item => item !== undefined),
        });
        if (dataIndex === "Vehicles") {
          setUserFiltes(prev => {
            const newState = { ...prev };
            newState[dataIndex] = selected;
            return newState;
          });
          confirm(selected);
          close();
        } else {
          console.log("Called", statusKeys);
          confirm(selected, stageFilters);
          close();
        }
        setflag && setflag(true);
      }
    }
    close();
  };

  const onReset = () => {
    onSetSearch("");
    onSearch && onSearch("");
    setSelected([]);
    setSelectedOwner([]);
    confirm([]);
    clearFilters();
    dataIndex !== "leadStatusType" ? setflag && setflag(false) : setflag && setflag(true);
    setparsedKeys({ parent: [], Layer2: [], Layer3: [] });
    setCheckedAll(false);
    if (dataIndex === "Owners") {
      setAndArray([]);
      setUsersFilter([]);
      setActiveSort(sortingOpt[0]?.value);
      setIsOwnerFilterApplied(false);
      setVariables(prev => {
        const temp = structuredClone(prev);
        delete temp.userTypesAnd;
        delete temp.userTypesOr;
        return temp;
      });
    }
    if (dataIndex === "leadStatusType") {
      setStatusKeys([]);
    }
    if (dataIndex === "Source") {
      setExpandedSourceNodes([]);
      setSourceKeys([]);
      setVariables(prev => {
        const temp = structuredClone(prev);
        delete temp.dsSources;
        delete temp.source;
        return temp;
      });
      setUserFiltes({ ...userFilters, Source: [] });
    }

    if (dataIndex === "opportunitySource" || dataIndex === "opportunityType") {
      setExpandedSourceNodes([]);
      setSourceKeys([]);
      setStatusKeys([]);
      confirmBtn([]);
    }
    close();
  };

  const onSelectAll = e => {
    if (!e.target.checked) {
      setSelected([]);
      // clearFilters()
    } else {
      const items = (filters || []).map(el => el.value);
      setSelected(items);
    }
    setCheckedAll(e.target.checked);
    if (stageFilters.includes("All")) {
      setstageFilters([]);
    } else {
      setstageFilters([...stageFilters, "All"]);
    }
  };

  const renderButtons = () => {
    return (
      <div className="px-0">
        <div className="flex mb-3 items-center gap-2">
          <CustomButton
            size="small"
            type="primary"
            handleClick={() => onConfirm()}
            btnText={lang.apply}
          />
          <CustomButton size="small" handleClick={() => onReset()} btnText={lang.reset} />
        </div>
        {!["Source", "Owners", "opportunityType"].includes(dataIndex) && (
          <CustomCheckbox
            className="py-1"
            checkboxLabel={lang.selectUnselectAllLabel}
            checked={checkedAll}
            handleChange={onSelectAll}
          />
        )}
        {onSearch && dataIndex !== "opportunityType" && (
          <div className="mb-[10px]">
            <Input.Search
              onSearch={onSearch}
              placeholder={lang.typeAndSearch}
              name="search"
              className="w-full"
              allowClear
              allowSearchOnClear
              value={searchKeyword}
              onChange={e => {
                onSetSearch(e.target.value);
                onSearch(e.target.value);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="p-[15px]">
      <CustomLabel labelClass="pb-2 font-medium" label={`${lang.filterBy} ${dataIndex}`} />

      {renderButtons()}
      <FilterDropdownList>
        {loading ? (
          <Spin spinning indicator={<LoadingIcon type="loading" className="ml-[2rem]" />} />
        ) : (
          <>
            {renderOptions()}
            <style jsx>{`
              .ant-checkbox .ant-checkbox-wrapper {
                margin-inline-start: 0 !important;
                padding: 2px 0;
              }
            `}</style>
          </>
        )}
      </FilterDropdownList>
    </div>
  );
};
export default FilterDropdown;
