/* eslint-disable no-else-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-restricted-syntax */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Form, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CREATE_LEAD_OPPORTUNITY, UPDATE_LEAD_OPPORTUNITY } from "../../graphql/mutation";
import { checkAccessPermission, closeDialog } from "../../library/helpers";
import LeadOpportunityInformationForm from "./leadOpportunityInformationForm";
import { GET_LEAD_OPPORTUNITIES_BY_ID, GET_LEAD_SOURCES_NEW } from "../../graphql/query";
import { UserContext } from "../../amplify/authenticator-provider";
import { getParentIds } from "../../library/utils";
import { AccessPermissionAction } from "../../library/constants";

export default function OpportunityFormWrapper({
  mode,
  lead,
  refetch = () => console.log("No refetch defined"),
  refetchLeads = () => console.log("No refetch defined"),
  getLeads = () => {},
}) {
  const { user } = useContext(UserContext);
  const [leadData, setleadData] = useState({ leadOpportunityUsers: [], vehiclesOfInterest: [] });
  const [selectedOpportunity, setselectedOpportunity] = useState({
    leadOpportunityUsers: [],
    vehiclesOfInterest: [],
  });
  const [updateLeadOpportunity, { loading: updateLeadOpportunityLoader }] =
    useMutation(UPDATE_LEAD_OPPORTUNITY);
  const [createLeadOpportunity, { loading: createLeadOpportunityLoader }] =
    useMutation(CREATE_LEAD_OPPORTUNITY);
  const [getLeadOpportunities] = useLazyQuery(GET_LEAD_OPPORTUNITIES_BY_ID);
  const [newOpportunity, setnewOpportunity] = useState(false);
  const [leadSources, setleadSources] = useState([]);
  const { loading: leadSourcesLoader } = useQuery(GET_LEAD_SOURCES_NEW, {
    fetchPolicy: "network-only",
    variables: {
      companyId: user.company.id,
      // locationIds: sessionStorage.getItem("currentLocationId").split(","),
    },
    onCompleted: res => {
      setleadSources(res.getLeadSourcesNew);
    },
  });
  const [form] = Form.useForm();
  const handleSave = async value => {
    if (mode === "EDIT" && !newOpportunity) {
      const payload = {
        ...selectedOpportunity,
        leadOpportunityUsers: selectedOpportunity.leadOpportunityUsers.map(item => ({
          id: item.id,
          userId: item.userId,
          userTypeId: item.userTypeId,
        })),
        locationId: value.location,
        leadOpportunityId: +selectedOpportunity.id,
        vehicleOfInterests: selectedOpportunity.vehiclesOfInterest,
        name: selectedOpportunity.opportunityName || selectedOpportunity.name,
      };
      const res = await updateLeadOpportunity({
        variables: {
          ...payload,
        },
      });
      if (res?.data?.updateLeadOpportunity?.statusCode === 200) {
        closeDialog("leadOpportunityFormDialog");
        refetch();
        refetchLeads();
        getLeads();
        // if (setSelectedLead) {
        //   // const opportunityRes = await getLeadOpportunities({
        //   //   variables: {
        //   //     leadId: lead.id,
        //   //   },
        //   // });
        //   // console.log(opportunityRes);
        // } else {
        // }
        message.success(res?.data?.updateLeadOpportunity?.message);
      } else {
        message.error(res?.data?.updateLeadOpportunity?.message);
      }
    } else {
      let payload = {};
      if (newOpportunity) {
        payload = {
          leadId: lead.id,
          ...selectedOpportunity,
          name: selectedOpportunity.opportunityName,
          locationId: value.location,
          vehicleOfInterests: selectedOpportunity.vehiclesOfInterest,
        };
      } else {
        payload = {
          leadId: lead.id,
          ...leadData,
          leadOpportunityUsers: leadData.leadOpportunityUsers.map(item => ({
            id: item.id,
            userId: item.userId,
            userTypeId: item.userTypeId,
          })),
          locationId: value.location,
          vehicleOfInterests: leadData.vehiclesOfInterest,
          name: leadData.opportunityName || "",
        };
      }
      const res = await createLeadOpportunity({
        variables: {
          ...payload,
        },
      });
      if (res.data.createLeadOpportunity.statusCode === 200) {
        message.success(res.data.createLeadOpportunity.message);
        closeDialog("leadOpportunityFormDialog");
        refetch();
        refetchLeads();
        getLeads();
        // if (setSelectedLead) {
        //   const opportunityRes = await getLeadOpportunities({
        //     variables: {
        //       leadId: lead.id,
        //     },
        //   });
        //   console.log(opportunityRes);
        // } else {
        // }
      } else {
        message.error(res.data.createLeadOpportunity.message);
      }
    }
  };
  useEffect(() => {
    if (mode === "EDIT") {
      setselectedOpportunity(lead);
      const vehicleObj = {};
      for (let index = 0; index < lead?.vehiclesOfInterest?.length; index++) {
        const element = lead.vehiclesOfInterest[index];
        for (const key of Object.keys(element)) {
          vehicleObj[`vehiclesOfInterest[${index}].${key}`] = element[key];
        }
      }
      const ownerObj = {};
      for (let index = 0; index < lead?.leadOpportunityUsers?.length; index++) {
        const element = lead.leadOpportunityUsers[index];
        ownerObj[`leadOpportunityUsers-${element.id}`] = +element.userId;
      }
      form.setFieldsValue({
        ...lead,
        location: lead?.location?.id,
        opportunityName: lead?.name,
        opportunityTypeId: +lead?.opportunityTypeId,
        opportunitySourceId: getParentIds(leadSources, +lead.opportunitySourceId),
        opportunityStatusId: +lead?.opportunityStatusId,
        // ...vehicleObj,
        ...ownerObj,
      });
    } else {
      form.setFieldsValue({
        location: sessionStorage.getItem("currentLocationId").includes(",")
          ? null
          : sessionStorage.getItem("currentLocationId"),
      });
    }
  }, [lead, mode, leadSources]);
  const canAddEditOpportunity = checkAccessPermission(
    AccessPermissionAction.LEAD_OPPORTUNITY_MANAGEMENT_ADD_EDIT
  );
  return (
    <div className="px-4">
      <Form form={form} layout="vertical" onFinish={handleSave}>
        <LeadOpportunityInformationForm
          mode={mode}
          leadData={leadData}
          setleadData={setleadData}
          form={form}
          lead={lead}
          selectedOpportunity={selectedOpportunity}
          setselectedOpportunity={setselectedOpportunity}
          newOpportunity={newOpportunity}
          setnewOpportunity={setnewOpportunity}
          showOpportunityDropdown={false}
          defaultOpportunity={lead}
          loader={updateLeadOpportunityLoader || createLeadOpportunityLoader}
          leadSources={leadSources}
          leadSourcesLoader={leadSourcesLoader}
          canAddEditOpportunity={canAddEditOpportunity}
        />
      </Form>
      {mode === "ADD" && (
        <div className="bg-grey-200 p-4 flex flex-row gap-1 justify-end">
          <Button onClick={() => closeDialog("leadOpportunityFormDialog")}>Cancel</Button>
          <Button
            onClick={() => form.submit()}
            loading={createLeadOpportunityLoader}
            type="primary"
          >
            {mode === "EDIT" ? "Update Opportunity" : "Create Opportunity"}
          </Button>
        </div>
      )}
    </div>
  );
}
