/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import "../../../smai-calling/callingComp/callingComp.css";
import { ModalStyled } from "./multipleMediaViewer";
import AntdIconV4 from "../../../../utils/antdIcon";

const MediaViewer = props => {
  const { isModalOpen, handleCancel, media, videoThumbnails = [], messageDetails } = props;
  return (
    <ModalStyled
      title={null}
      width="97.5%"
      visible={isModalOpen}
      onCancel={handleCancel}
      wrapClassName="mediaModal"
      footer={null}
      closeIcon={<AntdIconV4 type="CloseOutlined" className="text-white" />}
    >
      {media?.mediaType.includes("image") ? (
        <img
          src={media.mediaUrl}
          alt="nothing"
          width="100%"
          height="500px"
          className="object-contain mt-[10%]"
        />
      ) : (
        <video
          style={{ marginTop: "4%" }}
          className="w-full h-full"
          controls
          // poster={videoThumbnails}
        >
          <source src={media?.mediaUrl} type="video/mp4" />
        </video>
        // <h1>Video</h1>
      )}
    </ModalStyled>
  );
};

export default MediaViewer;
