/* eslint-disable no-cond-assign */
/* eslint-disable import/no-extraneous-dependencies */
import Icon, { CloseCircleOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import React, { useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import EngagedAI from "../../../../static/EngagedAi_trans_bg.svg";
import { GET_AI_RESPONSE, GET_USE_CASES } from "../../../graphql/query";
import useLang from "../../../hooks/useLang";

function LeadSummaryOpenAi({ selectedLead, user }) {
  const [lang] = useLang();
  const { data: useCases } = useQuery(GET_USE_CASES);
  const [misc, setmisc] = useState({
    loading: false,
    isShowLeadSummarySection: false,
    leadSummaryText: "",
  });
  const [generatedSummeryLead, setGeneratedSummeryLead] = useState(null);
  const [getAIResponse, { refetch: refetchAIResponse, loading: aiResponseLoading }] = useLazyQuery(
    GET_AI_RESPONSE,
    {
      fetchPolicy: "network-only",
      variables: {
        companyId: +user?.company?.id,
        applicationUseCaseId: +useCases?.getApplicationUseCases?.filter(
          useCase => useCase.code === "ENGAGEMENT_CUSTOMER_SUMMARY"
        )[0]?.id,
        leadId: selectedLead?.id,
      },
      onCompleted: res => {
        if (res?.getAiResponse?.statusCode !== 200) {
          message.error(lang.ottoAiSummaryFailedText);
        }
      },
    }
  );
  const createLeadSummary = async () => {
    setGeneratedSummeryLead(selectedLead);
    try {
      setmisc({ ...misc, loading: true, isShowLeadSummarySection: false });
      const parseResponse = async conversation => {
        const strippedConversation = conversation.replace(/<[^>]+>/g, ""); // removes all HTML tags

        const headingRegex = /<(.*?)-start>(.*?)<(.*?)-end>/g; // regular expression to match heading tags and their content

        const headings = {};
        let match;
        while ((match = headingRegex.exec(conversation)) !== null) {
          const [, heading, content] = match;
          headings[heading] = content.trim();
        }
        return headings;
      };

      const response = await getAIResponse();
      const headingRegex = /<(.*?)-start>(.*?)<(.*?)-end>/g; // regular expression to match heading tags and their content
      if ((headingRegex.exec(response?.data?.getAiResponse?.content) || [])?.length > 0) {
        const parsedObj = await parseResponse(response?.data?.getAiResponse?.content);
        setmisc({
          ...misc,
          isShowLeadSummarySection: true,
          parsedObj,
          typeObj: true,
        });
      } else {
        setmisc({
          ...misc,
          isShowLeadSummarySection: true,
          leadSummaryText: response?.data?.getAiResponse?.content,
          typeObj: false,
        });
      }
    } catch (err) {
      console.log(err.message);
      setmisc({
        ...misc,
        isShowLeadSummarySection: true,
        leadSummaryText:
          "Unable to show the summary at this time. This is an experimental feature, so we appreciate your patience while we're refining things.",
      });
    }
  };
  return (
    <div className="">
      <div className="flex flex-row items-center gap-1">
        <Button
          loading={aiResponseLoading}
          onClick={() => {
            createLeadSummary();
          }}
        >
          Otto 360° <Icon component={EngagedAI} className="text-sm ml-2" />
        </Button>
        {misc.isShowLeadSummarySection && (
          <CloseCircleOutlined
            className="cursor-pointer"
            onClick={() => setmisc({ ...misc, isShowLeadSummarySection: false })}
          />
        )}
      </div>
      {misc.isShowLeadSummarySection && (
        <div className="w-full text-start">
          {misc.typeObj ? (
            <Conversation headings={misc.parsedObj} />
          ) : (
            <div className="w-full text-start">{misc.leadSummaryText}</div>
          )}
        </div>
      )}
    </div>
  );
}

export default LeadSummaryOpenAi;

const Conversation = ({ headings }) => {
  return (
    <div>
      {Object.entries(headings).map(([heading, content]) => (
        <div key={heading}>
          <h4>{heading}</h4>
          <p>{content}</p>
        </div>
      ))}
    </div>
  );
};
