/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unreachable */
/* eslint-disable default-param-last */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-target-blank */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Avatar,
  Form,
  Image,
  Input,
  List,
  Select,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { isEmpty, isUndefined } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";

import { useDispatch } from "react-redux";
import { navigate } from "gatsby";
import { UserContext } from "../../../../amplify/authenticator-provider";
import { DEFAULT_COUNTRY } from "../../../../config";
import {
  CREATE_LEAD,
  CREATE_LEAD_PHONE,
  PULL_LEAD_FROM_CRM,
  SYNC_UP_LEAD,
} from "../../../../graphql/mutation";
import {
  GET_LEADS_BY_PHONE,
  GET_LEADS_PHONE_FROM_CRM,
  GET_LEAD_SOURCES,
  GET_LEAD_SOURCES_DS,
  GET_LOCATIONS,
  GET_LOCATION_VEHICLE_INVENTORY,
} from "../../../../graphql/query";
import useLang from "../../../../hooks/useLang";
import { AccessPermissionAction, currencies } from "../../../../library/constants";
import {
  checkAccessPermission,
  closeDialog,
  showConfirmationModal,
} from "../../../../library/helpers";
import { formatPrice, vehicleFilter } from "../../../../library/utils";
import SelectSchedule from "../../../../library/selectSchedule";
import TextInput from "../../../../library/textInput";
import { pinLeadToTop } from "../../../../redux/slices/leadSlice";
import { setLocationIdSlice } from "../../../../redux/slices/locationSlice";
import CustomButton from "../../../commonComponents/button";
import DialogFooter from "../../../commonComponents/dialogFooter";
import CustomLabel from "../../../commonComponents/label";
import { LeadStatus, displayPhoneNumbers } from "../engagementHelpers";
import { crmLinkGeneration } from "../opporotunityLayer/crmLinks";
import OpportunityWarning, {
  handleOpportunityDilaog,
} from "../opporotunityLayer/opportuityWarning";

const ModelDiv = styled.div`
  .ant-list-item-action {
    display: flex;
    justify-content: end;
  }
`;

const AddPhoneModal = props => {
  const {
    phoneNumber,
    setModalVisible,
    onSetLead,
    variables,
    sources,
    onLeadStatus,
    onLeadOwners,
    onVehicles,
    statusList,
    isSyncUp = true,
    crmIntegration = "",
    isFromChatbot = false,
    leadFirstName = "",
    leadLastName = "",
    handleLeadCRMName = () => {},
    userLocation = false,
    handleCancelBtn = () => {},
    entityId,
    eventId,
    locationName,
    isFromCRM = false,
    vinCustomerId,
    vinDealerId,
  } = props;
  const [lang] = useLang();
  const { user } = useContext(UserContext);
  const formatedPhone = isUndefined(phoneNumber)
    ? ""
    : phoneNumber.length === 10
    ? `+1${phoneNumber}`
    : phoneNumber.length === 11
    ? `+${phoneNumber}`
    : phoneNumber;
  const dispatch = useDispatch();
  const canchangeLocation = checkAccessPermission(
    AccessPermissionAction.LEAD_MANAGEMENT_SEND_TO_STORE
  );

  const [isOpporDialogFlagChanged, setIsOpprDialogFlagChanged] = useState(false);
  const [userLocationsId, setUserLocationsId] = useState([]);
  const [phone, setPhone] = useState(formatedPhone);
  const [firstName, setFirstName] = useState(leadFirstName || "");
  const [lastName, setLastName] = useState(leadLastName || "");
  const [leads, setLeads] = useState([]);
  const [otherData, setotherData] = useState({});
  const [misc, setmisc] = useState({
    state: false,
    data: {},
  });
  const [opportunityWarningDialog, setOpportunityWarningDialog] = useState(false);
  const [opportunityInfo, setOpportunityInfo] = useState({});
  const [isCrm, setisCrm] = useState(false);
  const [skipduplicate, setskipduplicate] = useState(false);

  const [createLead, { loading: createLeadLoader }] = useMutation(CREATE_LEAD);
  const [createLeadPhone, { loading: createLeadPhoneLoader }] = useMutation(CREATE_LEAD_PHONE);
  const [syncUpLead] = useMutation(SYNC_UP_LEAD);
  const [selectedSource, setCampaignSource] = useState([]);
  const [getLeadsPhoneFromCRM, { loading: crmLoading }] = useLazyQuery(GET_LEADS_PHONE_FROM_CRM, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      setLeads(res?.getLeadsByPhoneFromCrm || []);
      setisCrm(true);
    },
  });
  const { data: resp } = useQuery(GET_LOCATIONS, {
    variables: { companyId: user?.company?.id },
  });

  const [pullCrmLead, { loading: crmPullLoader }] = useMutation(PULL_LEAD_FROM_CRM);

  // console.log(user);
  const locationId = sessionStorage.getItem("currentLocationId");
  // console.log("addphonemodal:locationId", locationId);
  const { data: vdpData, loading: vdpLoader } = useQuery(GET_LOCATION_VEHICLE_INVENTORY, {
    variables: {
      locationId: sessionStorage.getItem("currentLocationId"),
      // search,
    },
  });
  const phoneUpForNissan = [
    {
      id: "7610",
      name: "AutoAlert",
    },
    {
      id: "7223",
      name: "KBB",
    },
    {
      id: "7233",
      name: "Buy Back Mailer",
    },
    {
      id: "7594",
      name: "Capital One",
    },
    {
      id: "7294",
      name: "CarFax",
    },
    {
      id: "7378",
      name: "CarGurus",
    },
    {
      id: "7921",
      name: "Credit Card Mailer",
    },
    {
      id: "5157",
      name: "Drive By/Location",
    },
    {
      id: "7562",
      name: "Google",
    },
    {
      id: "7209",
      name: "Lease Retention",
    },
    {
      id: "8327",
      name: "Nissan List Other",
    },
    {
      id: "7538",
      name: "Nissan USA",
    },
    {
      id: "7388",
      name: "Referral",
    },
    {
      id: "7218",
      name: "Retail Retention",
    },
    {
      id: "7566",
      name: "Service Customer",
    },
    {
      id: "7474",
      name: "ShopEmpireAuto.com",
    },
    {
      id: "7536",
      name: "Website",
    },
  ];

  const phoneUpForToyota = [
    {
      id: "7378",
      name: "CarGurus",
    },
    {
      id: "7294",
      name: "CarFax",
    },
    {
      id: "7740",
      name: "Cars.com",
    },
    {
      id: "7760",
      name: "Edmunds",
    },
    {
      id: "7447",
      name: "AutoAlert",
    },
    {
      id: "7402",
      name: "Dealership Website",
    },
    {
      id: "8197",
      name: "Honda Conquest Mail",
    },
    {
      id: "7209",
      name: "Lease Retention",
    },
    {
      id: "7388",
      name: "Referral",
    },
    {
      id: "7218",
      name: "Retail Retention",
    },
    {
      id: "7475",
      name: "Service Sales",
    },
    {
      id: "7474",
      name: "ShopEmpireAuto.com",
    },
  ];
  const handleSearch = e => {
    setPhone(e);
  };

  const handleCancel = () => {
    if (isFromChatbot) {
      handleCancel();
    }
    handleLeadCRMName();
    closeDialog("addLeadViaPhone");
  };
  useEffect(() => {
    if (!sessionStorage.getItem("currentLocationId").includes(",")) {
      setotherData({ ...otherData, location: sessionStorage.getItem("currentLocationId") });
    }
  }, []);
  const handleSave = async e => {
    if (e) {
      if (isEmpty(phone)) {
        message.error(lang.enterPhoneNumber);
        return;
      }
      if (isEmpty(firstName)) {
        message.error(lang.enterFirstNameLabel);
        return;
      }
      if (isEmpty(lastName)) {
        message.error(lang.enterLastNameLabel);
        return;
      }

      const defaultSource = sources.find(item => item.name === "SMAI")?.id || 7;
      // console.log("handleSave:user", user);
      // console.log("handleSave:locationName", otherData);
      // console.log("handleSave:locationId", locationId);
      try {
        if (isEmpty(otherData?.location)) {
          return message.error("Select a location before proceeding");
        }
        let payload = {
          firstName: firstName,
          lastName: lastName,
          fullName: `${firstName} ${lastName}`,
          leadSourceType: "MANUAL",
          leadSourceOriginalId: parseInt(selectedSource) || defaultSource,
          phones: [{ phone: phone, phoneType: "Mobile" }],
          primaryOwnerId: user?.id,
          dsEventId: eventId || null,
          dsEntityId: entityId || null,
          locationId: otherData.location,
          ...otherData,
          isPushCrm: entityId || isFromCRM ? false : true,
          leadOpportunityUsers: [
            {
              userTypeId: null,
              userId: user?.id,
            },
          ],
        };
        // console.log("handleSave PAYLOAD:", payload);

        if (vinCustomerId) {
          payload = { ...payload, vsContactId: vinCustomerId };
        }
        if (vinDealerId) {
          payload = { ...payload, vsDealerId: vinDealerId };
        }

        const res = await createLead({
          variables: payload,
        });

        if (res?.data?.createLead?.statusCode === 200) {
          const lead = res?.data?.createLead?.lead || {};
          onSetLead(lead, "new", "phoneLead");
          setModalVisible(false);
          closeDialog("addLeadViaPhone");
          message.success(res?.data?.createLead.message);
        } else {
          message.error(res?.data?.createLead?.message);
        }
      } catch (err) {
        console.log("Error in createLead", err);
      }
    }
  };

  const pullToSMAI = (id, locationId, lead, leadPhone) => {
    pullCrmLead({
      variables: {
        crmLeadId: id,
        locationId: locationId,
      },
    }).then(res => {
      if (res?.data?.pullCrmLead?.statusCode === 200) {
        const lead = res?.data?.pullCrmLead?.lead || {};
        setModalVisible(false);
        closeDialog("addLeadViaPhone");
        onSetLead(
          {
            ...lead,
          },
          "new",
          "phoneLead"
        );
      }
    });
  };

  const onCreateLead = () => {
    if (!canchangeLocation) {
      message.error(lang.viewLeadCurrentLocation);
      return;
    }
    const location = (resp.getLocations || []).find(
      el => +el.id === +sessionStorage.getItem("currentLocationId")
    );
    const MODAL_CONTENT = `${lang.createSameLead} ${
      location?.title
    } ${lang.location.toLowerCase()}`;
    showConfirmationModal(lang.doYouWantToContinue, MODAL_CONTENT, () => console.log("OKKK"));
  };

  useEffect(() => {
    if (user && user?.locations?.length > 0) {
      const locationIDs = user?.locations.map(location => location?.location?.id);
      setUserLocationsId(locationIDs);
    }
  }, [user]);

  const locationPermission = lead => {
    console.log("locationPermission:lead", lead, "| userLocationsId", userLocationsId);
    const lead_opp_locations = lead?.leadOpportunities?.map(opp => opp?.location);
    console.log("locationPermission:lead_opp_locations", lead_opp_locations);
    let userHasLocationPermission = false;
    if (lead_opp_locations?.length > 0 && userLocationsId?.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const loc of lead_opp_locations) {
        if (userLocationsId.includes(loc?.id)) {
          userHasLocationPermission = true;
          break;
        }
      }
    }
    if (userHasLocationPermission) {
      const location = sessionStorage.getItem("currentLocationId");
      const lead_opp_location_ids = lead_opp_locations?.map(loc => loc?.id);
      if (
        +location === +lead?.location?.id ||
        lead_opp_location_ids.includes(+location) ||
        location.includes(",")
      ) {
        dispatch(pinLeadToTop(lead));
      } else {
        const { phone } = lead.phoneNumbers[0];
        const lead_opp_loc_id = lead?.location?.id || lead_opp_locations[0]?.id;
        if (window.location.pathname.includes("browser-ext")) {
          navigate(
            `/browser-ext/${phone.replace("+", "")}?loc=${lead_opp_loc_id}&leadId=${lead.id}`
          );
        } else {
          navigate(
            `/engagements/activity-center/${phone.replace("+", "")}?loc=${lead_opp_loc_id}&leadId=${
              lead.id
            }`
          );
        }
        dispatch(setLocationIdSlice({ locationId: lead_opp_loc_id }));
        sessionStorage.setItem("currentLocationId", lead_opp_loc_id);
        closeDialog("addLeadViaPhone");
      }
      closeDialog("addLeadViaPhone");
    } else {
      message.error(`${lang.leadPermission} ${lead?.location?.title}`);
    }
  };

  const handleOpporDialogFlagChanged = () => {
    setIsOpprDialogFlagChanged(true);
    localStorage.setItem("isOpporDialogFlagChangedLocal", "true");
  };

  const handleOpportunityWarningDialog = (
    item,
    crmLink,
    opprId,
    opprName,
    isOpp = false,
    crmLeadObj
  ) => {
    setOpportunityInfo({
      ...item,
      opprLink: crmLink,
      opprId: opprId,
      opprName: opprName,
      isOpp: isOpp,
      crmLeadObj: crmLeadObj,
    });
    setOpportunityWarningDialog(true);
  };

  const handleOpportunityIdDialog = (oppr, crmLeadObj, lead) => {
    // DS
    if (
      (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
      crmLeadObj?.dsExtractedLead?.dsLeadId
    ) {
      const opprId =
        oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId
          ? String(oppr?.dsOpportunityEvents[0]?.dsEventId)
          : crmLeadObj?.dsExtractedLead?.dsLeadId
          ? String(crmLeadObj?.dsExtractedLead?.dsLeadId).padStart(4, "0")
          : oppr?.id;

      let opprName;
      if (
        (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
        crmLeadObj?.dsExtractedLead?.dsLeadId
      ) {
        opprName =
          oppr &&
          oppr?.dsOpportunityEvents &&
          oppr?.dsOpportunityEvents.length > 0 &&
          oppr?.dsOpportunityEvents[0]?.dsEventId
            ? `${lang.salesOpportunity} - ${oppr?.name}`
            : oppr?.name;
      }

      const oppLink =
        oppr?.dsOpportunityEvents?.length > 0 &&
        crmLinkGeneration(
          "DS",
          Boolean(oppr?.dsOpportunityEvents[0]?.dsEventId),
          opprId,
          crmLeadObj?.dsExtractedLead?.dsLeadId
        );
      const dsOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme"
            onClick={() =>
              handleOpportunityWarningDialog(oppr, oppLink, opprId, opprName, false, crmLeadObj)
            }
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme"
            onClick={() => {
              message.error(`${lang.dontHaveOppr} ${lead?.location?.title}`);
            }}
          >
            <p>{lang.viewDetails}</p>
          </div>
        );
      return dsOppType;
    }

    // SF
    if (
      (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
      crmLeadObj?.sfExtractedLead?.sfLeadId
    ) {
      const opprId =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? String(oppr?.sfLeadOpportunities[0]?.sfOpportunityId)
          : crmLeadObj?.sfExtractedLead?.sfLeadId
          ? String(crmLeadObj?.sfExtractedLead?.sfLeadId)
          : oppr?.id;

      const oppLink =
        oppr?.sfLeadOpportunities?.length > 0 &&
        crmLinkGeneration(
          "SF",
          Boolean(oppr?.sfLeadOpportunities[0]?.sfOpportunityId),
          opprId,
          "",
          "usAutoSales"
        );

      const isOpp =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? true
          : false;

      let opprName;
      if (
        (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
        crmLeadObj?.sfExtractedLead?.sfLeadId
      ) {
        opprName = oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? oppr?.name
          : `${lang.leadLabel}: ${lead?.fullName}`; // asdklasjdasdlkaj
      }
      const sfOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme"
            onClick={() =>
              handleOpportunityWarningDialog(oppr, oppLink, opprId, opprName, isOpp, crmLeadObj)
            }
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme"
            onClick={() => {
              message.error(
                `${lang.donthavePermission} ${
                  isOpp ? lang.opportunity.toLowerCase() : lang.lead
                } in the ${lead?.location?.title}`
              );
            }}
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        );

      return sfOppType;
    }

    // VIN
    if (
      (oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId) ||
      crmLeadObj?.vsExtractedLead?.vsContactId
    ) {
      const opprId =
        oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId
          ? String(oppr?.vsLeadOpportunities[0]?.vsContactId)
          : crmLeadObj?.vsExtractedLead?.vsContactId
          ? String(crmLeadObj?.vsExtractedLead?.vsContactId)
          : oppr?.id;

      const oppLink =
        oppr?.vsLeadOpportunities?.length > 0 &&
        crmLinkGeneration(
          "VIN",
          Boolean(oppr?.vsLeadOpportunities[0]?.vsContactId),
          opprId,
          "",
          "VinSolution"
        );

      const isOpp =
        oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId
          ? true
          : false;

      let opprName;
      if (
        (oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId) ||
        crmLeadObj?.vsExtractedLead?.vsContactId
      ) {
        opprName = oppr?.vsLeadOpportunities[0]?.vsContactId
          ? oppr?.name
          : `${lang.leadLabel}: ${lead?.fullName}`;
      }
      const sfOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme"
            onClick={() =>
              handleOpportunityWarningDialog(oppr, oppLink, opprId, opprName, isOpp, crmLeadObj)
            }
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme"
            onClick={() => {
              message.error(
                `${lang.donthavePermission} ${
                  isOpp ? lang.opportunity.toLowerCase() : lang.lead
                } in the ${lead?.location?.title}`
              );
            }}
          >
            <div className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme">
              {lang.viewDetails}
            </div>
          </div>
        );

      return sfOppType;
    }

    return "";
  };

  const handleOpportunityId = (oppr, crmLeadObj, lead) => {
    // DS
    if (
      (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
      crmLeadObj?.dsExtractedLead?.dsLeadId
    ) {
      const opprId =
        oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId
          ? String(oppr?.dsOpportunityEvents[0]?.dsEventId)
          : crmLeadObj?.dsExtractedLead?.dsLeadId
          ? String(crmLeadObj?.dsExtractedLead?.dsLeadId).padStart(4, "0")
          : oppr?.id;

      const oppLink =
        oppr?.dsOpportunityEvents?.length > 0 &&
        crmLinkGeneration(
          "DS",
          Boolean(oppr?.dsOpportunityEvents[0]?.dsEventId),
          opprId,
          crmLeadObj?.dsExtractedLead?.dsLeadId
        );

      const dsOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <a href={oppLink} target="_blank">
            <div className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme">
              <p>{lang.viewDetails}</p>
            </div>
          </a>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme"
            onClick={() => {
              message.error(`${lang.dontHaveOppr} ${lead?.location?.title}`);
            }}
          >
            <p>{lang.viewDetails}</p>
          </div>
        );
      return dsOppType;
    }

    // SF
    if (
      (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
      crmLeadObj?.sfExtractedLead?.sfLeadId
    ) {
      const opprId =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? String(oppr?.sfLeadOpportunities[0]?.sfOpportunityId)
          : crmLeadObj?.sfExtractedLead?.sfLeadId
          ? String(crmLeadObj?.sfExtractedLead?.sfLeadId)
          : oppr?.id;

      const oppLink =
        oppr?.sfLeadOpportunities?.length > 0 &&
        crmLinkGeneration(
          "SF",
          Boolean(oppr?.sfLeadOpportunities[0]?.sfOpportunityId),
          opprId,
          "",
          "usAutoSales"
        );

      const isOpp =
        oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId
          ? true
          : false;

      const sfOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <a href={oppLink} target="_blank">
            <div className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme">
              <p>{lang.viewDetails}</p>
            </div>
          </a>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme"
            onClick={() => {
              message.error(
                `You do not have permissions to view ${isOpp ? "opportunity" : "lead"} in the ${
                  lead?.location?.title
                }`
              );
            }}
          >
            <p>{lang.viewDetails}</p>
          </div>
        );

      return sfOppType;
    }

    // VIN
    if (
      (oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId) ||
      crmLeadObj?.vsExtractedLead?.vsContactId
    ) {
      const opprId =
        oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId
          ? String(oppr?.vsLeadOpportunities[0]?.vsContactId)
          : crmLeadObj?.vsExtractedLead?.vsContactId
          ? String(crmLeadObj?.vsExtractedLead?.vsContactId)
          : oppr?.id;

      const oppLink =
        oppr?.vsLeadOpportunities?.length > 0 &&
        crmLinkGeneration(
          "VIN",
          Boolean(oppr?.vsLeadOpportunities[0]?.vsContactId),
          opprId,
          "",
          "VinSolution"
        );

      const isOpp =
        oppr?.vsLeadOpportunities?.length > 0 && oppr?.vsLeadOpportunities[0]?.vsContactId
          ? true
          : false;

      const sfOppType =
        userLocationsId?.length > 0 && userLocationsId.includes(lead?.location?.id) ? (
          <a href={oppLink} target="_blank">
            <div className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme">
              <p>{lang.viewDetails}</p>
            </div>
          </a>
        ) : (
          <div
            className="font-sans text-[16px] font-medium block shadow-none secondaryColorTheme"
            onClick={() => {
              message.error(
                `You do not have permissions to view ${isOpp ? "opportunity" : "lead"} in the ${
                  lead?.location?.title
                }`
              );
            }}
          >
            <p>{lang.viewDetails}</p>
          </div>
        );

      return sfOppType;
    }

    return "";
  };

  const APICallForPhone = e => {
    setPhone(e);
  };

  const { data: sourcesData } = useQuery(GET_LEAD_SOURCES);

  const [getDssources, { data: dsSources, loading: getLeadSourcesLoader }] = useLazyQuery(
    GET_LEAD_SOURCES_DS,
    {
      onCompleted: res => {
        // setCampaignSource();
      },
    }
    // {
    //   variables: {
    //     companyId: user?.company?.id,
    //   },
    // }
  );

  useEffect(() => {
    if (user.company.crmIntegrationType === "DS") {
      getDssources({
        variables: {
          companyId: user?.company?.id,
        },
      });
    }
  }, [user.company.crmIntegrationType]);

  useEffect(() => {
    setCampaignSource("7");
  }, []);

  // const debounceFnPhone = useCallback(debounce(APICallForPhone, 500), []);
  const [form] = Form.useForm();
  return (
    <>
      <ModelDiv>
        <PhoneInputJsx lang={lang} phone={phone} handleSearch={handleSearch} />
        {!isEmpty(phone) && (
          <FoundPhoneJSX
            leads={leads}
            phone={phone}
            skipduplicate={skipduplicate}
            isCrm={isCrm}
            lang={lang}
            variables={variables}
            crmLoading={crmLoading}
            crmPullLoader={crmPullLoader}
            crmIntegration={crmIntegration}
            handleOpportunityId={handleOpportunityId}
            user={user}
            handleOpportunityIdDialog={handleOpportunityIdDialog}
            onCreateLead={onCreateLead}
            locationPermission={locationPermission}
            onLeadStatus={onLeadStatus}
            pullToSMAI={pullToSMAI}
            onLeadOwners={onLeadOwners}
            onVehicles={onVehicles}
            isFromCRM={isFromCRM}
            locationName={locationName}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            otherData={otherData}
            setotherData={setotherData}
            locationId={locationId}
            setCampaignSource={setCampaignSource}
            phoneUpForNissan={phoneUpForNissan}
            phoneUpForToyota={phoneUpForToyota}
            vdpData={vdpData}
            setLeads={setLeads}
            setisCrm={setisCrm}
            getLeadsPhoneFromCRM={getLeadsPhoneFromCRM}
          />
        )}

        {/* <ChangeStatusModal data={misc} setdata={setmisc} api /> */}
      </ModelDiv>
      {opportunityWarningDialog && (
        <OpportunityWarning
          opportunityInfo={opportunityInfo}
          crmIntegrationType={crmIntegration}
          crmLeadObj={opportunityInfo?.crmLeadObj}
          visibleModal={opportunityWarningDialog}
          handleOKBtn={() => setOpportunityWarningDialog(false)}
          setModalVisible={() => setOpportunityWarningDialog(false)}
          handleOpporDialogFlagChanged={handleOpporDialogFlagChanged}
        />
      )}
      <DialogFooter
        disabled={leads.length > 0 && !skipduplicate}
        showConfirmButton={leads.length === 0}
        confirmBtnText={isFromCRM ? lang.startConversation : lang.save}
        cancelBtnText={lang.cancel}
        handleConfirm={handleSave}
        handleCancel={isFromChatbot ? handleCancelBtn : handleCancel}
        loading={createLeadLoader || createLeadPhoneLoader}
        extraComponent={
          phone && phone.length > 3 && leads.length > 0 ? (
            <CustomButton
              className="mr-2"
              btnText={skipduplicate ? lang.close : lang.ignoreAndAddLead}
              key="skip"
              handleClick={() => setskipduplicate(!skipduplicate)}
            />
          ) : (
            ""
          )
        }
      />
      <style jsx>{`
        .phoneInput input {
          border: 1px solid #d9d9d9;
          width: 373px;
          padding: 5px;
          border-radius: 4px;
        }
      `}</style>
    </>
  );
};

export default AddPhoneModal;
function FoundPhoneJSX({
  leads,
  phone,
  skipduplicate,
  isCrm,
  lang,
  crmLoading,
  crmPullLoader,
  crmIntegration,
  handleOpportunityId,
  user,
  handleOpportunityIdDialog,
  onCreateLead,
  locationPermission,
  onLeadStatus,
  pullToSMAI,
  onLeadOwners,
  onVehicles,
  isFromCRM,
  locationName,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  otherData,
  setotherData,
  locationId,
  setCampaignSource,
  phoneUpForNissan,
  phoneUpForToyota,
  vdpData,
  variables,
  setisCrm,
  setLeads,
  getLeadsPhoneFromCRM,
}) {
  const { loading } = useQuery(GET_LEADS_BY_PHONE, {
    fetchPolicy: "network-only",
    variables: {
      pageSize: 5,
      page: 1,
      phone: phone,
    },
    onCompleted: resp => {
      if (resp?.getLeadsSearch?.data.length > 0) {
        setisCrm(false);
        setLeads(resp?.getLeadsSearch?.data || []);
      } else {
        getLeadsPhoneFromCRM({
          variables: {
            phone: phone,
          },
        });
      }
    },
  });

  return (
    <>
      {leads.length > 0 && !isEmpty(phone) && !skipduplicate && (
        <Typography className="w-full mt-1" variant="small" weight="medium">
          {lang.matchPhoneInCrm}
        </Typography>
      )}
      {loading || crmLoading || crmPullLoader ? (
        <div className="flex mt-2">
          <Skeleton active avatar loading={loading || crmLoading || crmPullLoader} />
        </div>
      ) : (
        <>
          {" "}
          {leads.length > 0 && !isEmpty(phone) && !skipduplicate && (
            <div className="w-full">
              <List
                itemLayout="vertical"
                dataSource={leads}
                pagination={false}
                renderItem={item => {
                  const links = [];
                  crmIntegration?.length > 0 &&
                    links.push(
                      localStorage.getItem("isOpporDialogFlagChangedLocal")
                        ? handleOpportunityId(
                            item?.crmLeadObj?.activeLeadOpportunity,
                            item?.crmLeadObj,
                            item
                          )
                        : !handleOpportunityDilaog(user?.locations, user)
                        ? handleOpportunityId(
                            item?.crmLeadObj?.activeLeadOpportunity,
                            item?.crmLeadObj,
                            item
                          )
                        : handleOpportunityIdDialog(
                            item?.crmLeadObj?.activeLeadOpportunity,
                            item?.crmLeadObj,
                            item
                          )
                    );

                  const email = item?.emails ? item?.emails[0]?.email || "" : "";
                  const leadStatus = item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus
                    ?.parentStatus
                    ? `${
                        item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.parentStatus
                          ?.name
                      } / ${
                        item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name || ""
                      }`.replace(/_/g, " ")
                    : item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name || "";
                  return (
                    <List.Item className="px-0" actions={links}>
                      <List.Item.Meta
                        avatar={
                          <Avatar size={40}>
                            {item?.fullName ? item?.fullName.substring(0, 1).toUpperCase() : "X"}
                          </Avatar>
                        }
                        title={
                          <div className="labelColor flex flex-wrap justify-between">
                            {!isCrm ? (
                              <CustomLabel
                                onClick={() => {
                                  locationPermission(item);
                                }}
                                label={
                                  !isCrm
                                    ? item?.fullName || ""
                                    : `${item?.firstName} ${item?.lastName}`
                                }
                                labelClass="labelColor font-medium cursor-pointer"
                              />
                            ) : (
                              <CustomLabel
                                label={
                                  !isCrm
                                    ? item?.fullName || ""
                                    : `${item?.firstName} ${item?.lastName}`
                                }
                                labelClass="labelColor font-medium"
                              />
                            )}
                            {!isCrm ? (
                              <div>
                                <Tooltip placement="topLeft" title={leadStatus}>
                                  {leadStatus}
                                </Tooltip>
                              </div>
                            ) : (
                              <div style={{ cursor: "pointer" }}>
                                <Tag
                                  onClick={() =>
                                    pullToSMAI(item?.leadId, item?.locationId, item, item.mobile)
                                  }
                                >
                                  {lang.startConversation}
                                </Tag>
                              </div>
                            )}
                          </div>
                        }
                        description={
                          <>
                            <div className="m-0 w-full mb-[6px] items-start">
                              <strong>{lang.phoneNumbers}: </strong>
                              {!isCrm ? displayPhoneNumbers(item?.phoneNumbers || []) : item.mobile}
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              <strong>{lang.emailLabel}: </strong>
                              {!isCrm ? (email === "" ? "N/A" : email) : item.email}
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              <strong>{lang.source}: </strong>
                              {`${
                                item?.crmLeadObj?.activeLeadOpportunity?.opportunitySource?.name ||
                                "N/A"
                              }`}
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              <strong>{lang.location}: </strong>
                              {!isCrm
                                ? `${item?.location?.title || ""} (${
                                    item?.location?.locationType || lang.store
                                  })`
                                : item.location}
                            </div>

                            <div className="m-0 w-full mb-[6px] items-start">
                              <strong>{lang.status}: </strong>
                              <span>
                                {LeadStatus(
                                  user,
                                  item?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name
                                )}
                              </span>
                            </div>
                            {(item?.leadUsers || []).length > 0 && !isCrm ? (
                              <>
                                {(crmIntegration === "SF" || crmIntegration === "DS") && (
                                  <div className="m-0 w-full mb-[6px] items-start">
                                    <strong>{lang.owners}:</strong>
                                  </div>
                                )}
                                <div className="m-0 w-full mb-[6px] items-start">
                                  {crmIntegration === "" && <strong>{lang.owners}:</strong>}{" "}
                                  <p className="text-left">
                                    {item?.leadUsers.length > 0
                                      ? onLeadOwners(item?.leadUsers || [], crmIntegration)
                                      : "N/A"}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <div className="m-0 w-full mb-[6px] items-start">
                                <strong>{lang.owner}: </strong>
                                <span className="text-left">
                                  {item.owner && item.owner !== "" ? item.owner : "N/A"}
                                </span>
                              </div>
                            )}
                            {(item?.crmLeadObj?.activeLeadOpportunity?.vehicleOfInterest || [])
                              .length > 0 &&
                              !isCrm && (
                                <div className="m-0 w-full mb-[6px] items-start">
                                  <p>
                                    {lang.vehicles}:{" "}
                                    <span className="text-left">
                                      {onVehicles(
                                        item?.crmLeadObj?.activeLeadOpportunity
                                          ?.vehicleOfInterest || []
                                      )}
                                    </span>
                                  </p>{" "}
                                </div>
                              )}
                          </>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </div>
          )}
          {(leads.length <= 0 || skipduplicate) && !isEmpty(phone) && (
            <>
              {!isFromCRM && !isEmpty(locationName) && (
                <Alert
                  className="mt-2 mb-2"
                  message={
                    <>
                      This lead will be created in <b>{locationName}</b>
                    </>
                  }
                  type="info"
                  showIcon
                />
              )}
              <div className="w-full">
                <p className="mt-1 font-normal w-full">
                  {lang.firstName}
                  <span className="requireFieldStar">*</span>
                </p>

                <TextInput
                  placeholder={lang.firstName}
                  name="firstName"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </div>
              {/* <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[{ required: true, message: "First name is required" }]}
                >
                  <Input placeholder={lang.firstName} name="firstName" />
                </Form.Item> */}
              <div className="w-full">
                <p className="mt-1 font-normal w-full">
                  {lang.lastName}
                  <span className="requireFieldStar">*</span>
                </p>
                <TextInput
                  placeholder={lang.lastName}
                  name="lastName"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </div>
              {/* <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input placeholder={lang.lastName} name="lastName" />
                </Form.Item> */}
              <div className="w-full">
                <p className="mt-1 font-normal w-full">
                  Location
                  <span className="requireFieldStar">*</span>
                </p>
                <Select
                  className="w-full"
                  name="location"
                  value={otherData.location}
                  placeholder="Select a location"
                  onChange={value => setotherData({ ...otherData, location: value })}
                >
                  {user.locations.map((item, index) => (
                    <Select.Option key={index} value={item?.location?.id}>
                      {item?.location?.title}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              {!isFromCRM && (
                <div className="w-full">
                  <p className="mt-1 font-normal w-full">
                    Lead Source
                    <span className="requireFieldStar">*</span>
                  </p>
                  <Input value="Phone Up" disabled />
                </div>
              )}
              {/* {sessionStorage.getItem("currentLocationId").includes(",") && (
                  <>
                    <p className="mt-1 font-normal w-full">
                      Location
                      <span className="requireFieldStar">*</span>
                    </p>
                    <Select>
                      {user.locations.map((item, i) => (
                        <Select.Option value={item.location.id} key={i}>
                          {item.location.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                )} */}
              {!isFromCRM && (locationId === "340" || locationId === "338") && (
                <div className="w-full mt-5">
                  <SelectSchedule
                    // mode="multiple"
                    // value={selectedSource}
                    placeholder={lang.selectSources}
                    showAll={false}
                    onChange={e => {
                      setCampaignSource(e);
                      // onSetCampign({
                      //   ...campaign,
                      //   campaignSources: e,
                      // });
                    }}
                    className="col-span-5"
                    data={
                      // user.company.crmIntegrationType === "DS"
                      //   ? dsSources?.getLeadSources || []
                      //   : sourcesData?.leadSources || []
                      locationId === "340" ? phoneUpForNissan : phoneUpForToyota
                    }
                    size="large"
                    isNeedToSearch
                    showSearch
                  />
                </div>
              )}
              <div className="flex flex-col w-full">
                <label>Select Vehicle</label>
                <Select
                  style={{ height: "auto" }}
                  showSearch
                  onChange={value =>
                    setotherData({
                      ...otherData,
                      vehicleOfInterests: [
                        {
                          inputType: "inventory",
                          inventoryId: +value,
                        },
                      ],
                    })
                  }
                  className="w-full"
                  value={
                    otherData?.vehicleOfInterests &&
                    otherData?.vehicleOfInterests.length > 0 &&
                    +otherData?.vehicleOfInterests[0]?.inventoryId
                  }
                  filterOption={(input, option) => vehicleFilter(input, option)}
                >
                  {(vdpData?.getVehicleInventory?.data || []).map((item, index) => (
                    <Select.Option
                      value={+item.id}
                      key={index}
                      label={`${item.year || ""} ${item.make || ""} ${item.model || ""}`}
                      vehicle={item}
                    >
                      <div className="flex flex-row gap-2 items-center">
                        <Image.PreviewGroup
                          items={(item?.vehicleInventoryMedias || []).map(media => media.mediaUrl)}
                        >
                          <Image
                            fallback="https://funnel-webapp-uploads.s3.amazonaws.com/prod/images/generic-car-not-available-image.png"
                            src={(item?.vehicleInventoryMedias || [])[0]?.mediaUrl || ""}
                            height="40px"
                            width="40px"
                            className="rounded-full"
                          />
                        </Image.PreviewGroup>
                        <div className="flex flex-col gap-0.5">
                          <span className="text-sm font-[600]">
                            {item.year || ""} {item.make || ""} {item.model || ""}
                          </span>
                          <span className="text-sm text-grey-700">
                            <span className="font-semibold">MSRP:</span>{" "}
                            {item?.msrp || item?.sellingPrice
                              ? `${
                                  currencies?.find(currency => currency.code === item.currencyUnit)
                                    ?.symbol
                                } ${formatPrice(item?.msrp || item?.sellingPrice) || "N/A"}`
                              : "Price not available"}
                          </span>
                          <span className="text-xs font[400] text-[#64748B]">{item.vin}</span>
                          <span className="text-sm text-grey-700">
                            <span className="font-semibold">Stock No</span>{" "}
                            <span>{item?.stockNumber || "N/A"}</span>
                          </span>
                          <span className="text-sm text-grey-700">
                            <span className="font-semibold">Model No</span>{" "}
                            <span>{item?.modelNumber || "N/A"}</span>
                          </span>
                          <span className="text-sm text-grey-700">
                            <span className="font-semibold">Color:</span>{" "}
                            <span>
                              {item?.exteriorColor || "N/A"}
                              <strong>(E)</strong>
                              {", "}
                            </span>
                            <span>
                              {item?.interiorColor || "N/A"}
                              <strong>(I)</strong>
                            </span>
                          </span>
                        </div>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
              {isFromCRM && (
                <Alert
                  className="mt-2 mb-2"
                  message={
                    <>
                      Starting conversation with customer now. This action will NOT create a new
                      lead or a duplicate entry in the CRM.
                    </>
                  }
                  type="info"
                  showIcon
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

function PhoneInputJsx({ lang, phone, handleSearch }) {
  return (
    <div>
      <p className="mt-1 font-normal w-full">
        {lang.phoneNumberLabel}
        <span className="requireFieldStar">*</span>
      </p>
      <div className="phoneInput">
        <PhoneInput
          defaultCountry={DEFAULT_COUNTRY}
          initialValueFormat="national"
          value={phone}
          placeholder={lang.enterPhone}
          onChange={e => {
            handleSearch(e);
            // debounceFnPhone(e);
          }}
        />
      </div>
    </div>
  );
}
