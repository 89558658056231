/* eslint-disable react/jsx-curly-brace-presence */
import { useQuery } from "@apollo/client";
import { Button, Select } from "antd";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { UserContext } from "../../../amplify/authenticator-provider";
import { GET_CAMPAIGN_LIST_FILTERS, GET_FILTER_USERS } from "../../../graphql/query";
import useLang from "../../../hooks/useLang";
import { Colors } from "../../../library/constants";
import SelectSchedule from "../../../library/selectSchedule";
import { getMobileView } from "../../../redux/slices/isMobile";
import {
  replaceQuickFilters,
  resetLeadFilters,
  setCampaignFilters,
  setSearchRedux,
  setUserFilters,
} from "../../../redux/slices/leadSlice";
import CustomLabel from "../../commonComponents/label";
import { Filter } from "./engagementHelpers";
import UserSelector from "../../commonComponents/userSelector";
import OpportunityFilter from "./opporotunityLayer/opportunityFilter";

export const TagColored = styled.div`
  display: inline-block;
  background-color: ${props => (props.color ? props.color : "#e6f0ff")};
  color: ${props => (props.color ? "#ffffff" : Colors.primaryBrandBlue)};
  border: ${props => (props.color ? "none" : `1px solid ${Colors.mediumGray}`)};
  border-radius: 3px;
  font-family: Helvetica;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 3px 6px;
  margin: 4px 1px;
  min-width: 50px;
  text-align: center;
`;

const LeadFilterBreadcrumbs = props => {
  const {
    variables,
    setVariables,
    setSearchKey,
    opportunityList,
    activeOpp,
    handleOppFilter,
    setActiveOpp,
    setShowLeadSection,
    handleStatusFilter,
    leadStatus,
    selectedStatus,
    setSelectedStatus,
    handleVisibleChange,
    filters,
    setFilters,
    campaignFilters,
    queryString,
    entityId,
    eventId,
    isFilterBreadcrumbs = false,
    isFromChatbot = false,
    activeOppName,
  } = props;

  const [lang] = useLang();
  const { user } = useContext(UserContext);
  const urlParams = new URLSearchParams(window.location.search);
  const {
    quickFilters: selectedQuickFilters,
    opportunityFilter,
    campaignFilters: campFilters,
  } = useSelector(state => state.lead.filters);
  const presetLeadFilter = urlParams.get("presetFilter");
  const isMobileView = useSelector(state => getMobileView(state));
  const dispatch = useDispatch();
  const [userList, setuserList] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const { userFilters } = useSelector(state => state.lead.filters);
  const setSearch = search => dispatch(setSearchRedux(search));

  const handleLeadVariables = () => {
    setVariables({
      search: queryString !== "undefined" && queryString !== "remove" ? queryString : "",
      source: [],
      sourceOriginal: [],
      combinedSource: [],
      voi: [],
      page: 1,
      pageSize: 25,
      orderBy: "latest_chat",
      orderDirection: "desc",
      status: selectedStatus,
      campaignIds: campaignFilters || [],
      dsEntityId: entityId,
      dsEventId: eventId,
    });
    setSearch(queryString !== "undefined" && queryString !== "remove" ? queryString : "");
  };
  useEffect(() => {
    setselectedUsers([...userFilters]);
  }, [userFilters]);

  const [campaignList, setcampaignList] = useState([]);
  const { loading: userLoader } = useQuery(GET_FILTER_USERS, {
    variables: {
      companyId: user?.company?.id,
      isAllLocationUsers: true,
    },
    fetchPolicy: "cache-first",
    onCompleted: res => {
      setuserList([...res.getUsers.data]);
    },
  });
  const { loading: campaignLoader } = useQuery(GET_CAMPAIGN_LIST_FILTERS, {
    fetchPolicy: "cache-first",
    variables: {
      activeInd: "Active",
    },
    onCompleted: res => {
      setcampaignList(res.getCampaigns.data || []);
    },
  });
  useEffect(() => {
    setVariables && setVariables({ ...variables, campaignIds: campFilters });
  }, [campFilters]);
  const handleCampaignFilterForLeads = e => {
    dispatch(setCampaignFilters(e));
    setVariables({ ...variables, page: 1, campaignIds: e });
  };
  const clearMessagesFilters = () => {
    setFilters({ ...filters, campaignIds: [] });
    handleOppFilter("#DEFAULTOPPID");
  };
  return (
    <div style={{ width: isMobileView ? 300 : 500 }}>
      <div key="filterCampagin">
        {!isFilterBreadcrumbs && <CustomLabel label={lang.quickFilters} labelClass="mt-[10px]" />}
        {!isFilterBreadcrumbs && (
          <SelectSchedule
            width="100%"
            mode="multiple"
            hascolorCode
            value={selectedQuickFilters}
            placeholder={lang.selectStatus}
            showAll={false}
            data={leadStatus}
            onChange={e => {
              setVariables &&
                setVariables({
                  ...variables,
                  page: 1,
                });
              dispatch(replaceQuickFilters(e));
            }}
          />
        )}

        <CustomLabel label={`${lang.filterByCampaign}:`} labelClass="mt-[10px]" />

        <Select
          value={
            campaignLoader
              ? []
              : isFilterBreadcrumbs
              ? filters?.campaignIds
              : variables?.campaignIds
          }
          placeholder={lang.selectCampaigns}
          className="w-full"
          loading={campaignLoader}
          mode="multiple"
          maxTagCount={3}
          onChange={e => {
            isFilterBreadcrumbs
              ? setFilters({ ...filters, campaignIds: e })
              : handleCampaignFilterForLeads(e);
          }}
        >
          {campaignList.map((campaign, index) => (
            <Select.Option
              // disabled={selectedUsers.includes("all")}
              key={index}
              value={campaign.id}
            >
              {campaign.name}
            </Select.Option>
          ))}
        </Select>
        {!isFilterBreadcrumbs && (
          <>
            <CustomLabel label={`${lang.filterByUser}:`} labelClass="mt-[10px]" />
            <UserSelector
              mode="multiple"
              placeholder="Select users"
              handleUsersSelection={data => {
                setVariables &&
                  setVariables({
                    ...variables,
                    page: 1,
                  });
                dispatch(setUserFilters(data));
              }}
              key="leadFilterUsers"
              value={userFilters}
            />
          </>
        )}

        {isFilterBreadcrumbs && (
          <>
            <CustomLabel label={`${lang.filterByOppr}:`} labelClass="mt-[10px]" />
            <OpportunityFilter
              oppValue={activeOpp}
              // disabled={!selectedLead?.id || messageLoader || loading || spinner || leadsLoading}
              handleFilter={value => {
                handleOppFilter(value);
              }}
              activeOppName={activeOppName}
              opportunityList={opportunityList}
              className="w-full"
            />
          </>
        )}

        {!isFilterBreadcrumbs && variables?.search !== "" && (
          <div className="flex gap-2 items-center mt-[10px]">
            <CustomLabel label={`${lang.searchKeywords}:`} />
            {variables?.search && (
              <Filter
                key="camap1"
                filter={{ value: variables?.search, type: "" }}
                remove={() => {
                  setVariables && setVariables({ ...variables, search: "", page: 1 });
                  setSearch("");
                  window.history.pushState("", "", `/engagements/activity-center`);
                  handleVisibleChange();
                  setSearchKey("");
                }}
              />
            )}
          </div>
        )}

        {!isFilterBreadcrumbs && presetLeadFilter && presetLeadFilter !== "undefined" && (
          <div className="flex gap-2 items-center mt-[10px]">
            <CustomLabel label={`${lang.removePresetFitler}:`} />
            {presetLeadFilter && (
              <Filter
                key="camap1"
                filter={{ value: presetLeadFilter, type: "" }}
                remove={() => {
                  handleLeadVariables();
                  window.history.pushState("", "", `/engagements/activity-center`);
                  handleVisibleChange();
                  setSearchKey("");
                }}
              />
            )}
          </div>
        )}
      </div>

      <div className="w-full mt-2 flex justify-start gap-2">
        <Button
          onClick={() => {
            setVariables &&
              setVariables({
                ...variables,
                page: 1,
              });
            isFilterBreadcrumbs ? clearMessagesFilters() : dispatch(resetLeadFilters());
          }}
          type="text"
          className="rounded-[2px]"
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default LeadFilterBreadcrumbs;
