import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { message } from "antd";

import { CREATE_COMPANY_LEAD_SOURCE } from "../../../graphql/mutation";
import TextInput from "../../../library/textInput";
import DialogFooter from "../../commonComponents/dialogFooter";
import { closeDialog } from "../../../library/helpers";
import useLang from "../../../hooks/useLang";

function AddSMAISource(props) {
  const { companyId, refetch } = props;

  const [lang] = useLang();

  const [name, setname] = useState("");

  const [createCompanyLeadSource, { loading: createSourceLoader }] = useMutation(
    CREATE_COMPANY_LEAD_SOURCE,
    {
      onCompleted: res => {
        if (res?.createCompanyLeadSource?.statusCode === 200) {
          message.success(lang.sourcesCreatedSuccessFully);
          refetch();
        }
      },
    }
  );

  console.log(name);

  const handleSave = () => {
    console.log(name);
    if (name !== "") {
      createCompanyLeadSource({
        variables: {
          companyId,
          consentOption: "CRM_CONSENT",
          name,
          parentId: null,
        },
      });
      closeDialog("createLeadSource");
    } else {
      message.error(lang.enterNameFirst);
    }
  };

  return (
    <div className="relative h-[110px]">
      <p>{lang.smaiSource}</p>
      <TextInput
        placeholder={lang.writeSmaiSourceName}
        value={name}
        onChange={e => setname(e.target.value)}
      />
      <DialogFooter
        loading={createSourceLoader}
        handleCancel={() => closeDialog("createLeadSource")}
        handleConfirm={handleSave}
        confirmBtnText={lang.createSmaiSource}
        cancelBtnText={lang.cancel}
      />
    </div>
  );
}

export default AddSMAISource;
