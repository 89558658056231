import { Pie } from "@ant-design/plots";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import PersonOutlined from "../../../icons/js/personOutlined";
import { appointmentStats, leadStats } from "../../../library/constants";
import CalendarIcon from "../../../icons/js/CalendarIcon";

const CampaignStats = ({ campaigns }) => {
  return (
    <div className="w-full items-center flex flex-row px-2">
      <div className="w-1/2">
        <LeftSideSection data={campaigns} />
      </div>
      <div className="w-1/2">
        <RightSideCampaignInformation
          leadStats={leadStats}
          campaigns={campaigns}
          appointmentStats={appointmentStats}
        />
      </div>
    </div>
  );
};

export default CampaignStats;

const LeftSideSection = ({ data }) => {
  function countItemsByActiveInd(arr) {
    // Create an object to store counts for each activeInd
    const counts = {};

    // Iterate through the array and count occurrences for each activeInd
    arr.forEach(item => {
      const { activeInd } = item;

      // If the activeInd is encountered for the first time, initialize count to 1
      if (!counts[activeInd]) {
        counts[activeInd] = 1;
      } else {
        // If the activeInd has been encountered before, increment the count
        counts[activeInd]++;
      }
    });

    // Convert the counts object into an array of objects
    const resultArray = Object.keys(counts).map(activeInd => ({
      activeInd: activeInd,
      count: counts[activeInd],
    }));

    return resultArray;
  }
  const config = {
    data: countItemsByActiveInd(data),
    angleField: "count",
    colorField: "activeInd",
    radius: 0.6,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    title: {
      text: "No. Of Campaigns",
    },
    legend: {
      position: "left", // Place legend on the left side
    },

    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "14px",
          fontWeight: "400",
        },
        content: "Total",
      },
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "16px",
          fontWeight: "600",
        },
      },
    },
  };
  return <Pie className="max-h-[200px] max-w-[300px]" {...config} />;
};
const RightSideCampaignInformation = ({ leadStats, appointmentStats, campaigns = [] }) => {
  const getTotalCountForAllKeys = array => {
    return _.reduce(
      array,
      (result, obj) => {
        _.forEach(obj.leadMessageCount, (value, key) => {
          result[key] = (result[key] || 0) + value;
        });
        return result;
      },
      {}
    );
  };
  const [campaignStats, setcampaignStats] = useState({});
  useEffect(() => {
    setcampaignStats(getTotalCountForAllKeys(campaigns));
  }, [campaigns]);
  try {
    return (
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-col gap-3">
          <span className="text-xs flex flex-row items-center gap-1 font-[600] text-grey-500">
            <PersonOutlined />
            Leads Information
          </span>
          <div className="flex flex-row gap-2 ">
            {leadStats.map((item, index) => (
              <div
                key={index}
                className="flex flex-col flex-1 py-2 px-1 items-center gap-1 rounded-sm"
                style={{ backgroundColor: item.color }}
              >
                <span className="text-xs font-[600] text-grey-700">{item.label}</span>
                <span className="text-sm font-[400] text-grey-700">
                  {campaignStats[item.key] || 0}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-xs flex flex-row items-center gap-1 font-[600] text-grey-500">
            <CalendarIcon />
            Appointment Information
          </span>
          <div className="flex flex-row justify-stretch gap-2 ">
            {appointmentStats.map((item, index) => (
              <div
                key={index}
                className="flex flex-col flex-1 py-2 px-1 items-center gap-1 rounded-sm"
                style={{ backgroundColor: item.color }}
              >
                <span className="text-xs font-[600] text-grey-700">{item.label}</span>
                <span className="text-sm font-[400] text-grey-700">
                  {campaignStats[item.key] || 0}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } catch (err) {
    return <>Error in displaying statistics</>;
  }
};
