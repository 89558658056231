import * as React from "react";

const RightIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#3B82F6"
      d="m18.567 9.557-7.5-7.5A.625.625 0 0 0 10 2.5v3.125H8.125a.625.625 0 0 0-.625.625v7.5a.625.625 0 0 0 .625.624H10V17.5a.625.625 0 0 0 1.067.442l7.5-7.5a.626.626 0 0 0 0-.885Zm-7.317 6.434v-2.242a.625.625 0 0 0-.625-.625H8.75v-6.25h1.875a.625.625 0 0 0 .625-.624V4.008L17.241 10l-5.991 5.99ZM3.75 6.25v7.5a.625.625 0 0 1-1.25 0v-7.5a.625.625 0 0 1 1.25 0Zm2.5 0v7.5a.625.625 0 0 1-1.25 0v-7.5a.625.625 0 0 1 1.25 0Z"
    />
  </svg>
);
export default RightIcon;
