export const SMAI_LOGO = "/images/logo-graphic-over-text-lg.png";
export const SMAI_BG = "/images/background.png";
export const CogIcon = "./icons/CogIcon.svg";
export const AnalyticsIcon = "./icons/AnalyticsIcon.svg";
export const ProspectsIcon = "./icons/ProspectsIcon.svg";
export const EngagementIcon = "./icons/MailIcon.svg";
export const ConversationsIcon = "./icons/ConversationsIcon.svg";
export const StarIcon = "../static/icons/StarIcon.svg";
export const ExportIcon = "./icons/ExportIcon.svg";
export const GRAPHQL_URL = process.env.GATSBY_API_URL || "http://localhost:5000/graphql";
export const V1_SOCKET_URL = process.env.GATSBY_V1_SOCKET_URL || "http://localhost:5000/graphql";
export const SadIcon = "/images/sad.png";
export const SMAI_MAIL = "support@engagedai.com";

export const DEFAULT_COUNTRY = "US";

export const COPY_RIGHTS =
  "2017-2023 All Rights Reserved. EngagedAi is a registered trademark of EngagedAi, Inc.";

export const ServiceTypes = [
  { name: "GENERAL", value: "GENERAL" },
  { name: "ACTIVITY CENTER", value: "ACTIVITY_CENTER" },
  { name: "CAMPAIGN", value: "CAMPAIGN" },
  { name: "APPOINTMENT", value: "APPOINTMENT" },
];

export const timeMappings = [
  { value: "00:00:00", name: "12:00AM" },
  { value: "01:00:00", name: "1:00AM" },
  { value: "02:00:00", name: "2:00AM" },
  { value: "03:00:00", name: "3:00AM" },
  { value: "04:00:00", name: "4:00AM" },
  { value: "05:00:00", name: "5:00AM" },
  { value: "06:00:00", name: "6:00AM" },
  { value: "07:00:00", name: "7:00AM" },
  { value: "08:00:00", name: "8:00AM" },
  { value: "09:00:00", name: "9:00AM" },
  { value: "10:00:00", name: "10:00AM" },
  { value: "11:00:00", name: "11:00AM" },
  { value: "12:00:00", name: "12:00PM" },
  { value: "13:00:00", name: "1:00PM" },
  { value: "14:00:00", name: "2:00PM" },
  { value: "15:00:00", name: "3:00PM" },
  { value: "16:00:00", name: "4:00PM" },
  { value: "17:00:00", name: "5:00PM" },
  { value: "18:00:00", name: "6:00PM" },
  { value: "19:00:00", name: "7:00PM" },
  { value: "20:00:00", name: "8:00PM" },
  { value: "21:00:00", name: "9:00PM" },
  { value: "22:00:00", name: "10:00PM" },
  { value: "23:00:00", name: "11:00PM" },
];

export const colorCode = {
  RESPONDED: "#A855F7",
  WAITING_FOR_REPLY: "#0EA5E9",
  UNCONTACTED: "#F59E0B",
};

export const titleCode = {
  RESPONDED: "Responded",
  WAITING_FOR_REPLY: "Waiting for Reply",
  UNCONTACTED: "Uncontacted",
};

export const BASE_URL_FOR_STATIC_ICONS_AND_IMAGES =
  "https://smai-public.s3.amazonaws.com/dev/assets";

// all the icons url
export const AddIcon = "/icons/AddIcon.svg";
export const AddItemIcon1 = "/icons/AddItemIcon1.svg";
export const AddNoteIcon = "/icons/AddNoteIcon.svg";
export const AddResponseIcon = "/icons/AddResponseIcon.svg";
export const AddSignatureIcon = "/icons/AddSignatureIcon.svg";
export const AirplaneIcon = "/icons/AirplaneIcon.svg";
export const AirplaneIconWhite = "/icons/AirplaneIconWhite.svg";
export const Analytics_Icon = "/icons/AnalyticsIcon.svg";
export const Avatar = "/icons/Avatar.svg";
export const BellIcon = "/icons/BellIcon.svg";
export const BellIconBlue = "/icons/BellIconBlue.svg";
export const BillingIcon = "/icons/BillingIcon.svg";
export const BotIcon = "/icons/BotIcon.svg";
export const CalendarIcon = "/icons/CalendarIcon.svg";
export const ChatIcon = "/icons/ChatIcon.svg";
export const ChatIcon2 = "/icons/ChatIcon2.svg";
export const ChatIconWhite = "/icons/ChatIconWhite.svg";
export const CheckIcon = "/icons/CheckIcon.svg";
export const CheckIconBlue = "/icons/CheckIconBlue.svg";
export const CloseIcon = "/icons/CloseIcon.svg";
export const CloseIconBlue = "/icons/CloseIconBlue.svg";
export const Cog_Icon = "/icons/CogIcon.svg";
export const ColdIcon = "/icons/ColdIcon.svg";
export const CompanyIcon = "/icons/CompanyIcon.svg";
export const ContactIcon = "/icons/ContactIcon.svg";
export const Conversations_Icon = "/icons/ConversationsIcon.svg";
export const CraiglistIcon = "/icons/CraiglistIcon.svg";
export const CreditIcon = "/icons/CreditIcon.svg";
export const CRMIcon = "/icons/CRMIcon.svg";
export const CSVIcon = "/icons/CSVIcon.svg";
export const DealerraterIcon = "/icons/DealerraterIcon.svg";
export const DownloadContractTemplate = "/icons/DownloadContractTemplate.svg";
export const EditIcon = "/icons/EditIcon.svg";
export const EditIcon2 = "/icons/EditIcon2.svg";
export const ExploreIcon = "/icons/ExploreIcon.svg";
export const Export_Icon = "/icons/ExportIcon.svg";
export const FacebookIcon = "/icons/FacebookIcon.svg";
export const FacebookIconCircleFilledBlue = "/icons/FacebookIconCircleFilledBlue.svg";
export const FacebooksIcon = "/icons/FacebooksIcon.svg";
export const FilterIcon1 = "/icons/FilterIcon1.svg";
export const FilterIcon2 = "/icons/FilterIcon2.svg";
export const GoogleIcon = "/icons/GoogleIcon.svg";
export const GraphColor = "/icons/GraphColor.svg";
export const GraphIcon = "/icons/GraphIcon.svg";
export const HandshakeIcon = "/icons/HandshakeIcon.svg";
export const HotIcon = "/icons/HotIcon.svg";
export const InstagramIcon = "/icons/InstagramIcon.svg";
export const LifeEventsIcn = "/icons/LifeEventsIcn.svg";
export const LinkedinIcon = "/icons/LinkedinIcon.svg";
export const LinkIcon = "/icons/LinkIcon.svg";
export const LinkIcon2 = "/icons/LinkIcon2.svg";
export const LocationIcon = "/icons/LocationIcon.svg";
export const LocationIcon2 = "/icons/LocationIcon2.svg";
export const MailIcon = "/icons/MailIcon.svg";
export const ManageTeamIcon = "/icons/ManageTeamIcon.svg";
export const PhoneIcon = "/icons/PhoneIcon.svg";
export const PostIcon = "/icons/PostIcon.svg";
export const Prospects_Icon = "/icons/ProspectsIcon.svg";
export const RedditIcon = "/icons/RedditIcon.svg";
export const RemoteCallIcon = "/icons/RemoteCallIcon.svg";
export const RemoveActive = "/icons/RemoveActive.svg";
export const RemoveIcon = "/icons/RemoveIcon.svg";
export const ResponseIcon = "/icons/ResponseIcon.svg";
export const ResponseIconWhite = "/icons/ResponseIconWhite.svg";
export const ScreenNameIcon = "/icons/ScreenNameIcon.svg";
export const SignatureIcon = "/icons/SignatureIcon.svg";
export const SocialMediaIcon = "/icons/SocialMediaIcon.svg";
export const Star_Icon = "/icons/StarIcon.svg";
export const StarIconActive = "/icons/StarIconActive.svg";
export const StarIconWhite = "/icons/StarIconWhite.svg";
export const TwitterIcon = "/icons/TwitterIcon.svg";
export const UploadIcon = "/icons/UploadIcon.svg";
export const UserRolesIcon = "/icons/UserRolesIcon.svg";
export const WarmIcon = "/icons/WarmIcon.svg";
export const WarmIcon2 = "/icons/WarmIcon2.svg";
export const XLSIcon = "/icons/XLSIcon.svg";
export const XMLIcon = "/icons/XMLIcon.svg";
export const YelpIcon = "/icons/YelpIcon.svg";

// All the smai logos url
export const logo = "/logos/logo.png";
export const funnelai_logo = "/logos/funnelai_logo.png";
export const Logo2_PNG = "/logos/Logo2.png";
export const Logo2_SVG = "/logos/Logo2.svg";
export const tribu_logo_login_area = "/logos/tribu_logo-login-area.png";
export const Logo = "/logos/Logo.svg";

// All the images url
export const autotrader = "/images/sources/autotrader.png";
export const calendar = "/images/sources/calendar.png";
export const cars_com = "/images/sources/cars-com.png";
export const craigslist = "/images/sources/craigslist.png";
export const facebook = "/images/sources/facebook.png";
export const forum = "/images/sources/forum.png";
export const fresh_up = "/images/sources/fresh up.png";
export const instagram = "/images/sources/instagram.png";
export const linkedin = "/images/sources/linkedin.png";
export const marketplace = "/images/sources/marketplace.png";
export const phone_up = "/images/sources/phone up.png";
export const quora = "/images/sources/quora.png";
export const reddit = "/images/sources/reddit.png";
export const twitter = "/images/sources/twitter.png";
export const unknown = "/images/sources/unknown.png";
export const privacy_policy = "/images/privacy-policy.png";
export const background = "/images/background.png";
export const avatar1 = "/images/avatar1.png";
