import * as React from "react";

const GIFIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M11.25 5.625v8.75a.624.624 0 1 1-1.25 0v-8.75a.625.625 0 1 1 1.25 0ZM17.5 5h-3.75a.625.625 0 0 0-.625.625v8.75a.624.624 0 1 0 1.25 0v-3.75h2.5a.624.624 0 1 0 0-1.25h-2.5V6.25H17.5a.625.625 0 1 0 0-1.25Zm-10 4.375H5.625a.625.625 0 0 0 0 1.25h1.25v1.25a1.875 1.875 0 1 1-3.75 0v-3.75A1.875 1.875 0 0 1 5 6.25c.874 0 1.688.605 1.895 1.406a.625.625 0 0 0 1.21-.312C7.755 5.986 6.45 5 5 5a3.125 3.125 0 0 0-3.125 3.125v3.75a3.125 3.125 0 1 0 6.25 0V10a.625.625 0 0 0-.625-.625Z"
    />
  </svg>
);
export default GIFIcon;
