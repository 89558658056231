import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Tooltip } from "antd";
import React from "react";

function CommonHoliday({
  misc,
  setmisc,
  text = "Holiday List",
  MT = true,
  title = `Holiday Calender (${dayjs().year()})`,
  tooltipTitle = "Holidays Calender",
  width = "50%",
  item = {},
}) {
  return (
    <Tooltip title={tooltipTitle}>
      <div
        onClick={() =>
          setmisc({
            ...misc,
            drawerVisible: true,
            width: width,
            title: title,
            item,
          })
        }
        className={`${MT ? "m-4" : ""} font-bold text-blue-500 cursor-pointer ml-[5px]`}
      >
        <CalendarOutlined className="mt-[5px] mr-1 cursor-pointer" />
        {text}
      </div>
    </Tooltip>
  );
}

export default CommonHoliday;
