import { createSlice } from "@reduxjs/toolkit";

// TODO: Make this dynamic in future, and add ability to set expiry for individual items
const EXPIRY_TIME = 60 * 60 * 1000;
const initialValue = {
  leadSources: [],
  permittedLeadIds: [],
  afterMessageText: {},
  consentTemplate: {},
  crmLeadStatus: {
    crmLeadStatus: [],
    crmIntegration: "",
    crmIntegrationData: [],
  },
  userList: {
    companyId: "",
    users: [],
  },
  companyConsentTemplateEvent: {
    companyId: "",
    consents: [],
  },
  allCampaigns: [],
  expiryTime: EXPIRY_TIME,
  activeLeadFilter: {},
  selectedLeadPhone: "",
};
const initialState = initialValue;

export const apiCacheSlice = createSlice({
  name: "apiCache",
  initialState,
  reducers: {
    setLeadSources: (state, action) => {
      state.leadSources = action.payload.leadSources;
    },
    setPermittedLeadIds: (state, action) => {
      state.permittedLeadIds = action.payload.permittedLeadIds;
    },
    setAfterMessageText: (state, action) => {
      state.afterMessageText = action.payload.afterMessageText;
    },
    setConsentTemplateSlice: (state, action) => {
      state.consentTemplate = action.payload.consentTemplate;
    },
    setCrmLeadStatus: (state, action) => {
      state.crmLeadStatus.crmLeadStatus = action.payload.crmLeadStatus;
      state.crmLeadStatus.crmIntegration = action.payload.crmIntegration;
      state.crmLeadStatus.crmIntegrationData = action.payload.crmIntegrationData;
    },
    setUserList: (state, action) => {
      state.userList.users = action.payload.usersList;
      state.userList.companyId = action.payload.companyId;
    },
    setCompanyConsentTemplate: (state, action) => {
      state.companyConsentTemplateEvent.consents = action.payload.consents;
      state.companyConsentTemplateEvent.companyId = action.payload.companyId;
    },
    setAllCampaigns: (state, action) => {
      state.allCampaigns = action.payload.campaigns;
    },
    setCrmType: (state, action) => {
      state.crmLeadStatus.crmIntegration = action.payload;
    },
    pushActiveLeadFilter: (state, action) => {
      state.activeLeadFilter = action.payload.activeLeadFilter;
    },
    setSelectedLeadPhone: (state, action) => {
      state.selectedLeadPhone = action.payload.selectedLeadPhone;
    },
    setCRMIntegration: (state, action) => {
      state.crmLeadStatus.crmIntegration = action.payload;
    },
    resetApiCacheStore: () => {
      localStorage.removeItem("persist:root");
      return {
        ...initialValue,
      };
    },
  },
});

export const {
  setLeadSources,
  setPermittedLeadIds,
  setAfterMessageText,
  setConsentTemplateSlice,
  setCrmLeadStatus,
  setUserList,
  resetApiCacheStore,
  setCompanyConsentTemplate,
  setAllCampaigns,
  setCrmType,
  pushActiveLeadFilter,
  setSelectedLeadPhone,
  setCRMIntegration,
} = apiCacheSlice.actions;

export const getCacheApi = (state, key) => state.apiCache.apiCache[key];

export default apiCacheSlice.reducer;
