/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unreachable */
import { useMutation, useQuery } from "@apollo/client";
import { Select, Spin, message } from "antd";
import { sortBy, uniqBy } from "lodash";
import React, { useState } from "react";
import { CREATE_REVIEW_LEAD } from "../../graphql/mutation";
import { GET_LEAD_NAMES } from "../../graphql/query";
import CheckIconJSX from "./CheckIcon";
import CloseIconJsx from "./closeIcon";

const LeadLinker = ({
  data,
  setReviewList,
  index,
  settaggedLead,
  taggedLead,
  setleadTagVisible,
}) => {
  const [leads, setleads] = useState([]);
  const [search, setsearch] = useState("");
  const [selectedLead, setselectedLead] = useState();
  const [createReviewLead, { loading: tagLoader }] = useMutation(CREATE_REVIEW_LEAD, {
    onError: err => {
      message.error(err.message);
    },
  });
  const fetchLead = leads => {
    const index = leads.findIndex(item => +item.id === +taggedLead?.id);
    if (index === -1) {
      setleads(uniqBy([...leads, taggedLead], "id"));
    }
  };
  const { data: respLeads, loading: leadLoader } = useQuery(GET_LEAD_NAMES, {
    fetchPolicy: "cache-first",
    variables: {
      search: search,
      page: 1,
      pageSize: 20,
    },
    onCompleted: res => {
      setleads([...res.getLeads.data]);
      setselectedLead(+taggedLead?.id);
      fetchLead(res.getLeads.data);
    },
    onError: err => {
      message.error(err.message);
    },
  });
  const handleSave = async () => {
    const res = await createReviewLead({
      variables: {
        leadId: selectedLead,
        socialReviewId: data.id,
      },
    });
    if (res.data.createReviewLead.statusCode === 200) {
      message.success(res.data.createReviewLead.message);
      const lead = leads.find(lead => +lead.id === +selectedLead);
      setReviewList(prev => {
        const updatedDat = [...prev.dat]; // Create a new array
        const obj = { ...updatedDat[index], leadId: selectedLead }; // Update the object
        updatedDat[index] = obj; // Assign the updated object to the new array
        return { ...prev, dat: updatedDat }; // Return the updated state
      });
      if (lead) {
        settaggedLead({ ...lead });
      }
      setleadTagVisible(false);
    }
  };
  function getAssociatedId(obj) {
    if (!obj) return "";
    // Check if leadOpportunities array is present and not empty
    if (obj && obj.crmLeadObj && obj.crmLeadObj.activeLeadOpportunity) {
      return `(DS Customer ID ${obj.crmLeadObj.activeLeadOpportunity.id})`;
    }

    // Return associated id if no dsEventId is found
    return `(EAI Lead ID ${obj?.id})`;
  }
  return (
    <div className="flex flex-col items-start">
      <div id="title" className="flex w-full mb-2 items-center flex-row justify-between">
        <span className="text-xs font-[600] text-grey-700">Tag Lead </span>
        <div className="flex flex-row items-center gap-2">
          <CloseIconJsx className="cursor-pointer" onClick={() => setleadTagVisible(false)} />
          {tagLoader ? (
            <Spin size="small" />
          ) : (
            <CheckIconJSX onClick={() => handleSave()} className="cursor-pointer" />
          )}
        </div>
      </div>
      <div className="justify-between min-w-[400px]">
        <Select
          showSearch
          placeholder="Select a lead to associate with this review"
          onSearch={value => setsearch(value)}
          className="w-full"
          loading={leadLoader}
          value={selectedLead}
          allowClear
          onClear={() => {
            setsearch("");
            setselectedLead(null);
          }}
          onSelect={value => setselectedLead(value)}
          filterOption={(inputValue, option) =>
            option?.props?.children?.toString().toLowerCase().includes(inputValue.toLowerCase())
          }
        >
          {sortBy(leads, "fullName").map((lead, index) => (
            <Select.Option value={+lead?.id} key={index}>
              {lead?.fullName} {getAssociatedId(lead)}
            </Select.Option>
          ))}
        </Select>
        {/* <div className="ml-2 text-right mt-2">
        <Button loading={tagLoader} onClick={handleSave} className="mr-2" type="primary">
          {data.leadId ? "Re-Associate" : "Associate"}
        </Button>
        <Button onClick={() => closeDialog("openLeadLinker")}>Cancel</Button>
      </div> */}
      </div>
    </div>
  );
};

export default LeadLinker;
