import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Card, Col, message, Row, Select, Table } from "antd";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { GET_VEHICLE_INVENTORY } from "../../../graphql/query";
import { colorConfig } from "../../../library/colorConfig";
import { Container, ContainerNavigation } from "../../../library/basicComponents";
import Typography from "../../../library/typography";
import AntdIconV4 from "../../../utils/antdIcon";

const { Option } = Select;

const ContainerNavigationWrap = styled(ContainerNavigation)`
  border-bottom: 0;
  padding: 0;
  .ant-card-body {
    padding: 12px;
  }
`;

const FormCntr = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 5px;
  align-items: center;
`;
const LabelWrap = styled.label`
  margin-bottom: 5px;
`;

const InlineFormButton = styled(Button)`
  margin: 12px 0 0 16px;
`;
const ContainerWrap = styled(Container)`
  margin-top: 10px;
  .ant-modal-body {
    padding-top: 10px;
  }
  border: 0;
`;

const VehicleIntentory = ({
  vehicles,
  saveVehicle,
  removeVehicle,
  loading = false,
  vehicleCount = 0,
}) => {
  const [voiVariables, setVoiVariables] = useState({
    search: "",
    page: 1,
    pageSize: 50,
  });
  const [vehicleInventory, setVehicleInventory] = useState(null);
  const { loading: makeLoading } = useQuery(GET_VEHICLE_INVENTORY, {
    variables: { ...voiVariables },
    onCompleted: resp => {
      setVehicleInventory(resp?.getDsVehicleInventory?.data || []);
    },
  });
  const [selectedVehicle, setSelectedVehicle] = useState("");

  const resetForm = () => {
    setSelectedVehicle("");
  };

  const submitVehicle = () => {
    if (isEmpty(selectedVehicle)) {
      message.error("Please enter vehicle");
      return;
    }
    const vehicle = (vehicleInventory || []).find(el => el.id === selectedVehicle);
    if (vehicle) {
      saveVehicle({ ...vehicle });
      resetForm();
    }
  };

  return (
    <ContainerWrap auto>
      <Row gutter={[4, 16]}>
        <div
          style={{
            display: "flex",
            flexDirection: `${window.innerWidth > 768 ? "row" : "column"}`,
            width: "100%",
          }}
        >
          <Col>
            <LabelWrap>Select Vehicle of Inventory</LabelWrap>
            <Select
              key="keyName"
              size="medium"
              placeholder="Select vehicle"
              style={{ width: "100%" }}
              onChange={e => {
                setSelectedVehicle(e);
              }}
              value={selectedVehicle}
              showSearch
              // onSearch={handleSearch}
              filterOption={false}
              loading={makeLoading}
            >
              {(vehicleInventory || []).map(el => {
                return (
                  <Option key={el.id} value={el.id}>
                    {`${el.vehicleOfInterest.make} ${el.vehicleOfInterest.model} ${el.vehicleOfInterest.year}`}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col
            style={{
              marginLeft: `${window.innerWidth > 768 ? "auto" : 0}`,
              width: `${window.innerWidth > 768 ? "" : "100%"}`,
            }}
          >
            <FormCntr>
              <InlineFormButton
                style={{
                  marginLeft: `${window.innerWidth > 768 ? "auto" : 0}`,
                  width: `${window.innerWidth > 768 ? "-10%" : "100%"}`,
                }}
                type="primary"
                onClick={submitVehicle}
                loading={loading}
                disabled={vehicleCount >= 3}
              >
                Add To List
              </InlineFormButton>
            </FormCntr>
          </Col>
        </div>
      </Row>

      <VehicleTable vehiclesList={vehicles} onDeleteVehicle={removeVehicle} />
    </ContainerWrap>
  );
};
export default VehicleIntentory;

const CardWrapper = styled(Card)`
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 15%) !important;
  & .ant-card-head-title {
    color: ${colorConfig.secondaryDark} !important;
  }
  .ant-card-actions {
    background-color: #fff;
  }
  width: 100%;
  /*@media (max-width: 992px) {
    width: 240px !important;
  }*/
`;

const AncherLink = styled.a`
  margin: 0 15px;
`;
const TextWrap = styled.div`
  wordwrap: break-word;
  wordbreak: break-word;
  width: 100%;
  @media (max-width: 992px) {
    width: 50px !important;
  }
`;

const VehicleTable = ({ vehiclesList, onDeleteVehicle }) => {
  const { Column } = Table;
  let columns = [
    <Column
      title="Vehicle Year"
      dataIndex="year"
      key="year"
      render={(text, record) => {
        return <span>{record?.year || record?.vehicleOfInterest?.year}</span>;
      }}
    />,
    <Column
      title="Vehicle Make"
      dataIndex="make"
      key="make"
      defaultSortOrder="ascend"
      render={(text, record) => {
        return <span>{record?.make || record?.vehicleOfInterest?.make}</span>;
      }}
    />,
    <Column
      title="Vehicle Model"
      dataIndex="model"
      key="model"
      render={(text, record) => {
        return <span>{record?.model || record?.vehicleOfInterest?.model}</span>;
      }}
    />,
    <Column title="Stock Number" dataIndex="stockNumber" key="stockNumber" />,
    <Column title="VIN" dataIndex="vin" key="vin" />,
    <Column
      title="Delete"
      width={85}
      key="delbutton"
      render={({ id }) => (
        <AntdIconV4
          type="DeleteOutlined"
          onClick={e => {
            e.stopPropagation();
            onDeleteVehicle(id);
          }}
        />
      )}
    />,
  ];
  if (window.innerWidth <= 1024) {
    columns = [
      <Column
        width="100%"
        key="edit"
        render={(text, record, index) => {
          let voi = "";
          voi = `${voi + record?.year} `;
          voi = `${voi + record?.make} `;
          voi = `${voi + record?.model} `;

          return (
            <CardWrapper
              title={
                <ContainerNavigationWrap>
                  <TextWrap>{voi}</TextWrap>
                </ContainerNavigationWrap>
              }
              actions={[
                <AncherLink key={index}>
                  <AntdIconV4
                    type="DeleteOutlined"
                    onClick={e => {
                      e.stopPropagation();
                      onDeleteVehicle(record?.id);
                    }}
                  />
                </AncherLink>,
              ]}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <Typography variant="h4" weight="medium" inline>
                    Stock Number:{" "}
                  </Typography>
                  {record?.stock_number || ""}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <Typography variant="h4" weight="medium" inline>
                    VIN:{" "}
                  </Typography>
                  {record?.vin || ""}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                >
                  <Typography variant="h4" weight="medium" inline>
                    Is New?:{" "}
                  </Typography>
                  {record?.is_new || ""}
                </Col>
              </Row>
            </CardWrapper>
          );
        }}
      />,
    ];
  }

  return (
    <Table
      dataSource={vehiclesList}
      rowKey="id"
      pagination={false}
      // showHeader={false}
    >
      {columns}
    </Table>
  );
};
