import { useMutation, useQuery } from "@apollo/client";
import { DatePicker, Form, Input, message } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { UserContext } from "../../../amplify/authenticator-provider";
import { CREATE_SCHEDULED_MESSAGE, UPDATE_SCHEDULED_MESSAGE } from "../../../graphql/mutation";
import { timezones } from "../../../library/constants";
import { closeDialog } from "../../../library/helpers";
import { addScheduledMessage, updateScheduledMessageRedux } from "../../../redux/slices/leadSlice";
import DialogFooter from "../../commonComponents/dialogFooter";
import { GET_COMPANY_WORK_HOURS } from "../../../graphql/query";

const ScheduleMessagesPopup = props => {
  const {
    scheduledMessages,
    getMessages,
    messageData: editMessageData,
    selectedLead,
    mode = "EDIT",
    setmessageText,
    reset = () => {},
  } = props;
  const { user } = useContext(UserContext);
  const getLocation = () => {
    return user.locations.find(
      item => item.location.id === sessionStorage.getItem("currentLocationId")
    );
  };
  const locationObj = useMemo(getLocation, []);
  const timeZone = sessionStorage.getItem("currentLocationId").includes(",")
    ? selectedLead?.location?.timezone
    : locationObj.location.timezone;
  const dispatch = useDispatch();
  const initialState = {
    message: editMessageData?.message,
    date:
      mode === "EDIT" ? dayjs(editMessageData?.taskSendDatetime).tz(timeZone).toISOString() : null,
    timezone: timeZone,
  };
  const [workingHours, setworkingHours] = useState([]);
  const [createScheduledMessage, { loading }] = useMutation(CREATE_SCHEDULED_MESSAGE);
  const [updateScheduledMessage, { updateLoading }] = useMutation(UPDATE_SCHEDULED_MESSAGE);
  useQuery(GET_COMPANY_WORK_HOURS, {
    variables: {
      companyId: user.company.id,
      locationId: sessionStorage.getItem("currentLocationId").includes(",")
        ? selectedLead?.location?.id
        : sessionStorage.getItem("currentLocationId"),
    },
    onCompleted: res => {
      setworkingHours(res.getCompanyWorkingHours);
    },
  });
  const [messageData, setmessageData] = useState(initialState);

  // useEffect(() => {
  //   const obj = timezones.find(item => item.value === dayjs.tz.guess());
  //   console.log(obj);
  //   if (obj) setmessageData({ ...messageData, timezone: obj.label });
  //   else {
  //     setmessageData({ ...messageData, timezone: dayjs.tz.guess() });
  //   }
  // }, []);
  const getTimezoneLabel = () => {
    const obj = timezones.find(item => item.value === messageData.timezone);
    if (obj) {
      return obj.label;
    }
    return messageData.timezone;
  };
  const timeZoneLabel = useMemo(() => getTimezoneLabel(), messageData.timezone);
  useEffect(() => {
    if (mode === "EDIT") {
      setmessageData({
        ...editMessageData,
        date: dayjs(editMessageData?.taskSendDatetime, "YYYY-MM-DDTHH:mm:ss")
          .utc(true)
          .toISOString(),
      });
    } else {
      setmessageData({ ...initialState });
    }
  }, [editMessageData]);
  const handleSave = async () => {
    try {
      if (messageData.message === "") {
        return message.error("Please enter the message you would like to send in the future");
      }
      if (mode === "EDIT") {
        const variables = {
          id: editMessageData?.id,
          scheduledDate: dayjs.tz(messageData?.date, timeZone).format("YYYY-MM-DDTHH:mm:ss"),
          timezone: timeZone,
          message: messageData?.message,
        };
        const resp = await updateScheduledMessage({
          variables,
        });
        if (resp?.data?.updateScheduledMessage?.statusCode === 200) {
          console.log(resp?.data?.updateScheduledMessage?.message);
          message.success("Scheduled message updated successfully");
          const index = scheduledMessages.findIndex(item => item?.id === editMessageData?.id);
          if (index > -1) {
            dispatch(
              updateScheduledMessageRedux({
                index,
                message: resp?.data?.updateScheduledMessage?.scheduledMessage,
              })
            );
          }
          getMessages();
          closeDialog("scheduleMessage");
        } else {
          console.error(resp?.data?.updateScheduledMessage?.message);
          message.error("Message failed to schedule. Please try again or contact support.");
        }
      } else {
        const variables = {
          leadId: selectedLead?.id,
          userId: user?.id,
          scheduledDate: dayjs
            .tz(messageData?.date, messageData?.timezone || dayjs.tz.guess())
            .format("YYYY-MM-DDTHH:mm:ss"),
          timezone: timeZone,
          message: messageData?.message,
        };
        const resp = await createScheduledMessage({
          variables,
        });

        if (resp?.data?.createScheduledMessage?.statusCode === 200) {
          setmessageText("");
          reset();
          message.success(resp?.data?.createScheduledMessage?.message);
          dispatch(addScheduledMessage(resp.data.createScheduledMessage.scheduledMessage));
          closeDialog("scheduleMessage");
          getMessages();
        } else {
          message.error("Message failed to schedule. Please try again or contact support.");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [form] = Form.useForm();
  useEffect(() => {
    if (mode === "EDIT")
      form.setFieldsValue({ message: messageData?.message || "", date: dayjs(messageData.date) });
    else form.setFieldsValue({ message: messageData?.message || "" });
  }, [mode, messageData]);
  const isWorkingDay = date => {
    const today = dayjs();
    const selectedDate = dayjs(date);

    // Check if the selected date is in the past
    if (selectedDate.isBefore(today, "day")) {
      return false;
    }

    const dayOfWeek = selectedDate.format("dddd").toUpperCase();
    const workingDay = workingHours.find(hours => hours.weekDay === dayOfWeek);

    return workingDay ? workingDay.isWorkingDay : false;
  };

  const disabledDate = current => !isWorkingDay(current);
  const disabledTime = current => {
    const dayOfWeek = dayjs(current).format("dddd").toUpperCase();
    const workingDay = workingHours.find(hours => hours.weekDay === dayOfWeek);

    if (workingDay && workingDay.isWorkingDay) {
      const startTime = dayjs(workingDay.startTime, "HH:mm:ss");
      const endTime = dayjs(workingDay.endTime, "HH:mm:ss");
      return {
        disabledHours: () =>
          [...Array(24).keys()].filter(h => h < startTime.hour() || h >= endTime.hour()),
        disabledMinutes: () => [],
      };
    }

    return {};
  };

  return (
    <div>
      <Form form={form} onFinish={() => handleSave()} layout="vertical">
        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: "Please enter the message you want to schedule" }]}
        >
          <Input.TextArea
            name="message"
            placeholder="Enter the message you want to schedule"
            value={messageData?.message}
            defaultValue={messageData?.message}
            onChange={e => setmessageData({ ...messageData, message: e.target.value })}
          />
        </Form.Item>
        <p className="mt-2 mb-1">Date and time you want the message to be delivered</p>
        <Form.Item
          label="Date"
          rules={[{ required: true, message: "Please select date" }]}
          name="date"
        >
          <DatePicker
            name="date"
            showTime={{
              format: "hh:mm A",
            }}
            // value={messageData?.date ? dayjs(messageData?.date) : null}
            onChange={value => {
              if (value)
                setmessageData({ ...messageData, date: value.toISOString(), rawDate: value });
            }}
            format="MM/DD/YYYY hh:mm A"
            disabledDate={disabledDate}
            disabledTime={disabledTime}
          />
        </Form.Item>
        <p className="mt-1">
          Current timezone : <b>{timeZoneLabel}</b>
        </p>
      </Form>
      <DialogFooter
        loading={loading || updateLoading}
        handleConfirm={() => form.submit()}
        handleCancel={() => closeDialog("scheduleMessage")}
      />
    </div>
  );
};

export default ScheduleMessagesPopup;
