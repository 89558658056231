import React from "react";
import { closeDialog } from "../../library/helpers";
import DialogFooter from "../commonComponents/dialogFooter";
import useLang from "../../hooks/useLang";

const PhoneCofirmation = ({ handleConfirm, onClose }) => {
  const [lang] = useLang();
  // const confirmLoader = useSelector(state => getLoaderStatus(state));

  const handleConfirmBtn = () => {
    handleConfirm();
    onClose();
  };

  return (
    <div>
      <p className="text-red-500 py-2">{lang.userProfilePhoneError}</p>
      <DialogFooter
        confirmBtnText={lang.yesSave}
        cancelBtnText={lang.noBack}
        handleConfirm={() => handleConfirmBtn()}
        // loading={confirmLoader}
        handleCancel={() => closeDialog("phoneCofirmation")}
      />
    </div>
  );
};

export default PhoneCofirmation;
