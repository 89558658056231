import React from "react";

const CustomLabel = props => {
  const { label, labelClass, onClick = () => {} } = props;
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <p className={labelClass} onClick={onClick}>
      {label}
    </p>
  );
};

export default CustomLabel;
