import Icon, { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Badge, Button, Checkbox, List, Select, Switch, message } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_NOTIFICATIONS } from "../../graphql/mutation";
import { colorConfig } from "../../library/colorConfig";
import { parseTimestamp } from "../../library/utils";
import { setnotificationRead, setnotificationType } from "../../redux/slices/userSlice";
import { notifTypeMap, notificationFilters } from "../layoutComponent/layoutHelpers";

const MobileNotifications = props => {
  const {
    notifications,
    setShowNotifModal,
    setSelectedNotif,
    getMoreNotifications,
    hasMore,
    markeNotifAsRead,
    locationIdslice,
    handleDispatch,
    handleNotifMenuClick,
    setSelectedLeadPhoneToRedux,
    refetchNotifications,
  } = props;
  const dispatch = useDispatch();
  const [checkedNotifications, setcheckedNotifications] = useState([]);
  const { type: notificationFilter, isReadOnly: showReadNotifications } = useSelector(
    state => state.user.notificationFilters
  );
  const [updateNotification, { loading }] = useMutation(UPDATE_NOTIFICATIONS, {
    onCompleted: res => {
      console.log(res);
      if (res.updateNotification.statusCode === 200) {
        message.success(res.updateNotification.message);
        refetchNotifications();
      } else {
        message.error(res.updateNotification.message);
      }
    },
  });
  const allChecked =
    checkedNotifications.length === notifications?.getNotifications?.map(item => item?.id).length;
  return (
    <div>
      <div className="text-[#c4c1c2] flex items-center flex-wrap gap-2 text-[12px] p-[4px]">
        <Select
          placeholder="Select the notification filters"
          className="w-[300px]"
          maxTagCount="responsive"
          mode="multiple"
          value={notificationFilter}
          onChange={value => dispatch(setnotificationType(value))}
        >
          {notificationFilters.map((item, index) => (
            <Select.Option value={item.value} key={index}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      {/* <Checkbox className="flex ml-8">Show only unread Notifications</Checkbox> */}
      <div className="flex items-center ml-1 my-1">
        Show :{" "}
        <Switch
          checked={showReadNotifications}
          onChange={(value, e) => dispatch(setnotificationRead(value))}
          className="ml-1"
          checkedChildren="Read only notifications"
          unCheckedChildren="Unread only notifications"
        />
      </div>
      <div className="flex items-center justify-between">
        <Checkbox
          className="flex ml-1"
          indeterminate={
            checkedNotifications.length === 0
              ? false
              : checkedNotifications.length <
                notifications?.getNotifications?.map(item => item?.id)?.length
          }
          checked={allChecked}
          onClick={e => {
            e.stopPropagation();
            allChecked
              ? setcheckedNotifications([])
              : setcheckedNotifications([
                  ...notifications?.getNotifications?.map(item => item?.id),
                ]);
          }}
        >
          Select all
        </Checkbox>
        {checkedNotifications.length > 0 && (
          <div className="flex items-center">
            <Button
              type="link"
              loading={loading}
              onClick={() =>
                updateNotification({
                  variables: {
                    notificationInputs: checkedNotifications.map(item => ({
                      id: item,
                      read: true,
                    })),
                  },
                })
              }
              className="flex items-center"
            >
              <EyeOutlined className="mr-1" />
            </Button>
            <Button type="link" className="flex items-center text-red-500">
              <DeleteOutlined className="mr-1" />
              {/* Delete */}
            </Button>
          </div>
        )}
      </div>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={() => getMoreNotifications()}
        hasMore={hasMore}
        useWindow={false}
      >
        <List
          dataSource={notifications && notifications.getNotifications}
          renderItem={n => (
            <List.Item
              className="cursor-pointer px-1 py-2 hover:rounded-[5px] hover:notificationCellBgColor"
              key={n.id}
              onClick={() =>
                handleNotifMenuClick(
                  n,
                  setShowNotifModal,
                  setSelectedNotif,
                  markeNotifAsRead,
                  locationIdslice,
                  handleDispatch,
                  setSelectedLeadPhoneToRedux
                )
              }
            >
              <div className="flex items-start py-2 gap-3">
                <Checkbox
                  checked={checkedNotifications.includes(n.id)}
                  onClick={e => {
                    e.stopPropagation();
                    checkedNotifications.includes(n.id)
                      ? setcheckedNotifications(prev => prev.filter(id => id !== n.id))
                      : setcheckedNotifications(prev => [...prev, n.id]);
                  }}
                />
                <Badge count={n.read ? 0 : 1} dot>
                  <Icon
                    component={notifTypeMap[n.notificationType]}
                    alt="Notifications"
                    className="text-[24px] bg-yellow-50 rounded-full p-3 align-middle leading-[0]"
                  />
                </Badge>
                <div>
                  <div className={`text-${colorConfig.notificationTypeColor} text-[12px]`}>
                    {n.notificationType}
                  </div>
                  <div
                    className={`text-${colorConfig.notificationTextColor} font-medium text-[12px]`}
                  >
                    {n.text}
                  </div>
                  <div className={`text-${colorConfig.notificationDateColor} text-[10px]`}>
                    {parseTimestamp(dayjs.utc(n.date))}
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default MobileNotifications;
