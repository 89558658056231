import React from "react";
import { Button } from "antd";
import { Auth } from "aws-amplify";
import styled from "styled-components";

export const ErrorContainter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-size: cover;
  padding: 5em;
  font-family: Helvetica;
  text-align: center;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: "" };
  }

  componentDidCatch(error) {
    console.log("error:::", error);
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render() {
    const { error } = this.state;
    const { children, persistor } = this.props;

    const signOut = () => {
      Auth.signOut().then(async () => {
        sessionStorage.clear();
        localStorage.clear();
        persistor.pause();
        persistor.flush().then(() => {
          return persistor.purge();
        });
        if (process.env.GATSBY_POSTHOG_API_KEY) {
          window?.posthog?.reset();
        }
      });
    };

    if (error) {
      // You can render any custom fallback UI
      return (
        <ErrorContainter>
          <div className={`flex-1 md:pl-[4.5rem h-[calc(100vh-56px)]"}`}>
            <h3 style={{ color: "red" }}>Whoops...</h3>
            <h4>
              It looks like there was a problem loading the data. Please click the ‘refresh’ button
              below. If the problem persists, please contact support.
            </h4>
            <Button
              type="link"
              onClick={() => {
                localStorage.setItem("showPhone", true);
                localStorage.setItem("callSocket", true);
                signOut();
                window.location = "/";
              }}
            >
              Refresh
            </Button>
          </div>
        </ErrorContainter>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
