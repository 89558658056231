import React from "react";
import { Modal } from "antd";
import LocationForm from "../../adminDashboard/location/locationForm";
import AddSMAISource from "../../adminDashboard/consentSources/addSMAISource";
import PhoneBotForm from "../../adminDashboard/phoneBots/phoneBotsForm";
import PhoneCofirmation from "../../dialog/phoneCofirmation";
import AddUserResponseTemplate from "../../dialog/addUserResponseTemplate";
import ConfirmDialog from "../../dialog/confirmDialog";
import CreateUserForm from "../../userSettings/users/createUserForm";
import ModalTeamForm from "../../adminDashboard/teams/teamForm";
import AddScreenNameForm from "../../dialog/screenForm";
import LeadEditModal from "../../engagements/leadEditModal";
import CampaignCloneModal from "../../engagements/campaigns/campaignCloneModal";
import ViewSourcesModal from "../../engagements/campaigns/viewSourcesModal";
import AppointmentForm from "../../engagements/activityCenter/appointments/appointmentForm";
import AppointmentConfirDialog from "../../engagements/activityCenter/appointments/appointmentConfirDialog";
import EditLeadLocation from "../../engagements/activityCenter/editLeadLocation";
import AddPhoneModal from "../../engagements/activityCenter/addLeadPhoneModal/addPhoneModal";
import SendReviewInvite from "../../dialog/sendReviewInvite";
import PushToCrmModal from "../../engagements/activityCenter/pushCrm/pushToCrmModal";
import PotentialLeadComp from "../../engagements/activityCenter/SFDuplicateLeads/potentialLeadComp";
import EditPhoneNumber from "../../life-events/customerList/editPhoneNumber";
import ReviewInviteForm from "../../engagements/activityCenter/reviewInviteForm";
import ReviewTemplate from "../../engagements/activityCenter/reviews/reviewTemplate";
import GraphAnalysis from "../../engagements/dashboard/graphAnalysis";
import SupportModal from "../../dialog/supportModal";
import EditLeadOwner from "../../engagements/activityCenter/editLeadOwner";
import SourceFilters from "../../prospects/sourceFilters";
import ResponseProspect from "../../prospects/responseProspect";
import CreatePresetFilters from "../../prospects/createPresetFilters";
import PushDealToCrm from "../../conversation/pushDealToCrm";
import RunExportModal from "../../personalized/runExportModal";
import LeadPresetFilter from "../../engagements/leadPresetFilters/leadPresetFilter";
import CsvImport from "../../../utils/csvImport";
import CrmLeadOpportunities from "../../dialog/crmLeadOpportunities";
import ScheduleMessagesPopup from "../../engagements/activityCenter/scheduleMessagesPopup";
import ViewScheduledMessagesPopup from "../../engagements/activityCenter/viewScheduledMessagesPopup";
import AccessPermissionMessage from "../../dialog/accessPermissionMessage";
import LeadLinker from "../../reviews/leadLinker";
import VDPSelector from "../../engagements/activityCenter/vdpSelector";
import ViewPDF from "../../engagements/activityCenter/mediaSharing/viewPDF";
import SelectSources from "../../engagements/campaigns/selectSources";
import OpportunityFormWrapper from "../../engagements/OpportunityFormWrapper";
import LeadOpportunityUsersForm from "../../engagements/leadOpportunityUsersForm";
import { OttoSettingsForm } from "../../userSettings/ottoSettingsForm";
import EngagmentTemplateForm from "../../adminDashboard/messageTemplates/engagmentTemplateForm";
import LeavesForm from "../../userSettings/userProfile/leavesForm";
import WorkingHourOverrideForm from "../../userSettings/userProfile/workingHourOverrideForm";
import HolidayForm from "../../userSettings/userProfile/holidayForm";
import DocumentSelector from "../../engagements/activityCenter/documentSelector";

const ModalDialog = props => {
  const { dialogData, confimLoading = false, type, openDialogHandler, handleClose, open } = props;

  const dialogContent = () => {
    switch (type) {
      case "createLocation":
        return (
          <LocationForm
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "createLeadSource":
        return (
          <AddSMAISource
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "addUpdatePhoneBots":
        return (
          <PhoneBotForm
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "phoneCofirmation":
        return (
          <PhoneCofirmation
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "createUserResponseTemplate":
        return (
          <AddUserResponseTemplate
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "createUserForm":
        return (
          <CreateUserForm
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "confirmDialog":
        return (
          <ConfirmDialog
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "deleteLeadDialog":
        return (
          <ConfirmDialog
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );

      case "teamDialog":
        return (
          <ModalTeamForm
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "screenForm":
        return (
          <AddScreenNameForm
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "leadFormDialog":
        return (
          <LeadEditModal
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "leadOpportunityFormDialog":
        return <OpportunityFormWrapper {...dialogData} />;
      case "cloneCampaign":
        return (
          <CampaignCloneModal
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "viewSources":
        return (
          <ViewSourcesModal
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "appointmentForm":
        return (
          <AppointmentForm
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "appointmentConfirm":
        return (
          <AppointmentConfirDialog
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "editLeadLocation":
        return (
          <EditLeadLocation
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "editOwnerDialog":
        return (
          <EditLeadOwner
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "prospectSourceFilter":
        return (
          <SourceFilters
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "addLeadViaPhone":
        return (
          <AddPhoneModal
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "sendReviewInvite":
        return (
          <SendReviewInvite
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "pushToCrm":
        return (
          <PushToCrmModal
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "respondToProspect":
        return (
          <ResponseProspect
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "potentialDuplicateLeads":
        return (
          <PotentialLeadComp
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "createPresetFilter":
        return (
          <CreatePresetFilters
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "editPhoneNumber":
        return (
          <EditPhoneNumber
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "reviewDialog":
        return (
          <ReviewInviteForm
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "reviewTemplate":
        return (
          <ReviewTemplate
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "leaveForm":
        return <LeavesForm {...dialogData} />;
      case "holidayForm":
        return <HolidayForm {...dialogData} />;
      case "dateOverride":
        return <WorkingHourOverrideForm {...dialogData} />;
      case "dashboardGraph":
        return (
          <GraphAnalysis
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "supportModal":
        return (
          <SupportModal
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "pushDealToCrm":
        return (
          <PushDealToCrm
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "runExportModal":
        return (
          <RunExportModal
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "leadPresetFilters":
        return (
          <LeadPresetFilter
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "csvImports":
        return (
          <CsvImport {...dialogData} openDialogHandler={openDialogHandler} onClose={handleClose} />
        );
      case "crmLeadOpportunities":
        return (
          <CrmLeadOpportunities
            {...dialogData}
            openDialogHandler={openDialogHandler}
            onClose={handleClose}
          />
        );
      case "scheduleMessage":
        return <ScheduleMessagesPopup {...dialogData} />;
      case "viewScheduledMessages":
        return <ViewScheduledMessagesPopup {...dialogData} />;
      case "leadAccessPermissionMessage":
        return <AccessPermissionMessage {...dialogData} />;
      case "openLeadLinker":
        return <LeadLinker {...dialogData} />;
      case "vdpSelect":
        return <VDPSelector {...dialogData} />;
      case "viewPDF":
        return <ViewPDF {...dialogData} />;
      case "selectSources":
        return <SelectSources {...dialogData} />;
      case "editLeadOpportunityUsers":
        return <LeadOpportunityUsersForm {...dialogData} />;
      case "ottoSettingsForm":
        return <OttoSettingsForm {...dialogData} />;
      case "engagementTemplatesForm":
        return <EngagmentTemplateForm {...dialogData} />;
      case "documentSelector":
        return <DocumentSelector {...dialogData} />;
      default:
        return <div>empty</div>;
    }
  };

  const closeDialogFunction = () => {
    if (dialogData?.handleCancelBtn) {
      dialogData.handleCancelBtn();
    } else {
      handleClose();
    }

    // ? if want to perform something while closing dialog from cross button than pass function from the desire component
    dialogData?.handleDialogClose && dialogData?.handleDialogClose();
  };

  return open ? (
    <Modal
      title={dialogData?.dialogHeader}
      open={open}
      centered={dialogData?.centered || false}
      onCancel={closeDialogFunction}
      closable
      confirmLoading={confimLoading}
      width={dialogData?.width}
      // ? here not using antd dialog footer , because content will be dynamic and footer functionality will be different and In addition communication from child to parent is difficult so using custome footer component
      footer={null}
    >
      {open && <div className="w-100">{dialogContent()}</div>}
    </Modal>
  ) : null;
};

export default ModalDialog;
