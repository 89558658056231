import { message, Upload } from "antd";
import React, { useState } from "react";
import { GRAPHQL_URL } from "../../../../config";
import { closeDialog } from "../../../../library/helpers";
import AntdIconV4 from "../../../../utils/antdIcon";
import CustomButton from "../../../commonComponents/button";
import DialogFooter from "../../../commonComponents/dialogFooter";
import useLang from "../../../../hooks/useLang";

const ReviewTemplate = () => {
  const [lang] = useLang();

  const [file, setFile] = useState(null);
  // const [showModal, setShowModal] = useState(isVisible)
  // const { user } = useContext(UserContext)

  let api_url = GRAPHQL_URL;
  [api_url] = api_url.split("/graph");

  const uploadProps = {
    accept: ".jpg",
    beforeUpload: file => {
      console.log(file);
      setFile(file);
      return false;
    },
    onRemove: file => {
      setFile(null);
    },
  };

  const handleUpload = () => {
    const hide = message.loading(`${lang.uploadInProgress}...`, 0);
    const formData = new FormData();
    formData.append("file", file);

    fetch(`${api_url}/review-template/upload`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then(res => res.json())
      .then(results => {
        hide();
        if (results && results.ok) {
          message.success(lang.uploadedSuccessfully);
        } else {
          message.error(lang.unableUploadDocument);
        }
        setFile(null);
      })
      .catch(err => {
        hide();
        console.log(err);
        message.error(lang.errorDuringUpload);
      });
  };

  return (
    <div>
      <p className="mb-5">{lang.uploadReviewJpgTemplate}</p>
      <Upload {...uploadProps} multiple="false" maxCount={1}>
        <CustomButton icon={<AntdIconV4 type="UploadOutlined" />} btnText={lang.jpgTemplate} />
      </Upload>
      <DialogFooter
        handleConfirm={handleUpload}
        confirmBtnText={lang.upload}
        handleCancel={() => closeDialog("reviewTemplate")}
        disabled={!file}
      />
    </div>
  );
};

export default ReviewTemplate;
