/* eslint-disable no-restricted-syntax */
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Dropdown, Popover, Tag, Tooltip, message } from "antd";
import dayjs from "dayjs";
import _, { uniqBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../amplify/authenticator-provider";
import { DELETE_CAMPAIGN, UPDATE_CAMPAIGN } from "../../../graphql/mutation";
import ActivateIcon from "../../../icons/js/ActivateIcon";
import CalendarIcon from "../../../icons/js/CalendarIcon";
import CloneIcon from "../../../icons/js/CloenIcon";
import PauseIcon from "../../../icons/js/PauseIcon";
import PersonOutlined from "../../../icons/js/personOutlined";
import {
  MODAL_CONTENT,
  MODAL_TITLE,
  appointmentStats,
  leadStats,
} from "../../../library/constants";
import { openDialog, showConfirmationModal } from "../../../library/helpers";
import { getHighlightedText } from "../../../library/utils";
import { StatusTag, TextLabel } from "./listView";
import { CrashAlert } from "../../commonComponents/crashAlert";

export function CampaignItem({
  handleTitleClick = () => {},
  item,
  canView,
  lang,
  refetch = () => {},
  sources,
  onCampaignBySource,
  search,
  statusData,
  showHeader = true,
}) {
  try {
    const { user } = useContext(UserContext);

    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN);
    const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);
    const handleActiveToggle = (checked, item) => {
      updateCampaign({
        variables: {
          id: parseInt(item.id),
          userId: user?.id || 0,
          name: item.name,
          textMessage: item.textMessage,
          method: "Text",
          activeInd: checked ? "Active" : "Inactive",
        },
      }).then(() => {
        refetch();
        message.success(
          `${lang.campaignSuccessfully} ${checked ? lang.activated : lang.inactivated}`
        );
      });
    };
    const handleDeleteClick = item => {
      showConfirmationModal(MODAL_TITLE, MODAL_CONTENT, () =>
        deleteCampaign({ variables: { id: item.id } }).then(resp => {
          if (resp?.data?.deleteCampaign?.statusCode === 200) {
            refetch();
            message.success(resp?.data?.deleteCampaign?.message);
          } else {
            message.success(lang.campaignCldntDelteInProgress);
          }
        })
      );
    };

    const handleCreateCampaign = data => {
      onCampaignBySource(data);
    };
    const handleCloneClick = e => {
      openDialog("cloneCampaign", {
        dialogHeader: `Clone ${e.name}`,
        campaign: e,
        // refetchCampaigns: onCampaignBySource,
        handleCreateCampaign,
      });
      // setClonePopup(tr?ue);
      // setSelectedCampaign(e);
    };

    const handleCampaignMenuClick = (value, item) => {
      switch (value.key) {
        case "1":
          showConfirmationModal(MODAL_TITLE, MODAL_CONTENT, () =>
            handleActiveToggle(item.activeInd.toLowerCase() !== "active", item)
          );
          break;
        case "2":
          break;
        case "3":
          handleCloneClick(item);
          break;
        case "4":
          handleDeleteClick(item);
          break;
        default:
          break;
      }
    };
    const findPath = (value, sources) => {
      const path = [];

      const findRecursive = (node, value) => {
        if (node.key === value) {
          path.push(node.name);
          return true;
        }

        if (node.childs && node.childs.length > 0) {
          for (const childNode of node.childs) {
            if (findRecursive(childNode, value)) {
              path.unshift(node.name);
              return true;
            }
          }
        }

        return false;
      };

      for (const source of sources) {
        if (findRecursive(source, value)) {
          break;
        }
      }

      return path;
    };
    const prepareResultArray = (input, sources) => {
      return _.map(input, item => {
        const path = findPath(item, sources);
        return path.join("/");
      });
    };

    const [sourcesList, setSourceList] = useState([]);

    const getStatusList = () => {
      const selectedStatuses = item.status;
      const foundStatus = uniqBy(statusData, "id")?.filter(item =>
        selectedStatuses.includes(item.id)
      );
      return uniqBy(foundStatus, "name");
    };
    useEffect(() => {
      setSourceList(prepareResultArray(item.source, sources)?.filter(item => item !== ""));
    }, [item]);

    return (
      <div className="flex flex-col bg-white rounded-sm  ">
        {showHeader && (
          <Header
            item={item}
            handleTitleClick={handleTitleClick}
            canView={canView}
            search={search}
            handleCampaignMenuClick={handleCampaignMenuClick}
          />
        )}
        <div className="h-0.5 w-full bg-grey-200" />
        <div className="flex flex-row h-full items-start p-4">
          <LeftSideCampaignInformation
            getStatusList={getStatusList}
            item={item}
            search={search}
            sourcesList={sourcesList}
            key="leftSideBar"
          />
          <div className="h-full w-0.5 bg-grey-200" />
          <RightSideCampaignInformation
            leadStats={leadStats}
            campaign={item}
            appointmentStats={appointmentStats}
          />
        </div>
      </div>
    );
  } catch (err) {
    <CrashAlert />;
    console.log(err);
  }
}
export function RightSideCampaignInformation({ leadStats, appointmentStats, campaign }) {
  try {
    return (
      <div className="flex flex-col gap-2 flex-[50%]">
        <div className="flex flex-col gap-3">
          <span className="text-xs flex flex-row items-center gap-1 font-[600] text-grey-500">
            <PersonOutlined />
            Leads Information
          </span>
          <div className="flex flex-row gap-2 ">
            {leadStats.map((item, index) => (
              <div
                key={index}
                className="flex flex-col flex-1 py-2 px-1 items-center gap-1 rounded-sm"
                style={{ backgroundColor: item.color }}
              >
                <span className="text-xs font-[600] text-grey-700">{item?.label}</span>
                <span className="text-sm font-[400] text-grey-700">
                  {campaign?.leadMessageCount[item?.key] || 0}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-xs flex flex-row items-center gap-1 font-[600] text-grey-500">
            <CalendarIcon />
            Appointment Information
          </span>
          <div className="flex flex-row justify-stretch gap-2 ">
            {appointmentStats.map((item, index) => (
              <div
                key={index}
                className="flex flex-col flex-1 py-2 px-1 items-center gap-1 rounded-sm"
                style={{ backgroundColor: item.color }}
              >
                <span className="text-xs font-[600] text-grey-700">{item.label}</span>
                <span className="text-sm font-[400] text-grey-700">
                  {campaign?.leadMessageCount[item.key] || 0}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } catch (err) {
    <CrashAlert />;
    console.log(err);
  }
}

function LeftSideCampaignInformation({ item, search, sourcesList, getStatusList }) {
  try {
    return (
      <div className="flex flex-col flex-[50%]">
        <TextLabel
          label="Sales Person"
          value={getHighlightedText(item?.user?.fullName, search) || ""}
        />
        <TextLabel
          label="Start Date"
          value={dayjs(item.startDate).format("MM/DD/YYYY hh:mm A") || ""}
        />
        <TextLabel
          label="End Date"
          value={item.endDate ? dayjs(item.endDate).format("MM/DD/YYYY hh:mm A") : "Until Stopped"}
        />
        <div className="mt-auto flex flex-col gap-1 items-start justify-start">
          <TextLabel
            label="Sources"
            value={
              <div className="flex flex-row flex-wrap gap-1">
                {sourcesList.slice(0, 2).map((item, index) => (
                  <Tag
                    style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                    className="bg-[#e2e8f0] flex gap-1 rounded-sm text-grey-700"
                    key={index}
                  >
                    {getHighlightedText(item, search)}
                  </Tag>
                ))}
                {sourcesList.length > 2 && (
                  <Popover
                    content={
                      <div className="flex flex-wrap max-w-[200px] items-center gap-2">
                        {(sourcesList || []).map((item, index) => (
                          <Tag
                            style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                            className="bg-[#e2e8f0] flex gap-1 rounded-sm text-grey-700"
                            key={index}
                          >
                            {getHighlightedText(item, search)}
                          </Tag>
                        ))}
                      </div>
                    }
                  >
                    <Tag className="bg-[#e2e8f0] flex gap-1 rounded-sm text-grey-700">
                      Show More...
                    </Tag>
                  </Popover>
                )}
              </div>
            }
          />
          <TextLabel
            label="Status"
            value={
              <div className="flex flex-row flex-wrap gap-1">
                {(getStatusList() || []).slice(0, 2).map((item, index) => (
                  <Tag
                    style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                    className="bg-[#e2e8f0] flex gap-1 rounded-sm text-grey-700"
                    key={index}
                  >
                    {getHighlightedText(item.name, search)}
                  </Tag>
                ))}
                {getStatusList().length > 2 && (
                  <Popover
                    content={
                      <div className="flex flex-wrap max-w-[200px] items-center gap-2">
                        {(getStatusList() || []).map((item, index) => (
                          <Tag
                            style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                            className="bg-[#e2e8f0] flex gap-1 rounded-sm text-grey-700"
                            key={index}
                          >
                            {getHighlightedText(item.name, search)}
                          </Tag>
                        ))}
                      </div>
                    }
                  >
                    <Tag className="bg-[#e2e8f0] flex gap-1 rounded-sm text-grey-700">
                      Show More...
                    </Tag>
                  </Popover>
                )}
              </div>
            }
          />
        </div>
      </div>
    );
  } catch (err) {
    <CrashAlert />;
    console.log(err);
  }
}

const Header = ({
  item,
  handleTitleClick,
  canView,
  search,
  campaignMenu,
  handleCampaignMenuClick,
}) => {
  return (
    <div className="flex flex-row items-center justify-between p-4">
      <Tooltip title="View Campaign Details">
        <span
          className="text-sm cursor-pointer font-[600] text-grey-700"
          onClick={() => handleTitleClick(item)}
          disabled={!canView}
        >
          {getHighlightedText(item.name, search)}
        </span>
      </Tooltip>
      <div className="flex flex-row items-center gap-3">
        <StatusTag item={item} />
        <CampaignOptions
          campaignMenu={campaignMenu}
          item={item}
          handleCampaignMenuClick={handleCampaignMenuClick}
        />
      </div>
    </div>
  );
};
export const CampaignOptions = ({ item, handleCampaignMenuClick = () => {} }) => {
  const campaignMenu = item => {
    return [
      {
        key: "1",
        label:
          item?.activeInd.toLowerCase() === "active" ? (
            <span className="flex flex-row gap-2 items-center justify-start w-[150px]">
              <PauseIcon /> Pause
            </span>
          ) : (
            <span className="flex flex-row gap-2 items-center justify-start w-[150px]">
              <ActivateIcon /> Activate
            </span>
          ),
      },
      {
        key: "3",
        label: (
          <span className="flex flex-row gap-2 items-center justify-start w-[150px]">
            <CloneIcon /> Clone
          </span>
        ),
      },
      {
        key: "4",
        label: (
          <span className="flex flex-row gap-2 text-red-500 items-center justify-start w-[150px]">
            <DeleteOutlined className="px-1" /> Delete
          </span>
        ),
      },
    ];
  };
  return (
    <Dropdown
      menu={{
        items: campaignMenu(item),
        onClick: value => handleCampaignMenuClick(value, item),
      }}
    >
      <MoreOutlined className="cursor-pointer" />
    </Dropdown>
  );
};
