import * as React from "react";

const PurpleEnvalopeIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={56} height={56} fill="none" {...props}>
    <rect width={56} height={56} fill="#F3E8FF" rx={28} />
    <path
      fill="#A855F7"
      d="m38.986 23.772-10.5-7a.875.875 0 0 0-.972 0l-10.5 7a.876.876 0 0 0-.389.729v11.375a1.75 1.75 0 0 0 1.75 1.75h19.25a1.75 1.75 0 0 0 1.75-1.75V24.5a.875.875 0 0 0-.39-.729Zm-14.407 6.854L18.375 35v-8.802l6.204 4.427Zm1.79.875h3.262l6.194 4.375h-15.65L26.37 31.5Zm5.052-.875 6.204-4.427v8.802l-6.204-4.375ZM28 18.552l8.959 5.973-7.328 5.226h-3.26l-7.328-5.226L28 18.552Z"
    />
  </svg>
);
export default PurpleEnvalopeIcon;
