import * as React from "react";

const PersonOutlined = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M13.804 12.215a6.264 6.264 0 0 0-1.35-2.002 6.292 6.292 0 0 0-2.002-1.35l-.02-.008a4.153 4.153 0 1 0-6.586-3.367 4.15 4.15 0 0 0 1.722 3.369l-.02.008c-.75.317-1.424.77-2.002 1.35a6.292 6.292 0 0 0-1.844 4.315.134.134 0 0 0 .134.137H2.84a.134.134 0 0 0 .134-.13 4.994 4.994 0 0 1 1.47-3.422A4.992 4.992 0 0 1 8 9.642c1.343 0 2.604.523 3.554 1.473a4.994 4.994 0 0 1 1.471 3.422c.002.073.06.13.134.13h1.005a.133.133 0 0 0 .134-.137 6.242 6.242 0 0 0-.494-2.315ZM8 8.37c-.769 0-1.493-.3-2.037-.844a2.862 2.862 0 0 1-.844-2.037c0-.769.3-1.492.844-2.037A2.862 2.862 0 0 1 8 2.607c.769 0 1.492.3 2.037.844.544.545.844 1.268.844 2.037 0 .769-.3 1.493-.844 2.037A2.862 2.862 0 0 1 8 8.369Z"
    />
  </svg>
);
export default PersonOutlined;
