/* eslint-disable no-unreachable */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
import { EditOutlined, InfoCircleFilled } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, Collapse, List, Tag, Tooltip, message } from "antd";
import { isEmpty, orderBy, uniqBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../amplify/authenticator-provider";
import {
  UPDATE_LEAD,
  UPDATE_LEAD_OPPORTUNITY,
  UPDATE_OPPORTUNITY_STAGE_NAME,
} from "../../../../graphql/mutation";
import useCrmIntegration from "../../../../hooks/useCrmIntegration";
import useLang from "../../../../hooks/useLang";
import CRMIcon from "../../../../icons/js/CRMIcon";
import LocationIcon from "../../../../icons/js/LocationIcon";
import { openDialog } from "../../../../library/helpers";
import { convertUtcToLocal } from "../../../../library/utils";
import { crmLinkGeneration } from "./crmLinks";
import OpportuityStage from "./opportuityStage";
import OpportunityWarning from "./opportuityWarning";
import { GET_OPPORTUNITY_STATUS } from "../../../../graphql/query";
import AntdIconV4 from "../../../../utils/antdIcon";
import { getLeadOpportunitySource } from "../../leadSourceHelper";
import { getLeadOpportunityStatus } from "../../leadStatusHelper";
import { eleadsLinkGenerator } from "../engagementHelpers";

const SFOpprStatus = [
  {
    statusTypeId: "1",
    status: "Duplicate",
  },
];

const OpportunityLayer = props => {
  const {
    leadOpportunities,
    leadFullname,
    crmLeadObj,
    selectedLead,
    crmIntegrationType,
    crmIntegrationData,
    refetchLeads,
    leadUsers,
    allowChangeStatus = true,
    refetchLeadDetails,
    setActiveOpp,
    oppValue,
  } = props;
  const [lang] = useLang();
  const [statusList, setStatusList] = useState([]);
  const [statusId, setStatusId] = useState(selectedLead?.leadStatusTypeId);
  const [isSFOppr, setIsSfOppr] = useState(false);
  const [isDSOppr, setIsDsOppr] = useState(false);
  const [isOpprOverall, setIsOpprOverall] = useState(false);
  const [list, setlist] = useState([]);
  const [listTemp, setListTemp] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [state, setState] = useState(false);
  const [userLocationsId, setUserLocationsId] = useState([]);
  const [opportunityWarningDialog, setOpportunityWarningDialog] = useState(false);
  const [opportunityInfo, setOpportunityInfo] = useState({});
  const [isOpporDialogFlagChanged, setIsOpprDialogFlagChanged] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState(oppValue);
  const handleOpporDialogFlagChanged = () => {
    setIsOpprDialogFlagChanged(true);
    localStorage.setItem("isOpporDialogFlagChangedLocal", "true");
  };

  const { user } = useContext(UserContext);

  const [updateLeadStatus] = useMutation(UPDATE_LEAD);
  const [updateSFStage] = useMutation(UPDATE_OPPORTUNITY_STAGE_NAME);
  const [updateLeadOpportunity, { loading: updateLeadOpportunityLoader }] =
    useMutation(UPDATE_LEAD_OPPORTUNITY);
  useEffect(() => {
    if (user && user?.locations?.length > 0) {
      const locationIDs = user?.locations.map(location => location?.location?.id);
      setUserLocationsId(locationIDs);
    }
  }, [user]);

  useEffect(() => {
    setStatusId(selectedLead?.leadStatusTypeId);
  }, [selectedLead?.leadStatusTypeId]);

  useEffect(() => {
    setSelectedOpportunity(oppValue);
  }, [oppValue]);

  useEffect(() => {
    if (crmIntegrationType === "SF") {
      if (
        leadOpportunities?.length > 0 &&
        leadOpportunities[0]?.sfLeadOpportunities?.length > 0 &&
        leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityId
      ) {
        setIsSfOppr(true);
        setIsOpprOverall(true);
      } else {
        setIsSfOppr(false);
        setIsOpprOverall(false);
      }
    }
    if (crmIntegrationType === "DT") {
      setIsOpprOverall(true);
    }
    if (crmIntegrationType === "DS") {
      if (
        leadOpportunities?.length > 0 &&
        leadOpportunities[0]?.dsOpportunityEvents?.length > 0 &&
        leadOpportunities[0]?.dsOpportunityEvents[0]?.dsEventId
      ) {
        setIsDsOppr(true);
        setIsOpprOverall(true);
      } else {
        setIsDsOppr(false);
        setIsOpprOverall(false);
      }
    }
  }, [crmIntegrationType, selectedLead]);

  useEffect(() => {
    if (!isSFOppr) {
      const SFStatusList = [...crmIntegrationData];

      crmIntegrationData?.length > 0 && setlist([...uniqBy(SFStatusList, "status")]);
      setState(prev => !prev);
      crmIntegrationData?.length > 0 && setListTemp([...uniqBy(SFStatusList, "status")]);
    }

    if (crmIntegrationType === "DS") {
      const SFStatusList = [...crmIntegrationData];
      crmIntegrationData?.length > 0 && setlist([...uniqBy(SFStatusList, "status")]);
      setState(prev => !prev);
    }
  }, [crmIntegrationData, isSFOppr, isDSOppr]);

  useEffect(() => {
    if (!isSFOppr && listTemp?.length > 0) {
      const SFStatusList = [...crmIntegrationData];

      const index = list?.length > 0 && list?.findIndex(item => +item?.statusTypeId === +statusId);
      if (index === -1) {
        const obj = SFStatusList?.find(item => +item.statusTypeId === +statusId);
        if (obj) {
          const dummyList = [...list];
          const findIndexStatus = dummyList?.findIndex(item => item.status === obj.status);
          dummyList[findIndexStatus] = obj;
          setlist([...dummyList]);
        }
      } else {
        setlist([...list]);
      }
    }
  }, [listTemp, isSFOppr, isDSOppr, statusId]);

  useEffect(() => {
    if (crmIntegrationType === "SF") {
      if (isSFOppr) {
        const sfStageName =
          selectedLead?.leadOpportunities[0]?.sfLeadOpportunities[0]?.sfOpportunityStage;
        setStatusId("2");
        setStatusList([...SFOpprStatus, { statusTypeId: "2", status: sfStageName }]);
      } else {
        setStatusList([...list]);
      }
    } else {
      setStatusList([...list]);
    }
  }, [isDSOppr, isSFOppr, list, selectedLead?.leadStatusTypeId]);

  const handleOpportunityStatusUpdation = async (oppStatus, opportunity) => {
    // return console.log(oppStatus, opportunity);
    const opporResp = await updateLeadOpportunity({
      variables: {
        leadOpportunityId: opportunity.id,
        opportunityStatusId: oppStatus,
      },
    });
    if (opporResp.data.updateLeadOpportunity.statusCode === 200) {
      message.success(opporResp?.data.updateLeadOpportunity.message);
      refetchLeadDetails();
      setConfirmModalOpen(false);
    } else {
      message.error(opporResp?.data.updateLeadOpportunity.message);
    }
  };

  const handleOpportunityName = oppr => {
    let opprName = oppr?.name;
    // DS
    if (
      (oppr?.dsOpportunityEvents?.length > 0 && oppr?.dsOpportunityEvents[0]?.dsEventId) ||
      crmLeadObj?.dsExtractedLead?.dsLeadId
    ) {
      opprName =
        oppr &&
        oppr?.dsOpportunityEvents &&
        oppr?.dsOpportunityEvents.length > 0 &&
        oppr?.dsOpportunityEvents[0]?.dsEventId
          ? `${lang.delearSocketOpprId} - ${oppr?.name}`
          : oppr?.name;
    }
    // SF
    if (
      (oppr?.sfLeadOpportunities?.length > 0 && oppr?.sfLeadOpportunities[0]?.sfOpportunityId) ||
      crmLeadObj?.sfExtractedLead?.sfLeadId
    ) {
      opprName = oppr?.sfLeadOpportunities[0]?.sfOpportunityId
        ? oppr?.name
        : `${lang.lead}: ${leadFullname}`;
    }
    return opprName;
  };

  const GetCrmTypeName = () => {
    const crmTypeName =
      crmIntegrationType === "DS"
        ? lang.dealerSocket
        : crmIntegrationType === "DT"
        ? lang.dealerteam
        : crmIntegrationType === "SF"
        ? lang.salesforce
        : crmIntegrationType === "VIN"
        ? lang.vinSolutions
        : crmIntegrationType === "EL"
        ? lang.ELead
        : crmIntegrationType === "MF"
        ? lang.Momentum
        : crmIntegrationType === "ADF"
        ? lang.ADF
        : lang.smaiCrm;
    return <Tooltip title={crmTypeName}>{crmTypeName}</Tooltip>;
  };

  const handleOpportunityWarningDialog = (item, crmLink, opprId, opprName, isOpp = false) => {
    setOpportunityInfo({
      ...item,
      opprLink: crmLink,
      opprId: opprId,
      opprName: opprName,
      isOpp: isOpp,
    });
    setOpportunityWarningDialog(true);
  };

  const handleStatusChangeSfOppr = async (oppStatus, opportunityItem) => {
    const leadStage = selectedLead?.leadOpportunities[0];
    const stageRes = await updateSFStage({
      variables: {
        leadOpportunityId: opportunityItem?.id,
        stageName: "Duplicate",
      },
    });
    if (stageRes?.data?.updateSfLeadStage?.statusCode !== 200) {
      message.error(stageRes?.data?.updateSfLeadStage?.message);
    } else {
      message.success(stageRes?.data?.updateSfLeadStage?.message);
      refetchLeads();
    }
  };

  const getAPIHandler = (oppStatus, opportunityItem) => {
    if (crmIntegrationType === "DS") {
      return handleOpportunityStatusUpdation(oppStatus, opportunityItem);
    }

    if (crmIntegrationType === "SF") {
      if (isSFOppr) {
        return handleStatusChangeSfOppr(oppStatus, opportunityItem);
      }
      return handleOpportunityStatusUpdation(oppStatus, opportunityItem);
    }
  };

  const renderLabel = () => {
    if (crmIntegrationType === "SF") {
      if (isSFOppr) {
        return lang.changeStage;
      }
    }
    return lang.changeStatus;
  };

  // TODO: Add location from the opportunity Object of the lead
  const handleOwnerName = (opprUsers, crmIntegrationType = "") => {
    const users = (opprUsers || []).filter(leadUser => leadUser.isActive);
    return (
      <div className="flex flex-wrap gap-1">
        {users?.map((item, i) => (
          <Tooltip key={i} title={item?.userType?.name || ""}>
            <Tag color={i % 2 === 0 ? "green" : "purple"}>
              {item.user.fullName || ""}{" "}
              {!isEmpty(item.user.companyRole) ? `(${item.user.companyRole.name})` : ""}
            </Tag>
          </Tooltip>
        ))}
      </div>
    );
  };
  const [opportunityStatusList, setOpportunityStatusList] = useState([]);
  const { loading: opportunityStatusLoader } = useQuery(GET_OPPORTUNITY_STATUS, {
    variables: {
      // opportunityTypeId: 1,
      locationIds: sessionStorage?.getItem("currentLocationId")?.split(","),
      fetchAll: true,
    },
    onCompleted: resp => {
      setOpportunityStatusList(resp.getOpportunityStatus);
    },
  });

  return (
    <div>
      <List
        itemLayout="vertical"
        className="py-1 px-0"
        dataSource={orderBy(leadOpportunities, item => new Date(item.updatedDate), "desc")}
        pagination={
          leadOpportunities?.length <= 7
            ? false
            : {
                onChange: page => {},
                pageSize: 7,
                size: "small",
              }
        }
        renderItem={item => {
          const salesForceType =
            crmIntegrationType === "SF"
              ? "usAutoSales"
              : crmIntegrationType === "DT"
              ? "dealerTeam"
              : "";
          let isOpportunity = false;
          if (
            crmIntegrationType === "DS" &&
            item?.dsOpportunityEvents?.length > 0 &&
            item?.dsOpportunityEvents[0].dsEventId
          ) {
            isOpportunity = true;
          } else if (
            crmIntegrationType === "DT" &&
            item?.dtLeadOpportunities?.length > 0 &&
            item?.dtLeadOpportunities[0].dtOpportunityId
          ) {
            isOpportunity = true;
          } else if (
            crmIntegrationType === "SF" &&
            item?.sfLeadOpportunities?.length > 0 &&
            item?.sfLeadOpportunities[0].sfOpportunityId
          ) {
            isOpportunity = true;
          } else if (item?.id) {
            isOpportunity = true;
          }

          const activeLeadOppr = selectedLead?.crmLeadObj;
          const leadId =
            crmIntegrationType === "DS"
              ? activeLeadOppr?.dsExtractedLead?.dsLeadId
              : crmIntegrationType === "DT"
              ? activeLeadOppr?.dtExtractedLead?.dtLeadId
              : crmIntegrationType === "SF"
              ? activeLeadOppr?.sfExtractedLead?.sfLeadId
              : crmIntegrationType === "MF"
              ? activeLeadOppr?.mfExtractedLead?.mfProspectId ||
                activeLeadOppr?.mfExtractedLead?.mfCustomerId
              : null;
          const opprId =
            crmIntegrationType === "DS" && item?.dsOpportunityEvents?.length > 0
              ? item?.dsOpportunityEvents[0].dsEventId
              : crmIntegrationType === "DT" && item?.dtLeadOpportunities?.length > 0
              ? item?.dtLeadOpportunities[0].dtOpportunityId
              : crmIntegrationType === "SF" && item?.sfLeadOpportunities?.length > 0
              ? item?.sfLeadOpportunities[0].sfOpportunityId
              : crmIntegrationType === "VIN" && item?.vsLeadOpportunities?.length > 0
              ? item?.vsLeadOpportunities[0].vsLeadId
              : crmIntegrationType === "EL" && item?.elLeadOpportunities?.length > 0
              ? item?.elLeadOpportunities[0].elOpportunityId
              : crmIntegrationType === "MF" && item?.mfLeadOpportunities?.length > 0
              ? item?.mfLeadOpportunities[0].mfLeadId
              : item?.id;

          const linkOpprId =
            crmIntegrationType === "VIN" && item?.vsLeadOpportunities?.length > 0
              ? item?.vsLeadOpportunities[0].vsContactId
              : crmIntegrationType === "EL"
              ? selectedLead.fullName
              : opprId;
          const crmLeadOpportunityLink = crmLinkGeneration(
            crmIntegrationType,
            isOpportunity,
            linkOpprId,
            leadId,
            salesForceType,
            user?.company?.id
          );

          const leadSourceName = getLeadOpportunitySource(item?.opportunitySource || {});

          const opportunityStatus = getLeadOpportunityStatus(item);

          return (
            <Collapse className="mb-1" expandIconPosition="right">
              <Collapse.Panel
                style={{ backgroundColor: selectedOpportunity === item.id ? "#eff6ff" : "" }}
                header={
                  <div className="flex flex-col gap-2">
                    {/* header */}
                    <div className="flex items-start justify-between">
                      <div className="text-sm font-[600] text-grey-700 max-w-[60%] flex flex-row items-center gap-1">
                        {handleOpportunityName(item)}
                      </div>
                      <div className="flex flex-row items-center gap-1">
                        <Checkbox
                          checked={selectedOpportunity === item.id}
                          value={item.id}
                          onChange={e => {
                            e.stopPropagation();
                            setSelectedOpportunity(e.target.checked ? item.id : "#DEFAULTOPPID");
                            setActiveOpp(e.target.checked ? item.id : "#DEFAULTOPPID");
                          }}
                        />
                        <Tooltip
                          title={
                            <div className="flex flex-col items-start">
                              <span>
                                Created on{" "}
                                {convertUtcToLocal(item?.createdDate, "MM/DD/YYYY h:mm A", "")}
                              </span>
                              {!isEmpty(item.updatedDate) && (
                                <span>
                                  & updated on{" "}
                                  {convertUtcToLocal(item?.updatedDate, "MM/DD/YYYY h:mm A", "")}
                                </span>
                              )}
                            </div>
                          }
                        >
                          <InfoCircleFilled />
                        </Tooltip>
                        <EditOutlined
                          onClick={e => {
                            e.stopPropagation();
                            openDialog("leadOpportunityFormDialog", {
                              mode: "EDIT",
                              lead: item,
                              width: "49.5vw",
                              refetch: () => refetchLeadDetails(),
                              crmIntegration: crmIntegrationType,
                              getLeads: () => {},
                              dialogHeader: (
                                <div className="flex flex-col">
                                  <span className="text-lg text-white font-[600]">
                                    Modify Opportunity {item.name || ""} for{" "}
                                    {selectedLead.fullName || ""}
                                  </span>
                                  <span className="text-sm font-[400] text-white">
                                    Provide the following details to modify the opportunity
                                  </span>
                                </div>
                              ),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-start">
                      <div className="flex flex-col items-start gap-1 justify-start">
                        <div className="flex flex-col justify-start items-start gap-1">
                          <div className="flex flex-row items-center gap-1">
                            <CRMIcon className="w-5 h-5" />
                            <GetCrmTypeName />{" "}
                            <div
                              onClick={e => e.stopPropagation()}
                              className="idColor flex flex-row items-center text-align-start"
                            >
                              {crmLeadOpportunityLink ? (
                                <a
                                  className="labelColor"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={crmLeadOpportunityLink}
                                >
                                  <Tooltip title={opprId}>
                                    #{opprId?.length > 15 ? `${opprId?.slice(0, 15)}...` : opprId}
                                  </Tooltip>
                                </a>
                              ) : (
                                isOpportunity && (
                                  <p className="labelColor font-semibold">
                                    #{crmIntegrationType === "MF" ? "" : `SMAI`}
                                    {String(opprId).padStart(4, "0")}
                                  </p>
                                )
                              )}
                            </div>
                          </div>
                          <div className="flex flex-row items-center gap-1">
                            <LocationIcon />
                            {item?.lead?.location?.title || "N/A"}
                          </div>
                          <div className="flex ml-1 flex-row items-center gap-1">
                            <AntdIconV4 type="CarOutlined" style={{ color: "#334155" }} />
                            <VehicleJSX item={item} />
                          </div>
                        </div>
                        <div className="flex flex-col mt-[5px] mb-0">
                          <div>
                            <span className="font-bold text-[#5e5e5e]">
                              {isOpprOverall ? `${lang.ownersOppr}` : lang.leadOwnersOppr}
                            </span>
                          </div>
                          {handleOwnerName(item?.leadOpportunityUsers, crmIntegrationType)}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              >
                <div className="flex flex-col gap-1 ">
                  <div className="flex flex-col mb-0">
                    <div>
                      <span className="font-bold text-[#5e5e5e]">{lang.status}</span>
                    </div>
                    <div>{opportunityStatus}</div>
                  </div>
                  <div className="flex flex-col mb-0">
                    <div>
                      <span className="font-bold text-[#5e5e5e]">{lang.source}</span>
                    </div>
                    <div className="text-transform: capitalize">
                      {(leadSourceName || "").replace(/_/g, " ").toLocaleLowerCase()}
                    </div>
                  </div>
                  {crmIntegrationType === "DT" && (
                    <div className="flex flex-col mb-0">
                      <div>
                        <span className="font-bold text-[#5e5e5e]">{lang.location}</span>
                      </div>
                      <div>{item?.location?.title}</div>
                    </div>
                  )}

                  {allowChangeStatus &&
                    (crmIntegrationType === "SF" ||
                      crmIntegrationType === "DT" ||
                      crmIntegrationType === "DS") && (
                      <div className="flex flex-col mb-0">
                        <div>
                          <span className="font-bold text-[#5e5e5e]">{renderLabel()}</span>
                        </div>
                        <div>
                          <OpportuityStage
                            loading={opportunityStatusLoader}
                            confirmLoader={updateLeadOpportunityLoader}
                            opportunityStatusList={opportunityStatusList}
                            opportunityItem={item}
                            isOpprOverall={isOpprOverall}
                            isSFOppr={isSFOppr}
                            isDSOppr={isDSOppr}
                            crmIntegrationType={crmIntegrationType}
                            statusId={statusId}
                            oldStatus={item?.opportunityStatus?.name || ""}
                            lead={selectedLead}
                            APIHandler={getAPIHandler}
                            confirmModalOpen={confirmModalOpen}
                            setConfirmModalOpen={setConfirmModalOpen}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </Collapse.Panel>
            </Collapse>
          );
        }}
      />
      {opportunityWarningDialog && (
        <OpportunityWarning
          opportunityInfo={opportunityInfo}
          crmIntegrationType={crmIntegrationType}
          crmLeadObj={crmLeadObj}
          visibleModal={opportunityWarningDialog}
          handleOKBtn={() => setOpportunityWarningDialog(false)}
          setModalVisible={() => setOpportunityWarningDialog(false)}
          handleOpporDialogFlagChanged={handleOpporDialogFlagChanged}
        />
      )}
    </div>
  );
};
export default OpportunityLayer;

// function to get lead owner info , like redering on lead center for owners (Implementation is in progress)
export const handleOwnerData = (leadUsers, crmIntegration, isOpprOverall = false) => {
  let primaryOwnerName = "Select Owner";

  const ownerArray = leadUsers.map(user => {
    if (user?.userType?.name) {
      return {
        label: `${user?.userType?.name}`,
        username: `${user?.user?.firstName} ${user?.user?.lastName} ${
          user?.user?.companyRole?.name ? `(${user?.user?.companyRole?.name})` : ""
        }`,
        id: user?.userType?.id,
      };
    }

    return {
      label: "",
      username: `${user?.user?.firstName} ${user?.user?.lastName} ${
        user?.user?.companyRole?.name ? `(${user?.user?.companyRole?.name})` : ""
      }`,
      id: "",
    };
  });
  if (crmIntegration === "SF") {
    let ownerArraySF = [];

    if (isOpprOverall) {
      ownerArraySF = ["opportunity owner", "salesperson 1", "salesperson 2"];
    } else {
      ownerArraySF = ["lead owner", "salesperson 1", "bdc rep"];
    }

    let isFoundSF = false;
    for (const ownerLabel of ownerArraySF) {
      for (const owner of ownerArray) {
        if (owner?.label?.toLowerCase() === ownerLabel) {
          primaryOwnerName = owner?.username;
          isFoundSF = true;
          break;
        } else {
          primaryOwnerName = owner?.username;
        }
      }
      if (isFoundSF) break;
    }
  } else if (crmIntegration === "DS") {
    const ownerArray1 = ["primary assigned", "bdc", "secondary assigned"];
    let isFound = false;
    for (const ownerLabel of ownerArray1) {
      for (const owner of ownerArray) {
        if (owner?.label?.toLowerCase() === ownerLabel) {
          primaryOwnerName = owner?.username;
          isFound = true;
          break;
        } else {
          primaryOwnerName = owner?.username;
        }
      }
      if (isFound) break;
    }
  }

  return primaryOwnerName;
};
function VehicleJSX({ item }) {
  const vehicleOfInterest = uniqBy(item?.vehiclesOfInterest || [], v =>
    [v.year, v.make, v.model].join()
  );

  return (
    <div className="flex flex-col items-start justify-start gap-1">
      {vehicleOfInterest?.length > 0 ? (
        vehicleOfInterest?.map((voi, index) => {
          const parseVehicles = () => {
            let name = "";
            if (
              voi?.year !== 0 &&
              voi?.year !== null &&
              voi?.year !== undefined &&
              voi?.year !== "0"
            )
              name = `${name} ${voi.year}`;
            if (voi?.make !== null && voi?.make !== undefined && voi.make !== 0 && voi.make !== "0")
              name = `${name} ${voi.make}`;
            if (
              voi?.model !== null &&
              voi?.model !== undefined &&
              voi?.model !== 0 &&
              voi?.model !== "0"
            )
              name = `${name} ${voi.model}`;

            if (name.trim() !== "") {
              return (
                <Tooltip placement="topLeft" title={name}>
                  <Tag color={voi?.customerInterest === "BUY" ? "blue" : "magenta"}>{name}</Tag>
                </Tooltip>
              );
            } else {
              return <span className="pl-[5px]">No Vehicles</span>;
            }
          };
          return (
            <div className="test-start" key={index}>
              {parseVehicles()}
            </div>
          );
        })
      ) : (
        <span>No Vehicles</span>
      )}
    </div>
  );
}
