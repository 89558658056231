import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobileView: false,
};

export const mobileView = createSlice({
  name: "isMobileView",
  initialState,
  reducers: {
    setMobileView: (state, action) => {
      state.isMobileView = action.payload.isMobileView;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMobileView } = mobileView.actions;

export const getMobileView = state => state.mobile.isMobileView;

export default mobileView.reducer;
