import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  notificationFilters: {
    type: [],
    isReadOnly: false,
    sortBy: "date",
    notificationsFor: "all",
    leadIds: [],
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserSlice: (state, action) => {
      state.user = action.payload.user;
    },
    setnotificationType: (state, action) => {
      state.notificationFilters.type = action.payload;
    },
    setNotificationSortAndFilters: (state, action) => {
      state.notificationFilters.type = action.payload.filters;
      state.notificationFilters.sortBy = action.payload.sortBy;
    },
    setnotificationRead: (state, action) => {
      state.notificationFilters.isReadOnly = action.payload;
    },
    setNoficationFor: (state, action) => {
      state.notificationFilters.notificationsFor = action.payload.notificationsFor;
      state.notificationFilters.leadIds = action.payload.selectedLeads;
    },
  },
});

export const {
  setUserSlice,
  setnotificationRead,
  setnotificationType,
  setNotificationSortAndFilters,
  setNoficationFor,
} = userSlice.actions;

export const getUser = state => state.user.user;

export default userSlice.reducer;
