import { Tree } from "antd";
import React from "react";
import { dynamicSort } from "../utils/dataSorting";
import { StyledTree } from "./filterDropdown";

export const TreeComponent = ({
  keys,
  expandedNodes,
  setExpandedNodes,
  list,
  setkeys,
  title,
  value,
  child1,
  child2 = null,
}) => {
  const handleExpand = keys => {
    setExpandedNodes([...keys]);
  };

  const onCheck = keys => {
    handleExpand(keys);
    setkeys(prev => {
      const newSelection = keys?.filter(x => !prev?.includes(x));
      expandedNodes?.length > 0 && setExpandedNodes([...expandedNodes, ...newSelection]);
      return keys;
    });
  };
  return (
    <StyledTree>
      <Tree
        checkable
        checkedKeys={keys}
        onCheck={onCheck}
        expandedKeys={expandedNodes}
        onExpand={expanded => {
          setExpandedNodes([...expanded]);
        }}
      >
        {(structuredClone(list) || [])?.sort(dynamicSort(title))?.map(item => {
          return (
            item?.name !== "Unknown" && (
              <Tree.TreeNode title={item[title]} key={item[value]}>
                {item[child1]?.sort(dynamicSort(title))?.map(child => {
                  return (
                    child?.name !== "Unknown" && (
                      <Tree.TreeNode key={child && child[value]} title={child && child[title]}>
                        {child &&
                          child2 &&
                          child[child2]?.length > 0 &&
                          child[child2]?.sort(dynamicSort(title))?.map(child => {
                            return (
                              child[title] !== "Unknown" && (
                                <Tree.TreeNode
                                  key={child && child[value]}
                                  title={child && child[title]}
                                />
                              )
                            );
                          })}
                      </Tree.TreeNode>
                    )
                  );
                })}
              </Tree.TreeNode>
            )
          );
        })}
      </Tree>
    </StyledTree>
  );
};
