import React, { memo, useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { Empty, Skeleton } from "antd";

const BarChart = props => {
  const { graphData } = props;

  const [api, setApi] = useState(true);

  const Wait = () =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(false);
      }, 2000);
    });

  const handleAPI = async () => {
    try {
      const api = await Wait();
      setApi(api);
    } catch (err) {
      setApi(true);
    }
  };

  useEffect(() => {
    handleAPI();
  }, []);

  const config = {
    data: graphData,
    xField: "type",
    yField: "value",
    seriesField: "type",
    yAxis: false,
    xAxis: true,
    height: 320,
    autoFit: true,
    legend: {
      position: "bottom",
      flipPage: false,
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
    label: {
      position: "top",
      offsetY: 8,
      formatter: ({ value }) => value,
    },
  };

  return api ? (
    <div className="h-[320px]">
      <Skeleton loading={api} active />
      <Skeleton className="mt-3" loading={api} active />
    </div>
  ) : graphData?.length > 0 ? (
    <Column className="flex-1" {...config} />
  ) : (
    <Empty className="h-[320px] flex flex-col justify-center" />
  );
};

export default memo(BarChart); // converted into pure component to prevent unwanted rendering
