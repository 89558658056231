import * as React from "react";

const ReviewTemplateIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#334155"
      stroke="#334155"
      strokeWidth={0.078}
      d="M12.46 5.664V4.375a3.086 3.086 0 0 0-3.085-3.086.586.586 0 0 0-.524.324L5.899 7.517l-.01.022H2.5A1.21 1.21 0 0 0 1.29 8.75v6.875a1.21 1.21 0 0 0 1.21 1.21h13.438a1.836 1.836 0 0 0 1.822-1.606m-5.3-9.565 5.338 9.57-.038-.005m-5.3-9.565h4.415a1.836 1.836 0 0 1 1.377.621l.03-.026m-5.821-.595 5.82.595m-.521 8.97.937-7.5a1.838 1.838 0 0 0-.445-1.444l.03-.026m-.522 8.97.521-8.97m-1.684 8.824a.665.665 0 0 1-.66.581H6.836V8.263l.005-.008 2.868-5.737.013-.026.03.006a1.914 1.914 0 0 1 1.538 1.877V6.25a.586.586 0 0 0 .586.586h5a.664.664 0 0 1 .66.747m-.938 7.5.898-7.505.04.005m-.938 7.5.937-7.5m-.937 7.5.937-7.5M2.5 8.711h-.04v6.954h3.205V8.71H2.5Z"
    />
  </svg>
);
export default ReviewTemplateIcon;
