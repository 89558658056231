/* eslint-disable react/destructuring-assignment */
import { MoreOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Dropdown, Empty, Skeleton, Tag, message } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_NOTE } from "../../../graphql/mutation";
import { GET_LEAD_NOTES } from "../../../graphql/query";
import { closeDialog, openDialog } from "../../../library/helpers";
import { convertUtcToLocal } from "../../../library/utils";
import { deleteNoteRedux, setSelectedLeadRedux } from "../../../redux/slices/leadSlice";
import { NotesDrawer } from "./NotesDrawer";
import { TruncatedContent } from "./engagementHelpers";

export function LeadNotes(props) {
  const { selectedLead } = useSelector(state => state.lead);
  const dispatch = useDispatch();
  const [deleteNote] = useMutation(DELETE_NOTE);
  const { loading: notesLoader } = useQuery(GET_LEAD_NOTES, {
    variables: { leadId: selectedLead.id },
    fetchPolicy: "network-only",
    onCompleted: res => {
      const notes = res.getLeadNotes || [];
      dispatch(setSelectedLeadRedux({ ...selectedLead, leadNotes: notes }));
    },
  });
  const noteItems = [
    {
      key: "VIEW",
      label: "View",
    },
    {
      key: "EDIT",
      label: "Edit",
    },
    {
      key: "DELETE",
      label: "Delete",
    },
  ];
  const onChange = (e, item) => {
    switch (e.key) {
      case "VIEW":
        props.setleadNotesData({ mode: "VIEW", open: true, leadNote: item, selectedLead });
        break;
      case "EDIT":
        props.setleadNotesData({ mode: "EDIT", open: true, leadNote: item, selectedLead });
        break;
      case "DELETE":
        openDialog("confirmDialog", {
          header: "Are you sure you want to delete this note",
          handleConfirm: async () => {
            const res = await deleteNote({
              variables: {
                id: item.id,
              },
            });
            if (res.data.deleteNote.statusCode === 200) {
              message.success(res.data.deleteNote.message);
              dispatch(deleteNoteRedux(item.id));
              closeDialog("confirmDialog");
            }
          },
        });
        break;
      default:
        break;
    }
  };
  return (
    <div className="flex flex-col gap-1">
      <Skeleton loading={notesLoader} active paragraph={{ rows: 4 }} avatar>
        {selectedLead?.leadNotes?.length > 0 ? (
          (selectedLead?.leadNotes || [])?.map((item, index) => {
            return (
              <div
                className="border-[#d9d9d9] border-[1px] px-2 py-2 border-solid rounded-[6px] flex gap-2 w-full flex-col-centered"
                key={index}
              >
                <div className="flex-row-centered justify-between w-full">
                  <Tag color={item?.leadOpportunity?.id ? "green" : "blue"}>
                    {item?.leadOpportunity?.id ? "Opportunity Note" : "Lead Note"}
                  </Tag>
                </div>
                <span
                  className="text-sm flex flex-wrap font-[400] whitespace-pre-wrap"
                  style={{ wordBreak: "break-word" }}
                >
                  <TruncatedContent content={item.note} color="blue" />
                </span>
                <div className="flex-row-centered justify-between">
                  <span className="text-xs font-[400] text-gray-500 w-full">
                    {item?.updatedBy?.fullName || item?.createdBy?.fullName}{" "}
                    {convertUtcToLocal(item.updateTs || item.createTs, "MM/DD/YYYY hh:mm:ss A")}
                  </span>
                  <Dropdown menu={{ items: noteItems, onClick: info => onChange(info, item) }}>
                    <MoreOutlined className="cursor-pointer" />
                  </Dropdown>
                </div>
              </div>
            );
          })
        ) : (
          <Empty description="No Lead Notes" imageStyle={{ height: 30 }} />
        )}
      </Skeleton>
      <NotesDrawer {...props} />
    </div>
  );
}
