import { useMutation } from "@apollo/client";
import { Collapse, message, Select, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { DEFAULT_COUNTRY } from "../../../config";
import { ADD_USER, UPDATE_USER } from "../../../graphql/mutation";
import TextInput from "../../../library/textInput";
import CustomAlert from "../../commonComponents/alert";
import DialogFooter from "../../commonComponents/dialogFooter";
import CustomDivider from "../../commonComponents/divider";
import PermissionInfo from "./permissionInfo";
import useLang from "../../../hooks/useLang";
import { isEmailValid } from "../../../library/helpers";

const parsePermissions = role => {
  const permissions = {
    canCreateUsers: "Users",
    canCreateTeams: "Teams",
    canViewProspects: "Prospects",
    isCompanyAdmin: "Company Admin",
    canViewAutoAnalytics: "Auto Analytics",
    canViewAdExport: "Personalized Ad Export",
    canViewClm: "Life Events",
    canViewGle: "Global Life Events",
  };
  let permissionString = "";
  Object.entries(role).map(([p, val]) => {
    permissionString += permissions[p] && val === true ? `${permissions[p]}, ` : "";
  });
  return permissionString
    ? permissionString.substring(0, permissionString.length - 2)
    : "No Permissions";
};

const CreateUserForm = props => {
  const {
    user: editUser,
    refreshIndexUsers,
    roles,
    company,
    companyRoles,
    refreshUsers,
    onClose,
  } = props;
  const [lang] = useLang();

  const [error, setError] = useState("");
  const [companyRole, setCompanyRole] = useState(null);
  const [phone, setPhone] = useState(editUser?.phone || "");
  const [user, setUser] = useState({ companyId: company.id, roleId: null, ...editUser });

  const [createUser, { loading: createUserLoader }] = useMutation(ADD_USER, {
    onCompleted: () => {
      refreshUsers();
      refreshIndexUsers();
    },
  });

  const [updateUser, { loading: updateUserLoader }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      refreshIndexUsers();
    },
  });

  useEffect(() => {
    if (editUser?.phone) {
      setPhone(editUser.phone);
    }
  }, [editUser]);

  const updateUserField = (field, value) => {
    setUser(prevState => {
      const newState = { ...prevState };
      newState[field] = value;
      return newState;
    });
  };

  useEffect(() => {
    setPhone(user?.phone || "");
    user?.id && setCompanyRole(user ? String(user?.companyRoleId) : undefined);
  }, [user]);

  const validateUserInputs = (user, setError) => {
    let emailValidated = false;
    let requirmentsSatisfied = false;
    if (
      user.email &&
      user.email.trim() !== "" &&
      user.firstName &&
      user.firstName.trim() !== "" &&
      user.lastName &&
      user.lastName.trim() !== ""
    ) {
      requirmentsSatisfied = true;
    } else {
      setError(lang.allFieldsRequired);
    }
    emailValidated = isEmailValid(user.email);
    if (!emailValidated) {
      console.log("createUserForm:failed email validation");
      setError(lang.invalidEmail);
    }
    return emailValidated && requirmentsSatisfied;
  };

  const resetUser = () => {
    setUser({ companyId: company.id, roleId: null, role: null });
  };

  const submitUser = async () => {
    if (validateUserInputs(user, setError)) {
      setError("");
      if (user.id) {
        await updateUser({
          variables: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            roleId: user.roleId,
            companyId: company.id,
            companyRoleId: companyRole,
            phone: user.phone,
          },
        });
        resetUser();
        refreshUsers();
        onClose();
      } else {
        await createUser({
          variables: {
            ...user,
            companyRoleId: companyRole,
            phone,
            locationIds: [],
            locationId: sessionStorage.getItem("currentLocationId"),
          },
        });
        resetUser();
        refreshUsers();
        onClose();
      }
      message.success(lang.userCreated);
    }
  };

  const hasRole = (roles, role) => {
    const hasSpecificRole = roles.find(x => x[role]);
    return hasSpecificRole !== undefined;
  };

  const handleActiveToggle = id => {
    setUser(prevState => {
      const newState = { ...prevState };
      newState.roleId = id;
      newState.role = { id };
      return newState;
    });
  };

  return (
    <div>
      {error && <CustomAlert message={error} type="error" banner />}
      <div className="py-1">
        <TextInput
          label={lang.emailLabel}
          labelClasses="my-1 font-normal"
          disabled={user?.id}
          placeholder={lang.enterEmail}
          value={user?.email}
          onChange={e => updateUserField("email", e.target.value)}
        />
      </div>
      <div className="py-1">
        <TextInput
          label={lang.firstName}
          labelClasses="my-1 font-normal"
          placeholder={lang.enterFirstName}
          value={user?.firstName}
          onChange={e => updateUserField("firstName", e.target.value)}
        />
      </div>
      <div className="py-1">
        <TextInput
          label={lang.lastName}
          labelClasses="my-1 font-normal"
          placeholder={lang.enterLastName}
          value={user?.lastName}
          onChange={e => updateUserField("lastName", e.target.value)}
        />
      </div>
      <div className="py-1">
        <p className="mt-1 font-normal">{lang.phoneNumber}</p>
        <PhoneInput
          international
          className="phoneInputCss"
          withCountryCallingCode
          countryCallingCodeEditable={false}
          defaultCountry={DEFAULT_COUNTRY}
          value={user?.phone}
          placeholder={lang.enterPhone}
          onChange={e => updateUserField("phone", e)}
        />
        <div className="py-1" id="antdRoleDropdown">
          <p className="mt-1 font-normal">{lang.copmanyRole}</p>
          <Select
            getPopupContainer={() => document.getElementById("antdRoleDropdown")}
            className="w-full"
            onChange={setCompanyRole}
            value={companyRole}
            placeholder={lang.selectCompanyRole}
            showArrow
          >
            {(companyRoles || []).map((item, i) => (
              <Select.Option key={i} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <CustomDivider className="my-2" />
        <PermissionInfo
          hasAutoAnalytics={hasRole(roles ? roles.roles : [], "canViewAutoAnalytics")}
          hasAdExport={hasRole(roles ? roles.roles : [], "canViewAdExport")}
          hasClm={hasRole(roles ? roles.roles : [], "canViewClm")}
        />
        <CustomDivider className="my-2" />

        {roles?.roles?.length && (
          <Collapse defaultActiveKey={[user?.id ? "1" : "0"]}>
            <Collapse.Panel header={lang.selectRoles} key="1">
              {roles.roles.map(role => (
                <div className="my-2 w-full" key={role.id}>
                  <div className="flex items-center gap-1">
                    <Switch
                      defaultChecked={false}
                      checked={user.role && user.role.id === role.id}
                      onChange={() => handleActiveToggle(role.id)}
                      id={role.id}
                      size="small"
                    />{" "}
                    <p className="font-medium">{role.name}-</p>
                  </div>
                  <Tooltip title={parsePermissions(role)}>
                    <div className="flex gap-1 items-center">
                      <p className="text-sm labelColor truncate">{parsePermissions(role)}</p>
                    </div>
                  </Tooltip>
                </div>
              ))}
            </Collapse.Panel>
          </Collapse>
        )}
        <DialogFooter
          confirmBtnText={user?.id ? lang.EditUser : lang.createUser}
          handleCancel={onClose}
          handleConfirm={submitUser}
          loading={createUserLoader || updateUserLoader}
        />
      </div>
    </div>
  );
};

export default CreateUserForm;
