/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-curly-brace-presence */
import { Badge, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import { isEmpty, isUndefined, orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { BASE_URL_FOR_STATIC_ICONS_AND_IMAGES, forum } from "../../../config";
import useCrmIntegration from "../../../hooks/useCrmIntegration";
import useLang from "../../../hooks/useLang";
import MessageIcon from "../../../icons/js/MessageIcon";
import { DealInfoText } from "../../../library/activityComponents";
import { convertUtcToLocal, parseTimestamp } from "../../../library/utils";
import AntdIconV4 from "../../../utils/antdIcon";
import CustomLabel from "../../commonComponents/label";
import { getLeadOpportunitySource } from "../leadSourceHelper";
import { CustomCalendar, DealStatusIcon, displayPhone, getLeadStatus } from "./engagementHelpers";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

const LeadList = props => {
  const {
    messageObject,
    user,
    item,
    sourcesData,
    handleAppointmentModalClose,
    spinner,
    selectedLeadId,
    refetchLeads,
    selectedLead,
  } = props;
  const crmIntegration = useCrmIntegration();
  const [lang] = useLang();
  const [vehicle, setvehicle] = useState("");
  const parseVehicles = () => {
    const latestOpportunity = item?.crmLeadObj?.activeLeadOpportunity;
    const voi =
      latestOpportunity?.vehiclesOfInterest &&
      latestOpportunity?.vehiclesOfInterest?.length > 0 &&
      orderBy(latestOpportunity?.vehiclesOfInterest || [], item => item.customerInterest, "asc")[0];
    if (voi) {
      let name = "";
      if (voi?.year && voi.year !== 0 && voi.year !== "" && voi.year !== "0")
        name = `${name} ${voi.year}`;
      if (voi?.make && voi.make !== 0 && voi.make !== "" && voi.make !== "0")
        name = `${name} ${voi.make}`;
      if (voi?.model && voi.model !== 0 && voi.model !== "" && voi.model !== "0")
        name = `${name} ${voi.model}`;
      return isEmpty(name) ? setvehicle("No vehicle") : setvehicle(name);
    }
    return setvehicle("No vehicle");
  };
  useEffect(() => {
    parseVehicles();
  }, [item?.leadVehicleInventory]);

  let leadDate = item?.leadCreatedDate
    ? parseTimestamp(dayjs.utc(item?.leadCreatedDate).local())
    : parseTimestamp(new Date());
  if (
    item?.messages &&
    item?.messages[
      item?.messages[item?.messages?.length - 1]?.id
        ? item?.messages?.length - 1
        : item?.messages?.length - 2
    ] != null
  ) {
    leadDate = item?.messages[
      item?.messages[item?.messages?.length - 1]?.id
        ? item?.messages?.length - 1
        : item?.messages?.length - 2
    ]?.dateReceived
      ? parseTimestamp(
          dayjs
            .utc(
              item?.messages[
                item?.messages[item?.messages?.length - 1]?.id
                  ? item?.messages?.length - 1
                  : item?.messages?.length - 2
              ]?.dateReceived
            )
            .local()
        )
      : parseTimestamp(
          dayjs
            .utc(
              item?.messages[
                item?.messages[item?.messages?.length - 1]?.id
                  ? item?.messages?.length - 1
                  : item?.messages?.length - 2
              ]?.dateSent
            )
            .local()
        );
  }
  let leadSource = { name: "", tooltipSourceMsg: "" };
  if (["DS", "VIN", "EL", "MF"].includes(user?.company?.crmIntegrationType)) {
    leadSource.tooltipSourceMsg = getLeadOpportunitySource(
      item?.crmLeadObj?.activeLeadOpportunity?.opportunitySource || {}
    );
    leadSource.name = `${
      item?.crmLeadObj?.activeLeadOpportunity?.opportunitySource?.name || "N/A"
    }`;
  } else {
    leadSource =
      sourcesData?.length > 0 &&
      sourcesData.find(el => +el.id === +(item?.leadSourceOriginalId || 0));
  }

  const appointmentStatus =
    item?.activeAppointment?.appointmentStatus === "CANCELLED"
      ? "error"
      : item?.activeAppointment?.appointmentStatus === "SHOWED"
      ? "show"
      : item?.activeAppointment?.appointmentStatus === "NO_SHOWED"
      ? "noshow"
      : item?.activeAppointment?.isConfirmed
      ? "success"
      : dayjs(item?.activeAppointment?.startDatetime).format("MM/DD/YYYYTHH:mm") <
        dayjs().utc().format("MM/DD/YYYYTHH:mm")
      ? "default"
      : "processing";
  const handleTooltip = () => {
    return item?.activeAppointment?.startDatetime ? (
      <>
        <p>
          Appointment:{" "}
          {!isUndefined(item?.activeAppointment?.startDatetime)
            ? convertUtcToLocal(item?.activeAppointment?.startDatetime, "ddd, MMMM Do YYYY, h:mm A")
            : "NA"}
        </p>
        <p>
          {lang.status}: {item?.activeAppointment?.appointmentStatus || "NA"}
        </p>
      </>
    ) : (
      ""
    );
  };

  const setLeadStatus = leadItem => {
    const status = { leadStatus: "", toolTipLeadStatus: "" };

    status.toolTipLeadStatus = leadItem?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus
      ?.parentStatus
      ? `${leadItem?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.parentStatus?.name} / ${
          leadItem?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name || ""
        }`.replace(/_/g, " ")
      : leadItem?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name || "";

    status.leadStatus = leadItem?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name
      ? getLeadStatus(
          user,
          leadItem?.crmLeadObj?.activeLeadOpportunity?.opportunityStatus?.name,
          status.toolTipLeadStatus,
          +selectedLeadId === +leadItem?.id ? spinner : false
        )
      : leadItem?.leadStatusType?.type
      ? getLeadStatus(
          user,
          leadItem?.leadStatusType?.type,
          status.toolTipLeadStatus,
          +selectedLeadId === +leadItem?.id ? spinner : false
        )
      : "";
    return status;
  };

  const leadOpportunityStatus = setLeadStatus(item);

  return (
    <div className="flex flex-col justify-center">
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <Tooltip
            placement="topLeft"
            title={leadSource?.tooltipSourceMsg || item?.otherSource || "N/A"}
          >
            <DealInfoText
              lineHight={0}
              className="flex flex-row items-center gap-2 py-1 text-[14px] text-[#333]"
            >
              {
                <img
                  width={
                    leadSource && leadSource?.name?.toLowerCase() === "cars.com" ? "38px" : "18px"
                  }
                  src={`/images/sources/${(leadSource?.name || "marketplace")
                    .toLowerCase()
                    .replace(".", "-")}.png`}
                  onError={e => {
                    // e.target.src = "/images/sources/forum.png";
                    e.target.src = BASE_URL_FOR_STATIC_ICONS_AND_IMAGES + forum;
                  }}
                  className="align-middle rounded h-[18px]"
                  alt={leadSource?.name}
                />
              }

              <CustomLabel
                label={
                  (leadSource?.name || "").length <= 25
                    ? leadSource?.name || "Direct"
                    : `${leadSource?.name.substring(0, 22)}...`
                }
                labelClass="text-[14px] font-sans font-[400]"
              />
            </DealInfoText>
          </Tooltip>
          <div className="flex items-center gap-2">
            <AntdIconV4 type="PhoneOutlined" className="rotate-90" style={{ color: "#334155" }} />
            <CustomLabel
              label={item?.phoneNumbers && displayPhone(item, crmIntegration)}
              labelClass="text-[14px] font-sans"
            />
          </div>

          {vehicle && !vehicle.includes("undefined") && (
            <div className="flex items-baseline mt-1 gap-2">
              <AntdIconV4 type="CarOutlined" style={{ color: "#334155" }} />
              <CustomLabel label={vehicle} labelClass="text-[14px] font-sans" />
            </div>
          )}
        </div>
        <div>
          <div className="flex flex-col items-end">
            {item?.unreadCount > 0 && (
              <CustomLabel
                label={
                  item?.unreadCount > 0 && <Badge count={item?.unreadCount || 0} offset={[5, 0]} />
                }
                labelClass="text-[#333] text-end"
              />
            )}
            <CustomLabel
              label={<div className="text-[12px]">{` ${leadDate}`}</div>}
              labelClass="text-[#333] text-end mb-2"
            />

            <DealStatusIcon
              status={leadOpportunityStatus.leadStatus}
              msg={leadOpportunityStatus.toolTipLeadStatus}
            />

            {(item?.crmLeadObj?.crmStatus === "Open" ||
              (item?.leadStatusType?.type || "").includes("ACTIVE")) &&
              handleTooltip() && (
                <DealInfoText color={"green"}>
                  <Tooltip placement="topLeft" title={handleTooltip()}>
                    <CustomCalendar
                      status={appointmentStatus}
                      onClick={e => handleAppointmentModalClose(e, item, true)}
                    >
                      <div className="relative bottom-1">
                        <span className="text-[16px] font-semibold">
                          {item?.activeAppointment
                            ? convertUtcToLocal(item?.activeAppointment?.startDatetime, "DD")
                            : "N/A"}
                        </span>
                      </div>
                      <div className="absolute w-full bottom-0 text-center h-[20px] flex justify-center items-center text-[13px] leading-4 text-[#fff]">
                        <small>
                          {item?.activeAppointment
                            ? convertUtcToLocal(item?.activeAppointment?.startDatetime, "MMM")
                            : ""}
                        </small>
                      </div>
                    </CustomCalendar>
                  </Tooltip>
                </DealInfoText>
              )}
          </div>
        </div>
      </div>
      {!isEmpty(messageObject) && (
        <div className="mt-2 ">
          <Tooltip
            title={(messageObject?.translatedContent || messageObject?.content || "")?.trim()}
          >
            <p
              className={`${
                selectedLead && selectedLead?.id === item?.id ? "bg-[#BFDBFE]" : "bg-brand-50"
              } p-1 text-grey-700 text-[12px] font-[400] rounded-[3px] font-sans pl-2 flex items-center`}
            >
              <MessageIcon className="text-grey-700 mr-2" />
              {messageObject?.direction === "TO_LEAD"
                ? `${
                    messageObject?.user?.fullName ||
                    `${messageObject?.user?.firstName} ${messageObject?.user?.lastName}` ||
                    ""
                  }`
                : messageObject?.direction === "FROM_OTTO"
                ? "Otto bot"
                : item?.fullName}{" "}
              :{" "}
              {(messageObject?.translatedContent || messageObject?.content || "").trim().length > 25
                ? `${(messageObject?.translatedContent || messageObject?.content || "")
                    .trim()
                    .slice(0, 25)}...`
                : (messageObject?.translatedContent || messageObject?.content || "").trim()}
            </p>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default LeadList;
