import { Button, Tooltip } from "antd";
import React from "react";

const CustomButton = props => {
  const {
    type,
    btnText,
    className,
    handleClick,
    icon,
    danger = false,
    ghost = false,
    size = "default",
    loading = false,
    shape = "",
    tooltipTitle = "",
    disabled = false,
    form = false,
    htmlType = "",
    id = "",
  } = props;
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        className={className}
        type={type}
        onClick={handleClick}
        icon={icon}
        danger={danger}
        ghost={ghost}
        size={size}
        loading={loading}
        shape={shape}
        disabled={disabled}
        htmlType={htmlType}
        id={id}
      >
        {btnText}
      </Button>
    </Tooltip>
  );
};

export default CustomButton;
