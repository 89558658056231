import React, { useState, useContext, useEffect } from "react";
import { message, Input, List } from "antd";
import { FilePdfOutlined, SearchOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import orderBy from "lodash/orderBy";
import { GET_COMPANY_DOCUMENTS, GET_LEAD_OPPORTUNITY_DOCUMENT_URL } from "../../../graphql/query";
import { closeDialog } from "../../../library/helpers";
import DialogFooter from "../../commonComponents/dialogFooter";
import { getHighlightedText } from "../../../library/utils";
import CustomButton from "../../commonComponents/button";
import OpportunityFilter from "./opporotunityLayer/opportunityFilter";
import CustomLabel from "../../commonComponents/label";
import { UserContext } from "../../../amplify/authenticator-provider";
import useLang from "../../../hooks/useLang";

let selectedId;
export default function DocumentSelector(props) {
  const [lang] = useLang();
  const { user } = useContext(UserContext);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [search, setSearch] = useState("");
  const [opportunity, setOpportunity] = useState();
  const opprList = (props?.opportunityList || []).filter(el => el.name !== "All");
  const opportunities = orderBy(opprList, item => new Date(item.updatedDate), "desc");

  useEffect(() => {
    if (props?.activeOppName && !opportunity) {
      setOpportunity(
        props?.activeOppName?.name === "All" && opportunities.length > 0
          ? opportunities[0]?.id
          : props?.activeOppName?.id
      );
    } else if (opportunities.length > 0) {
      setOpportunity(opportunities[0]?.id);
    }
  }, [opportunity, props?.activeOppName, opportunities]);

  const { data: documentData, loading: docLoader } = useQuery(GET_COMPANY_DOCUMENTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: user?.company.id,
      locationId: sessionStorage.getItem("currentLocationId"),
      search: search,
      page: 1,
      pageSize: 25,
    },
  });

  const [getDocumentLink, { loading: docLinkLoading }] = useLazyQuery(
    GET_LEAD_OPPORTUNITY_DOCUMENT_URL,
    {
      fetchPolicy: "network-only",
      onCompleted: resp => {
        if (resp?.getLeadOpportunityDocumentUrl?.statusCode === 200) {
          const documentUrl = resp?.getLeadOpportunityDocumentUrl?.documentUrl;
          if (documentUrl) {
            window.open(documentUrl, "_blank").focus();
            selectedId = null;
          }
        } else {
          message.error(resp?.getLeadOpportunityDocumentUrl?.statusMessage);
        }
      },
    }
  );
  const count = documentData?.getCompanyDocuments?.companyDocuments?.length || 0;

  return (
    <div>
      <CustomLabel label={lang.selectOpportunity} labelClass="m-0" />
      <OpportunityFilter
        className="w-full my-2"
        oppValue={opportunity}
        disabled={!props?.leadId}
        handleFilter={setOpportunity}
        activeOppName={props?.activeOppName || ""}
        opportunityList={opportunities || []}
      />
      <CustomLabel label="Documents" labelClass="m-0" />
      <Input
        className="w-full"
        placeholder="Search documents"
        prefix={<SearchOutlined />}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      <List
        loading={docLoader}
        className="mt-2 max-h-[300px] overflow-y-auto"
        dataSource={documentData?.getCompanyDocuments?.companyDocuments || []}
        renderItem={(item, index) => {
          return (
            <div key={index} className="flex flex-row p-2 gap-3 mr-4 mb-4 cursor-pointer">
              <FilePdfOutlined className="text-[20px]" />
              <div className="flex flex-col gap-3 ml-2 w-full">
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex flex-row items-center justify-between w-full">
                    <span className="text-sm">{getHighlightedText(item?.name, search)}</span>
                    <CustomButton
                      btnText="Print"
                      key="submit"
                      loading={item.id === selectedId && docLinkLoading}
                      type="primary"
                      handleClick={async () => {
                        selectedId = item.id;
                        await getDocumentLink({
                          variables: {
                            companyDocumentId: item.id,
                            leadId: props?.leadId,
                            leadOpportunityId: opportunity,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }}
        pagination={
          count < 5
            ? null
            : {
                current: pagination.page,
                pageSize: pagination.pageSize,
                total: count || 0,
                showTotal: total => `${lang.total}: ${total} ${lang.vehicles}`,
                pageSizeOptions: ["25", "50", "100", "150", "200", "500"],
                showSizeChanger: true,
                onChange: (page, pageSize) => {
                  setPagination({ page, pageSize });
                },
                onShowSizeChange: (current, size) => {
                  setPagination({ page: current, pageSize: size });
                },
              }
        }
        itemLayout="horizontal"
      />

      <DialogFooter
        loading={docLoader || docLinkLoading}
        showConfirmButton={false}
        confirmBtnText=""
        handleCancel={() => closeDialog("documentSelector")}
        align="left"
      />
    </div>
  );
}
