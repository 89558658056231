/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import React, { Suspense, lazy, useEffect, useState } from "react";
import "./editor.css";
import "react-quill/dist/quill.snow.css";
import { Spin } from "antd";

const ReactQuill = lazy(() => import("react-quill"));

export const setValue = text => {
  const element = document.querySelector("trix-editor");
  element.editor.insertString(text);
  const end = element.editor.element.value.length;
  console.log(end);

  element.editor.setSelectedRange([end, end]);
};
export const getTextValue = () => {
  const element = document.querySelector("trix-editor");
  const htmlString = element.editor.element.value;
  const contentWithoutDiv = htmlString.replace(/<\/?div>/g, "");
  return contentWithoutDiv;
};
export const resetEditor = () => {
  const element = document.querySelector("trix-editor");
  element.editor.element.value = "";
};
const RichTextEditor = ({ value, setValue }) => {
  const [canRender, setCanRender] = useState(false);
  useEffect(() => setCanRender(true));
  return (
    <div>
      <Suspense fallback={<Spin />}>
        {canRender ? (
          <ReactQuill
            id="rich-editor"
            value={value}
            placeholder="Type your Message here..."
            theme="snow"
            onChange={e => setValue(e)}
          />
        ) : (
          <textarea />
        )}
      </Suspense>
    </div>
  );
};

export default RichTextEditor;
