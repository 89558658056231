/* eslint-disable prefer-template */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { message, Spin, Tooltip } from "antd";
import Icon from "@ant-design/icons";
import dayjs from "dayjs";
import { isEmpty, isUndefined } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { UserContext } from "../amplify/authenticator-provider";
import CustomLabel from "../components/commonComponents/label";
import { InputTextWrap } from "../components/engagements/activityCenter/engagementActivity";
import {
  ConsentIconType,
  ConsentStatus,
  ConsentStatusColor,
} from "../components/engagements/activityCenter/engagementHelpers";
import Messages from "../components/engagements/activityCenter/messages";
import { CREATE_MESSAGE, RETRY_MESSAGE_SEND } from "../graphql/mutation";
import {
  GET_ALL_USERS,
  GET_COMPANY_AFTER_MESSAGE_CONSENT_TEMPLATE,
  GET_LEAD_BY_PHONE,
  GET_LEAD_SOURCES,
  GET_MESSAGES,
} from "../graphql/query";
import { OptionContainer } from "../library/activityComponents";
import { TooltipButton } from "../library/basicComponents";
import AntdIconV4 from "../utils/antdIcon";
import AirplaneIconWhite from "../../static/icons/AirplaneIconWhite.svg";
import Logo from "../../static/Logo.svg";
import { openDialog } from "../library/helpers";
import useLang from "../hooks/useLang";

const pageSize = 100;
let page = 1;
let lastLead = "";

const ChatBotPopup = props => {
  const { location } = props;

  const { user, notificationData } = useContext(UserContext);
  const [lang] = useLang();

  const text = useRef("");
  const messagesEndRef = useRef(null);
  const msgContainerRef = useRef(null);
  const queryString = location ? `${(location || "").split("/")[2]}`?.replace("/", "") : "";

  const [selectedLead, setSelectedLead] = useState(null);
  const [openAddModal, setAddPhoneModalVisible] = useState(false);
  const [userIdMap, setUserIdMap] = useState({});
  const [messageFilters, setMessageFilters] = useState({
    campaignIds: [],
    leadId: selectedLead?.id,
    lastId: lastLead,
    page: 1,
    pageSize: pageSize,
  });
  const [variables, setVariables] = useState({
    search: "",
    source: [],
    sourceOriginal: [],
    combinedSource: [],
    voi: [],
    page: 1,
    pageSize: 25,
    orderBy: "latest_chat",
    orderDirection: "desc",
    status: ["RESPONDED", "WAITING_FOR_REPLY", "UNCONTACTED"],
    campaignIds: [],
  });

  const { data: sourcesData } = useQuery(GET_LEAD_SOURCES);

  const { data: respTemp } = useQuery(GET_COMPANY_AFTER_MESSAGE_CONSENT_TEMPLATE, {
    fetchPolicy: "network-only",
  });
  const afterMessageConsent = respTemp?.getAfterMessageText?.content || "";

  const { data: userData } = useQuery(GET_ALL_USERS, {
    variables: { companyId: user?.company?.id || 0 },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: res => {
      let obj = userIdMap;
      if (res?.getUsers?.data || []) {
        const users = res?.getUsers?.data || [];
        users.forEach(el => {
          const id = el?.id;
          obj = {
            ...obj,
            [id]: el?.firstName || "Ott",
          };
        });
        setUserIdMap({ ...obj });
      }
    },
  });

  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [retryMessageSend] = useMutation(RETRY_MESSAGE_SEND);

  const { data: leadData, loading: leadsLoading } = useQuery(GET_LEAD_BY_PHONE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      phone: queryString,
    },
    onCompleted: res => {
      console.log("res", res?.leadByPhone);
      const leadByPhone = res?.leadByPhone || null;
      if (leadByPhone) {
        setSelectedLead(leadByPhone);
        page = 1;
        setMessageFilters({
          ...messageFilters,
          leadId: "36" || leadByPhone?.id,
          lastId: leadByPhone?.id,
          page: 1,
        });
      } else if (!isUndefined(queryString) && !isEmpty(queryString)) {
        setAddPhoneModalVisible(true);
        openDialog("addLeadViaPhone", {
          dialogHeader: lang.addPhone,
          phoneNumber: queryString,
          openModal: openAddModal,
          setModalVisible: setAddPhoneModalVisible,
          variables: variables,
          data: [],
          sources: sourcesData?.leadSources || [],
          user: user,
          onSetLead: item => {
            const detail = { ...item, messages: [] };
            setSelectedLead(detail);
            page = 1;
            setMessageFilters({
              ...messageFilters,
              leadId: item.id,
              lastId: lastLead,
              page: 1,
            });
          },
          onLeadStatus: () => {},
          onLeadOwners: () => {},
          onVehicles: () => {},
          isSyncUp: false,
          isFromChatbot: true,
        });
      }
    },
  });
  const [getMessages, { loading, error }] = useLazyQuery(GET_MESSAGES, {
    // pollInterval: 10000,
    // fetchPolicy: "network-only",
    variables: {
      ...messageFilters,
    },
    onCompleted: res => {
      lastLead = res?.messages?.lastId || "";
      const messageData = res?.messages?.data || [];
      const messagesData = messageData.map(el => {
        const findIndex = (selectedLead?.messages || []).findIndex(s => +s?.id === +el?.id);
        if (findIndex === -1 && !isUndefined(el)) {
          return el;
        }
      });
      let messageList;
      if (messageData.length > 0 && messagesData.length > 0) {
        messageList = [...messageData];
        const leadDetail = { ...selectedLead, messages: messageList };
        setSelectedLead({ ...leadDetail });
      }
    },
  });

  const onMsgScroll = () => {
    // eslint-disable-next-line prefer-destructuring
    const scrollTop = msgContainerRef.current.scrollTop;

    if (scrollTop === 0 && !isEmpty(selectedLead?.messages)) {
      page += 1;
      setMessageFilters({
        ...messageFilters,
        leadId: "36" || selectedLead?.id,
        lastId: lastLead,
        page: page,
      });
    }
  };

  useEffect(() => {
    if (messageFilters?.leadId) {
      getMessages();
    }
  }, [messageFilters]);

  useEffect(() => {
    if (notificationData) {
      if (+notificationData?.lead_id === +selectedLead?.id) {
        const notificationMessages = notificationData?.lead_messages || [];
        if (notificationMessages.length > 0) {
          setSelectedLead(prevState => {
            const newState = { ...prevState };
            notificationMessages.forEach(notification => {
              const findIndex = (selectedLead?.messages || []).findIndex(
                s => +s?.id === +notification?.id
              );
              if (findIndex === -1) {
                newState.messages.push({
                  id: notification?.id,
                  campaignId: notification?.campaign_id,
                  content: notification?.content,
                  dateReceived: notification?.date_received,
                  dateSent: notification?.date_sent,
                  direction: notification?.direction,
                  leadId: notification?.lead_id,
                  userId: notification?.user_id,
                  systemUserId: notification?.system_user_id,
                  messageType: notification?.message_type || "",
                  messageStatus: notification?.message_status || "",
                  statusCode: notification?.status_code || "",
                  errorCode: notification?.error_code || "",
                  errorMessage: notification?.error_message || "",
                  messageLog: {
                    id: notification?.message_log?.id,
                    messageId: notification?.message_log?.message_id,
                    toPhone: notification?.message_log?.to_phone,
                    fromPhone: notification?.message_log?.from_phone,
                  },
                });
              }
            });

            return newState;
          });
        }
      }
    }
  }, [notificationData]);

  const onClickHandler = (content, lead, userId) => {
    const match = content.match(/{([^}]+)}/);
    if (match) {
      message.error(lang.fillValueBeforeSend);
      return;
    }

    if (!isEmpty(content)) {
      const messageData = {
        leadId: +lead?.id,
        userId: userId,
        direction: "TO_LEAD",
        content: content,
        toPhone: lead?.phoneNumbers[0]?.phone,
      };

      let selectedLeadData = {
        ...selectedLead,
        textConsentStatus: lead?.textConsentStatus || selectedLead?.textConsentStatus,
      };
      if ((selectedLead?.messages || []).length <= 0) {
        selectedLeadData = { ...selectedLeadData, messages: [] };
      }
      selectedLeadData.messages.push({
        ...messageData,
        type: "new",
      });
      page = 1;
      setSelectedLead(selectedLeadData);
      text.current.value = "";
      createMessage({
        variables: {
          ...messageData,
        },
      }).then(res => {
        if (res?.data?.createMessage?.statusCode === 200) {
          const resMessage = res?.data?.createMessage?.leadMessage || {};
          const leadDetail = { ...selectedLeadData };
          const findIndex = leadDetail.messages.findIndex(el => el?.type === "new");
          if (findIndex > -1) {
            leadDetail.messages[findIndex].type = "";
            leadDetail.messages[findIndex].id = resMessage?.id;
            leadDetail.messages[findIndex].dateSent = resMessage?.dateSent;
            leadDetail.messages[findIndex].messageStatus = resMessage?.messageStatus;
            leadDetail.messages[findIndex].messageType = resMessage?.messageType;
            leadDetail.messages[findIndex].errorMessage = resMessage?.errorMessage;
            leadDetail.messages[findIndex].errorCode = resMessage?.errorCode;
            leadDetail.messages[findIndex].leadOpportunity = resMessage?.leadOpportunity ||
              resMessage?.lead_opportunity || { name: "", id: "" };
          }
          setSelectedLead({ ...leadDetail });
        } else {
          message.error(res?.data?.createMessage?.message || lang.msgSendFail);
          const leadDetail = { ...selectedLeadData };
          const findIndex = leadDetail.messages.findIndex(el => el?.type === "new");
          if (findIndex > -1) {
            leadDetail.messages[findIndex].type = "error";
          }
          setSelectedLead({ ...leadDetail });
        }
      });
    }
  };

  const handleRetry = (e, c) => {
    if (c?.id) {
      const leadDetail = { ...selectedLead };
      const findIndex = leadDetail.messages.findIndex(el => +el?.id === +c?.id);
      if (findIndex > -1) {
        leadDetail.messages[findIndex].type = "new";
        leadDetail.messages[findIndex].messageStatus = "";
        setSelectedLead({ ...leadDetail });
      }
      retryMessageSend({
        variables: {
          messageId: c.id,
        },
      }).then(resp => {
        if (resp?.data?.retryMessage?.statusCode === 200) {
          const resMessage = resp?.data?.retryMessage?.leadMessage || {};
          if (findIndex > -1) {
            leadDetail.messages[findIndex].type = "";
            leadDetail.messages[findIndex].id = resMessage?.id;
            leadDetail.messages[findIndex].dateSent = resMessage?.dateSent;
            leadDetail.messages[findIndex].messageStatus = resMessage?.messageStatus;
            leadDetail.messages[findIndex].messageType = resMessage?.messageType;
            leadDetail.messages[findIndex].errorMessage = resMessage?.errorMessage;
            leadDetail.messages[findIndex].errorCode = resMessage?.errorCode;
          }
          setSelectedLead({ ...leadDetail });
        } else {
          message.error(resp?.data?.retryMessage?.message);
        }
      });
    }
  };

  const IsConentStatusEnable = (consent, status) => {
    return consent === "ACCEPTED" || (consent === "PENDING" && !status);
  };

  const SetContentBy = item => {
    return item?.content || "";
  };

  const handleLogoClick = () => {
    let url = `${window.location.origin}/engagements/activity-center`;
    console.log("url", url);
    url = !isUndefined(queryString) && !isEmpty(queryString) ? `${url}/${queryString}` : url;
    window.open(url, "_blank");
  };

  const handleOpportunityId = selectedLead => {
    if (
      selectedLead?.leadOpportunity?.dsOpportunityEvents?.length > 0 &&
      selectedLead?.leadOpportunity?.dsOpportunityEvents[0]?.dsEventId
    ) {
      return `#${String(selectedLead?.leadOpportunity?.dsOpportunityEvents[0]?.dsEventId).padStart(
        4,
        "0"
      )}`;
    }
    if (
      selectedLead?.leadOpportunity?.sfLeadOpportunities?.length > 0 &&
      selectedLead?.leadOpportunity?.sfLeadOpportunities[0]?.sfOpportunityId
    ) {
      return `#${String(
        selectedLead?.leadOpportunity?.sfLeadOpportunities[0]?.sfOpportunityId
      ).padStart(4, "0")}`;
    }
    return `#SMAI${String(selectedLead?.leadOpportunity?.id).padStart(4, "0")}`;
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if ((selectedLead?.messages || []).length > 0 && page === 1) {
      scrollToBottom();
    }
  }, [selectedLead]);

  return (
    <div className="relative">
      <div className="absolute z-99 top-[20px] left-[20px]" onClick={() => handleLogoClick()}>
        <Icon
          onClick={() => handleLogoClick()}
          className="text-4xl absolute"
          component={Logo}
          alt="SocialMiningAi"
        />
      </div>
      <div className="sticky top-0 z-10 bg-white py-4 w-full flex flex-col items-center justify-center">
        <CustomLabel label={selectedLead?.fullName} labelClass="font-semibold text-[17px]" />
        <div>
          <Tooltip title={ConsentStatus(selectedLead?.textConsentStatus)}>
            <AntdIconV4
              type={ConsentIconType(
                selectedLead?.textConsentStatus,
                user?.company?.isOptinConsentMethod
              )}
              style={{
                color: ConsentStatusColor(selectedLead?.textConsentStatus),
                fontSize: 14,
                marginLeft: 5,
              }}
            />{" "}
            <small>
              {formatPhoneNumber(
                selectedLead?.phoneNumbers ? selectedLead?.phoneNumbers[0]?.phone : "N/A"
              )}
            </small>
          </Tooltip>
        </div>
      </div>
      <div className="h-[72vh] overflow-auto" ref={msgContainerRef} onScroll={onMsgScroll}>
        <div className="m-[1em]">
          <div className="text-center">
            {loading && (
              <Spin
                spinning={loading}
                tip={loading ? `${lang.loadingMessages}...` : `${lang.loadingMoreMessages}...`}
              />
            )}
          </div>
          {!loading &&
            !error &&
            selectedLead &&
            selectedLead?.messages?.length > 0 &&
            selectedLead?.id &&
            selectedLead?.messages
              .sort((a, b) => {
                let aPostTime = 0;
                let bPostTime = 0;
                if (a?.dateSent) {
                  aPostTime = dayjs(a?.dateSent).valueOf();
                } else {
                  aPostTime = dayjs(a?.dateReceived).valueOf();
                }
                if (b?.dateSent) {
                  bPostTime = dayjs(b?.dateSent).valueOf();
                } else {
                  bPostTime = dayjs(b?.dateReceived).valueOf();
                }
                if (aPostTime < bPostTime) {
                  return -1;
                }
                if (aPostTime > bPostTime) {
                  return 1;
                }
                return 0;
              })
              .map((c, index) => (
                <Messages
                  key={index}
                  SetContentBy={SetContentBy}
                  c={c}
                  userIdMap={userIdMap}
                  selectedLead={selectedLead}
                  handleOpportunityId={handleOpportunityId}
                  handleRetry={handleRetry}
                />
              ))}
        </div>
        <div ref={messagesEndRef} />
      </div>
      <OptionContainer>
        <div className="flex gap-3 items-center">
          <div className="flex-1">
            <div className="bg-white p-[20px] w-full rounded-[10px] relative">
              <InputTextWrap
                className="p-3 w-full"
                id="text"
                ref={text}
                placeholder={`${lang.typeYourMessageHere}...`}
                name="text"
                onKeyUp={e => {
                  if (
                    e?.key === "Enter" &&
                    !isEmpty(text.current.value) &&
                    IsConentStatusEnable(
                      selectedLead?.textConsentStatus,
                      user?.company?.isOptinConsentMethod
                    )
                  ) {
                    const msg =
                      afterMessageConsent !== ""
                        ? text.current.value + ". " + afterMessageConsent
                        : text.current.value;
                    onClickHandler(msg, selectedLead, user?.id);
                  }
                }}
              />
            </div>
          </div>
          <div>
            <TooltipButton
              tooltip="Send SMS"
              type="primary"
              style={{ fontSize: "10" }}
              shape="circle"
              component={AirplaneIconWhite}
              alt="Send"
              onClick={() => {
                if (!isEmpty(text.current.value)) {
                  const msg =
                    afterMessageConsent !== ""
                      ? text.current.value + ". " + afterMessageConsent
                      : text.current.value;
                  onClickHandler(msg, selectedLead, user?.id);
                }
              }}
            />
          </div>
        </div>
      </OptionContainer>
    </div>
  );
};

export default ChatBotPopup;
