/* eslint-disable no-else-return */
import { useQuery } from "@apollo/client";
import { Form, Select } from "antd";
import { isEmpty, sortBy, uniqBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../amplify/authenticator-provider";
import { GET_FILTER_USERS } from "../../graphql/query";

export default function UserSelector({
  className,
  placeholder,
  handleUsersSelection = () => {
    console.log("Handler not defined");
  },
  allowClear,
  value,
  containerClassName = "w-full",
  name = "",
  showAll = true,
  multiple = true,
  disabled = false,
  errorMessage,
  validation = false,
  item = {},
  maxTagCount = "responsive",
  required = true,
}) {
  const { user } = useContext(UserContext);
  const [userList, setuserList] = useState([]);
  const { loading: userLoader } = useQuery(GET_FILTER_USERS, {
    fetchPolicy: "cache-first",
    variables: {
      companyId: user.company.id,
      isAllLocationUsers: true,
      isGlobalLeadOwner: true,
    },
    onCompleted: res => {
      setuserList(res.getUsers.data || []);
    },
  });
  useEffect(() => {
    if (!isEmpty(item.item)) {
      setuserList(uniqBy([...userList, item.item.user], "id"));
    }
  }, [item]);
  const handleUsers = e => {
    try {
      if (multiple) {
        if (e.includes("all")) {
          handleUsersSelection(userList.map(item => +item.id));
          // dispatch(setUserFilters(userList.map(item => +item.id)));
        } else {
          handleUsersSelection(e.map(item => +item));
          // dispatch(setUserFilters(e.map(item => +item)));
        }
      } else {
        handleUsersSelection(e);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleFilter = (inputValue, option) => {
    return `${option.children[0]} ${option.children[2]}`
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  };
  return (
    <div className={containerClassName}>
      {validation ? (
        <Form.Item
          rules={[
            { required: required, message: errorMessage || `Please select ${item.name} user` },
          ]}
          name={name || `leadOpportunityUsers-${item.id}`}
          label={item.name}
        >
          <Select
            showSearch
            name={name || `leadOpportunityUsers-${item.id}`}
            disabled={disabled}
            allowClear={allowClear}
            filterOption={handleFilter}
            mode={multiple ? "multiple" : ""}
            loading={userLoader}
            maxTagCount={maxTagCount}
            value={
              !multiple
                ? value
                : userLoader
                ? []
                : value?.length === userList?.length
                ? "all"
                : value
            }
            onClear={() => handleUsersSelection(null)}
            placeholder={placeholder || "Select users"}
            className={className || "w-full"}
            onChange={e => handleUsers(e)}
          >
            {showAll && <Select.Option value="all">All</Select.Option>}
            {sortBy(userList, "fullName").map((item, index) => (
              <Select.Option
                disabled={multiple ? value?.length === userList?.length : false}
                value={+item?.id}
                key={index}
              >
                {item.fullName} {!isEmpty(item?.companyRole) ? `(${item?.companyRole?.name})` : ""}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          name={name}
          disabled={disabled}
          allowClear={allowClear}
          filterOption={handleFilter}
          mode={multiple ? "multiple" : ""}
          loading={userLoader}
          maxTagCount={maxTagCount}
          value={
            !multiple ? value : userLoader ? [] : value?.length === userList?.length ? "all" : value
          }
          onClear={() => handleUsersSelection(null)}
          placeholder={placeholder || "Select users"}
          className={className || "w-full"}
          onChange={e => handleUsers(e)}
        >
          {showAll && <Select.Option value="all">All</Select.Option>}
          {sortBy(userList, "fullName").map((item, index) => (
            <Select.Option
              disabled={multiple ? value?.length === userList?.length : false}
              value={+item?.id}
              key={index}
            >
              {item?.fullName || ""}{" "}
              {!isEmpty(item?.companyRole) ? `(${item?.companyRole?.name})` : ""}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
}
