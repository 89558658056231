import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Drawer, Input, List, message, Radio, Spin, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { UserContext } from "../../../amplify/authenticator-provider";
import {
  GET_ENGAGEMENT_MESSAGE_TEMPLATES,
  GET_ENGAGEMENT_TEMPLATE_TEXT,
} from "../../../graphql/query";
import { AccessPermissionAction } from "../../../library/constants";
import { checkAccessPermission } from "../../../library/helpers";
import AntdIconV4 from "../../../utils/antdIcon";
import CustomButton from "../../commonComponents/button";
import CustomCheckbox from "../../commonComponents/checkbox";
import CreateTemplate from "./createTemplate";
import useLang from "../../../hooks/useLang";

const { Search } = Input;

const TemplateDrawer = ({ openTemplateDrawer, setOpenTemplateDrawer, onApply, leadId = null }) => {
  const createtemplate = checkAccessPermission(AccessPermissionAction.TEMPLATE_ADD_EDIT);
  const viewTemplate = checkAccessPermission(AccessPermissionAction.TEMPLATE_VIEW);
  const editTemplate = checkAccessPermission(AccessPermissionAction.TEMPLATE_EDIT);

  const [lang] = useLang();

  const { user } = useContext(UserContext);
  const [variables, setVariables] = useState({
    userId: user?.id || 0,
    companyId: user?.company?.id || null,
    isActive: true,
    templateType: "OWN",
  });
  const [template, settemplate] = useState({});
  const [activeFilter, setActiveFilter] = useState("own");
  const [isCreateTemplateSection, setIsCreateTemplateSection] = useState(false);
  const [isTemplateInEditMode, setIsTemplateInEditMode] = useState(false);
  const [isAPIneedToCall, setIsAPIneedToCall] = useState(1);
  const [data, setData] = useState([]);
  const {
    data: resp,
    loading: templateLoader,
    refetch: refetchTemplates,
  } = useQuery(GET_ENGAGEMENT_MESSAGE_TEMPLATES, {
    fetchPolicy: "network-only",
    variables: { ...variables },
    onCompleted: resp => {
      const data = (resp?.engagementMessageTemplates || []).map(el => ({
        ...el,
        checked: false,
      }));
      setData([...data]);
    },
  });
  const [getMessageText, { loading: msgLoading }] = useLazyQuery(GET_ENGAGEMENT_TEMPLATE_TEXT, {
    onCompleted: resp => {
      if (resp?.getEngagementTemplateText?.statusCode === 303) {
        message.error(lang.fillValueBeforeSend);
      } else if (resp.getEngagementTemplateText?.statusCode === 200) {
        message.success(resp?.getEngagementTemplateText?.message);
      } else {
        message.error(resp?.getEngagementTemplateText?.message);
      }
      const data = resp?.getEngagementTemplateText?.content || "";
      onApply(data);
      setOpenTemplateDrawer({ visible: false });
    },
    onError: err => {
      console.log(err);
    },
  });

  useEffect(() => {
    const data = (resp?.engagementMessageTemplates || []).map(el => ({
      ...el,
      checked: false,
    }));
    setData([...data]);
  }, [resp]);

  useEffect(() => {
    isAPIneedToCall > 1 && refetchTemplates();
  }, [isAPIneedToCall, variables]);

  const onDrawerClose = () => {
    setOpenTemplateDrawer({ visible: false });
  };

  const onSearchboxChange = e => {
    if (!isEmpty(e)) {
      const templates = data.filter(el => {
        return (
          el.title.toLowerCase().includes(e.toLowerCase()) ||
          el.message.toLowerCase().includes(e.toLowerCase())
        );
      });
      setData(templates);
    } else {
      const data = (resp?.engagementMessageTemplates || []).map(el => ({
        ...el,
        checked: false,
      }));
      setData(data);
    }
  };

  const onDrawerApply = () => {
    const obj = data.find(el => el.checked);
    if (obj) {
      if (leadId) {
        getMessageText({
          variables: {
            leadId: leadId,
            content: obj.message,
          },
        });
      } else {
        onApply(obj.message);
        setOpenTemplateDrawer({ visible: false });
      }
    }
  };

  const handleChange = id => {
    const templates = data.map(el => {
      return { ...el, checked: el.id === id };
    });
    setData(templates);
  };

  const hanndlFilterChange = e => {
    let newVariables = { ...variables };
    setIsTemplateInEditMode(false);
    switch (e.target.value) {
      case "all":
        newVariables = {
          ...newVariables,
          templateType: "ALL",
          companyId: user?.company?.id || null,
        };
        setIsCreateTemplateSection(false);
        break;
      case "own":
        newVariables = {
          ...newVariables,
          userId: user?.id || 0,
          templateType: "OWN",
          companyId: user?.company?.id || null,
          editTemplate: editTemplate,
        };
        setIsCreateTemplateSection(false);
        break;
      case "location":
        newVariables = {
          ...newVariables,
          companyId: user?.company?.id || null,
          templateType: "LOCATION",
          editTemplate: editTemplate,
        };
        setIsCreateTemplateSection(false);
        break;
      case "company":
        newVariables = {
          ...newVariables,
          companyId: user?.company?.id || null,
          templateType: "COMPANY",
          editTemplate: editTemplate,
        };
        setIsCreateTemplateSection(false);
        break;
      case "createTemplate":
        setIsCreateTemplateSection(prev => !prev);
        break;
      case "global":
        newVariables = {
          ...newVariables,
          companyId: user?.company?.id || null,
          templateType: "GLOBAL",
          editTemplate: editTemplate,
        };
        setIsCreateTemplateSection(false);
        break;
      default:
        break;
    }
    setVariables(newVariables);
    setActiveFilter(e.target.value);
  };

  const handleTemplateSection = () => {
    setIsCreateTemplateSection(false);
    const newVariables = {
      isActive: true,
      userId: user?.id || 0,
      templateType: "OWN",
      companyId: user?.company?.id || null,
    };
    setActiveFilter("own");
    setVariables(newVariables);
    setIsAPIneedToCall(prev => prev + 1);
  };

  const handleEdit = item => {
    setIsCreateTemplateSection(true);
    settemplate(item);
  };

  const handleEditTemplate = item => {
    handleEdit(item);
    setIsCreateTemplateSection(true);
    setActiveFilter("createTemplate");
    setIsTemplateInEditMode(true);
  };
  return (
    <Drawer
      title={lang.selectMessageTemplate}
      placement="right"
      closable={false}
      width="90%"
      onClose={onDrawerClose}
      open={openTemplateDrawer.visible}
    >
      <div className="block md:flex justify-between items-center">
        <div className="w-full flex flex-wrap items-center">
          <Spin
            spinning={templateLoader}
            className="fixed top-[50%] left-[50%] "
            style={{
              transform: "translateX(-50%) translateY(-50%)",
            }}
          />
          <b>{lang.filterBy}:</b>
          <Radio.Group
            value={activeFilter}
            buttonStyle="solid"
            style={{ marginLeft: 5 }}
            onChange={hanndlFilterChange}
          >
            {viewTemplate && <Radio.Button value="all">{lang.all}</Radio.Button>}
            <Radio.Button value="own">{lang.myTemplates}</Radio.Button>
            <Radio.Button value="company">{lang.companyTemplates}</Radio.Button>
            <Radio.Button value="location">Location Templates</Radio.Button>
            {viewTemplate && <Radio.Button value="global">{lang.globalTemplates}</Radio.Button>}
            <Radio.Button value="createTemplate">
              {isTemplateInEditMode ? lang.editTemplates : lang.createTemplates}
            </Radio.Button>
          </Radio.Group>
        </div>

        {!isCreateTemplateSection && (
          <div className="py-2 md:p-0">
            <Search
              onChange={e => onSearchboxChange(e.target.value)}
              placeholder={lang.searchTemplates}
              className="w-full"
            />
          </div>
        )}
      </div>
      {/* <InternalDivider /> */}
      <div className="mt-10">
        {isCreateTemplateSection ? (
          <CreateTemplate
            company={user.company}
            handleTemplateSection={handleTemplateSection}
            createtemplate={createtemplate}
            editTemplate={isTemplateInEditMode ? editTemplate : ""}
            setIsTemplateInEditMode={setIsTemplateInEditMode}
            template={isTemplateInEditMode ? template : ""}
            settemplate={settemplate}
            isTemplateInEditMode={isTemplateInEditMode}
          />
        ) : (
          <List
            grid={{
              column: 1,
            }}
            itemLayout="horizontal"
            size="small"
            dataSource={data}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <CustomCheckbox
                      key={`rdo__${item.id}`}
                      checked={item.checked}
                      handleChange={() => handleChange(item.id)}
                    />
                  }
                  title={
                    <div className="w-full flex justify-between">
                      <div>
                        <span className="text-[14px] font-medium">{item.title}</span>
                        {(createtemplate || variables.templateType === "OWN") &&
                          variables.templateType !== "GLOBAL" && (
                            <span style={{}}>
                              {" "}
                              <Tooltip placement="topLeft" title={lang.editTemplate}>
                                <AntdIconV4
                                  type="EditOutlined"
                                  onClick={() => handleEditTemplate(item)}
                                />
                              </Tooltip>
                            </span>
                          )}
                      </div>
                    </div>
                  }
                  description={item.message}
                />
              </List.Item>
            )}
          />
        )}
      </div>
      <div className="absolute bottom-[15px] right-[15px]">
        {!isCreateTemplateSection && (
          <CustomButton
            handleClick={onDrawerApply}
            type="primary"
            className="mr-[10px]"
            loading={msgLoading}
            btnText={lang.apply}
          />
        )}
        <CustomButton handleClick={onDrawerClose} btnText={lang.cancel} />
      </div>
    </Drawer>
  );
};

export default TemplateDrawer;
