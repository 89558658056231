/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
import Icon, { CloseCircleOutlined, UpOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, Checkbox, Input, Popover, Skeleton, Switch, Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import EngagedAI from "../../../../static/EngagedAi_trans_bg.svg";
import { UserContext } from "../../../amplify/authenticator-provider";
import { GET_AI_RESPONSE, GET_AI_SUGGESTION_QUICK_TIP } from "../../../graphql/query";

import { applicationUseCaseMapping, getApplicationUseCaseId } from "../../../library/utils";

import "./editor.css";

export function SuggestAMessageButton({
  createSuggestedResponse,
  misc,
  selectedLead,
  disabled,
  text,
  setinputText,
  setloaderFn,
}) {
  const popupRef = useRef(null);
  const { user } = useContext(UserContext);
  const [visibleCustomizedAiPopup, setVisibleCustomizedAiPopup] = useState(false);
  const [customizePromptData, setcustomizePromptData] = useState({
    visible: false,
    quickTips: [],
    tones: [],
    additionalNote: "",
  });

  const onOpenChange = nextOpen => {
    if (!nextOpen) {
      setVisibleCustomizedAiPopup(true);
      return;
    }
    setVisibleCustomizedAiPopup(nextOpen);
  };

  return (
    <Button
      key={selectedLead?.id}
      loading={misc.loading}
      className="text-lg flex bg-grey-200 hover:border-separate flex-row items-center justify-between p-1 gap-1"
      disabled={disabled}
      style={{
        borderWidth: "1px",
        borderColor: "black",
      }}
      // type="text"
    >
      <Tooltip title="Suggest message">
        <Icon
          component={EngagedAI}
          alt="SocialMiningAi"
          onClick={() => createSuggestedResponse()}
        />
      </Tooltip>
      <div className="h-[20px] w-[1px] bg-black" />
      <Tooltip title="Customize AI Suggestion">
        <Popover
          className=""
          overlayClassName="ai-prompt-popup"
          trigger={["click"]}
          onOpenChange={onOpenChange}
          open={visibleCustomizedAiPopup}
          content={
            <CustomizePrompt
              user={user}
              text={text}
              selectedLead={selectedLead}
              customizePromptData={customizePromptData}
              setcustomizePromptData={setcustomizePromptData}
              // popupRef={popupRef}
              setinputText={setinputText}
              setloaderFn={setloaderFn}
              setVisibleCustomizedAiPopup={setVisibleCustomizedAiPopup}
            />
          }
        >
          <UpOutlined
            className={`text-[#080707] scale-75 ${
              visibleCustomizedAiPopup ? "rotate-0" : "rotate-180"
            } transition duration-300`}
            // ref={popupRef}
            onClick={e => {
              // e.stopPropagation();
              e.preventDefault();
              setcustomizePromptData({ ...customizePromptData, visible: true });
              setVisibleCustomizedAiPopup(true);
            }}
            onBlur={e => {
              setVisibleCustomizedAiPopup(true);
            }}
          />
        </Popover>
      </Tooltip>
    </Button>
  );
}

const CustomizePrompt = ({
  setcustomizePromptData,
  customizePromptData,
  selectedLead,
  user,
  text,
  popupRef,
  setinputText,
  setloaderFn,
  setVisibleCustomizedAiPopup,
}) => {
  const Divider = () => <div className="w-full h-[2px] bg-grey-200 " />;
  const SectionHeader = ({ children }) => (
    <span className="text-sm font-[400] text-grey-700">{children}</span>
  );
  const Toggle = ({ children, onChange, value }) => (
    <div className="flex gap-1 flex-row items-center">
      <Switch onChange={onChange} checked={value} size="small" /> {children}
    </div>
  );
  const keywordMapping = {
    firstTip: "generate an appointment",
    secondTip: "give a reminder text",
    thirdTip: "make it short",
    firstTone: "Polite",
    secondTone: "Funny",
    thirdTone: "Formal",
  };
  const [dynamicTips, setdynamicTips] = useState([]);
  const { applicationUseCases } = useSelector(state => state.aiData);
  const [getAiResponse, { loading: getAiResponseLoader }] = useLazyQuery(GET_AI_RESPONSE, {
    nextFetchPolicy: "network-only",
  });
  useEffect(() => {
    setloaderFn(getAiResponseLoader);
  }, [getAiResponseLoader]);
  const { loading: quickTipLoader } = useQuery(GET_AI_SUGGESTION_QUICK_TIP, {
    variables: {
      companyId: user.company.id,
      locationIds: !sessionStorage.getItem("currentLocationId").includes(",")
        ? sessionStorage.getItem("currentLocationId")
        : null,
      applicationUseCaseId: getApplicationUseCaseId(
        applicationUseCases,
        applicationUseCaseMapping.ENGAGEMENT_MESSAGE_SUGGESTION
      )?.id,
    },
    onCompleted: res => {
      setdynamicTips(res?.getAiSuggestionQuickTip || []);
    },
  });
  const close = () => setVisibleCustomizedAiPopup(false);
  const getAiResponseHandler = async () => {
    text.current.placeholder = "Generating response...";
    text.current.value = "";
    text.current.style.height = "0px";
    text.current.focus();
    const promptData = { ...customizePromptData };
    delete promptData.visible;
    const payload = {
      aiSuggestionUserInput: { ...promptData },
      leadId: selectedLead.id,
      companyId: user.company.id,
      applicationUseCaseId: getApplicationUseCaseId(
        applicationUseCases,
        applicationUseCaseMapping.ENGAGEMENT_MESSAGE_SUGGESTION
      )?.id,
    };
    setloaderFn(true);
    const res = await getAiResponse({
      variables: payload,
    });
    const writeText = res?.data?.getAiResponse?.content || "No response recieved";
    setloaderFn(false);
    let index = 0;
    if (!isEmpty(writeText)) {
      typeCharacter();
    }
    function typeCharacter() {
      if (index < writeText.length) {
        if (!isEmpty(text?.current)) {
          text.current.style.height = `${text.current.scrollHeight}px`;
          if (isEmpty(text?.current?.value)) {
            console.log(writeText.charAt(index), index);
            text.current.value = writeText.charAt(index);
            console.log(text.current.value);
          } else {
            text.current.value += writeText.charAt(index);
          }
          console.log(text.current.value);
          index++;
          if (index === writeText.length) {
            setinputText(writeText);
            setloaderFn(false);
          }
          setTimeout(typeCharacter, 20); // Adjust the delay (in milliseconds) between characters here
        }
      }
    }
    // text.current.value
    close();
  };
  return (
    <div className="flex flex-col" onClick={e => e.stopPropagation()}>
      <div className="flex flex-row p-3 bg-[#F1F5F9] justify-between">
        <span className="text-sm font-[600] text-grey-700 ">Customize AI Suggestion</span>
        <CloseCircleOutlined onClick={() => close()} className="cursor-pointer" />
      </div>
      <div className="flex flex-col py-2 px-4 items-start ">
        <SectionHeader>Quick Tips</SectionHeader>
        <div className="flex flex-row flex-wrap gap-1">
          {quickTipLoader ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            dynamicTips.map((item, index) => (
              <Toggle
                key={index}
                onChange={checked => {
                  if (checked) {
                    setcustomizePromptData(prev => {
                      const temp = { ...prev };
                      temp.quickTips.push(item.tip);
                      return temp;
                    });
                  } else {
                    setcustomizePromptData(prev => {
                      const temp = structuredClone(prev);
                      const index = temp.quickTips.indexOf(item.tip);
                      temp.quickTips.splice(index, 1);
                      return temp;
                    });
                  }
                }}
                value={customizePromptData.quickTips.includes(item.tip)}
              >
                {item.tip}
              </Toggle>
            ))
          )}
        </div>
      </div>
      <Divider />
      <div className="flex flex-col py-2 px-4 items-start">
        <SectionHeader>Tone</SectionHeader>
        <div className="flex flex-row flex-wrap gap-1">
          <Toggle
            onChange={e => {
              setcustomizePromptData(prev => {
                const temp = structuredClone(prev);
                if (e) {
                  temp.tones = [keywordMapping.firstTone];
                } else {
                  temp.tones = temp.tones.filter(el => el !== keywordMapping.firstTone);
                }
                return temp;
              });
            }}
            value={customizePromptData?.tones?.includes(keywordMapping?.firstTone)}
          >
            Polite
          </Toggle>
          <Toggle
            onChange={e => {
              setcustomizePromptData(prev => {
                const temp = structuredClone(prev);
                if (e) {
                  temp.tones = [keywordMapping.secondTone];
                } else {
                  temp.tones = temp.tones.filter(el => el !== keywordMapping.secondTone);
                }
                return temp;
              });
            }}
            value={customizePromptData?.tones?.includes(keywordMapping?.secondTone)}
          >
            Funny
          </Toggle>
          <Toggle
            onChange={e => {
              setcustomizePromptData(prev => {
                const temp = structuredClone(prev);
                if (e) {
                  temp.tones = [keywordMapping.thirdTone];
                } else {
                  temp.tones = temp.tones.filter(el => el !== keywordMapping.thirdTone);
                }
                return temp;
              });
            }}
            value={customizePromptData?.tones?.includes(keywordMapping?.thirdTone)}
          >
            Formal
          </Toggle>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col py-2 gap-1 px-4 items-start">
        <SectionHeader>Additional Notes</SectionHeader>
        <Input
          value={customizePromptData.additionalNote}
          onChange={e =>
            setcustomizePromptData({ ...customizePromptData, additionalNote: e.target.value })
          }
          placeholder="Type some notes..."
        />
        {/* <Checkbox
          checked={customizePromptData.save}
          onChange={e => setcustomizePromptData({ ...customizePromptData, save: e.target.checked })}
        >
          Save these options
        </Checkbox>
        {customizePromptData.save && (
          <Input
            value={customizePromptData.filterName}
            onChange={e =>
              setcustomizePromptData({ ...customizePromptData, filterName: e.target.value })
            }
            placeholder="Enter preset customize name"
          />
        )} */}
        <Button
          loading={getAiResponseLoader}
          onClick={getAiResponseHandler}
          type="primary"
          className="w-full rounded-sm"
        >
          Regenerate
        </Button>
      </div>
    </div>
  );
};
