/* eslint-disable operator-assignment */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unreachable */
/* eslint-disable no-else-return */
/* eslint-disable semi */
/* eslint-disable prettier/prettier */
/* eslint-disable default-param-last */
/* eslint-disable no-use-before-define */

import Icon, {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseCircleFilled,
  FileGifOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Badge,
  Button,
  Col,
  Collapse,
  Drawer,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Tabs,
  Tooltip,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import { isEmpty, isUndefined, uniq, uniqBy } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EngagedAI from "../../../../static/EngagedAi_trans_bg.svg";
import CloseIconForTranslation from "../../../../static/icons/CloseIconForTranslation.svg";
import TranslateIcon from "../../../../static/icons/Translate.svg";
import { UserContext } from "../../../amplify/authenticator-provider";
import {
  BROADCAST_UNREAD_COUNT,
  CREATE_LEAD_EMAIL,
  CREATE_LEAD_PHONE,
  CREATE_MESSAGE,
  CREATE_MMS,
  RETRY_MESSAGE_SEND,
  TRANSLATE_MESSAGE,
  UPDATE_LEAD,
  UPDATE_LEAD_CONSENT,
  UPDATE_LEAD_CONVERSATION_STATUS,
  UPDATE_LEAD_EMAIL,
  UPDATE_LEAD_PHONE,
  UPDATE_TRANSLATION_SETTINGS,
} from "../../../graphql/mutation";
import {
  EDIT_LEAD,
  GET_ALL_TEAMS,
  GET_ALL_USERS_ACTIVITY_CENTER,
  GET_CAMPAIGN_PAGINATED_LIST,
  GET_COMPANY_AFTER_MESSAGE_CONSENT_TEMPLATE,
  GET_COMPANY_CONSENT,
  GET_COMPANY_CONSENT_TEMPLATES,
  GET_CONSENT_TEMPLATE,
  GET_CRM_LEAD_STATUS,
  GET_EXTENSION_LEAD_SOURCES,
  GET_EXTENSION_LIST_ACTIVITY_CENTER,
  GET_GENERIC_FILTERS,
  GET_LEAD_ACTIVE_APPOINTMENT,
  GET_LEAD_DETAIL_ACTIVITY_CENTER,
  GET_MESSAGES,
  GET_MY_COMPANY,
  GET_SCHEDULED_MESSAGES_FOR_LEAD,
  GET_SOCIAL_REVIEW_SETTINGS,
  GET_SUPPORTED_LANGUAGES,
  GET_TRANSLATION_SETTINGS,
} from "../../../graphql/query";
import { useInterval } from "../../../hooks";
import useCrmIntegration from "../../../hooks/useCrmIntegration";
import useLang from "../../../hooks/useLang";
import LeftIcon from "../../../icons/js/LeftIcon";
import RightIcon from "../../../icons/js/RightIcon";
import SummaryIcon from "../../../icons/js/SummaryIcon";
import TimerIcon from "../../../icons/js/TimerIcon";
import { SVGIcon } from "../../../library/basicComponents";
import { colorConfig } from "../../../library/colorConfig";
import { AccessPermissionAction, MESSAGE_CONTENT_TYPES } from "../../../library/constants";
import {
  checkAccessPermission,
  closeDialog,
  openDialog,
  showConfirmationModal,
} from "../../../library/helpers";
import {
  getCacheApi,
  resetApiCacheStore,
  setAfterMessageText,
  setAllCampaigns,
  setCompanyConsentTemplate,
  setConsentTemplateSlice,
  setCrmLeadStatus,
  setLeadSources,
} from "../../../redux/slices/apiCacheSlice";
import { getMobileView } from "../../../redux/slices/isMobile";
import {
  addMessage,
  addQuickFilter,
  getUpdatedCountInfo,
  setLeadsRedux,
  setScheduledMessages,
  setSearchRedux,
  setSelectedLeadIdRedux,
  setSelectedLeadRedux,
} from "../../../redux/slices/leadSlice";
import { getLocationId, setLocationIdSlice } from "../../../redux/slices/locationSlice";
import { toTitleCase, parsePhoneNumber } from "../../../utils";
import AntdIconV4 from "../../../utils/antdIcon";
import { s3uploader } from "../../../utils/awsS3MediaUploader";
import { generateVideoThumbnails } from "../../../utils/videoThumbnailGenerator";
import CustomButton from "../../commonComponents/button";
import CustomDivider from "../../commonComponents/divider";
import CustomLabel from "../../commonComponents/label";
import TemplateDrawer from "../schedual/templateDrawer";
import { BottomActions } from "./BottomActions";
import { InputArea } from "./InputArea";
import AppointmentModal from "./appointments/appointmentModal";
import Attachments from "./attachments";
import ConversationSummary from "./conversationSummary";
import "./editor.css";
import {
  LeadStatus,
  SetContentBy,
  ShowConsent,
  isConsentStatusEnable,
  leadOwners,
  vehicles,
} from "./engagementHelpers";
import LeadFilterBreadcrumbs from "./leadFilterBreadcrumbs";
import LeadListContainer from "./leadListContainer";
import LeadProfile from "./leadProfile";
import Messages from "./messages";
import OpportunityFilter from "./opporotunityLayer/opportunityFilter";
import InfoIcon from "../../../../static/icons/info.svg";

const customizeRenderEmpty = lang => (
  <div style={{ textAlign: "center", color: "lightgray" }}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p style={{ color: "lightgray" }}>{lang.emptyMessageMsg}</p>
  </div>
);

export const leadStatus = [
  { id: "RESPONDED", name: "Awaiting Your Response" },
  { id: "WAITING_FOR_REPLY", name: "Awaiting Lead Response" },
  { id: "UNCONTACTED", name: "Uncontacted" },
  { id: "ENGAGED", name: "Engaged" },
  { id: "MY_CONVERSATIONS", name: "My Conversations" },
  { id: "NO_RESPONSE_NEEDED", name: "No Response Needed" },
];

export const ConversationPopover = styled(Popover)`
  display: flex;
`;
export const InputTextWrap = styled.input`
  background: rgb(245, 245, 245) !important;
  border: none !important;
  color: #000000;
  width: 95%;

  ::placeholder {
    color: ${props => props?.placeholderStyle?.color};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${props => props?.placeholderStyle?.color};
  }

  ::-ms-input-placeholder {
    color: ${props => props?.placeholderStyle?.color};
  }

  &:hover {
    outline: none !important;
    border: none !important;
  }
  &:focus {
    outline: none !important;
    border: none !important;
    border-right-width: 0 !important;
    background: rgb(245, 245, 245) !important;
  }
  &:selected {
    background: rgb(245, 245, 245) !important;
  }
`;

export const InputTextAreaWrap = styled.textarea`
  background: ${props => {
    if (props.styling.background === "#EF4444") {
      return "#CBD5E1";
    }
    return "rgb(245, 245, 245) !important";
  }};
  cursor: ${props => {
    if (props.styling.background === "#EF4444") {
      return "not-allowed ";
    }
  }};
  border-radius: 4px;
  border: ${`1px solid ${colorConfig.greyLight}`};
  color: #000000;
  min-height: 64px;
  resize: vertical;
  width: 100%;
`;
export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1120px) {
    flex-direction: column;
  }
`;
export const IconMobile = styled(Icon)`
  font-size: 24px;
  margin: 0 5px;
`;

export const pageSize = 20;
let page = 1;
let lastLead = "";

export const handleFiles = async (
  attachments,
  user,
  selectedLead,
  fetchData,
  setMediaUploadLoader
) => {
  try {
    const urlObj = [];
    const handleFilesURL = async (file, index) => {
      if (file) {
        let thumbnailUrl = "";
        if (file.file.type.includes("video")) {
          thumbnailUrl = await fetchData(file.file, file.file.type);
        }
        let media = { url: file.preview };
        if (file.generateLink) {
          media = await s3uploader(
            file.file,
            process.env.GATSBY_S3_BUCKET,
            state => {
              setMediaUploadLoader(state);
            },
            false,
            sessionStorage.getItem("currentLocationId")?.includes(",")
              ? "all"
              : sessionStorage.getItem("currentLocationId"),
            user,
            selectedLead,
            index + 1
          );
        }
        urlObj.push({
          // compressedMediaUrl: media.url,
          size: file.file.size || 0,
          mediaType: file.file.type,
          mediaUrl: media.url,
          thumbnail: thumbnailUrl,
          // compressedThumbnail: thumbnailUrl,
        });
      }
    };

    for (let k = 0; k < attachments.length; k++) {
      // eslint-disable-next-line no-await-in-loop
      await handleFilesURL(attachments[k], k);
    }
    return urlObj;
  } catch (err) {
    console.log(err);
  }
};
export const handleMediaEvent = async (e, attachments, setAttachments) => {
  const fileType = "";
  const { files } = e.target;
  const filesarr = [];
  if (e.target.files.length === 0) return;
  for (let i = 0; i < e.target.files.length; i++) {
    filesarr.push(e.target.files[i]);
  }
  console.log(files);
  if (filesarr.length > 0) {
    console.log("filesarr", filesarr);
    setAttachments([
      ...attachments,
      ...filesarr.map(item => {
        return {
          file: item,
          preview: URL.createObjectURL(item),
          generateLink: true,
        };
      }),
    ]);
  }
};
const EngagementActivity = props => {
  const {
    queryString,
    lead,
    campaignFilters,
    leadPresetFilter = {},
    isFromCampaignToActivity = false,
    onFullScreen,
    onSetfull,
    isFirstLeadSet,
    onUnpinLead = () => {},
  } = props;
  const [lang] = useLang();
  const dispatch = useDispatch();
  const { user, setMaximizeScreen, notificationData, socketMsgSelectedLead, modal } =
    useContext(UserContext);
  const [isReview, setisReview] = useState(false);
  const [summaryVisible, setSummaryVisible] = useState({ visible: false, count: 0 });
  const text = useRef("");
  const messagesEndRef = useRef(null);
  const msgContainerRef = useRef(null);
  const mediaRef = useRef(null);
  const videoRef = useRef(null);
  const documentRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  const paramLeadId = urlParams.get("leadId");
  const presetLeadFilter = urlParams.get("presetFilter");
  const locaitonLocalId = localStorage.getItem("changedLocationLeadId");
  const isUserBDC = user?.companyRole?.name?.toLowerCase().includes("bdc");

  let concentTime;
  let timeReference;
  const prevSortOrderRef = useRef();
  const prevStatusRef = useRef();
  const locationIdslice = useSelector(state => getLocationId(state));
  const leadSourcesReduxData = useSelector(state => getCacheApi(state, "leadSources"));
  const crmLeadStatusReduxData = useSelector(state => getCacheApi(state, "crmLeadStatus"));
  const userListReduxData = useSelector(state => getCacheApi(state, "userList"));
  const actionDispatchTime = useSelector(state => getCacheApi(state, "expiryTime"));
  const leadCountUpdatedData = useSelector(state => getUpdatedCountInfo(state));
  const companyConsentReduxData = useSelector(state =>
    getCacheApi(state, "companyConsentTemplateEvent")
  );
  const { selectedLead, leads: leadList } = useSelector(state => state.lead);
  const { leadScheduledMessages: scheduledMessages } = useSelector(state => state?.lead);
  const afterMessageFromRedux = useSelector(state => getCacheApi(state, "afterMessageText"));
  const consentTemplateFromRedux = useSelector(state => getCacheApi(state, "consentTemplate"));
  const allCampaignsReduxData = useSelector(state => getCacheApi(state, "allCampaigns"));

  const [entityId, setEntityId] = useState(
    urlParams.get("entityId")?.split("?")[0] !== "remove"
      ? urlParams.get("entityId")?.split("?")[0]
      : ""
  );
  const [textMode, settextMode] = useState(0);
  const [eventId, setEventId] = useState(
    urlParams.get("eventId")?.split("?")[0] !== "remove"
      ? urlParams.get("eventId")?.split("?")[0]
      : ""
  );

  const [leadIdClick, setLeadIdClick] = useState("");
  const [showSidebar, setShowSideBar] = useState(true);
  const [showDetails, setShowDetails] = useState(true);
  const [richTextValue, setrichTextValue] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  // const [setSelectedLead] = useState(lead || null);

  // const [selectedLeadId, setSelectedLeadId] = useState(lead?.id || "");
  const [showReviewRequestButton, setShowReviewRequestButton] = useState(true);
  const location = window.location.pathname;
  const [searchKey, setSearchKey] = useState(queryString !== "remove" ? queryString : ""); // location.split("/")[3]
  const [activeOpp, setActiveOpp] = useState("#DEFAULTOPPID");
  const [activeOppName, setActiveOppName] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [callingScreen, setCallingScreen] = useState({
    visible: false,
    lead: {},
    recording: false,
  });
  const [opportunityList, setOpportunityList] = useState([
    { filterId: null, id: "#DEFAULTOPPID", name: "All" },
  ]);
  const [filterLastId, setFilterLastId] = useState("");
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const { leadSort } = useSelector(state => state.lead);
  const [videoThumbnails, setVideoThumbnails] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [showLeadSection, setShowLeadSection] = useState(window.innerWidth > 1024);
  const [showProfileSection, setShowProfileSection] = useState(window.innerWidth > 1024);
  const [activeLeadId, setActiveLeadId] = useState(localStorage.getItem("changedLocationLeadId"));
  const [changeState, setChangeState] = useState(true);
  const [leadCount, setLeadCount] = useState(0);
  const [openCRMModal, setPushToCrmModalVisible] = useState(false);
  const [openAppointmentDrawer, setOpenAppointmentDrawer] = useState(false);
  const [openAddModal, setAddPhoneModalVisible] = useState(false);
  const [openSendReviewModal, setSendReviewVisible] = useState(false);
  // const crmIntegration = useCrmIntegration();
  const crmIntegration = user?.company?.crmIntegrationType;
  const [crmIntegrationData, setcrmIntegrationData] = useState("");
  const [openSetReviewTemplateUploadModal, setReviewTemplateUploadVisible] = useState(false);
  const [openTemplateModal, setTemplateModal] = useState({ visible: false });
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [refetchAppointment, setRefetchAppointment] = useState(false);
  const [inlineField, setInlineEditField] = useState("");
  const [openEditUserPhone, setOpenEditUserPhone] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [isTranslationForCustomer, setIsTranslationForCustomer] = useState(false);
  const [isTranslationForEmployee, setIsTranslationForEmployee] = useState(false);
  const [targetLangForCustomer, setTargetLangForCustomer] = useState("");
  const [targetLangForEmployee, setTargetLangForEmployee] = useState("");
  const [languages, setLanguages] = useState();
  const [isAutoTranslate, setIsAutoTranslate] = useState(false);
  const [isIncludeOriginalMessage, setIsIncludeOriginalMessage] = useState(false);
  const [isTranslatePreviewShow, setIsTranslatePreviewShow] = useState(false);
  const activeLeadFilterReduxData = useSelector(state => getCacheApi(state, "activeLeadFilter"));
  const [messageFilters, setMessageFilters] = useState({
    campaignIds: isFromCampaignToActivity ? [] : campaignFilters || [],
    leadId: +selectedLead?.id,
    lastId: lastLead,
    page: 1,
    pageSize,
  });
  const [messageText, setmessageText] = useState("");
  const [globalMessageData, setGlobalMessageData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const {
    quickFilters: selectedQuickFilters,
    addMyConversation,
    userFilters,
    campaignFilter,
  } = useSelector(state => state.lead.filters);
  const [isMsgScroll, setIsMsgScroll] = useState(false);
  const [disableConcentClick, setDisableConcentClick] = useState(true);
  const [isMsgApi, setIsMsgApi] = useState(false);
  const [hideBottom, setHideBottom] = useState(true);
  const [isTranslateClick, setIsTranslateClick] = useState(false);
  const [stopLoaderForReceivedMessage, setStopLoaderForReceivedMessage] = useState(true);
  const { search } = useSelector(state => state.lead);
  const [variables, setVariables] = useState({
    source: [],
    sourceOriginal: [],
    combinedSource: [],
    voi: [],
    page: 1,
    pageSize: 25,
    orderBy: "latest_chat",
    orderDirection: "desc",
    status: uniq([...selectedQuickFilters, ...selectedStatus]),
    campaignIds: campaignFilters || [],
    dsEntityId: entityId,
    dsEventId: eventId,
  });
  const [apiCheck, setApiCheck] = useState(false);
  const [sourcesData, setSourcesData] = useState({});
  const [company, setCompany] = useState(null);
  const [reviewInfo, setReviewInfo] = useState(null);
  const [consent, setConsent] = useState([]);
  const [isClickonPotentialDuplicate, setIsClickonPotentialDuplicate] = useState(false);
  const [consentTemplate, setConsentTemplate] = useState("");
  const [afterMessageConsent, setAfterMessageConsent] = useState("");
  const [onlyFirstMessage, setonlyFirstMessage] = useState("");
  const [isActive, setisActive] = useState(null);
  const [selectedLeadID, setSelectedLeadID] = useState("");
  const [connection, setConnection] = useState({});
  const [callConsentMessage, setCallConsentMessage] = useState(false);
  const [openAppointmentPopup, setAppointmentPopupVisible] = useState(false);
  const { socket, isFullScreen } = useContext(UserContext);
  const [isPopupNeedToOpen, setIsPopupNeedToOpen] = useState(false);
  const [statusList, setstatusList] = useState([]);
  const [isAppointmentChangeFromLead, setIsAppointmentChangeFromLead] = useState(false);
  const [appintmentLead, setAppointmentLead] = useState({});
  const [implicit, setimplicit] = useState(
    consent.find(item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_OFF")?.isActive
  );
  const [explicit, setexplicit] = useState(
    consent.find(item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_ON")?.isActive
  );
  const [cosentAtEnd, setcosentAtEnd] = useState(isActive);
  const [btnClicked, setbtnClicked] = useState(false);
  const [mediaUploadLoader, setMediaUploadLoader] = useState(false);
  const [hideToolbar, sethideToolbar] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [consentData, setconsentData] = useState(false);
  const [consentMessage, setConsentMessage] = useState("");
  const [recording, setRecording] = useState({ visible: false, lead: {} });
  const [userIdMap, setUserIdMap] = useState({});
  const [leadListDrawer, setLeadListDrawer] = useState(false);
  const [leadProfileDrawer, setLeadProfileDrawer] = useState(false);
  const isMobileView = useSelector(state => getMobileView(state));
  const [mmsLoader, setmmsLoader] = useState(false);
  const [visibleTranslationSettings, setVisibleTranslationSetting] = useState(false);
  const canEdit = checkAccessPermission(AccessPermissionAction.LEAD_ADD_EDIT);
  const canViewTranslation = checkAccessPermission(
    AccessPermissionAction.TRANSLATION_MANAGEMENT_VIEW
  );

  const handleEditLead = lead => {
    if (!isEmpty(lead)) {
      editLeadDetails({
        variables: { leadId: lead.id },
      });
    }
  };
  const setSearch = search => {
    dispatch(setSearchRedux(search));
  };
  const handleToasterSelectedLead = () => {
    handleLeadDetailProfile(socketMsgSelectedLead);
  };
  const setSelectedLead = lead => {
    if (!isEmpty(lead)) {
      dispatch(setSelectedLeadRedux({ ...selectedLead, ...lead }));
    } else {
      dispatch(setSelectedLeadRedux({}));
    }
  };
  const setSelectedLeadId = id => {
    dispatch(setSelectedLeadIdRedux(id));
  };
  const setLeadList = list => {
    dispatch(setLeadsRedux(list));
  };
  const handleCallingScreen = () => {
    setCallingScreen(!callingScreen);
  };
  useEffect(() => {
    setSearch(queryString !== "undefined" && queryString !== "remove" ? queryString : "");
  }, [queryString]);
  const handleMediaSharing = mediaType => {
    if (mediaType === "image") {
      mediaRef.current.click();
    } else if (mediaType === "video") {
      videoRef.current.click();
    } else {
      documentRef.current.click();
    }
  };

  // const call = async selectedLead => {
  //   try {
  //     const callObj = await twilioDevice.connect({
  //       params: {
  //         To: selectedLead?.phoneNumbers[0]?.phone,
  //       },
  //       rtcConstraints: {
  //         audio: true,
  //       },
  //     });
  //     if (callObj) {
  //       setCallingScreen({ ...callingScreen, visible: true, lead: selectedLead, callObj: callObj });
  //     }
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  const fetchData = async (files, fileType) => {
    if (fileType.includes("video" || "quicktime")) {
      try {
        const response = await generateVideoThumbnails(files, 2);
        setVideoThumbnails(response[0]);
        const thumbnailUrl = await s3uploader(
          response,
          process.env.GATSBY_S3_BUCKET,
          state => {},
          true,
          sessionStorage.getItem("currentLocationId")?.includes(",")
            ? "all"
            : sessionStorage.getItem("currentLocationId"),
          user,
          selectedLead
        );
        return thumbnailUrl.url;
      } catch (e) {
        console.log("error in generateVideoThumbnails", e);
      }
    }
  };

  const handleOppFilter = oppValue => {
    setActiveOpp(oppValue);
  };

  const setOppDropdown = () => {
    const OppId = opportunity => {
      if (crmIntegration === "EL") {
        const elObject =
          opportunity?.elLeadOpportunities?.length > 0 && opportunity?.elLeadOpportunities[0];
        return `${elObject.elOpportunityId || ""}`;
      } else if (crmIntegration === "DS") {
        const dsObject =
          opportunity?.dsOpportunityEvents?.length > 0 && opportunity?.dsOpportunityEvents[0];
        return `${dsObject.dsEventId || ""}`;
      } else if (crmIntegration === "SF") {
        const sfLeadOpportunity =
          opportunity?.sfLeadOpportunities?.length > 0 && opportunity?.sfLeadOpportunities[0];
        return `${sfLeadOpportunity.sfOpportunityId || ""}`;
      } else if (crmIntegration === "DT") {
        const dtLeadOpportunity =
          opportunity?.dtLeadOpportunities?.length > 0 && opportunity?.dtLeadOpportunities[0];
        return `${dtLeadOpportunity.dtOpportunityId || ""}`;
      }
    };
    const oppoDropdown =
      selectedLead?.leadOpportunities?.length > 0 &&
      selectedLead?.leadOpportunities.map(opp => {
        return {
          id: opp.id,
          name: opp.name,
          opprId: OppId(opp),
          updatedDate: opp.updatedDate,
        };
      });

    if (oppoDropdown.length > 0) {
      oppoDropdown.push({
        id: "#DEFAULTOPPID",
        name: "All",
      });
    }

    setOpportunityList([...oppoDropdown]);
  };

  const updateMobileResponsive = () => {
    setShowLeadSection(window.innerWidth > 1024);
    setShowProfileSection(window.innerWidth > 1024);
  };

  const onClickHandler = (content, lead, userId, button = false) => {
    if (!isEmpty(content) && !isEmpty(lead)) {
      if (!isEmpty(lead?.phoneNumbers)) {
        const messagePayload = {
          leadId: +lead?.id,
          userId: user.id,
          user: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
          },
          direction: "TO_LEAD",
          content: content,
          toPhone: lead?.phoneNumbers[0]?.phone,
          dateSent: dayjs().utc().toISOString(),
          ...(isReview
            ? { messageType: "REVIEW", contentType: MESSAGE_CONTENT_TYPES.REVIEW_REQUEST_SENT }
            : {}),
          ...(activeOppName?.id && activeOppName.id[0] !== "#"
            ? {}
            : { leadOpportunityId: +activeOppName.id }),
          ...(eventId ? { dsEventId: eventId } : {}),
        };
        let selectedLeadDetails = {
          ...selectedLead,
          textConsentStatus: lead?.textConsentStatus || selectedLead?.textConsentStatus,
        };
        if ((selectedLead?.messages || []).length <= 0) {
          selectedLeadDetails = { ...selectedLeadDetails, messages: [] };
        }
        const temp = structuredClone(selectedLeadDetails);
        if (!isReview) {
          temp.messages.push({
            ...messagePayload,
            type: "new",
          });
        }
        selectedLeadDetails = structuredClone(temp);
        selectedLeadDetails = {
          ...selectedLeadDetails,
          messages: selectedLeadDetails.messages.filter(item => item !== undefined),
        };
        page = 1;
        // setSelectedLead(selectedLeadDetails);
        setrichTextValue("");
        createMessage({
          variables: {
            ...messagePayload,
          },
        }).then(res => {
          textMode === 0 ? (text.current.value = "") : setrichTextValue("");
          text.current.value = "";
          text.current.style.height = "0px";
          setmessageText("");
          setCallConsentMessage(false);
          if (res?.data?.createMessage?.statusCode === 200) {
            const selectedLeadIndex = (leadList || []).findIndex(
              lead => lead?.id === selectedLead?.id
            );

            if (selectedLeadIndex > -1) {
              const arrayFn = () => {
                const newState = structuredClone(leadList);
                newState[selectedLeadIndex] = {
                  ...newState[selectedLeadIndex],
                  unreadCount: 0,
                };
                return newState;
              };
              setLeadList(arrayFn());
            }
            dispatch(
              addMessage({ id: selectedLead.id, messages: { ...messagePayload, type: "new" } })
            );
            if (
              !isConsentStatusEnable(
                selectedLead?.textConsentStatus,
                user?.company?.isOptinConsentMethod
              )
            ) {
              updateLeadConsent({
                variables: { leadId: selectedLead?.id },
              });
            }

            const resMessage = res?.data?.createMessage?.leadMessage || {};
            const leadInfo = structuredClone(selectedLeadDetails);
            if (!isReview) {
              const findIndex = leadInfo.messages.findIndex(el => el?.type === "new");
              if (findIndex > -1) {
                leadInfo.messages[findIndex] = {
                  ...resMessage,
                  leadOpportunity: resMessage?.leadOpportunity || { name: "", id: "" },
                };
              }
            } else {
              leadInfo.messages.push(resMessage);
            }

            setSelectedLead({ ...leadInfo });
            setChangeState(!changeState);
            setbtnClicked(true);
          } else {
            message.error(res?.data?.createMessage?.message || lang.msgSendFail);
            const leadInfo = structuredClone({ ...selectedLeadDetails });
            const findIndex = leadInfo.messages.findIndex(el => el?.type === "new");
            if (findIndex > -1) {
              leadInfo.messages[findIndex].type = "error";
            }
            setSelectedLead({ ...leadInfo });
            setChangeState(!changeState);
          }
          if (!selectedLead?.textConsentStatus) {
            getLeadDetail({ variables: { leadId: selectedLead?.id } });
          }
          setisReview(false);
        });
      } else {
        message.error("No phone number registered for this lead");
        text.current.style.height = "0px";
      }
    } else {
      message.error("Please enter a message");
      text.current.value = "";
      text.current.style.height = "0px";
    }
  };

  useQuery(GET_SCHEDULED_MESSAGES_FOR_LEAD, {
    fetchPolicy: "network-only",
    variables: {
      leadId: selectedLead?.id,
    },
    skip: !selectedLead?.id,
    onCompleted: res => {
      dispatch(setScheduledMessages([...res?.getScheduledMessagesForLead]));
    },
  });
  useEffect(() => {
    if (!isEmpty(lead) && !Array.isArray(lead)) {
      setSelectedLead(lead);
      setSelectedLeadId(lead.id);
    }
    if (Array.isArray(lead) && lead?.length > 0) {
      setSelectedLead(lead[0]);
      setSelectedLeadId(lead[0].id);
    }
  }, [lead]);

  useEffect(() => {
    if (leadCountUpdatedData?.lead_id) {
      const selectedLeadIndex = (leadList || []).findIndex(
        lead => +lead?.id === +leadCountUpdatedData?.lead_id
      );
      if (selectedLeadIndex > -1) {
        const array = () => {
          const newState = structuredClone(leadList);
          newState[selectedLeadIndex] = {
            ...newState[selectedLeadIndex],
            unreadCount: leadCountUpdatedData.unread_count,
          };
          return newState;
        };
        setLeadList(array());
      }
    }
  }, [leadCountUpdatedData]);

  const onClickReviewHandler = (content, reviewLink, lead, userId, messageType = "NORMAL") => {
    if (!isEmpty(content)) {
      let messagePayload = {
        leadId: lead?.id,
        userId: userId,
        direction: "TO_LEAD",
        content: content,
        toPhone: lead?.phoneNumbers[0]?.phone,
        reviewLink,
        dateSent: dayjs().toISOString(),
        messageType,
      };
      let selectedLeadDetails = {
        ...selectedLead,
        textConsentStatus: lead?.textConsentStatus || selectedLead?.textConsentStatus,
      };
      if ((selectedLead?.messages || []).length <= 0) {
        selectedLeadDetails = { ...selectedLeadDetails, messages: [] };
      }

      page = 1;
      setSelectedLead(selectedLeadDetails);
      textMode === 0 ? (text.current.value = "") : setrichTextValue("");
      if (eventId) {
        messagePayload = { ...messagePayload, dsEventId: eventId };
      } else {
        delete messagePayload.dsEventId;
      }
      const res = createMessage({
        variables: {
          ...messagePayload,
        },
      }).then(res => {
        if (res?.data?.createMessage?.statusCode === 200) {
          const selectedLeadIndex = (leadList || []).findIndex(
            lead => lead?.id === selectedLead?.id
          );
          if (selectedLeadIndex > -1) {
            const arrayFn = () => {
              const newState = structuredClone(leadList);
              newState[selectedLeadIndex] = {
                ...newState[selectedLeadIndex],
                unreadCount: 0,
              };
              return newState;
            };
            setLeadList(arrayFn());
          }
          const resMessage = res?.data?.createMessage?.leadMessage || {};
          console.log("🚀 ~ onClickReviewHandler ~ resMessage:", resMessage);
          const id = parseInt(resMessage?.id);
          messagePayload.content = resMessage?.content;
          selectedLeadDetails.messages.push({
            ...messagePayload,
            ...resMessage,
            type: "new",
            messageType: "Review",
            contentType: MESSAGE_CONTENT_TYPES.REVIEW_REQUEST_SENT,
          });
          setSelectedLead(selectedLeadDetails);
          //

          const leadDetails = structuredClone({ ...selectedLeadDetails });
          const findIndex = leadDetails.messages.findIndex(el => el?.type === "new");
          if (findIndex > -1) {
            leadDetails.messages[findIndex] = { ...resMessage };
            // leadDetails.messages[findIndex].type = "";
            // leadDetails.messages[findIndex].id = resMessage?.id;
            // leadDetails.messages[findIndex].dateSent = resMessage?.dateSent;
            // leadDetails.messages[findIndex].messageStatus = resMessage?.messageStatus;
            // leadDetails.messages[findIndex].messageType = resMessage?.messageType;
            // leadDetails.messages[findIndex].errorMessage = resMessage?.errorMessage;
            // leadDetails.messages[findIndex].errorCode = resMessage?.errorCode;
            // leadDetails.messages[findIndex].leadOpportunity = resMessage?.leadOpportunity ||
            //   resMessage?.lead_opportunity || { name: "", id: "" };
          }
          setSelectedLead({ ...leadDetails });

          setChangeState(!changeState);
        } else {
          message.error(res?.data?.createMessage?.message);
        }
      });
    }
  };

  const handleBackClick = () => {
    // redirect to previous slug value
    window.history.back();
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const updateDimensions = () => {
    setShowLeadSection(window.innerWidth > 1024);
    setShowProfileSection(window.innerWidth > 1024);
  };

  const handleSendConsentClick = (e, status) => {
    status !== "PENDING" && setDisableConcentClick(prev => !prev);
    if (e) {
      e.preventDefault();
      if (status === "PENDING") {
        showConfirmationModal(lang.resendConsent, "", () => {
          sendContentMessage();
        });
      } else {
        sendContentMessage();
      }
    }

    concentTime = setTimeout(() => {
      setDisableConcentClick(true);
    }, 10000);
  };

  const sendContentMessage = async () => {
    let content =
      consentTemplate ||
      `${user?.company?.name || lang.theDelear} ${lang.wantsToReplayYou}. ${lang.stopToOptout}.`;
    if (user?.company?.isOptinConsentMethod) {
      content = `${user?.company?.name || lang.theDelear} ${lang.wantsToReplayYou}. ${
        lang.optInOptOut
      }.`;
    }
    await updateLeadConsent({ variables: { leadId: selectedLead?.id } })
      .then(resp => {
        if (resp?.data?.updateLeadConsentStatus?.ok) {
          const respLead = resp?.data?.updateLeadConsentStatus?.lead || {};
          setSelectedLead({
            ...selectedLead,
            textConsentStatus: respLead?.textConsentStatus || "PENDING",
            textConsentDate: respLead?.textConsentDate || dayjs(),
          });
          const arrayFn = () => {
            const newState = structuredClone(leadList);
            const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
            if (findIndex > -1) {
              newState[findIndex].textConsentStatus = respLead?.textConsentStatus;
              newState[findIndex].textConsentDate = respLead?.textConsentDate;
            }
            return newState;
          };
          setLeadList(arrayFn());
          message.success(lang.consentSent);

          setTimeout(() => {
            clearTimeout(concentTime);
            setDisableConcentClick(true);
          }, 3000);
        }
      })
      .catch(() => {
        clearTimeout(concentTime);
        setDisableConcentClick(true);
      });
    onClickHandler(content, { ...selectedLead, textConsentStatus: "PENDING" }, user?.id);
  };

  const handleSendReviewInviteClick = () => {
    openDialog("sendReviewInvite", {
      dialogHeader: lang.sendReviewInvite,
      phoneNumber: queryString,
      openModal: openAddModal && modal && apiCheck,
      statusList: statusList,
      setModalVisible: setAddPhoneModalVisible,
      variables: variables,
      data: leadList,
      sources: sourcesData || [],
      user: user,
      oncancel: () => {
        closeDialog("sendReviewInvite");
      },
      // dialogHeader: "Send review invite link",
      sendSendReview: payload => {
        onClickReviewHandler(`${payload.body}`, payload.reviewLink, payload.selectedLead, user?.id);
      },
      onSetLead: (item, mode, phoneLead = "") => {
        if (phoneLead === "phoneLead") {
          const newLead = { ...item, messages: [] };
          setSelectedLead(newLead);

          page = 1;
          setMessageFilters({
            ...messageFilters,
            leadId: item.id,
            lastId: lastLead,
            page: 1,
          });
          if (mode === "new") {
            const tempLeads = structuredClone(leadList);
            tempLeads.unshift(newLead);
            setLeadList(structuredClone([...tempLeads]));
          }
        } else {
          if (+selectedLead?.id === +item?.id) {
            setAddPhoneModalVisible(false);
            return;
          }

          if (sessionStorage.getItem("currentLocationId") !== +item?.location?.id) {
            localStorage.setItem("changedLocationLeadId", item?.id);
            sessionStorage.setItem("currentLocationId", item?.location?.id);
            window.location.reload();
            return;
          }

          handleLeadDetailProfile(item?.id);
          handleScrollOnMovement();
        }
      },
      onLeadStatus: LeadStatus,
      onLeadOwners: leadOwners,
      onVehicles: vehicles,
      crmIntegration: crmIntegration,
    });
  };

  const handleAddPhoneClick = () => {
    // setAddPhoneModalVisible(true);
    openDialog("addLeadViaPhone", {
      dialogHeader: lang.addPhone,
      phoneNumber: queryString,
      openModal: openAddModal && modal && apiCheck,
      statusList: statusList,
      setModalVisible: setAddPhoneModalVisible,
      variables: variables,
      data: leadList,
      sources: sourcesData || [],
      user: user,
      onSetLead: (item, mode, phoneLead = "") => {
        if (phoneLead === "phoneLead") {
          const newLead = { ...item, messages: [] };
          setSelectedLead(newLead);

          page = 1;
          setMessageFilters({
            ...messageFilters,
            leadId: item.id,
            lastId: lastLead,
            page: 1,
          });
          const newLeadList = structuredClone(leadList);
          if (mode === "new") {
            newLeadList.unshift(newLead);
            setLeadList(newLeadList);
          }
        } else {
          if (+selectedLead?.id === +item?.id) {
            setAddPhoneModalVisible(false);
            return;
          }

          if (sessionStorage.getItem("currentLocationId") !== +item?.location?.id) {
            localStorage.setItem("changedLocationLeadId", item?.id);
            sessionStorage.setItem("currentLocationId", item?.location?.id);
            window.location.reload();
            return;
          }

          handleLeadDetailProfile(item?.id);
          handleScrollOnMovement();
        }
      },
      onLeadStatus: LeadStatus,
      onLeadOwners: leadOwners,
      onVehicles: vehicles,
      crmIntegration: crmIntegration,
    });
  };

  const handleSendReviewClick = () => {
    openDialog("reviewDialog", {
      handleConfirm: () => {
        closeDialog("reviewDialog");
      },
      oncancel: () => {
        closeDialog("reviewDialog");
      },
      lead: selectedLead,
      dialogHeader: "Send review invite link",
      confirmBtnText: "Send",
      sendSendReview: payload => {
        onClickReviewHandler(
          `${payload.body}`,
          payload.reviewLink,
          selectedLead,
          user?.id,
          "REVIEW"
        );
      },
    });
  };

  const handleReviewTemplateUploadClick = () => {
    setReviewTemplateUploadVisible(true);
  };

  const handleStatusFilter = status => {
    setSelectedStatus(status);
    setFilterApplied(true);
    setVariables({ ...variables, page: 1, status });
  };

  const handleSetDefinedTemplate = msg => {
    // console.log("MSG", msg);
    text.current.value = msg;
    textMode === 0 ? (text.current.value = msg) : setrichTextValue(richTextValue + msg);
    // setmessageText(msg);
  };

  const handleAddAppointment = () => {
    setOpenAppointmentDrawer(true);
    setRefetchAppointment(false);
  };

  const handleActiveToggle = async e => {
    console.log(e);
    await updateLeadConversionStatus({
      variables: { leadId: selectedLead?.id, disableConversation: !e },
    }).then(resp => {
      if (resp?.data?.enableDisableLeadConversation?.statusCode === 200) {
        const respLead = resp?.data?.enableDisableLeadConversation?.lead || {};
        setSelectedLead({
          ...selectedLead,
          ...(selectedLead.conversationStatus
            ? { conversationStatus: !e }
            : { conversationStatus: respLead.conversationStatus || {} }),
        });
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
          if (findIndex > -1) {
            if (newState[findIndex].conversationStatus) {
              newState[findIndex].conversationStatus.disableConversation = !e;
            } else {
              newState[findIndex] = {
                ...newState[findIndex],
                conversationStatus: respLead?.conversationStatus || {},
              };
            }
          }
          return newState;
        };
        setLeadList(arrayFn());
        console.log(selectedLead);
        e
          ? message.success(`${lang.leadAutoMsgFeatureUpdatedturnedon} ${selectedLead?.fullName}`)
          : message.success(`${lang.leadAutoMsgFeatureUpdatedturnedoff} ${selectedLead?.fullName}`);
      }
    });
  };

  const onMsgScroll = () => {
    // const scrollTop = filterApplied
    //   ? msgContainerRef.current.scrollTop + 10
    //   : msgContainerRef.current.scrollTop;
    // scrollTop === 10 ? setFilterApplied(true) : setFilterApplied(false);
    // scrollTop === 10 && setIsMsgScroll(true);
    // setFilterApplied(false);
    const { scrollTop } = msgContainerRef.current;
    if (
      selectedLead?.messages?.length < messageCount &&
      scrollTop === 0 &&
      !isEmpty(selectedLead?.messages)
    ) {
      // page = filterApplied ? 1 : page + 1;
      // setScrollBottom(scrollTop);
      page = selectedLead?.messages?.length > pageSize - 1 ? page + 1 : page;
      setMessageFilters({
        ...messageFilters,
        leadId: selectedLead?.id,
        lastId: filterApplied ? filterLastId : lastLead,
        page: page,
      });
    }
    timeReference = setTimeout(() => {
      if (selectedLead?.id && leadList?.length) {
        // TODO : come here for final code review
        // setLeadList(prev => {
        //   const dummySelectedLead = { ...selectedLead };
        //   dummySelectedLead.unreadCount = 0;
        //   const selectedleadIndex =
        //     leadList?.length > 0 &&
        //     leadList.findIndex(lead => +lead?.id === +dummySelectedLead?.id);
        //   const dummyLeadList = [...leadList];
        //   dummyLeadList[selectedleadIndex] = dummySelectedLead;
        //   return dummyLeadList;
        // });
      }
    }, 1000);
  };
  let currentScrollTop;
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    // Function to calculate and set the container width
    const updateContainerWidth = () => {
      if (msgContainerRef.current) {
        const { width } = msgContainerRef.current.getBoundingClientRect();
        setContainerWidth(width);
      }
    };

    // Call the function on component mount
    updateContainerWidth();

    // Attach a resize event listener to update the width if the window is resized
    window.addEventListener("resize", updateContainerWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);
  useEffect(() => {
    if (msgContainerRef.current?.scrollTop === 0 && page !== 1)
      if (selectedLead?.messages?.length % 20 === 0) {
        document.getElementById(`msg-19`)?.scrollIntoView({ behavior: "smooth" });
      } else {
        document
          .getElementById(`msg-${(selectedLead?.messages?.length % 20) - 1}`)
          ?.scrollIntoView({ behavior: "smooth" });
      }
  }, [selectedLead]);

  const handleWheel = event => {
    try {
      if (event.deltaY < 0) {
        // Mouse wheel scrolled upward
        // Your code here
        const scrollTop = filterApplied
          ? msgContainerRef.current.scrollTop + 10
          : msgContainerRef.current.scrollTop;
        const topPosition = filterApplied ? 10 : 0;
        if (scrollTop === topPosition && !loading && selectedLead.messages.length < messageCount) {
          page = selectedLead?.messages?.length > pageSize - 1 ? page + 1 : page;
          setMessageFilters({
            ...messageFilters,
            leadId: selectedLead?.id,
            lastId: filterApplied ? filterLastId : lastLead,
            page: page,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  let startY = 0;

  const handleTouchStart = event => {
    // Store the initial touch position
    startY = event.touches[0].clientY;
  };

  const handleTouchMove = event => {
    const currentY = event.touches[0].clientY;
    const deltaY = currentY - startY;
    // Check if the swipe is upwards and larger than a certain threshold (e.g., 50 pixels)
    if (deltaY > 100) {
      const scrollTop = filterApplied
        ? msgContainerRef.current.scrollTop + 10
        : msgContainerRef.current.scrollTop;
      const topPosition = filterApplied ? 10 : 0;
      if (scrollTop === topPosition && !loading && selectedLead.messages.length < messageCount) {
        page = selectedLead?.messages?.length > pageSize - 1 ? page + 1 : page;
        setMessageFilters({
          ...messageFilters,
          leadId: selectedLead?.id,
          lastId: filterApplied ? filterLastId : lastLead,
          page: page,
        });
      }
    }
  };

  const handleLeadDetailProfile = async leadId => {
    setLeadIdClick(leadId);
    localStorage.setItem("clickLeadId", leadId);
  };

  const handleScrollOnMovement = () => {
    document.querySelector(".antdList").scrollTop = 0;
  };

  const handleUpdate = async payload => {
    if (payload) {
      let lead = structuredClone(selectedLead);
      if (payload?.key === "fullName") {
        const leadResp = await updateLead({
          variables: {
            leadId: selectedLead?.id,
            fullName: payload?.value?.replace(/\s{2,}/g, " ")?.trim() || "",
            isSyncCheck: true,
            isPushCrm: true,
          },
        });
        if (leadResp?.data?.updateLead?.statusCode !== 200) {
          message.error(leadResp?.data?.updateLead?.message);
          return;
        }

        lead = {
          ...lead,
          fullName: leadResp?.data?.updateLead?.lead?.fullName,
        };
        getLeadDetail({ variables: { leadId: lead?.id } });
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
          if (findIndex > -1) {
            newState[findIndex].fullName = lead.fullName;
          }
          return newState;
        };
        setLeadList(arrayFn());
      }
      if (payload?.key === "phone") {
        let phoneResp = null;
        if (payload.id) {
          phoneResp = await updateLeadPhone({
            variables: {
              id: payload.id,
              phone: payload?.value || "",
              isSyncCheck: true,
              isPushCrm: true,
              phoneType: toTitleCase(payload.phoneType),
              leadId: selectedLead?.id,
            },
          });
          if (phoneResp?.data?.updateLeadPhone?.statusCode !== 200) {
            message.error(phoneResp?.data?.updateLeadPhone?.message);
            return;
          }
          const index = lead.phoneNumbers.findIndex(
            item => toTitleCase(item.phoneType || item.lookupType) === payload.phoneType
          );
          lead.phoneNumbers[index] = {
            ...phoneResp?.data?.updateLeadPhone?.leadPhone,
          };
          getLeadDetail({ variables: { leadId: lead?.id } });
        } else {
          phoneResp = await createLeadPhone({
            variables: {
              leadId: parseInt(selectedLead?.id),
              phone: payload?.value || "",
              isSyncCheck: true,
              isPushCrm: true,
              phoneType: toTitleCase(payload.phoneType),
            },
          });
          if (phoneResp?.data?.createLeadPhone?.statusCode !== 200) {
            message.error(phoneResp?.data?.createLeadPhone?.message);
            return;
          }
          lead = {
            ...lead,
            phoneNumbers: [{ ...phoneResp?.data?.createLeadPhone?.leadPhone }],
          };
        }
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
          if (findIndex > -1) {
            const [phone] = lead.phoneNumbers;
            newState[findIndex].phoneNumbers[0] = phone;
          }
          return newState;
        };
        setLeadList(arrayFn());
      }
      if (payload?.key === "email") {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!payload?.value.match(emailRegex)) {
          message.error(lang.enterValidEmail);
          return;
        }
        let emailResp = null;
        if ((lead?.emails || []).length > 0 && lead?.emails[0] != null) {
          emailResp = await updateLeadEmail({
            variables: {
              id: parseInt(parseInt(lead?.emails[0].id)),
              email: payload?.value || "",
              isSyncCheck: true,
              isPushCrm: true,
            },
          });
          if (emailResp?.data?.updateLeadEmail?.statusCode !== 200) {
            message.error(emailResp?.data?.updateLeadEmail?.message);
            return;
          }
          lead.emails[0] = {
            ...emailResp?.data?.updateLeadEmail?.leadEmail,
          };
          getLeadDetail({ variables: { leadId: lead?.id } });
        } else {
          emailResp = await createLeadEmail({
            variables: {
              leadId: parseInt(selectedLead?.id),
              email: payload?.value || "",
              isSyncCheck: true,
              isPushCrm: true,
            },
          });
          if (emailResp?.data?.createLeadEmail?.statusCode !== 200) {
            message.error(emailResp?.data?.createLeadEmail?.message);
            return;
          }
          lead = {
            ...lead,
            emails: [{ ...emailResp?.data?.createLeadEmail?.leadEmail }],
          };
        }
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          const findIndex = newState.findIndex(el => +el.id === +selectedLead?.id);
          if (findIndex > -1) {
            const [email] = lead.emails;
            if (newState[findIndex]?.emails && newState[findIndex].emails[0]) {
              newState[findIndex].emails[0] = email;
            }
          }
          return newState;
        };
        setLeadList(arrayFn());
      }
      setSelectedLead(lead);

      setInlineEditField(null);
    }
  };

  const handleRetry = (e, c) => {
    if (c?.id) {
      const leadDetails = structuredClone(selectedLead);
      const findIndex = leadDetails.messages.findIndex(el => +el?.id === +c?.id);
      if (findIndex > -1) {
        leadDetails.messages[findIndex] = {
          ...leadDetails.messages[findIndex],
          type: "new",
          messageStatus: "",
        };
        setSelectedLead({ ...leadDetails });
      }
      retryMessageSend({
        variables: {
          messageId: c.id,
        },
      }).then(resp => {
        if (resp?.data?.retryMessage?.statusCode === 200) {
          console.log("after retryMessageSend");
          getMessages();
        } else {
          message.error(resp?.data?.retryMessage?.message);
        }
      });
    }
  };

  const handleOpportunityId = selectedLead => {
    if (
      selectedLead?.leadOpportunity?.dsOpportunityEvents?.length > 0 &&
      selectedLead?.leadOpportunity?.dsOpportunityEvents[0]?.dsEventId
    ) {
      return `#${String(selectedLead?.leadOpportunity?.dsOpportunityEvents[0]?.dsEventId).padStart(
        4,
        "0"
      )}`;
    }
    if (
      selectedLead?.leadOpportunity?.sfLeadOpportunities?.length > 0 &&
      selectedLead?.leadOpportunity?.sfLeadOpportunities[0]?.sfOpportunityId
    ) {
      return `#${String(
        selectedLead?.leadOpportunity?.sfLeadOpportunities[0]?.sfOpportunityId
      ).padStart(4, "0")}`;
    }
    // VIN
    if (
      selectedLead?.leadOpportunity?.vsLeadOpportunities?.length > 0 &&
      selectedLead?.leadOpportunity?.vsLeadOpportunities[0]?.vsLeadId
    ) {
      return `#${String(selectedLead?.leadOpportunity?.vsLeadOpportunities[0]?.vsLeadId).padStart(
        4,
        "0"
      )}`;
    }

    return `#SMAI${String(selectedLead?.leadOpportunity?.id).padStart(4, "0")}`;
  };

  const handleAppointmentModalClose = async (event, lead, isAPINeedTocall = false) => {
    event && event.stopPropagation && event.stopPropagation();
    setAppointmentLead({});
    try {
      let appointmentDetails;
      if (isAPINeedTocall) {
        // appointmentDetails = await getLeadActiveAppointment({
        //   variables: { leadId: +lead?.id },
        // });
        setAppointmentPopupVisible(!openAppointmentPopup);
        setAppointmentLead(prev => {
          const newState = { ...prev, ...appointmentDetails?.data?.lead };
          !isAPINeedTocall && setAppointmentPopupVisible(!openAppointmentPopup);
          openDialog("appointmentForm", {
            dialogHeader:
              lead?.activeAppointment?.appointmentStatus === "CANCELLED"
                ? lang.appointmentCancelled
                : lang.editAppointment,
            lead: lead,
            visibleModal: openAppointmentPopup,
            appointment: lead?.activeAppointment,
            defaultDate: null,
            eventId: eventId,
            selectedLead,
            isFromCard: true,
            refreshLeadListData,
            refetchLeads,
            setAppointmentModelVisible: handleAppointmentModalClose,
            leadOpportunities: lead?.leadOpportunities,
            redirect: async (e, updateDate, needToRedirect = true, leadId, isClickOnName) => {
              if (+selectedLead?.id === +leadId && isClickOnName) {
                closeDialog("appointmentForm");
                return;
              }
              setIsAppointmentChangeFromLead(prev => !prev);
              if (leadId) {
                handleLeadDetailProfile(leadId);
                handleScrollOnMovement();
                return;
              }
              if (!(isEmpty(appintmentLead) && e)) {
                setIsMsgApi(true);
                handleLeadDetailProfile(selectedLead?.id);
                handleScrollOnMovement();
                setShowLeadSection(false);
              }
            },
          });
          return lead;
        });
        setAppointmentPopupVisible(!openAppointmentPopup);
      }
    } catch (err) {
      console.log("Error in getLeadActiveAppointment", err);
    }
  };

  const buttonStyle = () => {
    if (selectedLead?.leadSource?.name === "Web Chat Widget") {
      if (!connection?.connected)
        return {
          background: "#EF4444",
        };
      return {
        background: "#3B82F6",
      };
    }
    const style = {
      background:
        selectedLead?.textConsentStatus === "DECLINED"
          ? "#EF4444"
          : selectedLead?.textConsentStatus === "ACCEPTED"
          ? "#52c41a"
          : "blue",
    };
    if (
      !isConsentStatusEnable(selectedLead?.textConsentStatus, user?.company?.isOptinConsentMethod)
    ) {
      if ((implicit || explicit) && selectedLead?.textConsentStatus !== "DECLINED") {
        if (selectedLead?.textConsentStatus === "ACCEPTED") {
          style.background = "#52c41a";
        } else {
          style.background = "#F59E0B";
        }
      }
      return style;
    }

    return style;
  };
  const tooltipTitle = () => {
    if (selectedLead?.leadSource?.name === "Web Chat Widget") {
      if (connection?.connected) return "Send message to Widget";
      return "User Disconnected";
    }
    if (
      !isConsentStatusEnable(selectedLead?.textConsentStatus, user?.company?.isOptinConsentMethod)
    ) {
      const hasOptedOut = selectedLead?.textConsentStatus === "DECLINED";

      if (implicit || explicit) {
        return hasOptedOut ? "Lead has opted out" : "Send consent request";
      } else {
        return hasOptedOut ? "Lead has opted out" : "Send message";
      }
    } else {
      return "Send Message";
    }
  };
  const textDisabled = () => {
    if (selectedLead?.leadSource?.name === "Web Chat Widget") {
      if (connection?.connected) return false;
      return false;
    }
    if (
      !isConsentStatusEnable(selectedLead?.textConsentStatus, user?.company?.isOptinConsentMethod)
    ) {
      if (implicit || explicit) {
        return true;
      }
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      return false;
    }
  };
  const buttonDisabled = () => {
    if (selectedLead?.leadSource?.name === "Web Chat Widget") {
      if (connection?.connected) return false;
      return true;
    }
    return selectedLead?.textConsentStatus === "DECLINED";
  };

  const handleSearch = e => {
    page = 0;
    lastLead = "";
    setSearchKey(e);
    setSelectedLead({});

    if (e) {
      setVariables({ ...variables, page: 1, dsEventId: "", dsEntityId: "" });
      setSearch(e);
      setEventId("");
      setEntityId("");
    } else {
      setVariables({ ...variables, page: 1, dsEventId: "", dsEntityId: "" });
      setSearch(e);
      setEventId("");
      setEntityId("");
    }
    if (e === "" || e === undefined || e === null) {
      window.history.pushState("", "", `/engagements/activity-center`);
    }
  };

  const sendConsentMessage = () => {
    if (implicit) {
      const { templatetext } = consent.find(
        item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_OFF"
      );
      getEngagementTemplateText({
        variables: {
          leadId: selectedLead?.id,
          content: templatetext,
        },
      });
    }
    if (explicit) {
      const { templatetext } = consent.find(
        item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_ON"
      );
      getEngagementTemplateText({
        variables: {
          leadId: selectedLead?.id,
          content: templatetext,
        },
      });
    }
  };

  const [getTranslationSettings] = useLazyQuery(GET_TRANSLATION_SETTINGS, {
    variables: { leadId: selectedLead?.id },
    skip: !selectedLead?.id,
    fetchPolicy: "cache-and-network",
    onCompleted: res => {
      if (res?.getTranslationSettings) {
        setTargetLangForCustomer(res?.getTranslationSettings?.customerLanguage);
        setTargetLangForEmployee(res?.getTranslationSettings?.userLanguage);
        setIsAutoTranslate(res?.getTranslationSettings?.isAutomaticTranslation);
        setIsIncludeOriginalMessage(res?.getTranslationSettings?.isIncludeOriginalMessage);
        setIsTranslatePreviewShow(res?.getTranslationSettings?.isTranslationPreview);
      } else {
        setIsAutoTranslate(false);
        setIsIncludeOriginalMessage(false);
        setIsTranslatePreviewShow(false);
      }
    },
  });

  const { refetch: refetchSupportedLanguages } = useQuery(GET_SUPPORTED_LANGUAGES, {
    onCompleted: res => {
      const languages = structuredClone(res?.getSupportedLanguages);
      setLanguages(languages);
    },
  });

  const { refetch: refreshTeams } = useQuery(GET_ALL_TEAMS, {
    variables: {
      companyId: user?.company.id,
    },
    onCompleted: res => {
      // console.log("Teams====>", res?.getTeams);
      if (addMyConversation) {
        const isUserTeamLeader = res?.getTeams?.data?.find(item => +item?.leaderId === +user?.id);
        const status = uniq([...selectedQuickFilters, ...selectedStatus]);
        const hasMyConversation = status?.find(item => item === "MY_CONVERSATIONS");
        if (!isUserTeamLeader && !hasMyConversation) {
          status.push("MY_CONVERSATIONS");
          // setSelectedStatus(status);
          dispatch(addQuickFilter("MY_CONVERSATIONS"));
        } else if (isUserTeamLeader && hasMyConversation) {
          const newStatusList = status.filter(item => item !== "MY_CONVERSATIONS");
          setSelectedStatus(newStatusList);
        }
        refetchLeads();
      }
    },
  });
  const [isRefetching, setRefetching] = useState(false);

  const [refreshLeadList] = useLazyQuery(GET_EXTENSION_LIST_ACTIVITY_CENTER, {
    fetchPolicy: "network-only",
  });
  const presetFilterJson = isEmpty(activeLeadFilterReduxData?.genericFilterJson?.filterJson)
    ? {}
    : JSON.parse(activeLeadFilterReduxData?.genericFilterJson?.filterJson);

  const {
    data: leadData,
    loading: leadsLoading,
    error: leadsError,
    refetch: refetchLeads,
  } = useQuery(GET_EXTENSION_LIST_ACTIVITY_CENTER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip:
      sessionStorage.getItem("currentLocationId") === null ||
      !sessionStorage.getItem("currentLocationId"),
    variables: {
      ...variables,
      search: search?.trim(),
      status: isEmpty(search) ? uniq([...selectedQuickFilters, ...selectedStatus]) : [],
      campaignIds: variables?.campaignIds?.map(el => parseInt(el)),
      excludeDuplicate: true,
      userIds: [...userFilters],
      prioritySortOrder: leadSort,
      leadId: paramLeadId,
      skipFilter: !isEmpty(search),
      ...(!isEmpty(presetLeadFilter) ? { ...presetFilterJson } : {}),
      pageSize:
        !isEmpty(lead) && Array.isArray(lead) && !isEmpty(search)
          ? variables.pageSize - (lead || []).length
          : variables.pageSize,
    },
    onCompleted: res => {
      // if (isEmpty(selectedLead) && leadList.length > 0) {
      //   setSelectedLead(leadList[0]);
      // }

      // if (isRefetching) {
      //   return;
      // }

      setApiCheck(false);
      let newLeadList = res?.getLeads?.data || [];
      let leadCount = res?.getLeads?.count || 0;
      setLeadCount(res?.getLeads?.count || 0);
      dispatch(setLeadsRedux(newLeadList.map(item => ({ ...item, messages: [] }))));
      if (res?.getLeads?.count > 0) {
        // TODO: Why is this filter needed? Is it related to the pinned lead functionality?
        // FIXME: Remove this filtering if not needed
        // leadList = leadList.filter(x => +x.id !== +lead?.id);
        if (
          lead?.id &&
          !variables?.search &&
          variables?.source?.length === 0 &&
          variables?.voi?.length === 0
        ) {
          handleLeadDetailProfile(lead?.id);
        } else if (isFirstLeadSet) {
          setSelectedLead(newLeadList[0]);
        } else {
          let leadDetails = selectedLead;
          if (!selectedLead?.id || isEmpty(selectedLead)) {
            if (activeLeadId) {
              setLeadList([...newLeadList]);
              handleLeadDetailProfile(activeLeadId);
              setApiCheck(true);
              return;
            }
            const currentLead = newLeadList.filter(lead => +lead?.id === +activeLeadId);
            leadDetails = activeLeadId ? currentLead.length > 0 && currentLead[0] : leadList[0];
            setSelectedLead({ ...leadDetails, messages: [] });
          }

          page = 1;
          setMessageFilters({
            ...messageFilters,
            leadId: leadDetails?.id,
            lastId: lastLead,
            page: 1,
          });
        }
        if (!activeLeadId) {
          // The currentLocationId can sometimes be "undefined". This happens
          // when a notification that does not have location information is
          // clicked, usually in cases of DeaerTeam leads.
          // eslint-disable-next-line no-lonely-if

          if (
            crmIntegration !== "DT" &&
            !sessionStorage.getItem("currentLocationId")?.includes(",")
          ) {
            const locationId = locationIdslice || sessionStorage.getItem("currentLocationId");
            const filterArr = newLeadList.filter(item => {
              if (item?.location?.id === locationId) {
                return item;
              }
              if (item?.leadOpportunities?.length > 0) {
                const locOpps = item?.leadOpportunities.filter(
                  lo => lo.location?.id === locationId
                );
                if (locOpps.length > 0) {
                  return item;
                }
              }
            });
            leadCount = (filterArr || []).length;
            if (leadCount > 0) {
              newLeadList = [...filterArr];
              leadCount > res?.getLeads?.count && setLeadCount(leadCount);
            }
          }
        }
        setAddPhoneModalVisible(false);
        closeDialog("addLeadViaPhone");
        // leadList.findIndex(item => item.id===selectedLead.id)
        if (leadSort !== prevSortOrderRef.current) {
          setSelectedLead(newLeadList[0]);
          prevSortOrderRef.current = leadSort;
        }
        if (uniq([...selectedQuickFilters, ...selectedStatus]) !== prevStatusRef) {
          setSelectedLead(newLeadList[0]);
          prevStatusRef.current = uniq([...selectedQuickFilters, ...selectedStatus]);
        }
      } else if (
        (!isUndefined(queryString) && !isEmpty(queryString)) ||
        (newLeadList.length <= 0 &&
          !isEmpty(search?.trim()) &&
          search.trim().length >= 10 &&
          parsePhoneNumber(search.trim()))
      ) {
        // queryString comes from url path for notifications and is then sent to the popup window
        setAddPhoneModalVisible(true);
        openDialog("addLeadViaPhone", {
          dialogHeader: lang.addPhone,
          phoneNumber: queryString || `${search.trim()}`,
          openModal: openAddModal && modal && apiCheck,
          statusList: statusList,
          setModalVisible: setAddPhoneModalVisible,
          variables: variables,
          data: newLeadList,
          sources: sourcesData?.leadSources || [],
          user: user,
          onSetLead: (item, mode, phoneLead = "") => {
            if (phoneLead === "phoneLead") {
              const itemData = { ...item, messages: [] };
              setSelectedLead(itemData);

              page = 1;
              setMessageFilters({
                ...messageFilters,
                leadId: item.id,
                lastId: lastLead,
                page: 1,
              });
              if (mode === "new") {
                if (!Array.isArray(newLeadList) || !Object.isExtensible(newLeadList)) {
                  // Make a shallow copy of leadList if it's an array
                  const tempLeadList = Array.isArray(newLeadList) ? [...newLeadList] : [];
                  tempLeadList.unshift(itemData);
                  // Replace the original leadList with the new, modified copy
                  setLeadList(structuredClone([...tempLeadList]));
                } else {
                  newLeadList.unshift(itemData);
                }
              }
            } else {
              if (selectedLead?.id === item?.id) {
                setAddPhoneModalVisible(false);
                closeDialog("addLeadViaPhone");
                return;
              }

              if (sessionStorage.getItem("currentLocationId") !== item?.location?.id) {
                localStorage.setItem("changedLocationLeadId", item?.id);
                sessionStorage.setItem("currentLocationId", item?.location?.id);
                dispatch(setLocationIdSlice({ locationId: item?.location?.id }));
                closeDialog("addLeadViaPhone");
                return;
              }

              handleLeadDetailProfile(item?.id);
              handleScrollOnMovement();
            }
          },
          onLeadStatus: LeadStatus,
          onLeadOwners: leadOwners,
          onVehicles: vehicles,
          crmIntegration: crmIntegration,
        });
      }
      if (res?.getLeads?.count === 0 || leadCount === 0) {
        console.log("==========>>> RESET LEADS AS EMPTY ================");
        // when the list is empty, clear the UI
        setLeadList([]);
        setLeadCount(0);
        setSelectedLead();
        setSelectedLeadId();
        setSelectedLeadID();
        setMessageFilters({
          ...messageFilters,
          leadId: undefined,
        });
      }

      if (Array.isArray(lead) && !search) {
        let temp = structuredClone(newLeadList);
        Array.isArray(lead) &&
          lead?.map(item => {
            temp.unshift(item);
          });

        temp = uniqBy(temp, "id");

        setLeadList([...temp]);
        setSelectedLead(temp[0]);
        setSelectedLeadId(temp[0].id);
        // newLeadList = temp;
      } else if (newLeadList.length > 0) {
        console.log("==========>>> SET LEADS  ================");
        setLeadList([...newLeadList]);
        if (+newLeadList[0].id === +selectedLead?.id) {
          setSelectedLead({ ...newLeadList[0], ...selectedLead });
        } else {
          setSelectedLead({ ...newLeadList[0], messages: [] });
        }
        setSelectedLeadId(newLeadList[0].id);
        getLeadDetail({
          variables: { leadId: newLeadList[0].id },
        });
      }

      setApiCheck(true);
    },
  });

  useEffect(() => {
    if (canViewTranslation && selectedLead?.id) {
      getTranslationSettings({
        variables: {
          leadId: selectedLead?.id,
        },
      });
    }
  }, [selectedLead]);

  useEffect(() => {
    refreshTeams({
      variables: {
        companyId: user.company.id,
      },
    });
  }, []);

  const { refetch: refreshCompany } = useQuery(GET_MY_COMPANY, {
    onCompleted: res => {
      if (res && res.me && res.me.company) setCompany(res.me.company);
    },
  });

  // const { refetch: refreshReviewInfo } = useQuery(GET_REVIEW_INFOS, {
  const { reviewInfoData, refetch: refreshReviewInfo } = useQuery(GET_SOCIAL_REVIEW_SETTINGS, {
    variables: {
      companyId: user.company.id,
      locationId: selectedLead?.location?.id || lead?.location?.id,
    },
    onCompleted: res => {
      const reviewInfos = res?.getSocialReviewSettings || [];
      const activeReview = reviewInfos.find(item => item.isActive);
      if (activeReview) {
        setReviewInfo(activeReview);
      } else if (reviewInfos.length > 0) {
        setReviewInfo(reviewInfos[0]);
      }
    },
  });
  const [messageCount, setMessageCount] = useState(0);
  const [getEngagementTemplateText] = useLazyQuery(GET_CONSENT_TEMPLATE, {
    fetchPolicy: "cache-and-network",
    onCompleted: res => {
      setConsentMessage(res.getEngagementTemplateText.content);
      if (
        res.getEngagementTemplateText.statusCode === 200 &&
        res.getEngagementTemplateText.content !== "" &&
        callConsentMessage
      ) {
        onClickHandler(res.getEngagementTemplateText.content, selectedLead, user.id, true);
      }
    },
  });

  const [getLeadActiveAppointment, { loading: activeSpinner }] = useLazyQuery(
    GET_LEAD_ACTIVE_APPOINTMENT,
    {
      fetchPolicy: "cache-and-network",
      variables: { leadId: selectedLead?.id || lead?.id || selectedLeadID },
      skip: !selectedLead?.id,
    }
  );

  const [{ data: respCampaigns }] = useLazyQuery(GET_CAMPAIGN_PAGINATED_LIST, {
    fetchPolicy: "network-only",
    variables: { status: "Active" },
    onCompleted: () => {
      setCampaigns(respCampaigns?.getCampaigns?.data);
      dispatch(setAllCampaigns({ campaigns: respCampaigns?.getCampaigns?.data }));
    },
  });

  const [editLeadDetails] = useLazyQuery(EDIT_LEAD, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setOpenEditPopup(true);
    },
  });
  useQuery(GET_ALL_USERS_ACTIVITY_CENTER, {
    variables: { companyId: user?.company?.id || 0 },
    fetchPolicy: "cache-first",
    onCompleted: res => {
      let obj = userIdMap;
      if (res?.getUsers?.data || []) {
        const users = res?.getUsers?.data || [];
        users.forEach(el => {
          const id = el?.id;
          obj = {
            ...obj,
            [id]: el?.fullName || "Otto",
          };
        });
        setUserIdMap({ ...obj });
        // dispatch(setUserList({ usersList: { ...obj }, companyId: user.company.id }));
      }
    },
  });
  const [getMessages, { loading, error, data: leadMessagesData, refetch: refetchMessages }] =
    useLazyQuery(GET_MESSAGES, {
      fetchPolicy: "cache-and-network",
      skip: leadList.length === 0 || !selectedLead.id,
      variables: {
        ...messageFilters,
        leadId: selectedLead?.id || selectedLeadID || messageFilters?.leadId,
        campaignIds: (messageFilters.campaignIds || []).map(el => parseInt(el)),
      },
      onCompleted: res => {
        lastLead = res?.messages?.lastId || "";
        filterApplied && setFilterLastId(lastLead);
        const messageData = (res?.messages?.data || []).filter(
          item => item !== undefined && +item?.leadId === +selectedLead?.id
        );
        if (isEmpty(messageData)) {
          return setSelectedLead({ ...selectedLead, messages: [] });
        }
        // setMessageCount(messageData.length);
        setMessageCount(res?.messages?.count);
        let zeroIndexMsg;
        let messagePayload = messageData.map(el => {
          const findIndex = (selectedLead?.messages || []).findIndex(s => +s?.id === +el?.id);
          if (findIndex === 0) {
            zeroIndexMsg = el;
            return el;
          }
          if (findIndex === -1 && !isUndefined(el)) {
            return el;
          }
        });
        messagePayload = messagePayload?.filter(obj => obj !== null && obj !== undefined);

        if (filterApplied && messageData.length > 0 && !activeOpp.includes("#")) {
          setGlobalMessageData(prev => [...prev, ...messageData]);
        }
        let messageList;
        if (
          messageData.length > 0 &&
          messagePayload.length > 0 &&
          isEmpty(messageFilters.campaignIds)
        ) {
          if (activeOpp === "#DEFAULTOPPID") {
            messageList = selectedLead?.messages || [];
            messagePayload.map(el => {
              messageList = [...messageList, el];
            });
            const specificMsg = messageList.findIndex(msg => msg?.id === zeroIndexMsg?.id);
            if (specificMsg !== -1) {
              messageList[specificMsg] = { ...zeroIndexMsg };
            }
          } else {
            messageList = [...messageData];
          }
          const leadDetail = { ...selectedLead, messages: uniqBy(messageList, "id") };
          setSelectedLead({ ...leadDetail });

          setChangeState(!changeState);
        } else if (messageData.length > 0 && !isEmpty(messageFilters.campaignIds)) {
          setSelectedLead({ ...selectedLead, messages: [...messageData] });
          setChangeState(!changeState);
        } else {
          setSelectedLead({ ...selectedLead, messages: [] });
          setChangeState(!changeState);
        }

        if (messageData.length === 0 && filterApplied) {
          messageList = isMsgScroll ? [] : [...globalMessageData];
          const leadDetail = { ...selectedLead, messages: uniqBy(messageList, "id") };
          setSelectedLead({ ...leadDetail });

          setChangeState(!changeState);
        }
      },
    });
  const [getLeadDetail, { loading: spinner }] = useLazyQuery(GET_LEAD_DETAIL_ACTIVITY_CENTER, {
    fetchPolicy: "network-only",
    variables: { leadId: selectedLead ? +selectedLead?.id : undefined },
    skip: !selectedLead.id,
    onCompleted: data => {
      setTargetLangForCustomer(data?.lead?.preferredLanguageCode || "");
      if (data?.lead) {
        const leadRes = data?.lead;
        setSelectedLead({
          ...selectedLead,
          ...leadRes,
          messages: selectedLead && +selectedLead.id === +leadRes?.id ? selectedLead?.messages : [],
        });
      }
    },
    onError: err => {
      console.log("Error in lead query", err);
    },
  });

  const [getConsentTemplate] = useLazyQuery(GET_COMPANY_CONSENT_TEMPLATES, {
    fetchPolicy: "network-only",
    onCompleted: resp => {
      if (resp?.getConsentTemplateMessage?.statusCode === 200) {
        dispatch(
          setConsentTemplateSlice({ consentTemplate: resp?.getConsentTemplateMessage?.content })
        );
        setConsentTemplate(resp?.getConsentTemplateMessage?.content || null);
      }
    },
  });

  const [getCrmLeadStatusData] = useLazyQuery(GET_CRM_LEAD_STATUS, {
    fetchPolicy: "network-only",
    onCompleted: resp => {
      setcrmIntegrationData(resp?.getCrmLeadStatus?.data);
      const leadStatus = (resp?.getCrmLeadStatus?.data || []).map(e => ({
        status: e?.status?.replace(/_/g, " "),
        statusTypeId: e?.statusTypeId,
        type: e.type,
        crmId: e?.id,
      }));
      setstatusList([...leadStatus]);
      dispatch(
        setCrmLeadStatus({
          crmLeadStatus: leadStatus,
          // crmIntegration: resp?.getCrmLeadStatus?.crmIntegrationType,
          crmIntegrationData: resp?.getCrmLeadStatus?.data,
        })
      );
    },
  });

  const [getLeadSources, { data: leadSourcesData }] = useLazyQuery(GET_EXTENSION_LEAD_SOURCES, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      if (res.leadSources && res.leadSources.length > 0) {
        setSourcesData(leadSourcesData);
        dispatch(setLeadSources({ leadSources: res.leadSources }));
      }
    },
  });

  const [getCompanyConsentTemplateEvent] = useLazyQuery(GET_COMPANY_CONSENT, {
    variables: { companyId: user?.company?.id },
    fetchPolicy: "network-only",
    onCompleted: res => {
      const consentData = res?.getCompanyConsentTemplateEvent.map(item => {
        return {
          code: item?.consentTemplateEvent?.code,
          isActive: item?.companyConsentTemplateEvent?.isActive,
          templatetext: item?.companyConsentTemplateEvent?.templateText,
        };
      });
      setConsent(consentData);
      dispatch(setCompanyConsentTemplate({ companyId: user?.company?.id, consents: consentData }));
    },
  });

  const [getAfterMessageText] = useLazyQuery(GET_COMPANY_AFTER_MESSAGE_CONSENT_TEMPLATE, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      dispatch(setAfterMessageText({ afterMessageText: res?.getAfterMessageText }));
      setAfterMessageConsent(res?.getAfterMessageText?.content);
      setonlyFirstMessage(res?.getAfterMessageText?.onlyFirstMessage);
      setisActive(res?.getAfterMessageText?.isActive);
    },
  });

  const [createMMS] = useMutation(CREATE_MMS, {
    onCompleted: res => {
      if (res.createMms.statusCode === 200) {
        const messages = structuredClone(selectedLead.messages);
        messages.push({
          ...res.createMms.leadMessage,
          mmsObjects: res.createMms.leadMessage.mmsObjects.map(media => ({
            compressedMediaUrl: media.compressedMediaUrl,
            mediaUrl: media.mediaUrl,
            mediaType: media.mediaType,
            size: media.size,
            thumbnail: media.thumbnail,
            compressedThumbnail: media.compressedThumbnail,
          })),
        });
        setSelectedLead({
          ...selectedLead,
          messages: [...messages],
        });

        textMode === 0 ? (text.current.value = "") : setrichTextValue("");
        message.success(res.createMms.message);
        setmmsLoader(false);
        setAttachments([]);
        setmessageText("");
        text.current.value = "";
      }
    },
  });
  const [createMessage, { loading: messageLoader }] = useMutation(CREATE_MESSAGE);
  const [updateTranslationSetting] = useMutation(UPDATE_TRANSLATION_SETTINGS, {
    onCompleted: res => {
      if (res?.modifyTranslationSetting?.statusCode === 200) {
        setTargetLangForCustomer(
          res?.modifyTranslationSetting?.translationSetting?.customerLanguage
        );
        setTargetLangForEmployee(res?.modifyTranslationSetting?.translationSetting?.userLanguage);
        setIsAutoTranslate(
          res?.modifyTranslationSetting?.translationSetting?.isAutomaticTranslation
        );
        setIsIncludeOriginalMessage(
          res?.modifyTranslationSetting?.translationSetting?.isIncludeOriginalMessage
        );
        setIsTranslatePreviewShow(
          res?.modifyTranslationSetting?.translationSetting?.isTranslationPreview
        );
      }
    },
  });
  const [broadCastUpdateUnreadCount] = useMutation(BROADCAST_UNREAD_COUNT);

  const [retryMessageSend] = useMutation(RETRY_MESSAGE_SEND);
  const [translateMessage, { loading: translateMessageLoader }] = useMutation(TRANSLATE_MESSAGE);

  const [updateLeadConsent, { loading: concentLoader }] = useMutation(UPDATE_LEAD_CONSENT, {
    onCompleted: res => {
      if (res?.updateLeadConsentStatus?.ok) {
        const respLead = res?.updateLeadConsentStatus?.lead || {};
        setSelectedLead({
          ...selectedLead,
          textConsentStatus: respLead?.textConsentStatus || "PENDING",
          textConsentDate: respLead?.textConsentDate || dayjs(),
        });
        getLeadDetail({
          variables: { leadId: +selectedLead?.id },
          skip: !selectedLead?.id,
        });
      }
    },
  });

  const refetchLeadDetails = async () => {
    if (!isEmpty(selectedLead) && !Array.isArray(selectedLead)) {
      const res = await getLeadDetail({
        variables: { leadId: +selectedLead?.id },
        skip: !selectedLead?.id,
      });
      const leadRes = res.data.lead;
      setSelectedLead({
        ...selectedLead,
        ...leadRes,
        messages: selectedLead && +selectedLead.id === +leadRes?.id ? selectedLead?.messages : [],
      });
    }
  };
  const [updateLeadConversionStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_LEAD_CONVERSATION_STATUS
  );
  const [updateLeadPhone, { loading: updatePhoneLoading }] = useMutation(UPDATE_LEAD_PHONE);
  const [createLeadPhone, { loading: phoneLoading }] = useMutation(CREATE_LEAD_PHONE);
  const [updateLeadEmail, { loading: updateEmailLoading }] = useMutation(UPDATE_LEAD_EMAIL);
  const [createLeadEmail, { loading: createEmailLoading }] = useMutation(CREATE_LEAD_EMAIL);

  const [updateLead] = useMutation(UPDATE_LEAD);

  useEffect(() => {
    updateMobileResponsive();
    window.addEventListener("resize", updateMobileResponsive);
    return () => {
      window.removeEventListener("resize", updateMobileResponsive);
    };
  }, []);

  useEffect(() => {
    if (leadPresetFilter && Object.keys(leadPresetFilter)?.length > 0) {
      setVariables(prev => {
        const newState = { ...prev };
        newState.leadStatusTypes = leadPresetFilter?.leadStatusTypes;
        newState.orderBy = leadPresetFilter?.orderBy;
        newState.orderDirection = leadPresetFilter?.orderDirection;
        newState.source = leadPresetFilter?.source;
        newState.voi = leadPresetFilter?.voi;
        newState.sourceOriginal = [];
        newState.combinedSource = [];
        newState.page = 1;
        newState.pageSize = 25;
        newState.status = uniq([...selectedQuickFilters, ...selectedStatus]);
        newState.campaignIds = campaignFilters || [];
        newState.dsEntityId = entityId;
        newState.dsEventId = eventId;

        if (leadPresetFilter?.userTypesOr?.length > 0) {
          newState.userTypesOr = leadPresetFilter.userTypesOr;
        }

        if (leadPresetFilter?.userTypesAnd?.length > 0) {
          newState.userTypesAnd = leadPresetFilter?.userTypesAnd;
        }

        return newState;
      });
      setSearch("");
    }
  }, [leadPresetFilter]);

  useEffect(() => {
    selectedLead?.leadOpportunities?.length > 0 && setOppDropdown();
  }, [selectedLead]);

  useEffect(() => {
    socketMsgSelectedLead && handleToasterSelectedLead();
  }, [socketMsgSelectedLead]);

  useEffect(() => {
    const oppName = opportunityList.find(opp => opp.id.toString() === activeOpp.toString());
    setActiveOppName(oppName);
    if (oppName?.id && oppName?.id[0] !== "#") {
      setMessageFilters({
        ...messageFilters,
        leadOpportunityId: +oppName?.id,
        page: 1,
        lastId: filterLastId,
      });
      setFilterApplied(true);
    } else {
      const dummyMsgFilters = {
        ...messageFilters,
        page: 1,
        lastId: filterLastId,
      };
      delete dummyMsgFilters.leadOpportunityId;
      setFilterApplied(true);
      setMessageFilters({ ...dummyMsgFilters });
    }
  }, [activeOpp]);

  useEffect(() => {
    setActiveLeadId(localStorage.getItem("changedLocationLeadId"));
  }, [locaitonLocalId]);

  useEffect(() => {
    localStorage.setItem("fullscreen", fullScreen);
  }, [fullScreen]);

  useEffect(() => {
    if (
      Object.entries(userListReduxData.users).length &&
      +user?.company?.id === +userListReduxData?.companyId
    ) {
      setUserIdMap({ ...userListReduxData.users });
    } else if (
      !Object.entries(userListReduxData.users).length &&
      user?.company?.id &&
      user?.company?.id !== userListReduxData?.companyId
    ) {
      // getAllUsers();
    }

    if (+companyConsentReduxData?.companyId !== +user?.company?.id && user?.company?.id) {
      getCompanyConsentTemplateEvent();
    } else if (
      +companyConsentReduxData?.companyId === +user?.company?.id &&
      companyConsentReduxData?.consents?.length
    ) {
      setConsent(companyConsentReduxData.consents);
    }

    if (allCampaignsReduxData?.length > 0) {
      setCampaigns([...allCampaignsReduxData]);
    } else {
      // getAllCampaigns();
    }
  }, [user?.company?.id, companyConsentReduxData, userListReduxData, allCampaignsReduxData]);

  useEffect(() => {
    getAfterMessageText();
  }, []);

  useEffect(() => {
    if (locationIdslice) {
      setSearchKey(location?.split("/")[3] || "");
      setSelectedLead({});
      setSelectedLeadId();
      setSelectedLeadID();
      setLeadList([]);
      setLeadIdClick("");
      localStorage.removeItem("clickLeadId");
      setIsClickonPotentialDuplicate("");
      setVariables({
        ...variables,
        page: 1,
        dsEventId: "",
        dsEntityId: "",
      });
      setSearch(location?.split("/")[3] || "");
      setEventId("");
      setEntityId("");
    }
  }, [locationIdslice]);

  useEffect(() => {
    if (!leadSourcesReduxData.length) {
      getLeadSources();
    } else {
      setSourcesData(leadSourcesReduxData);
    }
    if (!crmLeadStatusReduxData.crmLeadStatus.length) {
      getCrmLeadStatusData();
    } else {
      setstatusList(crmLeadStatusReduxData.crmLeadStatus);
      setcrmIntegrationData(crmLeadStatusReduxData.crmIntegrationData);
    }
  }, [leadSourcesReduxData, crmLeadStatusReduxData]);

  // useEffect(() => {
  //   !isUndefined(selectedLead?.id) && getLeadDetail({ variables: { leadId: +selectedLead?.id } });
  // }, [selectedLead?.id]);

  useEffect(() => {
    if (isEmpty(consentTemplateFromRedux)) {
      getConsentTemplate();
    } else {
      setConsentTemplate(consentTemplateFromRedux);
    }
  }, [consentTemplateFromRedux]);

  useEffect(() => {
    if (!isEmpty(selectedLead) && !Array.isArray(selectedLead)) {
      setSelectedLeadID(selectedLead?.id);
    }
    if ((selectedLead?.messages || []).length > 0 && page === 1) {
      scrollToBottom();
    }
    if (selectedLead?.unreadCount) {
      const selectedLeadIndex = (leadList || []).findIndex(lead => lead?.id === selectedLead?.id);
      if (selectedLeadIndex > -1) {
        const arrayFn = () => {
          const newState = structuredClone(leadList);
          if (newState[selectedLeadIndex]?.unreadCount) {
            newState[selectedLeadIndex] = { ...newState[selectedLeadIndex], unreadCount: 0 };
          }
          return newState;
        };
        setLeadList(arrayFn());
      }
    }
  }, [selectedLead]);

  const fetchLeadData = () => {
    if (leadList.length > 0) {
      getMessages({
        variables: {
          ...messageFilters,
          leadId:
            leadList.length > 0
              ? selectedLead?.id || selectedLeadID || messageFilters?.leadId
              : undefined,
          campaignIds: (messageFilters.campaignIds || []).map(el => parseInt(el)),
        },
      });
      getLeadDetail({
        variables: { leadId: +selectedLead?.id || selectedLeadID },
      });
    }
  };

  useMemo(() => {
    if (!isUndefined(selectedLead?.id)) {
      fetchLeadData();
    }
  }, [selectedLead?.id, messageFilters]);

  useEffect(() => {
    if (reviewInfo && reviewInfo.isActive) {
      setShowReviewRequestButton(true);
    } else {
      setShowReviewRequestButton(false);
    }
  }, [reviewInfo]);

  useEffect(() => {
    if (notificationData) {
      console.log("TEST notificationData===>", notificationData);
      if (notificationData.refresh) {
        console.log("TEST refresh===>", notificationData.refresh);
        setRefetching(true);
        refreshLeadList({
          variables: {
            ...variables,
            search: search?.trim(),
            status: isEmpty(search) ? uniq([...selectedQuickFilters, ...selectedStatus]) : [],
            campaignIds: variables?.campaignIds?.map(el => parseInt(el)),
            excludeDuplicate: true,
            userIds: [...userFilters],
            prioritySortOrder: leadSort,
            skipFilter: !isEmpty(search),
          },
        }).then(res => {
          console.log("res===>", res);
          setRefetching(false);
          const updatedLeadList = structuredClone(res?.data?.getLeads.data);
          const selectedLeadIndex = (updatedLeadList || []).findIndex(
            lead => lead?.id === selectedLead?.id
          );
          if (
            selectedLeadIndex > -1 &&
            +notificationData?.lead_id === +selectedLead?.id &&
            +notificationData?.user_id === +user?.id
          ) {
            updatedLeadList[selectedLeadIndex] = {
              ...updatedLeadList[selectedLeadIndex],
              unreadCount: 0,
            };
          }

          setLeadList([...updatedLeadList]);
          // setSelectedLead(updatedLeadList[0]);
        });
      }

      if (+notificationData?.lead_id === +selectedLead?.id) {
        broadCastUpdateUnreadCount({
          variables: {
            leadId: selectedLead?.id,
          },
        });
      }

      // if (notificationData?.lead_messages?.length > 0) {
      //   const msgContent = notificationData?.lead_messages[0]?.content?.toLowerCase();
      //   if (msgContent === "c" || msgContent === "r" || msgContent === "d") {
      //     // appointlabel was not being changed on lead list (left section)
      //     refetchLeads();
      //   }
      // }

      if (+notificationData?.lead_id === +selectedLead?.id) {
        const notificationMessages = notificationData?.lead_messages || [];
        // console.log("🚀 ~ useEffect ~ notificationMessages:", notificationMessages);
        if (notificationMessages.length > 0) {
          notificationMessages.forEach(notification => {
            const findIndex = (selectedLead?.messages || []).findIndex(
              s => +s?.id === +notification?.id
            );
            if (findIndex === -1) {
              const newMessage = {
                id: `${notification?.id}`,
                campaignId: notification?.campaign_id,
                content: notification?.content,
                translatedContent: notification?.translated_content,
                dateReceived: notification?.date_received,
                dateSent: notification?.date_sent,
                direction: notification?.direction,
                leadId: notification?.lead_id,
                userId: notification?.user_id,
                systemUserId: notification?.system_user_id,
                messageType: notification?.message_type || "",
                messageStatus: notification?.message_status || "",
                statusCode: notification?.status_code || "",
                errorCode: notification?.error_code || "",
                errorMessage: notification?.error_message || "",
                messageLog: [
                  {
                    id: notification?.message_log?.id,
                    messageId: notification?.message_log?.message_id,
                    toPhone: notification?.message_log?.to_phone,
                    fromPhone: notification?.message_log?.from_phone,
                  },
                ],
                mmsObjects: (notification?.medias || [])?.map(item => {
                  return {
                    id: item.id,
                    mediaType: item.media_type,
                    mediaUrl: item.media_url,
                    thumbnail: item.thumbnail,
                    size: item.size,
                  };
                }),
                leadOpportunity: { ...notification?.lead_opportunity },
                contentType: notification?.content_type,
              };
              const newObj = {
                ...selectedLead,
                messages: [...selectedLead.messages, { ...newMessage }],
                ...(notificationData?.text_consent_status
                  ? { textConsentStatus: notificationData?.text_consent_status }
                  : {}),
              };
              if (!selectedLead?.textConsentStatus) {
                console.log("🚀 ~ useEffect ~ notificationMessages: getLeadDetails");
                getLeadDetail({ variables: { leadId: selectedLead?.id } });
              }
              setSelectedLead({ ...newObj });
            }
          });
        }
      }
      if (notificationData?.lead_messages?.length > 0) {
        notificationData.lead_messages.forEach(notification => {
          const newMessage = {
            id: notification?.id,
            campaignId: notification?.campaign_id,
            content: notification?.content,
            translatedContent: notification?.translated_content,
            dateReceived: notification?.date_received,
            dateSent: notification?.date_sent,
            direction: notification?.direction,
            leadId: notification?.lead_id,
            userId: notification?.user_id,
            systemUserId: notification?.system_user_id,
            messageType: notification?.message_type || "",
            messageStatus: notification?.message_status || "",
            statusCode: notification?.status_code || "",
            errorCode: notification?.error_code || "",
            errorMessage: notification?.error_message || "",
            messageLog: {
              id: notification?.message_log?.id,
              messageId: notification?.message_log?.message_id,
              toPhone: notification?.message_log?.to_phone,
              fromPhone: notification?.message_log?.from_phone,
            },
            mmsObjects: (notification?.medias || [])?.map(item => {
              return {
                id: item.id,
                mediaType: item.type,
                mediaUrl: item.url,
                thumbnail: item.thumbnail,
                size: item.size,
              };
            }),
            leadOpportunity: { ...notification?.lead_opportunity },
            contentType: notification?.content_type,
            user: {
              id: user?.id,
              firstName: user?.firstName,
              lastName: user?.lastName,
            },
          };
          dispatch(addMessage({ id: newMessage.leadId, messages: newMessage }));
        });
      }
    }
  }, [notificationData]);
  useEffect(() => {
    if (
      !isUndefined(queryString) &&
      searchKey !== "" &&
      searchKey !== "remove" &&
      searchKey &&
      search !== searchKey
    ) {
      handleSearch(searchKey);
    }
    setSummaryVisible({ visible: false, count: 0 });
  }, [searchKey]);

  useEffect(() => {
    socket &&
      socket.on("message_send_status", async args => {
        let selectedLeadDetail = {
          ...selectedLead,
          textConsentStatus: lead?.textConsentStatus || selectedLead?.textConsentStatus,
        };
        if ((selectedLead?.messages || []).length <= 0) {
          selectedLeadDetail = { ...selectedLeadDetail, messages: [] };
        }
        const messagePayload = {
          leadId: +lead?.id,
          userId: args.lead_message.user_id,
          direction: "TO_LEAD",
          content: args.message,
        };
        selectedLeadDetail.messages.push({
          ...messagePayload,
          type: "new",
        });
        if (selectedLeadDetail?.leadSource?.name === "Web Chat Widget") {
          const leadDetails = { ...selectedLeadDetail };
          const findIndex = leadDetails.messages.findIndex(el => el?.type === "new");
          if (findIndex > -1) {
            leadDetails.messages[findIndex].type = "";
            leadDetails.messages[findIndex].id = args.lead_message?.id;
            leadDetails.messages[findIndex].dateSent = args.lead_message?.date_sent;
            leadDetails.messages[findIndex].messageStatus = args.lead_message?.message_status;
            leadDetails.messages[findIndex].messageType = args.lead_message?.message_type;
            leadDetails.messages[findIndex].errorMessage = args.lead_message?.error_message;
            leadDetails.messages[findIndex].errorCode = args.lead_message?.error_code;
            leadDetails.messages[findIndex].leadOpportunity = args.lead_message?.leadOpportunity ||
              args.lead_message?.lead_opportunity || { name: "", id: "" };
          }
          setSelectedLead({ ...leadDetails });

          setChangeState(!changeState);
          setbtnClicked(true);
          return false;
        }
      });

    // return () => {
    //   socket.off("message_send_status");
    // };
  }, []);

  useEffect(() => {
    const slug = window.location.pathname;
    const phoneNumber =
      `${slug?.split("/")[3]}`.replace("/", "") === "undefined" ||
      `${slug?.split("/")[3]}`.replace("/", "") === "remove"
        ? ""
        : `${slug?.split("/")[3]}`.replace("/", "") || "";
    phoneNumber && setSearchKey(phoneNumber);
  }, [window.location.href]);

  useEffect(() => {
    setimplicit(
      consent.find(item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_OFF")?.isActive
    );
    setexplicit(
      consent.find(item => item.code === "CONSENT_MESSAGE_FOR_OPTIN_CONSENT_METHOD_ON")?.isActive
    );
    setcosentAtEnd(consent.find(item => item.code === "CONSENT_MESSAGE_FOR_MESSAGE")?.isActive);
  }, [consent]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // Expire cached data periodically to fetch latest from server
  useInterval(() => {
    dispatch(resetApiCacheStore({}));
  }, parseInt(actionDispatchTime));

  const updateLoading =
    updatePhoneLoading || phoneLoading || updateEmailLoading || createEmailLoading;

  const handleLeadListDrawer = () => {
    setLeadListDrawer(prev => !prev);
  };

  const refreshLeadListData = () => {
    refreshLeadList({
      variables: {
        ...variables,
        search: search?.trim(),
        status: isEmpty(variables.search) ? uniq([...selectedQuickFilters, ...selectedStatus]) : [],
        campaignIds: variables?.campaignIds?.map(el => parseInt(el)),
        excludeDuplicate: true,
        userIds: [...userFilters],
        prioritySortOrder: leadSort,
        skipFilter: !isEmpty(variables.search),
      },
    }).then(res => {
      setLeadList(res?.data?.getLeads.data);
    });
  };

  const handleLeadProfileDrawer = () => {
    setLeadProfileDrawer(prev => !prev);
  };

  const [IsLeadListVisible, setIsLeadListVisible] = useState(true);
  const [isLeadProfileVisible, setisLeadProfileVisible] = useState(true);

  const handleLeadListVisible = () => {
    setIsLeadListVisible(prev => !prev);
  };

  const renderConsentNote = () => {
    if (afterMessageFromRedux?.content) {
      if (!onlyFirstMessage) {
        return true;
      } else {
        if (selectedLead?.messages?.length) {
          return false;
        }
        return true;
      }
    }
    return false;
  };
  const handleRefetchAndCloseDrawer = flag => {
    try {
      setRefetchAppointment(true);
      setOpenAppointmentDrawer(flag);
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleKeyDown = event => {
    if (event.keyCode === 16) {
      // 16 is the key code for the Shift key
      event.preventDefault();
      event.stopPropagation();
    }
    event.target.style.height = "inherit";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };
  useEffect(() => {
    if (text) {
      text.current.style.height = "inherit";
    }
  }, [text, selectedLead]);

  useEffect(() => {
    if (user) {
      setTargetLangForEmployee(user?.preferredLanguageCode);
      // getMessages();
    }
  }, [user]);

  const handleMMS = async mmsArr => {
    const messagePayload = {
      leadId: +selectedLead?.id,
      userId: +user.id,
      direction: "TO_LEAD",
      content: textMode === 0 ? text.current.value.trim() : richTextValue.trim(),
      toPhone:
        selectedLead?.phoneNumbers &&
        selectedLead?.phoneNumbers.length > 0 &&
        selectedLead?.phoneNumbers[0]?.phone.startsWith("+")
          ? selectedLead?.phoneNumbers[0]?.phone
          : `+${selectedLead?.phoneNumbers[0]?.phone}`,
      mmsObjects: mmsArr,
    };
    await createMMS({
      variables: messagePayload,
    });
  };
  const handleKeyUp = async event => {
    if (event.keyCode === 13 && event.shiftKey) {
      // 13 is the key code for the Enter key
      event.preventDefault();
      text.current.scrollTop = text.current.scrollHeight;
    } else if (event.keyCode === 13) {
      let mmsArr = [];
      if (attachments.length > 0) {
        mmsArr = await handleFiles(
          attachments,
          user,
          selectedLead,
          fetchData,
          setMediaUploadLoader
        );
      }
      if (mmsArr.length > 0) {
        // mms Message handler
        handleMMS(mmsArr);
      } else {
        if (
          isEmpty(messageText.trim()) &&
          isEmpty(textMode === 0 ? text.current?.value?.trim() : richTextValue)
        ) {
          // setmessageText(messageText.trim());
          return message.error(lang.pleaseEnterMessageToSend);
        }
        setmessageText(textMode === 0 ? text.current.value : richTextValue);

        if (!textDisabled()) {
          let msg;
          if (onlyFirstMessage) {
            // Consent text to be added only on the first message to lead
            if (selectedLead?.messages?.length > 0) {
              // Not the first message to lead, so consent text not required to be added.
              msg = text.current?.value.trim() || messageText.trim();
            } else {
              // Append consent text to the message
              msg =
                afterMessageConsent !== ""
                  ? `${
                      textMode === 0 ? text.current.value.trim() : richTextValue.trim()
                    } ${afterMessageConsent}`
                  : textMode === 0
                  ? text.current.value.trim()
                  : richTextValue.trim();
            }
          } else {
            // Consent text to be added on every message to lead
            msg =
              afterMessageConsent !== ""
                ? `${
                    textMode === 0 ? text.current.value.trim() : richTextValue.trim()
                  } ${afterMessageConsent}`
                : textMode === 0
                ? text.current.value.trim()
                : richTextValue.trim();
          }
          onClickHandler(msg, selectedLead, user?.id);
        } else {
          event.preventDefault();
        }
      }
    }
  };

  const removeSelectedLeadFromLeadCenter = item => {
    onUnpinLead(item);
    refetchLeads();
  };

  const placeholder = () => {
    return buttonStyle()?.background === "#F59E0B"
      ? `${lang.clickOnYellowSendIcon}`
      : buttonStyle().background === "#EF4444"
      ? "Customer has opted out, you don’t have consent!"
      : `Use Otto AI bot to write your message or type your message here...`;
  };
  function SuggestAMessage({ createSuggestedResponse, misc }) {
    return misc.loading ? (
      <Spin size="small" className="mr-2" />
    ) : (
      <Tooltip title="Suggest a message">
        <Icon
          className="text-sm mr-2"
          onClick={() => createSuggestedResponse()}
          component={EngagedAI}
          alt="SocialMiningAi"
        />
      </Tooltip>
    );
  }
  const findOpp = () => {
    return opportunityList.find(item => +item.id === +activeOpp);
  };
  const opportunityObj = useMemo(findOpp, [activeOpp, opportunityList]);
  return (
    <>
      <Spin
        spinning={activeSpinner || mediaUploadLoader}
        tip={
          mediaUploadLoader
            ? `${lang.uploadingMedia}...`
            : activeSpinner
            ? lang.openingAppointmentForm
            : ""
        }
        className="fixed top-[50%] left-[50%] z-50 font-bold bg-white py-[15px] px-[30px]"
        style={{
          transform: "translateX(-50%) translateY(-50%)",
        }}
      />
      <div className="flex w-full p-2 headerBackground items-center xl:hidden">
        <AntdIconV4
          onClick={() => handleLeadListDrawer()}
          className="basis-1/4 text-lg text-start font-medium"
          type="MenuOutlined"
        />
        <div className="basis-1/2 text-center">
          <CustomLabel labelClass="text-lg font-semibold" label={selectedLead?.fullName || ""} />
          <CustomLabel
            labelClass="text-sm text-cener font-meduim"
            label={ShowConsent(selectedLead, user)}
          />
        </div>
        <AntdIconV4
          onClick={() => handleLeadProfileDrawer()}
          className="basis-1/4 w-full text-lg flex justify-end font-medium mr-2"
          type="UserOutlined"
        />

        <Tooltip placement="top" title={!fullScreen ? "Minimize screen" : "Maximize screen"}>
          {!fullScreen ? (
            <FullscreenExitOutlined
              onClick={() => {
                setFullScreen(!fullScreen);
                onSetfull(!fullScreen);
                console.log("Hello");
                onFullScreen(!fullScreen);
                setMaximizeScreen(!fullScreen);
              }}
            />
          ) : (
            <FullscreenOutlined
              onClick={() => {
                setFullScreen(!fullScreen);
                onSetfull(!fullScreen);
                onFullScreen(!fullScreen);
                setMaximizeScreen(!fullScreen);
              }}
            />
          )}
        </Tooltip>
      </div>
      <div
        className={
          !isMobileView
            ? "flex items-start gap-3 h-[80vh] md:h-[80vh] lg:h-[85vh] xl:h-[85vh] 2xl:h-[81vh] 3xl:h-[85vh] 4xl:h-[88vh]"
            : isFullScreen
            ? "flex items-start gap-3"
            : "flex items-start gap-3 h-[85vh]"
        }
      >
        <div className="relative h-full">
          <div
            className={`hidden h-full overflow-y-auto overflow-x-hidden w-[25vw] antdList ${
              IsLeadListVisible ? "xl:block" : "xl:hidden"
            } transition-all ease-in-out delay-150`}
          >
            <LeadListContainer
              showSidebar={showSidebar}
              setShowSideBar={setShowSideBar}
              selectedLead={selectedLead}
              setSummaryVisible={setSummaryVisible}
              leadsError={leadsError}
              leadsLoading={leadsLoading}
              setShowLeadSection={setShowLeadSection}
              leadList={[...leadList]}
              setLeadList={setLeadList}
              variables={variables}
              setmessageText={setmessageText}
              leadCount={leadCount}
              lastLead={lastLead}
              setVariables={setVariables}
              setSelectedLead={setSelectedLead}
              setMessageFilters={payload => {
                setMessageFilters({ ...payload });
              }}
              messageFilters={messageFilters}
              sourcesData={sourcesData}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              handleSearch={handleSearch}
              activeOpp={activeOpp}
              setActiveOpp={setActiveOpp}
              opportunityList={opportunityList}
              activeOppName={activeOppName}
              timeReference={timeReference}
              setOpportunityList={setOpportunityList}
              setFilterApplied={setFilterApplied}
              handleOppFilter={handleOppFilter}
              setShowProfileSection={setShowProfileSection}
              selectedStatus={uniq([...selectedQuickFilters, ...selectedStatus])}
              setSelectedStatus={setSelectedStatus}
              leadStatus={leadStatus}
              handleStatusFilter={handleStatusFilter}
              handleAppointmentModalClose={handleAppointmentModalClose}
              openAppointmentPopup={openAppointmentPopup}
              getLeadDetail={getLeadDetail}
              setSelectedLeadId={setSelectedLeadId}
              setbtnClicked={setbtnClicked}
              setRefetchAppointment={setRefetchAppointment}
              setActiveLeadId={setActiveLeadId}
              handleScrollOnMovement={handleScrollOnMovement}
              eventId={eventId}
              // eslint-disable-next-line no-return-assign
              handleLeadListVisible={handleLeadListVisible}
              IsLeadListVisible={IsLeadListVisible}
              changePage={() => {
                page = 1;
              }}
              spinner={spinner}
              campaignFilters={campaignFilters}
              queryString={queryString}
              entityId={entityId}
              setAttachments={setAttachments}
              refetchLeads={refetchLeads}
              handleUnpinLead={item => removeSelectedLeadFromLeadCenter(item)}
              selectedLeadsFromLeadCenter={lead && Array.isArray(lead) ? lead : [lead]}
            />
            <Tooltip
              placement="topLeft"
              title={IsLeadListVisible ? "Hide Conversations" : "Show Conversations"}
            >
              {IsLeadListVisible ? (
                <div
                  className="hidden xl:block absolute py-[10px] z-[9] px-[6px] right-0 top-[55%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer"
                  onClick={() => handleLeadListVisible()}
                >
                  <LeftIcon />
                </div>
              ) : (
                <div
                  className="hidden xl:block absolute py-[10px] z-[9] px-[6px] right-0 top-[55%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer"
                  onClick={() => handleLeadListVisible()}
                >
                  <RightIcon />
                </div>
              )}
            </Tooltip>
          </div>
        </div>
        <div
          className="flex-1 flex flex-col h-full overflow-auto relative chatContainer"
          style={{
            borderLeft: `2px solid ${colorConfig.greyLightest}`,
            borderRight: `2px solid ${colorConfig.greyLightest}`,
          }}
        >
          <div
            id="msgEndDiv"
            className={`${
              !isMobileView
                ? !loading
                  ? "grow overflow-auto h-[55vh]"
                  : "grow overflow-hidden h-[55vh]"
                : "grow overflow-auto h-[55vh]"
            } `}
            ref={msgContainerRef}
            onScroll={onMsgScroll}
            // onWheel={handleWheel}
            // onTouchMove={handleTouchMove}
            // onTouchStart={handleTouchStart}
          >
            <Spin
              spinning={loading}
              className="mt-5 z-[999]"
              tip={loading ? `${lang.loadingMessages}...` : `${lang.loadingMoreMessages}...`}
            >
              {activeOpp !== "#DEFAULTOPPID" && (
                <div className="sticky  top-0 bg-[#F1F5F9] z-[100] border-b-4 border-b-solid border-b-[#0000000f] text-center text-black ">
                  Opportunity: {opportunityObj?.name} #
                  {opportunityObj?.opprId || opportunityObj?.id}{" "}
                </div>
              )}
              <div className="m-[1em]">
                {!error &&
                  selectedLead &&
                  selectedLead?.messages?.length > 0 &&
                  selectedLead?.id &&
                  uniqBy(selectedLead?.messages, "id")
                    .filter(message => +message?.leadId === +selectedLead?.id)
                    .sort((a, b) => {
                      const dateA = dayjs(a.dateReceived || a.dateSent);
                      const dateB = dayjs(b.dateReceived || b.dateSent);

                      // Sort in descending order, so the latest message is at the bottom
                      return dateA.isBefore(dateB) ? -1 : 1;
                    })
                    .map((c, index) => (
                      <Messages
                        key={index}
                        SetContentBy={SetContentBy}
                        c={c}
                        userIdMap={userIdMap}
                        selectedLead={selectedLead}
                        handleOpportunityId={handleOpportunityId}
                        handleRetry={handleRetry}
                        messageIndex={index}
                        isTranslationForCustomer={isTranslationForCustomer}
                        isTranslationForEmployee={isTranslationForEmployee}
                        targetLangForCustomer={targetLangForCustomer}
                        targetLangForEmployee={targetLangForEmployee}
                        canViewTranslation={canViewTranslation}
                        isIncludeOriginalMessage={isIncludeOriginalMessage}
                        isAutoTranslate={isAutoTranslate}
                      />
                    ))}
                {(selectedLead?.messages || []).length <= 0 && customizeRenderEmpty(lang)}
              </div>
            </Spin>
            <div ref={messagesEndRef} />
          </div>
          <div className="absolute right-[10px] top-[5px] z-[1000] flex flex-col">
            <Popover
              placement="left"
              title={
                <Typography variant="small" weight="bold" inline>
                  {lang.messageFilters}
                </Typography>
              }
              content={
                <LeadFilterBreadcrumbs
                  filters={messageFilters}
                  setFilters={payload => {
                    page = 1;
                    setMessageFilters({ ...payload, page: 1 });
                  }}
                  opportunityList={opportunityList}
                  activeOpp={activeOpp}
                  setActiveOpp={setActiveOpp}
                  handleOppFilter={handleOppFilter}
                  activeOppName={activeOppName}
                  isFilterBreadcrumbs
                />
              }
              trigger="click"
            >
              <Tooltip placement="topLeft" title={lang.messageFilters}>
                <Badge count={messageFilters.campaignIds.length} style={{ backgroundColor: "red" }}>
                  <CustomButton
                    type="primary"
                    shape="circle"
                    icon={<AntdIconV4 type="FilterOutlined" />}
                    className="primaryBgTheme mb-[6px]"
                  />
                </Badge>
              </Tooltip>
            </Popover>
          </div>
          {!isMobileView && <CustomDivider className="my-0" />}
          <div className="mx-[0.5em] mb-0">
            {!recording.visible ? (
              <Spin
                spinning={
                  spinner ||
                  leadsLoading ||
                  mmsLoader ||
                  (translateMessageLoader && stopLoaderForReceivedMessage)
                }
              >
                {attachments.length > 0 && (
                  <div className="w-full">
                    <Collapse className="reverse-collapse mt-2" defaultActiveKey={["1"]} accordion>
                      {attachments.length > 0 && (
                        <Collapse.Panel key="1" header={`Attached files (${attachments.length})`}>
                          <Attachments
                            key={selectedLead?.id}
                            attachments={attachments}
                            setAttachments={setAttachments}
                          />
                        </Collapse.Panel>
                      )}
                    </Collapse>
                  </div>
                )}
                {summaryVisible.visible && (
                  <ConversationSummary
                    key={selectedLead?.id}
                    isDisabled={
                      !selectedLead?.id || messageLoader || loading || spinner || leadsLoading
                    }
                    selectedLead={selectedLead}
                    user={user}
                    summaryVisible={summaryVisible}
                  />
                )}

                <div>
                  <div className="flex justify-between mt-2 items-center">
                    <div className="flex flex-row items-center gap-1">
                      <div className="absolute mb-[100px]">
                        {canViewTranslation && (
                          <Popover
                            placement="top"
                            visible={visibleTranslationSettings}
                            onVisibleChange={() => {
                              setVisibleTranslationSetting(!visibleTranslationSettings);
                            }}
                            content={
                              <>
                                <div style={{ width: 471 }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: 8,
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#334155",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Translator Settings
                                    </span>
                                    <Button
                                      type="text"
                                      onClick={() => setVisibleTranslationSetting(false)}
                                    >
                                      <SVGIcon component={CloseIconForTranslation} alt="Close" />
                                    </Button>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: 8,
                                    }}
                                  >
                                    <span>Customer Speaks:</span>
                                    <Select
                                      disabled
                                      value={targetLangForCustomer}
                                      style={{ minWidth: 190 }}
                                      onChange={value => {
                                        page = 1;
                                        if (value) {
                                          setTargetLangForCustomer(value);
                                        } else {
                                          setTargetLangForCustomer("");
                                        }
                                        updateTranslationSetting({
                                          variables: {
                                            customerLanguage: value,
                                            userLanguage: targetLangForEmployee,
                                            isAutomaticTranslation: isAutoTranslate,
                                            isIncludeOriginalMessage: isIncludeOriginalMessage,
                                            isTranslationPreview: isTranslatePreviewShow,
                                            leadId: selectedLead?.id,
                                          },
                                        });
                                      }}
                                      options={languages?.map(item => ({
                                        value: item?.languageCode,
                                        label: item?.languageName,
                                      }))}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: 8,
                                    }}
                                  >
                                    <span>You Speak:</span>
                                    <Select
                                      disabled
                                      value={targetLangForEmployee}
                                      style={{ minWidth: 190 }}
                                      onChange={value => {
                                        page = 1;
                                        if (value) {
                                          setTargetLangForEmployee(value);
                                        } else {
                                          setTargetLangForEmployee("");
                                        }
                                        updateTranslationSetting({
                                          variables: {
                                            customerLanguage: targetLangForCustomer,
                                            userLanguage: value,
                                            isAutomaticTranslation: isAutoTranslate,
                                            isIncludeOriginalMessage: isIncludeOriginalMessage,
                                            isTranslationPreview: isTranslatePreviewShow,
                                            leadId: selectedLead?.id,
                                          },
                                        });
                                      }}
                                      options={languages?.map(item => ({
                                        value: item?.languageCode,
                                        label: item?.languageName,
                                      }))}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: 8,
                                    }}
                                  >
                                    <span style={{ marginRight: 8 }}>
                                      Automatic Translation:
                                      <span className="absolute mt-[3px]">
                                        <Tooltip title="The received message will be automatically translated without manually clicking translate.">
                                          <SVGIcon component={InfoIcon} alt="info" />
                                        </Tooltip>
                                      </span>
                                    </span>
                                    <Switch
                                      checked={isAutoTranslate}
                                      onChange={value => {
                                        setIsAutoTranslate(value);
                                        updateTranslationSetting({
                                          variables: {
                                            customerLanguage: targetLangForCustomer,
                                            userLanguage: targetLangForEmployee,
                                            isAutomaticTranslation: value,
                                            isIncludeOriginalMessage: isIncludeOriginalMessage,
                                            isTranslationPreview: isTranslatePreviewShow,
                                            leadId: selectedLead?.id,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: 8,
                                    }}
                                  >
                                    <span style={{ marginRight: 8 }}>
                                      Include original message:
                                      <span className="absolute mt-[3px]">
                                        <Tooltip title="This option defines if the original from the customer and message you typed will be included or not to the translation.">
                                          <SVGIcon component={InfoIcon} alt="info" />
                                        </Tooltip>
                                      </span>
                                    </span>
                                    <Switch
                                      checked={isIncludeOriginalMessage}
                                      onChange={value => {
                                        setIsIncludeOriginalMessage(value);
                                        updateTranslationSetting({
                                          variables: {
                                            customerLanguage: targetLangForCustomer,
                                            userLanguage: targetLangForEmployee,
                                            isAutomaticTranslation: isAutoTranslate,
                                            isIncludeOriginalMessage: value,
                                            isTranslationPreview: isTranslatePreviewShow,
                                            leadId: selectedLead?.id,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: 8,
                                    }}
                                  >
                                    <span style={{ marginRight: 8 }}>
                                      Translation Preview:
                                      <span className="absolute mt-[3px]">
                                        <Tooltip title="This option is for displaying a translate icon to translate the content of the message box.">
                                          <SVGIcon component={InfoIcon} alt="info" />
                                        </Tooltip>
                                      </span>
                                    </span>
                                    <Switch
                                      checked={isTranslatePreviewShow}
                                      onChange={value => {
                                        setIsTranslatePreviewShow(value);
                                        updateTranslationSetting({
                                          variables: {
                                            customerLanguage: targetLangForCustomer,
                                            userLanguage: targetLangForEmployee,
                                            isAutomaticTranslation: isAutoTranslate,
                                            isIncludeOriginalMessage: isIncludeOriginalMessage,
                                            isTranslationPreview: value,
                                            leadId: selectedLead?.id,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            }
                            trigger="click"
                          >
                            <Tooltip placement="topLeft" title="Translate Messages">
                              <Button
                                // onClick={() => setIsTranslateClick(!isTranslateClick)}
                                type="text"
                                className="bg-brand-100"
                                style={{
                                  borderRadius: "1111px !important",
                                }}
                              >
                                <Icon
                                  className="text-xl"
                                  component={TranslateIcon}
                                  alt="Translate"
                                />
                              </Button>
                            </Tooltip>
                          </Popover>
                        )}
                      </div>
                      <Button
                        onClick={() =>
                          setSummaryVisible(prev => ({
                            visible: true,
                            count: prev.count + 1,
                          }))
                        }
                        type="text"
                        disabled={
                          !selectedLead?.id || messageLoader || loading || spinner || leadsLoading
                        }
                        className="flex items-center "
                      >
                        <SummaryIcon className="mr-2" />
                        {isMobileView
                          ? "Conv. Summ"
                          : summaryVisible.visible
                          ? "Re-generate Conversation Summary"
                          : "Conversation Summary"}
                      </Button>
                      {summaryVisible.visible && (
                        <CloseCircleFilled
                          className="text-[#64748B] cursor-pointer"
                          onClick={() => setSummaryVisible({ visible: false, count: 0 })}
                        />
                      )}
                    </div>
                    {/* </ConversationPopover> */}
                    <div className="flex">
                      <Tabs
                        key={`text-tab ${selectedLead?.id}`}
                        size="small"
                        className={`${attachments.length === 0 && "-mt-[0.5rem]"} text-mode-tabs`}
                        activeKey={`${textMode}`}
                        onChange={e => settextMode(+e)}
                      >
                        {/* <Tabs.TabPane key="0" tab="SMS" /> */}
                        {/* <Tabs.TabPane disabled key="1" tab="Chat / Email" /> */}
                        {/* <Tabs.TabPane key="2" tab="Conversation Summary" /> */}
                      </Tabs>
                      <OpportunityFilter
                        oppValue={activeOpp}
                        disabled={
                          !selectedLead?.id || messageLoader || loading || spinner || leadsLoading
                        }
                        handleFilter={handleOppFilter}
                        activeOppName={activeOppName}
                        opportunityList={opportunityList}
                      />
                      {isMobileView && (
                        <div className="text-end ml-2">
                          {!hideBottom ? (
                            <Tooltip placement="topLeft" title="Show">
                              <Badge
                                count={messageFilters.campaignIds.length}
                                style={{ backgroundColor: "red" }}
                              >
                                <CustomButton
                                  type="primary"
                                  shape="circle"
                                  icon={
                                    <ArrowUpOutlined
                                      className="text-base"
                                      onClick={() => setHideBottom(true)}
                                    />
                                  }
                                  className="primaryBgTheme mb-[6px] w-[20px]"
                                />
                              </Badge>
                            </Tooltip>
                          ) : (
                            <Tooltip placement="topLeft" title="Hide">
                              <CustomButton
                                type="primary"
                                shape="circle"
                                icon={
                                  <ArrowDownOutlined
                                    className="text-base"
                                    onClick={() => setHideBottom(false)}
                                  />
                                }
                                className="primaryBgTheme mb-[6px] w-[20px]"
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {scheduledMessages?.length > 0 && (
                    <>
                      <div className="ml-[15px] sm:block mb-3 flex flex-row items-center">
                        <TimerIcon className="mr-2" /> {scheduledMessages?.length} messages
                        scheduled to be sent to{" "}
                        <span className="text-sm ml-1 font-[600]"> {selectedLead?.fullName}</span>
                        <span
                          className="text-sm font-[600] ml-1 cursor-pointer text-brand-500"
                          onClick={() =>
                            openDialog("viewScheduledMessages", {
                              dialogHeader: `${lang.viewSchMsgPopupTitlePrefix}${selectedLead?.fullName}`,
                              scheduledMessages,
                              getMessages,
                              width: "50vw",
                              selectedLead,
                              messageText,
                            })
                          }
                        >
                          {" "}
                          See all scheduled messages
                        </span>
                      </div>
                      <Button
                        onClick={() =>
                          openDialog("viewScheduledMessages", {
                            dialogHeader: `${lang.viewSchMsgPopupTitlePrefix}${selectedLead?.fullName}`,
                            scheduledMessages,
                            getMessages,
                            width: "50vw",
                            selectedLead,
                            messageText,
                          })
                        }
                        type="text"
                        className="block flex flex-row items-center gap-1 sm:hidden"
                      >
                        <TimerIcon />
                        View Sched. messages
                      </Button>
                    </>
                  )}
                  <InputArea
                    textMode={textMode}
                    key={selectedLead?.id}
                    buttonStyle={buttonStyle}
                    text={text}
                    selectedLead={selectedLead}
                    handleKeyDown={handleKeyDown}
                    handleKeyUp={handleKeyUp}
                    textDisabled={textDisabled}
                    messageLoader={messageLoader}
                    loading={loading}
                    spinner={spinner}
                    leadsLoading={leadsLoading}
                    lang={lang}
                    setmessageText={setmessageText}
                    placeholder={placeholder}
                    messageText={messageText}
                    richTextValue={richTextValue}
                    setrichTextValue={setrichTextValue}
                    renderConsentNote={renderConsentNote}
                    afterMessageFromRedux={afterMessageFromRedux}
                    handleSetDefinedTemplate={handleSetDefinedTemplate}
                    isEmojiOpen={isEmojiOpen}
                    setIsEmojiOpen={setIsEmojiOpen}
                    scheduledMessages={scheduledMessages}
                    getMessages={getMessages}
                    user={user}
                    SuggestAMessage={SuggestAMessage}
                    tooltipTitle={tooltipTitle}
                    buttonDisabled={buttonDisabled}
                    mmsLoader={mmsLoader}
                    attachments={attachments}
                    setmmsLoader={setmmsLoader}
                    fetchData={fetchData}
                    setMediaUploadLoader={setMediaUploadLoader}
                    handleMMS={handleMMS}
                    onlyFirstMessage={onlyFirstMessage}
                    afterMessageConsent={afterMessageConsent}
                    onClickHandler={onClickHandler}
                    setCallConsentMessage={setCallConsentMessage}
                    sendConsentMessage={sendConsentMessage}
                    translateMessage={translateMessage}
                    languages={languages}
                    originalText={originalText}
                    setOriginalText={setOriginalText}
                    isTranslatePreviewShow={isTranslatePreviewShow}
                    setisReview={setisReview}
                  />
                </div>
              </Spin>
            ) : (
              <div style={{ display: "flex" }}>
                <h1>Record View</h1>
              </div>
            )}

            <div className="w-full">
              {(!user?.phone || (user?.phone === "" && showLeadSection)) && (
                <Alert
                  className="sm:w-2/4 w-full "
                  style={{ fontSize: 11 }}
                  message={
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <div className="secondaryColorTheme" onClick={() => setOpenEditUserPhone(true)}>
                      {lang.dontHaveValidNumber}
                      <AntdIconV4 type="EditOutlined" />
                    </div>
                  }
                  type="error"
                />
              )}
            </div>
            {(!isMobileView || hideBottom) && (
              <BottomActions
                attachments={attachments}
                handleMediaSharing={handleMediaSharing}
                mediaRef={mediaRef}
                videoRef={videoRef}
                documentRef={documentRef}
                setAttachments={setAttachments}
                textMode={textMode}
                handleSetDefinedTemplate={handleSetDefinedTemplate}
                text={text}
                messageText={messageText}
                textDisabled={textDisabled}
                setIsEmojiOpen={setIsEmojiOpen}
                isEmojiOpen={isEmojiOpen}
                selectedLead={selectedLead}
                user={user}
                setTemplateModal={setTemplateModal}
                handleSendReviewClick={handleSendReviewClick}
                handleAddAppointment={handleAddAppointment}
                richTextValue={richTextValue}
                setrichTextValue={setrichTextValue}
                SuggestAMessage={SuggestAMessage}
                showReviewRequestButton={showReviewRequestButton}
                refetchLeads={refetchLeads}
                setmessageText={setmessageText}
                setisReview={setisReview}
                activeOppName={activeOppName}
                opportunityList={opportunityList}
                crmIntegrationType={crmIntegration}
              />
            )}
          </div>
        </div>
        <div className="relative h-full">
          <div
            className={`hidden w-[22vw] overflow-auto h-full ${
              leadsLoading ? "pointer-events-none" : ""
            } ${
              isLeadProfileVisible ? "xl:block" : "xl:hidden"
            } transition-all ease-in-out delay-150`}
          >
            <LeadProfile
              connection={connection}
              setConnection={setConnection}
              setLeadList={setLeadList}
              socket={socket}
              canEditPhone={canEdit}
              handleAddPhoneClick={handleAddPhoneClick}
              handleSendReviewInviteClick={handleSendReviewInviteClick}
              handleBackClick={handleBackClick}
              selectedLead={selectedLead}
              showDetails={showDetails}
              setPushToCrmModalVisible={setPushToCrmModalVisible}
              setOpenEditPopup={setOpenEditPopup}
              handleUpdate={handleUpdate}
              refetchAppointment={refetchAppointment}
              handleSendConsentClick={handleSendConsentClick}
              disableConcentClick={disableConcentClick}
              concentLoader={concentLoader}
              handleActiveToggle={handleActiveToggle}
              setInlineEditField={setInlineEditField}
              updateLoading={updateLoading}
              inlineField={inlineField}
              user={user}
              isUserBDC={isUserBDC}
              updateStatusLoading={updateStatusLoading}
              setFilters={id => {
                page = 1;
                setMessageFilters({
                  ...messageFilters,
                  page: 1,
                  campaignIds: id ? [id] : [],
                });
              }}
              refetchLeadDetails={refetchLeadDetails}
              handleEditLead={lead => handleEditLead(lead)}
              setRefetchAppointment={setRefetchAppointment}
              setSelectedLead={setSelectedLead}
              setMessageFilters={setMessageFilters}
              messageFilters={messageFilters}
              refetchLeads={refetchLeads}
              crmLeadObj={selectedLead?.crmLeadObj}
              isAppointmentChangeFromLead={isAppointmentChangeFromLead}
              spinner={spinner}
              statusList={statusList}
              setstatusList={setstatusList}
              leadList={leadList}
              setShowLeadSection={setShowLeadSection}
              setOpportunityList={setOpportunityList}
              setFilterApplied={setFilterApplied}
              setSelectedLeadId={setSelectedLeadId}
              setActiveOpp={setActiveOpp}
              timeReference={timeReference}
              crmIntegration={crmIntegration}
              crmIntegrationData={crmIntegrationData}
              handleLeadDetailProfile={handleLeadDetailProfile}
              setIsClickonPotentialDuplicate={setIsClickonPotentialDuplicate}
              getLeadDetail={getLeadDetail}
              eventId={eventId}
              userIdMap={userIdMap}
              getMessages={getMessages}
              activeOpportunity={activeOpp}
              languages={languages}
            />
          </div>
          <Tooltip
            placement="topLeft"
            title={isLeadProfileVisible ? "Hide Profile" : "Show Profile"}
          >
            {isLeadProfileVisible ? (
              <div
                className={`xl:block absolute py-[10px] z-[9] px-[6px]  right-auto top-[55%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer ${
                  isLeadProfileVisible ? "-left-[15px]" : "-left-[30px]"
                }`}
                onClick={() => setisLeadProfileVisible(prev => !prev)}
              >
                <RightIcon />
              </div>
            ) : (
              <div
                className={`xl:block absolute py-[10px] z-[9] px-[6px]  right-auto top-[55%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer ${
                  isLeadProfileVisible ? "-left-[15px]" : "-left-[30px]"
                }`}
                onClick={() => setisLeadProfileVisible(prev => !prev)}
              >
                <LeftIcon />
              </div>
            )}
          </Tooltip>
        </div>
        {!IsLeadListVisible && (
          <Tooltip
            placement="topLeft"
            title={IsLeadListVisible ? "Hide Conversations" : "Show Conversations"}
          >
            <div
              className="fixed py-[10px] px-[6px] top-[50%] left-[5%] bg-[#E6F0FF] border border-solid border-sky-500 rounded-[4px] z-1 cursor-pointer"
              onClick={() => handleLeadListVisible()}
            >
              <RightIcon />
            </div>
          </Tooltip>
        )}
      </div>
      <Drawer
        title={lang.leadList}
        placement="left"
        onClose={handleLeadListDrawer}
        bodyStyle={{ padding: 0 }}
        open={leadListDrawer}
      >
        <LeadListContainer
          setSummaryVisible={setSummaryVisible}
          showSidebar={showSidebar}
          setShowSideBar={setShowSideBar}
          selectedLead={selectedLead}
          leadsError={leadsError}
          leadsLoading={leadsLoading}
          setmessageText={setmessageText}
          setShowLeadSection={setShowLeadSection}
          leadList={leadList}
          setLeadList={setLeadList}
          variables={variables}
          leadCount={leadCount}
          lastLead={lastLead}
          handleLeadListDrawer={handleLeadListDrawer}
          setVariables={setVariables}
          setSelectedLead={setSelectedLead}
          setMessageFilters={payload => {
            textMode === 0 ? (text.current.value = "") : setrichTextValue("");
            setMessageFilters({ ...payload });
          }}
          messageFilters={messageFilters}
          sourcesData={sourcesData}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          handleSearch={handleSearch}
          activeOpp={activeOpp}
          setActiveOpp={setActiveOpp}
          opportunityList={opportunityList}
          activeOppName={activeOppName}
          timeReference={timeReference}
          setOpportunityList={setOpportunityList}
          setFilterApplied={setFilterApplied}
          handleOppFilter={handleOppFilter}
          setShowProfileSection={setShowProfileSection}
          selectedStatus={uniq([...selectedQuickFilters, ...selectedStatus])}
          setSelectedStatus={setSelectedStatus}
          leadStatus={leadStatus}
          handleStatusFilter={handleStatusFilter}
          handleAppointmentModalClose={handleAppointmentModalClose}
          openAppointmentPopup={openAppointmentPopup}
          getLeadDetail={getLeadDetail}
          setSelectedLeadId={setSelectedLeadId}
          setbtnClicked={setbtnClicked}
          setRefetchAppointment={setRefetchAppointment}
          setActiveLeadId={setActiveLeadId}
          handleScrollOnMovement={handleScrollOnMovement}
          eventId={eventId}
          // eslint-disable-next-line no-return-assign
          changePage={() => {
            page = 1;
          }}
          spinner={spinner}
          campaignFilters={campaignFilters}
          queryString={queryString}
          entityId={entityId}
          setAttachments={setAttachments}
          refetchLeads={refetchLeads}
        />
      </Drawer>
      <Drawer
        title={lang.leadProfile}
        placement="right"
        bodyStyle={{ padding: 5 }}
        onClose={handleLeadProfileDrawer}
        open={leadProfileDrawer}
      >
        <LeadProfile
          connection={connection}
          setConnection={setConnection}
          socket={socket}
          canEditPhone={canEdit}
          refetchLeadDetails={refetchLeadDetails}
          handleAddPhoneClick={handleAddPhoneClick}
          handleSendReviewInviteClick={handleSendReviewInviteClick}
          handleBackClick={handleBackClick}
          selectedLead={selectedLead}
          showDetails={showDetails}
          setPushToCrmModalVisible={setPushToCrmModalVisible}
          setOpenEditPopup={setOpenEditPopup}
          handleUpdate={handleUpdate}
          refetchAppointment={refetchAppointment}
          handleSendConsentClick={handleSendConsentClick}
          disableConcentClick={disableConcentClick}
          concentLoader={concentLoader}
          handleActiveToggle={handleActiveToggle}
          setInlineEditField={setInlineEditField}
          updateLoading={updateLoading}
          inlineField={inlineField}
          user={user}
          isUserBDC={isUserBDC}
          updateStatusLoading={updateStatusLoading}
          setFilters={id => {
            page = 1;
            setMessageFilters({
              ...messageFilters,
              page: 1,
              campaignIds: id ? [id] : [],
            });
          }}
          handleEditLead={lead => handleEditLead(lead)}
          setRefetchAppointment={setRefetchAppointment}
          setSelectedLead={setSelectedLead}
          setMessageFilters={setMessageFilters}
          messageFilters={messageFilters}
          refetchLeads={refetchLeads}
          crmLeadObj={selectedLead?.crmLeadObj}
          isAppointmentChangeFromLead={isAppointmentChangeFromLead}
          spinner={spinner}
          statusList={statusList}
          setstatusList={setstatusList}
          leadList={leadList}
          setShowLeadSection={setShowLeadSection}
          setOpportunityList={setOpportunityList}
          setFilterApplied={setFilterApplied}
          setSelectedLeadId={setSelectedLeadId}
          setActiveOpp={setActiveOpp}
          timeReference={timeReference}
          crmIntegration={crmIntegration}
          setLeadList={setLeadList}
          crmIntegrationData={crmIntegrationData}
          handleLeadDetailProfile={handleLeadDetailProfile}
          setIsClickonPotentialDuplicate={setIsClickonPotentialDuplicate}
          getLeadDetail={getLeadDetail}
          userIdMap={userIdMap}
          eventId={eventId}
        />
      </Drawer>
      {openAppointmentDrawer && (
        <AppointmentModal
          lead={selectedLead}
          openAppointmentDrawer={openAppointmentDrawer}
          refetchLeads={refetchLeads}
          eventId={eventId}
          assignedUserId={
            (selectedLead?.activeAppointment && selectedLead?.activeAppointment?.user?.id) ||
            user.id
          }
          setOpenDrawer={flag => {
            handleRefetchAndCloseDrawer(flag);
          }}
          leadOpportunities={selectedLead?.leadOpportunities}
          onClick={async (item, flag, updateDate, isClickOnName) => {
            if (item?.id === selectedLead?.id && isClickOnName) {
              setOpenAppointmentDrawer(false);
              return;
            }
            if (isClickOnName) {
              setOpenAppointmentDrawer(false);
              handleLeadDetailProfile(item?.id);
              handleScrollOnMovement();
              return;
            }

            handleLeadDetailProfile(item?.id);
            handleScrollOnMovement();
            setOpenAppointmentDrawer(false);
            setIsAppointmentChangeFromLead(prev => !prev);
          }}
        />
      )}
      {openTemplateModal && (
        <TemplateDrawer
          openTemplateDrawer={openTemplateModal}
          setOpenTemplateDrawer={setTemplateModal}
          onApply={handleSetDefinedTemplate}
          leadId={selectedLead?.id}
        />
      )}
      <style jsx>{`
        .chatContainer::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </>
  );
};

export default EngagementActivity;
