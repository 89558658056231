/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/media-has-caption */
import { isNaN } from "lodash";
import React, { useState, useRef, useEffect } from "react";
import AntdIconV4 from "../../../utils/antdIcon";
import CustomButton from "../../commonComponents/button";
import styles from "./AudioPlayer.module.css";

const AudioPlayer = props => {
  const { chatblock = true, isBtnClicked = false, audioLink } = props;

  // references
  const audioPlayer = useRef(); // reference our audio component
  const progressBar = useRef(); // reference our progress bar
  const animationRef = useRef(); // reference the animation

  const [isPlaying, setIsPlaying] = useState(isBtnClicked || false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100}%`
    );
    setCurrentTime(progressBar.current.value);
  };

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  useEffect(() => {
    setIsPlaying(isBtnClicked);
    if (isBtnClicked) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }, [isBtnClicked]);

  useEffect(() => {
    const seconds = Math.floor(audioPlayer.current.duration);
    setDuration(seconds);
    progressBar.current.max = seconds;
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

  const calculateTime = secs => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const backThirty = () => {
    progressBar.current.value = Number(progressBar.current.value - 30);
    changeRange();
  };

  const forwardThirty = () => {
    progressBar.current.value = Number(progressBar.current.value + 30);
    changeRange();
  };

  return (
    <div className={"audioPlayer"}>
      {/* current time */}
      <div className={chatblock ? "playerWrapper" : "playerWrapperChat"}>
        {chatblock && <div className={"currentTime"}>{calculateTime(currentTime)}</div>}
        {/* progress bar */}
        <div>
          <input
            type="range"
            className={"progressBar"}
            defaultValue="0"
            ref={progressBar}
            onChange={changeRange}
          />
        </div>

        {/* duration */}
        {chatblock && (
          <div className={"duration"}>
            {duration && !isNaN(duration) && calculateTime(duration)}
          </div>
        )}
      </div>
      <audio
        ref={audioPlayer}
        src={
          audioLink ||
          "https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3"
        }
        preload="metadata"
      ></audio>
      {chatblock && (
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <CustomButton
            className={"forwardBackward"}
            handleClick={backThirty}
            icon={
              <AntdIconV4
                type="StepBackwardOutlined"
                style={{ fontSize: "20px", cursor: "pointer" }}
              />
            }
            btnText="30"
          />

          <CustomButton
            className={"forwardBackward"}
            handleClick={togglePlayPause}
            icon={
              isPlaying ? (
                <AntdIconV4
                  type="PauseCircleOutlined"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
              ) : (
                <AntdIconV4
                  type="PlayCircleOutlined"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
              )
            }
          />

          <CustomButton
            className={"forwardBackward"}
            handleClick={forwardThirty}
            icon={
              <AntdIconV4
                type="StepForwardOutlined"
                style={{ fontSize: "20px", cursor: "pointer" }}
              />
            }
            btnText="30"
          />
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;
