/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Select, Spin, message } from "antd";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import { getMessaging, getToken } from "firebase/messaging";
import { navigate, Link } from "gatsby";
import isEmpty from "lodash/isEmpty";
import React, { useContext, useEffect, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components";
import io from "socket.io-client";
// eslint-disable-next-line no-unused-vars
import { sortBy } from "lodash";
import firebase from "./firebaseInit/firebase";
import { UserContext } from "../amplify/authenticator-provider";
import { GRAPHQL_URL, V1_SOCKET_URL } from "../config";
import {
  CREATE_FCM_DEVICE,
  CREATE_USAGE_EVENT,
  DELETE_FCM_DEVICE,
  UPDATE_NOTIFICATION,
  UPDATE_USER,
  UPDATE_USER_DEFAULT_COMPANY_ID,
  UPDATE_USER_PHONE,
} from "../graphql/mutation";
import {
  GET_APPLICATION_LEVELS,
  GET_APPLICATION_USE_CASES,
  GET_CRM_INTEGRATION_TYPE,
  GET_LEAD_PHONE_LOCATION,
  GET_LOCATIONS,
  GET_NOTIFICATIONS,
  GET_PERMISSION_LEAD_IDS,
  GET_UNREAD_NOTIFICATIONS_COUNT,
  GET_USER_ACCESS_PERMISSIONS,
  ME,
} from "../graphql/query";
import { useInterval, usePageVisibilityWithBeatTracker } from "../hooks";
import useLang from "../hooks/useLang";
import { getCognitoRoles } from "../hooks/utils";
import checkIcon from "../icons/svg/Toast/check.svg";
import errorIcon from "../icons/svg/Toast/error.svg";
import infoIcon from "../icons/svg/Toast/info.svg";
import warningIcon from "../icons/svg/Toast/warning.svg";
import { colorConfig } from "../library/colorConfig";
import { AccessPermissionAction } from "../library/constants";
import { checkAccessPermission } from "../library/helpers";
import ChatBotPopup from "../pages/browser-extension-v1";
import BrowserExtensionPopup from "../pages/browser-extension-v2";
import { setAiApplicationUseCases, setApplicationLevels } from "../redux/slices/aiSlice";
import { getCacheApi, setCrmType, setPermittedLeadIds } from "../redux/slices/apiCacheSlice";
import { reducerSetAppointmentUserIds } from "../redux/slices/appointmentSlice";
import { setMobileView } from "../redux/slices/isMobile";
import { getLocationId, setLocationIdSlice } from "../redux/slices/locationSlice";
import {
  fsIdentify,
  handleProfileMenuClick,
  posthogIdentify,
} from "./layoutComponent/layoutHelpers";
import MainLayout from "./layoutComponent/mainLayout";
import UpdatePhone from "./layoutComponent/updatePhone";
import Toast from "./toast/Toast";
import { setLeadCountChange } from "../redux/slices/leadSlice";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin

dayjs.extend(timezone);
dayjs.extend(utc);

const endPoint = GRAPHQL_URL.replace("/graphql", "");

const PINE_BELT_CHEVEROLATE_LAKEWOOD = "Pine Belt Chevrolet Lakewood";
const PINE_BELT_CHRYSLER_JDM = "Pine Belt Chrysler Jeep";

let queryStringLocation = "";

export const ErrorContainter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-size: cover;
  padding: 5em;
  font-family: Helvetica;
  text-align: center;
`;

function fallbackRender({ error, resetErrorBoundary }) {
  return (
    <div className="min-h-screen">
      <ErrorContainter>
        <div className={`flex-1 md:pl-[4.5rem h-[calc(100vh-56px)]"}`}>
          <div role="alert">
            <h3 style={{ color: "red" }}>Whoops...</h3>
            <h4>This page got lost due to something went wrong..</h4>
            <Button
              type="link"
              onClick={() => {
                if (window.location.href?.includes("chat-bot")) {
                  window.reload();
                } else {
                  window.location = "/engagements/dashboard";
                }
              }}
            >
              Go back to the homepage
            </Button>
          </div>
        </div>
      </ErrorContainter>
    </div>
  );
}

const Layout = props => {
  const { children, authData, updateUser, updateUserWithPermissions } = props;
  const locationIdslice = useSelector(state => getLocationId(state));
  const permittedLeadIdsReduxData = useSelector(state => getCacheApi(state, "permittedLeadIds"));

  const dispatch = useDispatch();

  const [lang] = useLang();
  const {
    user,
    setNotificationData,
    setUser,
    setleadIds,
    setSocketMsgSelectedLead,
    setmodal,
    persistor,
    socket,
    setSocket,
    setNewLead,
    setAddNewLead,
    notificationData,
  } = useContext(UserContext);

  let toastProperties = null;
  const location = window.location.pathname;
  const locationChatBot = window.location.search;
  const locationParamas = new URLSearchParams(locationChatBot);
  const locationName = locationParamas.get("locationName");
  const getCRM = locationParamas.get("crmName") || "DS";
  const accessPayload = authData.signInUserSession.accessToken.payload;
  const checkValue = true;
  const autoDeleteTime = 10;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const loc = urlParams.get("loc");
  const currentPath = `/${children.props.location.pathname.split("/")[1]}`;
  const { filters } = useSelector(state => state.appointments);
  const { userIds } = filters;
  const [userFullName, setUserFullName] = useState(user ? user.fullName : "");
  const [userAccounts, setUserAccounts] = useState(user ? user.userAccounts : "");
  const [userAccountOptions, setUserAccountOptions] = useState(null);
  const [showUserAccountOptions, setShowUserAccountOptions] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showNotifModal, setShowNotifModal] = useState();
  const [selectedNotif, setSelectedNotif] = useState({});
  const [currentUserCompany, setcurrentUserCompany] = useState(user.company.id);
  const [locationList, setLocationList] = useState([]);
  const [phone, setPhone] = useState("");
  const [locationId, setLocation] = useState();
  const [mobileaddModal, setmobileaddModal] = useState({
    modalState: false,
    data: {},
  });
  const [contentStyle, setContentStyle] = useState(false);
  const [canRedirect, setCanRedirect] = useState(true);
  const [location2, setlocation2] = useState([]);
  const [gotLocationId, setGotLocationId] = useState(getCRM !== "DS");
  const [list, setList] = useState([]);
  const [skipper, setSkipper] = useState({
    permissions: false,
  });
  const [hasMore, setHasMore] = useState(true);
  const [changed, setchanged] = useState(false);

  const { loading: permissionLeader, refetch: refetchPermissions } = useQuery(
    GET_USER_ACCESS_PERMISSIONS,
    {
      skip: skipper.permissions,
      onCompleted: resp => {
        if ((resp?.getUserPermissionActions || []).length > 0) {
          const userDetail = {
            ...user,
            accessControlPermissions: resp?.getUserPermissionActions,
          };
          setUser({ ...userDetail });
          setSkipper({ ...skipper, permissions: true });
        }
      },
    }
  );

  const [getPermissionLeadIds] = useLazyQuery(GET_PERMISSION_LEAD_IDS, {
    fetchPolicy: "cache-first",
    onCompleted: resp => {
      setleadIds(resp?.getPermissionLeadIds);
      dispatch(setPermittedLeadIds({ permittedLeadIds: resp?.getPermissionLeadIds }));
      setSkipper({ ...skipper, permissionsIds: true });
    },
  });
  useEffect(() => {
    if (getCognitoRoles(accessPayload)[0] !== "ADMIN") {
      getPermissionLeadIds();
      // setleadIds(permittedLeadIdsReduxData);
      // if (permittedLeadIdsReduxData.length === 0) {
      // } else {
      // }
    }
  }, []);

  const showToast = (type, title, message, leadId = "") => {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: title,
          description: message,
          backgroundColor: colorConfig.primaryDark,
          icon: checkIcon,
          lead_id: leadId,
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: title,
          description: message,
          backgroundColor: "#d9534f",
          icon: errorIcon,
        };
        break;
      case "info":
        toastProperties = {
          id,
          title: title,
          description: message,
          backgroundColor: "#5bc0de",
          icon: infoIcon,
        };
        break;
      case "warning":
        toastProperties = {
          id,
          title: title,
          description: message,
          backgroundColor: "#f0ad4e",
          icon: warningIcon,
        };
        break;
      default:
        setList([...list]);
        break;
    }
    setList(prev => [...prev, toastProperties]);
  };

  const [createFcmDevice] = useMutation(CREATE_FCM_DEVICE);
  const [deleteFcmDevice] = useMutation(DELETE_FCM_DEVICE);

  const [updateUserDefaultCompanyId] = useMutation(UPDATE_USER_DEFAULT_COMPANY_ID, {
    onCompleted: () => {
      window.location.href = "/";
    },
  });

  const [getMe, { data: meData, error: meError }] = useLazyQuery(ME, {
    // fetchPolicy: "network-only",
    onCompleted: result => {
      // console.log("layout.js:getMe completed");
      updateUser(result?.me);
      const showPhoneAdder = localStorage.getItem("showPhone");
      if (
        showPhoneAdder === "true" &&
        getCognitoRoles(accessPayload)[0] !== "ADMIN" &&
        (result?.me?.phone === null ||
          result?.me?.phone === undefined ||
          result?.me?.phone?.length === 0)
      ) {
        const obj = {
          userId: result.me.id,
          firstName: result.me.firstName,
          lastName: result.me.lastName,
          email: result.me.email,
        };
        setmobileaddModal({
          modalState: true,
          data: {
            ...obj,
          },
        });
      }
      const allActiveUserAccounts = result.me.userAccounts.filter(res => {
        if (!res.company.isDisabled && !res.isDisabled) {
          return true;
        }
      });
      const allUserAccounts = allActiveUserAccounts.map(res => {
        return res.company;
      });
      const isUserActiveInCompany = allActiveUserAccounts.filter(res => {
        if (+result.me.company.id === +res.company.id) {
          return res.company;
        }
      });
      if (
        (result.me.company.isDisabled || isUserActiveInCompany.length === 0) &&
        allUserAccounts.length > 0
      ) {
        updateUserDefaultCompanyId({
          variables: { companyId: allUserAccounts[0].id, userId: result.me.id },
        });
        setCanRedirect(false);
        user.company.isDisabled = false;
      }
    },
  });

  const [{ loading }] = useMutation(UPDATE_USER, {
    onCompleted: data => {
      if (data.updateUser.ok) {
        message.success(lang.phoneAdded);
        setmobileaddModal({ modalState: false, data: {} });
        localStorage.setItem("showPhone", "false");
      }
    },
  });

  const [updateUserPhone, { loading: phoneSpinner }] = useMutation(UPDATE_USER_PHONE, {
    onCompleted: data => {
      if (data.updateUserPhone.statusCode === 200) {
        message.success(data.updateUserPhone.message || lang.phoneAdded);
        setmobileaddModal({ modalState: false, data: {} });
        localStorage.setItem("showPhone", "false");
      } else {
        message.error(data.updateUserPhone.message || lang.invalidPhoneLookup);
      }
    },
  });

  const getActiveCrmIntegration = currentLocationId => {
    let type = "";
    if ((meData?.me?.company?.crmIntegration || []).length > 0) {
      const findIndex = (meData?.me?.company?.crmIntegration).findIndex(
        el => el.active && String(el.locationId) === String(currentLocationId)
      );

      if (findIndex > -1) {
        type = meData?.me?.company?.crmIntegration[findIndex].integrationType;
      }
    }
    return type;
  };

  const [getLocations, { data: resp }] = useLazyQuery(GET_LOCATIONS, {
    variables: { companyId: user?.company?.id },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: res => {
      const locations = res?.getLocations || [];
      if (queryStringLocation !== "" && queryStringLocation !== undefined) {
        setQueryStringLocation();
      } else if (locations.length > 0) {
        const location = locations.find(el => el.isDefault);
        if (location && isEmpty(sessionStorage.getItem("currentLocationId"))) {
          setLocation(String(location?.id || ""));
          const crmIntegrationType = getActiveCrmIntegration(location?.id);
          sessionStorage.setItem("currentLocationId", location?.id);
          setUser({
            ...user,
            company: { ...user.company, crmIntegrationType },
          });
        }
      }
    },
  });

  const setQueryStringLocation = () => {
    try {
      if (queryStringLocation !== "") {
        const findIndex = (resp?.getLocations || []).findIndex(
          el => +el.id === +queryStringLocation
        );

        if (findIndex > -1) {
          setLocation(queryStringLocation);
          sessionStorage.setItem("currentLocationId", queryStringLocation);
          const crmIntegrationType = getActiveCrmIntegration(location?.id);
          setUser({
            ...user,
            locationId: queryStringLocation,
            company: { ...user.company, crmIntegrationType },
          });
        }
      }
    } catch (error) {
      console.log("Error in setQueryStringLocation", error);
    }
  };

  const [getLeadLocationByPhone] = useLazyQuery(GET_LEAD_PHONE_LOCATION, {
    fetchPolicy: "network-only",
    variables: { phone },
    skip: phone === "" || phone === "undefined" || !phone,
    onCompleted: result => {
      if (phone && phone !== "undefined") {
        const locId = result?.getLeadPhoneLocation?.id || "";
        const locationIndex = location2.findIndex(el => +el.id === +locId);
        if (locId !== "") {
          if (locationIndex !== -1 && !sessionStorage.getItem("currentLocationId")?.includes(",")) {
            if (locId !== "") {
              const id = sessionStorage.getItem("currentLocationId");
              if (id !== locId) {
                sessionStorage.setItem("currentLocationId", locId);
                +locationIdslice !== +id && dispatch(setLocationIdSlice({ locationId: locId }));
              }
            }
          } else {
            const id = sessionStorage.getItem("currentLocationId");
            if (id !== location2[0]?.id && !id?.includes(",")) {
              sessionStorage.setItem("currentLocationId", location2[0]?.id);
              +locationIdslice !== +id &&
                dispatch(setLocationIdSlice({ locationId: location2[0]?.id }));
            }
          }
        }
        setmodal(true);
      }
    },
  });

  useEffect(() => {
    if (meData && resp?.getLocations) {
      const defaultId = [{ ...meData?.me?.location }];

      // ! if will only run if get locationName param from url (which basically will get from DS)
      if (locationName) {
        setGotLocationId(false);
        const ids = (meData?.me?.locations || []).map(item => item?.location?.id);
        const duplicates = (resp.getLocations || []).filter(val => ids.indexOf(val.id) !== -1);
        // * finding the locationId from the locaiton name for DS
        const locationData = duplicates?.filter(
          loc =>
            loc?.title?.toLowerCase() ===
            (PINE_BELT_CHEVEROLATE_LAKEWOOD?.toLowerCase() === locationName?.toLowerCase()
              ? "pine belt chevrolet"
              : PINE_BELT_CHRYSLER_JDM?.toLowerCase() === locationName?.toLowerCase()
              ? "pine belt chrysler jeep dodge ram"
              : locationName?.toLowerCase())
        );

        const route = `${location.split("/")[1]}`.replace("/", "");
        const phone = `${location.split("/")[2]}`.replace("/", "");
        if (
          !window.location.search.match(/^loc$/) &&
          locationData?.length > 0 &&
          locationData[0]?.id !== sessionStorage.getItem("currentLocationId")
        ) {
          sessionStorage.getItem("currentLocationId") !== locationData[0]?.id &&
            dispatch(setLocationIdSlice({ locationId: locationData[0]?.id }));
          sessionStorage.setItem("currentLocationId", locationData[0]?.id);
          setGotLocationId(true);
          window.history.pushState(
            "",
            "",
            `/${route}/${phone}${window.location.search}&loc=${locationData[0]?.id}`
          );
        } else {
          setGotLocationId(true);
          setmodal(true);
        }
      }
      if (meData?.me?.locations?.length > 0) {
        const ids = meData?.me?.locations.map(item => {
          return item?.location?.id;
        });
        const duplicates = resp.getLocations.filter(val => {
          return ids.indexOf(val.id) !== -1;
        });
        setlocation2([...duplicates]);
      } else {
        setlocation2([...defaultId]);
      }
      if (phone !== "" && phone !== "undefined" && phone) {
        getLeadLocationByPhone();
      } else {
        if (locationName) return;
        setmodal(true);
      }
    }
  }, [meData, resp?.getLocations]);

  const { data: notificationsCount, refetch: refetchNotificationsCount } = useQuery(
    GET_UNREAD_NOTIFICATIONS_COUNT
  );
  const {
    type: notificationFilter,
    isReadOnly,
    sortBy,
    notificationsFor,
    leadIds,
  } = useSelector(state => state.user.notificationFilters);
  console.log("🚀 ~ Layout ~ notificationsFor:", notificationsFor);
  const [notifications, setNotifications] = useState([]);
  const [
    getNotifications,
    {
      refetch: refetchNotifications,
      fetchMore: fetchMoreNotifications,
      loading: notificationLoader,
    },
  ] = useLazyQuery(GET_NOTIFICATIONS, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      setNotifications(res?.getNotifications || []);
    },
  });

  useEffect(() => {
    if (notificationData && notificationData.refresh) {
      refetchNotificationsCount();
      refetchNotifications().then(res => {
        setNotifications(res?.data?.getNotifications || []);
      });
    }
  }, [notificationData]);

  // console.log("🚀 ~ file: layout.js:465 ~ Layout ~ notifications:", notifications);
  const refetchNotificationHandler = () => {
    getNotifications({
      variables: {
        isRead: isReadOnly,
        notificationTypes: notificationFilter,
        leadIds: notificationsFor === "all" ? [] : leadIds,
        orderBy: sortBy === "date" ? "date" : "read",
        ...(sortBy === "unread" && { orderDirection: "asc" }),
        ...(sortBy === "read" && { orderDirection: "desc" }),
      },
    });
  };
  useEffect(() => {
    refetchNotificationHandler();
  }, [isReadOnly, notificationFilter, sortBy, notificationsFor, leadIds]);

  const [updateNotification] = useMutation(UPDATE_NOTIFICATION, {
    onCompleted: () => {
      refetchNotifications({
        variables: {
          isRead: isReadOnly,
          notificationTypes: notificationFilter,
          leadIds,
          orderBy: sortBy === "date" ? "date" : "read",
          ...(sortBy === "unread" && { orderDirection: "asc" }),
        },
      });
      refetchNotificationsCount();
    },
  });

  const [createUsageEvent] = useMutation(CREATE_USAGE_EVENT, {
    onCompleted: res => {
      const now = dayjs();
      const eventId = sessionStorage.getItem("eventId");
      const controlTime = sessionStorage.getItem("controlTime");
      const diff = now.diff(controlTime, "days");
      if (!eventId) {
        sessionStorage.setItem("eventId", res.createUsageEvent.event.id);
        sessionStorage.setItem("controlTime", now);
      } else if (eventId && diff >= 1) {
        sessionStorage.removeItem("eventId");
        sessionStorage.removeItem("controlTime");
      }
    },
  });
  useQuery(GET_APPLICATION_USE_CASES, {
    onCompleted: res => {
      if ((res?.getApplicationUseCases || []).length > 0) {
        dispatch(setAiApplicationUseCases(res?.getApplicationUseCases || []));
      }
    },
  });
  useQuery(GET_APPLICATION_LEVELS, {
    onCompleted: res => {
      if ((res?.getApplicationLevels || []).length > 0) {
        dispatch(setApplicationLevels(res?.getApplicationLevels || []));
      }
    },
  });
  usePageVisibilityWithBeatTracker(({ verb, duration }) => {
    if (Auth.user) {
      const storedEventId = sessionStorage.getItem("eventId");
      createUsageEvent({
        variables: {
          verb,
          context: location,
          duration,
          eventId: storedEventId !== undefined ? storedEventId : null,
        },
      });
    }
  });

  // Ask for new notifications each minute
  useInterval(() => {
    refetchNotifications();
    refetchNotificationsCount();
  }, 60000);

  const handleisAll = () => {
    if (sessionStorage.getItem("currentLocationId")?.includes(",")) {
      return true;
    }
    return false;
  };

  const setUserAccessLocations = user => {
    if (user) {
      const locations = (user?.locations || []).map(el => el.location);
      if (locations.length > 0) {
        const location = locations.find(el => +el?.id === +user?.locationId);
        if (location && isEmpty(sessionStorage.getItem("currentLocationId"))) {
          setLocation(String(location?.id || ""));
          const crmIntegrationType = getActiveCrmIntegration(location?.id);
          setUser({
            ...user,
            company: { ...user.company, crmIntegrationType },
          });
        }
      }

      if (loc) {
        console.log("layout:setUserAccessLocations:loc", loc);
        const locationIndex = locations.findIndex(el => +el.id === +loc);
        +locationIdslice !== +sessionStorage.getItem("currentLocationId") &&
          dispatch(setLocationIdSlice({ locationId: loc }));
        sessionStorage.setItem("currentLocationId", loc);
        if (locations.length > 0 && locationIndex === -1) {
          sessionStorage.setItem("currentLocationId", locations[0]?.id);
          const phone = `${location.split("/")[3]}`.replace("/", "");
          window.history.pushState(
            "",
            "",
            `/engagements/activity-center/${phone}?loc=${locations[0]?.id}`
          );
          sessionStorage.getItem("currentLocationId") !== loc && window.location.reload();
        }
      } else {
        const locationId = locations.findIndex(
          el => +el?.id === +sessionStorage.getItem("currentLocationId")
        );
        if (locationId === -1 && handleisAll() === false) {
          let sLocations = locations[0].id;
          const crmIntegrationType = getActiveCrmIntegration(sLocations);
          if (crmIntegrationType === "DT") {
            sLocations = locations.map(location => location.id).join(",");
          }
          sessionStorage.setItem("currentLocationId", sLocations);
        }
      }

      if (locations.length > 0) {
        setLocationList(locations);
      } else {
        setLocationList([meData?.me?.location]);
        sessionStorage.setItem("currentLocationId", meData?.me?.location?.id);
      }
    }
  };

  useEffect(() => {
    if (
      (!sessionStorage.getItem("currentLocationId") ||
        sessionStorage.getItem("currentLocationId") === null) &&
      user?.location?.id
    ) {
      sessionStorage.setItem("currentLocationId", user?.location?.id);
    }
  }, [location2, user]);

  useEffect(() => {
    setLocation(
      sessionStorage.getItem("currentLocationId")?.includes(",") ||
        sessionStorage.getItem("currentLocationId") == null ||
        sessionStorage.getItem("currentLocationId") === "undefined"
        ? "all"
        : sessionStorage.getItem("currentLocationId")
    );
  }, [changed]);

  useEffect(() => {
    localStorage.removeItem("isOpporDialogFlagChangedLocal");
    localStorage.removeItem("clickLeadId");
    try {
      let qPhone = `${location.split("/")[3]}`.replace("/", "");
      if (locationName) {
        return;
      }
      if (window.location.pathname.includes("browser-ext")) {
        qPhone = `${location.split("/")[2]}`.replace("/", "");
      }
      queryStringLocation = !isEmpty(document.location.search)
        ? document.location.search.split("loc=")[1]
        : meData?.locationId;
      if (
        queryStringLocation !== "" &&
        queryStringLocation !== undefined &&
        queryStringLocation !== "undefined"
      ) {
        setQueryStringLocation();
      } else if (!isEmpty(qPhone) && queryStringLocation !== "undefined") {
        setPhone(qPhone);
      } else if (!isEmpty(sessionStorage.getItem("currentLocationId"))) {
        setLocation(sessionStorage.getItem("currentLocationId"));
      } else {
        setLocation(user?.locationId || "");
      }
    } catch (err) {
      console.log("Error in useEffect[]", err);
    }
  }, []);

  useEffect(() => {
    // console.log("layout.js:useeffect on medata");
    if (meData) {
      // console.log("layout.js:useeffect on medata: present");
      !user?.id && updateUser(meData.me);
      if (isEmpty(sessionStorage.getItem("currentLocationId")) && queryStringLocation === "") {
        sessionStorage.setItem(
          "currentLocationId",
          (meData.me && String(meData?.me?.locationId || "")) || undefined
        );
        setLocation((meData.me && String(meData?.me?.locationId || "")) || undefined);
      }
      setUserAccessLocations({ ...user, ...meData.me });

      getLocations({ variables: { companyId: meData?.me?.company?.id } });
      fsIdentify(user);
      posthogIdentify(user);
      const fcmToken = sessionStorage.getItem("fcmToken") || "";
      if (fcmToken === "" || fcmToken == null) {
        const messaging = getMessaging();
        getToken(messaging, {
          vapidKey:
            "BD9Q8PAslpLGvoeuQCN1a_fv73mWko26NSgto01DqjkyxYxvsK4PqL8Hs9ykuLZpIHGD16USpahBy0_fQnvdcfM",
        })
          .then(currentToken => {
            if (currentToken) {
              const data = {
                registrationId: currentToken,
              };
              createFcmDevice({
                variables: {
                  ...data,
                },
              }).then(res => {
                if (res?.data?.createFcmDevice?.statusCode === 200) {
                  sessionStorage.setItem("fcmToken", currentToken);
                }
              });
            }
          })
          .catch(err => {
            console.log("An error occurred while retrieving token. ", err);
          });
      }
    }
  }, [meData]);

  const fetchUser = async () => {
    await getMe();
  };

  useEffect(() => {
    if (!user.id) {
      getMe().then(userData => {
        refetchPermissions().then(permissions => {
          updateUserWithPermissions(userData.data?.me, permissions?.data?.getUserPermissionActions);
        });
      });
    }
  }, [user]);

  useEffect(() => {
    fetchUser();
  }, [location]);
  useEffect(() => {
    if (user.id && userIds.length === 0) {
      dispatch(reducerSetAppointmentUserIds([+user.id]));
    }
  }, [user]);

  const getDifferedState = async setState => {
    let differedState;
    await new Promise(resolve =>
      // eslint-disable-next-line no-promise-executor-return
      setState(data => {
        differedState = data;
        resolve();
        return data;
      })
    );
    return differedState;
  };

  useEffect(() => {
    if (location === "/") {
      if (getCognitoRoles(accessPayload)[0] === "ADMIN") {
        navigate("/admin-dashboard");
      } else {
        navigate("/engagements/activity-center");
      }
      if (!sessionStorage.getItem("isInitialUrlSet")) {
        sessionStorage.setItem("isInitialUrlSet", true);
      }
    } else if (getCognitoRoles(accessPayload)[0] === "ADMIN") {
      navigate("/admin-dashboard");
      sessionStorage.setItem("isInitialUrlSet", true);
    } else if (
      !sessionStorage.getItem("isInitialUrlSet") &&
      !window.location.pathname.includes("browser-ext")
    ) {
      navigate("/engagements/activity-center");
      sessionStorage.setItem("isInitialUrlSet", true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      setUserFullName(user.fullName);
      setcurrentUserCompany(user.company.id);
      if (getCognitoRoles(accessPayload)[0] !== "ADMIN") {
        if (socket && !socket.connected) {
          socket.connect({ transports: ["websocket"], upgrade: false });
        }
        if (!socket) {
          const newSocket = io.connect(`${endPoint}`, {
            transports: ["websocket"],
            upgrade: false,
          });
          setSocket(newSocket);
        }
        if (socket) {
          console.log("layout.js:V1_SOCKET_URL", V1_SOCKET_URL);
          socket.on("connect", () => {
            console.log("Connected to socket:", socket.connected, " ID:", socket.id);
          });
          socket.on("disconnect", () => {
            console.log(
              "on disconnect from socket: Disconnected:",
              socket.disconnected,
              " ID:",
              socket.id
            );
          });
          socket.on("connect_error", () => {
            console.log(
              "on connect_error on socket: Connected:",
              socket.connected,
              " ID:",
              socket.id
            );
            socket.connect({ transports: ["websocket"], upgrade: false });
          });
          // Update unread message count in the state.
          socket.on("update_count", async data => {
            console.log("TEST update_count===>", data);
            dispatch(
              setLeadCountChange({
                ...data,
              })
            );
          });
          // When the server tells the socket that a message was received, show a toast message and post a message to the
          // parent (in case of the browser extension). Also, update the state to show the notification.
          // Do not show toast messages or notifications for messages sent to the lead.
          const handleRecievedMessageFromSocket = async data => {
            const leadIds = await getDifferedState(setleadIds);
            const locations = await getDifferedState(setlocation2);
            // Only show notifications relevant to the currently logged-in user
            if (+data?.user_id === +user.id) {
              const leadIdIndex = (leadIds || []).findIndex(item => +item === +data?.lead_id);
              if (
                leadIdIndex !== -1 &&
                getCognitoRoles(accessPayload)[0] !== "ADMIN" &&
                (!data?.direction || (data?.direction && data?.direction !== "TO_LEAD"))
              ) {
                if (window) {
                  window.location.pathname.includes("browser-ext") &&
                    window.parent.postMessage(
                      {
                        event_id: "notification_trigger",
                        data: {
                          notification: true,
                        },
                      },
                      "*"
                    );
                }

                if (!data?.direction || (data?.direction && data?.direction !== "TO_LEAD")) {
                  showToast("success", "NEW MESSAGE", data?.message, data?.lead_id);
                }
                setNotificationData({ ...data });
                setNewLead({});
                setAddNewLead(false);
              } else if (
                leadIdIndex === -1 &&
                getCognitoRoles(accessPayload)[0] !== "ADMIN" &&
                locations.findIndex(item => +item?.id === +data?.lead?.location_id) !== -1 &&
                (!data?.direction || (data?.direction && data?.direction !== "TO_LEAD"))
              ) {
                if (window) {
                  window.location.pathname.includes("browser-ext") &&
                    window.parent.postMessage(
                      {
                        event_id: "notification_trigger",
                        data: {
                          notification: true,
                        },
                      },
                      "*"
                    );
                }
                setNewLead({ ...data?.lead });
                setAddNewLead(true);
                setleadIds([...leadIds, data?.lead_id]);
                if (!data?.direction || (data?.direction && data?.direction !== "TO_LEAD")) {
                  showToast("success", "NEW MESSAGE", data?.message, data?.lead_id);
                }
                setNotificationData({ ...data });
              }
            } else {
              setNotificationData({ ...data });
            }
          };
          socket.on("received_message", async data => {
            console.log(
              "socket message received_message:data.user_id =",
              data?.user_id,
              "| user.id =",
              user.id,
              data
            );
            await handleRecievedMessageFromSocket(data);
          });
          socket.on("sent_message", async data => {
            console.log("sent_message====>", data);
            setNotificationData({ ...data });
          });
          setSocket(socket);
          localStorage.setItem("callSocket", "false");
          // Clean up the effect before the next render. In this case, disconnect the socket.
          return () => {
            socket.disconnect();
          };
        }
      }
    }
  }, [user]);

  useQuery(GET_CRM_INTEGRATION_TYPE, {
    onCompleted: res => {
      if (res) {
        dispatch(setCrmType(res?.getCrmLeadStatus?.crmIntegrationType || ""));
      }
    },
  });

  useEffect(() => {
    if (user.userAccounts) {
      setUserAccounts(user.userAccounts);
      if (user.userAccounts.length > 1 && getCognitoRoles(accessPayload)[0] !== "ADMIN") {
        setShowUserAccountOptions(true);
      }
      const userAccountOpts = user.userAccounts.map(userAccount => (
        <Select.Option key={userAccount.id} title={userAccount.name} value={userAccount.id}>
          {userAccount.name}
        </Select.Option>
      ));
      setUserAccountOptions(userAccountOpts);
    }
  }, [user]);

  function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }

  useEffect(() => {
    if (meError) {
      if (meError.message?.includes("Unauthorized")) {
        Auth.signOut().then(() => {
          sessionStorage.clear();
          localStorage.clear();
          deleteAllCookies();
        });
      } else if (meError.message?.includes("Signature has expired")) {
        Auth.currentSession()
          .then(session => session.getRefreshToken())
          .then(refreshToken => Auth.user.refreshSession(refreshToken));
      }
    }
  }, [meError]);

  if (user && user.company && (user.isDisabled || user.company.isDisabled) && canRedirect) {
    Auth.signOut().then(() => {
      sessionStorage.clear();
      localStorage.clear();
      deleteAllCookies();
    });
  }

  const markeNotifAsRead = notification => {
    updateNotification({ variables: { id: notification.id, read: true } });
  };

  const getMoreNotifications = () => {
    const lastNotification = notifications[notifications?.length - 1];
    fetchMoreNotifications({
      variables: {
        older: lastNotification?.date,
        isRead: isReadOnly,
        notificationTypes: notificationFilter,
        leadIds,
        orderBy: sortBy === "date" ? "date" : "read",
        ...(sortBy === "unread" && { orderDirection: "asc" }),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          getNotifications: [...prev.getNotifications, ...fetchMoreResult.getNotifications],
        };
      },
    }).then(d => {
      if (d.notifications && d.notifications.getNotifications.length === 0) {
        setHasMore(false);
      }
    });
  };

  const canchangeLocation = checkAccessPermission(
    AccessPermissionAction.LEAD_MANAGEMENT_SEND_TO_STORE
  );

  const handleMobileView = () => {
    dispatch(setMobileView({ isMobileView: window.innerWidth <= 800 }));
  };

  useEffect(() => {
    // Initial call
    handleMobileView();

    // Attach event listener
    const handleResize = () => {
      handleMobileView();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSavePhone = () => {
    if (mobileaddModal?.data?.phone && isPossiblePhoneNumber(mobileaddModal?.data?.phone)) {
      updateUserPhone({
        variables: { phone: mobileaddModal.data.phone },
      });
    } else {
      message.error(lang.enterValidPhone);
    }
  };

  const skip = () => {
    setmobileaddModal(prev => ({
      ...mobileaddModal,
      modalState: !prev.modalState,
      data: {},
    }));
    localStorage.setItem("showPhone", false);
  };

  const view = mobileaddModal.modalState ? (
    <UpdatePhone
      phoneSpinner={phoneSpinner}
      handleSavePhone={handleSavePhone}
      loading={loading}
      mobileaddModal={mobileaddModal}
      setmobileaddModal={setmobileaddModal}
      skip={skip}
    />
  ) : window.location.href?.includes("chat-bot") ? (
    <>
      <ChatBotPopup
        location={location}
        locationChatBot={locationChatBot}
        currentLocation={sessionStorage.getItem("currentLocationId")}
      />
      <Toast
        toastList={list}
        autoDelete={checkValue}
        autoDeleteTime={autoDeleteTime}
        onClickofToast={leadId => setSocketMsgSelectedLead(leadId)}
        isFromLayout
      />
    </>
  ) : window.location.href?.includes("browser-ext") ? (
    meData ? (
      <>
        <Toast
          toastList={list}
          autoDelete={checkValue}
          autoDeleteTime={autoDeleteTime}
          onClickofToast={leadId => setSocketMsgSelectedLead(leadId)}
          isFromLayout
        />
        <BrowserExtensionPopup
          Role={getCognitoRoles(accessPayload)[0]}
          setchanged={setchanged}
          changed={changed}
          setLocation={setLocation}
          getActiveCrmIntegration={getActiveCrmIntegration}
          canchangeLocation={canchangeLocation}
          location2={location2}
          location={location}
          locationChatBot={locationChatBot}
          currentLocation={sessionStorage.getItem("currentLocationId")}
          gotLocationId={gotLocationId}
          setPhone={setPhone}
          notificationsCount={notificationsCount}
          setShowNotifModal={setShowNotifModal}
          showNotifModal={showNotifModal}
          setSelectedNotif={setSelectedNotif}
          getMoreNotifications={getMoreNotifications}
          hasMore={hasMore}
          markeNotifAsRead={markeNotifAsRead}
          notifications={notifications}
          refetchNotifications={refetchNotifications}
          refetchNotificationsCount={refetchNotificationsCount}
          notificationLoader={notificationLoader}
          setNotifications={setNotifications}
        />
      </>
    ) : (
      <Spin className="fixed top-[50%] left-[50%]" />
    )
  ) : (
    <MainLayout
      accessPayload={accessPayload}
      permissionLeader={permissionLeader}
      list={list}
      checkValue={checkValue}
      autoDeleteTime={autoDeleteTime}
      setSocketMsgSelectedLead={setSocketMsgSelectedLead}
      canchangeLocation={canchangeLocation}
      setPhone={setPhone}
      location2={location2}
      setLocation={setLocation}
      getActiveCrmIntegration={getActiveCrmIntegration}
      setUser={setUser}
      persistor={persistor}
      currentPath={currentPath}
      user={user}
      setchanged={setchanged}
      changed={changed}
      notifications={notifications}
      setShowNotifModal={setShowNotifModal}
      setSelectedNotif={setSelectedNotif}
      getMoreNotifications={getMoreNotifications}
      hasMore={hasMore}
      markeNotifAsRead={markeNotifAsRead}
      notificationsCount={notificationsCount}
      handleProfileMenuClick={handleProfileMenuClick}
      setShowSupportModal={setShowSupportModal}
      deleteFcmDevice={deleteFcmDevice}
      userFullName={userFullName}
      setContentStyle={setContentStyle}
      contentStyle={contentStyle}
      showNotifModal={showNotifModal}
      selectedNotif={selectedNotif}
      locationId={locationId}
      refetchNotifications={refetchNotifications}
      notificationLoader={notificationLoader}
      refetchNotificationsCount={refetchNotificationsCount}
      setNotifications={setNotifications}
    >
      {children}
    </MainLayout>
  );
  return view;
};

export default Layout;
