import * as React from "react";

const RedirectIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none" {...props}>
    <rect width={35} height={35} x={0.5} y={0.5} fill="#fff" rx={1.5} />
    <path
      stroke="#3B82F6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.709 11.96h-2.084c-.92 0-1.666.747-1.666 1.668v8.75c0 .92.746 1.666 1.666 1.666h8.75c.92 0 1.667-.746 1.667-1.666v-2.084m0-4.583v-3.75h-3.75m3.541.208-6.041 6.042"
    />
    <rect width={35} height={35} x={0.5} y={0.5} stroke="#E2E8F0" rx={1.5} />
  </svg>
);
export default RedirectIcon;
