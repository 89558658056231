import { Input, DatePicker, message, Form, Spin, Radio, TimePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { closeDialog } from "../../../library/helpers";
import DialogFooter from "../../commonComponents/dialogFooter";

const HolidayForm = props => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { setLeaves, leave, index, edit, modifyCompanySchedule } = props;
  const [formData, setformData] = useState({
    fromDate: dayjs().toISOString(),
    toDate: dayjs().toISOString(),
    reason: "",
    leaveDescription: "",
    leaveType: "fullDay",
  });
  const [loading, setloading] = useState(true);
  useEffect(() => {
    if (edit && leave) {
      setloading(true);
      setformData({
        id: +leave?.id,
        fromDate: leave?.date,
        toDate: leave?.date,
        reason: leave?.details,
        leaveDescription: leave?.details,
        leaveType: "fullDay",
      });
      setloading(false);
    } else {
      setformData({ ...formData, ...leave });
      setloading(false);
    }
  }, [leave]);

  const handleSave = () => {
    try {
      if (edit) {
        setLeaves(prev => {
          const temp = [...prev];
          const index = temp.findIndex(item => item === leave);
          temp[index] = formData;
          return temp;
        });
        modifyCompanySchedule(formData);
      } else {
        setLeaves(prev => {
          const temp = [...prev];
          temp.push(formData);
          return temp;
        });
        modifyCompanySchedule(formData);
      }
      closeDialog("holidayForm");
      setloading(true);
    } catch (err) {
      message.error(err.message);
      console.log("err", err);
    }
  };
  return loading ? (
    <Spin tip="Loading..." />
  ) : (
    <div>
      <Form
        form={form}
        initialValues={{
          rangeDate: [dayjs(formData.fromDate), dayjs(formData.toDate)],
          reason: formData?.reason,
          leaveDescription: formData?.leaveDescription,
          leaveType: formData.leaveType,
          rangeTime: [dayjs(formData.fromDate), dayjs(formData.toDate)],
        }}
        onFinish={handleSave}
        layout="vertical"
      >
        <Form.Item
          name="reason"
          label="Name"
          rules={[{ required: true, message: "Please enter the holiday name" }]}
        >
          <Input
            value={formData.reason}
            name="reason"
            placeholder="Add holiday reason"
            onChange={e => setformData({ ...formData, reason: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          name="holidayDescription"
          rules={[{ required: true, message: "Please enter the holiday description" }]}
          label="Description"
        >
          <Input
            value={formData.leaveDescription}
            name="leaveDescription"
            onChange={e => setformData({ ...formData, leaveDescription: e.target.value })}
            placeholder="Add Holiday Description"
          />
        </Form.Item>
        {/* <Form.Item name="leaveType">
          <Radio.Group
            name="leaveType"
            value={formData && formData.leaveType}
            onChange={e => setformData({ ...formData, leaveType: e.target.value })}
          >
            <Radio value="fullDay">Full Day</Radio>
            <Radio value="custom">Custom Time</Radio>
          </Radio.Group>
        </Form.Item> */}
        {/* {formData.leaveType === "fullDay" ? (
          <Form.Item
            name="rangeDate"
            rules={[{ required: true, message: "Please enter the leave start and end date" }]}
            label="From-to date"
          >
            <>
              <RangePicker
                className="w-full"
                name="rangeDate"
                disabledDate={current => dayjs(current).day() === 0 || dayjs(current).day() === 6}
                value={[dayjs(formData.fromDate), dayjs(formData.toDate)]}
                onChange={date => {
                  setformData({
                    ...formData,
                    fromDate: dayjs(date[0]).toISOString(),
                    toDate: dayjs(date[1]).toISOString(),
                  });
                }}
              />
              <p className="text-right">
                Total :{" "}
                <b>
                  {dayjs(formData.toDate || formData.fromDate).diff(formData.fromDate, "d") + 1}
                </b>{" "}
                days
              </p>
            </>
          </Form.Item>
        ) : (
          <Form.Item
            title="From-to Time"
            rules={[
              { required: true, message: "Please select the time range you will be unavailable" },
            ]}
          >
            <>
              <TimePicker.RangePicker
                className="w-full"
                format="h:mm a"
                value={[dayjs(formData.fromDate), dayjs(formData.toDate)]}
                onChange={time => {
                  if (time) {
                    setformData({
                      ...formData,
                      fromDate: dayjs(time[0]).toISOString(),
                      toDate: dayjs(time[1]).toISOString(),
                    });
                  }
                }}
              />
              <p className="text-right">
                Total :{" "}
                <b>{dayjs(formData.toDate || formData.fromDate).diff(formData.fromDate, "h")}</b>{" "}
                hours
              </p>
            </>
          </Form.Item>
        )} */}
        <div className="flex flex-col w-full">
          <DatePicker
            value={dayjs(formData.fromDate)}
            // onSelect={value => {
            //   setholidays(prev => {
            //     const temp = [...prev];
            //     temp[i].date = value.toISOString();
            //     return temp;
            //   });
            // }}
            onSelect={value => {
              setformData({
                ...formData,
                fromDate: value.toISOString(),
                toDate: value.toISOString(),
              });
            }}
            placeholder="Select Holiday Date"
          />
        </div>
      </Form>
      <DialogFooter
        confirmBtnText="Save"
        handleConfirm={() => form.submit()}
        handleCancel={() => {
          closeDialog("holidayForm");
          setloading(true);
        }}
      />
    </div>
  );
};

export default HolidayForm;
