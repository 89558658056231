import * as React from "react";

const PersonIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#64748B"
      d="M10.75 7.5a2.75 2.75 0 1 1-5.5 0 2.75 2.75 0 0 1 5.5 0Zm3.75-4v9a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1Zm-1 9v-9h-11v9h.917a5 5 0 0 1 1.839-2.178.25.25 0 0 1 .301.02 3.739 3.739 0 0 0 4.886 0 .25.25 0 0 1 .301-.02 5 5 0 0 1 1.838 2.178h.918Z"
    />
  </svg>
);
export default PersonIcon;
