import React, { useState } from "react";
import TextInput from "../../library/textInput";
import CustomButton from "../commonComponents/button";
import CustomLabel from "../commonComponents/label";
import useLang from "../../hooks/useLang";

const CreatePresetFilters = props => {
  const { saveFilterSet } = props;

  const [lang] = useLang();

  const [filterName, setFilterName] = useState("");
  const [isValidPresetName, setIsValidPresetName] = useState(true);

  const handlePresetFilter = () => {
    if (filterName.length < 1) {
      setIsValidPresetName(false);
    } else {
      saveFilterSet(filterName);
      setIsValidPresetName(true);
    }
  };

  return (
    <div>
      <div className="flex gap-2 items-center w-full">
        <TextInput
          value={filterName}
          onChange={e => setFilterName(e.target.value)}
          placeholder={lang.enterPresetName}
          name="name"
          small
          containerClass="flex-1"
        />
        <CustomButton
          type="primary"
          btnText={lang.addToFavourites}
          handleClick={() => handlePresetFilter()}
        />
      </div>
      {!isValidPresetName && (
        <CustomLabel labelClass="text-red-500 font-[15px] py-2" label={lang.invalidName} />
      )}
    </div>
  );
};

export default CreatePresetFilters;
