import { useMutation } from "@apollo/client";
import { Button, Form, Input, Radio, message } from "antd";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../amplify/authenticator-provider";
import { SAVE_ENAGEMENT_MESSAGE_TEMPLATE } from "../../../graphql/mutation";
import { closeDialog } from "../../../library/helpers";
import { RichTextEditorJSX } from "../../userSettings/RichTextEditor";
import { isPlatformAdmin } from "../../../hooks/utils";

const EngagmentTemplateForm = ({ template, handleRefetch, companyId, mode = "ADD" }) => {
  const [form] = Form.useForm();
  const { user, authData } = useContext(UserContext);
  const isPlatformAdminUser = isPlatformAdmin(authData);
  const [formData, setformData] = useState({
    message: "",
    isCompanyShared: true,
    userId: user.id,
    id: template?.id || null,
    companyId: companyId,
  });
  useEffect(() => {
    if (mode === "EDIT") {
      setformData({ ...template });
      form.setFieldsValue({ ...template });
    }
  }, [template, mode]);
  const [saveEngagementMessageTemplates] = useMutation(SAVE_ENAGEMENT_MESSAGE_TEMPLATE);
  const handleSave = async value => {
    try {
      const payload = {
        ...value,
        ...formData,
        ...(formData.isCompanyShared
          ? {}
          : // TODO: Add Location selector and remove this condition from here
          isPlatformAdminUser
          ? {}
          : { locationId: sessionStorage.getItem("currentLocationId") }),
      };
      if (isEmpty(formData.message)) {
        return message.error("Please enter the message");
      }
      console.log(payload);
      const res = await saveEngagementMessageTemplates({
        variables: { ...payload },
      });
      if (res.data.saveEngagementMessageTemplate.statusCode === 200) {
        await handleRefetch();
        message.success(res.data.saveEngagementMessageTemplate.message);
        closeDialog("engagementTemplatesForm");
      } else {
        message.error(res.data.saveEngagementMessageTemplate.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Form form={form} onFinish={handleSave} layout="vertical">
      <div className="flex flex-col">
        <Form.Item
          label="Name"
          name="title"
          rules={[{ required: true, message: "Title is required" }]}
        >
          <Input name="title" placeholder="Message Title" />
        </Form.Item>
        <RichTextEditorJSX
          formData={formData}
          setformData={setformData}
          changingKey="message"
          className="min-h-[200px] h-[200px] w-full p-2"
        />
        <div className="flex flex-row items-center my-2 gap-2">
          <span>Share With:</span>
          <Radio.Group
            value={formData.isCompanyShared}
            onChange={e => setformData({ ...formData, isCompanyShared: e.target.value })}
          >
            <Radio value>Company</Radio>
            {/* TODO: Add a location selector for the admin user */}
            <Radio value={false} disabled={isPlatformAdminUser}>
              Location
            </Radio>
          </Radio.Group>
        </div>
        <div
          id="footer"
          className="flex bg-[#f8fafc] -mx-6 px-8  py-6 -mb-6 rounded-b-md flex-row items-center justify-between"
        >
          <div className="flex flex-row items-center gap-2">
            <Button htmlType="submit" type="primary">
              {mode === "ADD" ? "Add Template" : "Edit Template"}
            </Button>
            <Button onClick={() => closeDialog("engagementTemplatesForm")}>Cancel</Button>
          </div>
          <span className="text-sm font-[400] text-grey-700">
            <span className="text-xs font-[600] text-[#6474B] ">Owner:</span>{" "}
            {formData?.user?.fullName || user?.fullName}
          </span>
        </div>
      </div>
    </Form>
  );
};

export default EngagmentTemplateForm;
