import React from "react";
// import dayjs from "dayjs";
import { Select } from "antd";
import dayjs from "dayjs";

const duration = require("dayjs/plugin/duration");

dayjs.extend(duration);

const { Option } = Select;

const isEarlierThanEndLimit = (timeValue, endLimit, lastValue, defaultDate) => {
  const start = dayjs(defaultDate).add(dayjs.duration(timeValue));
  const timeValueIsEarlier = dayjs(timeValue, "hh:mm A") < dayjs(endLimit, "hh:mm A");

  const timeValueIsLaterThanLastValue =
    lastValue === undefined ? true : dayjs(lastValue, "hh:mm A") < dayjs(timeValue, "hh:mm A");

  return timeValueIsEarlier && timeValueIsLaterThanLastValue;
};

const renderOptions = (startTime, endTime, minuteStep, disabledTimes, defaultDate) => {
  let timeValue = startTime || "9:00 AM";
  let lastValue;
  const endLimit = endTime || "7:00 PM";
  const step = minuteStep || 15;
  const options = [];
  let index = 0;
  options.push(
    <Option
      key={`key__${index}`}
      value={timeValue}
      disabled={
        disabledTimes?.includes(timeValue) ||
        (dayjs()?.format("HH:mm") >= dayjs(timeValue, "h:mm A")?.format("HH:mm") &&
          defaultDate?.format("MM/DD/YYYY") <= dayjs()?.format("MM/DD/YYYY"))
      }
    >
      {timeValue}
    </Option>
  );

  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue, defaultDate)) {
    lastValue = timeValue;
    timeValue = dayjs(timeValue, "h:mm A").add(step, "minutes").format("h:mm A");
    index += 1;
    options.push(
      <Option
        key={`key__${index}`}
        value={timeValue}
        disabled={
          disabledTimes.includes(timeValue) ||
          (dayjs().format("HH:mm") >= dayjs(timeValue, "h:mm A").format("HH:mm") &&
            defaultDate?.format("MM/DD/YYYY") <= dayjs().format("MM/DD/YYYY"))
        }
      >
        {timeValue}
      </Option>
    );
  }
  return options;
};

const CustomTimePicker = ({
  fkey,
  value = null,
  onChange,
  startTime = "9:00 AM",
  endTime = "7:00 PM",
  minuteStep = 15,
  placeholder = "Select time",
  disabledTimes = [],
  disabled = false,
  defaultDate = dayjs(),
  getPopupContainer,
}) => {
  return (
    <div className="flex-1" key="div__fkey">
      <Select
        className="w-full"
        key={fkey}
        value={value || ""}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        getPopupContainer={getPopupContainer}
      >
        {renderOptions(startTime, endTime, minuteStep, disabledTimes, defaultDate)}
      </Select>
    </div>
  );
};

export default CustomTimePicker;
