import { navigate } from "gatsby";
import React from "react";
import { useDownloadExport } from "../../hooks/personalizedHooks";
import { colorConfig } from "../../library/colorConfig";
import { CenteredContainer } from "../../library/basicComponents";
import { displayGraphQLError } from "../../library/helpers";
import { Spacer } from "../../library/utils";
import AntdIconV4 from "../../utils/antdIcon";
import CustomButton from "../commonComponents/button";
import CustomLabel from "../commonComponents/label";

const RunExportModal = ({ status }) => {
  const { loading, error, data } = status;
  const id = data?.createExportConfig?.exportConfig?.exports[0]?.id;

  return (
    <CenteredContainer minHeight="230px">
      {loading && (
        <Banner
          title="Looking for prospects matching your configuration..."
          iconType="LoadingOutlined"
        />
      )}
      {error && (
        <Banner
          title={displayGraphQLError(error)}
          iconType="ExclamationCircleOutlined"
          id={id}
          history
        />
      )}
      {data && !loading && !error && (
        <Banner
          title="Your export is available!"
          iconType="CheckCircleOutlined"
          id={id}
          history
          download
        />
      )}
    </CenteredContainer>
  );
};

export default RunExportModal;

const Banner = ({ id, title, iconType, history, download }) => {
  const [downloadExport] = useDownloadExport();

  return (
    <>
      <AntdIconV4 style={{ fontSize: 52, color: colorConfig.primaryBrandBlue }} type={iconType} />
      <CustomLabel labelClass="p-5" label={title} />
      <Spacer>
        <CustomButton
          btnText="Go to History"
          disabled={!history}
          handleClick={() => navigate("/personalized-ads/history")}
        />

        <CustomButton
          type="primary"
          disabled={!download}
          handleClick={() => downloadExport({ variables: { id } })}
        />
      </Spacer>
    </>
  );
};
