import { useMutation } from "@apollo/client";
import { Alert, Input, message, Select } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { DEFAULT_COUNTRY } from "../../../config";

import { CREATE_LOCATION, UPDATE_LOCATION } from "../../../graphql/mutation";
import { timezones } from "../../../library/constants";
import { closeDialog, hasAdminAccess } from "../../../library/helpers";
import TextInput from "../../../library/textInput";
import { Countries } from "../../../utils/countries";
import { USStates } from "../../../utils/usStates";
import CommonHoliday from "../../commonComponents/commonHoliday";
import DialogFooter from "../../commonComponents/dialogFooter";
import useLang from "../../../hooks/useLang";
import CustomButton from "../../commonComponents/button";
import CustomDrawer from "../../commonComponents/drawer";
import { Holidays } from "../../userSettings/companyProfile/companyProfile";
import { UserContext } from "../../../amplify/authenticator-provider";

const LocationForm = ({
  editableLocation = null,
  regions,
  setEditableLocation,
  refetch,
  companyId,
  onClose,
}) => {
  const [lang] = useLang();

  const email = useRef("");
  const title = useRef("");
  const addressLine1 = useRef("");
  const postalCode = useRef("");
  const city = useRef("");
  const geoLink = useRef("");
  const website = useRef("");
  const openaiApiKey = useRef("");
  const locationNotes = useRef("");
  const vdpTextRef = useRef(null);
  const [vdpLink, setvdpLink] = useState("");
  const [createLocation, { loading }] = useMutation(CREATE_LOCATION);
  const [updateLocation, { loading: updateLoading }] = useMutation(UPDATE_LOCATION);

  const [error, setError] = useState("");
  const [location, setLocation] = useState(null);
  const [misc, setmisc] = useState({});
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (editableLocation) {
      setLocation(editableLocation);
      setvdpLink(editableLocation?.inventoryUrlFormat || "");
    }
  }, [editableLocation]);

  const updateLocationForm = (field, value) => {
    setLocation(prevState => {
      const newState = { ...prevState };
      newState[field] = value;
      return newState;
    });
  };

  const validateForm = (location, setError) => {
    let requirmentsSatisfied = true;

    if (!location?.timezone || location?.timezone === "") {
      requirmentsSatisfied = false;
      setError(lang.timeZoneSelected);
    }
    if (!location?.regionId || location?.regionId === "") {
      requirmentsSatisfied = false;
      setError(lang.regionSelected);
    }

    if (!location?.locationType || location?.locationType === "") {
      requirmentsSatisfied = false;
      setError(lang.locationSelected);
    }
    if (!location?.title || location?.title === "") {
      requirmentsSatisfied = false;
      setError(lang.locationNameEntered);
    }
    return requirmentsSatisfied;
  };
  const handleUpdateClick = async () => {
    if (validateForm(location, setError)) {
      setError("");
      if (editableLocation) {
        try {
          const resp = await updateLocation({
            variables: {
              ...location,
              locationId: editableLocation.id,
              inventoryUrlFormat: vdpLink,
            },
          });

          if (resp?.data?.updateCompanyLocation?.statusCode === 200) {
            refetch();
            setEditableLocation(null);
            onClose();
            message.success(resp?.data?.updateCompanyLocation?.message);
          } else {
            message.error(resp?.data?.updateCompanyLocation?.message);
          }
        } catch (err) {
          console.log("Error in updateLocation", err);
        }
      } else {
        try {
          const resp = await createLocation({
            variables: {
              ...location,
              isDefault: false,
              companyId,
              inventoryUrlFormat: vdpLink,
            },
          });
          if (resp?.data?.createCompanyLocation?.statusCode === 200) {
            refetch();
            setEditableLocation(null);
            onClose();
            message.success(resp?.data?.createCompanyLocation?.message);
          } else {
            message.error(resp?.data?.createCompanyLocation?.message);
          }
        } catch (err) {
          console.log("Error in createLocation", err);
        }
      }
    }
  };
  const handleAddKeyword = keyword => {
    const ctl = document.getElementById("vdpLinkText");
    const startPos = ctl.selectionStart;

    const output = [vdpLink.slice(0, startPos), `${keyword}`, vdpLink.slice(startPos)].join("");
    setvdpLink(output.trim());
    if (vdpTextRef.current) {
      vdpTextRef.current.focus();
    }
  };

  const isAdminAccess = hasAdminAccess();
  return (
    <div className="relative">
      {error && <Alert className="w-full py-1" message={error} type="error" banner />}
      <div className="w-full block sm:flex flex-wrap items-center gap-2 my-2">
        <div className="flex-1">
          <TextInput
            reference={title}
            placeholder={lang.locationName}
            label={lang.locationName}
            value={location?.title}
            require
            className="flex-1"
            labelClass="my-1 font-medium"
            onChange={e => updateLocationForm("title", e.target.value)}
          />
        </div>
        <div className="flex-1">
          <TextInput
            reference={email}
            placeholder={lang.emailLabel}
            label={lang.emailLabel}
            className="flex-1"
            labelClass="my-1 font-medium"
            value={location?.email}
            onChange={e => updateLocationForm("email", e.target.value)}
          />
        </div>
        <div className="flex-1">
          <p className="my-1 font-medium">{lang.phone}</p>
          <PhoneInput
            international
            withCountryCallingCode
            countryCallingCodeEditable={false}
            defaultCountry={DEFAULT_COUNTRY}
            placeholder={lang.phoneNumber}
            className="phoneInputCss flex-1"
            value={location?.phone}
            onChange={e => updateLocationForm("phone", e)}
          />
        </div>
      </div>
      <div className="w-full block sm:flex flex-wrap items-center gap-2 my-2">
        <div className="flex-1">
          <TextInput
            reference={addressLine1}
            placeholder={lang.address}
            className="w-full"
            label={lang.address}
            labelClass="my-1 font-medium"
            value={location?.addressLine1}
            onChange={e => updateLocationForm("addressLine1", e.target.value)}
          />
        </div>
        <div className="flex-1">
          <TextInput
            reference={city}
            placeholder={lang.city}
            label={lang.city}
            className="w-full"
            value={location?.city}
            labelClass="my-1 font-medium"
            onChange={e => updateLocationForm("city", e.target.value)}
          />
        </div>
      </div>
      <div className="w-full flex flex-wrap items-center gap-2 my-2">
        <div className="flex-1">
          <p className="font-medium">{lang.stateLabel}</p>
          <Select
            showSearch
            placeholder={lang.stateLabel}
            className="w-full"
            value={location?.state}
            onChange={e => updateLocationForm("state", e)}
          >
            {USStates.map((el, i) => (
              <Select.Option key={`key__${i}`} value={el.code}>
                {el.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex-1">
          <TextInput
            reference={postalCode}
            className="w-full"
            placeholder={lang.postalCode}
            label={lang.postalCode}
            value={location?.postalCode}
            labelClass="my-1 font-medium"
            onChange={e => updateLocationForm("postalCode", e.target.value)}
          />
        </div>
        <div className="flex-1">
          <p className="font-medium">{lang.country}</p>
          <Select
            showSearch
            placeholder={lang.country}
            className="w-full"
            value={location?.country}
            onChange={e => updateLocationForm("country", e)}
          >
            {Countries.map((el, i) => (
              <Select.Option key={`key__${i}`} value={el.name}>
                {el.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="w-full flex flex-wrap items-center gap-2 my-2">
        <div className="flex-1">
          <TextInput
            reference={geoLink}
            placeholder={lang.enterMapLinkLabel}
            value={location?.geoLink}
            className="w-full"
            label={lang.mapLink}
            labelClass="my-1 font-medium"
            onChange={e => updateLocationForm("geoLink", e.target.value)}
          />
        </div>
        <div className="flex-1">
          <TextInput
            reference={website}
            placeholder={lang.websiteUrl}
            className="w-full"
            label={lang.websiteLink}
            value={location?.website}
            labelClass="my-1 font-medium"
            onChange={e => updateLocationForm("website", e.target.value)}
          />
        </div>
      </div>
      <div className="w-full flex flex-wrap items-center gap-2 my-2">
        <div className="flex-1">
          <p className="font-medium">
            {lang.timezone}
            <span className="m-0 text-red-500 ml-1">*</span>
          </p>
          <Select
            className="w-full"
            placeholder={lang.timezone}
            value={location?.timezone}
            onChange={e => updateLocationForm("timezone", e)}
          >
            {timezones.map(timezone => (
              <Select.Option key={timezone.value} value={timezone.value}>
                {timezone.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex-1">
          <p className="font-medium">
            {lang.locationType}
            <span className="m-0 text-red-500 ml-1">*</span>
          </p>
          <Select
            className="w-full"
            placeholder={lang.locationType}
            value={location?.locationType}
            onChange={e => updateLocationForm("locationType", e)}
          >
            <Select.Option key="Headquarters" value="Headquarters">
              {lang.headquarters}
            </Select.Option>
            <Select.Option key="Corporate_Office" value="Corporate Office">
              {lang.corporateOffice}
            </Select.Option>
            <Select.Option key="store" value="Store">
              {lang.store}
            </Select.Option>
          </Select>
        </div>
        <div className="flex-1">
          <p className="font-medium">
            {lang.region}
            <span className="m-0 text-red-500 ml-1">*</span>
          </p>
          <Select
            className="w-full"
            placeholder={lang.region}
            value={String(location?.regionId || "")}
            onChange={e => updateLocationForm("regionId", e)}
          >
            {regions.map(item => (
              <Select.Option key={item?.id} value={item?.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="flex-1">
        <p className="font-medium">
          VDP Link Format
          <span className="m-0 text-red-500 ml-1">*</span>
        </p>
        <CustomButton
          key="vinNumber"
          className="px-1 labelColor"
          btnText="{vinNumber}"
          type="link"
          handleClick={() => handleAddKeyword("{vinNumber}")}
        />
        <Input
          id="vdpLinkText"
          value={vdpLink}
          onChange={e => setvdpLink(e.target.value)}
          ref={vdpTextRef}
          placeholder="Enter the VDP link format here"
        />
      </div>
      {isAdminAccess && (
        <>
          <div className="flex-1 my-2">
            <TextInput
              reference={openaiApiKey}
              placeholder={lang.openApiKeyLabel}
              label={lang.openApiKeyLabel}
              className="flex-1"
              labelClass="my-1 font-medium"
              value={location?.openaiApiKey}
              onChange={e => updateLocationForm("openaiApiKey", e.target.value)}
            />
          </div>
          <div className="flex-1 my-2">
            <p className="font-medium">{lang.locationNoteLabel}</p>
            <Input.TextArea
              rows={2}
              ref={locationNotes}
              id="locationNotes"
              className="flex-1"
              value={location?.notes}
              onChange={e => updateLocationForm("notes", e.target.value)}
              placeholder={lang.locationNoteLabel}
            />
          </div>
        </>
      )}

      <div className="w-max">
        <CommonHoliday misc={misc} setmisc={setmisc} />
      </div>
      <CustomDrawer data={misc} setdata={setmisc} content={<Holidays edit user={user} />} />
      <DialogFooter
        confirmBtnText={editableLocation ? lang.update : lang.save}
        handleConfirm={handleUpdateClick}
        loading={loading || updateLoading}
        handleCancel={() => closeDialog("createLocation")}
      />
    </div>
  );
};

export default LocationForm;
